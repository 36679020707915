import Track from "@/utils/track";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.module.less";

interface Props {
  list: any[];
  activeIndex: number;
  setActiveIndex: (any) => void;
}
const TabBasic: React.FC<Props> = ({ list, activeIndex, setActiveIndex }) => {
  const track = Track();

  const isNeedCenterActive = useRef(true);
  const [showBackToday, setShowBackToday] = useState(true);
  const [showAllPanel, setShowAllPanel] = useState(false);

  const scrollIntoTargeView = () => {
    document.getElementById("tab" + activeIndex)?.scrollIntoView({
      behavior: "auto",
      inline: isNeedCenterActive.current ? "center" : "nearest",
    });
    if (isNeedCenterActive.current) {
      isNeedCenterActive.current = false;
    }
  };

  const onBackToday = () => {
    const todayIndex = list.findIndex(item => item.title === "今日");
    if (todayIndex === -1) {
      return;
    }
    isNeedCenterActive.current = true;
    if (todayIndex === activeIndex) {
      scrollIntoTargeView();
    } else {
      setActiveIndex(todayIndex);
    }
    setShowBackToday(false);
  };

  const onShowAll = () => {
    setShowAllPanel(!showAllPanel);
    track.track("resource_click", {
      page_name: "今日必卖日历页",
      resource_type: "日历导航",
      resource_name: "日历",
      btn_name: "日历",
    });
  };

  const onClosePopup = () => {
    setShowAllPanel(false);
  };

  const handleTodayItemisIntersecting = () => {
    const todayIndex = list.findIndex(item => item.title === "今日");
    const todayElement = document.getElementById("tab" + todayIndex);
    const observer = new IntersectionObserver(entries => {
      setShowBackToday(!entries[0].isIntersecting);
    });
    // 开始观察元素
    todayElement && observer.observe(todayElement);
  };

  const handleSetActiveIndex = (index, options?: any) => {
    setActiveIndex(index);
    const MD = moment(Number(list[index]?.calendarDay)).format("M月D日");
    if (options?.isPanel) {
      track.track("resource_click", {
        page_name: "今日必卖日历页",
        resource_type: "日历筛选",
        resource_name: MD,
        btn_name: MD,
        brand_id: list[index]?.brandVOList.map(item => item.brandId).join(),
        page_desc: MD,
      });
    } else {
      track.track("resource_click", {
        page_name: "今日必卖日历页",
        resource_type: "日历导航",
        resource_name: MD,
        btn_name: MD,
      });
    }
  };

  useEffect(() => {
    if (list.length === 0) {
      return;
    }
    scrollIntoTargeView();
  }, [activeIndex]);

  useEffect(() => {
    handleTodayItemisIntersecting();
  }, [list]);

  return (
    <div className={styles.component}>
      {list?.length > 0 && (
        <>
          {/* tab列表x轴滚动区域 */}
          <div className={styles.component__tabs}>
            {/*  回今日 */}
            {showBackToday && (
              <div className={styles["back-today"]} onClick={onBackToday}>
                <img
                  className={styles.icon}
                  src="https://akim-oss.aikucun.com/mshop/dac296d29785b267dc8e6a5636b6cff8a7d1a733_1722579456471_58.png"
                />
                <div className={styles.text}>回今日</div>
              </div>
            )}
            {/* 滚动区域 */}
            <div className={styles["scroll-view"]}>
              {list.length > 0 &&
                list.map((item, index) => {
                  return (
                    <div
                      id={"tab" + index}
                      className={`${styles["item"]} ${activeIndex === index && styles["active"]}`}
                      key={index}
                      onClick={() => handleSetActiveIndex(index)}>
                      <div className={styles["item__title"]}>{item.title || ""}</div>
                      <div className={styles["item__subtitle"]}>{item.subTitle || ""} </div>
                    </div>
                  );
                })}
            </div>
            {/* 日历 */}
            <div className={styles["calendar"]} onClick={onShowAll}>
              <img
                className={styles["icon__calendar"]}
                src="https://akim-oss.aikucun.com/mshop/7d7d0f30bd81688595a13a2fba104177010b7703_1722514786043_63.png"
              />
              <div className={styles.text}>日历</div>
              <img
                className={styles["icon__arrow"]}
                src="https://akim-oss.aikucun.com/mshop/b000d8bbe58bbfd76794af1daebd6d8dec8122de_1722514881548_59.png"
              />
            </div>
          </div>
          {/* 全部面板 */}
          {showAllPanel && (
            <div className={styles["all-panel-popup"]} onClick={onClosePopup}>
              <div className={styles["all-panel"]}>
                {list.map((item, index) => {
                  return (
                    <div
                      className={`${styles["panel__item"]} ${activeIndex === index && styles["active"]}`}
                      key={index}
                      onClick={() => handleSetActiveIndex(index, { isPanel: true })}>
                      <div className={styles["item__header"]}>
                        <div className={styles["header__left"]}>{item.title}</div>
                        <div className={styles["header__right"]}>{item.week}</div>
                      </div>
                      <div className={`${styles["item__brand"]} ${styles["text-ellipsis1"]}`}>
                        {item.brandVOList?.[0]?.["brandName"]}
                      </div>
                      <div className={`${styles["item__brand"]} ${styles["text-ellipsis1"]}`}>
                        {item.brandVOList?.[1]?.["brandName"]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TabBasic;
