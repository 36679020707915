import React from "react";
import { useSelectedActivity, useSelectedPrefixCategory } from "../hooks/useActivityProduct";
import styles from "./SelectionHeader.module.less";

type Props = {
  showSelectionInHeader: boolean;
  onClickSelection?: React.MouseEventHandler<HTMLDivElement>;
};

const SelectionHeader: React.FC<Props> = ({ showSelectionInHeader, onClickSelection }) => {
  const selectedActivity = useSelectedActivity();
  const selectedCategory = useSelectedPrefixCategory();

  return (
    <div
      className={`${styles.selection} ${showSelectionInHeader ? "" : styles.hide}`}
      onClick={onClickSelection}>
      <span>{selectedActivity?.activityAggrTitle}</span>
      {selectedActivity?.activityAggrTitle && <span className={styles.seperator}></span>}
      <span>{selectedCategory?.name}</span>
    </div>
  );
};

export default SelectionHeader;
