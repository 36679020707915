import { isInGray } from "@/api";
import { SharePosterParams } from "@/api/scode/poster";
import { ReactComponent as Empty } from "@/assets/empty.svg";
import loadingPng from "@/base64";
import InfiniteScrollContent from "@/components/InfiniteScrollContent";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import SizePopup from "@/components/size-popup";
import { ProductModel as PM2 } from "@/models/product";
import { sharePosterParams4H5Product, shareProductPosterAction } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { showSKUPreview } from "@/utils/select-color";
import useNavigation from "@/utils/useNavigate";
import { ProductModel } from "@akc/biz-api";
import { ProductCard12Comp } from "@akc/mx-taro-h5-components";
import { InfiniteScroll, Skeleton } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import AddPurchasePanel from "../add-purchase-panel";
import AddToCartModal from "../add-to-cart-modal";
import Waterfall from "../waterfall";
import styles from "./index.module.less";

type Props = {
  productList: Array<ProductModel>;
  hasNext: boolean;
  loadMore: () => Promise<void>;
  onCartChange?: () => (Promise<void> | void);
  onClickProduct?: (item: ProductModel) => void;
  onPropertyDetail?: (item: ProductModel) => void;
  topRightLocationCode?: string;
  titleFrontLocationCode?: string;
  titleBottomLocationCode?: string;
  hasSidebar?: boolean;
  cardHasBorder?: boolean;
  provideBackground?: boolean;
  isManualLoadMore?: boolean;
  isOuterMargin?: boolean;
  trackData?: any;
  btnClickName?: string;
};

const ProductWaterfall: React.FC<Props> = ({
  productList,
  hasNext,
  loadMore,
  onCartChange,
  onClickProduct,
  onPropertyDetail,
  topRightLocationCode,
  titleFrontLocationCode,
  titleBottomLocationCode,
  hasSidebar = false,
  cardHasBorder = false,
  provideBackground = true,
  isManualLoadMore = false,
  isOuterMargin = false,
  trackData,
  btnClickName,
}) => {
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>();
  const [customEditData, setCustomEditData] = useState<CustomEditData>();
  const [showPoster, setShowPoster] = useState(false);
  const { setCartNum } = useCommonContext();
  const [waterfallContainerDomHeight, setWaterfallContainerDomHeight] = useState<number>(0);
  const [search] = useSearchParams();
  const pageCode = search.get("customPageCode");
  const [isOTC, setIsOTC] = useState(false);
  // 是否显示新版加购面板
  const [showNewCartPanel, setShowNewCartPanel] = useState<boolean>(true);

  // 获取加购面板灰度
  const getCartPanelGray = async () => {
    const res = await isInGray("B-H5_REACT_ADD_PANEL");
    const newCartPanel = !!res;
    setShowNewCartPanel(newCartPanel);
  };

  const getPlatformFunc = () => {
    // eslint-disable-next-line no-underscore-dangle
    const isMini = window.__wxjs_environment === "miniprogram";
    const isApp = navigator && navigator.userAgent.search("AKC") > -1;
    const isSaas = isMini && !!location.href.match(/plt=saas/i);
    const isWxH5 = !isMini && navigator && !!navigator.userAgent.match(/miniProgram/i);
    const isH5 = isWxH5 || (!isMini && !isApp);
    const isTuan = isMini && location.href.match(/plt=atuan/i);
    return {
      isMini,
      isApp,
      isWxH5,
      isH5,
      isSaas,
      isBrowser: !isMini && !isApp && !isWxH5,
      isTuan,
    };
  };

  const platform = getPlatformFunc();

  const forwardProduct = (item: any) => {
    if (!getPlatformFunc()?.isApp && !getPlatformFunc()?.isMini) {
      setSharePosterData(
        sharePosterParams4H5Product({
          pageCode: pageCode as string,
          activitySpuId: item.activitySpuId,
          selectSkuId: item.selectSkuId,
        }),
      );
      setCustomEditData({
        activitySpuId: item.activitySpuId,
        activityId: item.activityId,
        selectSkuId: item.selectSkuId,
      });
      setIsOTC(!!item?.skuExtendInfoVO?.isOTC);
      setShowPoster(true);
    } else {
      shareProductPosterAction({
        product: item,
        pageCode: pageCode as string,
      });
    }
  };

  // const forwardProduct = async (item: any) => {
  //   setSharePosterData(
  //     // TODO: pageCode
  //     await sharePosterParams4H5Product({
  //       pageCode: "",
  //       activitySpuId: item.activitySpuId,
  //       selectSkuId: item.selectSkuId || undefined,
  //     }),
  //   );
  //   setCustomEditData({
  //     activitySpuId: item.activitySpuId,
  //     activityId: item.activityId,
  //     selectSkuId: item.selectSkuId,
  //   });
  //   setShowPoster(true);
  // };

  const { navigate2Product } = useNavigation();
  const goDetail = (item, _, salePropertyFirstListPicItem?: any) => {
    navigate2Product({
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
      firstSalePropertyValue: salePropertyFirstListPicItem?.value,
    });
  };

  const [cartModalVisible, setCartModalVisible] = useState<boolean>(false);
  const [modalProduct, setModalProduct] = useState<PM2>();
  const onClickAddBtn = (item: PM2) => {
    setCartModalVisible(true);
    setModalProduct(item);
  };
  useUnactivate(() => {
    setCartModalVisible(false);
  });

  const [showSizePopup, setShowSizePopup] = React.useState(false);
  const [sizePopupProduct, setSizePopupProduct] = React.useState<PM2>();
  const changeCartNum = () => {
    setCartNum().then(num => {
      console.log("购物车数量", num);
      onCartChange?.();
    });
  };

  const waterfallContainerDom = useRef<HTMLDivElement>(null);
  // const getWaterfallContainerDomHeight = () => {
  //   return waterfallContainerDom.current?.clientHeight ?? 0;
  // };
  const resetWaterfallContainerDomHeight = (height: number) => {
    setWaterfallContainerDomHeight(height);
  };

  useEffect(() => {
    getCartPanelGray();
  }, []);

  useEffect(() => {
    productList.length === 0 && setWaterfallContainerDomHeight(0);
  }, [productList]);

  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className={`${styles.container} ${provideBackground ? styles.provideBackground : ""}`}>
        {hasNext && productList.length === 0 && (
          <div className={`${styles.skeletonContainer} ${hasSidebar ? styles.hasSidebar : ""}`}>
            {new Array(6).fill(0).map((_, index) => (
              <Skeleton key={index} animated className={styles.cardSkeleton} />
            ))}
          </div>
        )}
        {productList.length > 0 && (
          <div
            ref={waterfallContainerDom}
            className={`${styles.waterfallConatainer} ${hasSidebar ? styles.hasSidebar : ""} ${isOuterMargin ? styles.outerMargin : ""
              }`}>
            <Waterfall
              dataList={productList}
              childRender={(item, index) => {
                return (
                  <ProductCard12Comp
                    style={{
                      width: hasSidebar ? "2.87rem" : "3.42rem",
                      border: cardHasBorder ? "0.5px solid #f0f0f0" : "none",
                    }}
                    product={item}
                    topRightLocationCode={topRightLocationCode}
                    titleFrontLocationCode={titleFrontLocationCode}
                    titleBottomLocationCode={titleBottomLocationCode}
                    onProdutClick={() => {
                      goDetail(item, index);
                      onClickProduct?.(item);
                    }}
                    onSkusClick={() => {
                      const productInfo: PM2 = {
                        salePropertyFirstList: item.salePropertyFirstList,
                        spuInfoVO: {
                          sellOut: item.spuSellOut,
                        },
                        spuId: item.spuId,
                        activityId: item.activityId,
                        activitySpuId: item.activitySpuId,
                        selectSkuId: item.selectSkuId,
                      };
                      showSKUPreview(productInfo).then(result => {
                        if (result) {
                          return;
                        }
                        setSizePopupProduct?.(productInfo);
                        setShowSizePopup(true);
                      });
                    }}
                    onAddCartClick={() => onClickAddBtn(item)}
                    onForwardClick={forwardProduct}
                    skuCount={hasSidebar ? 3 : 4}
                    trackData={item.trackData ?? trackData}
                    btnClickName={btnClickName ?? "btn_click"}
                  />
                );
              }}
              margin={{ x: 8, y: 8 }}
              resetHeight={resetWaterfallContainerDomHeight}
            />
          </div>
        )}
        {isManualLoadMore ? (
          <>
            {!isLoading && (
              <div
                className={styles.seeMoreBtn}
                onClick={() => {
                  setIsLoading(true);
                  loadMore().then(() => {
                    setIsLoading(false);
                  });
                }}>
                <span>查看更多</span>
                <img
                  className={styles.icon}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAaBJREFUOE+tlbtKA0EUhr9JcBtJKm1MOgsRVxKIQiAPoSKmsRUfQBK1EEFsNN4QJOKt00aI19cwECGggptCQVKl0E7Rkcmum83FZMVMN+fyzX/mnJ0VNFgyEung830UGAGGkPSUwwQvwA1wiVe7ENnsR226qDXIkD6GEGtAb6PDHDYDKZPiNn/ujLOBEjyE9RUQibIWd0uCXCeXnxfwZRZhLRnWUyCS7jh1da2JXH7WBlplZv6grI6IlOOqfGE14M7FnbUSb+DV+oUMD04Ap62iXfrjCngMTNoJHg8k5yAYhK0NKBSqWZoGMwkIBCC1Cs9PTv+JAt4DfbY1GoXdfXNbKsH0FBiP5l7BNrchFjP3ZxlYXnICH4QMDb4i8NnWrm44OwefvxqqlDhhyru4ANdXFaDkrR6o3AM67O6BzzpHKTUMGB6uJKd34MCqxJ49Bawt+cdZC3UW1ghm+h/qm+JMbAT9HaYyy01pPjYKupMGvx8U7Oiw2QTF3Q12Z6d5n8ViM5g52CqirZ+e3aB2Pg5lle1+vmyl7Xpgnbf9n1/AN5hVqU1s2NT1AAAAAElFTkSuQmCC"
                />
              </div>
            )}
            {isLoading && (
              <div className={styles.loadingMore}>
                <img src={loadingPng} />
              </div>
            )}
          </>
        ) : (
          <div className={styles.infiniteScrollContainer}>
            {waterfallContainerDomHeight > 0 && (
              <InfiniteScroll loadMore={loadMore} hasMore={hasNext} threshold={0}>
                {(hasNext || productList.length > 0) && <InfiniteScrollContent hasMore={hasNext} />}
              </InfiniteScroll>
            )}
          </div>
        )}
        {!hasNext && productList.length === 0 && (
          <div className={styles.emptyContainer}>
            <Empty />
            <div className={styles.text}>未找到相关商品</div>
          </div>
        )}
      </div>

      {sizePopupProduct && (
        <SizePopup
          product={sizePopupProduct}
          show={showSizePopup}
          close={() => setShowSizePopup(false)}
          toDetailByFirstProp={(product, salePropertyFirstListPicItem) => {
            goDetail(product, undefined, salePropertyFirstListPicItem);
            onPropertyDetail?.(product);
          }
          }
        />
      )}

      {sharePosterData && (
        <PosterSwipe
          show={showPoster}
          posterData={sharePosterData}
          customEditData={customEditData}
          isOTC={isOTC}
          shareType="PRODUCT"
          btnConfig={["copy", "edit"]}
          close={() => {
            setShowPoster(false);
            document.body.style.overflow = "auto";
          }}
        />
      )}
      {showNewCartPanel ? (
        <AddPurchasePanel
          visible={cartModalVisible}
          product={modalProduct}
          close={() => setCartModalVisible(false)}
          confirm={changeCartNum}
        />
      ) : (
        <AddToCartModal
          visible={cartModalVisible}
          product={modalProduct}
          close={() => setCartModalVisible(false)}
          confirm={changeCartNum}
        />
      )}
    </>
  );
};

export default ProductWaterfall;
