import { getCouponWindowForVenue } from "@/api/coupon";
import CouponForActivity from "@/components/couponForActivity";
import CouponForVenue from "@/components/couponForVenue";
import MarketingPopup from "@/components/marketing-popup";
import React, { useEffect, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import { useSelectedActivity } from "../hooks/useActivityProduct";

interface IProps {
  updateActivity: () => void;
}

const ActivityCouponPopup = (props: IProps) => {
  const { updateActivity } = props;

  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const [couponInfo, setCouponInfo] = useState<any>({});
  const selectedActivity = useSelectedActivity();

  const queryCouponWindowForVenue = async activityId => {
    const params = {
      shopBizCode: shopId,
      pageCode: "activityDetail",
      venueId: activityId,
    };
    const res: any = await getCouponWindowForVenue(params);
    if (res?.data) {
      console.log("queryCouponWindowForVenue", res.data);
      setCouponInfo(res.data);
    }
  };

  useActivate(() => {
    setCouponInfo(null);
  });

  useUnactivate(() => {
    setCouponInfo(null);
  });

  useEffect(() => {
    if (selectedActivity?.activityId) {
      setCouponInfo({});
      queryCouponWindowForVenue(selectedActivity.activityId);
    } else {
      setCouponInfo({});
    }
  }, [selectedActivity]);

  return (
    <>
      {couponInfo && couponInfo.totalCouponAmount > 0 && couponInfo.thirdCustomerFlag !== 1 && (
        <CouponForActivity
          couponInfo={couponInfo}
          pageName="档期详情页"
          update={() => updateActivity()}
        />
      )}
      {couponInfo && couponInfo.totalCouponAmount > 0 && couponInfo.thirdCustomerFlag === 1 && (
        <CouponForVenue
          couponInfo={couponInfo}
          pageName="档期详情页"
          update={() => updateActivity()}
        />
      )}
      {!couponInfo?.totalCouponAmount && <MarketingPopup serverCode="product_detail" />}
    </>
  );
};
export default ActivityCouponPopup;
