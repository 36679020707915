import { useEffect } from "react";

const useStickyStateObserver = (
  dom: HTMLDivElement | null,
  onStickyStateChange: (isSticky: boolean) => void,
) => {
  useEffect(() => {
    if (!dom) {
      return;
    }
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.intersectionRatio > 0) {
          onStickyStateChange(false);
        } else {
          onStickyStateChange(true);
        }
      },
      { threshold: [0, 1], rootMargin: "-44px 0px 0px 0px" },
    );
    observer.observe(dom);

    return () => {
      observer.unobserve(dom);
      observer.disconnect();
    };
  }, [dom]);
};

export default useStickyStateObserver;
