import { member } from "@/api";
import AKJS from "@akc/akjs";
import { urlQueryObject } from "./url-parse";

export const getPlatform = () => {
  // eslint-disable-next-line no-underscore-dangle
  const isMini = window.__wxjs_environment === "miniprogram";
  const isApp = navigator && navigator.userAgent.search("AKC") > -1;
  const isiOS = isApp && navigator && navigator.userAgent.match(/(iPhone|iPad|iPod|iOS)/i);
  const isAndroid = isApp && navigator && navigator.userAgent.match(/(Android)/i);
  const isSaas = isMini && !!location.href.match(/plt=saas/i);
  const isWxH5 = !isMini && navigator && !!navigator.userAgent.match(/MicroMessenger/i);
  const isH5 = isWxH5 || (!isMini && !isApp);
  const isTuan = isMini && location.href.match(/plt=atuan/i);
  return {
    isMini,
    isApp,
    isiOS,
    isAndroid,
    isWxH5,
    isH5,
    isSaas,
    isBrowser: !isMini && !isApp && !isWxH5,
    isTuan,
  };
};
export const getCookie = cname => {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
};
export const entryShop = async () => {
  const { shopId, plt, token, distributorId } = urlQueryObject();
  let finalToken = "";
  let channel = "";
  if (getPlatform().isApp) {
    const res: any = await AKJS.ready().then(() => {
      return AKJS.info.account();
    });
    finalToken = res.data.token;
    channel = "akcApp";
  } else if (getPlatform().isMini) {
    channel = "xdApplets";
    if (plt === "saas") {
      channel = "SaaSApplets";
    }
    if (plt === "atuan") {
      channel = "tuanApplets";
    }
    finalToken = token || "";
  } else {
    if (shopId) {
      const res = (await member({ shopId, distributorId })) || {};
      localStorage.setItem("userinfo", JSON.stringify(res?.data));
    }
  }
  return {
    token: finalToken,
    channel,
  };
};
export const platform = getPlatform();

export const MICRO_APP_BASEURL = "";

export const getIOSVersion = () => {
  const userAgent = window.navigator.userAgent;
  if (/iP(hone|od|ad)/.test(userAgent)) {
    const versionMatch = userAgent.match(/OS (\d+_\d+)/);
    if (versionMatch) {
      return parseFloat(versionMatch[1].replace("_", "."));
    }
    return 0;
  }
  return 0;
};

// 获取宿主环境(pageId | 标签code 前缀用)
export const getHostEnv = () => {
  if (getPlatform().isApp) {
    return "App";
  } else if (getPlatform().isMini) {
    return "Mini_Program";
  } else {
    return "H5";
  }
};

export const hostEnv = getHostEnv();
