import { ActivityTaskItem } from "@/api/rewardCenter";
import { getCookie } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Button, ProgressBar, Tag } from "antd-mobile";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "./ArrowIcon.svg";
import { ReactComponent as ArrowIcon2 } from "./ArrowIcon2.svg";
import { ReactComponent as MoneyIcon } from "./MoneyIcon.svg";
import { ReactComponent as MoreIcon } from "./MoreIcon.svg";
import { ReactComponent as NewIcon } from "./NewIcon.svg";
import styles from "./index.module.less";
import { computedCountdownTime, useCountdown } from "./useCountDown";

interface ButtonIProps {
  // 是否是权益活动
  equity?: boolean;
  // 是否参与
  isPart?: boolean;
  // "活动状态 NOT_BEGIN:未开始 ADVANCE:预告中 BEGIN:已开始 END:已结束 CANCEL:已作废 DRAFT:草稿 AWAITING_DRAW:待开奖 "
  status?: ActivityTaskItem["aggStatus"];
  // 开奖状态 1、未中奖 2、已中奖 3、计算中
  calculateReward?: ActivityTaskItem["calculateReward"];
  onClick?: () => void;
  children?: React.ReactNode;
}
// 按钮组件（立即参加、活动预热中、活动详情、奖励结算中）
const TaskButton: React.FC<ButtonIProps> = ({
  equity,
  isPart,
  status,
  calculateReward,
  onClick,
}) => {
  let btnText = ["NOT_BEGIN", "ADVANCE"].includes(status as string)
    ? "活动预热中"
    : !isPart && status === "BEGIN"
      ? "转发赚钱"
      : "活动详情";
  if (calculateReward === 3) {
    btnText = "奖励结算中";
  }
  return (
    <Button
      color="primary"
      size="small"
      onClick={() => onClick?.()}
      style={{
        "--background-color":
          btnText === "转发赚钱" ? "#FF4747" : equity ? "#FFFFFF" : "rgba(255, 71, 71, 0.08)",
        "--border-color":
          btnText === "转发赚钱" ? "#FF4747" : equity ? "transparent" : "transparent",
        "--text-color": btnText === "转发赚钱" ? "#FFFFFF" : "#FF4747",
        fontSize: 14,
        height: 32,
      }}>
      {btnText}
    </Button>
  );
};
interface TagIProps {
  // 标签类型
  type?: "welfare";
  // 是否是权益活动
  equity?: boolean;
  children?: React.ReactNode;
}
// 标签组件（平台福利、预计xx后开奖）
const TaskTag: React.FC<TagIProps> = ({ equity, type, children }) => {
  // 平台福利标签
  if (type === "welfare") {
    return (
      <Tag
        fill="solid"
        style={{
          "--background-color": equity
            ? "linear-gradient(292deg, #222222 25%, #6A4522 100%)"
            : "#FFD4B6",
          "--text-color": equity ? "#FFE2B6" : "#8C4206",
          border: "none",
          fontSize: 10,
          fontWeight: 500,
          lineHeight: "14px",
          padding: "1px 4px",
          marginTop: 3,
          marginRight: 4,
          display: "inline-block",
          verticalAlign: "text-top",
        }}>
        {children}
      </Tag>
    );
  }
  return (
    <Tag
      round
      fill="solid"
      style={{
        "--background-color": "rgba(255, 71, 71, 0.08)",
        "--text-color": "#FF4747",
        border: "none",
        fontSize: 10,
        fontWeight: 500,
        lineHeight: "14px",
        padding: "2px 8px",
        marginTop: 6,
      }}>
      {children}
    </Tag>
  );
};
const FollowTag: React.FC<TagIProps> = ({ equity, children }) => {
  return (
    <Tag
      fill="solid"
      style={{
        "--background-color": equity ? "#ffffff" : "rgba(255, 102, 0, 0.08)",
        "--text-color": "#FF6600",
        border: "none",
        fontSize: 10,
        fontWeight: 500,
        lineHeight: "14px",
        padding: "1px 4px",
        marginTop: 3,
        marginRight: 4,
        display: "inline-block",
        verticalAlign: "text-top",
      }}>
      {children}
    </Tag>
  );
};

// 活动倒计时
const CountDown: React.FC<{ remainder: number }> = ({ remainder }) => {
  const countdown = useCountdown({
    remainder: remainder > 0 ? remainder : 0,
    complete: () => {
      console.log("complete");
    },
  });
  if (!countdown) {
    return <></>;
  }
  if (countdown.days) {
    return (
      <span className={styles.taskItemCountDown}>
        <div>{countdown.days.toString().padStart(2, "0")}</div>天
        <div>{countdown.hours.toString().padStart(2, "0")}</div>时
      </span>
    );
  } else if (countdown.hours) {
    return (
      <span className={styles.taskItemCountDown}>
        <div>{countdown.hours.toString().padStart(2, "0")}</div>时
        <div>{(countdown.minutes || 0).toString().padStart(2, "0")}</div>分
      </span>
    );
  } else {
    return (
      <span className={styles.taskItemCountDown}>
        <div>{(countdown.minutes || 0).toString().padStart(2, "0")}</div>分
        <div>{(countdown.seconds || 0).toString().padStart(2, "0")}</div>秒
      </span>
    );
  }
};

// 奖励倒计时
const RewardTime: React.FC<{ remainder: number }> = ({ remainder }) => {
  const time = computedCountdownTime(remainder > 0 ? remainder : 0, "D");
  if (time.days) {
    return (
      <>
        {time.days}天{time.hours}时后
      </>
    );
  }
  if (time.hours) {
    return (
      <>
        {time.hours}时{time.minutes}分后
      </>
    );
  }
  if (time.minutes) {
    return <>00时{time.minutes}分后</>;
  }
  return <>1分钟内</>;
};

// 任务进度展示
const RewardTarget: React.FC<Pick<ActivityTaskItem, "bizType" | "actSellBatePartakeInfo">> = ({
  bizType,
  actSellBatePartakeInfo,
}) => {
  const type = actSellBatePartakeInfo?.thresholdIndicatorType;
  const textMap = {
    "FULL_RETURN-GMV": "满返目标：",
    "FULL_RETURN-SALE_QUANTITY": "件返目标：",
    "FULL_RETURN-COMMISSION": "赚返目标：",
    "FULL_RETURN-SHOP_NEW_USER_QUANTITY": "新C目标：",
    "GIFT_RETURN-GMV": "满赠目标：",
    "GIFT_RETURN-SALE_QUANTITY": "件赠目标：",
    "GIFT_RETURN-COMMISSION": "赚赠目标：",
    "GIFT_RETURN-SHOP_NEW_USER_QUANTITY": "新C目标：",
    "RANK-GMV": "PK金额目标：",
    "RANK-SALE_QUANTITY": "PK销量目标：",
    "RANK-COMMISSION": "PK省赚目标：",
    "RANK-SHOP_NEW_USER_QUANTITY": "PK新C目标：",
  };
  let current = actSellBatePartakeInfo?.currentGmv?.toString();
  let target = (
    bizType === "RANK"
      ? actSellBatePartakeInfo?.minSalesVolume
      : actSellBatePartakeInfo?.nextRewardAmount
  )?.toString();
  if (type === "SALE_QUANTITY") {
    current = `${current || 0}件`;
    target = Number(target || 0) > 0 ? `${target || 0}件` : "";
  } else if (type === "SHOP_NEW_USER_QUANTITY") {
    current = `${current || 0}人`;
    target = Number(target || 0) > 0 ? `${target || 0}人` : "";
  } else {
    current = `¥${current || 0}`;
    target = Number(target || 0) > 0 ? `¥${target}` : "";
  }
  return (
    <>
      {<div>{`进度：${current}`}</div>}
      <div>
        {target ? (
          <>
            {textMap[`${bizType}-${type}`]}
            {target}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

interface IProps {
  equity?: boolean;
  index?: number;
  data: ActivityTaskItem;
  backgroundColor?: string;
  trackParams?: any;
  isR?: boolean;
}
const ActivityTask: React.FC<IProps> = ({ equity, index, data, backgroundColor, trackParams, isR }) => {
  const track = Track();
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const { navigate } = useNavigation();

  const handleToDetail = useCallback(() => {
    track.track("resource_click", {
      page_name: window.location.pathname.includes("reward-search")
        ? "奖励中心/奖励中心搜索结果"
        : "奖励中心",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      tab_name: equity ? "权益" : "限时激励",
      resource_type: "",
      resource_name: data.name,
      resource_content: data.bizTypeName,
      resource_rank: index,
      resource_id: data.actId,
      merchant_shop_code: data.ownerType === "MERCHANT" ? data.ownerId : "",
      btn_name: "活动详情",
      ...(trackParams || {}),
    });
    // navigate({
    //   h5Options: {
    //     url: "/mx-shop-micro/reward-center/detail",
    //     query: {
    //       actId: data.actId
    //     }
    //   },
    // });
    let envid: any = window.location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    let href = `/mx-shop-micro/reward-center/detail?token=${search.get("token")}&shopId=${search.get(
      "shopId",
    )}&channel=h5Mall&actId=${data.actId}&env_identity=${envid || getCookie("X-Hades-Env-Identity")
      }`;
    if (search.get("distributorId")) {
      href += `&distributorId=${search.get("distributorId")}`;
    }
    navigater(href);
  }, [track, search, equity, index, data]);

  // 活动参与状态 10: "未报名/未参与" 20: "进行中" 30: "开奖中" 40: "已发放（已结束/已开奖）" 50: "未达标"
  const partakeStatus = data.actSellBatePartakeInfo?.partakeStatus || 10;
  // 是否参加了活动
  const isPart = partakeStatus !== 10;
  // 任务进度
  const percent = useMemo(() => {
    const a = data.actSellBatePartakeInfo?.currentGmv || 0;
    let b;
    if (data.bizType === "RANK") {
      b = data.actSellBatePartakeInfo?.minSalesVolume || 0;
    } else {
      b = data.actSellBatePartakeInfo?.nextRewardAmount || 0;
    }
    return a > b ? 100 : ((a / b) * 100).toFixed(2);
  }, [
    data.actSellBatePartakeInfo?.currentGmv,
    data.actSellBatePartakeInfo?.nextRewardAmount,
    data.bizType,
    data.actSellBatePartakeInfo?.minSalesVolume,
  ]);

  return (
    <>
      <div
        className={styles.taskItemGroup + " " + (equity ? styles.taskItemEquity : "")}
        onClick={handleToDetail}
        id={`cnt-${data.actId}`}
        data-param={JSON.stringify({
          page_name: window.location.pathname.includes("reward-search")
            ? "奖励中心/奖励中心搜索结果"
            : "奖励中心",
          previous_page_name: search.get("page_name"),
          domain: "激励活动",
          tab_name: equity ? "权益" : "限时激励",
          resource_type: "",
          resource_name: data.name,
          resource_content: data.bizTypeName,
          resource_rank: index,
          resource_id: data.actId,
          merchant_shop_code: data.ownerType === "MERCHANT" ? data.ownerId : "",
          ...(trackParams || {}),
        })}
        data-eventid={`cnt-${data.actId}`}>
        {(data.calculateReward === 1 || partakeStatus === 50) && (
          <div className={styles.taskItemTop}>很遗憾，您没有达到奖励标准，下次再接再厉吧～</div>
        )}
        {data.todayNewActivity ? <NewIcon className={styles.taskItemNewBadge} /> : ""}
        <div className={styles.taskItemBox}>
          <div
            className={styles.taskItemContent}
            style={{ backgroundColor: backgroundColor || "#fff" }}>
            <div className={styles.taskItemHeader}>
              {
                isR ? (
                  <div>
                    <div className={styles.taskItemBrandList}>
                      {data.brandLogos?.slice(0, 4)?.map((item, index) => (
                        <div key={index} className={styles.taskItemBrand}>
                          <img src={item} alt="" />
                        </div>
                      ))}
                      {(data.brandLogos?.length || 0) >= 5 && (
                        <div className={styles.taskItemBrand + " " + styles.taskItemBrandMore}>
                          <MoreIcon />
                        </div>
                      )}
                    </div>
                    <div className={styles.taskItemBrandName}>
                      {(data.brandLogos?.length || 0) > 1 ? "等品牌" : data.brandName}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={styles.taskItemBrandName}>
                      {(data.brandLogos?.length || 0) > 1 ? `${data.brandName || ""}等品牌` : data.brandName}
                    </div>
                  </div>
                )
              }
              <div>
                {/* ["NOT_BEGIN", "ADVANCE", "BEGIN"].includes(data?.aggStatus as string) */}
                {data.startTime > data.currentSysTime || data.endTime > data.currentSysTime ? (
                  <>
                    {data.startTime > data.currentSysTime ? "距活动开始" : "距活动结束"}
                    <CountDown
                      remainder={
                        data.startTime > data.currentSysTime
                          ? data.startTime - data.currentSysTime
                          : data.endTime - data.currentSysTime
                      }
                    />
                  </>
                ) : (
                  <>活动详情</>
                )}
                <div className={styles.arrowIcon}>{equity ? <ArrowIcon2 /> : <ArrowIcon />}</div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={styles.taskItemLeft}>
                {
                  isR ? (
                    <>
                      <div className={styles.taskItemCover}>
                        <img src={data.actImgUrl} alt="" />
                      </div>
                      {(data.participantCount || 0) > 0 && <div>{data.participantCount}人已参加</div>}
                    </>
                  ) : (
                    <div className={styles["logo-list"]}>
                      {(data.brandLogos || []).slice(0, 3).map((item, index) => {
                        return (
                          <img
                            className={styles["item"]}
                            key={index}
                            style={{ marginLeft: `${pxtorem(index === 0 ? 0 : -38)}` }}
                            src={item}
                          />
                        );
                      })}
                    </div>
                  )
                }
              </div>
              <div className={styles.taskItemRight}>
                <div className={styles.taskItemInfo}>
                  <div className={styles.taskItemTitle}>
                    {data.ownerType === "PLATFORM" && (
                      <TaskTag equity={equity} type="welfare">
                        平台福利
                      </TaskTag>
                    )}
                    {data.isFollow ? <FollowTag equity={equity}>已关注</FollowTag> : ""}
                    {data.name}
                  </div>
                  <div className={styles.taskItemDesc}>{data.actDesc}</div>
                  {/* 已参加 & 进行中 展示任务进度 */}
                  {isPart && (partakeStatus === 20 || data.aggStatus === "BEGIN") && (
                    <div className={styles.taskItemProgress}>
                      <div className={styles.taskItemProgressTitle}>业绩目标：</div>
                      <ProgressBar
                        percent={Number(percent || 0)}
                        style={{
                          "--track-width": "4px",
                          "--track-color": "rgba(255, 71, 71, 0.16)",
                          "--fill-color": "#FF4747",
                        }}
                      />
                      <div className={styles.taskItemProgressInfo}>
                        <RewardTarget
                          bizType={data.bizType}
                          actSellBatePartakeInfo={data.actSellBatePartakeInfo}
                        />
                      </div>
                    </div>
                  )}
                  {/* 已参加 & 开奖中 展示预估奖励 */}
                  {isPart && partakeStatus === 30 && (
                    <div className={styles.taskItemReward}>
                      <div>
                        <MoneyIcon /> {isR ? "店铺预估奖励" : "预估奖励"}：
                        {data.bizType === "GIFT_RETURN"
                          ? (data?.actSellBatePartakeInfo?.expectedAwardAmount || 0) > 0 &&
                            data?.actSellBatePartakeInfo?.giftName
                            ? `${data?.actSellBatePartakeInfo?.giftName}*${data?.actSellBatePartakeInfo?.expectedAwardAmount}`
                            : "无"
                          : `${data?.actSellBatePartakeInfo?.expectedAwardAmount || 0}元`}
                      </div>
                      <TaskTag>
                        预计
                        <RewardTime remainder={data.rewardDistributionTime - data.currentSysTime} />
                        开奖
                      </TaskTag>
                    </div>
                  )}
                  {/* 已参加 & 已开奖&已中奖 展示奖励发放 */}
                  {isPart && partakeStatus === 40 && data.calculateReward === 2 && (
                    <div className={styles.taskItemReward}>
                      <div>
                        <MoneyIcon /> 奖励发放：
                        {data.bizType === "GIFT_RETURN"
                          ? (data?.actSellBatePartakeInfo?.expectedAwardAmount || 0) > 0 &&
                            data?.actSellBatePartakeInfo?.giftName
                            ? `${data?.actSellBatePartakeInfo?.giftName}*${data?.actSellBatePartakeInfo?.expectedAwardAmount}`
                            : "无"
                          : `${data?.actSellBatePartakeInfo?.expectedAwardAmount || 0}元`}
                      </div>
                    </div>
                  )}
                </div>
                {/* 未参加 || 未达标 || 未中奖 || 已开奖(奖励结算中/未中奖) 展示按钮*/}
                {(!isPart ||
                  data.calculateReward === 1 ||
                  partakeStatus === 50 ||
                  (partakeStatus === 40 && [1, 3].includes(data.calculateReward as number))) && (
                    <TaskButton
                      equity={equity}
                      isPart={isPart}
                      status={data.aggStatus}
                      calculateReward={data.calculateReward}></TaskButton>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ActivityTask;
