import { platform } from "@/utils/platform";
import { CenterPopup } from "antd-mobile";
import React from "react";
import styles from "./PopForSign.module.less";

const PopForSign: React.FC<{
  visible: boolean;
  setVisible: any;
  handleSignIn: any;
}> = ({ visible, setVisible, handleSignIn }) => {
  return (
    <div className={styles.container}>
      <CenterPopup
        visible={visible}
        getContainer={() => document.body}
        bodyStyle={{
          backgroundColor: "transparent",
        }}
        style={{ zIndex: 300 }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}>
        <div className={styles.wrap}>
          <div className={styles.popBgImg}></div>
          <div className={styles.btn} onClick={handleSignIn}></div>
          <div className={styles.closeBtn} onClick={() => setVisible(false)}></div>
        </div>
      </CenterPopup>
    </div>
  );
};

export default PopForSign;
