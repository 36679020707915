import { useCommonContext } from "@/utils/context/common";

export const useTrack = (pageName: string) => {
  const { track } = useCommonContext();
  //PV
  const trackPageView = (params?: { previousPageName?: string | null }) => {
    try {
      if (track.track) {
        track.track("$pageview", {
          page_name: pageName,
          previous_page_name: params?.previousPageName,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  //交易快照-点击
  const trackSnapshotClick = (params: { previousPageName?: string | null }) => {
    try {
      if (track.track) {
        const trackParams = {
          page_name: pageName,
          previous_page_name: params?.previousPageName,
          btn_name: "交易快照",
          domain: "交易快照",
        };
        track.track("resource_click", trackParams);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    trackSnapshotClick,
    trackPageView,
  };
};
