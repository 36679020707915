import { ReactComponent as ArrowRightIcon } from "@/assets/arrow_right.svg";
import { ReactComponent as CloseIcon } from "@/assets/close.svg";
import { platform } from "@/utils/platform";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { DotLoading, Image, ImageViewer, Popup, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SkuTag from "./components/skuTag";
import Stepper from "./components/stepper";
import styles from "./index.module.less";

// import "./index.less";
import {
  addToCartReq,
  batchReceiveCoupon,
  buyMore,
  queryProductPrice,
  querySpuSalePropertyVO,
} from "@/api/add-to-cart";
import MxPrice from "./components/mxPrice";

export enum BottomButtonStyle {
  cart = 2, // 2. 加入购物车
  buyNow = 3, // 3. 立即购买
}

interface AddToCartModalProps {
  product?: any;
  visible?: boolean;
  showFirstPicker?: boolean;
  showSkuNum?: boolean;
  allowDeliver?: true;
  promoActivityId?: string;
  awdInstanceId?: string;
  ownerId?: string;
  productSource?: string;
  liveNo?: string;
  lockScroll?: boolean;
  autoClose?: boolean;
  pageType?: 1 | 2 | 3 | 4 | 5; //1: "种草社区_推荐", 2: "种草详情",3: "实拍专区-图文",4: "实拍专区-视频" 5: "素材商品详情页",
  buttonStyle?: BottomButtonStyle; //按钮类型
  buttonText?: string; //按钮文案
  confirm?: (params: { type: string; skuId: string }) => void;
  close?: (params?: { skuId?: string }) => void;
}
interface ProductInfoProps {
  marketingPrice: number;
  tagPrice?: number;
  title?: string;
  promotionTypeName?: string;
  creditAmount?: number;
  rewardCreditAmount?: number;
  commission?: number;
  commissionAddition?: number;
  skuQuantity?: number;
  minQuantity?: number;
  maxQuantity?: number;
  availablePromoQuantity?: number;
  availableQuantity?: number;
  skuAdvancePriceInfo?: ProductInfoProps;
}
interface PromoCouponInfoProps {
  skuTotalPostPrice?: number;
  creditAmount?: number;
  discountList: Array<any>;
  toDrawAwardList: Array<any>;
}
interface PropListItemProps {
  name?: string;
  value?: string;
  sellOut?: boolean;
  disabled?: boolean;
  picList?: Array<string>;
}
interface SalePropertySkuIdInfoProps {
  sellOut?: boolean;
  skuId?: string;
  firstPropertyValue?: string;
  secondPropertyValue?: string;
  levelTwoProperty?: string;
}
interface SpuVoListRequestProps {
  activityId: string;
  activitySpuId: string;
  firstPropertyValue: string;
  needSaleProperty: boolean;
  needSalePropertySkuId: boolean;
  promoActivityIds?: Array<string>;
  awdInstanceId?: string;
  sourceScene?: string;
}

const AddToCartModal: React.FC<AddToCartModalProps> = ({
  product = {},
  visible = false,
  lockScroll = true,
  showFirstPicker = true,
  showSkuNum = true,
  allowDeliver = true,
  promoActivityId = "",
  awdInstanceId = "",
  productSource = "",
  liveNo = "",
  ownerId = "",
  autoClose = true,
  confirm,
  close,
  pageType,
  buttonStyle,
  buttonText,
}) => {
  const { navigate2OrderConfrim } = useNavigation();
  const track = Track();
  let lastLevel = 2;
  const [skuMainImageUrl, setSkuMainImageUrl] = useState(
    "https://akim-oss.aikucun.com/4a8f162524aa4178c173ed635fad284a261201ec_1695111298526_4.png",
  );
  const tempProductInfo = {
    marketingPrice: 0,
    title: "",
    promotionTypeName: "",
    tagPrice: 0,
    creditAmount: 0,
    rewardCreditAmount: 0, // 纯c积分
    commission: 0, // 店长佣金
    commissionAddition: 0, // 佣金加成
    skuQuantity: 0,
    availableQuantity: 0,
  };
  const tempPromoCouponInfo = {
    skuTotalPostPrice: 0,
    creditAmount: 0,
    discountList: [],
    toDrawAwardList: [],
  };
  const commissionAdditionPrefix = platform.isApp
    ? "省赚"
    : platform.isTuan
      ? "推广费"
      : platform.isMini && !platform.isSaas
        ? "饷"
        : "赚";
  const pageNameObj = {
    1: "种草社区_推荐",
    2: "种草详情",
    3: "实拍专区-图文",
    4: "实拍专区-视频",
    5: "素材商品详情页",
  };
  const domainObj = {
    1: "种草",
    2: "种草",
    3: "实拍专区",
    4: "实拍专区",
    5: "",
  };
  const [search] = useSearchParams();
  const [selloutText, setSelloutText] = useState("暂时缺货");
  const [productPicList, setProductPicList] = useState<Array<string>>([]);
  const [preViewProductImageVisible, setPreViewProductImageVisible] = useState(false);
  const [productInfo, setProductInfo] = useState<ProductInfoProps>(tempProductInfo);
  const [promoCouponInfo, setPromoCouponInfo] = useState<PromoCouponInfoProps>(tempPromoCouponInfo);
  const [levelOnePropertyList, setLevelOnePropertyList] = useState<Array<PropListItemProps>>([]); // 一级属性列表
  const [levelTwoPropertyList, setLevelTwoPropertyList] = useState<Array<PropListItemProps>>([]); // 二级属性列表
  const [showLevelOneAllProperty, setShowLevelOneAllProperty] = useState(false); // 是否展示全部一级属性
  const [showLevelTwoAllProperty, setShowLevelTwoAllProperty] = useState(false); // 是否展示全部二级属性
  const [selectedLevelOnePropertyIndex, setSelectedLevelOnePropertyIndex] = useState(0); // 当前选中的一级属性
  const [selectedLevelTwoPropertyIndex, setSelectedLevelTwoPropertyIndex] = useState(0); // 当前选中的二级属性
  const [levelOnePropertyHasSellout, setLevelOnePropertyHasSellout] = useState(false); // 是否展示售罄
  const [levelTwoPropertyHasSellout, setLevelTwoPropertyHasSellout] = useState(false); // 是否展示售罄
  const [minQuantity, setMinQuantity] = useState(1); // 起购数量
  const [maxQuantity, setMaxQuantity] = useState(200); //   最大购买数量
  const [perchaseQuantity, setPerchaseQuantity] = useState(1);
  const [requireMinimum, setRequireMinimum] = useState(false); // 是否起购 大于1表示起购
  const [requireLimit, setRequireLimit] = useState(false);
  const [requireAdLimit, setRequireAdLimit] = useState(false); // 是否限购 -1无限购  0限购额度用完  >1限购
  const [showRemainQuality, setShowRemainQuality] = useState(false); //sku库存小于仅剩阈值展示展示仅剩几件
  const [minimumLessThanQuality, setMinimumLessThanQuality] = useState(false); // 起购 库存小于起订数量
  const [limitUseUp, setLimitUseUp] = useState(false); // 额度用完
  const [hasNx, setHasNx] = useState(false);
  const [selectedSku, setSelectedSku] = useState<SalePropertySkuIdInfoProps>({}); // 选中的sku
  const [rendered, setRendered] = useState(false);
  const [oldProduct, setOldProduct] = useState<any>({});
  const cache: Map<string, any> = new Map();
  const onClose = () => {
    if (lockScroll) {
      document.body.style.overflowY = "auto";
    }
    setRendered(false);
    close && close({ skuId: selectedSku?.skuId });
  };
  const sortProperty = (a, b) => {
    if (!a.sellOut && b.sellOut) {
      return -1;
    } else if (a.sellOut && !b.sellOut) {
      return 1;
    } else {
      return 0;
    }
  };
  const getStepperMin = res => {
    let minQuantity = res.minQuantity;
    if (res?.minQuantity > 1 && res?.skuQuantity < res?.minQuantity) {
      minQuantity = (productInfo as any)?.initQuantity;
    }
    return minQuantity;
  };
  const getStepperMax = res => {
    // 最大限购数量 res.maxQuantity <0 无限购(取sku库存：skuQuantity)  >0 限购(取后端返回的限购数量：maxQuantity)
    let maxQuantity = res.maxQuantity < 0 ? res.skuQuantity : res.maxQuantity;
    if (showRemainQuality) {
      // 限购且额度没用完，最大限购数量取（库存和最大限购数量中的小值；如果限购额度用完或者无限购，最大购买数量取库存
      maxQuantity =
        res.maxQuantity > 0
          ? Math.min((productInfo as any).skuQuantity, res.maxQuantity)
          : res.skuQuantity;
    }
    return maxQuantity;
  };
  const handleOverlimitPrice = res => {
    setShowRemainQuality(res?.skuQuantity <= res?.skuQuantityMin); //sku库存小于仅剩阈值展示展示仅剩几件
    setRequireMinimum(res?.minQuantity > 1); // 是否起购 大于1表示起购
    setMinimumLessThanQuality(res?.skuQuantity < res?.minQuantity); // 库存小于起订数量
    setRequireLimit(res?.maxQuantity >= 0); //是否限购 -1无限购  >=0 表示限购（0限购额度用完  >1限购）
    setLimitUseUp(res?.maxQuantity === 0); // 0限购额度用完
    setRequireAdLimit(res.availablePromoQuantity >= 0); // 营销限购
    setPerchaseQuantity(res.initQuantity); //步进器输入框的数量
    setMaxQuantity(getStepperMax(res)); // 步进器输入框的数量最大值
    setMinQuantity(getStepperMin(res)); // 步进器输入框的数量最小值
  };
  const adaptLevelOneProperty = (item, isLastLevel, salePropertySkuIdInfoVOList) => {
    item.levelOneProperty = item.value;
    if (isLastLevel) {
      item.skuId = salePropertySkuIdInfoVOList.find(
        i => i.firstPropertyValue === item.value,
      )?.skuId;
    }
  };
  const adaptLevelTwoProperty = (item, salePropertySkuIdInfoVOList) => {
    const skuIdVOItem = salePropertySkuIdInfoVOList.find(i => i.secondPropertyValue === item.value);
    if (skuIdVOItem) {
      item.sellOut = skuIdVOItem.sellOut;
      item.skuId = skuIdVOItem.skuId;
      item.levelOneProperty = skuIdVOItem.firstPropertyValue;
      item.levelTwoProperty = skuIdVOItem.secondPropertyValue;
    } else {
      item.disabled = true;
    }
  };
  const querySpuSaleProperty = async levelOneProperty => {
    if (cache.get(levelOneProperty)) {
      return cache.get(levelOneProperty);
    }
    const params: SpuVoListRequestProps = {
      activityId: product.activityId,
      activitySpuId: product.activitySpuId,
      firstPropertyValue: levelOneProperty,
      needSaleProperty: true,
      needSalePropertySkuId: true,
    };
    if (promoActivityId) {
      params.promoActivityIds = [promoActivityId];
      params.sourceScene = "JOIN_ORDER_PAGE";
    }
    if (awdInstanceId) {
      params.awdInstanceId = awdInstanceId;
    }
    try {
      const res = await querySpuSalePropertyVO(params);
      cache.set(
        levelOneProperty,
        res?.data || {
          salePropertyFirstList: [],
          salePropertySecondList: [],
          salePropertySkuIdInfoVOList: [],
        },
      );
      return (
        res?.data || {
          salePropertyFirstList: [],
          salePropertySecondList: [],
          salePropertySkuIdInfoVOList: [],
        }
      );
    } catch (e) {
      console.error(e);
      return {
        salePropertyFirstList: [],
        salePropertySecondList: [],
        salePropertySkuIdInfoVOList: [],
      };
    }
  };
  const handleObserveSelectedSku = async selectedSku => {
    if (selectedSku && selectedSku.skuId) {
      try {
        const params = {
          queryAvailableQuantity: true,
          activityId: product.activityId,
          activitySpuId: product.activitySpuId,
          selectSkuId: selectedSku.skuId,
          ownerId: ownerId || "",
        };
        const res = await queryProductPrice(params);
        // 处理商品
        if (res && (res as any)?.success && res?.data) {
          setProductInfo(res.data ?? tempProductInfo);
          setPromoCouponInfo(res.data?.promoCouponInfo ?? tempPromoCouponInfo);
        }
        // 一级属性展开过就不隐藏
        !showLevelOneAllProperty ? setShowLevelOneAllProperty(selectedSku.sellOut) : void 0;
        // 二级级属性展开过就不隐藏
        !showLevelTwoAllProperty ? setShowLevelTwoAllProperty(selectedSku.sellOut) : void 0;
        // 处理价格(包含限购限购)
        handleOverlimitPrice(res?.data);
        setRendered(true);
      } catch (error) {
        setRendered(true); // 商品渲染完成
        console.error(error);
      }
    }
  };
  const handleObserveTwoIndex = (index, levelTwoPropertyList) => {
    if (levelTwoPropertyList.length) {
      if (levelTwoPropertyList[index]?.sellOut && !showLevelTwoAllProperty) {
        setSelectedSku(levelTwoPropertyList[index]);
        setShowLevelTwoAllProperty(true);
        handleObserveSelectedSku(levelTwoPropertyList[index]);
      } else {
        setSelectedSku(levelTwoPropertyList[index]);
        handleObserveSelectedSku(levelTwoPropertyList[index]);
      }
    }
  };
  const handleObserveOneIndex = async (index, levelOnePropertyList) => {
    try {
      // 如果一级属性是最后一级属性，则直接设置selectedSku
      if (!levelOnePropertyList.length) {
        return;
      }
      if (lastLevel === 1) {
        const response = await querySpuSaleProperty(levelOnePropertyList[index]?.value);
        const { salePropertyFirstList, salePropertySkuIdInfoVOList, salePropertySecondList } =
          response;
        salePropertyFirstList.forEach(item =>
          adaptLevelOneProperty(
            item,
            salePropertySecondList?.length > 0 ? false : true,
            salePropertySkuIdInfoVOList,
          ),
        );
        const levelOnePropertyListTemp = salePropertyFirstList.sort(sortProperty);
        setLevelOnePropertyList(levelOnePropertyListTemp);
        setSelectedSku(levelOnePropertyList[index]);
        setLevelTwoPropertyList([]); // 只有一级属性时，二级属性置空
        setSkuMainImageUrl(
          (levelOnePropertyList[index].picList?.length &&
            (levelOnePropertyList[index].picList as Array<string>)[0]) ||
            "",
        );
        handleObserveSelectedSku(levelOnePropertyList[index]);
        return;
      }
      const res = await querySpuSaleProperty((levelOnePropertyList[index] as any).value);
      const { salePropertySecondList, salePropertySkuIdInfoVOList } = res ?? {};
      salePropertySecondList?.forEach(item =>
        adaptLevelTwoProperty(item, salePropertySkuIdInfoVOList),
      );
      const levelTwoPropertyList = salePropertySecondList
        ?.filter(item => !item.disabled)
        .sort(sortProperty);
      let defaultIndex;
      if (selectedSku.skuId) {
        // 取上次选中的二级属性
        defaultIndex = levelTwoPropertyList.findIndex(
          i => i.value === selectedSku.levelTwoProperty,
        );
        defaultIndex = defaultIndex < 0 ? 0 : defaultIndex;
      } else {
        // 初始取商品默认SKU
        const defaultSku =
          (salePropertySkuIdInfoVOList.length &&
            salePropertySkuIdInfoVOList.find(it => it.skuId === product.selectSkuId)) ||
          salePropertySkuIdInfoVOList[0];
        defaultIndex = levelTwoPropertyList.findIndex(
          item => item.value === defaultSku.secondPropertyValue,
        );
      }
      setSkuMainImageUrl(
        levelOnePropertyList[index]?.picList?.length
          ? (levelOnePropertyList[index].picList as any)[0]
          : "",
      );
      setLevelTwoPropertyList(levelTwoPropertyList);
      setSelectedLevelTwoPropertyIndex(defaultIndex);
      setLevelTwoPropertyHasSellout(levelTwoPropertyList.findIndex(i => i.sellOut) >= 0);
      setShowLevelTwoAllProperty(false);
      handleObserveTwoIndex(defaultIndex, levelTwoPropertyList);
    } catch (error) {
      console.log("catcherr", error);
    }
  };
  const handleObserveProduct = async product => {
    const firstPropertyValue =
      product?.firstPropertyValue || product?.skuBaseInfoVO?.firstSalePropertyValue;
    if (!firstPropertyValue) {
      return console.error("firstPropertyValue为空");
    }
    const res = await querySpuSaleProperty(firstPropertyValue);
    const { salePropertyFirstList, salePropertySecondList, salePropertySkuIdInfoVOList } =
      res ?? {};
    lastLevel = 2;
    if (!salePropertySecondList || salePropertySecondList.length === 0) {
      lastLevel = 1;
    }
    salePropertyFirstList.forEach(item =>
      adaptLevelOneProperty(
        item,
        salePropertySecondList?.length > 0 ? false : true,
        salePropertySkuIdInfoVOList,
      ),
    );
    const levelOnePropertyList = salePropertyFirstList.sort(sortProperty);
    const defaultSku = salePropertySkuIdInfoVOList?.length
      ? salePropertySkuIdInfoVOList.find(it => it.skuId === product.selectSkuId) ||
        salePropertySkuIdInfoVOList[0]
      : {};
    let selectedLevelOnePropertyIndex = levelOnePropertyList.findIndex(
      item => item.value === defaultSku.firstPropertyValue,
    );
    selectedLevelOnePropertyIndex =
      selectedLevelOnePropertyIndex < 0 ? 0 : selectedLevelOnePropertyIndex;
    setLevelOnePropertyList(levelOnePropertyList);
    setSelectedLevelOnePropertyIndex(selectedLevelOnePropertyIndex);
    setLevelOnePropertyHasSellout(levelOnePropertyList.findIndex(i => i.sellOut) >= 0);
    setShowLevelOneAllProperty(false);
    handleObserveOneIndex(selectedLevelOnePropertyIndex, levelOnePropertyList);
  };
  const handleLimitValidate = () => {
    let flag = false;
    if (minimumLessThanQuality) {
      flag = true;
    }
    // 起购限制 || 限购限制 || 营销限制 库存统一限制
    if (requireMinimum || requireLimit || requireAdLimit) {
      if (perchaseQuantity > (productInfo?.skuQuantity || 0)) {
        Toast.show({
          content: "商品库存不足,请重新选择数量",
          maskClickable: false,
          position: "bottom",
          maskStyle: { zIndex: 100001 },
        });
        flag = true;
      }
    }
    // 限购限制 限购数量用完
    if (requireLimit && limitUseUp) {
      Toast.show({
        content: "抱歉，你已无商品购买额度",
        maskClickable: false,
        position: "bottom",
        maskStyle: { zIndex: 100001 },
      });
      flag = true;
    }
    return flag;
  };
  const onClickLevelOneProperty = (item, index) => {
    if (index === selectedLevelOnePropertyIndex) {
      return;
    }
    handleObserveOneIndex(index, levelOnePropertyList);
    setSelectedLevelOnePropertyIndex(index);
  };
  const onClickLevelTwoProperty = (item, index) => {
    if (index === selectedLevelTwoPropertyIndex) {
      return;
    }
    handleObserveTwoIndex(index, levelTwoPropertyList);
    setSelectedLevelTwoPropertyIndex(index);
  };
  const handleShowLevelOneAllProperty = () => {
    setShowLevelOneAllProperty(true);
  };
  const handleShowLevelTwoAllProperty = () => {
    setShowLevelTwoAllProperty(true);
  };
  const addQuanity = e => {
    if (requireLimit) {
      if (e > (productInfo as any)?.availableQuantity) {
        Toast.show({
          content: `限购商品，最多购买 ${productInfo?.availableQuantity} 件哦～`,
          maskClickable: false,
          position: "bottom",
          maskStyle: { zIndex: 100001 },
        });
        return;
      }
    } else if (requireMinimum) {
      if (e > (productInfo as any)?.skuQuantity) {
        Toast.show({
          content: "商品库存不足",
          maskClickable: false,
          position: "bottom",
          maskStyle: { zIndex: 100001 },
        });
        return;
      }
    }
    setPerchaseQuantity(e);
    const params = {
      activityId: product.activityId,
      activitySpuId: product.activitySpuId,
      queryAvailableQuantity: true,
      selectSkuId: selectedSku.skuId || "",
      productQuantity: Number(e),
    };
    queryProductPrice(params).then(res => {
      setPromoCouponInfo(res?.data?.promoCouponInfo ?? tempPromoCouponInfo);
    });
  };
  const handleOverlimit = e => {
    if (e.type === "plus") {
      let toastText = "商品库存不足";
      if (requireLimit) {
        toastText = product?.skuExtendInfoVO?.onlyDirectModel
          ? "直购商品，单次最多购买一件，可以重复购买哦～"
          : `限购商品，最多购买 ${productInfo?.availableQuantity} 件哦～`;
        if (e.value < (productInfo as any)?.availableQuantity) {
          toastText = "商品库存不足";
        }
      }
      Toast.show({
        content: toastText,
        maskClickable: false,
        position: "bottom",
        maskStyle: { zIndex: 100001 },
      });
    }
  };
  const stillBuy = () => {
    buyMore({ activityId: product.activityId, activitySpuId: product.activitySpuId }).then(() => {
      Toast.show({
        content: "你的购买意愿已通知商家",
        maskClickable: false,
        position: "bottom",
        maskStyle: { zIndex: 100001 },
      });
    });
    track.track("btn_click", {
      page_name: pageType ? pageNameObj[pageType] : document.title,
      module: "规格选择弹窗",
      btn_name: "还想买",
      spu_id: product.activitySpuId,
      sku_id: product.selectSkuId,
      click_sku_id: selectedSku.skuId,
      product_name: product?.skuBaseInfoVO?.title,
      brand_id: product?.brandInfo?.brandId,
      brand_name: product?.brandInfo?.brandName,
      activity_id: product.activityId,
      activity_name: product?.activitySimpleVO?.name,
      activity_spu_code: product?.activitySpuId,
      product_model: product?.activityType === 4 ? "普通商品" : "档期商品",
      btn_text: "还想买",
    });
  };
  const addToCart = async () => {
    if (!product?.skuExtendInfoVO?.buyShoppingCartModel) {
      return;
    }
    if (!allowDeliver) {
      return;
    }
    if (handleLimitValidate()) {
      return;
    }
    if (product?.skuExtendInfoVO?.isVirtual) {
      Toast.show({
        content: "亲~该场活动还未开始，暂不支持购买",
        maskClickable: false,
        position: "bottom",
        maskStyle: { zIndex: 100001 },
      });
      return;
    }
    const params: {
      skuNo: string;
      quantity: number;
      activityNo: string;
      spuId: string;
      productSource?: string;
      liveRoomNo?: string;
    } = {
      skuNo: (selectedSku as any).skuId || "", // 修改为根据属性映射得的skuId
      quantity: perchaseQuantity,
      activityNo: product.activityId,
      spuId: product.activitySpuId,
    };
    productSource && (params.productSource = productSource);
    liveNo && (params.liveRoomNo = liveNo);
    const res = await addToCartReq({
      skus: [{ ...params }],
      // shopBizCode: "WO8iAADm23J" || search.get("shopId") || "",
      shopBizCode: search.get("shopId") || "",
    });
    if (res && (res as any)?.success) {
      Toast.show({
        content: "添加成功，商品在购物车等亲~",
        maskClickable: false,
        position: "bottom",
        maskStyle: { zIndex: 100001 },
      });
      confirm && confirm({ type: "addCart", skuId: selectedSku.skuId || "" });
      if (autoClose) {
        onClose();
      }
    } else {
      Toast.show({
        content: (res as any).message || "添加失败，请稍后重试～",
        maskClickable: false,
        position: "bottom",
        maskStyle: { zIndex: 100001 },
      });
    }
    track.track("add_to_cart", {
      page_name: pageType ? pageNameObj[pageType] : document.title,
      domain: pageType && domainObj?.[pageType]?.length > 0 ? domainObj[pageType] : undefined,
      module: "规格选择弹窗",
      activity_spu_code: product.activitySpuId,
      sku_id: product.selectSkuId,
      click_sku_id: selectedSku.skuId,
      add_cart_amount: perchaseQuantity,
      product_price: productInfo.marketingPrice,
      product_name: product?.skuBaseInfoVO?.title,
      product_model: product?.activityType === 4 ? "普通商品" : "档期商品",
      brand_id: product?.brandInfo?.brandId,
      brand_name: product?.brandInfo?.brandName,
      desc: "加入购物车",
    });
    track.track("btn_click", {
      page_name: pageType ? pageNameObj[pageType] : document.title,
      domain: pageType && domainObj?.[pageType]?.length > 0 ? domainObj[pageType] : undefined,
      module: "规格选择弹窗",
      btn_name: "加入购物车",
      spu_id: product.activitySpuId,
      sku_id: product.selectSkuId,
      click_sku_id: selectedSku.skuId,
      product_name: product?.skuBaseInfoVO?.title,
      brand_id: product?.brandInfo?.brandId,
      brand_name: product?.brandInfo?.brandName,
      activity_id: product?.activityId,
      activity_name: product?.activitySimpleVO?.name,
      activity_spu_code: product.activitySpuId,
      product_model: product?.activityType === 4 ? "普通商品" : "档期商品",
      btn_text: "加入购物车",
      nums: perchaseQuantity,
      price: productInfo.marketingPrice,
    });
  };
  const buyNow = async () => {
    if (!allowDeliver) {
      return;
    }
    const skuNo = selectedSku.skuId;
    const quantity = perchaseQuantity;
    const activityNo = product.activityId;
    const spuId = product.activitySpuId;
    const pricePanelType = product.pricePanelType;
    const virtualRechargeType = product?.skuExtendInfoVO?.virtualRechargeType || "";
    if (handleLimitValidate()) {
      return;
    }
    if (pricePanelType === 13) {
      navigate2OrderConfrim({
        miniQuery: {
          skuNo: skuNo,
          quantity,
          virtualRechargeType: virtualRechargeType,
          activityNo: activityNo,
          spuId: spuId,
        },
        h5Query: {
          skuNo,
          num: perchaseQuantity,
          directcChargeType: virtualRechargeType,
          activityNo,
          spuId,
          liveRoomNo: search.get("liveNo") || "",
          isBusinessVenue: search.get("isBusinessVenue") || "",
        },
      });
    } else {
      if (promoCouponInfo?.toDrawAwardList?.length) {
        const response: any = await batchReceiveCoupon({
          couponByIdReqList: promoCouponInfo?.toDrawAwardList,
        });
        if (response && response?.success && response?.code === "00000") {
          Toast.show({
            content: `已帮您自动领取${response.data}张优惠券，下单更省钱`,
            maskClickable: false,
            position: "bottom",
            maskStyle: { zIndex: 100001 },
          });
        } else {
          Toast.show({
            content: response.message || "领取失败，请稍后重试～",
            maskClickable: false,
            position: "bottom",
            maskStyle: { zIndex: 100001 },
          });
        }
        setTimeout(() => {
          track.track("pick_up_coupon", {
            page_name: pageType ? pageNameObj[pageType] : document.title,
            btn_name: "立即购买",
            is_manual: "否",
            coupons_id: promoCouponInfo?.toDrawAwardList.map(obj => obj.couponId).join(","),
            coupon_amount_auto: promoCouponInfo?.toDrawAwardList
              .map(obj => obj.couponAmount)
              .join(","),
            sku_id: selectedSku.skuId,
            activity_spu_code: product.activitySpuId,
            domain:
              pageType && domainObj?.[pageType]?.length > 0 ? domainObj[pageType] : document.title,
            // page_name: document.title,
            // domain: pageSource === 1 ? "种草" : document.title,
          });
          confirm && confirm({ type: "buyNow", skuId: selectedSku.skuId || "" });
          if (autoClose) {
            onClose();
          }
          navigate2OrderConfrim({
            miniQuery: {
              skuNo: skuNo,
              quantity: perchaseQuantity,
              virtualRechargeType: virtualRechargeType,
              activityNo: activityNo,
              spuId: spuId,
            },
            h5Query: {
              skuNo,
              num: perchaseQuantity,
              directcChargeType: virtualRechargeType,
              activityNo,
              spuId,
              liveRoomNo: search.get("liveNo") || "",
              isBusinessVenue: search.get("isBusinessVenue") || "",
            },
          });
        }, 500);
      } else {
        if (autoClose) {
          onClose();
        }
        confirm && confirm({ type: "buyNow", skuId: selectedSku.skuId || "" });
        navigate2OrderConfrim({
          miniQuery: {
            skuNo: skuNo,
            quantity: perchaseQuantity,
            virtualRechargeType: virtualRechargeType,
            activityNo: activityNo,
            spuId: spuId,
          },
          h5Query: {
            skuNo,
            num: perchaseQuantity,
            directcChargeType: virtualRechargeType,
            activityNo,
            spuId,
            liveRoomNo: search.get("liveNo") || "",
            isBusinessVenue: search.get("isBusinessVenue") || "",
          },
        });
        track.track("btn_click", {
          page_name: pageType ? pageNameObj[pageType] : document.title,
          module: pageType ? "规格弹框" : "规格选择弹窗",
          domain: pageType && domainObj?.[pageType]?.length > 0 ? domainObj[pageType] : undefined,
          btn_name: "立即购买",
          spu_id: product.activitySpuId,
          sku_id: product.selectSkuId,
          click_sku_id: selectedSku.skuId,
          product_name: product?.skuBaseInfoVO?.title,
          brand_id: product?.brandInfo?.brandId,
          brand_name: product?.brandInfo?.brandName,
          activity_id: product?.activityId,
          activity_name: product?.activitySimpleVO?.name,
          activity_spu_code: product?.activitySpuId,
          product_model: product.activityType === 4 ? "普通商品" : "档期商品",
          btn_text: "立即购买",
          nums: perchaseQuantity,
          price: productInfo.marketingPrice,
        });
      }
    }
  };
  const onPreviewProductImg = () => {
    setProductPicList((levelOnePropertyList[selectedLevelOnePropertyIndex] as any).picList);
    setPreViewProductImageVisible(true);
  };
  const onPreviewSizeImg = () => {
    setProductPicList([product?.spuInfoVO?.sizeUrl]);
    setPreViewProductImageVisible(true);
    track.track("btn_click", {
      page_name: pageType ? pageNameObj[pageType] : document.title,
      module: "规格选择弹窗",
      btn_name: "查看尺码表",
      spu_id: product.activitySpuId,
      sku_id: selectedSku.skuId,
      product_name: product?.skuBaseInfoVO?.title,
      brand_id: product?.brandInfo?.brandId,
      brand_name: product?.brandInfo?.brandName,
      activity_id: product?.activityId,
      activity_name: (product.activitySimpleVO && product.activitySimpleVO.name) || "",
      activity_spu_code: product.activitySpuId,
      product_model: product.activityType === 4 ? "普通商品" : "档期商品",
      btn_text: "查看尺码表",
    });
  };
  // 展示弹框
  useEffect(() => {
    if (visible) {
      if (lockScroll) {
        document.body.style.overflowY = "hidden";
      }
      if (product?.activitySpuId === oldProduct?.activitySpuId) {
        setRendered(true);
      }
      // 设置显示售罄的文案
      setSelloutText(selloutText);
    }
  }, [visible]);
  // 监听传入的商品
  useEffect(() => {
    if (!product || Object.keys(product).length === 0) {
      return;
    }
    setSelectedSku({});
    handleObserveProduct(product);
    setOldProduct(product);
  }, [product]);
  useEffect(() => {
    if (promoCouponInfo?.discountList?.length) {
      setHasNx(
        promoCouponInfo.discountList.filter(
          item =>
            item.marketingToolsType === "N_PIECES_X_CNY" ||
            item.marketingToolsType === "N_PIECES_X_DISCOUNT",
        ).length > 0,
      );
    }
  }, [promoCouponInfo]);
  return (
    <React.Fragment>
      <Popup
        style={{ "--z-index": "2000" }}
        maskStyle={{ zIndex: "1999" }}
        visible={visible}
        onMaskClick={onClose}
        className={styles["size-select-model-wrapper"]}>
        {rendered ? (
          <div className={styles["size-select-model-box"]}>
            <CloseIcon className={styles["close-icon"]} onClick={onClose} />
            {/* 商品属性 */}
            <div className={styles["sku-intro-wrapper"]}>
              <Image
                className={styles["img"]}
                onClick={onPreviewProductImg}
                src={skuMainImageUrl}
              />
              {productInfo.marketingPrice > 0 && (
                <div className={styles["intro-wrapper-content"]}>
                  <div className={styles["content-top"]}>{productInfo.title}</div>
                  <div className={styles["content-bottom"]}>
                    {product.pricePanelType !== 13 ? (
                      <MxPrice
                        product={productInfo}
                        promoCouponInfo={promoCouponInfo}
                        hasNx={hasNx}
                      />
                    ) : (
                      // <div className={styles["price-wrapper"]}>
                      //   <div className={styles["special-price"]}>
                      //     {productInfo.promotionTypeName}
                      //   </div>
                      //   <Price
                      //     price={productInfo.marketingPrice}
                      //     tagPrice={productInfo.tagPrice}
                      //     priceColor="#FF302D"
                      //     fontSize={17}
                      //     integerFontSize={22}
                      //     showTagPrice={!promoCouponInfo.skuTotalPostPrice}
                      //   />
                      //   {(promoCouponInfo as any).skuTotalPostPrice > 0 && (
                      //     <div className={styles["after-price-bg"]} style={{ marginLeft: "5px" }}>
                      //       <span className={styles["left-triangle"]}></span>
                      //       <span className={styles["prefix"]}>{hasNx ? "到手" : "券后"}</span>
                      //       <span className={styles["tag"]}>￥</span>
                      //       <span className={styles["price"]}>
                      //         {promoCouponInfo.skuTotalPostPrice}
                      //       </span>
                      //     </div>
                      //   )}
                      // </div>
                      <div className={styles["credit-wrapper"]}>
                        <div className={styles["creadit-amount"]}>{productInfo.creditAmount}</div>
                        <Image
                          className={styles["credit-icon"]}
                          src={
                            "https://akim-oss.aikucun.com/317db9c17c78847dcebe0e5e2329faaa8fdd3b09_1672296855717_54.png"
                          }
                        />
                      </div>
                    )}
                    {product.pricePanelType !== 13 && (
                      <div className={styles["earns-wrapper"]}>
                        {productInfo.rewardCreditAmount && (
                          <div className={styles["normal-c-jifen-wrapper"]}>
                            积{productInfo.rewardCreditAmount}
                            <Image
                              className={styles["icon-img"]}
                              src="https://akim-oss.aikucun.com/317db9c17c78847dcebe0e5e2329faaa8fdd3b09_1672296855717_54.png"
                            />
                          </div>
                        )}
                        {(productInfo?.skuAdvancePriceInfo ?? productInfo).commission && (
                          <div className={styles["commission"]}>
                            {(productInfo?.skuAdvancePriceInfo ?? productInfo).commission && (
                              <div className={styles["commission-content"]}>
                                {commissionAdditionPrefix} ¥
                                {(productInfo?.skuAdvancePriceInfo ?? productInfo).commission}
                              </div>
                            )}
                            {(productInfo?.skuAdvancePriceInfo ?? productInfo)
                              .commissionAddition && (
                              <div className={styles["commission-content"]}>
                                +¥
                                {
                                  (productInfo?.skuAdvancePriceInfo ?? productInfo)
                                    .commissionAddition
                                }
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* sku一二级属性 */}
            <div className={styles["tag-content"]}>
              {levelOnePropertyList.length && showFirstPicker ? (
                <div className={`${styles["sku-wrapper"]} ${styles["mgt28"]}`}>
                  <div className={styles["sku-wrapper-title"]}>
                    <div className={styles["wrapper-left"]}>
                      <span>选择{levelOnePropertyList[0]?.name}</span>
                      {showRemainQuality &&
                      productInfo.skuQuantity &&
                      (!levelTwoPropertyList || !levelTwoPropertyList.length) ? (
                        <span className={styles["remain-quality"]}>
                          (仅剩 {productInfo.skuQuantity} 件)
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    {product?.spuInfoVO?.sizeUrl && (
                      <div className={styles["wrapper-right"]} onClick={onPreviewSizeImg}>
                        查看尺码表
                        <ArrowRightIcon className={styles["image"]} />
                      </div>
                    )}
                  </div>
                  <div className={styles["sku-wrapper-content"]}>
                    {levelOnePropertyList.map((item, index) =>
                      !item.sellOut || showLevelOneAllProperty ? (
                        <SkuTag
                          type="img-text"
                          key={index}
                          active={index === selectedLevelOnePropertyIndex}
                          content={item}
                          tap={() => onClickLevelOneProperty(item, index)}
                          product={product}
                          isColor={true}
                        />
                      ) : (
                        <></>
                      ),
                    )}
                    {levelOnePropertyHasSellout && !showLevelOneAllProperty ? (
                      <div
                        className={styles["show-hide-property-wrapper"]}
                        onClick={handleShowLevelOneAllProperty}>
                        {product.activitySimpleVO && product.activitySimpleVO.status === 1
                          ? selloutText
                          : "售罄"}
                        <Image
                          className={styles["img-icon"]}
                          src="https://akim-oss.aikucun.com/cd7b28f7b78bc27a3f5324de3eb57aaf2a233fe7_1677483027676_21.png"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {levelTwoPropertyList.length ? (
                <div className={`${styles["sku-wrapper"]} ${styles["mgt16"]}`}>
                  <div className={styles["sku-wrapper-title"]}>
                    <div className={styles["wrapper-left"]}>
                      <span>请选择{levelTwoPropertyList[0]?.name}</span>
                      {showRemainQuality && productInfo.skuQuantity ? (
                        <span className={styles["remain-quality"]}>
                          （仅剩 {productInfo.skuQuantity} 件）
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className={styles["sku-wrapper-content"]}>
                    {showLevelTwoAllProperty}
                    {levelTwoPropertyList.map(
                      (item, index) =>
                        (!item.sellOut || showLevelTwoAllProperty) && (
                          <SkuTag
                            key={index}
                            type="img-text"
                            active={index === selectedLevelTwoPropertyIndex}
                            content={item}
                            tap={() => onClickLevelTwoProperty(item, index)}
                            product={product}
                          />
                        ),
                    )}
                    {levelTwoPropertyHasSellout && !showLevelTwoAllProperty && (
                      <div
                        className={styles["show-hide-property-wrapper"]}
                        onClick={handleShowLevelTwoAllProperty}>
                        {product.activitySimpleVO && product.activitySimpleVO.status === 1
                          ? selloutText
                          : "售罄"}
                        <Image
                          className={styles["img-icon"]}
                          src="https://akim-oss.aikucun.com/cd7b28f7b78bc27a3f5324de3eb57aaf2a233fe7_1677483027676_21.png"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {showSkuNum ? (
                <div className={styles["sku-num-wrapper"]}>
                  <div className={styles["wrapper-left"]}>购买数量</div>
                  <div className={styles["wrapper-right"]}>
                    <Stepper
                      min={minQuantity}
                      max={maxQuantity}
                      change={addQuanity}
                      overlimit={handleOverlimit}
                      value={perchaseQuantity}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {requireMinimum ? (
                <div className={styles["marketing-limit-tip"]}>
                  {" "}
                  {productInfo.minQuantity} 件起购{" "}
                </div>
              ) : (
                <></>
              )}
              {requireLimit ? (
                <div className={styles["marketing-limit-tip"]}>
                  {productInfo.maxQuantity === 0
                    ? "限购商品，购买额度已用完"
                    : `限购 ${productInfo.maxQuantity}件`}
                </div>
              ) : (
                <></>
              )}
              {requireAdLimit ? (
                <div className={styles["marketing-limit-tip"]}>
                  {productInfo.availablePromoQuantity === 0
                    ? "优惠购买额度已用完"
                    : `优惠商品，限购 ${productInfo.availablePromoQuantity} 件`}
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* 优惠券展示 */}
            {promoCouponInfo?.discountList?.length > 0 ? (
              <div className={styles["coupon-tips"]}>
                <div className={styles["coupon-desc"]}>当前商品可使用</div>
                <div className={styles["coupon-contents"]}>
                  {promoCouponInfo?.discountList.map((item, index) => {
                    return `${item.marketingTypeDesc} ${
                      index < promoCouponInfo?.discountList.length - 1 ? "、" : ""
                    }`;
                  })}
                </div>
                <div className={styles["coupon-desc"]}>优惠</div>
              </div>
            ) : (
              <></>
            )}
            {/* 底部按钮 */}
            <div className={styles.footer}>
              {/* 预告 */}
              {product?.activitySimpleVO?.status === 1 && (
                <div className={styles["buy-btn-remind-me-model-wrap"]}>
                  {selectedSku?.sellOut ? (
                    <div
                      className={`${styles.btn} ${styles.primary} ${styles.large}`}
                      onClick={stillBuy}>
                      还想买
                    </div>
                  ) : (
                    <div
                      className={`${
                        minimumLessThanQuality || limitUseUp || !allowDeliver ? styles.disabled : ""
                      }} ${styles.btn} ${styles.primary} ${styles.large}`}
                      onClick={addToCart}>
                      加入购物车
                    </div>
                  )}
                </div>
              )}

              {product.pricePanelType !== 13 && product?.activitySimpleVO?.status !== 1 && (
                <>
                  {/* // 非积分商品售罄展示还想买 */}
                  {selectedSku.sellOut ? (
                    <div
                      className={`${styles.btn} ${styles.primary} ${styles.large}`}
                      onClick={stillBuy}>
                      还想买
                    </div>
                  ) : (
                    <>
                      {/* 只有加购商品 */}
                      {buttonStyle === BottomButtonStyle.cart &&
                        product.skuExtendInfoVO &&
                        product.skuExtendInfoVO.buyShoppingCartModel &&
                        product.pricePanelType !== 13 && (
                          <div
                            className={`${
                              (product.activitySimpleVO?.status === 1 &&
                                product.skuExtendInfoVO?.isCrossBorder) ||
                              minimumLessThanQuality ||
                              limitUseUp ||
                              !allowDeliver
                                ? styles.disabled
                                : ""
                            } ${styles.btn} ${styles.primary} ${styles.large}`}
                            onClick={addToCart}>
                            {buttonText ?? "加入购物车"}
                          </div>
                        )}

                      {buttonStyle === BottomButtonStyle.buyNow &&
                        product.skuExtendInfoVO &&
                        product.skuExtendInfoVO.buyDirectModel &&
                        product.pricePanelType !== 13 && (
                          <div
                            className={`${styles.btn} ${styles.primary} ${styles.large} ${
                              minimumLessThanQuality || limitUseUp || !allowDeliver
                                ? styles.disabled
                                : ""
                            }`}
                            onClick={buyNow}>
                            {product?.skuCurrentproductInfo?.promoCouponInfo?.toDrawAwardList
                              ?.length ? (
                              <div className={styles["buy-after-coupon"]}>
                                <div className={styles["up"]}>
                                  {" "}
                                  {hasNx ? "预估到手" : "券后"}¥{promoCouponInfo.skuTotalPostPrice}{" "}
                                </div>
                                <div className={styles["down"]}>领券购买</div>
                              </div>
                            ) : (
                              <div className={styles["buy-after-coupon"]}>
                                {hasNx && promoCouponInfo.skuTotalPostPrice && (
                                  <div className={styles["up"]}>
                                    {" "}
                                    预估到手¥{promoCouponInfo.skuTotalPostPrice}{" "}
                                  </div>
                                )}
                                <div className={styles["down"]}>{buttonText ?? "立即购买"}</div>
                              </div>
                            )}
                          </div>
                        )}

                      {/* 加购 && 立即购买 */}
                      {!buttonStyle &&
                        product.skuExtendInfoVO &&
                        product.skuExtendInfoVO.buyShoppingCartModel &&
                        product.skuExtendInfoVO.buyDirectModel &&
                        product.pricePanelType !== 13 && (
                          <>
                            <div>
                              <div
                                className={`${
                                  (product.activitySimpleVO?.status === 1 &&
                                    product.skuExtendInfoVO?.isCrossBorder) ||
                                  minimumLessThanQuality ||
                                  limitUseUp ||
                                  !allowDeliver
                                    ? styles.disabled
                                    : ""
                                } ${styles.btn} ${styles.plain} ${styles.medium}`}
                                onClick={addToCart}>
                                加入购物车
                              </div>
                            </div>
                            <div
                              className={`${styles.btn} ${styles.primary} ${styles.medium} ${
                                minimumLessThanQuality || limitUseUp || !allowDeliver
                                  ? styles.disabled
                                  : ""
                              }`}
                              onClick={buyNow}>
                              {promoCouponInfo?.toDrawAwardList.length ? (
                                <div className={styles["buy-after-coupon"]}>
                                  <div className={styles["up"]}>
                                    {hasNx ? "预估到手" : "券后"}¥
                                    {promoCouponInfo.skuTotalPostPrice}{" "}
                                  </div>
                                  <div className={styles["down"]}>领券购买</div>
                                </div>
                              ) : (
                                <div className={styles["buy-after-coupon"]}>
                                  {hasNx && promoCouponInfo.skuTotalPostPrice ? (
                                    <div className={styles["up"]}>
                                      {" "}
                                      预估到手¥{promoCouponInfo.skuTotalPostPrice}{" "}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    className={`${styles["down"]} ${!hasNx ? styles["fx"] : ""}`}>
                                    立即购买
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      {/* 只有加购商品 */}
                      {!buttonStyle &&
                        product.skuExtendInfoVO &&
                        product.skuExtendInfoVO.buyShoppingCartModel &&
                        !product.skuExtendInfoVO.buyDirectModel &&
                        product.pricePanelType !== 13 && (
                          <div
                            className={`${
                              (product.activitySimpleVO?.status === 1 &&
                                product.skuExtendInfoVO?.isCrossBorder) ||
                              minimumLessThanQuality ||
                              limitUseUp ||
                              !allowDeliver
                                ? styles.disabled
                                : ""
                            } ${styles.btn} ${styles.primary} ${styles.large}`}
                            onClick={addToCart}>
                            加入购物车
                          </div>
                        )}
                      {/* 只有直购商品 */}
                      {!buttonStyle &&
                        product.skuExtendInfoVO &&
                        product.skuExtendInfoVO.buyDirectModel &&
                        !product.skuExtendInfoVO.buyShoppingCartModel &&
                        product.pricePanelType !== 13 && (
                          <div
                            className={`${styles.btn} ${styles.primary} ${styles.large} ${
                              minimumLessThanQuality || limitUseUp || !allowDeliver
                                ? styles.disabled
                                : ""
                            }`}
                            onClick={buyNow}>
                            {product?.skuCurrentproductInfo?.promoCouponInfo?.toDrawAwardList
                              ?.length ? (
                              <div className={styles["buy-after-coupon"]}>
                                <div className={styles["up"]}>
                                  {" "}
                                  {hasNx ? "预估到手" : "券后"}¥{promoCouponInfo.skuTotalPostPrice}{" "}
                                </div>
                                <div className={styles["down"]}>领券购买</div>
                              </div>
                            ) : (
                              <div className={styles["buy-after-coupon"]}>
                                {hasNx && promoCouponInfo.skuTotalPostPrice && (
                                  <div className={styles["up"]}>
                                    {" "}
                                    预估到手¥{promoCouponInfo.skuTotalPostPrice}{" "}
                                  </div>
                                )}
                                <div className={styles["down"]}>立即购买</div>
                              </div>
                            )}
                          </div>
                        )}
                    </>
                  )}
                </>
              )}
              {/* 积分兑换商品 */}
              {product.pricePanelType === 13 && (
                <div>
                  {selectedSku.sellOut ? (
                    <div
                      className={`${styles.btn} ${styles.primary} ${styles.large}`}
                      onClick={stillBuy}>
                      还想买
                    </div>
                  ) : (
                    <div
                      className={`${styles.btn} ${styles.primary} ${styles.large} ${
                        !allowDeliver ? styles.disabled : ""
                      }`}
                      onClick={buyNow}>
                      立即兑换
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles["dot-loading"]}>
            <DotLoading color="primary" />
          </div>
        )}
      </Popup>
      <ImageViewer.Multi
        classNames={{ mask: styles.imageMask }}
        getContainer={document.body}
        images={productPicList}
        visible={preViewProductImageVisible}
        defaultIndex={1}
        onClose={() => {
          setPreViewProductImageVisible(false);
        }}
      />
    </React.Fragment>
  );
};
export default AddToCartModal;
