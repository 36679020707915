import { Toast } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { StudyTourSignUpDayListInfo, YouXueSignUp } from "../../model";
import useActivityList from "./useActivityList";

export interface Tab {
  // 标识
  key?: string;
  // 标题
  title?: string;
}

const useStudyListTour = (props?: {
  title?: string;
  tabKey?: string;
  categoryCode?: string;
  selectStudyIds?: string[];
  onStudyTourListAction?: (
    tabKey?: string,
    list?: StudyTourSignUpDayListInfo[],
    brandLogos?: string[],
    total?: number,
  ) => void;
  onUpdateSelectStatusAction?: (
    tabKey?: string,
    detail?: YouXueSignUp,
    showSelect?: boolean,
  ) => void;
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [showDetailBottom, setShowDetailBottom] = useState<boolean>(true);
  const selectStudyTourDetail = useRef<YouXueSignUp>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const { list, setList, brandLogos, total, loading } = useActivityList({
    title: props?.title,
    categoryCode: props?.categoryCode,
    selectStudyIds: props?.selectStudyIds,
  });

  useEffect(() => {
    if ((props?.categoryCode?.length ?? 0) > 0) {
      list?.map((i?: StudyTourSignUpDayListInfo) => {
        i?.studyTourSignUpList?.map(item => {
          const isSelect =
            (props?.selectStudyIds?.findIndex(id => id === item.studyTourId) ?? -1) > -1;
          item.isSelect = isSelect;
        });
      });
      setRefresh(!refresh);
      return;
    }
  }, [props?.selectStudyIds]);

  useEffect(() => {
    if (!props?.onStudyTourListAction) {
      return;
    }
    // 类目的不返回
    if ((props?.categoryCode?.length ?? 0) > 0) {
      return;
    }
    props?.onStudyTourListAction(props?.tabKey, list, brandLogos.current, total.current);
  }, [list]);

  const checkCount = () => {
    let number = 0;
    list?.map(item => {
      item.studyTourSignUpList?.forEach(i => {
        if (i?.isSelect) {
          number += 1;
        }
      });
    });
    return number;
  };

  const unSelect = (signUpDetail?: YouXueSignUp) =>
    !signUpDetail?.signUpFlag || signUpDetail?.memberSignUpFlag;

  const unSelectWithStudyActivityEnd = (signUpDetail?: YouXueSignUp) => !signUpDetail?.signUpFlag;

  const onSelectStudyTourList = (signUpDetail?: YouXueSignUp, select?: boolean) => {
    if (!signUpDetail) {
      return;
    }
    let isExceedLimit = true;
    list?.map(t => {
      // 选中的是已选的 取消勾选
      t?.studyTourSignUpList?.forEach(item => {
        if (item?.studyTourId === signUpDetail?.studyTourId) {
          if (unSelect(item)) {
            if (unSelectWithStudyActivityEnd(item)) {
              Toast.show(item?.signUpBanReason || "该场游学报名已经裁止，请再看看其他的活动吧");
            }
            return;
          }
          if (!item.isSelect && total.current <= checkCount()) {
            Toast.show("已超出可选数量，请清理后再选择");
            return;
          }
          // 专门处理类目的提示
          if (
            !item.isSelect &&
            (props?.categoryCode?.length ?? 0) > 0 &&
            total.current <= checkCount() + 1
          ) {
            Toast.show("已超出可选数量，请清理后再选择");
            isExceedLimit = false;
            return;
          }
          if (select) {
            item.isSelect = true;
            return;
          }
          item.isSelect = !item?.isSelect || false;
        }
      });
      setList([...list]);
    });
    if (!props?.onUpdateSelectStatusAction || !isExceedLimit) {
      return;
    }
    if ((props?.categoryCode?.length ?? 0) > 0) {
      props?.onUpdateSelectStatusAction(props?.tabKey, signUpDetail, select);
    }
  };

  const onSelectSureAction = (signUpDetail?: YouXueSignUp) => {
    setDetailVisible(false);
    onSelectStudyTourList(signUpDetail, true);
  };
  const onSelectActivityAction = (signUpDetail?: YouXueSignUp) => {
    onSelectStudyTourList(signUpDetail, false);
  };

  const onActivityDetailAction = (signUpDetail?: YouXueSignUp) => {
    setShowDetailBottom(!(unSelect(signUpDetail) ?? true));
    selectStudyTourDetail.current = signUpDetail;
    setDetailVisible(true);
  };

  const onSelectedDialogVisible = (show?: boolean) => setVisible(show ?? false);

  const onDetailDialogVisible = (show?: boolean) => setDetailVisible(show ?? false);

  return {
    list,
    visible,
    loading,
    detailVisible,
    selectStudyTourDetail,
    showDetailBottom,
    unSelect,
    onSelectedDialogVisible,
    onDetailDialogVisible,
    onActivityDetailAction,
    onSelectSureAction,
    onSelectActivityAction,
  };
};

export default useStudyListTour;
