import { queryFrontSettingsServ } from "@/api/index";
import { Mask, Toast } from "antd-mobile";
import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./ShareOTCBeforeConfirmPopup.module.less";

interface Props {
  setIsConfirm: (bool) => void;
  handleClose: () => void;
}

const ShareOTCBeforeConfirmPopup: FC<Props> = ({ setIsConfirm, handleClose }) => {
  const [isReadAll, setIsReadAll] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const readAllDom = useRef<HTMLDivElement>(null);
  const [otcForwordTip, setOtcForwordTip] = useState({
    appellation: "",
    preface: "",
    content: [],
    protocolLinkUrl: "",
    protocolName: "",
  });

  const closePopup = () => {
    handleClose();
  };

  const onConfirm = () => {
    if (isAgree) {
      setIsConfirm(true);
    } else {
      Toast.show("请先同意OTC商品转发的相关规范");
    }
  };

  const toProtocolPage = () => {
    if (!otcForwordTip.protocolLinkUrl) {
      return;
    }
    window.location.href = otcForwordTip.protocolLinkUrl;
  };

  const getFrontSettings = async () => {
    const res = await queryFrontSettingsServ();
    if (res?.data?.otcForwordTip) {
      setOtcForwordTip(res?.data?.otcForwordTip || {});
    }
  };

  useEffect(() => {
    getFrontSettings();
  }, []);

  // useEffect(() => {
  //   if (!readAllDom.current) {
  //     return;
  //   }
  //   const observer = new IntersectionObserver((entries) => {
  //     if (entries[0].intersectionRatio > 0) {
  //       console.log("进入可视区域");
  //       setIsReadAll(true);
  //     } else {
  //       console.log("移出可视区域");
  //     }
  //   });
  //   observer.observe(readAllDom.current);
  //   return () => {
  //     observer?.disconnect();
  //   };
  // }, [readAllDom.current]);

  return (
    <Mask style={{ "--z-index": "20" }} visible={true} onMaskClick={closePopup}>
      <div className={styles["share-otc-before-confirm-popup"]}>
        <div className={styles["popup__header"]}>
          <h2 className={styles["header__title"]}>转发提醒</h2>
          <img
            className={styles["header__icon"]}
            src="https://akim-oss.aikucun.com/mshop/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1713508223039_0.png"
            onClick={closePopup}
          />
        </div>
        <div className={styles["popup__body"]}>
          {otcForwordTip?.appellation && (
            <h3 className={styles["body__title"]}>{otcForwordTip.appellation}</h3>
          )}
          {otcForwordTip?.preface && (
            <h4 className={styles["body__subtitle"]}>{otcForwordTip.preface}</h4>
          )}
          {otcForwordTip?.content?.length && (
            <div className={styles["body__list"]}>
              {otcForwordTip.content.map((item, index) => {
                return (
                  <div key={index} className={styles["list__item"]}>
                    {item}
                  </div>
                );
              })}
              <div ref={readAllDom} className={styles["read-all"]}></div>
            </div>
          )}
        </div>
        <div className={styles["popup__footer"]}>
          {otcForwordTip.protocolLinkUrl && otcForwordTip.protocolName && (
            <div className={styles.footer__protocol}>
              <div
                className={`${styles.protocol__check} ${
                  isAgree && styles["protocol__check--agree"]
                }`}
                onClick={() => setIsAgree(!isAgree)}></div>
              <div className={styles.protocol__right}>
                <span className={styles.protocol__prefix}>我已阅读并同意</span>
                <span className={styles.protocol__link} onClick={toProtocolPage}>
                  {otcForwordTip.protocolName}
                </span>
              </div>
            </div>
          )}
          <div className={styles.footer__btns}>
            <div className={`${styles["btn"]} ${styles["btn__cancel"]}`} onClick={closePopup}>
              不同意
            </div>
            <div
              className={`${styles["btn"]} ${styles["btn__confirm"]} ${
                !isAgree && styles["btn__disable"]
              }`}
              onClick={onConfirm}>
              同意并继续
            </div>
          </div>
        </div>
      </div>
    </Mask>
  );
};

export default ShareOTCBeforeConfirmPopup;
