import { CategoryInfo, StudyTourConfigInfo } from "@/pages/youxue/model";
import { getBaseurl } from "@/utils/url-parse";
import { API, MemberSignUpInfo, YouXueSignUpDetail } from "@akc/biz-api";
import { Request, Response } from "../request";

export type SignupReq = MemberSignUpInfo & {
  studyTourIds?: number[]; // 游学ID
  signUpType?: number; // 游学报名类型 1-自主报名 2-运营邀请
};

// 游学报名结果
export type SignupResult = {
  signUpSuccess?: boolean; // 报名成功标识 true：报名成功 false：报名失败
  signUpFailReason?: string; // 报名失败原因
};

/**
 * @description 游学报名
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/392892
 */
export async function youxueSignUp(data?: SignupReq): Promise<SignupResult> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/create`,
    method: "post",
    data: {
      ...data,
    },
  });
  return res?.data;
}

/**
 * @description 获取地址库版本，旧接口(参照h5-Shop项目里的调用)
 */
export async function getAddressVersion() {
  const res = await Request({
    url: `${process.env.REACT_APP_ZUUL_INFRA}/akucun-base-data-new/base/address/selectAddrVersion`,
    method: "get",
  });
  return res?.data;
}

/**
 * @description 获取地址列表信息，旧接口(参照h5-Shop项目里的调用)
 * https://akmer.aikucun.com 是cdn域名，此处作特殊化使用
 *
 */
export async function getAddressListInfo() {
  return API.get<any>({
    path: `${process.env.REACT_APP_AKMER}/AllNameThreeJson`,
    withCredentials: false,
  });
}

export type SignupConfirmReq = {
  studyTourId?: string; // 游学ID
  confirmStatus?: number; // 确认状态 0-待确认参与 1-确认参与 2-拒绝参与 3-超时不参与
  operator?: string;
  refuseReason?: string;
};

/**
 * @description 游学待确认行程
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/393153
 */
export async function youxueSignUpConfirm(data?: SignupConfirmReq): Promise<any> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/confirm`,
    method: "post",
    data: {
      ...data,
    },
  });
  return res?.data;
}

export type SignupDetailReq = {
  studyTourId?: string; // 游学ID
  scene?: string; // 场景 INVITE-邀请报名页 DETAIL-详情页 CHANGE-行程变更
};

/**
 * @description 获取游学活动详情
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/392928
 */
export async function getYouxueSignUpDetail(
  data?: SignupDetailReq,
): Promise<Response<YouXueSignUpDetail>> {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/detail`,
    method: "get",
    params: {
      ...data,
    },
  });
}

/**
 *
 * @description 根据月份查询游学报名活动列表
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/392883
 */
export async function fetchStudyTourListInfo(props?: { month?: string; categoryCode?: string }) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/listV2`,
    method: "post",
    data: { month: props?.month, categoryCode: props?.categoryCode },
  });
  return res?.data;
}

/**
 *
 * @description 查询游学类目
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/413772
 */
export async function fetchStudyCategoryList(): Promise<CategoryInfo[]> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/queryStudyCategory`,
    method: "get",
  });
  return res?.data;
}

/**
 *
 * @description 游学报名表单配置
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/393198
 */
export async function fetchConfigInfo(): Promise<StudyTourConfigInfo> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/form/config`,
    method: "get",
  });
  return res?.data;
}

/**
 *
 * @description 游学历史
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/393711
 */
export async function fetchHistoryInfo(props?: { lastMonth?: string; queryType?: number }) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/history`,
    method: "post",
    data: { lastMonth: props?.lastMonth, queryType: props?.queryType },
  });
  return res?.data;
}

/**
 *
 * @description 取消游学报名
 * @author 入格
 * @link https://yapi.akcops.com/project/2427/interface/api/393216
 */
export async function fetchCancelSignUp(params?: { studyTourId?: string }) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/cancel`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * 获取当前签到信息
 */
export async function fetchCurrentSingInfo() {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/studyTour/signUp/currentSingInfo`,
    method: "get",
  });
  return res?.data;
}
