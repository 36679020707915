import React from "react";
import styles from "./index.module.less";
import { Image } from "antd-mobile";
type Props = {
  title: string;
  className?: string;
};

/**
 * 标题组件（好货推荐、详情介绍）
 */
const TitleComp: React.FC<Props> = props => {
  return (
    <div className={`${styles.pdtc}${props.className?.length ? " " + props.className : ""}`}>
      <Image
        className={styles.flag}
        src="https://akim-oss.aikucun.com/saas/518edcdf460cd0094a1e816cbc116011c403c706_1654505998379_9.png"
      />
      <span className={styles.text}>{props.title}</span>
      <img
        className={styles.flag}
        src="https://akim-oss.aikucun.com/saas/518edcdf460cd0094a1e816cbc116011c403c706_1654505998379_9.png"
      />
    </div>
  );
};

export default TitleComp;
