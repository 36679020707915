import React from "react";
import styles from "./RightsUpgradePop.module.less";

type Props = {
  onClose: () => void;
};
const RightsUpgradePop: React.FC<Props> = ({ onClose }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>星用户级权益提升通知</div>
      <div>
        <section className={styles.section}>
          <div className={styles.title}>亲爱的用户您好！</div>
          <div className={styles.content}>
            为了给您带来更加贴心、无忧的购物体验，我们特别对您的运费无忧专属权益进行了全面升级!让您在享受心仪商品的同时，也能拥有更多的安心与保障!
          </div>
        </section>
        <section className={styles.section}>
          <div className={styles.title}>升级亮点如下：</div>
          <div className={styles.content}>
            1、如您现在的会员星级是3星会员，在以前可在退换货率45 %
            以内时享有，现在您的权益已提升至退换货率<span className={styles.mark}>75 %</span>
            以内可享受！（除虚拟、生鲜、海淘、商品自身不支持外）
          </div>
          <div className={styles.content}>
            2、如您现在的会员星级是4、5星会员，在以前可在退换货率45 % 以内时享有，现在对
            <span className={styles.mark}>退换货率无要求</span>
            ，可全部享受！（除虚拟、生鲜、海淘、商品自身不支持外）
          </div>
        </section>
        <section className={styles.section}>
          <div className={styles.title}>感谢您一直以来的支持与理解！</div>
        </section>
      </div>
      <div className={styles.closeBtn} onClick={onClose}></div>
      <div className={styles.footer} onClick={onClose}>
        我知道了
      </div>
    </div>
  );
};

export default RightsUpgradePop;
