import { getRecommendProductList, ProductCard as ProductCartData, ProductParams } from "@/api/home";
import ProductWaterfall from "@/components/product-waterfall";
import { ProductModel } from "@akc/biz-api";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ProductList.module.less";

type Props = {
  pageId: string;
};

const ProductList: React.FC<Props> = ({ pageId }) => {
  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [productParams, setProductParams] = useState<ProductParams>();
  const [hasNext, setHasNext] = useState<boolean>(true);
  const loadMorePromise = useRef<any>();
  const nextPage = useRef<number>(0);
  const idsClick = useRef<string[]>([]);
  const lastActivitySpuCode = useRef<string>("");

  useEffect(() => {
    if (!pageId) {
      return;
    }
    const searchRequestId = _.random(100000000000, 999999999999999);
    setProductParams({
      idsClick: idsClick.current.join(),
      lastActivitySpuCode: lastActivitySpuCode.current,
      pageId,
      pageIndex: 1,
      pageSize: 10,
      scene: "homeFeeds",
      searchRequestId,
    });
  }, []);

  useEffect(() => {
    if (!productParams) {
      return;
    }
    getRecommendProductList(productParams)
      .then(data => {
        setProductList(productList => {
          return [
            ...(productList || []),
            ...data.result.map((item, index) => ({
              ...item,
              rank: productList.length + index,
            })),
          ];
        });
        nextPage.current = data.pageIndex + 1;
        lastActivitySpuCode.current = data.result[data.result.length - 1]?.activitySpuId || "";
        // version.current = data.version;
        loadMorePromise.current?.resolve();
        setHasNext(data.hasNext);
      })
      .catch(console.error);
  }, [productParams]);

  const loadMore = async () => {
    if (!productParams) {
      return;
    }
    const searchRequestId = _.random(100000000000, 999999999999999);
    setProductParams({
      idsClick: idsClick.current.join(),
      lastActivitySpuCode: lastActivitySpuCode.current,
      pageId,
      pageIndex: nextPage.current,
      pageSize: 10,
      scene: "homeFeeds",
      searchRequestId,
    });
    await new Promise((resolve, reject) => {
      loadMorePromise.current = { resolve, reject };
    });
    loadMorePromise.current = null;
  };

  return (
    <div className={styles.waterfallConatainer}>
      <ProductWaterfall
        productList={productList}
        hasNext={hasNext}
        loadMore={loadMore}
        trackData={{
          domain: "首页",
          module: "甄选精品",
          resource_name: "feed",
          resource_content: "商品",
          resource_type: "feed",
        }}
        btnClickName="resource_click"
        onClickProduct={product => idsClick.current.push(product?.activitySpuId || "")}
        topRightLocationCode="H5_Feed_ProductList_ProductPicRight"
        titleFrontLocationCode="H5_Feed_ProductList_TitleFront"
        titleBottomLocationCode="H5_Feed_ProductList_TitleDown"
      />
    </div>
  );
};

export default ProductList;
