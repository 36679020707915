import {
  getMaterialProductSwitch,
  getShareConfig,
  setShareConfig,
  ShareConfigParams,
  updateMaterialProductSwitch,
} from "@/api/share";
import { akjs } from "@/utils/akjs";
import { platform } from "@/utils/platform";
import AKJS from "@akc/akjs";
import { defaultChannel, ForwardConfigExtendModel, ForwardConfigModel } from "@akc/biz-api";
import { useCallback, useEffect, useRef, useState } from "react";
import { GroupProps, ItemID, ItemProps } from "../props";

type PopUpData = {
  title: string;
  actions: {
    title?: string;
    selected?: boolean;
    onClick: () => void;
  }[];
};

const groupMetaDatas: GroupProps[] = [
  {
    title: "转发海报",
    items: [
      {
        id: "PosterQRCodeSetting",
        type: "detail",
        title: "二维码设置",
        app: true,
      },
      {
        id: "PosterProduct",
        type: "detail",
        title: "商品海报",
        app: true,
        h5: true,
        weapp: true,
      },
      {
        id: "PosterActivity",
        type: "detail",
        title: "档期海报",
        app: true,
        h5: true,
        weapp: true,
      },
      {
        id: "PosterStyle",
        type: "detail",
        title: "海报样式设置",
        subTitle: "可对海报基础设置(店铺名称、描述、LOGO等)进行修改配置",
        app: true,
      },
      {
        id: "ForwardStockoutSize",
        type: "switch",
        title: "转发缺货尺码",
        subTitle: "开启后商品海报将显示售罄尺码，关闭后不显示",
        app: true,
        h5: true,
        weapp: true,
      },
    ],
  },
  {
    title: "转发卡片",
    items: [
      {
        id: "MiniProduct",
        type: "detail_switch",
        title: "商品卡片",
        switchTitle: "是否开启头像昵称",
        app: true,
        weapp: true,
        h5Mode4App: false,
      },
      {
        id: "MiniActivity",
        type: "detail",
        title: "档期卡片",
        app: true,
        weapp: true,
        h5Mode4App: false,
      },
    ],
  },
  {
    title: "档期转发",
    items: [
      {
        id: "BatchForwardCount",
        type: "detail",
        title: "选择性转发数量",
        app: true,
      },
    ],
  },
  {
    items: [
      {
        id: "MaterialProduct",
        type: "switch",
        title: "素材商品详情",
        subTitle: "开启后有优质素材的商品详情会变成素材商详",
        h5: true,
      },
    ],
  },
];

const useShareConfig = () => {
  const [groups, setGroups] = useState<GroupProps[]>();

  // 缓存的配置数据
  const shareConfig = useRef<ForwardConfigModel>();
  // 是否显示素材商品详情开关
  const showMaterialProductSwitchStatus = useRef<{ showFlag?: boolean; switchStatus?: number }>();

  // 切换素材商品详情示例弹窗
  const [mpPopupVisible, setMpPopupVisible] = useState(false);

  const [popUpData, setPopUpData] = useState<PopUpData>();

  const rebuildGroups = useCallback(() => {
    const isH5Mode = defaultChannel(shareConfig.current) === "h5";
    // 判断并过滤数据
    const groups = groupMetaDatas
      .map(i => Object.assign({}, i)) // 深拷贝
      .filter(group => {
        group.items = group.items.filter(item => {
          if (platform.isMini) {
            return item.weapp === true;
          }
          if (platform.isH5) {
            if (item.id === "MaterialProduct") {
              return item.h5 === true && showMaterialProductSwitchStatus.current?.showFlag;
            }
            return item.h5 === true;
          }
          if (platform.isApp) {
            const h5Mode4App = item.h5Mode4App ?? true;
            if (isH5Mode) {
              return h5Mode4App && item.app === true;
            }
            return item.app === true;
          }
          return true;
        });
        return group.items.length > 0;
      });
    groups.forEach(group => {
      group.items.forEach(item => {
        switch (item.id) {
          case "PosterQRCodeSetting":
            item.value = shareConfig.current?.shareChannels?.find(
              i => i.defaultChannel === true,
            )?.shareChannelName;
            break;
          case "PosterProduct":
            item.value = shareConfig.current?.productPoster?.find(
              i => i.defaultTemplate === true,
            )?.templateName;
            break;
          case "PosterActivity":
            item.value = shareConfig.current?.activityPoster?.find(
              i => i.defaultTemplate === true,
            )?.templateName;
            break;
          case "PosterStyle":
            break;
          case "ForwardStockoutSize":
            item.switchValue = shareConfig.current?.stockFlag === 1;
            break;
          case "MiniProduct": {
            const productCardConfig = shareConfig.current?.productCard?.find(
              i => i.defaultTemplate === true,
            );
            item.value = productCardConfig?.templateName;
            item.type = "detail";
            if (productCardConfig?.extendConfigs && productCardConfig.extendConfigs.length > 0) {
              if (productCardConfig.extendConfigs[0].show) {
                // 有扩展 && 要显示开关，则修改类型
                item.type = "detail_switch";
                item.switchValue = productCardConfig.extendConfigs[0].value === "1";
              }
            }
            break;
          }
          case "MiniActivity":
            item.value = shareConfig.current?.activityCard?.find(
              i => i.defaultTemplate === true,
            )?.templateName;
            break;
          case "BatchForwardCount":
            item.value = shareConfig.current?.forwardNum + "件";
            break;
          case "MaterialProduct":
            item.switchValue = showMaterialProductSwitchStatus.current?.switchStatus === 1;
            item.onTitleClick = () => {
              setMpPopupVisible(true);
            };
            break;
        }
      });
    });
    setGroups(groups);
  }, []);

  useEffect(() => {
    Promise.all([
      getShareConfig(),
      platform.isH5 ? getMaterialProductSwitch() : Promise.resolve(undefined),
    ]).then(datas => {
      const config = datas[0];
      if (!config) {
        setGroups([]);
        return;
      }
      shareConfig.current = config;
      const materialProductSwitchStatus = datas[1];
      showMaterialProductSwitchStatus.current = materialProductSwitchStatus;
      rebuildGroups();
    });
  }, []);

  const updateItemValue = (props: {
    id: ItemID;
    value?: string;
    switchValue?: boolean;
    extendConfig?: ForwardConfigExtendModel;
  }) => {
    setGroups(pre => {
      const groups = Array.from(pre ?? []);
      groups.forEach(group => {
        const i = group.items.find(i => i.id === props.id);
        if (i) {
          if (props.value) {
            i.value = props.value;
          }
          if (props.switchValue !== undefined) {
            i.switchValue = props.switchValue;
          }
          if (props.id === "MiniProduct") {
            i.type = props.extendConfig?.show ? "detail_switch" : "detail";
          }
        }
      });
      return groups;
    });
  };

  const updateTemplateConfig = (
    item: ItemProps,
    key: keyof ShareConfigParams,
    templateConfigList?: ForwardConfigModel["productCard"],
  ) => {
    if (shareConfig.current && templateConfigList && templateConfigList.length > 0) {
      setPopUpData({
        title: item.title,
        actions: templateConfigList.map(i => ({
          title: i.templateName,
          selected: i.defaultTemplate,
          onClick: () => {
            updateItemValue({ id: item.id, value: i.templateName });
            templateConfigList.forEach(e => {
              e.defaultTemplate = false;
            });
            i.defaultTemplate = true;
            if (i.extendConfigs && i.extendConfigs.length > 0 && i.extendConfigs[0].show === true) {
              // 显示内容
              updateItemValue({
                id: item.id,
                switchValue: i.extendConfigs[0].value === "1",
                extendConfig: i.extendConfigs[0],
              });
            }
            setShareConfig({ [key]: i.templateNo });
            setPopUpData(undefined);
          },
        })),
      });
    }
  };

  /**
   * 二维码设置
   */
  const onPosterQRCodeSettingAction = async (item: ItemProps) => {
    if (shareConfig.current?.shareChannels && shareConfig.current.shareChannels.length > 0) {
      setPopUpData({
        title: item.title,
        actions: shareConfig.current.shareChannels.map(i => ({
          title: i.shareChannelName,
          selected: i.defaultChannel,
          onClick: () => {
            //updateItemValue({ id: item.id, value: i.shareChannelName });
            shareConfig.current?.shareChannels?.forEach(e => {
              e.defaultChannel = false;
            });
            i.defaultChannel = true;
            rebuildGroups();
            setShareConfig({ defaultChannel: i.shareChannelCode }).then(() => {
              // 设置成功
              if (platform.isApp) {
                akjs.event.send({ name: "event.notify.defaultChannelChange" });
              }
            });
            setPopUpData(undefined);
          },
        })),
      });
    }
  };

  /**
   * 商品海报
   */
  const onPosterProductAction = async (item: ItemProps) => {
    updateTemplateConfig(item, "defaultTemplateNo", shareConfig.current?.productPoster);
  };

  /**
   * 档期海报
   */
  const onPosterActivityAction = async (item: ItemProps) => {
    updateTemplateConfig(
      item,
      "defaultActivityPosterTemplateNo",
      shareConfig.current?.activityPoster,
    );
  };

  /**
   * 海报样式
   */
  const onPosterStyleAction = async (item: ItemProps) => {
    if (platform.isApp) {
      AKJS.page.open("/posterStyleSetting");
    }
  };

  /**
   * 转发缺货尺码
   */
  const onForwardStockoutSizeSwitchAction = async (value: boolean, item: ItemProps) => {
    updateItemValue({ id: item.id, switchValue: value });
    await setShareConfig({ stockFlag: value ? 1 : 0 });
    if (platform.isApp) {
      akjs.event.send({ name: "event.notify.defaultChannelChange" });
      akjs.event.send({ name: "event.notify.stockFlagChange" });
    }
  };

  /**
   * 素材商品详情开关
   */
  const onMaterialProductSwitchAction = async (value: boolean, item: ItemProps) => {
    updateItemValue({ id: item.id, switchValue: value });
    await updateMaterialProductSwitch(value);
  };

  /**
   * 商品卡片
   */
  const onMiniProductAction = async (item: ItemProps) => {
    updateTemplateConfig(item, "defaultProductCardTemplateNo", shareConfig.current?.productCard);
  };
  const onMiniProductSwitchAction = async (value: boolean, item: ItemProps) => {
    if (shareConfig.current?.productCard && shareConfig.current.productCard.length > 0) {
      // 目前仅有大图模式才有 extendConfigs
      const config = shareConfig.current.productCard.find(
        i => i.extendConfigs && i.extendConfigs.length > 0,
      );
      config?.extendConfigs?.forEach(e => {
        e.value = value ? "1" : "0";
      });
      updateItemValue({
        id: item.id,
        switchValue: value,
        extendConfig: config?.extendConfigs?.[0],
      });
    }
    await setShareConfig({
      customTemplateFlag: value ? 1 : 0,
    });
    if (platform.isApp) {
      akjs.event.send({ name: "event.notify.miniProductIconFlagChange" });
    }
  };

  /**
   * 档期卡片
   */
  const onMiniActivityAction = async (item: ItemProps) => {
    updateTemplateConfig(item, "defaultActivityCardTemplateNo", shareConfig.current?.activityCard);
  };

  /**
   * 选择性转发数量
   */
  const onBatchForwardCountAction = async (item: ItemProps) => {
    setPopUpData({
      title: item.title,
      actions: [4, 6, 9].map(i => ({
        title: i.toString(),
        selected: i === shareConfig.current?.forwardNum,
        onClick: () => {
          updateItemValue({ id: item.id, value: i + "件" });
          if (shareConfig.current) {
            shareConfig.current.forwardNum = i;
          }
          setShareConfig({ forwardNum: i }).then(() => {
            if (platform.isApp) {
              akjs.event.send({ name: "event.notify.forwardCountChange" });
            }
          });
          setPopUpData(undefined);
        },
      })),
    });
  };

  const onDetailAction = (item: ItemProps) => {
    switch (item.id) {
      case "PosterQRCodeSetting":
        onPosterQRCodeSettingAction(item);
        break;
      case "PosterProduct":
        onPosterProductAction(item);
        break;
      case "PosterActivity":
        onPosterActivityAction(item);
        break;
      case "PosterStyle":
        onPosterStyleAction(item);
        break;
      case "MiniProduct":
        onMiniProductAction(item);
        break;
      case "MiniActivity":
        onMiniActivityAction(item);
        break;
      case "BatchForwardCount":
        onBatchForwardCountAction(item);
        break;
    }
  };
  const onSwitchAction = (value: boolean, item: ItemProps) => {
    switch (item.id) {
      case "ForwardStockoutSize":
        onForwardStockoutSizeSwitchAction(value, item);
        break;
      case "MiniProduct":
        onMiniProductSwitchAction(value, item);
        break;
      case "MaterialProduct":
        onMaterialProductSwitchAction(value, item);
        break;
    }
  };

  return {
    groups,
    popUpData,
    mpPopupVisible,
    setMpPopupVisible,
    setPopUpData,
    onDetailAction,
    onSwitchAction,
  };
};

export default useShareConfig;
