import { getDarkWords } from "@/api/home";
import { ReactComponent as BrandOptionsIcon } from "@/assets/brand_options.svg";
import camera from "@/assets/camera.png";
import { default as VSwiper } from "@/components/v-swiper";
import { useCommonContext } from "@/utils/context/common";
import { default as useNavigation } from "@/utils/useNavigate";
import { default as React, useEffect, useRef, useState } from "react";
import styles from "./SearchBar.module.less";

const SearchBar: React.FC = () => {
  const { skin } = useCommonContext();
  const [darkWords, setDarkWords] = useState<any[]>([]);
  const currentDarkWord = useRef<any>(null);
  const { navigate } = useNavigation();
  useEffect(() => {
    getDarkWords().then(data => {
      setDarkWords(data?.darkList ?? []);
    });
  }, []);

  const navigateTo = (url: string) => {
    navigate({
      h5Options: {
        url,
      },
    });
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.inputBox}
        style={{ borderColor: skin?.home?.searchBorderColor || "" }}
        onClick={() => navigateTo("/#/mixSearch")}>
        <img className={styles.pictureSearchIcon} src={camera} />
        <div className={styles.sperator}></div>
        <div className={styles.darkWords}>
          <VSwiper
            dataList={darkWords}
            height={29}
            stickyDuration={2000}
            rollingDuration={500}
            onIndexChange={(_, item) => (currentDarkWord.current = item)}
            childRender={item => <div className={styles.darkWord}>{item.searchWords}</div>}
          />
        </div>
        <div
          className={styles.button}
          style={{
            color: skin?.home?.searchBtnTextColor,
            backgroundColor: skin?.home?.searchBtnBgColor,
          }}>
          搜索
        </div>
      </div>
      <div
        className={styles.brandArea}
        style={{ backgroundColor: skin?.home?.nagivationBtnBgColor }}
        onClick={() => navigateTo("/#/brandWall")}>
        <BrandOptionsIcon
          className={styles.brandIcon}
          style={{ "--svgColor": skin?.home?.nagivationIconColor || "#222222" }}
        />
        <div style={{ color: skin?.home?.nagivationIconColor }} className={styles.brandText}>
          品牌
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
