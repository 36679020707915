import Exposable from "@/components/exposable";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import styles from "./ProductCard.module.less";

type Props = {
  product: any;
};

const ProductCard: React.FC<Props> = ({ product }) => {
  const { navigate2Product } = useNavigation();
  const goDetail = (item, index?: any, salePropertyFirstListPicItem?: any) => {
    navigate2Product({
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
      firstSalePropertyValue: salePropertyFirstListPicItem?.value,
    });
  };
  return (
    <Exposable
      className={styles.wrap}
      clickEventName="resource_click"
      trackData={{
        resource_type: "商品橱窗",
        resource_content: "商品",
        resource_rank: 1,
        relative_rank: product.relativeRank,
        merchant_shop_code: product.activitySimpleVO.merchantShopCode,
        activity_id: product.activityId,
        brand_id: product.brandInfo.brandId,
        activity_spu_code: product.activitySpuId,
        btn_name: "商品点击",
      }}
      trackContextKeys={["page_name", "previous_page_name", "domain"]}
      onClick={() => goDetail(product)}>
      <img src={product.skuMainImageUrls[0]} className={styles.pic} />
      <div className={styles.title}>{product.title}</div>
      <div className={styles.priceWrap}>
        <span className={styles.price}>
          {product.skuCurrentPriceInfo.promoCouponInfo?.skuTotalPostPrice ||
            product.skuCurrentPriceInfo.marketingPrice}
        </span>
        <span className={styles.tagPrice}>¥{product.skuCurrentPriceInfo.tagPrice}</span>
      </div>
      <img
        src="https://akim-oss.aikucun.com/3c363836cf4e16666669a25da280a1865c2d2874_1708683227023_86.png"
        className={styles.longLabel}
      />
    </Exposable>
  );
};

export default ProductCard;
