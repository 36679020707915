import { drawCouponListForCenter } from "@/api/coupon";
import useNavigation from "@/utils/useNavigate";
import { Mask } from "antd-mobile";
import React, { FC, useEffect, useState } from "react";
import styles from "./FallCoupon.module.less";

type Props = {
  couponInfo: any;
};

const FallCoupon: FC<Props> = ({ couponInfo }) => {
  const [visible, setVisible] = useState(couponInfo?.spCouponActivities?.length);
  const { navigate2Activity } = useNavigation();

  const drawCoupon = async () => {
    const params = {
      couponIdList: couponInfo?.spCouponActivities?.map(item => item.ticketId),
    };
    const res = await drawCouponListForCenter(params);
    if (res?.code === 0 && res?.success) {
      const { liveId } = couponInfo ?? {};
      liveId &&
        navigate2Activity({
          activityNo: liveId,
        });
      setVisible(false);
    }
  };
  return (
    <Mask visible={visible} style={{ zIndex: 1002 }} onMaskClick={() => setVisible(false)}>
      <div className={styles.overlayContent}>
        <div className={styles.header}>
          <div className={styles.title}>
            <img className={styles.logo} src={couponInfo?.brandLogoUrl} />
            <span className={styles.brandName}>{couponInfo?.brandName}</span>
          </div>
          <div>{couponInfo?.popDesc}</div>
        </div>
        <ul className={styles.list}>
          {couponInfo?.spCouponActivities?.map(item => {
            return (
              <li key={item.ticketId} className={styles.item}>
                <div className={styles.itemLeft}>
                  <span className={styles.price}>{item.amount}</span>
                </div>
                <div className={styles.itemRight}>
                  <div className={styles.couponName}>{item.couponName}</div>
                  <div className={styles.timeRange}>{item.time}</div>
                </div>
              </li>
            );
          })}
          {couponInfo.spCouponActivities.map(item => {
            return (
              <li key={item.ticketId} className={styles.item}>
                <div className={styles.itemLeft}>
                  <span className={styles.price}>{item.amount}</span>
                </div>
                <div className={styles.itemRight}>
                  <div className={styles.couponName}>{item.couponName}</div>
                  <div className={styles.timeRange}>{item.time}</div>
                </div>
              </li>
            );
          })}
        </ul>
        <div className={styles.couponBtn} onClick={drawCoupon}>
          领券去品牌逛逛<i></i>
        </div>
      </div>
    </Mask>
  );
};

export default FallCoupon;
