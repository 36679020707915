import { getRecommendProductList } from "@/api/home";
import Track from "@/utils/track";
import { urlQueryObject } from "@/utils/url-parse";
import { ProductListItemModel } from "@akc/biz-api";
import { ProductCard1NComp } from "@akc/mx-taro-h5-components";
import React, { useEffect } from "react";
import styles from "./index.module.less";

const GoodRecommendations = ({
  orderDetail,
  jumpOut2Product,
  visibleCount,
}): React.ReactElement => {
  const { userId = "userId" } = urlQueryObject();
  const track = Track();
  const [recommandProductList, setRecommandProductList] = React.useState<ProductListItemModel[]>();

  React.useEffect(() => {
    const queryRecommendProductList = async () => {
      if (!orderDetail?.activitySpuIds?.length) {
        return;
      }
      try {
        const res = await getRecommendProductList({
          activitySpuIds: orderDetail?.activitySpuIds,
          categoryCodeList: orderDetail?.categoryCodeList,
          scene: "orderPaySuc",
          pageNo: 1,
          pageSize: visibleCount,
          pageId: "H5_ProductList",
          requestId: (orderDetail?.userId ?? "userId") + Date.now(),
          voConfig: { showType: 2 },
          shopBizCode: orderDetail?.shopId,
          distributorId: orderDetail?.distributorId,
        });
        setRecommandProductList((res.result || []) as any);
      } catch (err) {
        // Do nothing
      }
    };
    queryRecommendProductList();
  }, [orderDetail]);

  const onProdutClick = e => {
    jumpOut2Product({
      activityId: e.activityId,
      activitySpuId: e.activitySpuId,
      selectSkuId: e.selectSkuId,
    });
  };

  const trackResourceExposeEvent = (product, index) => {
    const trackParams = {
      page_name: " 支付成功页",
      previous_page_name: "来源页面",
      domain: "支付成功页",
      module: "支付成功好货推荐",
      resource_type: "支付成功好货推荐",
      resource_content: "商品",
      resource_name: "支付成功好货推荐",
      resource_id: product.activitySpuId,
      resource_rank: index + 1,
      recommend_requestid: userId + Date.now(),
      activity_id: product.activityId,
      activity_spu_code: product.activitySpuId,
      sku_id: product.selectSkuId,
      product_model: product.activityType === 4 ? "普通商品" : "档期商品",
    };
    track.track("resource_expose", trackParams);
  };

  useEffect(() => {
    if (recommandProductList?.length) {
      recommandProductList.forEach((product, index) => {
        trackResourceExposeEvent(product, index);
      });
    }
  }, [recommandProductList]);

  const trackResourceClickEnterEvent = (product, index) => {
    const trackParams = {
      page_name: " 支付成功页",
      previous_page_name: "来源页面",
      domain: "支付成功页",
      module: "支付成功好货推荐",
      btn_name: "商品点击",
      resource_type: "支付成功好货推荐",
      resource_content: "商品",
      resource_name: "支付成功好货推荐",
      resource_id: product.activitySpuId,
      resource_rank: index + 1,
      recommend_requestid: product.searchRequestId || userId + Date.now(),
      click_activity_id: product.activityId,
      click_activity_spu_code: product.activitySpuId,
      click_sku_id: product.selectSkuId,
      product_model: product.activityType === 4 ? "普通商品" : "档期商品",
      price: product.skuCurrentPriceInfo.sowingPrice,
    };
    console.log(trackParams);
    track.track("resource_click", trackParams);
  };

  if (!recommandProductList?.length) {
    return <></>;
  }

  return (
    <div className={styles["goods-recommond"]}>
      <div className={styles["goods-recommond-title"]}>
        <img
          className={styles["flag"]}
          src="https://akim-oss.aikucun.com/saas/518edcdf460cd0094a1e816cbc116011c403c706_1654505998379_9.png"
        />
        <span className={styles["text"]}>好货推荐</span>
        <img
          className={styles["flag"]}
          src="https://akim-oss.aikucun.com/saas/518edcdf460cd0094a1e816cbc116011c403c706_1654505998379_9.png"
        />
      </div>
      <div className={styles["product-list-wrap"]}>
        {recommandProductList?.map((product, index) => (
          <div className={styles["product-wrap"]} key={index}>
            <ProductCard1NComp
              key={index}
              product={product}
              titleLines={1}
              onProdutClick={p => {
                trackResourceClickEnterEvent(product, index);
                onProdutClick(p);
              }}
              showBrandName={true}
              showBottomTag={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoodRecommendations;
