import { CategoryInfo } from "@/pages/youxue/model";
import React, { useEffect, useRef, useState } from "react";
import Tab from "../tab";
import styles from "./index.module.less";

type Props = {
  list?: CategoryInfo[];
  onCategoryShowOrHide: (isShow?: boolean) => void;
  onSelectionChange?: (category?: CategoryInfo) => void;
};

const CategoryTab: React.FC<Props> = ({ list, onCategoryShowOrHide, onSelectionChange }) => {
  const categoryTabDom = useRef<HTMLDivElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<CategoryInfo>();

  useEffect(() => {
    if (list === undefined) {
      return;
    }
    setSelectedCategory(list?.[0]);
  }, [list]);
  const onClickCategory = (index: number) => {
    setSelectedCategory(list?.[index]);
    onSelectionChange?.(list?.[index]);
  };

  return (
    <>
      {list && (
        <div className={styles.container}>
          <div ref={categoryTabDom}>
            <Tab
              data={list?.map(item => ({
                text: item.categoryName,
              }))}
              activeIndex={
                list?.findIndex(item => item.categoryCode === selectedCategory?.categoryCode) ?? 0
              }
              onChange={onClickCategory}
              expandable={true}
              onExpand={onCategoryShowOrHide}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryTab;
