import React from "react";
import ProductCardComp from "./components/product-card";
import { useTradeSnapshotList } from "./hook/useTradeSnapshotList";
import styles from "./list.module.less";

//交易快照列表页面
const TradeSnapshotList: React.FC = () => {
  const { ...control } = useTradeSnapshotList();
  return (
    <div className={styles.tradeSnapshotListC}>
      <div className={styles.desc}>
        交易快照包含订单创建时的商品描述和下单信息，买卖双方和平台在发生交易争议时，该页面作为判定依据。
      </div>
      <div className={styles.title}>订单包含的商品</div>
      {control.productList?.length > 0 && (
        <>
          {control.productList?.map((item, index) => (
            <ProductCardComp
              key={index}
              product={item}
              onSnapshotClick={() => {
                control.goProductSnapshotDetail(item, index);
              }}
            />
          ))}
        </>
      )}
      {control.showEmpty && (
        <div className={styles.empty}>
          <img
            className={styles.empty_icon}
            src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
          />
          <div>暂无相关明细</div>
        </div>
      )}
    </div>
  );
};

export default TradeSnapshotList;
