import { getUrlParam } from "../../../utils/url-parse";

const { merchantShopCode } = getUrlParam();

export const initParams = (): any => {
  const {
    activitySpuId = "",
    baseProductId = "",
    queryType = "",
    activityNo,
    merchantShopCode,
    resourceCode,
  } = getUrlParam();
  let queryParams = {};
  if (activitySpuId && queryType) {
    queryParams = {
      // 商品详情页跳过来的
      useType: 1,
      queryType: 14,
      objectIds: [activitySpuId],
      baseProductIds: [baseProductId],
    };
  } else if (merchantShopCode && resourceCode) {
    queryParams = {
      // 查询店铺维度下素材列表
      queryType: 17,
      objectIds: [merchantShopCode],
      resourceCode,
    };
  } else {
    queryParams = {
      // 档期详情页跳过来的
      useType: 2,
      queryType: 5,
      objectIds: activityNo.split(","),
    };
  }
  return queryParams;
};

export const sortListFunc = merchantShopCode => {
  return merchantShopCode
    ? [
        {
          name: "综合",
          code: 11,
        },
        {
          name: "销量",
          code: 31,
        },
        {
          name: "转发量",
          code: 34,
        },
        {
          name: "发布时间",
          code: 1,
        },
        {
          name: "转化率",
          code: 38,
        },
      ]
    : [
        {
          name: "综合",
          code: 11,
        },
        {
          name: "销量",
          code: 31,
        },
        {
          name: "价格",
          code: 29,
        },
        {
          name: "转发量",
          code: 34,
        },
        {
          name: "发布时间",
          code: 1,
        },
        {
          name: "转化率",
          code: 38,
        },
      ];
};
