import { ProductDetailModel, SKUBaseInfoListItem } from "@akc/biz-api";
import { Image, Popup } from "antd-mobile";
import React, { ReactNode } from "react";
import styles from "./index.module.less";

type Props = {
  dialogVisible: boolean;
  onClose?: () => void;
  medicationGuidanceList?: SKUBaseInfoListItem[];
};

/**
 * OTC 用药指导弹窗
 */
const MedicateGuidanceDialog: React.FC<Props> = props => {
  const medicationGuidanceList = props.medicationGuidanceList ?? [];
  return (
    <Popup
      visible={props.dialogVisible}
      onMaskClick={() => {
        props.onClose?.();
      }}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        zIndex: 10000,
      }}>
      <div className={styles.medicateC}>
        <div className={styles.top}>
          <div className={styles.title}>用药指导</div>
          <Image
            className={styles.close}
            src="https://devdevnew.obs.cn-east-3.myhuaweicloud.com/taro/resources/close_black.png"
            onClick={() => {
              props.onClose?.();
            }}
          />
        </div>
        <div className={styles.body}>
          {medicationGuidanceList?.map((e, index) => {
            return (
              <div className={styles.textContainer} key={index}>
                <div className={styles.text}>【{e.itemCode}】</div>
                <div className={styles.subText}>{e.itemName}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.bottomC}>
          <div
            className={styles.confirmBtn}
            onClick={() => {
              props.onClose?.();
            }}>
            完成
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default MedicateGuidanceDialog;
