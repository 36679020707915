import { useEffect } from "react";

const useStickyStateObserver = (
  dom: HTMLDivElement | null,
  onStickyStateChange: (isSticky: boolean) => void,
) => {
  useEffect(() => {
    if (!dom) {
      return;
    }

    const getParentScrollView = (element: HTMLElement) => {
      if (!element) {
        return null;
      }
      const parent = element.parentElement;
      if (parent === document.body || parent === null) {
        return null;
      }
      if (getComputedStyle(parent).overflowY === "scroll") {
        return parent;
      }
      return getParentScrollView(parent);
    };

    const scrollView = getParentScrollView(dom);
    const containerHeight = scrollView
      ? parseInt(window.getComputedStyle(scrollView).height)
      : window.innerHeight;

    if (
      isNaN(parseInt(getComputedStyle(dom).top)) ||
      isNaN(parseInt(getComputedStyle(dom).height))
    ) {
      return;
    }
    const top = parseInt(getComputedStyle(dom).top);
    const height = parseInt(getComputedStyle(dom).height);
    const div = document.createElement("div");
    div.setAttribute(
      "style",
      `position:absolute;left:0;right:0;visibility:hidden;height:${height - 1}px;`,
    );
    div.style.setProperty("top", `calc(-${top / 50}rem + ${containerHeight - height}px)`);
    dom?.appendChild(div);

    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.intersectionRatio >= 1) {
          onStickyStateChange(true);
        } else {
          onStickyStateChange(false);
        }
      },
      { threshold: 1 },
    );
    observer.observe(div);

    return () => {
      dom?.removeChild(div);
      observer.unobserve(div);
      observer.disconnect();
    };
  }, [dom]);
};

export default useStickyStateObserver;
