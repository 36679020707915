import { ActivityTaskItem } from "@/api/rewardCenter";
import { queryAggregationStatistics, queryTask } from "@/api/yearEndBonus";
import Track from "@/utils/track";
import { getUrlParam } from "@/utils/url-parse";
import constate from "constate";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

type actSellRebateStatus = 10 | 20 | 30 | 40;

interface ActivityTaskParams {
  actSellRebateStatus?: actSellRebateStatus;
  pageIndex: number;
  pageSize?: number;
  taskAggregationCode: string;
}

interface tabItem {
  name: string;
  value: actSellRebateStatus;
  total: number;
}

interface IProps {
  query?: ActivityTaskParams;
  type?: "Search" | "Reward";
  setQuery?: (query: ActivityTaskParams) => void;
}

const tabList: tabItem[] = [
  {
    name: "未开始",
    value: 10,
    total: 0,
  },
  {
    name: "进行中",
    value: 20,
    total: 0,
  },
  {
    name: "开奖中",
    value: 30,
    total: 0,
  },
  {
    name: "已开奖",
    value: 40,
    total: 0,
  },
];

const useGoldMedal = ({ query, setQuery }: IProps) => {
  const track = Track();
  const [search] = useSearchParams();
  const taskAggregationCode = search.get("activityCode") || "";
  const [pageName, setPageName] = useState("");
  const [tabIndex, setTabIndex] = useState(1);
  const [activityData, setActivityData] = useState<any>({});
  const [list, setList] = useState<ActivityTaskItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  //获取tab下的列表
  const queryList = async (params?: ActivityTaskParams) => {
    // if (loading) {
    //   return;
    // }
    setLoading(true);
    const { pageIndex } = params || {};
    let num = pageIndex || pageNum || 1;
    let actList: any = [];
    try {
      if (num === 1 || params?.actSellRebateStatus !== tabList[tabIndex].value) {
        setList([]);
      }
      const data: any = await queryTask({ ...params, pageIndex: num, pageSize: 10 });
      actList = data?.result || [];
      if (num === 1) {
        setList(actList);
      } else {
        setList(list.concat(actList));
      }
      setPageNum(num + 1);
      setHasMore(data?.hasNext);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  // 加载更多
  const loadMore = async () => {
    if (hasMore) {
      await queryList();
    }
  };

  const getAggregationStatistics = async () => {
    setPageLoading(true);
    const data: any = await queryAggregationStatistics({
      taskAggregationCode,
    });
    setActivityData(data || {});
    setPageLoading(false);
  };

  const onSearch = (index = tabIndex) => {
    setLoading(false);
    setTabIndex(() => index);
    queryList({
      actSellRebateStatus: tabList[index]?.value,
      pageIndex: 1,
      taskAggregationCode,
    });
  };

  const onSetTabIndex = (index = 0) => {
    setTabIndex(index);
    track.track("resource_click", {
      page_name: pageName,
      domain: "年终奖",
      tab_name: tabList[index]?.name,
      resource_rank: index,
      btn_name: tabList[index]?.name,
    });
  };

  useEffect(() => {
    if (!taskAggregationCode) {
      return;
    }
    setLoading(false);
    queryList({
      actSellRebateStatus: tabList[tabIndex].value,
      pageIndex: 1,
      taskAggregationCode,
    });
  }, [tabIndex, taskAggregationCode]);

  useEffect(() => {
    if (!taskAggregationCode) {
      return;
    }
    getAggregationStatistics();
  }, [taskAggregationCode]);

  return {
    activityData,
    tabList,
    tabIndex,
    setTabIndex,
    onSetTabIndex,
    list,
    loading,
    hasMore,
    pageNum,
    queryList,
    loadMore,
    onSearch,
    pageLoading,
    setPageName,
  };
};

export default useGoldMedal;
