import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  useIsEmptyStatus,
  useProductList,
  useSelectedActivity,
} from "../../hooks/useActivityProduct";
import ProductList from "../ProductList1";
import SortAndFilter from "./../SortAndFilter";
import styles from "./landing-product-list.module.less";

interface Props {
  ref: any;
  onScrollTop: () => void;
  setDisableScroll: (bool) => void;
  setShowEmptyStatus: (bool) => void;
  handleSetIsSticky: (bool) => void;
  trackData?: any;
}

const LandingProductList = forwardRef<any, Props>(
  ({ onScrollTop, setDisableScroll, setShowEmptyStatus, handleSetIsSticky, trackData }, ref) => {
    const selectedActivity = useSelectedActivity();
    const productList = useProductList();
    const isEmptyStatus = useIsEmptyStatus();

    const watchStickyRef = useRef<any>(null);
    const [isSticky, setIsSticky] = useState(false);
    const sortAndFilterDomRef = useRef<any>(null);
    const [showPanel, setShowPanel] = useState(false);
    const selectorContainerDom = useRef<HTMLDivElement>(null);
    const sortAndFilterWrapper = useRef<HTMLDivElement>(null);
    const productWaterfallDom = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!watchStickyRef.current) {
        return;
      }
      const observer = new IntersectionObserver(entries => {
        const entry = entries[0];
        setIsSticky(!entry.intersectionRatio);
        handleSetIsSticky(!entry.intersectionRatio);
      });
      observer.observe(watchStickyRef.current);

      return () => {
        observer?.disconnect();
      };
    }, [watchStickyRef.current]);

    useEffect(() => {
      setShowEmptyStatus(isEmptyStatus);
    }, [isEmptyStatus]);

    useImperativeHandle(ref, () => {
      return {
        productList,
        selectedActivity,
      };
    });

    return (
      <div className={styles.body__product}>
        <div ref={watchStickyRef} className={styles["scroll__watch-sticky-line"]}></div>
        <div className={styles.product__sticky}>
          {platform.isH5 && (
            <div
              style={{
                position: isSticky ? "relative" : "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                background: isSticky ? "#fff" : "transparent",
              }}></div>
          )}

          <div
            className={styles.sticky__body}
            style={{
              borderRadius: isSticky || showPanel ? "0" : "10px 10px 0 0",
            }}>
            <div
              ref={sortAndFilterWrapper}
              className={styles.sticky}
              style={{
                zIndex: 2,
                top: pxtorem(44),
                borderRadius: isSticky ? "10px" : 0,
              }}>
              <SortAndFilter
                ref={sortAndFilterDomRef}
                onSortFilterChange={() => {
                  onScrollTop();
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={productWaterfallDom}
          style={{ scrollMarginTop: pxtorem(88) }}
          className={styles.product__list}>
          <ProductList trackData={trackData} />
        </div>
      </div>
    );
  },
);

export default LandingProductList;
