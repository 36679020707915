import { pxtorem } from "@/utils/pxtorem";
import React, { useRef } from "react";
import { ProductProvider } from "../hooks/useProduct";
import ActivityList from "./ActivityList";
import ProductList from "./ProductList";
import styles from "./ResultList.module.less";
import ShopList from "./ShopList";

interface Props {
  relevanceType: string;
  setCardList?: (any) => void;
  trackData?: any;
}

const ResultList: React.FC<Props> = ({ relevanceType, setCardList, trackData }) => {
  const productWaterfallDom = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.resultList}>
      <h3 className={styles.list__title}>参与活动</h3>
      <div className={styles.list__body}>
        {relevanceType === "PRODUCT" && (
          <ProductProvider>
            <div
              ref={productWaterfallDom}
              style={{
                scrollMarginTop: pxtorem(88),
              }}
              className={styles.waterfallWrapper}>
              <ProductList setCardList={setCardList} trackData={trackData} />
            </div>
          </ProductProvider>
        )}
        {relevanceType === "ACTIVITY" && (
          <ActivityList setCardList={setCardList} trackData={trackData} />
        )}
        {relevanceType === "STORE" && <ShopList setCardList={setCardList} trackData={trackData} />}
      </div>
    </div>
  );
};

export default ResultList;
