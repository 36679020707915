import { queryProductList } from "@/api/product";
import { PromotionRulesModel, queryCoupon, queryPromotionRules } from "@/api/promote";
import { CommonContext } from "@/utils/context";
import { useCommonContext } from "@/utils/context/common";
import { CouponModel, NetworkError, ProductModel } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import React from "react";
import { useSearchParams } from "react-router-dom";

export const useJoinOrderNX = () => {
  const [searchParams] = useSearchParams();
  const [productList, setProductList] = React.useState<ProductModel[]>([]);
  const [hasNext, setHasNext] = React.useState(true);
  const nextPageNum = React.useRef(1);
  const curPromoActivityId = React.useRef<string>();
  const curPromoActivityInfo = React.useRef<PromotionRulesModel>();

  const { track, currentRole } = useCommonContext();

  // const [couponInfo, setCouponInfo] = React.useState<CouponModel>();
  // 活动规则描述
  const [ruleDesc, setRuleDesc] = React.useState<string>();
  // 分佣规则
  const [theCommissionRule, setTheCommissionRule] = React.useState<string>();

  React.useEffect(() => {
    document.title = "多买优惠";
    track.pageView?.("营销凑单页", {
      domain: "年终奖",
    });
  }, []);

  const loadProductList = React.useCallback(async (reload = false) => {
    const awdInstanceId = searchParams.get("awdInstanceId");

    if (reload) {
      nextPageNum.current = 1;
    }

    const res = await queryProductList("H5_ProductList", {
      pageNum: nextPageNum.current,
      pageSize: 10,
      promoActivityIds: curPromoActivityId.current ? [curPromoActivityId.current] : undefined,
      awdInstanceId,
      scene: "JOIN_ORDER_PAGE",
    });
    nextPageNum.current = (res?.pageIndex ?? 1) + 1;
    setHasNext(res?.hasNext ?? false);
    setProductList(pre => {
      const newList =
        res?.result?.map((item, index) => ({
          ...item,
          rank: productList.length + index,
        })) ?? [];
      if (reload) {
        return newList;
      }
      return [...pre, ...newList];
    });
  }, []);

  React.useEffect(() => {
    const marketingId = searchParams.get("marketingId");
    const awdInstanceId = searchParams.get("awdInstanceId");
    const curPromoActivityName = searchParams.get("curPromoActivityName");
    const cPromoActivityId = searchParams.get("curPromoActivityId");
    const couponId = searchParams.get("couponId");
    const voucherNo = searchParams.get("voucherNo");

    if (couponId && voucherNo) {
      // 查询优惠券信息
      // 暂时忽略优惠券
      /*const func = async () => {
        try {
          const model = await queryCoupon({
            ownerType: "CONSUMER",
            couponId,
            voucherNo,
          });
          if (model) {
            setCouponInfo(model);
          }
        } catch (error) {
          Toast.show((error as NetworkError)?.message ?? "服务异常，请稍后重试！");
        }
      };
      func();*/
    }

    if (!marketingId) {
      loadProductList(true);
      return;
    }
    // 查询活动规则
    const loadPromotionRules = async () => {
      const promoActivityIds = marketingId.split(",");
      try {
        const rules = await queryPromotionRules(promoActivityIds);
        console.log("rules", rules);
        if (!rules || rules.length === 0) {
          return;
        }
        const index = cPromoActivityId
          ? rules.findIndex(rule => rule.promoActivityId === cPromoActivityId)
          : 0;
        const info = rules[index];
        curPromoActivityId.current = info?.promoActivityId;
        curPromoActivityInfo.current = info;
        setRuleDesc(info?.promoRuleDesc?.join());
        setTheCommissionRule(info?.promoRuleCommissionDesc?.[0]);
        loadProductList(true);
      } catch (error) {
        Toast.show((error as NetworkError)?.message ?? "查询活动规则失败");
      }
    };
    loadPromotionRules();
  }, []);

  const [isCommissionDetailVisible, setIsCommissionDetailVisible] = React.useState(false);

  const onCommissionClick = React.useCallback(() => {
    setIsCommissionDetailVisible(true);
  }, []);

  return {
    ruleDesc,
    // 分佣规则 C 不可见
    isCommissionVisible: ["R", "E", "A"].includes(currentRole),
    theCommissionRule,
    productList,
    hasNext,
    loadMore: loadProductList,
    onCommissionClick,
    isCommissionDetailVisible,
    setIsCommissionDetailVisible,
  };
};
