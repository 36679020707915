import { ActivityTaskParams } from "@/api/rewardCenter";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { Badge } from "antd-mobile";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "../index.module.less";
import FilterItems from "./filter-items";
import { ReactComponent as ArrowIcon } from "./icons/ArrowIcon.svg";
import { ReactComponent as FilterIcon } from "./icons/FilterIcon.svg";

// const firstLevel = [
//   {
//     key: "FULL_RETURN",
//     value: "FULL_RETURN",
//     name: "满返任务",
//   },
//   {
//     key: "GIFT_RETURN",
//     value: "GIFT_RETURN",
//     name: "满赠任务",
//   },
//   {
//     key: "RANK",
//     value: "RANK",
//     name: "PK任务",
//   }
//   ...(isR
//     ? [
//       {
//         key: "RANK",
//         value: "RANK",
//         name: "PK任务",
//       }
//     ]
//     : []),
// ];
const firstLevel = ({
  isR
}): {
  key: string;
  value: string;
  name: string;
}[] => [
    {
      key: "FULL_RETURN",
      value: "FULL_RETURN",
      name: "满返任务",
    },
    {
      key: "GIFT_RETURN",
      value: "GIFT_RETURN",
      name: "满赠任务",
    },
    {
      key: "FULL_RETURN_NEW_C",
      value: "FULL_RETURN_NEW_C",
      name: "拓新任务",
    },
    ...(isR
      ? [
        {
          key: "RANK",
          value: "RANK",
          name: "PK任务",
        }
      ]
      : []),
  ];
const numInfoKeyMap = {
  FULL_RETURN: "fullReturnRedPoint",
  RANK: "rankRedPoint",
  GIFT_RETURN: "giftReturnRedPoint",
};

const statusTextEnums = {
  10: "未参加",
  20: "进行中",
  30: "开奖中",
  40: "已开奖",
};
const secondLevel = [
  {
    key: "actStatus10",
    value: 10,
    name: "未开始",
  },
  {
    key: "actStatus20",
    value: 20,
    name: "进行中",
  },
  {
    key: "actStatus30",
    value: 30,
    name: "开奖中",
  },
  {
    key: "actStatus40",
    value: 40,
    name: "已开奖",
  },
];
const filters = [
  // {
  //   key: "isDraw",
  //   name: "状态",
  //   defaultValue: undefined,
  //   list: [
  //     {
  //       key: undefined,
  //       value: undefined,
  //       name: "全部状态",
  //     },
  //     {
  //       key: true,
  //       value: true,
  //       name: "已中奖",
  //     }
  //   ]
  // },
  {
    key: "date",
    name: "时间",
    defaultValue: 0,
    list: [
      {
        key: 0,
        value: 0,
        name: "全部时间",
      },
      {
        key: 3,
        value: 3,
        name: "近3天",
      },
      {
        key: 7,
        value: 7,
        name: "近7天",
      },
      {
        key: "date",
        value: "date",
        name: "自定义",
      },
    ],
  },
  {
    key: "shopType",
    name: "店铺",
    defaultValue: 1,
    list: [
      {
        key: 1,
        value: 1,
        name: "全部店铺",
      },
      {
        key: 2,
        value: 2,
        name: "关注店铺",
      },
    ],
  },
];

const OpenFilter = ({ active, open, toggle }: { active: boolean; open: boolean; toggle: (open: boolean) => void }) => {
  return <div
    className={styles.filterItem}
    style={{ marginLeft: "auto", color: active ? "#FF4747" : "#666666", borderColor: "transparent", backgroundColor: "transparent", paddingRight: 0 }}
    onClick={() => toggle(!open)}
  >
    筛选{open ? <ArrowIcon style={{ width: pxtorem(16), height: pxtorem(16) }}></ArrowIcon> : <FilterIcon style={{ width: pxtorem(16), height: pxtorem(16) }}></FilterIcon>}
  </div>;
};

interface IProps {
  open: boolean;
  numInfo: any;
  value: ActivityTaskParams;
  toggleFilter: (open: boolean) => void;
  onFilter?: (query: ActivityTaskParams, refreshAll?: boolean) => void;
  isR: boolean
}

const RewardFilters: React.FC<IProps> = ({ open, value, numInfo, toggleFilter, onFilter, isR }) => {
  const track = Track();
  const [search] = useSearchParams();
  const [resetFlag, setResetFlag] = useState(0);
  const [hasFilter, setHasFilter] = useState(false);
  const [query, setQuery] = useState<ActivityTaskParams>(value);

  //修改筛选项
  const changeFilter = useCallback(
    (key: keyof ActivityTaskParams, value: any) => {
      console.log("changeFilter", query, key, value);
      setQuery({
        ...(query || {}),
        [key]: value,
      });
    },
    [query],
  );
  const checkFilter = params => {
    if (params.isDraw || params.date !== 0 || params.shopType !== 1) {
      setHasFilter(true);
    } else {
      setHasFilter(false);
    }
  };
  const computedQuery = (query: ActivityTaskParams) => {
    const params = query;
    if (Array.isArray(params?.date) && params?.date?.length === 2) {
      params.beginDate = moment(params?.date[0]).valueOf();
      params.endDate = moment(params?.date[1]).valueOf();
    } else if (typeof params?.date === "number" && params?.date > 0) {
      params.beginDate = moment().subtract(params.date, "days").valueOf();
      params.endDate = moment().valueOf();
    } else {
      params.beginDate = undefined;
      params.endDate = undefined;
    }
    if (params.actSellRebateStatus !== 40) {
      params.isDraw = undefined;
    }
    checkFilter(params);
    return params;
  };
  //筛选tab
  const handleTabChange = (key: keyof ActivityTaskParams, value: any) => {
    const data: ActivityTaskParams = {
      ...(query || {}),
      taskWinningStatus: undefined,
      [key]: value,
    };
    // 如果是已开奖则中奖状态设置为已中奖
    if (data.actSellRebateStatus === 40) {
      data.taskWinningStatus = "WINNING";
    }
    // 如果是进行中则中奖状态设置为有业绩
    if (data.actSellRebateStatus === 20) {
      data.taskWinningStatus = "HAS_GMV";
    }
    if (key === "actSellRebateStatus") {
      track.track("btn_click", {
        page_name: window.location.pathname.includes("reward-search")
          ? "奖励中心/奖励中心搜索结果"
          : "奖励中心",
        previous_page_name: search.get("page_name"),
        domain: "激励活动",
        tab_name: query.searchType === 2 ? "权益" : "限时激励",
        btn_name: statusTextEnums[value],
      });
    }
    setQuery(data);
    onFilter?.(computedQuery(data) as ActivityTaskParams, key === "promoActivityType");
  };
  //确认筛选
  const submitFilter = () => {
    toggleFilter(false);
    console.log("submitFilter", query);
    onFilter?.(computedQuery(query) as ActivityTaskParams, true);
  };
  //重置筛选
  const resetFilter = () => {
    const data: ActivityTaskParams = {
      ...query,
      // promoActivityType: "FULL_RETURN",
      // actSellRebateStatus: 10,
      isDraw: undefined,
      shopType: 1,
      beginDate: undefined,
      date: 0,
      endDate: undefined,
    };
    setQuery(data);
    setResetFlag(resetFlag + 1);
    toggleFilter(false);
    onFilter?.(data as ActivityTaskParams, true);
    checkFilter(data);
  };

  // 同步查询条件
  useEffect(() => {
    console.log("filters useEffect", value);
    if (JSON.stringify(value) !== JSON.stringify(query)) {
      setQuery(value);
      checkFilter(value);
    }
  }, [value]);
  // 关闭筛选恢复选择项
  useEffect(() => {
    setTimeout(() => {
      if (!open) {
        setQuery(value);
      }
    }, 100);
  }, [open]);

  return (
    <>
      <div
        className={`${styles.filters} ${open ? styles.filtersOpen : ""}`}
        onClick={e => e.stopPropagation()}>
        {[1, 2].includes(query.searchType as number) && (
          <div className={styles.filterTab}>
            {firstLevel({ isR }).map((item, index) => {
              return (
                <div
                  key={index}
                  className={query?.promoActivityType === item.value ? styles.active : ""}
                  onClick={() => handleTabChange("promoActivityType", item.value)}>
                  <Badge
                    content={numInfo[numInfoKeyMap[item.key]] ? Badge.dot : ""}
                    style={{
                      minWidth: pxtorem(8),
                      width: pxtorem(8),
                      height: pxtorem(8),
                      borderRadius: pxtorem(4),
                      top: pxtorem(2),
                      right: "-" + pxtorem(2),
                    }}>
                    {item.name}
                  </Badge>
                </div>
              );
            })}
          </div>
        )}
        <div className={styles.filterListBox + " " + styles.filterList}>
          {secondLevel.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  styles.filterItem +
                  " " +
                  (query?.actSellRebateStatus === item.value ? styles.active : "")
                }
                onClick={() => handleTabChange("actSellRebateStatus", item.value)}>
                {item.name}
                {numInfo?.[item.key] > 0 && (
                  <div
                    className={styles.badge}
                    data-label={numInfo?.[item.key] > 99 ? "99+" : numInfo?.[item.key]}></div>
                )}
              </div>
            );
          })}
          <OpenFilter
            active={hasFilter}
            open={open}
            toggle={open => toggleFilter(open)}></OpenFilter>
        </div>
        {open && (
          <div className={styles.moreFilter}>
            {filters.map((filter, index) => {
              if (query.actSellRebateStatus !== 40 && filter.key === "isDraw") {
                return "";
              }
              return (
                <div key={index} className={styles.pb12}>
                  <FilterItems
                    type={filter.key}
                    resetFlag={resetFlag}
                    value={query[filter.key as keyof ActivityTaskParams]}
                    options={filter.list}
                    onChange={value => changeFilter(filter.key as keyof ActivityTaskParams, value)}
                  />
                </div>
              );
            })}
            <div className={styles.btns}>
              <div className={styles.reset} onClick={() => resetFilter()}>
                重置
              </div>
              <div className={styles.submit} onClick={() => submitFilter()}>
                确定
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default RewardFilters;
