import React from "react";
import { CascadePicker } from "antd-mobile";
import { optionsPicker } from "./data";
import style from "./index.module.less";

interface Props {
  visible?: boolean;
  defaultValue?: string[];
  onClosePress?: () => void;
  onConfirmPress?: (val: string[]) => void;
}

export const YXMouthPicker: React.FC<Props> = ({
  visible,
  defaultValue,
  onClosePress,
  onConfirmPress,
}) => {
  return (
    <>
      <div className={style.page}>
        <CascadePicker
          className={style.picker}
          options={optionsPicker()}
          cancelText={<div style={{ color: "#A5A5A5", fontSize: 16, marginLeft: 10 }}>取消</div>}
          confirmText={
            <div style={{ color: "#FF4747", fontSize: 16, fontWeight: "bold", marginRight: 10 }}>
              确定
            </div>
          }
          visible={visible}
          defaultValue={defaultValue}
          onClose={() => {
            onClosePress?.();
          }}
          onConfirm={val => {
            if (val && val.length > 0) {
              console?.log(`${val[0]?.toString()}  ${val[1]?.toString()} `);
              onConfirmPress?.([val[0]?.toString() || "", val[1]?.toString() || ""]);
            }
          }}
          onSelect={val => {
            console.log("onSelect", val);
          }}
        />
      </div>
    </>
  );
};
