import Clickable from "@/components/clickable";
import { Switch } from "antd-mobile";
import React from "react";
import { ItemProps } from "../../props";
import styles from "./index.module.less";

interface Props extends ItemProps {
  onDetailClick?: (item: ItemProps) => void;
  onSwitchChanged?: (value: boolean, item: ItemProps) => void;
  trackData?: Record<string, any>;
}

const SettingItem: React.FC<Props> = props => {
  switch (props.type) {
    case "detail":
      return (
        <Clickable
          className={
            styles.item + " " + styles.detail + " " + (props.subTitle ? styles.subTitleItem : "")
          }
          onClick={() => props.onDetailClick?.(props)}
          trackData={props.trackData}>
          <div className={styles.detailContentTop}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.aside}>{props.value}</div>
          </div>
          {props.subTitle && <div className={styles.subTitle}>{props.subTitle}</div>}
        </Clickable>
      );
    case "switch":
      return (
        <div
          className={
            styles.item + " " + styles.switch + " " + (props.subTitle ? styles.subTitleItem : "")
          }
          // onClick={props.onClick}
          // trackData={props.trackData}
        >
          <div
            className={styles.switchLeft}
            onClick={e => {
              e.stopPropagation();
              props.onTitleClick?.();
            }}>
            <div className={styles.titleWrap}>
              <span className={styles.title}>{props.title}</span>
              {props.onTitleClick && <span className={styles.titleDetail}>查看示例</span>}
            </div>
            {props.subTitle && <div className={styles.subTitle}>{props.subTitle}</div>}
          </div>
          <Switch
            onChange={v => props.onSwitchChanged?.(v, props)}
            checked={props.switchValue ?? false}
            className={styles.switchRight}
          />
        </div>
      );
    case "detail_switch":
      return (
        <div
          className={
            styles.item +
            " " +
            styles.detailSwitch +
            " " +
            (props.subTitle ? styles.subTitleItem : "")
          }>
          <Clickable
            className={styles.detailSwitchTop}
            onClick={() => props.onDetailClick?.(props)}
            trackData={props.trackData}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.aside}>{props.value}</div>
          </Clickable>
          {props.switchTitle && (
            <div className={styles.detailSwitchBottom}>
              <div className={styles.detailSwitchBottomTitle}>{props.switchTitle}</div>
              <Switch
                onChange={v => props.onSwitchChanged?.(v, props)}
                checked={props.switchValue ?? false}
              />
            </div>
          )}
        </div>
      );
  }
};

export default SettingItem;
