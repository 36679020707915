import { isInGrayBatch } from "@/api";

export const isNewProductDetail = () =>
  ["true", "1"].indexOf(localStorage.getItem("_n_product_detail") || "") > -1;

export const setupGrayService = () => {
  const func = async () => {
    const res = await isInGrayBatch(["B-ProductDetailRefatorH5"]);
    if (!res || res.length === 0) {
      localStorage.removeItem("_n_product_detail");
      return;
    }
    const isNewProductDetail =
      res.find(item => item.bwlCode === "B-ProductDetailRefatorH5")?.isHit === 1;
    localStorage.setItem("_n_product_detail", isNewProductDetail.toString());
  };
  func();
};
