import { OrderProductModel } from "@/api/order/model";
import { ImageURLUitls } from "@akc/foundation";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
  product?: OrderProductModel;
  onSnapshotClick?: () => void;
};
//交易快照-商品卡片组件
const ProductCardComp: React.FC<Props> = prop => {
  const product = prop.product;
  const skuStr = product?.productPropertyDescList?.join(" ");
  return (
    <div className={styles.container}>
      <Image
        className={styles.img}
        src={ImageURLUitls.normalProcessImage(product?.picUrl, 86, 86)}
        fit="contain"
      />
      <div className={styles.right}>
        <div className={styles.fir}>
          <div className={styles.title}>{product?.productTitle}</div>
          <div className={styles.price}>
            <span className={styles.symbol}>¥</span>
            {product?.productAmount}
          </div>
        </div>
        <div className={styles.sec}>
          <div className={styles.sku}>{skuStr}</div>
          <div className={styles.sale}>x{product?.integrateQuantity}</div>
        </div>
        <div className={styles.btn} onClick={prop.onSnapshotClick}>
          交易快照
        </div>
      </div>
    </div>
  );
};
export default ProductCardComp;
