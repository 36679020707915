import { ProductDetailModel } from "@akc/biz-api";
import { PromotionFeeComp } from "@akc/mx-taro-h5-components";
import { Image, Popup } from "antd-mobile";
import React, { ReactNode } from "react";
import styles from "./index.module.less";
type Props = {
  //商品模型
  productDetail?: ProductDetailModel;
  // 是否预告佣金(区分：佣金 || 预告佣金)
  isAdvanceProfit?: boolean;
  dialogVisible: boolean;
  onClose?: () => void;
};

/**
 * 推广费说明弹窗
 */
const PromotionFeeDialog: React.FC<Props> = props => {
  return (
    <Popup
      visible={props.dialogVisible}
      onMaskClick={() => {
        props.onClose?.();
      }}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        zIndex: 10000,
      }}>
      <PromotionFeeComp
        className={styles.promoFeeC}
        skuCurrentPriceInfo={props.productDetail?.skuCurrentPriceInfo}
        skuAdvancePriceInfo={props.productDetail?.skuAdvancePriceInfo}
        isAdvanceProfit={props.isAdvanceProfit}
        onClose={() => {
          props.onClose?.();
        }}
      />
    </Popup>
  );
};

export default PromotionFeeDialog;
