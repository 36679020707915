import { getCookie } from "@/utils/platform";
import { Popover } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ADMF.module.less";

interface Props {
  info: any;
}

const ADMF: React.FC<Props> = ({ info }) => {
  const [search] = useSearchParams();
  const navigater = useNavigate();
  // 目标枚举
  const thresholdIndicatorTypeEnum = {
    GMV: { desc: "销售额", unit: "元" },
    SALE_QUANTITY: { desc: "销量", unit: "件" },
    COMMISSION: { desc: "基础推广费", unit: "元" },
    SHOP_NEW_USER_QUANTITY: { desc: "拓新人数", unit: "人" },
  };
  // 奖励枚举
  const rewardTypeEnum = {
    1: { desc: "奖励", unit: "元" },
    2: { desc: "推广费比", unit: "%" },
    3: { desc: "销售额比", unit: "%" },
    4: { desc: "奖励", unit: "元" },
  };

  const [currentGmv, setCurrentGmv] = useState(0); // 当前累计
  const [rewardType, setRewardType] = useState(1); // 奖励类型
  const [ruleDetails, setRuleDetails] = useState<any>([]); // 进度数组
  const [isMultistage, setIsMultistage] = useState(false); // 进度数组是否超过3段(要展示...省略号)
  const [isRank, setIsRank] = useState(false);
  const [isR, setIsR] = useState(false);

  const gmvDom = () => {
    if (!info) {
      return;
    }
    if (isRank) {
      // 限量
      if (!info.isQualified) {
        // 没入围
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.desc}
            <span>
              {info.actSellBatePartakeInfo.currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.unit}
            </span>
            ， 仅差
            <span>
              {info?.actSellBatePartakeInfo?.unChosenAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.unit}
            </span>
            入围 ，继续努力呀~
          </p>
        );
      } else if (info?.actSellBatePartakeInfo?.currentRank === 1) {
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.desc}
            <span>
              {info.actSellBatePartakeInfo.currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.unit}
            </span>
            ，<span>排名第{info.actSellBatePartakeInfo.currentRank}名</span>
            ，领先下一名
            <span>
              {info?.actSellBatePartakeInfo?.unChosenAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.unit}
            </span>
            ，继续努力呀~
          </p>
        );
      } else {
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.desc}
            <span>
              {info.actSellBatePartakeInfo.currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.unit}
            </span>
            ，<span>排名第{info.actSellBatePartakeInfo.currentRank}名</span>
            ，距上一名仅差
            <span>
              {info?.actSellBatePartakeInfo?.unChosenAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.unit}
            </span>
            ，继续努力呀~
          </p>
        );
      }
    } else {
      if (info?.actSellBatePartakeInfo?.nextRewardAmount) {
        return (
          <p>
            当前累计
            {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
              "拓展"}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
            {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
              "数量"}
            <span>
              {currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，
            <span>
              仅差{info?.actSellBatePartakeInfo?.nextRewardAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            就可以获得
            <span>
              {info?.actSellBatePartakeInfo?.nextRuleRewardAmount}
              {rewardTypeEnum[rewardType].unit}
              {[2, 3].includes(rewardType) && rewardTypeEnum[rewardType].desc}
              奖励
            </span>
            ，继续努力呀~
          </p>
        );
      } else {
        return (
          <p>
            当前累计
            {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
              "拓展"}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
            {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
              "数量"}
            <span>
              {currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，预计可得
            <span>
              {ruleDetails[ruleDetails.length - 1].rewardAmount}
              {rewardTypeEnum[rewardType].unit}
              {[2, 3].includes(rewardType) && rewardTypeEnum[rewardType].desc}
              奖励
            </span>
            ，继续努力呀
          </p>
        );
      }
    }
  };

  const matchTypeOfDataCaliberDom = () => {
    let dom = <></>;
    switch (info?.actSellBatePartakeInfo?.thresholdIndicatorType) {
      case "GMV": // 销售额
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>{info?.actSellBatePartakeInfo?.saleAmount || 0}</span>
            <span className={styles.item__key}>累计销售额(元)</span>
          </li>
        );
        break;
      case "SALE_QUANTITY": // 销量
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalSaleQuantity || 0}
            </span>
            <span className={styles.item__key}>累计销量(件)</span>
          </li>
        );
        break;
      case "COMMISSION": // 基础推广费
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalCommission || 0}
            </span>
            <span className={styles.item__key}>累计基础推广费(元)</span>
          </li>
        );
        break;
      case "SHOP_NEW_USER_QUANTITY": // 新C
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalShopNewUserQuantity || 0}
            </span>
            <span className={styles.item__key}>累计品牌新客(人)</span>
          </li>
        );
        break;
    }
    return dom;
  };

  const getPercent = (item, index) => {
    if (item.isInfinite) { return 0; }
    if (index === 0) {
      let num = currentGmv / item.endThreshold * 100;
      return num >= 100 ? 100 : num;
    }
    if (currentGmv > item.strThreshold) {
      return currentGmv < item.endThreshold
        ? ((currentGmv - item.strThreshold) / (item.endThreshold - item.strThreshold)) * 100
        : 100;
    } else {
      return 0;
    }
  };

  const handleRuleDetails = () => {
    const ruleDetails = info?.actSellRebateRuleInfos?.ruleDetails || [];
    const maxLength = 3;
    setIsMultistage(ruleDetails.length > maxLength);
    if (ruleDetails.length <= maxLength) {
      setRuleDetails(ruleDetails);
    } else {
      /**
       * 1. 初始展示前两个阶梯+最后一个阶梯值;
       * 2. 当业绩超过两个后，销售业绩每增加一个阶梯，前面就少展示一个阶梯;
       * 3. 当业绩到最后3个阶梯间，固定展示最后3个阶梯;
       */
      let currentIndex = ruleDetails.findIndex(item => {
        return item.endThreshold > currentGmv;
      });
      let newList: any[] = [];
      let isRewardSuperimpositionType =
        info?.actSellRebateRuleInfos?.rewardSuperimpositionType === "SUPERIMPOSITION"; // 是否可叠加
      let isExistCeilingTimes = info?.actSellBatePartakeInfo?.isExistCeilingTimes; // 是否存在封顶次数
      // let ceilingTimes = info?.actSellBatePartakeInfo?.ceilingTimes; // 次数
      let isInfinite = isRewardSuperimpositionType && !isExistCeilingTimes; // 是否不封顶叠加
      if (currentIndex >= ruleDetails.length - 3) {
        newList = isInfinite
          ? [
            ...ruleDetails.slice(-2),
            {
              isInfinite: true,
              endThreshold: "多卖多得",
              rewardAmount: "上不封顶",
            },
          ]
          : ruleDetails.slice(-3);
      } else if (currentIndex > 1) {
        newList = [
          ruleDetails[currentIndex],
          ruleDetails[currentIndex + 1],
          ruleDetails[ruleDetails.length - 1],
        ];
      } else {
        newList = [ruleDetails[0], ruleDetails[1], ruleDetails[ruleDetails.length - 1]];
      }
      if (isInfinite) {
        newList[newList.length - 1] = {
          isInfinite: true,
          endThreshold: "多卖多得",
          rewardAmount: "上不封顶",
        };
        setRuleDetails(newList);
      } else {
        setRuleDetails(newList);
      }
    }
  };

  const toDistributorPage = () => {
    let envid: any = window.location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    let href = `/mx-shop-micro/reward-center/detail/distributor?token=${search.get("token")}&shopId=${search.get(
      "shopId",
    )}&channel=h5Mall&actId=${search.get("actId")}&env_identity=${envid || getCookie("X-Hades-Env-Identity")
      }`;
    if (search.get("distributorId")) {
      href += `&distributorId=${search.get("distributorId")}`;
    }
    navigater(href);
  };

  const totalDom = () => {
    if (isRank) {
      return (
        <ul className={styles.achievement__rank}>
          {
            info?.actSellBatePartakeInfo?.currentRankDetails?.length > 0 && (
              <>
                <li className={`${styles.rank__item} ${styles.rank__title}`}>
                  <span className={styles.item__0}>排名</span>
                  <span className={styles.item__1}>用户信息</span>
                  <span className={styles.item__2}>
                    {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.desc}(
                    {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]?.unit})
                  </span>
                </li>
                {info?.actSellBatePartakeInfo?.currentRankDetails[0].rank !== 1 && (
                  <li className={`${styles.rank__item}`}>
                    <span className={styles.item__0}>...</span>
                    <span className={styles.item__1}>...</span>
                    <span className={styles.item__2}>...</span>
                  </li>
                )}
                {info?.actSellBatePartakeInfo?.currentRankDetails.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={`${styles.rank__item} ${item.isCurrentUser && styles.rank__item_active
                        } ${info.isQualified && item.rank === 1 && styles.rank__1} ${info.isQualified && item.rank === 2 && styles.rank__2
                        } ${info.isQualified && item.rank === 3 && styles.rank__3}`}
                    >
                      <span className={styles.item__0}>{info.isQualified ? item.rank : "未入围"}</span>
                      <span className={`${styles.item__1} ${styles["text-ellipsis1"]}`}>
                        {item.isCurrentUser ? "我" : item.nickname || item.userCode}
                      </span>
                      <span className={styles.item__2}>{item.saleAmount || 0}</span>
                    </li>
                  );
                })}
              </>
            )
          }
        </ul>
      );
    } else {
      return (
        <>
          {
            info?.actSellBatePartakeInfo?.saleAmount > 0 && (
              <>
                <ul className={styles.achievement__info}>
                  {matchTypeOfDataCaliberDom()}
                  <li className={styles.info__item}>
                    <span className={styles.item__value}>
                      {isR ? (info?.actSellBatePartakeInfo?.shopEstimatedRewards || 0) : (info?.actSellBatePartakeInfo?.estimatedRewards || 0)}
                    </span>
                    <span className={styles.item__key}>{isR ? "店铺预估奖励(元)" : "预估奖励(元)"}</span>
                  </li>
                  <li className={styles.info__item}>
                    <span className={styles.item__value}>
                      {info?.actSellBatePartakeInfo?.totalOrderNum || 0}
                    </span>
                    <span className={styles.item__key}>订单总数</span>
                  </li>
                  <li className={styles.info__item}>
                    <span className={styles.item__value}>
                      {info?.actSellBatePartakeInfo?.afterSaleOrderNum || 0}
                    </span>
                    <span className={styles.item__key}>售后订单</span>
                  </li>
                </ul>
              </>
            )
          }
        </>
      );
    }
  };

  useEffect(() => {
    handleRuleDetails();
  }, [currentGmv]);

  useEffect(() => {
    if (!info) {
      return;
    }
    setCurrentGmv(info?.actSellBatePartakeInfo?.currentGmv || 0);
    setRewardType(info?.actSellRebateRuleInfos?.ruleDetails?.[0]?.rewardType || 1);
    setIsRank(info?.actSellBatePartakeInfo?.isLimitAwardRanking);
    setIsR(info?.currentIdentityType === 2); // 当前人身份类型: 2店主,3店长
  }, [info]);

  return (
    <>
      <div className={styles.achievement}>
        {info?.actSellBatePartakeInfo?.partakeStatus === 40 && (
          <img
            className={styles.achievement__received}
            src="https://akim-oss.aikucun.com/mshop/dc445ddc22b2697ae5fbd5168cd31abd8a7f0be3_1720255803315_87.png"
          />
        )}
        <h3 className={styles.achievement__title}>{isR ? "店铺业绩" : "我的业绩"}</h3>
        <div className={styles.achievement__progress}>
          <div className={styles.progress__list}>
            <div className={`${styles.list__title}`}>
              <div
                className={`${styles.item__icon} ${currentGmv && styles["item__icon_active"]}`}></div>
              <div className={styles.item__con}>
                <p className={styles.con__gmv}>
                  {
                    thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                      ?.desc
                  }
                  (
                  {
                    thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                      ?.unit
                  }
                  )
                </p>
                <p className={styles.con__award}>
                  {rewardTypeEnum[rewardType].desc}
                  {rewardType === 1 ? "(元)" : ""}
                </p>
              </div>
            </div>
            <ul className={styles.list__con}>
              {ruleDetails?.length &&
                ruleDetails.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={`${styles.list__item} ${ruleDetails?.length === 1 &&
                        styles.list__lenght_1
                        } ${ruleDetails?.length === 2 &&
                        styles.list__lenght_2
                        } ${isMultistage && index === 2 && styles.list__multistage
                        }`}
                    >
                      <div className={styles.item__line}>
                        <div
                          className={styles.line__current}
                          style={{
                            width: `${getPercent(item, index)}%`,
                          }}></div>
                      </div>
                      <div
                        className={`${styles.item__icon} ${currentGmv > item.endThreshold && styles["item__icon_active"]
                          }`}
                      ></div>
                      <div className={styles.item__con}>
                        <p className={styles.con__gmv}>{item.endThreshold}</p>
                        <p className={styles.con__award}>
                          {item.rewardAmount}
                          {[2, 3].includes(rewardType) && rewardTypeEnum[rewardType].unit}
                        </p>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        {currentGmv > 0 && <div className={styles.achievement__gmv}>{gmvDom()}</div>}
        <div>{totalDom()}</div>
      </div>

      {/* 预估奖励 */}
      {
        info?.distributorVisibility && (
          <div className={styles.estimated_rewards}>
            <h3 className={styles.rewards__title}>预估奖励</h3>
            <p className={styles.rewards__tips}>我的预估奖励=店铺预估奖励-店长预估奖励</p>
            <ul className={styles.rewards__data}>
              <li className={styles.data__item}>
                <span className={styles.item__value}>{info?.actSellBatePartakeInfo?.estimatedRewards || 0}</span>
                <span className={styles.item__key}>我的预估奖励(元)</span>
              </li>
              <li className={styles.data__item} onClick={toDistributorPage}>
                <span className={`${styles.item__value} ${styles.arrow}`}>{info.distributorEstimatedReward || 0}</span>
                <span className={styles.item__key}>店长预估奖励(元)</span>
              </li>
            </ul>
          </div>
        )
      }

      {/* 提示 */}
      <div className={styles.update__tips}>*销售数据每隔1小时刷新1次</div>
    </>
  );
};

export default ADMF;
