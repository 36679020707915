import { ReactComponent as Empty } from "@/assets/empty.svg";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import { ReactComponent as GuideTop } from "@/assets/toTop.svg";
import loadingPng from "@/base64";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import { ProductModel } from "@akc/biz-api";
import { ProductCard4BatchForwardV2Comp as ProductCard } from "@akc/mx-taro-h5-components";
import { Image, ImageViewer, InfiniteScroll, Skeleton, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { UseSelectiveProductContext } from "../../hooks/useSelectiveProduct";
import styles from "./index.module.less";

const ProductList: React.FC = () => {
  const {
    hasNext,
    loadMore,
    productList,
    setProductList,
    forwardNum,
    updateProductItem,
    checkedProductList,
    setCheckedProductList,
  } = UseSelectiveProductContext();

  const { navigate2Product } = useNavigation();
  const [showGuideTop, setShowGuideTop] = useState(false);

  const SkeletonItem = () => {
    return (
      <div className={styles["skeleton-item"]}>
        <Skeleton className={styles.ridio} />
        <Skeleton className={styles.product} />
        <div className={styles.paragraph}>
          <Skeleton.Paragraph lineCount={4} animated />
        </div>
      </div>
    );
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore: boolean }) => {
    return (
      <div className={styles.infinite}>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {productList?.length > 0 && (
              <>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
                <div style={{ color: "#999" }}>已经到底了</div>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  // 商品勾选
  const toggleCheckAction = (checked, product: ProductModel) => {
    if (checked && checkedProductList.length >= forwardNum) {
      Toast.show("最多选中" + forwardNum + "个商品");
      return false;
    }
    if (checked) {
      const newProductList = productList.map(item => {
        if (item.activitySpuId === product.activitySpuId) {
          return { ...item, isSelect: true };
        }
        return item;
      });
      setProductList(newProductList);
      return false;
    }
    if (!checked) {
      const newProductList = productList.map(item => {
        if (item.activitySpuId === product.activitySpuId) {
          return { ...item, isSelect: false };
        }
        return item;
      });
      setProductList(newProductList);
      return false;
    }
  };

  // 商品预览
  const previewAction = product => {
    ImageViewer.Multi.show({
      images: product.skuMainImageUrls,
      defaultIndex: 0,
    });
  };

  // 商品描述
  const onExpandAction = (expanded, product) => {
    const newProductList = productList.map(item => {
      if (item.activitySpuId === product.activitySpuId) {
        item.expandedStatus = expanded;
      }
      return item;
    });
    setProductList(newProductList);
  };

  // 商品sku
  const onSKUAction = (property, product, index) => {
    const productReqItem = {
      activityId: product?.activityId ?? "",
      activitySpuId: product?.activitySpuId ?? "",
      firstSalePropertyValue: property.value ?? "",
    };
    updateProductItem(productReqItem, index);
  };

  // 商品详情
  const itemClick = product => {
    navigate2Product({
      activitySpuId: product.activitySpuId,
      activityId: product.activityId,
      selectSkuId: product.selectSkuId,
    });
  };

  // 置顶
  const observerDomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!observerDomRef) {
      return;
    }
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.intersectionRatio === 0) {
          setShowGuideTop(true);
        }
        if (entry.intersectionRatio > 0) {
          setShowGuideTop(false);
        }
      },
      { threshold: [0, 1] },
    );
    observerDomRef.current && observer.observe(observerDomRef.current);

    return () => {
      observerDomRef.current && observer.unobserve(observerDomRef.current);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const pageDom: any = document.getElementById("selective-page");
      if (pageDom && (productList?.length ?? 0) < 2) {
        pageDom.style.overflowY = "hidden";
      } else if (pageDom) {
        pageDom.style.overflowY = "scroll";
      }
    }, 500);
  }, [productList]);

  return (
    <>
      <div className={styles["product-container"]}>
        {hasNext &&
          productList.length === 0 &&
          new Array(4).fill(0).map((_, index) => <SkeletonItem key={index} />)}
        {productList.length > 0 &&
          productList.map((product, index) => (
            <div
              key={index}
              className={`${styles["product-item"]} ${productList.length === index + 1 ? styles["last"] : ""}`}
              id={`product-${product.activitySpuId}`}>
              <ProductCard
                model={product}
                locationCode="H5_TopProduct_ProductList_PicRightTitleDown"
                supportExpand={product?.supportExpand ?? false}
                expandedStatus={product?.expandedStatus ?? false}
                checked={product.isSelect ?? false}
                selectedIndex={product.selectedIndex ?? 0}
                forwarded={(product.skuExtendInfoVO?.isShare || product.isForwarded) ?? false}
                toggleCheckAction={checked => toggleCheckAction(checked, product)}
                previewAction={previewAction}
                onExpandAction={(expanded, product) => onExpandAction(expanded, product)}
                onSKUAction={(property, product) => onSKUAction(property, product, index)}
                itemClick={itemClick}
              />
            </div>
          ))}
        {productList.length > 0 && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasNext} threshold={250}>
            <InfiniteScrollContent hasMore={hasNext} />
          </InfiniteScroll>
        )}
        {!hasNext && productList.length === 0 && (
          <div className={styles.emptyContainer}>
            <Empty />
            <div className={styles.text}>未找到相关商品</div>
          </div>
        )}
        <div className={styles["observer-dom"]} ref={observerDomRef}></div>
        {productList.length > 10 && showGuideTop && (
          <GuideTop
            className={styles["guide-top"]}
            onClick={() => {
              observerDomRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProductList;
