import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";
import { MICRO_APP_BASEURL, platform } from "@/utils/platform";
import AKJS from "@akc/akjs";
import { Image } from "antd-mobile";
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./TopBar.module.less";

interface props {
  ref?: ForwardedRef<any>;
  isDisableSearch?: boolean;
  onSearch?: (str) => void;
  isBackHostEnv: boolean;
}

const TabBar = forwardRef<any, props>(({ isDisableSearch, onSearch, isBackHostEnv }, ref) => {
  const navigater = useNavigate();
  const [search] = useSearchParams();
  const shopId = search.get("shopId");

  const [inputVal, setInputVal] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<any>(null);

  useImperativeHandle(ref, () => {
    return {
      inputVal,
    };
  });

  const goBack = () => {
    if (isBackHostEnv && platform.isApp) {
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.page.close", {});
      });
    } else {
      history.back();
    }
  };

  const toIndex = useCallback(async () => {
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  }, []);

  const toSearchPage = () => {
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/hide-setting/search?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}&token=${search.get("token")}`,
      );
    } else {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/hide-setting/search?shopId=${shopId}&channel=h5Mall&token=${search.get(
          "token",
        )}`,
      );
    }
  };

  const onClickSearchInput = () => {
    isDisableSearch && toSearchPage();
  };

  const onClickSearchBtn = () => {
    if (isDisableSearch) {
      toSearchPage();
    } else {
      onSearch && onSearch(inputVal);
      inputRef.current.blur();
    }
  };

  const changeText = e => {
    const text = e?.target?.value;
    setInputVal(text);
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      onSearch && onSearch(inputVal);
      inputRef?.current?.blur();
    }
  };

  const onFocus = e => {
    setIsFocus(true);
  };

  const onBlur = e => {
    setIsFocus(false);
  };

  return (
    <div className={styles.top_bar}>
      <div className={`${styles.bar__left} ${platform.isH5 && styles.bar__left_h5}`}>
        {(platform.isApp || platform.isH5) && (
          <div className={styles.leftImageBox} onClick={goBack}>
            <Image src={BackPng} className={styles.image}></Image>
          </div>
        )}
        {platform.isH5 && (
          <div className={styles.leftImageBox} onClick={toIndex}>
            <Image src={HomePng} className={styles.image}></Image>
          </div>
        )}
      </div>

      <div className={styles.bar__center}>
        <div className={styles.center__search} onClick={onClickSearchInput}>
          <img
            className={styles.search__icon}
            src="https://akim-oss.aikucun.com/mshop/22d54938bb770fdbde7c0f8e4d5fd62d893a2033_1719216273468_34.png"
          />
          <form
            className={styles.search__form}
            action=""
            onSubmit={e => {
              if (e.preventDefault) {
                e.preventDefault();
              }
              return false;
            }}>
            <input
              className={styles.search__text}
              type="search"
              ref={inputRef}
              value={inputVal}
              autoFocus={!isDisableSearch}
              onChange={e => changeText(e)}
              onKeyDown={e => onKeyDown(e)}
              onFocus={e => onFocus(e)}
              onBlur={e => onBlur(e)}
              placeholder="请输入品牌/店铺名称"
            />
          </form>
          {isFocus && inputVal.length > 0 && (
            <img
              className={styles.search__clear}
              src="https://akim-oss.aikucun.com/mshop/4a1a0e5ed29c0ea4cf8742c01f98270f5bebf689_1719456408138_62.png"
              onMouseDown={() => {
                setInputVal("");
                inputRef.current.focus();
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.bar__right}>
        <div className={styles.right__btn} onClick={onClickSearchBtn}>
          搜索
        </div>
      </div>
    </div>
  );
});

export default TabBar;
