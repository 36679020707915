import { getCartTotal } from "@/api";
import {
  queryMaterialDetail,
  queryRecommendByMaterialNo,
  queryRecommendList,
  setAwesome,
} from "@/api/promote";
import { getIOSVersion } from "@/utils/platform";
import { Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import { Swiper, SwiperInstance } from "react-vant";
import useShare from "../../share";
import { useTrack } from "../../track";
import VideoPlayer from "../videoPlayer";
import styles from "./index.module.less";

interface VideoEntity {
  url: string; //
  cover: string;
  creatorAvatar: string;
  name: string; // 标题
  productListVOList: Array<any>;
  activityListVOList: Array<any>;
  conferenceVOList: Array<any>;
  scheduleListVOList: Array<any>;
  allList: Array<any>;
  materialNo: string;
  awesomeNum: string;
  awesome: boolean;
}
interface RecommendProps {
  activeTab: string;
  getCart?: (params: { cartNum: number }) => void;
}
const Recommend: React.FC<RecommendProps> = (props: RecommendProps) => {
  const { activeTab, getCart } = props;
  const [videoList, setVideoList] = useState<Array<VideoEntity>>([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [muted, setMuted] = useState(false);
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const materialNo = search.get("materialNo");
  const [cartTotal, setCartTotal] = useState(0);
  const fromPage = search.get("fromPage");
  const pageSize = 10;
  const { trackVideoClick } = useTrack();
  const swipeRef = useRef<SwiperInstance>(null);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const useShareProps = useShare();

  const queryGetCartTotal = async () => {
    const res = await getCartTotal({ shopBizCode: shopId });
    const { cartSkuNum = 0 } = res?.data || {};
    setCartTotal(cartSkuNum);
    getCart && getCart({ cartNum: cartSkuNum });
    console.log(res, "购物车数量");
  };
  const triggerShare = () => {
    if (activeTab === "2") {
      if (videoList[currentVideoIndex]) {
        const shareType = fromPage === "productDetail" ? 6 : 1;
        useShareProps.shareSetting(shareType, videoList[currentVideoIndex].materialNo);
      }
    }
  };
  const mapType = (list: Array<any>, type: string) => {
    return list?.map(item => {
      return {
        ...item,
        lType: type,
      };
    });
  };
  const adaperVideoEntity = (item: any): VideoEntity => {
    let url = "";
    let cover = "";
    if (item?.materialContextVOS && item?.materialContextVOS?.length > 0) {
      const videoObj = item.materialContextVOS.find(i => {
        return i.contextType === 3;
      });
      url = videoObj.contextContent.contextVideo.video;
      cover = videoObj.contextContent.contextVideo.thumbnail;
    }
    return {
      materialNo: item.materialNo,
      url,
      cover,
      creatorAvatar: item.creatorAvatar,
      name: item.name,
      awesome: item.awesome,
      awesomeNum: item.awesomeNum || "0",
      productListVOList: item.productListVOList,
      activityListVOList: item.activityListVOList,
      conferenceVOList: item.conferenceVOList,
      scheduleListVOList: item.scheduleListVOList,
      allList: [
        ...mapType(item.productListVOList, "product"),
        ...mapType(item.activityListVOList, "activity"),
        ...mapType(item.conferenceVOList, "conference"),
        ...mapType(item.scheduleListVOList, "schedule"),
      ],
    };
  };
  //获取视频列表
  const updateVideos = async (initList: VideoEntity | null, isFirst: boolean) => {
    if (hasNext || isFirst) {
      let list = [];
      const params = {
        scene: "G_P_C",
        pageNo,
        pageSize,
        materialNo,
        pageId: "H5_MaterialList",
      };
      try {
        const res = materialNo
          ? await queryRecommendByMaterialNo(params)
          : await queryRecommendList(params);
        if (res && res.data) {
          if (res.data.result && res.data.result.length > 0) {
            list = res.data.result.map(item => {
              return adaperVideoEntity(item);
            });
            console.log("list", list);
          }
          setHasNext(res.data.hasNext);
          setPageNo(res.data.pageIndex + 1);
        }
        // 有默认进来的视频
        if (initList) {
          setVideoList([initList, ...list]);
        } else {
          if (isFirst) {
            setLoading(false);
            setVideoList(list);
          } else {
            setVideoList([...videoList, ...list]);
          }
        }
      } catch (error) {
        setIsError(true);
        setLoading(false);
        console.log(error);
      }
    }
  };
  // 获取第一个视频，可能没有
  const queryDetail = async () => {
    try {
      const res = await queryMaterialDetail({
        scene: "G_P_C",
        materialNo: materialNo,
        pageId: "H5_MaterialList",
      });
      if (res) {
        const firstVideo = adaperVideoEntity(res.data);
        setVideoList([firstVideo]);
        setLoading(false);
        await updateVideos(firstVideo, true);
      }
    } catch (error) {
      setIsError(true);
      setLoading(false);
      console.log(error);
    }
  };
  const judgeAutoPlay = () => {
    const iosVersion = getIOSVersion();
    const isIos = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
    isIos && setIsAutoPlay(iosVersion >= 17);
  };
  useActivate(() => {
    console.log("in useActivate");
    queryGetCartTotal();
    judgeAutoPlay();
  });
  // useUnactivate(() => {
  //   setShowMoreCard(false);
  //   setCartModalVisible(false);
  // });
  useEffect(() => {
    if (activeTab === "1") {
      setMuted(true);
    }
    if (activeTab === "2") {
      // onTogglePlay(null, "play");
      setMuted(false);
      triggerShare();
      judgeAutoPlay();
    }
  }, [activeTab]);
  useEffect(() => {
    if (currentVideoIndex === videoList.length - 1 && hasNext) {
      updateVideos(null, false);
    }
  }, [currentVideoIndex, videoList]);
  useEffect(() => {
    setHasNext(true);
    setCurrentVideoIndex(0);
    setVideoList([]);
    console.log("materialNo", materialNo);
    queryGetCartTotal();
    if (materialNo) {
      queryDetail();
    } else {
      updateVideos(null, true);
    }
  }, [materialNo]);
  const playAnimation = async () => {
    let flag = true;
    if (flag) {
      flag = false;
      const item = videoList[currentVideoIndex];
      const params = {
        scene: "G_P_C",
        materialNo: item.materialNo,
        interactScene: 1,
        interactFlag: item.awesome ? 1 : 0,
      };
      try {
        const res = (await setAwesome(params)) as any;
        if (res && res.success) {
          item.awesomeNum = res.data;
          item.awesome = !item.awesome;
          setVideoList([...videoList]);
          trackVideoClick({
            btn_name: item.awesome ? "点赞" : "取消点赞",
            item: videoList[currentVideoIndex],
            currentVideoIndex,
            fromPage,
          });
          flag = true;
          if (item.awesome) {
            setShowAnimation(true);
            setTimeout(() => {
              setShowAnimation(false);
            }, 2000);
          } else {
            setShowAnimation(false);
          }
        } else {
          flag = true;
        }
      } catch (error) {
        flag = true;
      }
    }
  };
  const playNextVideo = () => {
    if (!isAutoPlay && !hasNext && currentVideoIndex === videoList.length - 1) {
      Toast.show({
        content: "到底了～",
      });
      return;
    }
    if (currentVideoIndex === videoList.length - 1 && hasNext) {
      Toast.show({
        content: "视频正在路上，请稍等～",
        maskClickable: false,
      });
      return;
    }
    if (!isAutoPlay) {
      swipeRef.current?.swipeNext();
    }
    if (currentVideoIndex < videoList.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }
  };

  const playPreviousVideo = () => {
    if (currentVideoIndex === 0) {
      Toast.show({
        content: "已经是最新的视频了～",
      });
      return;
    }
    if (!isAutoPlay) {
      swipeRef.current?.swipePrev();
    }
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
    }
  };
  const setSwiper = (enable: any) => {
    if (isAutoPlay) {
      enable && swipeRef.current?.enable();
      !enable && swipeRef.current?.disable();
    } else {
      swipeRef.current?.disable();
    }
  };
  const onIndexChange = e => {
    if (e > currentVideoIndex) {
      playNextVideo();
    }
    if (e >= 0 && e < currentVideoIndex) {
      playPreviousVideo();
    }
  };
  const VideoItems = videoList.map((item, index) => (
    <Swiper.Item
      key={index}
      className={styles.itemWrapper}
      style={
        {
          // width: "100%",
          // height: "100%",
          // position: "absolute",
          // overflow: "hidden",
          // top: `${(index - currentVideoIndex) * 100}vh`,
          // left: 0,
          // transition: "top 0.3s ease-in-out",
          // zIndex: index === currentVideoIndex ? 1 : 0
        }
      }>
      {Math.abs(index - currentVideoIndex) < 3 && (
        <VideoPlayer
          activeTab={activeTab}
          videoItem={item}
          currentVideoIndex={currentVideoIndex}
          muted={muted}
          triggerShare={triggerShare}
          playNextVideo={playNextVideo}
          playAnimation={playAnimation}
          cartTotal={cartTotal}
          showAnimation={showAnimation}
          queryGetCartTotal={queryGetCartTotal}
          playPreviousVideo={playPreviousVideo}
          index={index}
          setSwiper={setSwiper}
          canAuto={isAutoPlay}
        />
      )}
    </Swiper.Item>
  ));
  return (
    <div className={styles.videoContainer} id="videoContainer">
      {!isError && (
        <>
          {!isAutoPlay && currentVideoIndex !== 0 && (
            <div className={styles["prev-video"]} onClick={playPreviousVideo}>
              上个视频
              <img
                className={styles["icon"]}
                src="https://akim-oss.aikucun.com/5d5815647c76e193ee289b94a0c43bf26e8aa23e_1700045513374_97.png"
              />
            </div>
          )}
          {!isAutoPlay && (
            <div
              className={styles["next-video"]}
              onClick={() => {
                playNextVideo();
              }}>
              下个视频
              <img
                className={styles["icon"]}
                src="https://akim-oss.aikucun.com/b0e24833f752e8500906c80edc9b995077774209_1700045624589_79.png"
              />
            </div>
          )}
          {videoList.length > 0 && (
            <Swiper
              ref={swipeRef}
              vertical={true}
              indicator={false}
              initialSwipe={0}
              onChange={onIndexChange}
              loop={false}
              className={styles.mySwiper}>
              {VideoItems}
            </Swiper>
          )}
          {loading && (
            <div className={styles.loading}>
              <img src="https://akim-oss.aikucun.com/14b85fa8e87f846f757eaccda09761641b397f01_1702533385957_40.png" />
            </div>
          )}
          {currentVideoIndex === 0 && videoList.length > 0 && (
            <div className={styles.tips1}>已经是最新的视频了~</div>
          )}
          {!hasNext && currentVideoIndex === videoList.length - 1 && videoList.length > 0 && (
            <div className={styles.tips2}>滑到底了~</div>
          )}
        </>
      )}
      {isError && (
        <div className={styles.error}>
          <div className={styles.mask1}></div>
          <div className={styles.mask2}></div>
          <img src="https://akim-oss.aikucun.com/07b7e0bd7b1f5fde90a6e7520edaca02ffe3f832_1702533136907_83.png" />
          <div className={styles.errorText}>服务器开小差了，请稍后重试~</div>
          <div className={styles.retry} onClick={() => location.reload()}>
            重试
          </div>
        </div>
      )}
    </div>
  );
};

export default Recommend;
