import React from "react";
import styles from "./index.module.less";
import { YouXueSignUpDetail } from "@akc/biz-api";

interface Props {
  detail?: YouXueSignUpDetail;
}

// 游学权益信息
const BenefitInfoComp: React.FC<Props> = props => (
  <>
    {props.detail?.studyTourBenefitPointResp?.map(item => {
      return (
        <>
          <div className={styles.info__header}>
            <div className={styles.header__title}>{item?.benefitName}</div>
            <div className={styles.header__desc}>{item?.benefitDesc}</div>
          </div>
        </>
      );
    })}
  </>
);

export default BenefitInfoComp;
