import Track from "@/utils/track";
import { Popup, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./TextPopup.module.less";

interface TextPopupProps {
  visible: boolean;
  setVisible: (boolean) => void;
}

const TextPopup: React.FC<TextPopupProps> = ({ visible, setVisible }) => {
  const track = Track();
  const [search] = useSearchParams();
  const textList = [
    "亲爱哒，欢迎光临小店~特地为您挑选了今天爆款好物，您可以看看哈。我们都是品牌方直接发货，七天无理由退换货，有免费的运费险，可以放心选购~有任何问题，随时跟我说哈❤",
    "亲，我精选了今天在线的品牌好物，都是品牌直接发货！万一碰到自己喜欢又拿不定主意的，可以先买下来，七天无理由退换货，有免费的运费险，可以放心选购~我们的口号就是：一起开开心心薅有质量的羊毛！",
    "宝~今天的精选爆款发你哈，卖得特别好。我们一般都是限时特卖3-5天，请在活动期内下单哈。❤正品保证，七天无理由退换，送8元运费险！希望你逛得开心，有任何问题都可以私信我。",
  ];

  const onCopy = (str: string) => {
    copy(str);
    Toast.show({
      content: "复制成功",
      position: "center",
      maskClassName: styles["poster-unify-toast"],
    });
    if (track.track) {
      track.track("resource_click", {
        page_name: "派单任务",
        previous_page_name: search.get("page_name"),
        resource_type: "精选文案",
        resource_name: str,
        btn_name: "复制",
      });
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Popup
      style={{ "--z-index": "99" }}
      maskStyle={{ zIndex: "100" }}
      visible={visible}
      onMaskClick={onClose}>
      <div className={styles["popup__box"]}>
        <div className={styles["box__header"]}>
          <div className={styles["header__title"]}>精选文案</div>
          <img
            className={styles["header__icon"]}
            onClick={() => onClose()}
            src="https://akim-oss.aikucun.com/mshop/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1710849026952_49.png"
          />
        </div>
        <div className={styles["box__body"]}>
          {textList.map((item, index) => {
            return (
              <div key={index} className={styles["body__item"]}>
                <div>{item}</div>
                <div className={styles["item__btn"]} onClick={() => onCopy(item)}>
                  复制
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

export default TextPopup;
