import React, { useRef } from "react";

interface IProps {
  children: React.ReactNode;
  onMoveUpAtBottom?: () => void; //滑动到底部后向上滑动的事件处理
  id?: string;
  styles?: React.CSSProperties; //样式
  className?: string; //类名
}
const VerticalScrollview: React.FC<IProps> = props => {
  const { children, onMoveUpAtBottom, id, styles, className } = props;
  const startRef = useRef<number>();
  const domRef = useRef<HTMLDivElement>(null);

  const touchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!onMoveUpAtBottom || !domRef.current) {
      return;
    }

    const height = domRef.current.clientHeight;
    const scrollHeight = domRef.current.scrollHeight;
    const scrollTop = domRef.current.scrollTop;

    // 3 是为了留个缓冲
    if (scrollHeight - scrollTop - height < 3) {
      startRef.current = event.touches[0].clientY;
    } else {
      startRef.current = undefined;
    }
  };
  const touchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!onMoveUpAtBottom || startRef.current === undefined) {
      return;
    }
    // 处理触摸事件
    if (event.changedTouches[0].clientY < startRef.current) {
      startRef.current = undefined;
      onMoveUpAtBottom();
    }
  };

  return (
    <div
      id={id}
      ref={domRef}
      className={className ? className : ""}
      // style={{ overflowY: "scroll", ...styles }}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}>
      {children}
    </div>
  );
};

export default VerticalScrollview;
