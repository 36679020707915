import Play from "@/assets/play2.png";
import { Image as ImageM, ImageViewer } from "antd-mobile";
import { MultiImageViewerRef } from "antd-mobile/es/components/image-viewer";
import React, { useEffect, useRef, useState } from "react";
import { ProductVideoImageItem } from "../../model/Media";
import styles from "./index.module.less";

interface IProps {
  selectIndex: number;
  swiperSources: ProductVideoImageItem[];
  onClosePress?: () => void;
}
const MediaPreview: React.FC<IProps> = ({ selectIndex, swiperSources, onClosePress }) => {
  const [ImageViewerVisible, setImageViewerVisible] = useState<boolean>(false);
  const [videoPlayStatus, setVideoPlayStatus] = useState<boolean[]>([false]);
  const [videoLoadingStatus, setVideoLoadingStatus] = useState<boolean[]>([false]);
  const [viewerImages, setViewerImages] = useState<string[]>([]);

  const imageViewerRef = useRef<MultiImageViewerRef>(null);
  const videoRefs = useRef<HTMLVideoElement | null[]>([]);

  const handleSwiperImageClick = () => {
    setImageViewerVisible(true);
  };
  const handleSwiperVideoClick = (index: number) => {
    const dom = videoRefs.current[index];
    if (dom) {
      if (dom.requestFullscreen) {
        dom.requestFullscreen();
      } else if (dom.mozRequestFullScreen) {
        /* Firefox */
        dom.mozRequestFullScreen();
      } else if (dom.webkitRequestFullscreen) {
        /* Chrome,Opera */
        console.log("全屏播放");
        dom.webkitRequestFullscreen();
      } else if (dom.msRequestFullscreen) {
        /* IE/Edge */
        dom.msRequestFullscreen();
      } else {
        // Safari ...
        dom.webkitEnterFullscreen();
        const timer = setInterval(() => {
          if (!dom.webkitDisplayingFullscreen) {
            const newStatus = [...videoPlayStatus];
            newStatus[index] = false;
            setVideoPlayStatus(newStatus);

            clearInterval(timer);
          }
        }, 500);
      }
    }
  };
  const handleVideoEnded = (index: number) => {
    videoRefs.current[index].currentTime = 0;
    const newStatus = [...videoPlayStatus];
    newStatus[index] = false;
    setVideoPlayStatus(newStatus);
  };
  const handleVideoPlay = (index: number) => {
    const newStatus = [...videoPlayStatus];
    newStatus[index] = true;
    setVideoPlayStatus(newStatus);
  };
  const handleVideoPause = (index: number) => {
    const newStatus = [...videoPlayStatus];
    newStatus[index] = false;
    setVideoPlayStatus(newStatus);
  };
  const handleVideoWaiting = (index: number) => {
    const newStatus = [...videoLoadingStatus];
    newStatus[index] = true;
    setVideoLoadingStatus(newStatus);
  };
  const handleVideoPlaying = (index: number) => {
    const newStatus = [...videoLoadingStatus];
    newStatus[index] = false;
    setVideoLoadingStatus(newStatus);
  };
  const handleViewerPlayClick = (index: number) => {
    handleSwiperVideoClick(index);
    setImageViewerVisible(false);
  };
  const renderFooter = (image: string, index: number) => {
    if (swiperSources.length && swiperSources[index]?.type === "video") {
      return (
        <div className={styles.footer}>
          <ImageM
            src={Play}
            className={styles.videoPlayIcon}
            onClick={() => handleViewerPlayClick(index)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    handleSwiperImageClick();
    imageViewerRef.current?.swipeTo(selectIndex);
    console.log("selectIndex11112222", selectIndex);
  }, [selectIndex]);

  useEffect(() => {
    setViewerImages(
      swiperSources?.map(item => {
        return item.imgUrl;
      }),
    );
  }, [swiperSources]);
  return (
    <div className={styles.MediaContainer}>
      {swiperSources?.map((item, index) => {
        if (item.type === "video") {
          return (
            <div key={index}>
              <video
                src={item.videoUrl}
                className={styles.playItem}
                playsInline={true}
                x5-video-player-type="h5"
                poster={item?.imgUrl}
                autoPlay
                onEnded={() => handleVideoEnded(index)}
                onPlay={() => handleVideoPlay(index)}
                onPause={() => handleVideoPause(index)}
                onWaiting={() => handleVideoWaiting(index)}
                onPlaying={() => handleVideoPlaying(index)}
                ref={ref => (videoRefs.current[index] = ref)}
              />
            </div>
          );
        }
      })}
      <ImageViewer.Multi
        images={viewerImages}
        visible={ImageViewerVisible}
        defaultIndex={selectIndex}
        onClose={() => {
          setImageViewerVisible(false);
          if (onClosePress !== undefined) {
            setTimeout(() => {
              onClosePress?.();
            }, 100);
          }
        }}
        ref={imageViewerRef}
        renderFooter={renderFooter}
        maxZoom="auto"
      />
    </div>
  );
};

export default MediaPreview;
