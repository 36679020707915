import LabelList from "@/pages/dispatch-task/components/LabelList";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

const ShopDispatchProduct = props => {
  const { productInfo } = props;
  const locationCode = "H5_Member_Task_ProductTitleDown";
  const labelInfoVOList =
    (productInfo.labelLocationVOList || []).find(item => item.locationCode === locationCode) || [];

  return (
    <div className={styles["product-card"]}>
      <div className={styles["card__left"]}>
        <img className={styles["product-image"]} src={productInfo?.skuMainImageUrls[0]} alt="" />
      </div>
      <div className={styles["card__right"]}>
        <div
          className={`${styles["product-title"]} ${labelInfoVOList.length > 0 ? styles["line1"] : styles["line2"]}`}>
          {productInfo.title}
        </div>
        {labelInfoVOList.length > 0 && (
          <LabelList labelLocationVOList={labelInfoVOList} locationCode={locationCode} />
        )}
        <div className={styles["product-price-box"]}>
          <div className={styles["price-box"]}>
            <div className={styles["price"]}>
              {productInfo?.skuCurrentPriceInfo?.marketingPrice}
            </div>
          </div>
          {productInfo?.skuCurrentPriceInfo?.commission && (
            <div className={styles["commission"]}>
              省赚¥{productInfo.skuCurrentPriceInfo.commission}
              {productInfo?.skuCurrentPriceInfo?.commissionAddition
                ? `+${productInfo.skuCurrentPriceInfo.commissionAddition}`
                : ""}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopDispatchProduct;
