import { getShopInfo } from "@/api/home";
import { MICRO_APP_BASEURL, getPlatform } from "@/utils/platform";
import { captureException } from "@/utils/sentry";
import React, { useEffect } from "react";
import { useNavigate, useRouteError, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

const ErrorBoundary: React.FC = () => {
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const shopId: any = search.get("shopId");
  const error = useRouteError();

  React.useEffect(() => {
    captureException(error);
  }, [error]);

  useEffect(() => {
    getShopInfo(shopId, localStorage.getItem("distributorId")).then(data => {
      document.title = data.shopName;
    });
  }, []);

  const goHome = () => {
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  };
  const { isH5 } = getPlatform();
  return (
    <div className={styles.container}>
      <img
        className={styles.img}
        src="https://akim-oss.aikucun.com/91241c0ba44a22bfd51323e69b4b2c00fccc4771_1710748684697_41.png"
      />
      <div className={styles.text}>攻城狮小哥哥正在修复，请稍后再访问</div>
      {isH5 && (
        <div className={styles.btn} onClick={() => goHome()}>
          回首页
        </div>
      )}
    </div>
  );
};
export default ErrorBoundary;
