import React, { useState } from "react";
import styles from "./search.module.less";
import { pxtorem } from "@/utils/pxtorem";
import { useSearchContext } from "./useSearch";

interface IProps {
  suggestList: any[];
}

const SearchSuggestKeys: React.FC<IProps> = ({ suggestList }) => {
  const { showSuggest, showSearchTag, handleClickKeyTag } = useSearchContext();

  return (
    suggestList.length > 0 &&
    !showSearchTag &&
    showSuggest && (
      <div className={styles.suggest}>
        {suggestList.map((item, index) => {
          return (
            <div className={styles.item} key={index} onClick={() => handleClickKeyTag(item.data)}>
              {item.node}
            </div>
          );
        })}
      </div>
    )
  );
};
export default SearchSuggestKeys;
