import { getStatisticsInfo } from "@/api/my/shop";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { AchievementCard } from "@akc/mx-taro-h5-components";
import React, { useEffect, useState } from "react";

const ShopCenterData: React.FC = () => {
  const tabItems = [
    { key: "TODAY", title: "今日" },
    { key: "YESTERDAY", title: "昨日" },
    { key: "WEEK", title: "7天" },
    { key: "MONTH", title: "30天" },
  ];

  const track = Track();
  const [timeTabKey, setTimeTabKey] = useState("TODAY");
  const [shopData, setShopData] = useState<any>({});
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const { navigate2MyItem } = useNavigation();

  const queryShopData = async () => {
    const data = await getStatisticsInfo({ timeType: timeTabKey });
    if (!data || Number(data.orderCount) < 0 || Number(data.saleAmount) < 0) {
      setIsEmpty(true);
    } else {
      setShopData(data);
    }
  };

  const goShopData = e => {
    // e.stopPropagation();
    track.track("btn_click", {
      page_name: "我的",
      module: "数据中心",
      btn_name: "全部",
    });
    navigate2MyItem("/#/shop-data");
  };

  const onTabChange = (key: string) => {
    setTimeTabKey(key);
    track.track("btn_click", {
      page_name: "我的",
      module: "数据中心",
      resource_type: "数据中心",
      btn_name: "时间选择",
      btn_text: tabItems.filter(item => item.key === key)[0].title,
    });
  };

  useEffect(() => {
    queryShopData();
  }, [timeTabKey]);

  return (
    <AchievementCard
      currentType={timeTabKey}
      titleStyle={{ fontSize: pxtorem(14) }}
      orderCountShop={shopData.orderCount}
      saleShop={shopData.saleAmount}
      sellerEstimate={shopData.estimatedTotalRevenue}
      onDateClick={key => onTabChange(key)}
      onAllClick={goShopData}
    />
    // <div className={styles.shopCenterData}>
    //   {!isEmpty && (
    //     <div className={styles.titleHeader}>
    //       <div className={styles.titleLeft}>数据中心</div>
    //       <Clickable
    //         className={styles.titleRight}
    //         onClick={goShopData}
    //         trackData={{
    //           page_name: "我的",
    //           btn_name: "数据中心",
    //         }}
    //       >
    //         查看全部
    //         <i className={styles.arrow} />
    //       </Clickable>
    //     </div>
    //   )}
    //   {!isEmpty && (
    //     <div className={styles.tabList}>
    //       <Tabs
    //         activeKey={timeTabKey}
    //         style={{
    //           "--title-font-size": "14px",
    //           "--active-line-color": "#FF4747",
    //           "--active-title-color": "#FF4747",
    //           "--active-line-height": "2px",
    //           // "--fixed-active-line-width": "22px",
    //         }}
    //         onChange={(key) => onTabChange(key)}
    //       >
    //         {tabItems.map((item) => (
    //           <Tabs.Tab title={item.title} key={item.key} />
    //         ))}
    //       </Tabs>
    //     </div>
    //   )}
    //   {!isEmpty && (
    //     <div className={styles.content}>
    //       <div className={styles.dataItem}>
    //         <span className={styles.text}>订单</span>
    //         <span className={styles.count}>{shopData.orderCount}</span>
    //       </div>
    //       <div className={styles.dataItem}>
    //         <span className={styles.text}>销售额</span>
    //         <span className={styles.count}>{shopData.saleAmount}</span>
    //       </div>
    //       <div className={styles.dataItem}>
    //         <span className={styles.text}>我的预估收入</span>
    //         <span className={styles.count}>{shopData.estimatedTotalRevenue}</span>
    //       </div>
    //     </div>
    //   )}
    //   {isEmpty && <div className={styles.empty}>系统繁忙，数据正在计算中，请稍后再试...</div>}
    // </div>
  );
};

export default ShopCenterData;
