import Track from "@/utils/track";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { TrackData, TrackDataKey } from "@akc/taro-service";
import React, { useContext, useEffect, useRef } from "react";

type Props = {
  children: React.ReactNode;
  onClick?: (() => void) | (() => Promise<void>);
  trackData?: TrackData;
  trackContextKeys?: Array<TrackDataKey>;
  style?: React.CSSProperties;
  id?: string;
  ref?: React.RefObject<HTMLDivElement>;
  className?: string;
  clickEventName?: "resource_click" | "tab_click" | "btn_click" | "pick_up_coupon";
};

const Exposable: React.FC<Props> = ({
  children,
  onClick,
  trackData,
  trackContextKeys,
  style,
  id,
  ref,
  className,
  clickEventName,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { trackData: contextTrackData } = useContext(TrackDataContext);
  trackContextKeys = (trackContextKeys ?? []).concat(["page_name", "previous_page_name"]);
  const requiredContextTrackData = contextTrackData
    ? trackContextKeys.reduce((acc, cur) => {
        acc[cur] = contextTrackData[cur];
        return acc;
      }, {})
    : {};

  const dom = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!dom.current) {
      return;
    }
    if (ref) {
      ref = dom;
    }
    const tmp = { ...trackData, ...requiredContextTrackData };
    delete tmp.btn_name;
    dom.current.setAttribute("data-track-data", JSON.stringify(tmp));
    // dom.current.setAttribute("data-track-context", JSON.stringify(trackContextKeys));
    dom.current.setAttribute("data-track-id", Math.floor(Math.random() * 1000000).toString());
    window.exposeObserver.observe(dom.current);

    return () => {
      dom.current && window.exposeObserver.unobserve(dom.current);
    };
  }, [dom]);

  return (
    <div
      ref={dom}
      id={id}
      className={className}
      style={style}
      onClick={evt => {
        evt.stopPropagation();
        onClick && onClick();
        if (!clickEventName) {
          return;
        }
        try {
          const track = Track();
          track.track(clickEventName, { ...trackData, ...requiredContextTrackData });
        } catch (e) {
          console.error(e);
        }
      }}>
      {children}
    </div>
  );
};

export default Exposable;
