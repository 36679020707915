import { queryAddressList } from "@/api/product";
import { AddressListModel } from "@/pages/product-detail/model/AddressListModel";
import Cookie from "js-cookie";
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * 获取地址列表
 */
export const useAddressList = () => {
  const [search] = useSearchParams();
  const [addressList, setAddressList] = useState<AddressListModel[]>();
  //当前地址id
  const [selectAddressId, setSelectAddressId] = useState<string | undefined>();
  const currentAddressId = useRef<string | undefined>();
  const [isLoadAddress, setIsLoadAddress] = useState<boolean>(false);
  const setAddressId = addressId => {
    const openId = Cookie.get("msOpenId");
    localStorage.setItem("addressId", addressId);
    localStorage.setItem(`${search.get["shopId"]}:${openId}:addressId`, addressId);
  };

  const getAddressId = () => {
    const openId = Cookie.get("msOpenId");
    const address =
      localStorage.getItem(`${search.get["shopId"]}:${openId}:addressId`) ||
      localStorage.getItem("addressId");
    return address;
  };

  const fetchAddressList = async () => {
    try {
      const res = await queryAddressList();
      setIsLoadAddress(true);
      setAddressList(res ?? []);
      if (res?.length ?? 0 > 0) {
        const saveAddressId = getAddressId();
        const defaultAddress = res?.filter(it => it.isDefault === 1);
        if (saveAddressId && saveAddressId !== "undefined") {
          const selected = res?.filter(it => it.id === saveAddressId);
          if (selected?.length) {
            //之前存储的地址
            currentAddressId.current = selected?.[0]?.id;
            setSelectAddressId(selected?.[0]?.id);
          }
        } else if (defaultAddress?.length) {
          // 设置默认地址
          currentAddressId.current = defaultAddress?.[0]?.id;
          setSelectAddressId(defaultAddress?.[0]?.id);
        } else {
          //地址列表的第一个
          currentAddressId.current = res?.[0]?.id;
          setSelectAddressId(res?.[0]?.id);
        }
      } else {
        currentAddressId.current = undefined;
        setSelectAddressId(undefined);
      }
    } catch (error) {
      setIsLoadAddress(true);
      setAddressList([]);
      currentAddressId.current = undefined;
      setSelectAddressId(undefined);
    }
  };

  //更新选中的地址
  const updateSelectAddress = (addressId?: string, index?: number) => {
    setAddressId(addressId);
    currentAddressId.current = addressId;
    setSelectAddressId(addressId);
  };

  return {
    addressList,
    selectAddressId,
    updateSelectAddress,
    fetchAddressList,
    isLoadAddress,
    currentAddressId,
  };
};
