import { OrderProductModel } from "@/api/order/model";
import { queryOrderDetail } from "@/api/order/order";
import useNavigation from "@/utils/useNavigate";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTrack } from "./useTrack";

const pageName = "交易快照";
export const useTradeSnapshotList = () => {
  const [search] = useSearchParams();
  const orderNo = search.get("orderNo");
  const { navigate } = useNavigation();
  const [productList, setProductList] = useState<OrderProductModel[]>([]);
  const pageIndex = useRef<number>(1);
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const { trackSnapshotClick, trackPageView } = useTrack(pageName);

  //请求订单详情
  const fetchOrderDetail = async () => {
    try {
      const res = await queryOrderDetail({ orderNo: orderNo });
      setProductList(res?.productList ?? []);
      setShowEmpty((res?.productList ?? []).length === 0);
      trackPageView({
        previousPageName: search.get("page_name") ?? search.get("previous_page_name"),
      });
    } catch (error) {
      console.log("fetchOrderDetail,error>>>", error);
    }
  };

  useEffect(() => {
    document.title = pageName;
    fetchOrderDetail();
  }, []);

  //跳转交易快照详情
  const goProductSnapshotDetail = (product: OrderProductModel, index: number) => {
    trackSnapshotClick({
      previousPageName: search.get("page_name") ?? search.get("previous_page_name"),
    });
    const query = {
      itemCode: product?.itemCode,
      skuId: product?.skuId,
      spuVersion: product?.spuVersion,
      spuId: product.spuId,
      activityId: product?.activityId,
    };
    navigate({
      appOptions: {
        url: `${origin}/mx-shop-micro/trade-snapshot/detail?itemCode=${product?.itemCode}&skuId=${product?.skuId}&spuVersion=${product?.spuVersion}&spuId=${product.spuId}&activityId=${product?.activityId}`,
      },
      miniOptions: {
        url: `${origin}/mx-shop-micro/trade-snapshot/detail`,
        query: query,
      },
      h5Options: {
        url: "/mx-shop-micro/trade-snapshot/detail",
        query: query,
      },
    });
  };

  return {
    productList,
    pageIndex,
    goProductSnapshotDetail,
    showEmpty,
  };
};
