import { queryDistributorDataListServ } from "@/api/appH5";
import { InfiniteScroll } from "antd-mobile";
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./Table.module.less";

interface Props {
  info: any;
}
const Table: React.FC<Props> = ({ info }) => {
  const [search] = useSearchParams();
  const actId = search.get("actId") || "";
  // 目标枚举
  const thresholdIndicatorTypeEnum = {
    GMV: { desc: "销售额", unit: "元" },
    SALE_QUANTITY: { desc: "销量", unit: "件" },
    COMMISSION: { desc: "基础推广费", unit: "元" },
    SHOP_NEW_USER_QUANTITY: { desc: "拓新人数", unit: "人" },
  };

  const [tableTitleList, setTableTitleList] = useState<any>([
    "昵称",
    "",
    "预估奖励(元)",
    ""
  ]); // 表头
  const [distributorList, setDistributorList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);


  const queryDistributorDataList = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await queryDistributorDataListServ({
      actId,
      pageIndex,
      pageSize: 10,
    });
    console.error("res——", res);
    setDistributorList(res?.pageIndex > 1 ? distributorList.concat(res?.result ?? []) : (res?.result ?? []));
    setPageIndex(pageIndex + 1);
    setHasMore(res?.pages > res?.pageIndex);
    setLoading(false);
  };

  const loadMore = async () => {
    console.error("loadMore");
    queryDistributorDataList();
  };

  useEffect(() => {
    setTableTitleList(tableTitleList.map((item, index) => {
      if (index === 1) {
        return `${thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}(${thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit})`;
      } else if (index === 2) {
        return info?.bizType === "GIFT_RETURN" ? "预估奖励(件)" : "预估奖励(元)";
      } else if (index === 3) {
        if (info?.supportDistributorRank) {
          return "店铺排名";
        } else {
          return `距下一阶段(${thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit})`;
        }
      } else {
        return item;
      }
    }
    ));
  }, [info]);

  useEffect(() => {
    queryDistributorDataList();
  }, []);

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <></>
    );
  };

  return <>
    <div className={styles.table}>
      <div className={styles.table__title}>
        {
          tableTitleList.map((item: any, index: number) => {
            return <div className={styles.title__item} key={index}>{item}</div>;
          })
        }
      </div>
      <div className={styles.table__body}>
        <div className={styles.body__list}>
          {/* 店主数据 */}
          {
            info?.supportDistributorRank && (
              <div className={`${styles.list__item} ${styles.me__item}`}>
                <div>我</div>
                <div>{info?.currentStatisticsAmount}</div>
                <div>
                  {/* 满赠 */}
                  {
                    info?.bizType === "GIFT_RETURN" && (
                      <>
                        <img className={styles.item__gift_image} src={info?.actSellBatePartakeInfo?.giftImageUrl} />
                        <span>{info?.bizType === "GIFT_RETURN" && "×"}{info?.actSellBatePartakeInfo?.expectedAwardAmount}</span>
                      </>
                    )
                  }
                  {
                    info?.bizType === "FULL_RETURN" && (
                      <span>{info?.actSellBatePartakeInfo?.estimatedRewards}</span>
                    )
                  }
                </div>
                <div>{info?.supportDistributorRank ? (info.rank || "--") : (info?.actSellBatePartakeInfo?.nextRewardAmount || "已达最高阶梯")}</div>
              </div>
            )
          }
          {/* 店长数据总计 */}
          <div className={`${styles.list__item} ${styles.distributor__item}`}>
            <div>店长总计</div>
            <div>{info?.distributorSalesAmount}</div>
            <div>
              {
                info?.bizType === "GIFT_RETURN" && (
                  <img className={styles.item__gift_image} src={info?.distributorGiftImageUrl} />
                )
              }
              <span>{info?.bizType === "GIFT_RETURN" && "×"}{info?.distributorEstimatedReward}</span>
            </div>
            <div>--</div>
          </div>
          {/* 店长数据列表 */}
          {
            distributorList.map((item: any, index: number) => {
              return <div className={styles.list__item} key={index}>
                <div>{item.nickName}</div>
                <div>{item.currentStatisticsAmount}</div>
                <div>
                  {/* 满赠 */}
                  {
                    info?.bizType === "GIFT_RETURN" && (
                      <img className={styles.item__gift_image} src={item?.giftImageUrl} />
                    )
                  }
                  <span>{info?.bizType === "GIFT_RETURN" && "×"}{item?.awardAmount}</span>
                </div>
                <div>{info?.supportDistributorRank ? item.rank : (item.nextThresholdGapAmount || "已达最高阶梯")}</div>
              </div>;
            })
          }
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
      </div>
    </div>
  </>;
};

export default Table;