import { Image as ImageM, ImageViewer, Swiper } from "antd-mobile";
import { MultiImageViewerRef } from "antd-mobile/es/components/image-viewer";
import { SwiperRef } from "antd-mobile/es/components/swiper";
import React, { useEffect, useRef, useState } from "react";
import { getFirstElementHeight } from "../../utils/utils";
import SwiperCustomIndicator from "../custom-indicator";

import Loading from "@/assets/loading2.png";
import Play from "@/assets/play2.png";
import VoiceClose from "@/assets/voice-close.png";
import VoiceOpen from "@/assets/voice-open.png";
import { getNetworkType } from "@/utils/network-type";
import "./index.less";
import styles from "./index.module.less";

export interface ISwiperDataItem {
  width: number;
  height: number;
  url: string;
  type: string;
  coverUrl?: string;
}

interface IProps {
  swiperSources: ISwiperDataItem[];
}
const CustomSwiper: React.FC<IProps> = ({ swiperSources }) => {
  const [firstElementHeight, setFirstElementHeight] = useState<number>(400);
  const [swiperCurrentIndex, setSwiperCurrentIndex] = useState<number>(0);
  const [ImageViewerVisible, setImageViewerVisible] = useState<boolean>(false);
  const [videoPlayStatus, setVideoPlayStatus] = useState<boolean[]>([false]);
  const [videoVoiceStatus, setVideoVoiceStatus] = useState<boolean[]>([false]);
  const [videoLoadingStatus, setVideoLoadingStatus] = useState<boolean[]>([false]);
  const [viewerImages, setViewerImages] = useState<string[]>([]);

  const imageViewerRef = useRef<MultiImageViewerRef>(null);
  const videoRefs = useRef<HTMLVideoElement | null[]>([]);
  const videoScrollBarRefs = useRef<HTMLVideoElement | null[]>([]);
  const swiperRef = useRef<SwiperRef>(null);

  const customVideoScrollBar = (videoRef: HTMLVideoElement, videoScrollBarRef: HTMLDivElement) => {
    videoRef.addEventListener("timeupdate", () => {
      const percent = (videoRef.currentTime / videoRef.duration) * 100;
      if (!percent) {
        setTimeout(() => {
          videoScrollBarRef.style.width = `${percent}%`;
        }, 300);
      } else {
        videoScrollBarRef.style.width = `${percent}%`;
      }
    });
  };
  const autoPlay = () => {
    getNetworkType().then(res => {
      // wifi环境下自动播放，自动播放需要静音
      if (res === "wifi") {
        if (videoRefs.current[0]) {
          const observer = new IntersectionObserver(
            entries => {
              if (entries[0].intersectionRatio === 1) {
                videoRefs.current[0].play();
                customVideoScrollBar(videoRefs.current[0], videoScrollBarRefs.current[0]);
              }
              if (entries[0].intersectionRatio === 0) {
                videoRefs.current[0].pause();
                // observer.disconnect();
              }
            },
            {
              threshold: [0, 1],
            },
          );
          observer.observe(videoRefs.current[0]);
        }
      }
    });
  };

  const handleSwiperImageClick = () => {
    setImageViewerVisible(true);
    imageViewerRef.current?.swipeTo(swiperCurrentIndex);
  };
  const handleSwiperVideoClick = (index: number) => {
    const dom = videoRefs.current[index];
    if (dom) {
      if (dom.requestFullscreen) {
        dom.requestFullscreen();
      } else if (dom.mozRequestFullScreen) {
        /* Firefox */
        dom.mozRequestFullScreen();
      } else if (dom.webkitRequestFullscreen) {
        /* Chrome,Opera */
        console.log("全屏播放");
        dom.webkitRequestFullscreen();
      } else if (dom.msRequestFullscreen) {
        /* IE/Edge */
        dom.msRequestFullscreen();
      } else {
        // Safari ...
        dom.webkitEnterFullscreen();
        const timer = setInterval(() => {
          if (!dom.webkitDisplayingFullscreen) {
            const newStatus = [...videoPlayStatus];
            newStatus[index] = false;
            setVideoPlayStatus(newStatus);

            clearInterval(timer);
          }
        }, 500);
      }
    }
  };
  const handlePlayClick = (e: React.MouseEvent<HTMLImageElement, Event>, index: number) => {
    e.stopPropagation();
    videoRefs.current[index].play();
    customVideoScrollBar(videoRefs.current[index], videoScrollBarRefs.current[index]);
  };
  const handleVoiceClick = (e, index: number) => {
    e.stopPropagation();
    const newStatus = [...videoVoiceStatus];
    newStatus[index] = !newStatus[index];
    setVideoVoiceStatus(newStatus);
  };
  const handleVideoEnded = (index: number) => {
    videoRefs.current[index].currentTime = 0;
    const newStatus = [...videoPlayStatus];
    newStatus[index] = false;
    setVideoPlayStatus(newStatus);
  };
  const handleVideoPlay = (index: number) => {
    const newStatus = [...videoPlayStatus];
    newStatus[index] = true;
    setVideoPlayStatus(newStatus);
  };
  const handleVideoPause = (index: number) => {
    const newStatus = [...videoPlayStatus];
    newStatus[index] = false;
    setVideoPlayStatus(newStatus);
  };
  const handleVideoWaiting = (index: number) => {
    const newStatus = [...videoLoadingStatus];
    newStatus[index] = true;
    setVideoLoadingStatus(newStatus);
  };
  const handleVideoPlaying = (index: number) => {
    const newStatus = [...videoLoadingStatus];
    newStatus[index] = false;
    setVideoLoadingStatus(newStatus);
  };
  const handleViewerPlayClick = (index: number) => {
    handleSwiperVideoClick(index);
    setImageViewerVisible(false);
  };
  const renderFooter = (image: string, index: number) => {
    if (swiperSources.length && swiperSources[index]?.type === "video") {
      return (
        <div className={styles.footer}>
          <ImageM
            src={Play}
            className={styles.videoPlayIcon}
            onClick={() => handleViewerPlayClick(index)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    autoPlay();
  }, []);
  useEffect(() => {
    setViewerImages(
      swiperSources?.map(item => {
        if (item.type === "image") {
          return item.url;
        } else {
          return item.coverUrl || "";
        }
      }),
    );
    getFirstElementHeight(swiperSources[0], (height: number) => {
      setFirstElementHeight(height);
    });
  }, [swiperSources]);
  return (
    <div className={styles.SwiperContainer} id="swiper">
      <Swiper
        onIndexChange={index => {
          setSwiperCurrentIndex(index);
        }}
        style={{
          "--border-radius": "10px",
          border: "1px solid rgba(34, 34, 34, 0.08)",
        }}
        indicator={() => null}
        ref={swiperRef}>
        {swiperSources?.map((item, index) => {
          if (item.type === "video") {
            return (
              <Swiper.Item key={index} onClick={() => handleSwiperVideoClick(index)}>
                <video
                  src={item.url}
                  className={styles.swiperItem}
                  muted={!videoVoiceStatus[index]}
                  playsInline={true}
                  x5-video-player-type="h5"
                  poster={item?.coverUrl}
                  onEnded={() => handleVideoEnded(index)}
                  onPlay={() => handleVideoPlay(index)}
                  onPause={() => handleVideoPause(index)}
                  onWaiting={() => handleVideoWaiting(index)}
                  onPlaying={() => handleVideoPlaying(index)}
                  ref={ref => (videoRefs.current[index] = ref)}
                  style={{ height: firstElementHeight + "px" }}
                />
                {!videoPlayStatus[index] && (
                  <ImageM
                    src={Play}
                    className={styles.videoPlayIcon}
                    onClick={e => handlePlayClick(e, index)}
                  />
                )}
                {videoLoadingStatus[index] && <img src={Loading} className={styles.videoLoading} />}
                <div className={styles.videoVioce} onClick={e => handleVoiceClick(e, index)}>
                  <ImageM
                    src={videoVoiceStatus[index] ? VoiceOpen : VoiceClose}
                    className={styles.videoVioceIcon}
                  />
                </div>
                <div className={styles.scrollBar}>
                  <div className={styles.scrollBarBackground}>
                    <div
                      className={styles.scrollBarInner}
                      ref={ref => (videoScrollBarRefs.current[index] = ref)}></div>
                  </div>
                </div>
              </Swiper.Item>
            );
          } else {
            return (
              <Swiper.Item key={index} onClick={handleSwiperImageClick}>
                <ImageM
                  src={item.url}
                  fit="scale-down"
                  style={{ "--height": `${firstElementHeight}px` }}
                />
              </Swiper.Item>
            );
          }
        })}
      </Swiper>
      <div className={styles.badge}>
        {swiperCurrentIndex + 1}/{swiperSources.length}
      </div>
      <div className={styles.indicatorClass}>
        <SwiperCustomIndicator current={swiperCurrentIndex} total={swiperSources.length} />
      </div>
      <ImageViewer.Multi
        images={viewerImages}
        visible={ImageViewerVisible}
        defaultIndex={swiperCurrentIndex}
        onClose={() => {
          setImageViewerVisible(false);
        }}
        onIndexChange={index => {
          setSwiperCurrentIndex(index);
          swiperRef.current?.swipeTo(index);
        }}
        ref={imageViewerRef}
        renderFooter={renderFooter}
        maxZoom="auto"
      />
    </div>
  );
};

export default CustomSwiper;
