import ProductWaterfall from "@/components/product-waterfall";
import React from "react";
import { useHasNext, useLoadMore, useProductList } from "../hooks/useActivityProduct";

interface IProps {
  onCartChange?: () => void;
}

const ProductList: React.FC<IProps> = (props) => {
  const productList = useProductList();
  const hasNext = useHasNext();
  const loadMore = useLoadMore();

  return (
    <ProductWaterfall
      productList={productList}
      hasNext={hasNext}
      loadMore={loadMore}
      topRightLocationCode="H5_ScheduleDetail_SmallList_ProductPicRight"
      titleFrontLocationCode="H5_ScheduleDetail_SmallList_TitleFront"
      titleBottomLocationCode="H5_ScheduleDetail_SmallList_TitleDown"
      onCartChange={props.onCartChange}
    />
  );
};

export default ProductList;
