import { useCommonContext } from "@/utils/context/common";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { TrackData, TrackDataKey } from "@akc/taro-service";
import React, { useContext } from "react";

type Props = {
  children: React.ReactNode;
  onClick?:
    | ((evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | ((evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void>);
  trackData?: TrackData;
  trackContextKeys?: Array<TrackDataKey>;
  style?: React.CSSProperties;
  id?: string;
  ref?: React.RefObject<HTMLDivElement>;
  className?: string;
  trackEventName?: "resource_click" | "tab_click" | "btn_click" | "pick_up_coupon";
};

const Clickable: React.FC<Props> = ({
  children,
  onClick,
  trackData,
  trackContextKeys,
  style,
  id,
  ref,
  className,
  trackEventName = "resource_click",
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { trackData: contextTrackData } = useContext(TrackDataContext);
  trackContextKeys = (trackContextKeys ?? []).concat(["page_name", "previous_page_name"]);
  const requiredContextTrackData = contextTrackData
    ? trackContextKeys.reduce((acc, cur) => {
        acc[cur] = contextTrackData[cur];
        return acc;
      }, {})
    : {};

  const { track } = useCommonContext();
  return (
    <div
      ref={ref}
      id={id}
      className={className}
      style={style}
      onClick={evt => {
        evt.stopPropagation();
        onClick?.(evt);
        setTimeout(() => {
          try {
            track?.track?.(trackEventName, { ...requiredContextTrackData, ...trackData });
          } catch (e) {
            console.error(e);
          }
        }, 17);
      }}>
      {children}
    </div>
  );
};

export default Clickable;
