import React from "react";
import styles from "./ModeTab.module.less";

type Props = {
  mode: "product" | "activity";
  setMode: (a: "activity" | "product") => void;
};

const ModeTab: React.FC<Props> = ({ mode, setMode }) => {
  return (
    <div className={styles.modeSelectorContainer}>
      <div className={styles.options}>
        <div
          className={`${mode === "activity" ? styles.active : ""}`}
          onClick={() => {
            if (mode === "activity") {
              return;
            }
            setMode("activity");
          }}>
          今日大牌
        </div>
        <div
          className={`${mode === "product" ? styles.active : ""}`}
          onClick={() => {
            if (mode === "product") {
              return;
            }
            setMode("product");
          }}>
          甄选精品
        </div>
      </div>
      <div className={`${styles.modeSelectorLine} ${mode === "product" ? styles.right : ""}`}></div>
    </div>
  );
};

export default ModeTab;
