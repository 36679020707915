import { getProductList } from "@/api/shopPlus";
import ProductWaterfall from "@/components/product-waterfall";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { throttle } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
  brandId: string;
  sortModel: any;
  promoTag: any;
  sortType: any;
  category: any;
  filterBoardInfo: any;
  productStatus: any;
  onStatusShow: (param) => void;
}

const ProductList: React.FC<IProps> = ({
  brandId,
  sortModel,
  promoTag,
  sortType,
  category,
  filterBoardInfo,
  productStatus,
  onStatusShow,
}) => {
  const [prodArr, setProdArr] = useState<any>([]);
  const [leftProd, setLeftProd] = useState<any>([]);
  const [arrKey, setArrKey] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [search] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [queryLoading, setQueryLoading] = useState(false);

  useEffect(() => {
    if (prodArr.length > 0 && leftProd.length < 1) {
      setLeftProd([prodArr[arrKey]]);
      setArrKey(1);
    }
  }, [prodArr]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  const querHighCommission = throttle(async ({ from = 0 }, type?: any) => {
    const merchantShopCode = search.get("merchantShopCode");

    if (queryLoading) {
      return;
    }
    setQueryLoading(true);

    const params = {
      pageId: "H5_Shop",
      searchParams: {
        pageNum: from + 1,
        pageSize: 10,
        activityTypeGroup: 1,
        shopCodes: [merchantShopCode],
        scene: "MERCHANT_SHOP_DETAIL_ALL",
      },
      voConfig: {
        onlyCountTotal: false,
        showType: 2,
      },
    };

    if (brandId) {
      params.searchParams["brandIds"] = [brandId];
    }

    if (sortModel) {
      params.searchParams["sortModel"] = sortModel;
    }

    if (sortType) {
      params.searchParams["sortType"] = sortType;
    }
    if (category && !promoTag) {
      params.searchParams["categoryIdList"] = [category];
    } else if (promoTag) {
      params.searchParams["promotionTypes"] = [promoTag];
    }

    if (filterBoardInfo) {
      params.searchParams = { ...params.searchParams, ...filterBoardInfo };
    }

    if (typeof productStatus === "number") {
      params.searchParams["productStatus"] = productStatus;
    }

    const res: any = await getProductList(params);

    try {
      const {
        data: { result, hasNext, pageIndex, extra },
      } = res;
      setPageIndex(pageIndex);
      setHasMore(hasNext);
      if (params.searchParams.pageNum === 1) {
        onStatusShow(extra?.isShowProductStatus ?? false);
      }
      if (from > 0) {
        const valArr = result ? prodArr.concat(result) : result;
        setProdArr(valArr.map((item, index) => ({ ...item, rank: index + 1 })));
      } else if (result) {
        setProdArr(result);
        setArrKey(0);
      } else {
        setProdArr([]);
        setArrKey(0);
      }
      setTrackData(data => ({
        ...data,
        rank_name: sortModel?.toString(),
        rank_type: sortType?.toString(),
        page_name: "B2R旗舰店详情",
        tab_name: "全部",
        tab_rank: 1,
      }));
    } catch (err) {
      console.error(err);
    }

    setQueryLoading(false);
  }, 100);

  useEffect(() => {
    if (promoTag !== undefined) {
      setProdArr([]);
      setHasMore(true);
      querHighCommission({ from: 0 });
    }
  }, [brandId, category, sortModel, sortType, promoTag, filterBoardInfo, productStatus]);

  // 加载更多
  const loadMore = async () => {
    querHighCommission({ from: pageIndex }, "loadMore");
  };

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <ProductWaterfall
          productList={prodArr}
          hasNext={hasMore}
          loadMore={loadMore}
          hasSidebar={true}
          cardHasBorder={true}
          provideBackground={false}
          topRightLocationCode="H5_Shop_DecorateSales_TwoColumnProductPicRight"
          titleFrontLocationCode="H5_Shop_DecorateSales_TwoColumnProductNameFront"
          titleBottomLocationCode="H5_Shop_DecorateSales_TwoColumnProductNameDown"
        />
      </div>
    </>
  );
};

export default ProductList;
