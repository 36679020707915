import { ReactComponent as ArrowDownIcon } from "@/assets/arrow_down.svg";
import Clickable from "@/components/clickable";
import Tabs from "@/components/tabs";
import useIntersect from "@/utils/hooks/useIntersect";
import { Popup } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useUnactivate } from "react-activation";
import {
  usePrefixCategoryList,
  useSelectedPrefixCategory,
  useSelectedSizeInfo,
  useSetFilterInfo,
  useSetSelectedPrefixCategory,
  useSetSelectedSizeInfo,
  useSetSelectedSortInfo,
  useSizeInfoList,
  useSortPopupOptions,
} from "../hooks/useActivityProduct";
import styles from "./CategoryAndSize.module.less";

type Props = {
  onCategoryShowOrHide?: (boolean) => void;
  onSelectionChange?: () => void;
};

const CategoryAndSize: React.FC<Props> = ({ onCategoryShowOrHide, onSelectionChange }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const categoryTabDom = useRef<HTMLDivElement>(null);

  const prefixCategoryList = usePrefixCategoryList();
  const selectedPrefixCategory = useSelectedPrefixCategory();
  const setSelectedPrefixCategory = useSetSelectedPrefixCategory();
  const sizeInfoList = useSizeInfoList();
  const selectedSizeInfo = useSelectedSizeInfo();
  const setSelectedSizeInfo = useSetSelectedSizeInfo();
  const setSelectedSortInfo = useSetSelectedSortInfo();
  const setFilterInfo = useSetFilterInfo();
  const sortPopupOptions = useSortPopupOptions();

  const onClickCategory = (index: number) => {
    setSelectedPrefixCategory(prefixCategoryList?.[index]);
    setFilterInfo(undefined);
    onSelectionChange?.();
  };

  useUnactivate(() => {
    setPopupVisible(false);
  });

  useIntersect(
    categoryTabDom.current,
    0,
    type => {
      if (type === "leaveFromTop") {
        onCategoryShowOrHide?.(false);
      } else if (type === "enterFromTop") {
        onCategoryShowOrHide?.(true);
      }
    },
    -44,
  );

  // 默认只显示一行尺码
  const placeholderDom = useRef<HTMLDivElement>(null);
  let ratio = 1;
  if (document?.querySelectorAll("html")?.[0]) {
    ratio = parseFloat(getComputedStyle(document.querySelectorAll("html")[0]).fontSize) / 50;
  }
  const [defaultShownSizeCount, setDefaultShownSizeCount] = useState(1);
  useEffect(() => {
    if (!placeholderDom.current) {
      return;
    }
    const itemsWidth = [].slice.call(placeholderDom.current.children).map((item: any) => {
      return item.clientWidth;
    });
    let tmp = 1;
    if ((itemsWidth[0] + itemsWidth[1]) / ratio + 16 + 24 + 24 < 374) {
      tmp = 2;
    }
    if ((itemsWidth[0] + itemsWidth[1] + itemsWidth[2]) / ratio + 16 + 36 + 24 < 374) {
      tmp = 3;
    }
    setDefaultShownSizeCount(tmp);
  }, [placeholderDom, sizeInfoList]);

  return (
    <>
      {prefixCategoryList && (
        <div className={styles.container}>
          <div ref={categoryTabDom}>
            <Tabs
              data={prefixCategoryList?.map(item => ({
                text: item.name,
              }))}
              activeIndex={
                prefixCategoryList?.findIndex(item => item.code === selectedPrefixCategory?.code) ??
                0
              }
              onChange={onClickCategory}
            />
          </div>

          <div className={`${styles.sizeSelector} ${styles.options}`}>
            {selectedSizeInfo && (
              <div className={styles.selection}>
                <span>你选择的尺码</span>
                <div
                  className={`${styles.item} ${styles.active}`}
                  onClick={() => setSelectedSizeInfo(undefined)}>
                  <span className={styles.name}>{selectedSizeInfo.sizeName}</span>
                  <span className={styles.count}>({selectedSizeInfo.productNum}款)</span>
                </div>
              </div>
            )}
            {typeof selectedSizeInfo === "undefined" && (
              <div className={styles.placeholder} ref={placeholderDom}>
                {sizeInfoList?.slice(0, 3).map((item, index) => (
                  <div key={index} className={`${styles.item}`}>
                    <span className={styles.name}>{item.sizeName}</span>
                    <span className={styles.count}>({item.productNum}款)</span>
                  </div>
                ))}
              </div>
            )}
            {typeof selectedSizeInfo === "undefined" &&
              sizeInfoList?.slice(0, defaultShownSizeCount).map((item, index) => (
                <Clickable
                  trackData={{
                    resource_type: "尺码筛选",
                    btn_name: "尺码",
                  }}
                  trackContextKeys={[
                    "tab_name",
                    "tab_id",
                    "tab_rank",
                    "merchant_shop_code",
                    "activity_id",
                  ]}
                  key={index}
                  className={`${styles.item}`}
                  onClick={() => {
                    setFilterInfo(undefined);
                    onSelectionChange && onSelectionChange();
                    setSelectedSizeInfo(item);
                    // setSelectedSortInfo(sortPopupOptions[0]);
                    sortPopupOptions.length > 0 && setSelectedSortInfo(sortPopupOptions[0]);
                  }}>
                  <span className={styles.name}>{item.sizeName}</span>
                  <span className={styles.count}>({item.productNum}款)</span>
                </Clickable>
              ))}
            {sizeInfoList?.length &&
              (sizeInfoList.length > defaultShownSizeCount ||
                (selectedSizeInfo && sizeInfoList.length > 1)) && (
                <div className={styles.item} onClick={() => setPopupVisible(true)}>
                  <ArrowDownIcon className={styles.icon} />
                </div>
              )}
          </div>
        </div>
      )}
      <Popup
        visible={popupVisible}
        showCloseButton
        onMaskClick={() => {
          setPopupVisible(false);
        }}
        onClose={() => {
          setPopupVisible(false);
        }}
        bodyClassName={styles.popup}>
        <div className={styles.header}>选择尺码</div>
        <div className={`${styles.body} ${styles.options}`}>
          {sizeInfoList?.map((item, index) => (
            <Clickable
              trackData={{
                resource_type: "尺码筛选",
                btn_name: "尺码",
              }}
              trackContextKeys={[
                "tab_name",
                "tab_id",
                "tab_rank",
                "merchant_shop_code",
                "activity_id",
              ]}
              key={index}
              className={`${styles.item} ${item.sizeCode === selectedSizeInfo?.sizeCode ? styles.active : ""
                }`}
              onClick={() => {
                setFilterInfo(undefined);
                onSelectionChange?.();
                if (item.sizeCode !== selectedSizeInfo?.sizeCode) {
                  setSelectedSizeInfo(item);
                } else {
                  setSelectedSizeInfo(undefined);
                }
                sortPopupOptions.length > 0 && setSelectedSortInfo(sortPopupOptions[0]);
                // setSelectedSortInfo(sortPopupOptions[0]);
                setPopupVisible(false);
              }}>
              <span className={styles.name}>{item.sizeName}</span>
              <span className={styles.count}>({item.productNum}款)</span>
            </Clickable>
          ))}
        </div>
      </Popup>
    </>
  );
};

export default CategoryAndSize;
