import { getHotWord, getSuggestWord, getTabList } from "@/api/market";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import exposure from "@/utils/expose/exposure";
import { MICRO_APP_BASEURL, hostEnv } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { storeRem } from "@/utils/rem";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { ShopCardV2 } from "@akc/mx-taro-h5-components";
import { Dialog, Image, InfiniteScroll } from "antd-mobile";
import { debounce, isEmpty } from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserRole } from "../utils/identity";
import styles from "./search.module.less";
interface Props {
  appStore?: any;
  [key: string]: any;
}

const Home: React.FC<Props> = () => {
  const track = Track();
  const { navigate2Product, navigate2LiveRoom } = useNavigation();
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const word = search.get("key");
  const navigater = useNavigate();
  const [historyList, setHistoryList] = useState<any>([]);
  const [showTag, setShowTag] = useState<any>({
    text: "",
    show: false,
  });
  const [cardList, setCardList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [darkList, setDarkList] = useState<any[]>([{ searchWords: "" }]);
  const [hotList, setHotList] = useState<any[]>([]);
  const [inputVal, setInputVal] = useState("");
  const [showSuggest, setShowSuggest] = useState(false);
  const [suggestList, setSuggestList] = useState<any[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [over, setOver] = useState(false);
  const [maxHeight, setMaxHeight] = useState<any>(75);

  //加载更多
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <div className={styles.infinite}>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {cardList?.length > 0 && (
              <>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
                <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const queryDarkWord = async () => {
    // const res = await getDarkWord({
    //   shopBizCode: search.get("shopId"),
    //   scene: "SHOP_SEARCH"
    // });
    setDarkList([{ searchWords: word ? word : "" }]);
  };

  const queryHotWord = async () => {
    const res = await getHotWord({
      shopBizCode: search.get("shopId"),
      scene: "SHOP_SEARCH",
    });
    let list = res?.data?.hotWords || [];
    list = list.filter(item => {
      return isEmpty(item.hotLink);
    });
    setHotList(list);
  };

  //获取tab下的列表
  const queryTabList = async (keyword, pageNum) => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    setLoading(true);
    const res: any = await getTabList({
      pageId: "H5_Shop",
      searchParams: {
        isNeedSpuIds: true,
        pageNum: pageNum,
        pageSize: 10,
        keyword: keyword,
      },
      voConfig: {
        withProduct: true,
        withProductCnt: 3,
      },
    });
    let list: any = [];
    for (const item of res?.data?.result || []) {
      list.push(item);
    }
    if (pageNum > 1 && cardList.length > 0) {
      list = cardList.concat(list);
    }
    setCardList(list);
    setShowSuggest(false);
    setPageNum(pageNum + 1);
    setHasMore(res?.data?.hasNext ?? false);
    setQueryLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    storeRem();
    // unmountApp("h5shop", {
    //   destroy: true,
    // });
    queryDarkWord();
    queryHotWord();
    // if (!isEmpty(word) && word !== "null" && word !== "undefined") {
    //   let keywords: any = localStorage.getItem("keywords");
    //   if (keywords) {
    //     keywords = JSON.parse(keywords);
    //     const index = keywords.indexOf(word);
    //     if (index < 0) {
    //       keywords.unshift(word);
    //     } else {
    //       keywords.splice(index, 1);
    //       keywords.unshift(word);
    //     }
    //   } else {
    //     keywords = [];
    //     keywords.unshift(word);
    //   }
    //   setHistoryList(keywords);
    //   localStorage.setItem("keywords", JSON.stringify(keywords));
    //   setShowTag({ text: word, show: true });
    //   queryTabList(word, 1);
    // }
  }, []);

  const clear = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setShowTag({
      text: "",
      show: false,
    });
    setShowSuggest(false);
    setCardList([]);
    setHasMore(false);
    setPageNum(1);
    setInputVal("");
    setMaxHeight(75);
  };

  useActivate(() => {
    console.log("in useActivate search");
    document.title = "\u200E";
    setMaxHeight(75);
    setShowMore(true);
    if (showTag?.text !== word) {
      // setCardList([]);
      // if (!isEmpty(word) && word !== "null" && word !== "undefined") {
      //   let keywords: any = localStorage.getItem("keywords");
      //   if (keywords) {
      //     keywords = JSON.parse(keywords);
      //     const index = keywords.indexOf(word);
      //     if (index < 0) {
      //       keywords.unshift(word);
      //     } else {
      //       keywords.splice(index, 1);
      //       keywords.unshift(word);
      //     }
      //   } else {
      //     keywords = [];
      //     keywords.unshift(word);
      //   }
      //   setHistoryList(keywords);
      //   localStorage.setItem("keywords", JSON.stringify(keywords));
      //   setShowTag({ text: word, show: true });
      //   queryTabList(word, 1);
      // } else {
      //   setCardList([]);
      //   setHasMore(false);
      //   setPageNum(1);
      // }
      setDarkList([{ searchWords: word ? word : "" }]);
    }
    if (
      localStorage.getItem("search_tag_market") === null ||
      localStorage.getItem("search_tag_market") === "undefined"
    ) {
      clear();
    }
    let list: any = localStorage.getItem("keywords");
    if (!isEmpty(list)) {
      list = JSON.parse(list);
      list = list.splice(0, 20);
      setHistoryList(list);
    } else {
      setHistoryList([]);
    }
  });

  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  useEffect(() => {
    let list: any = localStorage.getItem("keywords");
    if (!isEmpty(list)) {
      list = JSON.parse(list);
      list = list.splice(0, 20);
      setHistoryList(list);
    } else {
      setHistoryList([]);
    }
    track.track("$pageview", {
      page_name: "搜索结果页",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < cardList.length; i++) {
        const dom = document.getElementById(`market-${cardList[i]["shopCode"]}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [cardList?.length]);

  const searchText = (e?: any) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
    if (showTag.show) {
      return;
    }
    let text = e.target.value;
    if (isEmpty(text) && darkList?.length > 0) {
      text = darkList[0].searchWords;
    }
    if (e.key === "Enter" && !isEmpty(text)) {
      track.track("btn_click", {
        page_name: "店铺聚合",
        previous_page_name: search.get("page_name"),
        domain: "B2R旗舰店",
        btn_name: "输入词",
        btn_text: text,
        module: "搜索框",
      });
      //查询
      let keywords: any = localStorage.getItem("keywords");
      if (keywords) {
        keywords = JSON.parse(keywords);
        const index = keywords.indexOf(text);
        if (index < 0) {
          keywords.unshift(text);
        } else {
          keywords.splice(index, 1);
          keywords.unshift(text);
        }
      } else {
        keywords = [];
        keywords.unshift(text);
      }
      const list = keywords.splice(0, 20);
      setHistoryList(list);
      localStorage.setItem("keywords", JSON.stringify(list));
      setShowTag({ text: text, show: true });
      queryTabList(text, 1);
    } else {
      setCardList([]);
      setHasMore(false);
      setShowSuggest(false);
      setPageNum(1);
    }
  };

  const searchBtn = () => {
    const dom: any = document.getElementById("inputDom");
    let text = dom.value;
    if (isEmpty(text) && darkList?.length > 0) {
      text = darkList[0].searchWords;
    }
    track.track("btn_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      btn_name: "搜索",
      btn_text: text,
      module: "搜索框",
    });
    if (showTag.show) {
      queryTabList(showTag.text, 1);
      return;
    }
    if (!isEmpty(text)) {
      let keywords: any = localStorage.getItem("keywords");
      if (keywords) {
        keywords = JSON.parse(keywords);
        const index = keywords.indexOf(text);
        if (index < 0) {
          keywords.unshift(text);
        } else {
          keywords.splice(index, 1);
          keywords.unshift(text);
        }
      } else {
        keywords = [];
        keywords.unshift(text);
      }
      const list = keywords.splice(0, 20);
      setHistoryList(list);
      localStorage.setItem("keywords", JSON.stringify(keywords));
      setShowTag({ text: text, show: true });
      queryTabList(text, 1);
    } else {
      setCardList([]);
      setHasMore(false);
      setPageNum(1);
    }
  };

  const clearKey = () => {
    Dialog.confirm({
      content: "确认删除搜索记录?",
      bodyClassName: "dialog_search",
      onConfirm: async () => {
        localStorage.removeItem("keywords");
        setHistoryList([]);
      },
    });
  };

  const clickTab = text => {
    track.track("btn_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      btn_name: "搜索历史",
      btn_text: text,
      module: "搜索框",
    });
    setShowTag({
      text: text,
      show: true,
    });
    setShowSuggest(false);
    let keywords: any = localStorage.getItem("keywords");
    if (keywords) {
      keywords = JSON.parse(keywords);
      const index = keywords.indexOf(text);
      if (index < 0) {
        keywords.unshift(text);
      } else {
        keywords.splice(index, 1);
        keywords.unshift(text);
      }
    } else {
      keywords = [];
      keywords.unshift(text);
    }
    const list = keywords.splice(0, 20);
    setHistoryList(list);
    localStorage.setItem("keywords", JSON.stringify(list));
    queryTabList(text, 1);
  };

  const loadMore = async () => {
    if (hasMore) {
      queryTabList(showTag.text, pageNum);
    }
  };

  const onShopAction = (e, index) => {
    localStorage.setItem("search_tag_market", "true");
    console.log("点击店铺回调", e);
    track.track("resource_click", {
      page_name: "搜索结果页",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      resource_content: e.shopName,
      resource_rank: index,
      merchant_shop_code: e.shopCode,
    });
    if (search.get("distributorId")) {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&shopId=${search.get(
          "shopId",
        )}&distributorId=${search.get("distributorId")}&previous_page_name=${encodeURIComponent(
          "搜索结果页",
        )}&productType=1`,
      );
    } else {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&shopId=${search.get(
          "shopId",
        )}&previous_page_name=${encodeURIComponent("搜索结果页")}&productType=1`,
      );
    }
  };

  const onProductAction = (e, index) => {
    localStorage.setItem("search_tag_market", "true");
    console.log("点击商品回调", e);
    track.track("resource_click", {
      page_name: "搜索结果页",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      activity_spu_code: e.activitySpuId,
      activity_id: e.activityId,
      sku_id: e.selectSkuId,
      btn_name: "商品点击",
      resource_rank: index,
      merchant_shop_code: e.shopCode,
    });
    navigate2Product({
      activityId: e.activityId,
      activitySpuId: e.activitySpuId,
      selectSkuId: e.selectSkuId,
    });
  };
  const onStudyTourMaterialAction = e => {
    console.log("点击游学素材图片/视频回调", e);
  };
  const onLiveAction = (e, index) => {
    localStorage.setItem("search_tag_market", "true");
    track.track("resource_click", {
      page_name: "搜索结果页",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      btn_name: "直播点击",
      live_no: e.liveNo,
      resource_rank: index,
      merchant_shop_code: e.shopCode,
    });
    console.log("点击直播回调", e);
    navigate2LiveRoom({
      liveId: e.liveNo,
      liveStatus: e.liveStatus,
    });
  };

  const goSearch = () => {
    track.track("resource_click", {
      page_name: "搜索结果页",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      btn_name: "搜索全场",
    });
    const dom: any = document.getElementById("inputDom");
    let text = dom.value;
    if (showTag.show) {
      text = showTag.text;
    }
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/#/mixSearch?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}&hotKey=${encodeURIComponent(text)}`,
      );
    } else {
      navigater(
        `${MICRO_APP_BASEURL}/#/mixSearch?shopId=${shopId}&channel=h5Mall&hotKey=${encodeURIComponent(
          text,
        )}`,
      );
    }
  };

  const changeShow = () => {
    const obj = { ...showTag };
    if (showTag.show) {
      obj.show = false;
      setShowTag(obj);
      const dom: any = document.getElementById("inputDom");
      setInputVal(obj.text);
      setCardList([]);
      setShowSuggest(true);
      setTimeout(() => {
        dom?.focus();
      }, 0);
    }
  };

  const submit = e => {
    e.preventDefault();
  };

  const querySuggestWord = debounce(async text => {
    if (isEmpty(text)) {
      return;
    }
    const res = await getSuggestWord({
      shopBizCode: search.get("shopId"),
      keyword: text,
      scene: "SHOP_SEARCH",
    });
    const list: any = [];
    if (res?.data?.suggest?.length > 0) {
      for (let item of res.data.suggest) {
        const reg = new RegExp(`(${text})`, "g");
        item = {
          text: `<div>${item.replace(reg, `<span style="color:#ff0000">${text}</span>`)}</div>`,
          old: item,
        };
        list.push(item);
      }
    }
    setSuggestList(list || []);
    // setTimeout(() => {
    //   const doms: any = document.getElementsByClassName("sug_item");
    //   if (doms?.length > 0) {
    //     const reg = new RegExp(`(${text})`, "g");
    //     for (const item of doms) {
    //       item.innerHTML = item.innerHTML.replace(reg, `<span style="color:#ff0000">${text}</span>`);
    //     }
    //   }
    // }, 500);
    setShowSuggest(true);
  }, 500);

  const changeText = e => {
    const text = e?.target?.value;
    console.log(text, showTag.show);
    setInputVal(text);
    if (isEmpty(text)) {
      setShowSuggest(false);
      setShowTag({ show: false, text: "" });
      setMaxHeight(75);
    }
  };

  useEffect(() => {
    querySuggestWord(inputVal);
    // 清除上一个 effect，确保每次只有一个防抖函数在运行
    return () => {
      querySuggestWord.cancel();
    };
  }, [inputVal]);

  useEffect(() => {
    if (showTag.show || showSuggest) {
      return;
    }
    const rem: any = document.documentElement.style.fontSize.split("px")[0];
    const height: any = document.getElementById("tags")?.offsetHeight;
    if (height / rem > 1.7) {
      setShowMore(true);
      setOver(true);
    } else {
      setShowMore(false);
      setOver(false);
    }
  }, [historyList?.length, showTag.show, showSuggest]);

  return (
    <div className={styles.search}>
      <div className={styles.searchBox}>
        <img
          onClick={() => goBackOrHome()}
          className={styles.back}
          src="https://akim-oss.aikucun.com/11d024154013c0ca47dfaeca33ecdeb6a5b9fcb6_1694082344311_50.png"></img>
        <div className={styles.inputBox} onClick={() => changeShow()}>
          <img
            className={styles.img}
            src="https://akim-oss.aikucun.com/f04090805c6eebdfc7f9cb69b81f3e44c3a3636a_1694086326376_51.png"></img>
          <form onSubmit={submit} action="javascript:return true;">
            <input
              className={styles.input + " " + (showTag.show ? styles.hid : styles.visiable)}
              onKeyDown={e => searchText(e)}
              onChange={e => changeText(e)}
              id="inputDom"
              type="search"
              value={inputVal}
              placeholder={darkList[0].searchWords}
              autoFocus></input>
          </form>
          <div className={styles.tag + " " + (showTag.show ? styles.visiable : styles.hid)}>
            <div
              style={{
                fontSize: pxtorem(14),
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: pxtorem(200),
              }}>
              {showTag.text}
            </div>
            <img
              onClick={e => clear(e)}
              className={styles.close}
              src="https://akim-oss.aikucun.com/feacb8e58e1e4974fd9a12dc2e5e730ffd3ec89f_1694091300902_1.png"></img>
          </div>
        </div>
        <div className={styles.text} onClick={() => searchBtn()}>
          搜索
        </div>
      </div>
      {suggestList.length > 0 && showSuggest && !showTag.show && (
        <div className={styles.suggest}>
          {suggestList.map((item, index) => {
            return (
              <div
                className={styles.item + " " + "sug_item"}
                key={index}
                onClick={() => clickTab(item.old)}
                dangerouslySetInnerHTML={{ __html: item.text }}></div>
            );
          })}
        </div>
      )}
      {historyList.length > 0 && !showTag.show && !showSuggest && (
        <div
          className={styles.history}
          style={{
            borderBottomLeftRadius: hotList?.length <= 0 ? pxtorem(18) : 0,
            borderBottomRightRadius: hotList?.length <= 0 ? pxtorem(18) : 0,
          }}>
          <div className={styles.top}>
            <div className={styles.text}>搜索历史</div>
            <img
              onClick={() => clearKey()}
              className={styles.img}
              src="https://akim-oss.aikucun.com/3755f56f2f83187613e709269508ddfdb8018b9c_1694088017876_52.png"></img>
          </div>
          <div
            className={styles.tagsDiv}
            style={{ maxHeight: maxHeight ? pxtorem(maxHeight) : "unset" }}>
            <div className={styles.tags} id="tags">
              {historyList.map((item, index) => {
                return (
                  <div className={styles.item} key={index} onClick={() => clickTab(item)}>
                    {item}
                  </div>
                );
              })}
              {!showMore && over && (
                <div
                  className={styles.up}
                  onClick={() => {
                    setMaxHeight(75);
                    setShowMore(true);
                  }}>
                  <img src="https://akim-oss.aikucun.com/2b9d013177da6700fbaee9f12bb86452c577caf6_1700657079063_17.png" />
                </div>
              )}
            </div>
            {showMore && over && (
              <div
                className={styles.down}
                onClick={() => {
                  setMaxHeight(null);
                  setShowMore(false);
                }}>
                <img src="https://akim-oss.aikucun.com/2b9d013177da6700fbaee9f12bb86452c577caf6_1700657079063_17.png" />
              </div>
            )}
          </div>
        </div>
      )}
      {hotList.length > 0 && !showSuggest && !showTag.show && (
        <div className={styles.hot}>
          <div className={styles.top}>
            <div className={styles.text}>大家都在搜</div>
          </div>
          <div className={styles.tags}>
            {hotList.map((item, index) => {
              return (
                <div className={styles.item} key={index} onClick={() => clickTab(item.hotName)}>
                  {item.hotName}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {cardList?.length > 0 && !showSuggest && (
        <div className={styles.content}>
          <div className={styles.cards}>
            {cardList.map((item: any, index) => {
              return (
                <div
                  id={`market-${item.shopCode}`}
                  data-param={JSON.stringify({
                    page_name: "店铺聚合搜索页",
                    domain: "B2R旗舰店",
                    module: "店铺列表",
                    resource_rank: index,
                    merchant_shop_code: item?.shopCode,
                    resource_content: item?.shopName,
                  })}
                  data-eventid={`market-${item?.shopCode}`}
                  key={index}
                  className={styles.card_item}>
                  <ShopCardV2
                    nameFrontTagLocationCode={`${hostEnv}_Shop_ShopNameFront`}
                    mainLocationCode={`${hostEnv}_Shop_ShopNameDown`}
                    shopInfo={item}
                    index={index}
                    sign="search"
                    onShopAction={e => onShopAction(e, index)}
                    onProductAction={e => onProductAction(e, index)}
                    onStudyTourMaterialAction={onStudyTourMaterialAction}
                    onLiveAction={e => onLiveAction(e, index)}
                    labelLocationVOList={item?.labelLocationVOList}
                    profitTag="赚"
                    role={getUserRole()}
                    terminal="h5"></ShopCardV2>
                </div>
              );
            })}
          </div>
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
      )}
      {cardList.length <= 0 && !loading && showTag.show && (
        <div className={styles.empty}>
          <img
            className={styles.img}
            src="https://akim-oss.aikucun.com/ec63eb18e254f5a136c9e2406e2595e1d84aa8f6_1693451945207_20.png"></img>
          <div className={styles.text}>未找到相关店铺</div>
          <div className={styles.btn} onClick={() => goSearch()}>
            搜索全场
          </div>
        </div>
      )}
    </div>
  );
};

export default inject("appStore")(observer(Home));
