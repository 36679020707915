import { RedPacketDetailCard } from "@/models/reward";
import { formatDate } from "@/utils/date-format";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React from "react";
import styles from "./RedPacketDetailCard.module.less";

interface RedPacketDetailCardProps {
  detail?: RedPacketDetailCard;
  showLine?: boolean;
}

const RedPacketDetailCardComp: React.FC<RedPacketDetailCardProps> = ({ detail, showLine }) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.first}>
          <div className={styles.name}>{detail?.bizTypeName}</div>
          <div
            className={`${styles["firNum"]} ${(detail?.amount || 0) > 0 ? styles["active"] : styles[""]}`}>
            {(detail?.amount || 0) > 0 ? `+${detail?.amount}` : detail?.amount}
          </div>
        </div>
        <div className={styles.second}>
          {detail?.totalFreeAmt !== undefined && (
            <div className={styles.balance}>{`余额: ￥${detail?.totalFreeAmt}`}</div>
          )}
          {detail?.tranTimestamp && (
            <div className={styles.date}>
              {formatDate(new Date(parseInt(detail?.tranTimestamp)))}
            </div>
          )}
        </div>
        {(detail?.sourceBillNo?.length || 0) > 0 && (
          <div
            className={styles.order}
            onClick={() => {
              if (detail?.sourceBillNo) {
                copy(detail?.sourceBillNo);
                Toast.show("复制成功");
              }
            }}>
            <div className={styles.orderNum}>订单号: {`${detail?.sourceBillNo}`}</div>
            <div className={styles.copy}>复制</div>
          </div>
        )}
        {showLine && <div className={styles.line} />}
      </div>
    </>
  );
};

export default RedPacketDetailCardComp;
