import { Swiper } from "antd-mobile";
import React from "react";

const replaceSwiperComp: React.FC<Omit<any, "itemCount" | "itemRenderer">> = props => {
  if (!props.children || (Array.isArray(props.children) && props.children.length === 0)) {
    return null;
  }
  return (
    <Swiper
      ref={props.swiperRef}
      defaultIndex={props.current}
      indicator={props.indicatorDots}
      autoplay={props.autoplay}
      loop={props.circular}
      direction={props.vertical ? "vertical" : "horizontal"}
      stuckAtBoundary={props.stuckAtBoundary}
      slideSize={props.slideSize}
      onIndexChange={idx => {
        props.onChange?.({ detail: { current: idx } });
      }}>
      {props.children.map((item: any) => (
        <Swiper.Item key={item}>{item}</Swiper.Item>
      ))}
    </Swiper>
  );
};

export default replaceSwiperComp;
