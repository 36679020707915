import { fetchAccountInfo, fetchRedPacketDetailList } from "@/api/daily-reward";
import { AccountInfo, RedPacketDetailCard } from "@/models/reward";
import { formatDateYYYYMM2 } from "@/utils/date-format";
import { useEffect, useRef, useState } from "react";

//YYYYMMDD
export const formatYYYYMMDD = date => {
  // 数字格式化函数，补齐前导零
  function formatNumber(val) {
    return val < 10 ? "0" + val : val;
  }
  const year = date.getFullYear(); // 获取年份
  const month = date.getMonth() + 1; // 获取月份（注意要加1，因为月份从0开始）
  const day = date.getDate(); // 获取日
  return `${year}${formatNumber(month)}${formatNumber(day)}`;
  // 使用字符串模板语法将年月日组合成字符串，并按照指定格式输出
};

//YYYYMMDD
export const getFirstDayOfMonth = date => {
  function formatNumber(val) {
    return val < 10 ? "0" + val : val;
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}${formatNumber(month)}01`;
};

//YYYYMMDD
export const getLastDayOfMonth = date => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  // 创建下个月的第一天，然后减去一天
  const lastDay = new Date(year, month, 0);

  return formatYYYYMMDD(lastDay);
};

const useRedPacketDetail = () => {
  const [list, setList] = useState<RedPacketDetailCard[]>([]);
  const pageIndex = useRef<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [selectDateStr, setSelectDateStr] = useState<string>(formatDateYYYYMM2(new Date()));
  const [redPacket, setRedPacket] = useState<AccountInfo>();
  const curBeginDate = useRef<string>(getFirstDayOfMonth(new Date()));
  const curEndDate = useRef<string>(getLastDayOfMonth(new Date()));
  // 请求红包余额
  const updateRedPacketAmount = async () => {
    fetchAccountInfo().then(res => {
      if (res?.freeBalance !== undefined) {
        setRedPacket(res);
      }
    });
  };

  // 请求红包明细列表
  const loadDetailList = async () => {
    setLoading(true);
    const res = await fetchRedPacketDetailList({
      pageNo: pageIndex.current,
      pageSize: 10,
      beginDate: curBeginDate.current,
      endDate: curEndDate.current,
    });
    setLoading(false);
    if (!res) {
      return;
    }
    if (pageIndex.current === 1) {
      setList(res?.data || []);
    } else {
      setList([...(list ?? []), ...(res?.data || [])]);
    }
    setHasMore(res?.hasNext ?? false);
    pageIndex.current += 1;
  };

  useEffect(() => {
    document.title = "日日奖红包明细";
    pageIndex.current = 1;
    curBeginDate.current = getFirstDayOfMonth(new Date());
    curEndDate.current = getLastDayOfMonth(new Date());
    updateRedPacketAmount();
    loadDetailList();
  }, []);

  const loadMore = async () => {
    if (hasMore && !loading) {
      loadDetailList();
    }
  };

  const onDateClick = () => {
    setDatePickerVisible(true);
  };

  const formatDateYYYYMMDD = date => {
    // 数字格式化函数，补齐前导零
    function formatNumber(val) {
      return val < 10 ? "0" + val : val;
    }
    const year = date.getFullYear(); // 获取年份
    const month = date.getMonth() + 1; // 获取月份（注意要加1，因为月份从0开始）
    const day = date.getDate(); // 获取日

    return `${year}.${formatNumber(month)}.${formatNumber(day)}`;
    // 使用字符串模板语法将年月日组合成字符串，并按照指定格式输出
  };

  const getTimeFromDatePicker = (startDate?: string, endDate?: string) => {
    if (startDate && endDate) {
      setSelectDateStr(
        `${formatDateYYYYMMDD(new Date(startDate))}-${formatDateYYYYMMDD(new Date(endDate))}`,
      );
      curBeginDate.current = formatYYYYMMDD(new Date(startDate));
      curEndDate.current = formatYYYYMMDD(new Date(endDate));
    } else {
      setSelectDateStr(formatDateYYYYMM2(startDate ? new Date(startDate) : new Date()));
      curBeginDate.current = getFirstDayOfMonth(startDate ? new Date(startDate) : new Date());
      curEndDate.current = getLastDayOfMonth(startDate ? new Date(startDate) : new Date());
    }
    pageIndex.current = 1;
    loadDetailList();
  };

  return {
    list,
    pageIndex,
    hasMore,
    loading,
    loadMore,
    onDateClick,
    datePickerVisible,
    setDatePickerVisible,
    getTimeFromDatePicker,
    selectDateStr,
    redPacket,
  };
};

export default useRedPacketDetail;
