import { applyCancelAccount } from "@/api/my";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { Dialog, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import Navigation from "../navigation";
import styles from "./index.module.less";

interface Props {
  applySuccess: any;
}

const Apply: React.FC<Props> = props => {
  const [hasChecked, setChecked] = useState(false);
  const { navigate2H5 } = useNavigation();

  useEffect(() => {
    document.title = "申请注销账号";
  }, []);

  const openAgreement = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const options: IOptions = {
      path: "/#/user-protocol",
      query: "docUrl=https://h5.aikucun.com/p/VfWoDzQtG.html",
      is_micro_app: true,
    };
    navigate2H5(options);
  };

  const actionApply = async ({ isConfirm = false } = {}) => {
    const params = {
      isConfirm,
    };
    const res: any = await applyCancelAccount(params);
    if (res && res.success && res.data) {
      const { status, msg, title } = res.data;
      if (status === 0) {
        // 申请成功
        props.applySuccess();
      } else if (status === 2) {
        const modal = Dialog.show({
          title: title || "账号存在资产",
          content: msg || "当前账号存在未使用的优惠券或积分资产，是否放弃资产，强制注销？",
          actions: [
            [
              {
                key: "confirm",
                text: "确认注销",
                style: { color: "#666666" },
                onClick: () => {
                  actionApply({ isConfirm: true });
                  modal.close();
                },
              },
              {
                key: "close",
                text: "暂不注销",
                bold: true,
                style: { color: "#FF4747" },
                onClick: () => {
                  modal.close();
                  // 跳转到我的页面
                  // const options: IOptions = {
                  //   path: "/mx-shop-micro/personal",
                  //   is_micro_app: true,
                  // };
                  // navigate2H5(options);
                },
              },
            ],
          ],
        });
      } else {
        const modal = Dialog.show({
          title: title || "注销失败",
          content: msg || "当前账号存在未完成的订单，订单过售后期，才可进行账号注销！",
          closeOnMaskClick: true,
          actions: [
            {
              key: "confirm",
              text: "我知道了",
              bold: true,
              style: { color: "#FF4747" },
              onClick: () => {
                modal.close();
              },
            },
          ],
        });
      }
    }
  };

  const submit = () => {
    if (!hasChecked) {
      Toast.show({
        content: "请先勾选同意协议！",
        maskClickable: false,
        position: "bottom",
        maskStyle: { zIndex: 9999 },
      });
      return;
    } else {
      const modal = Dialog.show({
        title: "账号注销确认",
        content: "提交注销申请后的14日内，再次访问账号时，可以解除账号注销",
        actions: [
          [
            {
              key: "confirm",
              text: "确认注销",
              style: { color: "#666666" },
              onClick: () => {
                actionApply();
                modal.close();
              },
            },
            {
              key: "close",
              text: "暂不注销",
              bold: true,
              style: { color: "#FF4747" },
              onClick: () => {
                modal.close();
              },
            },
          ],
        ],
      });
    }
  };

  return (
    <div className={styles.wrap}>
      <Navigation />
      <div className={styles.container}>
        <div className={styles.main}>
          <dl className={styles.passage}>
            <dt className={styles.title}>注销限制</dt>
            <dd className={styles.cont}>当存在未完成的订单时，不可进行账号注销。</dd>
            <dd className={styles.cont}>注销时，需要勾选并同意用户协议。</dd>
          </dl>
          <dl className={styles.passage}>
            <dt className={styles.title}>解除注销</dt>
            <dd className={styles.cont}>
              提交注销申请后的14日内，再次访问账号时，可以解除账号注销。
            </dd>
          </dl>
        </div>
        <div className={styles.footer}>
          <div
            className={styles.checkWrap}
            onClick={() => {
              setChecked(!hasChecked);
            }}>
            <div className={`${styles.checkBox} ${hasChecked && styles.checked}`}></div>
            <div>
              同意
              <span className={styles.hl} onClick={openAgreement}>
                《用户协议》
              </span>
            </div>
          </div>
          <div className={styles.longBtn} onClick={submit}>
            申请注销
          </div>
        </div>
      </div>
    </div>
  );
};
export default Apply;
