import TopNavigation, { NavigationBarRef } from "@/components/top-navigation";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useSelectedActivity, useSelectedActivityIdList } from "../hooks/useActivityProduct";

type Props = {
  showSearchIcon: boolean;
  children?: React.ReactNode;
};

const Navigation = forwardRef<NavigationBarRef, Props>(({ children, showSearchIcon }, ref) => {
  const selectedIdList = useSelectedActivityIdList();
  const selectedActivity = useSelectedActivity();

  const navigationRef = useRef<NavigationBarRef>(null);

  const triggerAddCartSuccess = () => {
    navigationRef.current?.triggerAddCartSuccess();
  };

  useImperativeHandle(ref, () => ({
    triggerAddCartSuccess,
  }));

  return (
    <TopNavigation
      ref={navigationRef}
      hideSearchIcon={!showSearchIcon}
      searchPageParams={{
        from: "brand",
        scene: "SEARCH_PRODUCT_FOR_ACTIVITY",
        activityId: selectedIdList.length > 0 ? selectedIdList.join(",") : "",
        activityType: selectedActivity?.activityType,
      }}
      center={children}
    />
  );
});

export default Navigation;
