import { ProductDetailModel, SKUBaseInfoListItem } from "@akc/biz-api";
import { Image, Popup } from "antd-mobile";
import React, { ReactNode } from "react";
import styles from "./index.module.less";

type Props = {
  product?: ProductDetailModel;
};

/**
 * 商品参数组件
 */
const ProductParamtersComp: React.FC<Props> = props => {
  const informationList = props.product?.skuBaseInfoVO?.informationList;
  return informationList?.map((e, index) => {
    return (
      <div className={styles.productParamtersC} key={index}>
        <div className={styles.content}>
          <div className={styles.name}>{e.itemCode}</div>
          <div className={styles.desc}>{e.itemName}</div>
        </div>
        <div className={styles.line} />
      </div>
    );
  });
};

export default ProductParamtersComp;
