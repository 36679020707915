import { ProductSnapshot } from "@/models/product";
import React from "react";
import styles from "./index.module.less";

type Props = {
  snapshotDetail?: ProductSnapshot;
};

/**
 * 商品参数组件
 */
const ProductParamtersComp: React.FC<Props> = props => {
  const informationList = props.snapshotDetail?.propertyList;
  return informationList?.map((e, index) => {
    let valueStr =
      (e.propertyValueList?.length > 1
        ? e.propertyValueList.map(v => v.value).join(" ")
        : e.propertyValueList?.[0]?.value || "") || "";
    return (
      <div className={styles.container} key={index}>
        <div className={styles.content}>
          <div className={styles.name}>{e.propertyName}</div>
          <div className={styles.desc}>{valueStr}</div>
        </div>
        <div className={styles.line} />
      </div>
    );
  });
};

export default ProductParamtersComp;
