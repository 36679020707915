import CouponNotice from "@/components/coupon-notice";
import ProductWaterfall from "@/components/product-waterfall";
import TopNavigation from "@/components/top-navigation";
import { Popup } from "antd-mobile";
import React from "react";
import { useJoinOrderNX } from "./hooks";
import styles from "./index.module.less";

/**
 * 多买优惠页面
 * 
 * 参照: gray-h5shop views/NpieceXfold/goodsOfNpieceXfold.vue
 */
const JoinOrderNX: React.FC = () => {
  const control = useJoinOrderNX();

  return <div className={styles.container}>
    <TopNavigation
      hideHomeText
      hideSearchIcon
      hideFavoriteIcon
      backgroundColor="#fff"
      className={styles.topNavigation}
      bgColorTrackScroll={false}
    />
    <div className={styles.scrollContent}>
      {/* 顶部优惠券数量信息 */}
      <CouponNotice />

      {/* 优惠券内容 */}

      {/* 优惠券倒计时 */}

      {/* 可悬停内容 */}
      <div className={styles.stickyContent}>
        <div className={styles.top}>
          <span className={styles.title}>活动规则</span>
          <span className={styles.value}>{control.ruleDesc}</span>
        </div>
        {control.isCommissionVisible && control.theCommissionRule && (
          <div className={styles.bottom} onClick={control.onCommissionClick}>
            <span>分佣规则</span>
            <img className={styles.img} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAABc0lEQVRIS72WsUvDQBSHf+9owA46+V90KL27qZtLFWtR3MRBcBIXcXcoOAuCozroooODTkI3HUToSwLiqqNuOnUJNCcHKQTRJinNZbvkve8jj7v3juDooTweZm7ZOK11L0/8XzGZon6/v01E50nyqVJqh4jiosJMETPvAThOgW+q1epmrVaLisgyRcaYiu/7lwA2UuCe53nr9Xp9kFeWKbKgbrcrOp3OiTFmNwV+jqKo3Ww2v/LIcolGIGY+BHCQAr8KIRallB9ZskIiC/N9f98YcwRglPsOoKW1fhsnKyyyMGbeAnAGoJLAP4loSSn18p9sIpGFhWG4OhwOrwHM2DURfQshVhqNxtNE52hcOZh5AcAtgLlENiCiZSnl4++8if9oBAqCQMdxfA9gPnkXaq2lC1GgtVZTFdnSGWPuiGg2Vbq2lPJhaiJmXgNwVepmcLK9nRzY0luQk6bKzB6Ai9LHhLPB52yUJ926/MtJ1kDL+/0HAgWfGbR5SuUAAAAASUVORK5CYII=" />
          </div>
        )}
      </div>

      {/* 商品列表 */}
      <ProductWaterfall
        productList={control.productList}
        hasNext={control.hasNext}
        loadMore={control.loadMore}
        topRightLocationCode="H5_ProductList_ProductPicRight"
        titleBottomLocationCode="H5_ProductList_TitleDown"
        titleFrontLocationCode="H5_ProductList_TitleFront"
        provideBackground={false}
      />
    </div>

    {/* 分佣规则弹窗 */}
    <Popup
      visible={control.isCommissionDetailVisible}
      onMaskClick={() => {
        control.setIsCommissionDetailVisible(false);
      }}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        minHeight: "40vh",
        zIndex: 10000,
      }}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.7)",
      }}>
      <div
        className={styles.popupContainer}>
        <div className={styles["popupHeader"]}>
          分佣规则
          <i className={styles["popupClose"]} onClick={() => control.setIsCommissionDetailVisible(false)}></i>
        </div>
        <div className={styles["popupContent"]}>
          <div className={styles["text"]}>{control.theCommissionRule}</div>
        </div>
      </div>

    </Popup>
  </div>;
};

export default JoinOrderNX;