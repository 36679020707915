import React from "react";

import { CenterPopup } from "antd-mobile";
import QRCode from "qrcode.react";

import CLOSE from "@/assets/icon_close_gray.png";
import styles from "./index.module.less";

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  shareInfo: any;
}
const ShopOwnerQrcode: React.FC<IProps> = ({ visible, setVisible, shareInfo }) => {
  const { shopName, shopDesc, shopLogoUrl, shopQrCodeUrl } = shareInfo ?? {};
  const defaultAvatarUrl =
    "https://akim-oss.aikucun.com/5adcf16a0a72ef9926af328220068b695fd489a5_1703043623267_78.png";
  return (
    <>
      <CenterPopup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{
          borderRadius: "12px",
          width: "fit-content",
          height: "fit-content",
        }}>
        <div className={styles["container"]}>
          <div className={styles["header"]}>
            <img className={styles["header__logo"]} src={shopLogoUrl || defaultAvatarUrl}></img>
            <div className={styles["header-info"]}>
              <div className={styles["info__title"]}>{shopName}</div>
              <div className={styles["info__desc"]}>{shopDesc}</div>
            </div>
          </div>
          <div className={styles["main"]}>
            <div className={styles["main-body"]}>
              <QRCode className={styles["body__qrcode"]} value={shopQrCodeUrl} renderAs="canvas" />
              <div className={styles["body__desc"]}>添加店主微信号即刻咨询</div>
            </div>
          </div>

          <img onClick={() => setVisible(false)} className={styles["close__icon"]} src={CLOSE} />
        </div>
      </CenterPopup>
    </>
  );
};
export default ShopOwnerQrcode;
