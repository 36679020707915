import React, { useEffect, useState } from "react";
import AddressList from "./AddressList";
import styles from "./QueryWinning.module.less";

interface Props {
  info: any;
}
const QueryWinning: React.FC<Props> = ({ info }) => {
  // 目标枚举
  const thresholdIndicatorTypeEnum = {
    GMV: { desc: "订单金额", unit: "元" },
    SALE_QUANTITY: { desc: "订单销量", unit: "件" },
    COMMISSION: { desc: "订单基础推广费", unit: "元" },
  };

  const emptyStatusDom = () => {
    if (info?.aggStatus === "BEGIN") {
      return (
        <div className={styles.status__empty}>
          <img
            className={styles.empty__img}
            src="https://akim-oss.aikucun.com/mshop/16947a10bf6a499e9231facca59a40646c90dc15_1714979812583_52.png"
          />
          <h3 className={styles.empty__title}>活动尚未结束</h3>
          <div className={styles.empty__desc}>结束后会有通知送达，请注意查收</div>
        </div>
      );
    } else if (
      ["AWAITING_DRAW", "END"].includes(info?.aggStatus) &&
      info?.actSellBatePartakeInfo?.estimatedRewards === 0
    ) {
      return (
        <div className={styles.status__empty}>
          <img
            className={styles.empty__img}
            src="https://akim-oss.aikucun.com/mshop/16947a10bf6a499e9231facca59a40646c90dc15_1714979812583_52.png"
          />
          {/* <h3 className={styles.empty__title}>活动已结束</h3> */}
          <p className={styles.empty__desc}>
            您此次
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}为
            {info?.actSellBatePartakeInfo?.currentGmv}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            ，未能满足领取赠品条件， 下次加油哦~
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles.status}>
        {emptyStatusDom()}

        {["AWAITING_DRAW", "END"].includes(info?.aggStatus) &&
          info?.actSellBatePartakeInfo?.estimatedRewards > 0 && (
            <div className={styles.status__result}>
              {/* <div className={styles.result__title}>活动已结束</div> */}
              <div className={styles.result__total}>
                您此次订单总金额为 <span>{info?.actSellBatePartakeInfo?.saleAmount || 0}</span> 元
              </div>
              <div className={styles.result__prize}>
                <h3 className={styles.prize__title}>
                  {info?.aggStatus === "END" ? "恭喜您获得奖品" : "预计获得奖品"}
                </h3>
                <img
                  className={styles.prize__img}
                  src={info?.actSellBatePartakeInfo?.giftImageUrl}
                />
                <div className={styles.prize__desc}>
                  <p>{info?.actSellBatePartakeInfo?.giftName || ""}</p>
                  <p>数量：{info?.actSellBatePartakeInfo?.estimatedRewards || 0}件</p>
                </div>
              </div>
              {info?.aggStatus === "END" ? (
                <AddressList
                  redemptionDeadlineTime={info?.actSellBatePartakeInfo?.redemptionDeadlineTime}
                  expectedAwardAmount={info?.actSellBatePartakeInfo?.estimatedRewards}
                  giftImageUrl={info?.actSellBatePartakeInfo?.giftImageUrl}
                  giftName={info?.actSellBatePartakeInfo?.giftName}
                />
              ) : (
                <p className={styles.tips}>*实际订单总额及获得奖品以售后期结束数据为准</p>
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default QueryWinning;
