import { queryActListServ } from "@/api/appH5";
import { getUrlQueryObject } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import { LabelInfoModel } from "@akc/biz-api";
import { ActivityIncentiveCard } from "@akc/mx-taro-h5-components";
import { Dialog, Popup, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./ActivityIncentivePopup.module.less";

interface IProps {
  popVisible?: boolean;
  activityId: string;
  activitySpuId?: string;
  pageId: string;
  labelInfoVOList: LabelInfoModel[];
  onClose: () => void;
}

const ActivityIncentivePopup: React.FC<IProps> = ({ activityId, labelInfoVOList, onClose, pageId, activitySpuId, popVisible }) => {
  const { navigate } = useNavigation();

  const [visible, setVisible] = useState(false);

  const [labelList, setLabelList] = useState([]);

  const onClosePopup = () => {
    setVisible(false);
    onClose();
  };

  const onLabelInfoDetail = (obj) => {
    const { skipUrl, drillDown } = obj?.labelVO || {};
    if (skipUrl && drillDown) {
      onClosePopup();
      let SKIPURL = new URL(skipUrl);
      let urlQuery = getUrlQueryObject(skipUrl);
      navigate({
        h5Options: {
          url: `${SKIPURL.pathname}`,
          query: urlQuery
        },
      });
    }
  };

  const queryActList = () => {
    if (activityId && labelInfoVOList?.length > 0) {
      let actIdList: string[] = [];
      (labelInfoVOList || []).forEach(item => {
        actIdList = Array.from(new Set([...actIdList, ...(item?.refIdList || [])]));
      });
      queryActListServ({
        pageId: pageId ?? "H5_ScheduleDetail",
        activityId,
        activitySpuId,
        actIdList,
        labelInfoVOList
      })
        .then(data => {
          setLabelList(data || []);
          setVisible(true);
        });
    }
  };

  useEffect(() => {
    if (popVisible) {
      queryActList();
      return;
    }
    setVisible(false);
  }, [popVisible]);

  return (
    <>
      <Popup
        visible={visible}
        onMaskClick={() => {
          onClosePopup();
        }}
        bodyStyle={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          minHeight: "40vh",
          zIndex: 10000,
        }}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <div className={styles.activity_incentive_popup}>
          <div className={styles.header}>
            <div className={styles.header__title}>激励</div>
            <img
              className={styles.header__icon}
              src="https://xiangdian-oss.aikucun.com/app_x_ios/da38860cb875cb64092e402d80d9fe29c4865b18_1729649817964_34.png"
              onClick={() => onClosePopup()}
            />
          </div>
          <div className={styles.scroll_view}>
            {labelList.map((item, index) => {
              return (<div className={styles.item} key={index}>
                <ActivityIncentiveCard
                  item={item}
                  onLabelInfoDetail={onLabelInfoDetail}
                />
              </div>);
            })}
          </div>
        </div>
      </Popup>
    </>);

};

export default ActivityIncentivePopup;