import { queryEmpowermentInfo } from "@/api/yearEndBonus";
import Track from "@/utils/track";
import AKJS from "@akc/akjs";
import { Dialog, Image } from "antd-mobile";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ActivityBanner from "./components/activity-banner/activity-banner";
import ActivityStatus from "./components/activity-status/activity-status";
import styles from "./empowerment.module.less";

const Empowerment: React.FC = () => {
  document.title = "赋能营激励";
  const pageName = "赋能营激励";
  const track = Track();

  const navigater = useNavigate();
  const [search] = useSearchParams();

  const [pageInfo, setPageInfo] = useState<any>({});

  const toRulePage = () => {
    AKJS.action.dispatch("event.page.open", {
      url: "https://h5.aikucun.com/p/_STky0E5i.html",
    });
  };

  const showDialog = () => {
    Dialog.alert({
      title: "预计可获得",
      content: "预计可得奖励包含已发放奖励和待发放奖励，该奖励月度发放80%，剩余20%年底统一发放",
      confirmText: "我知道了",
      className: "dialog__private",
      // onConfirm: () => { },
    });
  };

  const getPageInfo = async () => {
    const data: any = await queryEmpowermentInfo();
    setPageInfo(
      data
        ? {
          ...data,
          totalRewardContent: data.totalRewardContent.replace(/#/g, "\n"),
        }
        : {},
    );
  };

  useEffect(() => {
    track.pageView(pageName, {
      domain: "年终奖",
    });
    getPageInfo();
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.scroll_view}>
        {/* 规则 */}
        <img
          className={styles.rule__absolute}
          src="https://akim-oss.aikucun.com/mshop/8e8a120b7b795a6c5b7428427f5a3e67245d44d4_1731383104974_3.png"
          onClick={toRulePage}
        />
        {/* header */}
        <div className={styles.page__header}>
          {/* 活动状态 */}
          <ActivityStatus
            type="gold-medal"
            sysTime={pageInfo.sysTime}
            taskEndTime={pageInfo.taskEndTime}
            taskStatus={pageInfo.taskStatus}
            backgroundColor="#FF4821"
            borderColor="#FCB37E"
          />
        </div>
        {/* body */}
        <div className={styles.page__body}>
          {/* 数据展示 */}
          <div className={styles.body__data}>
            <div className={styles.data__count}>
              <div className={styles.count__value}>{pageInfo.totalReward || 0}<span className={styles.unit}>元</span></div>
              <div className={styles.count__title} style={{ whiteSpace: "pre-wrap" }}>{(pageInfo?.totalRewardContent || "").replace(/\\n/g, <br />)}</div>
            </div>
            <div className={styles.data__list}>
              <div className={styles.list__item}>
                <p className={styles.item__value}>
                  {pageInfo.actualAward || 0}
                  <span className={styles.unit}>元</span>
                </p>
                <p className={styles.item__title}>已发放奖励</p>
              </div>
              <div className={styles.list__item}>
                <p className={styles.item__value}>
                  {pageInfo.awaitReward || 0}
                  <span className={styles.unit}>元</span>
                </p>
                <p className={styles.item__title}>待发放奖励</p>
              </div>
            </div>
          </div>
          {/* 寄语 */}
          <img className={styles.body__jiyu} src={pageInfo.empowermentContentImgUrl || ""} />
          {/* 专属激励 */}
          <img
            className={styles.body__jili}
            src="https://akim-oss.aikucun.com/mshop/4ad1e759708d00f42b66ebd878821259a70c8387_1733120869389_83.png"
          />
        </div>
        {/* 业绩提升小妙招 */}
        <ActivityBanner pageName={pageName} />
      </div>
    </div>
  );
};

export default Empowerment;
