import { platform } from "@/utils/platform";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface SharerProductProps {
  title: string;
  skuMainImageUrl: string;
  tagPrice: number;
  tagPriceDesc: string;
  marketingPrice: number;
  commission: number;
  commissionAddition: number;
}
const SharerProductCard: React.FC<SharerProductProps> = props => {
  const { title, skuMainImageUrl, tagPrice, marketingPrice, commission, commissionAddition } =
    props;
  const getDecimal = () => {
    const decimal = marketingPrice.toString().split(".")[1];
    if (decimal) {
      return <div className={styles.decimal}>.{decimal}</div>;
    }
    return "";
  };

  return (
    <div className={styles.sharerProductCard}>
      <div className={styles.img}>
        <Image src={skuMainImageUrl} className={styles.img_item}></Image>
        {commission > 0 && (
          <div className={styles.save_money_content}>
            <div className={styles.save_money}>
              {platform.isApp
                ? "省赚"
                : platform.isTuan
                  ? "推广费"
                  : platform.isMini && !platform.isSaas
                    ? "饷"
                    : "赚"}
              ¥{commission}
              {commissionAddition > 0 && <div>+{commissionAddition}</div>}
            </div>
          </div>
        )}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.price_real}>
        <div className={styles.symbol}>¥</div>
        <div className={styles.price_big}>{Math.trunc(marketingPrice)}</div>
        {getDecimal()}
        <div className={styles.price_old}>
          ¥<span>{tagPrice}</span>
        </div>
      </div>
    </div>
  );
};
export default SharerProductCard;
