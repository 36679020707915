import { queryBannerMsg } from "@/api/rewardCenter";
import { getCookie } from "@/utils/platform";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cls from "./index.module.less";

const ToExChangeTip: React.FC<{
  bizId?: string;
  callback?: () => void;
  onLoadComplete?: (visible: boolean) => void;
  showClose?: boolean;
}> = ({ bizId, callback, onLoadComplete, showClose = false }) => {
  const navigater = useNavigate();
  const [search] = useSearchParams();

  const [text, setText] = useState("");
  const [btnText, setBtnText] = useState("去兑奖");
  const [data, setData] = useState<any>();

  const queryBanner = async () => {
    const data = await queryBannerMsg({ bizId: bizId as string });
    if (data?.bizId && data?.content) {
      onLoadComplete?.(true);
      setData(data);
      setText(data.content);
    } else {
      onLoadComplete?.(true);
      setData(null);
      setText("");
    }
  };
  const handleClick = () => {
    if (bizId) {
      callback?.();
    } else {
      let envid: any = window.location.href.match(/[?&]env_identity=([^?#&]+)/);
      envid = envid && envid[1];
      let href = `/mx-shop-micro/reward-center/detail?token=${search.get("token")}&shopId=${search.get(
        "shopId",
      )}&channel=h5Mall&actId=${data?.bizId}&tabIndex=1&env_identity=${envid || getCookie("X-Hades-Env-Identity")
        }`;
      if (search.get("distributorId")) {
        href += `&distributorId=${search.get("distributorId")}`;
      }
      navigater(href);
    }
  };

  const onClose = () => {
    setData(null);
    setText("");
  };

  useEffect(() => {
    queryBanner();
  }, []);
  // 查询关注公众号接口无异常，且为未关注状态 展示提示
  return (
    data && text && data.content && <div className={cls.exchangeBox}>
      <img className={cls.icon__notice} src="https://akim-oss.aikucun.com/mshop/e57a4cc247e0d9ea18e081230b0d852d5330c5ef_1736308167528_92.png" />
      <div className={cls.content} onClick={handleClick}>
        {text}
      </div>
      {
        showClose && (
          <img className={cls.icon__close} onClick={onClose} src="https://akim-oss.aikucun.com/mshop/4a1a0e5ed29c0ea4cf8742c01f98270f5bebf689_1736308236350_41.png" />
        )
      }
    </div>
  );
};
export default ToExChangeTip;
