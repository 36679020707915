import CHECKED from "@/assets/icon_checked.png";
import CLOSE from "@/assets/icon_close_gray.png";
import UNCHECK from "@/assets/icon_uncheck.png";
import { Popup } from "antd-mobile";
import React, { useEffect } from "react";
import { useActivate, useUnactivate } from "react-activation";
import styles from "./index.module.less";

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  curHideType: "brand" | "brand_product";
  onConfirm: (type: string) => void;
}

const HidePopup: React.FC<IProps> = ({ visible, setVisible, curHideType = "brand", onConfirm }) => {
  const [hideType, setHideType] = React.useState<"brand" | "brand_product">("brand");

  const onClose = () => {
    setVisible(false);
  };
  const handleConfirm = type => {
    setVisible(false);
    onConfirm(type);
  };

  useActivate(() => {
    setVisible(false);
  });

  useUnactivate(() => {
    setVisible(false);
  });

  useEffect(() => {
    setHideType(curHideType);
  }, [curHideType]);

  return (
    <>
      <Popup
        visible={visible}
        onMaskClick={onClose}
        bodyStyle={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          zIndex: 11000,
        }}>
        <div className={styles["hide-popup"]}>
          <div className={styles["hide-popup-header"]}>
            <div className={styles["header__title"]}>选择隐藏方式</div>
            <img className={styles["header__close"]} src={CLOSE} onClick={onClose} />
          </div>
          <div className={styles["hide-popup-main"]}>
            <div className={styles["main-item"]} onClick={() => setHideType("brand")}>
              <div className={styles["item-left"]}>
                <div className={styles["left-title"]}>
                  <div className={styles["title__name"]}>全部隐藏</div>
                </div>
                <div className={styles["left__desc"]}>隐藏后，用户进店将看不到此品牌活动</div>
              </div>
              <div className={styles["item-right"]}>
                <img
                  className={styles["right__icon"]}
                  src={hideType === "brand" ? CHECKED : UNCHECK}
                  alt=""
                />
              </div>
            </div>
            <div className={styles["main-item"]} onClick={() => setHideType("brand_product")}>
              <div className={styles["item-left"]}>
                <div className={styles["left-title"]}>
                  <div className={styles["title__name"]}>仅隐藏常销</div>
                </div>
                <div className={styles["left__desc"]}>
                  隐藏后，用户进店可以看到该品牌活动，但是看不到品牌活动下的常销商品
                </div>
              </div>
              <div className={styles["item-right"]}>
                <img
                  className={styles["right__icon"]}
                  src={hideType === "brand_product" ? CHECKED : UNCHECK}
                  alt=""
                />
              </div>
            </div>
            <div className={styles["confirm-btn"]} onClick={() => handleConfirm(hideType)}>
              确认隐藏
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
export default HidePopup;
