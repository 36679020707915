import React, { useContext, useEffect } from "react";
import styles from "./LabelList.module.less";

interface LabelListProps {
  labelLocationVOList: Array<any>;
  locationCode: string;
}

const LabelList: React.FC<LabelListProps> = props => {
  const { labelLocationVOList, locationCode } = props;

  const [list, setList] = React.useState<any>([]);
  useEffect(() => {
    const list =
      (labelLocationVOList || []).find((item: any) => {
        return item.locationCode === locationCode && item?.labelInfoVOList?.length;
      })?.labelInfoVOList || [];
    setList(list);
  }, [labelLocationVOList, locationCode]);

  return (
    <>
      {labelLocationVOList?.length > 0 && (
        <div className={styles.labelList}>
          {list.map((item: any, index: number) => {
            return <img className={styles.label} key={index} src={item.labelIconUrl} />;
          })}
        </div>
      )}
    </>
  );
};

export default LabelList;
