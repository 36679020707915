import { getShop } from "@/api/decorate";
import arrowRight from "@/assets/arrow_right.png";
import goBack from "@/assets/go_back.png";
import bgImg from "@/assets/shop_info_bg.png";
import SideBar from "@/components/sidebar";
import StarCom from "@/components/star";
import { useCommonContext } from "@/utils/context/common";
import { getIdentity, getUserRole } from "@/utils/identity";
import { MICRO_APP_BASEURL, platform } from "@/utils/platform";
import { getBaseurl } from "@/utils/url-parse";
import AKJS from "@akc/akjs";
import { Image } from "antd-mobile";
import axios from "axios";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ShopInfo.module.less";

interface Props {
  appStore?: any;
  [key: string]: any;
}

const ShopInfo: React.FC<Props> = ({ appStore }) => {
  const navigater = useNavigate();
  const [search] = useSearchParams();
  const shopId = search.get("shopId"); // R店铺ID
  const shopCode = search.get("shopCode"); // 商家店铺ID
  const { track } = useCommonContext();
  const [urls, setUrls] = useState<any>([]);

  const [shopInfo, setShopInfo] = useState<any>();

  function getQueryParams(url: string) {
    const paramArr = url.slice(url.indexOf("?") + 1).split("&");
    const params = {};
    paramArr.map(param => {
      const [key, val] = param.split("=");
      params[key] = decodeURIComponent(val);
    });
    return params;
  }

  const getCookie = cname => {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  };

  const queryShop = async () => {
    if (platform.isApp) {
      const res: any = await AKJS.ready().then(() => {
        return AKJS.info.account();
      });
      appStore.token = res.data.token;
      appStore.channel = "akcApp";
    } else if (platform.isMini) {
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (search.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      appStore.token = search.get("token");
      console.log("页面信息33333------------", location.href);
    } else {
      if (
        search.get("shopId") &&
        search.get("shopId") !== "undefined" &&
        search.get("shopId") !== "null"
      ) {
        await axios({
          method: "get",
          url: search.get("distributorId")
            ? `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${search.get(
                "shopId",
              )}?distributorId=${search.get("distributorId")}`
            : `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${search.get("shopId")}`,
          headers: {
            "app-login-channel": "xdWxH5",
            "auth-token": getCookie("mstoken"),
            "AKC-H5": "H5",
          },
          params: {},
        }).then(res => {
          if (res?.data?.code === 999999 || res?.data?.code === 999998) {
            const ua = window.navigator.userAgent.toLowerCase();
            const result = ua.match(/MicroMessenger/i);
            if (result) {
              const current = `${window.location.pathname}${window.location.search}${window.location.hash}`;
              let url = "";
              const staticQuery: any = getQueryParams(location.href);
              if (getQueryParams(location.href)["distributorId"]) {
                url = `${getBaseurl()}/api/member/auth?backUrl=${window.encodeURIComponent(
                  `/#/login?shopId=${staticQuery.shopId}&distributorId=${
                    staticQuery.distributorId
                  }&rdt=1&bU=${window.encodeURIComponent(current)}`,
                )}`;
              } else {
                url = `${getBaseurl()}/api/member/auth?backUrl=${window.encodeURIComponent(
                  `/#/login?shopId=${staticQuery.shopId}&rdt=1&bU=${window.encodeURIComponent(
                    current,
                  )}`,
                )}`;
              }
              if (staticQuery.shopId && staticQuery.shopId !== "undefined") {
                window["willRedirect"] = true;
                window.location.href = url;
              }
            }
          }
          localStorage.setItem("userinfo", JSON.stringify(res?.data?.data));
        });
      }
    }
    getShop({
      merchantShopCode: shopCode,
      needLicenseImg: true,
      needScore: true,
    }).then(res => {
      setShopInfo(res?.data);
      if (!res?.data?.licenseImgList) {
        return;
      }
      if ((res?.data?.licenseImgList?.length ?? 0) === 0) {
        return;
      }
      const imageUrlsStr = res?.data?.licenseImgList?.join(",");
      const imageUrls =
        imageUrlsStr && imageUrlsStr.length > 0 ? imageUrlsStr.split(",") : undefined;
      setUrls(imageUrls);
    });
  };

  useEffect(() => {
    //页面埋点
    /* eslint-disable */
    if (track.track) {
      track.track("$pageview", {
        page_name: "店铺详情",
        previous_page_name: search.get("previous_page_name"),
        desc: shopCode,
      });
    }
    /* eslint-enable */
    document.title = "店铺详情";
    if (!shopCode) {
      return;
    }
    // 请求店铺信息接口
    queryShop();
  }, []);

  const goBackOrHome = () => {
    if (history.length > 1) {
      history.back();
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  const showLicenese = () => {
    if (!shopInfo?.licenseImgList) {
      return;
    }
    if (shopInfo.licenseImgList.length === 0) {
      return;
    }
    const imageUrls = encodeURIComponent(shopInfo.licenseImgList.join(","));
    const title = encodeURIComponent("证件信息");
    navigater(
      `/mx-shop-micro/show-images?imageUrls=${imageUrls}&title=${title}&shopCode=${shopCode}`,
    );
  };

  const openingDate = shopInfo?.openingTime ? new Date(Number(shopInfo.openingTime)) : undefined;

  let role: any = false;
  if (platform.isMini) {
    role = getIdentity();
  } else {
    role = getUserRole() !== "R";
  }

  return (
    <>
      {role ? (
        <div className={styles.show_images}>
          {platform.isH5 && (
            <Image className={styles.go_back} src={goBack} onClick={() => goBackOrHome()} />
          )}
          {platform.isH5 && <SideBar />}
          {urls &&
            urls.length > 0 &&
            urls.map((img, index) => (
              <div key={index} className={styles.img_container}>
                <Image src={img} />
              </div>
            ))}
        </div>
      ) : (
        <div className={styles.shop_info}>
          {platform.isH5 && (
            <Image className={styles.go_back} src={goBack} onClick={() => goBackOrHome()} />
          )}
          {platform.isH5 && <SideBar />}
          <div className={styles.top_info} style={{ backgroundImage: `url(${bgImg})` }}>
            <div className={styles.top_center}>
              {shopInfo?.shopLogo && <Image src={shopInfo.shopLogo} className={styles.logo} />}
              {shopInfo?.shopName && <div className={styles.name}>{shopInfo.shopName}</div>}
              {shopInfo?.shopAuthorizeImgUrl && (
                <Image src={shopInfo.shopAuthorizeImgUrl} className={styles.auth} />
              )}
              {shopInfo?.score && (
                <div className={styles.score}>
                  <StarCom score={shopInfo?.score} />
                  <div className={styles.value}>{shopInfo?.score}</div>
                </div>
              )}
            </div>
            {shopInfo?.scoreItemVOList && shopInfo?.scoreItemVOList.length > 0 && (
              <div className={styles.score_list}>
                {shopInfo?.scoreItemVOList.map((item, index) => (
                  <div key={index} className={styles.item}>
                    <div className={styles.name}>{item.itemName}</div>
                    <div className={styles.progress}>
                      <div
                        className={styles.content}
                        style={{
                          width: `${(item.score / 5) * 100}%`,
                        }}
                      />
                    </div>
                    <div className={styles.number}>{item.score}</div>
                    <div className={styles.desc}>{item.scoreDesc}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {shopInfo && (
            <div className={styles.content_list}>
              {shopInfo?.licenseImgList && (
                <div className={styles.item} onClick={showLicenese}>
                  <div className={styles.name}>证照信息</div>
                  <Image src={arrowRight} className={styles.arrow_right} />
                </div>
              )}
              {openingDate && (
                <div className={styles.item}>
                  <div className={styles.name}>开店时间</div>
                  <div className={styles.date}>{moment(openingDate).format("yyyy-MM-DD")}</div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default inject("appStore")(observer(ShopInfo));
