import { queryHideInfo, setHideInfo } from "@/api/member";
import { default as nullPng } from "@/assets/null.png";
import { default as HideIcon1 } from "@/assets/shopHide1.png";
import { default as HideIcon2 } from "@/assets/shopHide2.png";
import StarCom from "@/components/star";
import { useCommonContext } from "@/utils/context/common";
import { getUserRole, identity } from "@/utils/identity";
import { pxtorem } from "@/utils/pxtorem";
import { getUrlParam } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import { MaterialV2ModuleComp } from "@akc/mx-taro-h5-components";
import { Dialog, Image, Toast } from "antd-mobile";
import { default as React, useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface IProps {
  shopInfo: any;
  subscribeShop: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isSubscript: boolean) => void;
  materialData: any;
  pageCode: any;
  activeIndex: number;
}
const ShopInfo: React.FC<IProps> = ({
  shopInfo,
  subscribeShop,
  materialData,
  pageCode,
  activeIndex,
}) => {
  const [hideVisible, setHideVisible] = useState<boolean>(false);
  const [hideStatus, setHideStatus] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [previous_page_name] = useState<string | null>(searchParams.get("previous_page_name"));
  const merchantShopCode = getUrlParam()?.["merchantShopCode"];
  const { track } = useCommonContext();
  const { navigate2H5, navigate2MaterialList, navigate2MaterialContent } = useNavigation();
  const goToShop = () => {
    navigate2H5({
      path: "/mx-shop-micro/shop/info",
      query: `shopCode=${searchParams.get("merchantShopCode")}`,
    });
  };

  const goMaterialList = extra => {
    const resourceCode = extra?.resourceCode;
    if (track.track) {
      track.track("btn_click", {
        page_name: "店铺首页",
        previous_page_name,
        domain: "B2R旗舰店",
        resource_type: "MATERIAL",
        module: "店铺素材",
        btn_name: "查看全部",
        merchant_shop_code: merchantShopCode,
        page_code: searchParams.get("merchantShopCode"),
      });
    }
    navigate2MaterialList(merchantShopCode, resourceCode);
  };

  const goMaterialContent = () => {
    return function (material) {
      if (track.track) {
        track.track("resource_click", {
          page_name: "店铺首页",
          previous_page_name,
          domain: "B2R旗舰店",
          resource_type: "店铺素材",
          resource_content: "素材",
          btn_name: "查看详情",
          material_id: material?.materialNo,
          merchant_shop_code: merchantShopCode,
          page_code: pageCode,
        });
      }
      navigate2MaterialContent(material?.type, material?.materialNo);
    };
  };

  const getHideInfo = async () => {
    if (getUserRole() === "A") {
      const data = await queryHideInfo({
        bizNo: merchantShopCode,
        hideType: "shop",
      });
      if (data) {
        setHideVisible(!!data.isHide);
        setHideStatus(!!data.status);
      }
    } else {
      setHideVisible(false);
    }
  };

  const onHideShop = () => {
    Dialog.confirm({
      title: hideStatus ? "取消隐藏" : "隐藏店铺",
      content: hideStatus
        ? "请确认是否要取消隐藏该店铺？"
        : "隐藏后，用户将看不到对应店铺以及店铺下的常销商品，确认是否隐藏？",
      className: "dialog_hide",
      cancelText: "取消",
      confirmText: hideStatus ? "确认" : "立即隐藏",
      onConfirm: () => {
        setHideInfo({
          bizNo: merchantShopCode,
          hideType: "shop",
          hideAction: hideStatus ? "cancel_hide" : "hide",
        }).then(data => {
          if (data) {
            Toast.show(hideStatus ? "已成功取消隐藏" : "隐藏成功");
            setHideStatus(!hideStatus);
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        });
      },
    });
  };

  const ShopSimple = () => {
    return (
      <div className={styles.brand} onClick={() => goToShop()}>
        <div
          className={styles.brand_top}
          style={{ marginBottom: identity() ? pxtorem(4) : pxtorem(0) }}>
          <Image
            src={shopInfo.shopLogo ? shopInfo.shopLogo : nullPng}
            className={styles.img}></Image>
          <div className={styles.brand_content}>
            <div className={styles.shop_name}>
              <div className={identity() ? "" : styles.title}>
                {getUserRole() !== "R" ? shopInfo.shopTitle : shopInfo.shopName}
                <img
                  style={{ width: pxtorem(10), height: pxtorem(10), marginLeft: pxtorem(2) }}
                  src="https://akim-oss.aikucun.com/b14f97413e78f590853ff4772d76c33925e6bfe3_1709538451925_46.png"
                />
              </div>
            </div>
            {identity() ? (
              <div
                className={styles.img_bottom}
                style={{
                  color: "#666666",
                  width: pxtorem(240),
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "block",
                  paddingTop: pxtorem(2),
                }}>
                {shopInfo.shopSubTitle}
              </div>
            ) : (
              <div className={styles.img_bottom}>
                <div className={styles.evaluate}>
                  <img
                    className={styles.shopAuthorize}
                    src="https://akim-oss.aikucun.com/694b618f02a5f140f33bffa85fd73e408797d4cd_1704167023527_77.png"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getHideInfo();
  }, []);

  useActivate(() => {
    getHideInfo();
    Dialog.clear();
  });

  return (
    <>
      {shopInfo?.shopStatusCode === "CLOSED" || shopInfo?.shopStatusCode === "PAUSED" ? (
        <ShopSimple />
      ) : (
        <div className={styles.brand} onClick={() => goToShop()}>
          <div
            className={styles.brand_top}
            style={{ marginBottom: identity() ? pxtorem(4) : pxtorem(0) }}>
            <Image
              src={shopInfo.shopLogo ? shopInfo.shopLogo : nullPng}
              className={styles.img}></Image>
            <div className={styles.brand_content}>
              <div className={styles.shop_name}>
                <div className={identity() ? "" : styles.title}>
                  {getUserRole() !== "R" ? shopInfo.shopTitle : shopInfo.shopName}
                  <img
                    style={{ width: pxtorem(10), height: pxtorem(10), marginLeft: pxtorem(2) }}
                    src="https://akim-oss.aikucun.com/b14f97413e78f590853ff4772d76c33925e6bfe3_1709538451925_46.png"
                  />
                </div>
              </div>
              {identity() ? (
                <div
                  className={styles.img_bottom}
                  style={{
                    color: "#666666",
                    width: pxtorem(240),
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: "block",
                    paddingTop: pxtorem(2),
                  }}>
                  {shopInfo.shopSubTitle}
                </div>
              ) : (
                <div className={styles.img_bottom}>
                  {shopInfo?.score && (
                    <div className={styles.evaluate}>
                      <img
                        className={styles.shopAuthorize}
                        src="https://akim-oss.aikucun.com/694b618f02a5f140f33bffa85fd73e408797d4cd_1704167023527_77.png"></img>
                      <div className={styles.shopScore}>
                        <StarCom score={shopInfo?.score} />
                        <div className={styles.score}>
                          {shopInfo?.score && Number(shopInfo?.score).toFixed(1)}分
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {!identity() && (
              <div
                className={shopInfo.isSubscript ? styles.subscript : styles.unSubscript}
                onClick={e => subscribeShop(e, shopInfo.isSubscript)}>
                <span className={styles.subscriptFont}>
                  {shopInfo.isSubscript ? "已关注" : "关注"}
                </span>
              </div>
            )}
            {hideVisible && (
              <div
                className={`${styles.hideButton} ${!identity() ? styles.marginLeft6 : ""}`}
                onClick={evt => {
                  evt.stopPropagation();
                  onHideShop();
                }}>
                <img className={styles.hideButtonIcon} src={hideStatus ? HideIcon1 : HideIcon2} />
              </div>
            )}
          </div>
          {materialData?.result?.length > 0 && activeIndex === 0 && (
            <div className={styles.brand_subTitle}>
              <MaterialV2ModuleComp
                models={materialData.result}
                count={materialData.totalCount}
                titleFrontLocationCode="H5_MaterialList_MaterialTitlefront"
                onMoreAction={() => goMaterialList(materialData?.extra)}
                onMaterialAction={goMaterialContent()}></MaterialV2ModuleComp>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default ShopInfo;
