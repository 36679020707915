import _ from "lodash";
import React from "react";
import { StarProps } from "../../expand_tab/types/basics/star";
import "./index.less";

/**
 * 评分组件
 */
const StarComp: React.FC<StarProps> = props => {
  const maxCount = props.maxCount || 5;

  const fullCount = (() => {
    if (props.score >= maxCount) {
      return maxCount;
    }
    return _.floor(props.score);
  })();
  const halfCount = (() => {
    if (fullCount === props.score) {
      // 没有小数
      return 0;
    }
    return props.score >= maxCount ? 0 : 1;
  })();
  const emptyCount = (() => {
    if (props.score >= maxCount) {
      // 满星
      return 0;
    }
    return maxCount - halfCount - fullCount;
  })();

  const IconFull =
    "https://xiangdian-oss.aikucun.com/app_akc_ios/f8a4798c48550c903561805152f32ecb16e8d120_1693209033131_52.png";
  const IconHalf =
    "https://xiangdian-oss.aikucun.com/app_akc_ios/c158695a64adb40952c1cf9fde78b62bd6147f42_1693209053725_60.png";
  const IconEmpty =
    "https://xiangdian-oss.aikucun.com/app_akc_ios/1e2fe980e7640518f30e5d199c79fc8d3fcb9fe7_1693209070636_30.png";

  const srcs: string[] = [];

  _.range(fullCount).map(() => {
    srcs.push(IconFull);
  });
  halfCount > 0 &&
    _.range(halfCount).map(() => {
      srcs.push(IconHalf);
    });
  emptyCount > 0 &&
    _.range(emptyCount).map(() => {
      srcs.push(IconEmpty);
    });

  return (
    <div className={`star_container ${props.className}`} style={props.style}>
      {srcs.map((src, idx) => {
        const className = `star${idx > 0 ? " margin_left" : ""}`;
        return <img className={className} key={idx} src={src} />;
      })}
    </div>
  );
};

export default StarComp;
