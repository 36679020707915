import { querySignInCalendar } from "@/api/dailyReward";
import { useEffect, useState } from "react";

const queryCalendarData = async (params: {
  promoActivityId: string;
  startDay: string;
  endDay: string;
}) => {
  const res = (await querySignInCalendar(params)) || [];
  const obj = {};
  if (res) {
    res.forEach(el => {
      obj[el.day] = el;
    });
  }
  return obj;
};

const getDateStr = date => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

const getWeeksOfMonth = async (curDate, promoActivityId) => {
  // 月份从0开始，所以要减1
  const firstDay = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
  const lastDay = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);

  const dataObj = await queryCalendarData({
    promoActivityId: promoActivityId,
    startDay: getDateStr(firstDay),
    endDay: getDateStr(lastDay),
  });

  const weeks: any[] = [];
  let week: any[] = [];

  // 遍历该月份的每一天
  for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
    const dayOfWeek = day.getDay(); // 获取星期几，0是周日，1是周一，以此类推

    // 如果是新的一周，且不是周日
    if (dayOfWeek === 0 && week.length > 0) {
      weeks.push(week);
      week = [];
    }

    week.push({
      date: day.getDate(),
      data: dataObj[day.getTime()],
      day: day.getDay(),
      // isToday: day.toDateString() === new Date().toDateString(),
      // isSign: false,
    });

    // 如果是该月的最后一天，且当前周有内容
    if (day.getDate() === lastDay.getDate()) {
      weeks.push(week);
    }
  }

  return weeks;
};

const useCalendarDate = (curDate: Date, promoActivityId) => {
  const [weeks, setWeeks] = useState<any[]>([]);
  useEffect(() => {
    getWeeksOfMonth(curDate, promoActivityId).then(weeks => setWeeks(weeks));
  }, [curDate]);
  return {
    weeks,
  };
};

export default useCalendarDate;
