import TopNavigation from "@/components/top-navigation";
import VerticalScrollview from "@/components/vertical-scrollview";
import { platform } from "@/utils/platform";
import React from "react";
import InstructAlert from "./components/alert";
import CategoryTab from "./components/category";
import StudyTourListComp from "./components/list";
import SelectedActivityListComp from "./dialog/select";
import useStudyTour from "./hook/useStudyTour";
import styles from "./index.module.less";

const YouxueCalendarPage: React.FC = () => {
  const {
    tabList,
    selectTab,
    visible,
    activityImages,
    instructVisible,
    registrationVisible,
    selectListMap,
    checkCount,
    configInfo,
    totalCount,
    pageRef,
    tabSticky,
    categoryList,
    cateorySelect,
    selectStudyIds,
    onChangeTabIndex,
    onSelectedDialogVisible,
    onRegistrationAction,
    onInstructAction,
    onUpdateSelectStatusAction,
    onStudyTourListAction,
    onDeleteAction,
    onCategoryShowOrHideAction,
    onCategorySelectAction,
  } = useStudyTour();

  return (
    <>
      {(platform.isH5 || platform.isWxH5) && (
        <TopNavigation hideSearchIcon={true} hideCartIcon={true} hideFavoriteIcon={true} />
      )}
      <div
        id="page"
        ref={pageRef}
        className={
          platform.isH5 || platform.isWxH5 ? `${styles.page} ${styles.ca_page__h5}` : styles.page
        }>
        <div className={styles.page__header}>
          <div className={styles.header__bg}>
            <img
              className={styles.bg__image}
              src="https://akim-oss.aikucun.com/mshop/31638c8d685da2f7a7b147912126d346d379cb27_1731406024149_25.png"
            />
            <div className={styles.bg__instruct} onClick={() => onInstructAction(true)}>
              <div className={styles.instruct__title}>游学说明</div>
            </div>
            <div
              className={styles.bg__brand}
              style={{ marginLeft: 15 * ((activityImages?.length || 0) - 1) }}></div>
          </div>
          <div
            id={"tab"}
            className={
              tabSticky
                ? `${styles.top__header} ${platform.isH5 || platform.isWxH5 ? `${styles.header__stick} ${styles.stick__h5}` : `${styles.header__stick}`}`
                : styles.top__header
            }>
            <ul className={styles.header__tabs}>
              {tabList.current?.map((item, index) => {
                return (
                  <li
                    className={
                      item?.monthTab === selectTab?.monthTab
                        ? `${styles["tabs__item"]} ${styles["tabs__item--active"]}`
                        : `${styles["tabs__item"]}`
                    }
                    key={item.month}
                    onClick={e => {
                      onChangeTabIndex(e, index);
                    }}>
                    {item?.monthTab}
                  </li>
                );
              })}
            </ul>
            {tabList.current?.map((item, index) => {
              return (
                <>
                  {
                    <div
                      className={
                        item?.monthTab === selectTab?.monthTab
                          ? styles.category__show
                          : styles.category__hidden
                      }>
                      <CategoryTab
                        key={index + `${item?.month}`}
                        list={categoryList}
                        onSelectionChange={onCategorySelectAction}
                        onCategoryShowOrHide={onCategoryShowOrHideAction}
                      />
                    </div>
                  }
                </>
              );
            })}
          </div>
        </div>
        <div className={tabSticky ? `${styles.list} ${styles.list_stick}` : styles.list}>
          {tabList.current?.map((item, index) => {
            return (
              <>
                {
                  <div
                    className={
                      item?.monthTab === selectTab?.monthTab ? styles.list_show : styles.list_hidden
                    }>
                    <StudyTourListComp
                      key={index + `${item?.month}`}
                      title={item?.month}
                      tabKey={item?.month}
                      selectStudyIds={selectStudyIds.current}
                      categoryCode={cateorySelect[item?.monthTab ?? ""]}
                      onStudyTourListAction={onStudyTourListAction}
                      onUpdateSelectStatusAction={onUpdateSelectStatusAction}
                    />
                  </div>
                }
              </>
            );
          })}

          <div className={styles.bottom}>
            <div className={styles.bottom__left} onClick={() => onSelectedDialogVisible(true)}>
              <div className={styles.left__title}>已选: </div>
              <div className={`${styles.left__title} ${styles.left__red}`}>{checkCount}</div>
              <div className={styles.left__title}>{`/${totalCount}`}</div>
              <img
                className={styles.left_arrow}
                src={
                  visible
                    ? "https://akim-oss.aikucun.com/mshop/f763bb96a88b46618aa6010b4cc55550f80d5b4a_1717417777993_6.png"
                    : "https://akim-oss.aikucun.com/mshop/42ba44acffddc5647a3806e3b0216fc2b6e811f4_1717474367976_38.png"
                }
              />
            </div>
            <div className={styles.bottom__right} onClick={() => onRegistrationAction(true)}>
              <div className={styles.right__title}>立即报名</div>
            </div>
          </div>
        </div>
        {visible && (
          <SelectedActivityListComp
            visible={visible}
            studyTourInfo={selectListMap.current}
            tab={selectTab?.month?.toString()}
            onVisiblePress={onSelectedDialogVisible}
            onDeletePress={onDeleteAction}
          />
        )}
        {instructVisible && (
          <InstructAlert
            visible={instructVisible}
            title="游学说明"
            content={configInfo?.studyTourSignUpNoticeResp?.introduce}
            btnName="确认"
            setVisible={onInstructAction}
          />
        )}

        {registrationVisible && (
          <InstructAlert
            visible={registrationVisible}
            title="报名须知"
            content={configInfo?.studyTourSignUpNoticeResp?.notice}
            btnName="我已阅读并同意"
            setVisible={onRegistrationAction}
          />
        )}
      </div>
    </>
  );
};

export default YouxueCalendarPage;
