import { ReactComponent as ExpandIcon } from "@/assets/expand_icon.svg";
import { useCommonContext } from "@/utils/context/common";
import { Popup } from "antd-mobile";
import { default as React, useEffect, useRef, useState } from "react";
import styles from "./index.module.less";

export type Props = {
  data?: Array<{
    imgUrl?: string;
    text?: string;
    activityTagNameColor?: string;
    promotionLevel?: string;
  }>;
  activeIndex?: number;
  color?: string;
  bodyClassName?: string;
  activeColor?: string;
  onChange: (index: number) => void;
  expandable?: boolean;
  bgColor?: string;
  navBtnColor?: string;
  onExpand?: (expand) => void;
};

const Tab: React.FC<Props> = ({
  data = [],
  activeIndex = 0,
  color = "#666",
  activeColor = "#fff",
  bodyClassName = "",
  onChange,
  expandable,
  bgColor,
  navBtnColor,
  onExpand,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const tabsInfoList = useRef<Array<{ left: number; width: number }>>([]);
  const imgCount = useRef(0);
  const [expand, setExpand] = useState(false);

  const { skin } = useCommonContext();

  useEffect(() => {
    tabsInfoList.current = Array.from(tabsRef.current?.children || []).map(item => ({
      width: item.clientWidth,
      left: item["offsetLeft"],
    }));
    imgCount.current = Array.from(tabsRef.current?.children || []).filter(item =>
      item.querySelector("img"),
    ).length;
  }, [data]);

  useEffect(() => {
    tabsRef.current?.children[activeIndex]?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
  }, [activeIndex]);

  const scrollIntoView = index => {
    tabsRef.current?.children[index]?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
  };

  const onClickExpand = () => {
    setExpand(expand => !expand);
    onExpand?.(!expand);
  };

  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <div ref={tabsRef} className={`${styles["tabs-row"]} ${expandable ? styles.pr56 : ""}`}>
          {data.map((item, index) => (
            <div
              className={`${styles.tab} ${index === activeIndex ? styles.active : ""}`}
              key={index}
              onClick={() => {
                scrollIntoView(index);
                setExpand(false);
                onChange(index);
              }}>
              <div
                className={styles.text}
                style={{
                  color: index === activeIndex ? activeColor : item.activityTagNameColor || color,
                }}>
                {item.text}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Popup
        visible={expand}
        onMaskClick={() => {
          setExpand(false);
        }}
        position="top"
        style={{ position: "absolute", top: 0 }}
        bodyClassName={styles.popupbody + " " + bodyClassName}>
        <div className={styles.line} />
        <div className={styles.popupContainer}>
          {data.map((item, index) => (
            <div
              className={`${styles.item} ${index === activeIndex ? styles.active : ""}`}
              key={index}
              onClick={() => {
                onChange(index);
                setExpand(false);
                scrollIntoView(index);
              }}>
              <div className={styles.text}>{item.text}</div>
            </div>
          ))}
        </div>
      </Popup>

      {expandable && (
        <div className={styles.expandArea}>
          <div
            className={styles.gradientArea}
            style={{
              background: bgColor
                ? `linear-gradient(to right, ${navBtnColor}00, ${navBtnColor})`
                : "",
            }}></div>
          <div
            className={styles.iconWrapper}
            style={{
              backgroundColor: bgColor,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              "--bgColor": color,
              backgroundImage: `url(${skin?.home?.categoryBg})`,
            }}
            onClick={onClickExpand}>
            <ExpandIcon className={styles.icon} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tab;
