import React from "react";
import styles from "./index.module.less";
import RichText from "../rich-text";
import { Image } from "antd-mobile";
import letterBg from "@/assets/bg_invite_header.png";

interface Props {
  text: string;
  highlightText?: string[];
}

// 邀请涵
const InvitationComp: React.FC<Props> = props => (
  <div className={styles.comp}>
    <div className={styles.c1}>
      <div className={styles.msg}>
        <div className={styles.tv1}>尊敬的爱豆，</div>
        <RichText
          text={props.text}
          highlight={props.highlightText}
          className={styles.tv2}
          highlightClassName={styles.tv3}
        />
      </div>
    </div>
    <Image src={letterBg} className={styles.letterBg} />
  </div>
);

export default InvitationComp;
