import loadingPng from "@/base64";
import ActivityTask from "@/pages/rewardCenter/components/activity-task/activity-task";
import exposure from "@/utils/expose/exposure";
import { getCookie } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Dialog, Image } from "antd-mobile";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ActivityBanner from "./components/activity-banner/activity-banner";
import ActivityStatus from "./components/activity-status/activity-status";
import EmptyStatus from "./components/empty-status";
import Tabs from "./components/tabs/tabs";
import styles from "./gold-medal.module.less";
import useGoldMedal from "./hooks/useGoldMedal";

const GoldMedal: React.FC = () => {
  document.title = "金牌新推官";
  const pageName = "金牌星推官";
  const track = Track();

  const navigater = useNavigate();
  const { navigate } = useNavigation();
  const [search] = useSearchParams();
  const activityCode = search.get("activityCode") || "";

  const { activityData, tabList, tabIndex, onSetTabIndex, list, pageLoading, setPageName } =
    useGoldMedal({});

  const toRulePage = () => {
    AKJS.action.dispatch("event.page.open", {
      url: "https://h5.aikucun.com/p/GN06il2nK.html",
    });
  };

  const showDialog = () => {
    Dialog.alert({
      title: "预计可获得",
      content:
        "此奖励金额为截止当前日期，所有已参与活动预计获得奖励之和(不包含实物奖励)，最终实发奖励以活动结束后实际发放为准。",
      confirmText: "我知道了",
      className: "dialog__private",
      // onConfirm: () => { },
    });
  };

  const toSearchPage = () => {
    let envid: any = window.location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    navigate({
      appOptions: {
        url: `${origin}/mx-shop-micro/year-end-bonus/search?activityCode=${activityCode}&token=${search.get("token")}&shopId=${search.get(
          "shopId",
        )}&channel=h5Mall&env_identity=${envid || getCookie("X-Hades-Env-Identity")
          }&tabIndex=${tabIndex}`,
      },
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        const dom = document.getElementById(`cnt-${list[i]?.actId}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [list]);

  useEffect(() => {
    track.pageView(pageName, {
      domain: "年终奖",
    });
    setPageName(pageName);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.scroll_view}>
        {/* 规则 */}
        <img
          className={styles.rule__absolute}
          src="https://akim-oss.aikucun.com/mshop/8e8a120b7b795a6c5b7428427f5a3e67245d44d4_1731383104974_3.png"
          onClick={toRulePage}
        />
        {/* header */}
        <div className={styles.page__header}>
          {/* 活动状态 */}
          <ActivityStatus
            type="gold-medal"
            sysTime={activityData.sysTime}
            taskEndTime={activityData.taskEndTime}
            taskStatus={activityData.taskStatus}
            backgroundColor="#FC4A2F"
            borderColor="#FEB85E"
          />
        </div>
        {/* body */}
        <div className={styles.page__body}>
          {/* 数据展示 */}
          <div className={styles.body__data}>
            <div className={styles.data__list}>
              <div className={styles.list__item}>
                <div className={styles.item__value}>
                  {activityData?.estimatedRewardsAmount || 0}
                  <span className={styles.unit}>元</span>
                </div>
                <div className={styles.item__label}>
                  预计可获得
                  <img
                    className={styles.icon}
                    src="https://akim-oss.aikucun.com/mshop/dc16dd3352577a826e74c056027382e2b5fbc772_1731119200062_62.png"
                    onClick={showDialog}
                  />
                </div>
              </div>
              <div className={styles.list__item}>
                <div className={styles.item__value}>
                  {activityData?.newCustomerCount || 0}
                  <span className={styles.unit}>人</span>
                </div>
                <div className={styles.item__label}>已拓展新客</div>
              </div>
            </div>
            <div className={styles.data__bg}></div>
          </div>
          {/* 内容区域 */}
          <div className={styles.body__content}>
            {/* tab */}
            <Tabs list={tabList} activeIndex={tabIndex} onChange={onSetTabIndex} />
            {/* 卡片列表 */}
            {
              list.slice(0, 3).map((item, index) => (
                <ActivityTask
                  key={item.actId}
                  index={index}
                  data={item}
                  backgroundColor="#F7F8F9"
                  isR={true}
                  trackParams={{
                    pageName,
                    domain: "年终奖",
                    tab_name: tabList[tabIndex]?.name,
                    module: "活动",
                    resource_type: "活动卡片",
                    resource_name: item?.name,
                    resource_rank: index,
                    activity_id: item?.actId,
                  }}
                />
              ))
            }
            {/* 空态 || 查看更多 */}
            {list.length === 0 ? (
              <EmptyStatus />
            ) : (
              <div className={styles.content__more} onClick={toSearchPage}>
                查看更多
                <img
                  className={styles.icon__arrow}
                  src="https://akim-oss.aikucun.com/mshop/e510552e929a375e0de5b636fcccc193bbbf79ce_1731325389799_48.png"
                />
              </div>
            )}
          </div>
        </div>
        {/* 领奖攻略 */}
        <img
          className={styles.page__strategy}
          src="https://akim-oss.aikucun.com/mshop/204eafe0d9877c3463a355d5c5e0558b95fd500c_1732005526580_24.png"
        />
        {/* 业绩提升小妙招 */}
        <ActivityBanner pageName={pageName} />
      </div>
      {pageLoading && (
        <div className={styles.page__loading}>
          <Image src={loadingPng} width={pxtorem(30)}></Image>
        </div>
      )}
    </div>
  );
};

export default GoldMedal;
