import {
  pushShoppingMessageShareServ,
  queryPageTabServ,
  queryShoppingMessageListServ,
} from "@/api/my";
import loadingPng from "@/base64";
import Bottom from "@/components/bottom/index";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { Image, InfiniteScroll } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { useSearchParams } from "react-router-dom";
import ShoppingMessageCard from "./components/ShoppingMessageCard";
import TextPopup from "./components/TextPopup";
import styles from "./index.module.less";
import { getTrackMaterialInfo, getTrackProductInfo } from "./trackTools";

const DispatchTask: React.FC = () => {
  const track = Track();
  const [search] = useSearchParams();

  const tabList = [
    {
      key: "TODAY",
      value: "今日",
    },
    {
      key: "YESTERDAY",
      value: "昨日",
    },
    {
      key: "MORE",
      value: "更早",
    },
  ];
  const [tabIndex, setTabIndex] = useState<number>(0);
  let [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [list, setList] = useState<any[]>([]);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getList = async params => {
    setLoading(true);
    let res = await queryShoppingMessageListServ({
      pageNo,
      pageSize,
      queryType: tabList[tabIndex].key,
      pageId: platform?.isApp
        ? "App_Member"
        : platform?.isMini
          ? "Mini_Program_Member"
          : "H5_Member",
      ...params,
    });
    setList(res.pageIndex > 1 ? list.concat(res.result) : res.result);
    setHasNext(res.hasNext);
    setPageNo(res.pageIndex);
    setLoading(false);
  };

  const queryPageTab = async () => {
    let data = await queryPageTabServ();
    switch (data?.queryType) {
      case "TODAY":
        setTabIndex(0);
        break;
      case "YESTERDAY":
        setTabIndex(1);
        break;
      case "MORE":
        setTabIndex(2);
        break;
    }
    getList({ pageNo, queryType: data?.queryType || "TODAY" });
  };

  const changeTabIndex = (e, index: number) => {
    e.stopPropagation();
    setTabIndex(index);
    setHasNext(false);
    setList([]);
    getList({
      pageNo: 1,
      queryType: tabList[index].key,
    });
    const dom = document?.getElementById("page");
    if (dom) {
      dom.scrollTop = 0;
    }
  };

  const loadMore = async () => {
    if (hasNext && !loading) {
      pageNo++;
      getList({
        pageNo,
      });
    }
  };

  useEffect(() => {
    document.title = "派单任务";
    queryPageTab();

    track.track("$pageview", {
      page_name: "派单任务",
      previous_page_name: search.get("page_name"),
    });
  }, []);

  const onShared = index => {
    const { skuId, spuId, itemCode, userId } = list[index] || {};
    if (skuId && spuId && itemCode) {
      flushSync(() => {
        {
          if (list[index]?.shared) {
            return;
          }
          setList(
            list.map((item, i) => {
              if (i === index) {
                item.shared = true;
              }
              return item;
            }),
          );
        }
      });
      pushShoppingMessageShareServ({
        skuId,
        spuId,
        itemCode,
        userId,
      });
    }
  };

  const onResourceClick = (index, { btnName }) => {
    const { showType, productVO, materialVO } = list[index] || {};
    const isProduct = showType === 1;
    const trackParams = isProduct
      ? getTrackProductInfo(productVO)
      : getTrackMaterialInfo(materialVO);
    track.track("resource_click", {
      page_name: "派单任务",
      previous_page_name: search.get("page_name"),
      resource_type: "派单任务",
      resource_content: isProduct ? "商品" : "素材",
      resource_rank: 1,
      ...trackParams,
      btn_name: btnName || "查看详情",
      tab_name: tabList[tabIndex].value,
    });
  };

  const handleSetVisible = () => {
    setVisible(true);
    track.track("resource_click", {
      page_name: "派单任务",
      previous_page_name: search.get("page_name"),
      resource_type: "精选文案",
      btn_name: "去看看",
    });
  };

  return (
    <div id="page" className={styles.page}>
      <div className={styles.page__header}>
        <div className={styles.header__notice}>
          <div className={styles.notice__left}>复制转发话术，促进用户下单</div>
          <div className={styles.notice__right} onClick={() => handleSetVisible()}>
            去看看
            <img
              className={styles.icon__arrow}
              src="https://akim-oss.aikucun.com/mshop/b14f97413e78f590853ff4772d76c33925e6bfe3_1710229944217_81.png"
            />
          </div>
        </div>
        <ul className={styles.header__tabs}>
          {tabList.map((item, index) => {
            return (
              <li
                className={
                  index === tabIndex
                    ? `${styles["tabs__item"]} ${styles["tabs__item--active"]}`
                    : `${styles["tabs__item"]}`
                }
                key={item.key}
                onClick={e => {
                  changeTabIndex(e, index);
                }}>
                {item.value}
              </li>
            );
          })}
        </ul>
        {/* <div className={styles.header__tips}>准实时数据，数据每X分钟更新一次</div> */}
      </div>
      <div className={styles.page__body}>
        {loading && list.length === 0 && (
          <div className={`${styles.loading} ${styles.loadingFixed}`}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )}
        <>
          {list?.length > 0 ? (
            <>
              {list.map((item, index) => {
                return (
                  <ShoppingMessageCard
                    key={index}
                    index={index}
                    item={item}
                    onShared={onShared}
                    onResourceClick={onResourceClick}
                  />
                );
              })}
              <InfiniteScroll loadMore={loadMore} hasMore={hasNext} threshold={0}>
                {loading && (
                  <div className={styles.loading}>
                    <Image src={loadingPng} width={pxtorem(30)}></Image>
                  </div>
                )}
                <Bottom
                  loading={loading}
                  hasMore={hasNext}
                  reachBottom={list && list?.length > 0 ? true : false}
                />
              </InfiniteScroll>
            </>
          ) : (
            !loading && (
              <div className={styles.empty}>
                <img
                  className={styles.emptyIcon}
                  src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                />
                <div>暂无相关任务</div>
              </div>
            )
          )}
        </>
      </div>
      {visible && <TextPopup visible={visible} setVisible={setVisible} />}
    </div>
  );
};

export default DispatchTask;
