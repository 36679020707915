import { AwardItem, CouponAward } from "@/models/reward";
import { useCommonContext } from "@/utils/context/common";
import { getCurrentformatTime } from "@/utils/date-format";
import React from "react";
import styles from "./Normal.module.less";

interface CardProps {
  reward?: AwardItem;
  isFromCustomer?: boolean;
  onUsePress?: (reward?: AwardItem) => void;
  onInviteClick?: (reward?: AwardItem) => void;
  onCustomerClick?: (reward?: AwardItem) => void;
  onCouponToShopDetailClick?: (couponAward?: CouponAward) => void;
}

const NormalCardComp: React.FC<CardProps> = ({
  reward,
  isFromCustomer,
  onUsePress,
  onInviteClick,
  onCustomerClick,
  onCouponToShopDetailClick,
}) => {
  const { canShare } = useCommonContext();
  const unusableCoupon =
    reward?.awardType === "COUPON" &&
    reward?.couponAward !== undefined &&
    (reward?.couponAward?.couponUseStatus ?? 0) > 0;
  const onUseAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (unusableCoupon) {
      console.log("优惠券不可用，不支持点击跳转");
      return;
    }
    onUsePress?.(reward);
  };

  const onInviteAction = () => {
    onInviteClick?.(reward);
  };

  const onCustomerCenterAction = () => {
    onCustomerClick?.(reward);
  };

  const onShopDetailAction = () => {
    onCouponToShopDetailClick?.(reward?.couponAward);
  };

  const onLookAction = () => {
    if (reward?.awardType === "RED_PACKET") {
      onUsePress?.(reward);
    }
  };

  const content = () => {
    if (reward?.awardType === "COUPON") {
      if (reward?.couponAward?.publisherType === "MERCHANT_SHOP") {
        return (
          <>
            <div className={styles.shop_title} onClick={onShopDetailAction}>
              <div className={styles.title_left}>{reward?.couponAward?.merchantShopName}</div>
              <img
                className={styles.title_image}
                src={
                  "https://akim-oss.aikucun.com/mshop/7bcad940df84d27092f655c1e58ac791769c4e33_1724552411687_71.png"
                }
              />
            </div>
            <div className={`${styles.content_title} ${styles.content_bottom}`}>
              <div
                className={`${styles.title_left} ${styles.title_red}`}>{`${reward?.couponAward?.couponName}`}</div>
              <div className={styles.title_left}>优惠券</div>
            </div>
            <div
              className={`${styles.content_desc} ${styles.content_bottom}`}>{`有效期至：${getCurrentformatTime(parseInt(reward?.couponAward?.couponExpiredTime || "0", 10))}`}</div>
          </>
        );
      }
      return (
        <>
          <div className={styles.content_title}>
            <div
              className={[styles.title_left, styles.title_red].join(
                " ",
              )}>{`${reward?.couponAward?.couponName}`}</div>
            <div className={styles.title_left}>优惠券</div>
          </div>
          <div
            className={
              styles.content_desc
            }>{`有效期至：${getCurrentformatTime(parseInt(reward?.couponAward?.couponExpiredTime || "0", 10))}`}</div>
        </>
      );
    }

    if (reward?.awardType === "POINTS") {
      return (
        <>
          <div className={styles.content_title}>
            <div className={styles.title_left}>积分奖励</div>
            <div
              className={[styles.title_left, styles.title_red].join(
                " ",
              )}>{`${reward?.pointsAward?.amount}分`}</div>
            <img
              className={styles.title_image}
              src={
                "https://akim-oss.aikucun.com/mshop/508abbcd00edddbf44f1833da9e927b01d84584c_1723197813120_87.png"
              }
            />
          </div>
          <div className={styles.content_desc}>{`${reward?.pointsAward?.awardDesc}`}</div>
        </>
      );
    }

    return (
      <>
        <div className={styles.content_title}>
          <div className={styles.title_left}>全场通用红包奖励</div>
          <div className={styles.title_right}>
            <div className={styles.price_left}>¥</div>
            <div className={styles.price_right}>{`${reward?.redPackageAward?.amount}`}</div>
          </div>
        </div>
        <div className={styles.red_title}>
          <div className={styles.title_left}>{reward?.redPackageAward?.awardDesc}</div>
          {!isFromCustomer && (
            <img
              className={styles.title_image}
              src={
                "https://akim-oss.aikucun.com/mshop/7bcad940df84d27092f655c1e58ac791769c4e33_1724552411687_71.png"
              }
            />
          )}
        </div>
        <div
          className={styles.content_desc}
          style={{ color: reward?.redPackageAward?.timeLimit ? "#FF4747" : "" }}
        >{`有效期至：${getCurrentformatTime(parseInt(reward?.redPackageAward?.redPackageExpiredTime || "0", 10))}`}</div>
      </>
    );
  };

  const cardImgeUrl = () => {
    if (reward?.awardType === "COUPON") {
      if (reward?.couponAward?.publisherType === "MERCHANT_SHOP") {
        return reward?.couponAward?.merchantShopLogo;
      }
      return reward?.couponAward?.awardImageUrl;
    }
    if (reward?.awardType === "POINTS") {
      return reward?.pointsAward?.awardImageUrl;
    }
    return reward?.redPackageAward?.awardImageUrl;
  };

  const cardUseTitle = () => {
    if (reward?.awardType === "COUPON") {
      if (reward?.couponAward?.couponUseStatus === 1) {
        return "已使用";
      }
      if (reward?.couponAward?.couponUseStatus === 2) {
        return "已过期";
      }
      return "去使用";
    }
    if (reward?.awardType === "POINTS") {
      return "去查看";
    }
    return "去逛逛";
  };

  const bottomRight = () => {
    if (isFromCustomer && canShare) {
      return (
        <div className={styles.right_forward} onClick={onInviteAction}>
          <div className={styles.btn_title}>邀请好友</div>
        </div>
      );
    }
    return (
      <>
        <div className={styles.right_forward} onClick={onInviteAction}>
          <div className={styles.btn_title}>邀请好友</div>
        </div>
        <div className={styles.right_space} />
        <div
          className={
            unusableCoupon ? `${styles.card_right} ${styles.right_gray}` : styles.card_right
          }
          onClick={onUseAction}>
          <div className={styles.btn_title}>{cardUseTitle()}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.top}
        >
          <div>{`中奖时间：${getCurrentformatTime(parseInt(reward?.awardTime || "0", 10))}`}</div>
          {reward?.redPackageAward?.timeLimit ? <div className={styles.limited_time}>限时红包</div> : null}
        </div>
        {!isFromCustomer && (
          <img
            className={styles.topLine}
            src={
              "https://akim-oss.aikucun.com/mshop/7056538220bfea98f51bfaee99f70b7d2423d7db_1723120200611_89.png"
            }
          />
        )}
        <div className={styles.card} onClick={onLookAction}>
          <div className={styles.card_left}>
            <img className={styles.left_image} src={cardImgeUrl()} />
            <div className={styles.left_content}>{content()}</div>
          </div>
        </div>
        <img
          className={styles.bottomLine}
          src={
            "https://akim-oss.aikucun.com/mshop/7056538220bfea98f51bfaee99f70b7d2423d7db_1723120200611_89.png"
          }
        />
        <div className={isFromCustomer ? styles.bottom : `${styles.bottom} ${styles.bottom_end}`}>
          {isFromCustomer && (
            <div className={styles.bottom_left} onClick={onCustomerCenterAction}>
              <img className={styles.left_image} src={reward?.userAvatarUrl} />
              <div className={styles.left_content}>{reward?.userName}</div>
              <img
                className={styles.left_arrow}
                src={
                  "https://akim-oss.aikucun.com/mshop/eec3ad2e003b47ca2dd387bc88c570ac39ada344_1723121268728_92.png"
                }
              />
            </div>
          )}
          <div className={styles.bottom_right}>{bottomRight()}</div>
        </div>
      </div>
    </>
  );
};

export default NormalCardComp;
