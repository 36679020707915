import { pxtorem } from "@/utils/pxtorem";
import React, { useEffect, useRef, useState } from "react";
import VirtialList from "../virtual-list/index";
import styles from "./waterfall.module.less";

interface Props {
  dataList: any[]; // 数据列表
  childRender: React.FC<any>; // 子组件FC
  margin?: { x: number; y: number }; // x轴和y轴的间距
  padding?: number; // 虚拟列表预加载高度
  resetHeight?: (height: number) => void; // 重置高度
}

const Waterfall: React.FC<Props> = (props: Props) => {
  const { dataList, childRender, margin = { x: 8, y: 8 }, padding, resetHeight } = props;
  const ratio: any = document.documentElement.style.fontSize.split("px")[0];
  const [leftArr, setLeftArr] = useState<any>([]);
  const [rightArr, setRightArr] = useState<any>([]);
  const [leftHeightArr, setLeftHeightArr] = useState<any>([]);
  const [rightHeightArr, setRightHeightArr] = useState<any>([]);
  const leftHeightRef = useRef<number>(0);
  const rightHeightRef = useRef<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [domList, setDomList] = useState<any[]>([]);

  useEffect(() => {
    const start = leftArr.length + rightArr.length;

    for (let i = start; i < domList.length; i++) {
      if (leftHeightRef.current <= rightHeightRef.current) {
        leftArr.push(dataList[i]);
        leftHeightArr.push(domList[i]);
        leftHeightRef.current += (domList[i] + margin.y) / Number(ratio);
      } else {
        rightArr.push(dataList[i]);
        rightHeightArr.push(domList[i]);
        rightHeightRef.current += (domList[i] + margin.y) / Number(ratio);
      }
    }
    setMaxHeight(Math.max(leftHeightRef.current, rightHeightRef.current));
    setLeftArr([...leftArr]);
    setRightArr([...rightArr]);
    setLeftHeightArr([...leftHeightArr]);
    setRightHeightArr([...rightHeightArr]);
    resetHeight ? resetHeight(Math.max(leftHeightRef.current, rightHeightRef.current)) : "";
  }, [domList]);

  useEffect(() => {
    const items: any = document.getElementsByClassName(styles.originCard);
    let arr = [...domList];
    for (let item of items) {
      if (!arr[item.dataset.index]) {
        arr.push(item?.getBoundingClientRect()?.height ?? 0);
      }
    }
    setDomList(arr);
  }, [dataList]);

  return (
    <div>
      <div className={styles.container} style={{ height: `${maxHeight}rem` }}>
        {dataList.map((item, index) => {
          if (domList[index]) {
            return;
          }
          return (
            <div
              className={styles.originCard}
              style={{ width: `calc(50% - ${pxtorem(margin.x / 2)})` }}
              key={index}
              data-index={index}>
              {childRender(item, index)}
            </div>
          );
        })}
        <div
          className={styles.left}
          style={{ width: `calc(50% - ${pxtorem(margin.x / 2)})`, height: "100%" }}>
          {leftArr.length > 0 && (
            <VirtialList
              dataList={leftArr}
              itemHeight={index => {
                return leftHeightArr[index];
              }}
              childRender={childRender}
              marginY={margin?.y}
            />
          )}
        </div>
        <div
          className={styles.right}
          style={{ width: `calc(50% - ${pxtorem(margin.x / 2)})`, height: "100%" }}>
          {rightArr.length > 0 && (
            <VirtialList
              dataList={rightArr}
              itemHeight={index => {
                return rightHeightArr[index];
              }}
              childRender={childRender}
              marginY={margin?.y}
            />
          )}
        </div>
      </div>
      {/* {footerRender && maxHeight > 0 && footerRender()} */}
    </div>
  );
};

export default Waterfall;
