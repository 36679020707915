import { getCartTotal, isInGray } from "@/api";
import AddToCartModal from "@/components/add-to-cart-modal";
import useShare from "@/pages/promote/share";
import exposure from "@/utils/expose/exposure";
import { MICRO_APP_BASEURL, getCookie } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import {
  ConferenceActivity,
  RecommendMidActivity,
  RecommendMidConference,
  RecommendMidProduct,
  RecommendNormalActivity,
  RecommendProduct,
} from "@akc/mx-taro-h5-components";
import { Image, InfiniteScroll, Popup, Toast } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PocketCom from "../promote/components/recommend/pocket";
import { useTrack } from "../promote/track";
import CustomSwiper from "./components/custom-swiper";
import InfoContent from "./components/info-content";
import Interaction from "./components/interaction";

import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";
import styles from "./index.module.less";
// import Skeleton from "@/assets/promote-detail-skeleton.png";

import { queryMaterialDetail, queryRecommendByMaterialNo } from "@/api/promote";
import AddPurchasePanel from "@/components/add-purchase-panel";
import { useActivate, useUnactivate } from "react-activation";

const PromoteDetail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const materialNo = searchParams.get("materialNo");
  const shopId = searchParams.get("shopId");
  const fromPage = searchParams.get("fromPage");
  const {
    navigate2Product,
    navigate2LiveRoom,
    navigate2Activity,
    navigate2SuperVenue,
    navigate2Venue,
    navigate: navigater,
    navigate2Cart,
  } = useNavigation();
  const { trackDetailPageView, trackDetailResourceClick } = useTrack();
  const useShareProps = useShare();

  const [materialData, setMaterialData] = useState<any[]>([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [hasNext, setHasNext] = useState<boolean>(true);
  // const [pageSize, setPageSize] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(true);
  const [cartModalVisible, setCartModalVisible] = useState(false);
  const [modalProduct, setModalProduct] = useState({});
  const [showMoreCard, setShowMoreCard] = useState<boolean>(false);
  const [cartCount, setCartCount] = useState<number>(0);
  const [activityList, setActivityList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [conferenceList, setConferenceList] = useState([]);
  const [scheduleList] = useState([]);
  //是否显示新版加购面板
  const [showNewCartPanel, setShowNewCartPanel] = useState<boolean>(false);

  //获取加购面板灰度
  const getCartPanelGray = async () => {
    const res = await isInGray("B-H5_REACT_ADD_PANEL");
    const newCartPanel = !!res;
    setShowNewCartPanel(newCartPanel);
  };

  const dataFormatRender = (data: any) => {
    const {
      materialNo: resMaterialNo,
      materialContextVOS,
      name,
      showName,
      source,
      uploaderRole,
      creatorAvatar,
      creatorNickname,
      createTimeStamp,
      sysTime,
      awesome,
      awesomeNum,
      productListVOList,
      activityListVOList,
      conferenceVOList,
      labelVOS,
      polyMaterialText,
    } = data;

    // 处理图片视频素材 contextType = 2：图片 3：视频
    const swiperSources: any[] = [];
    try {
      materialContextVOS.forEach((item: any) => {
        if (item.contextType === 2) {
          swiperSources.push({
            url: item.contextContent.contextImage.imageUrl,
            type: "image",
            width: item.contentFormat ? JSON.parse(item.contentFormat).width : 375,
            height: item.contentFormat ? JSON.parse(item.contentFormat).height : 375,
          });
        } else if (item.contextType === 3) {
          swiperSources.unshift({
            url: item.contextContent.contextVideo.video,
            type: "video",
            coverUrl: item.contextContent.contextVideo.thumbnail,
            width: item.contentFormat ? JSON.parse(item.contentFormat).width : 375,
            height: item.contentFormat ? JSON.parse(item.contentFormat).height : 375,
          });
        }
      });
    } catch (error) {
      console.error(error);
      console.error("视频素材接口没返回尺寸");
    }

    // 处理文本素材
    const materialText = {
      title: name,
      subTitle: showName,
      labelList: labelVOS,
      isYouxue: source === 2 ? true : false,
      polyMaterialText,
    };
    // 处理素材基本信息
    const materialBaseInfo = {
      uploaderRole,
      creatorAvatar,
      creatorNickname,
      createTimeStamp,
      sysTime,
    };
    // 点赞分享交互
    const interactionData = {
      materialNo: resMaterialNo,
      isLike: awesome,
      likedNum: awesomeNum,
    };
    // 处理所有卡片
    const allCardList = []
      .concat(productListVOList.map(item => ({ ...item, cardType: "product" })))
      .concat(activityListVOList.map(item => ({ ...item, cardType: "activity" })))
      .concat(conferenceVOList.map(item => ({ ...item, cardType: "conference" })));
    const preCardList = allCardList.slice(0, 5);
    const lastCardList = allCardList.slice(5);

    const materialDataObj = {
      swiperSources,
      materialText,
      materialBaseInfo,
      interactionData,
      productListVOList,
      activityListVOList,
      conferenceVOList,
      preCardList,
      lastCardList,
    };
    setMaterialData(pre => [...pre, materialDataObj]);
  };
  const reqRecommendByMaterialNo = async () => {
    try {
      const { success, data }: any = await queryRecommendByMaterialNo({
        materialNo,
        pageSize: 5,
        pageNo,
        scene: "G_P_C",
        pageId: "H5_MaterialList",
      });
      if (!success) {
        return;
      }
      const {
        result,
        hasNext: isHasNext,
        pageIndex,
        // pageSize
      } = data ?? {};
      setPageNo(pageIndex + 1);
      // setPageSize(pageSize);
      setHasNext(isHasNext);
      result.forEach((item: any) => {
        dataFormatRender(item);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const reqMaterialDetail = async () => {
    try {
      const { success, data }: any = await queryMaterialDetail({
        materialNo,
        scene: "G_P_C",
        pageId: "H5_MaterialList",
      });
      if (!success) {
        return;
      }
      dataFormatRender(data);
      setLoading(false);
    } catch (error) {
      Toast.show({
        content: "网络异常，请稍后再试～",
      });
      console.log(error);
    }
  };
  const queryGetCartTotal = async () => {
    const { success, data }: any = await getCartTotal({ shopBizCode: shopId });
    if (!success) {
      return;
    }
    setCartCount(data?.cartSkuNum ?? 0);
  };
  const loadMore = async () => {
    if (!hasNext || loading) {
      return;
    }
    await reqRecommendByMaterialNo();
  };
  //加购
  const addCart = (item, resource_rank, relative_rank) => {
    setShowMoreCard(false);
    setCartModalVisible(true);
    setModalProduct(item);
    trackDetailResourceClick({
      activity_id: item.activityId,
      brand_id: item?.brandInfo?.brandId,
      material_id: materialNo,
      click_material_id: item.materialNo,
      sku_id: item.selectSkuId,
      btn_name: "加购",
      relative_rank,
      resource_rank,
      activity_spu_code: item.activitySpuId,
      fromPage,
    });
  };
  //商品详情
  const goProduct = (item, resource_rank, relative_rank) => {
    setShowMoreCard(false);
    navigate2Product({
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
      promotePageType: fromPage === "productDetail" ? 3 : 2,
    });
    trackDetailResourceClick({
      activity_id: item.activityId,
      brand_id: item?.brandInfo?.brandId,
      material_id: materialNo,
      click_material_id: item.materialNo,
      sku_id: item.selectSkuId,
      btn_name: "去商品详情",
      relative_rank,
      resource_rank,
      activity_spu_code: item.activitySpuId,
      fromPage,
    });
  };
  //还想买
  const stillBuy = (item, resource_rank: number, relative_rank: number) => {
    setShowMoreCard(false);
    console.log("还想买", item);
    navigate2Product({
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
    });
    // buyMore({
    //   activityId: item.activityId,
    //   activitySpuId: item.activitySpuId,
    // });
    // Toast.show("已通知商家您的意愿～");
    trackDetailResourceClick({
      activity_id: item.activityId,
      brand_id: item?.brandInfo?.brandId,
      material_id: materialNo,
      click_material_id: item.materialNo,
      sku_id: item.selectSkuId,
      btn_name: "还想买",
      relative_rank,
      resource_rank,
      activity_spu_code: item.activitySpuId,
      fromPage,
    });
  };
  //跳直播间
  const goLive = (item, resource_rank: number, relative_rank: number) => {
    console.log("跳直播间", item);
    setShowMoreCard(false);
    navigate2LiveRoom({
      liveId: item.liveVO.liveNo,
      liveStatus: item.liveVO.liveStatus,
    });
    trackDetailResourceClick({
      activity_id: item.activityId,
      brand_id: item?.brandInfo?.brandId,
      material_id: materialNo,
      click_material_id: item.materialNo,
      sku_id: item.selectSkuId,
      btn_name: "还想买",
      relative_rank,
      resource_rank,
      activity_spu_code: item.activitySpuId,
      fromPage,
    });
  };
  // 提醒我
  const remindMe = item => {
    console.log("提醒我", item);
  };
  const goHome = () => {
    if (searchParams.get("distributorId")) {
      navigate(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${searchParams.get(
          "distributorId",
        )}`,
      );
    } else {
      navigate(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  };
  //跳档期
  const goActivity = (item: any, resource_rank: number, relative_rank: number) => {
    console.log("跳档期", item);
    setShowMoreCard(false);
    navigate2Activity({
      activityNo: item.activityId,
      promotePageType: fromPage === "productDetail" ? 3 : 2,
    });
    trackDetailResourceClick({
      activity_id: item.activityId,
      brand_id: item?.brandInfo?.brandId,
      material_id: materialNo,
      click_material_id: item?.materialNo,
      sku_id: item?.selectSkuId,
      btn_name: "去档期",
      relative_rank,
      resource_rank,
      activity_spu_code: item.activitySpuId,
      fromPage,
    });
  };
  //跳会场
  const goConference = (item: any, resource_rank: number, relative_rank: number) => {
    setShowMoreCard(false);
    if (item.type === 1) {
      navigate2SuperVenue({
        h5Query: {
          hotSaleId: item.conferenceId,
        },
      });
    }
    if (item.type === 2) {
      navigate2Venue({
        h5Query: {
          conferenceId: item.conferenceId,
        },
      });
    }
    if (item.type === 3) {
      navigater({
        h5Options: {
          url: `/mx-shop-micro?customPageCode=${item.conferenceId}`,
        },
      });
    }
    trackDetailResourceClick({
      activity_id: item.activityId,
      brand_id: item?.brandInfo?.brandId,
      material_id: materialNo,
      click_material_id: item?.materialNo,
      sku_id: item.selectSkuId,
      btn_name: "去档期",
      relative_rank,
      resource_rank,
      activity_spu_code: item.activitySpuId,
      fromPage,
    });
  };
  const goBack = () => {
    console.log(window.history.length);

    if (window.history.length === 1) {
      goHome();
    } else {
      navigate(-1);
    }
  };
  //素材曝光埋点
  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < materialData.length; i++) {
        const dom = document.getElementById(
          `resource-${materialData[i].interactionData.materialNo}`,
        );
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [materialData?.length]);

  useActivate(() => {
    if (fromPage === "productDetail") {
      document.title = "实拍专区";
    } else {
      document.title = "逛一逛";
    }
    queryGetCartTotal();
    setShowMoreCard(false);
    setCartModalVisible(false);
    const shareType = fromPage === "productDetail" ? 5 : 3;
    useShareProps.shareSetting(shareType, materialNo);
  });
  useUnactivate(() => {
    console.log("离开---------");
    setShowMoreCard(false);
    setCartModalVisible(false);
  });
  useEffect(() => {
    if (fromPage === "productDetail") {
      document.title = "实拍专区";
    } else {
      document.title = "逛一逛";
    }
    //获取加购面板灰度
    getCartPanelGray();
    reqMaterialDetail();
    trackDetailPageView({ fromPage });
    queryGetCartTotal();
    const shareType = fromPage === "productDetail" ? 5 : 3;
    useShareProps.shareSetting(shareType, materialNo);
  }, []);

  //去购物车
  const goCart = () => {
    setShowMoreCard(false);
    console.log("去购物车");
    navigate2Cart();
  };

  //商详
  // const goDetail = (item: any, index: number, curTab: number, pocketCard: number) => {
  //   console.log("商详", item, index, curTab);
  //   setShowMoreCard(false);
  //   navigate2Product(
  //     {
  //       activityId: item.activityId,
  //       activitySpuId: item.activitySpuId,
  //       selectSkuId: item.selectSkuId
  //     }
  //   );
  // };

  const handleMore = item => {
    setActivityList(item.activityListVOList || []);
    setProductList(item.productListVOList || []);
    setConferenceList(item.conferenceVOList || []);
    setShowMoreCard(true);
  };

  return (
    <>
      <div className={styles.PromoteDetailContainer}>
        <div className={styles.NavContainer}>
          <div className={styles.NavContentBox}>
            <div className={styles.ImageBox} onClick={goBack}>
              <Image src={BackPng} className={styles.NavImage} />
            </div>
            <div className={styles.ImageBox} onClick={goHome}>
              <Image src={HomePng} className={styles.NavImage} />
            </div>
          </div>
          <div className={styles.text}>精选好物，边逛边买</div>
          <div className={styles.NavCart} onClick={() => navigate2Cart()}>
            <img
              className={styles.NavCartImage}
              src="https://akim-oss.aikucun.com/371a5384044948994735af6426ee4634f70273de_1701866437444_81.png"
            />
            {cartCount > 0 && <div className={styles.NavCartBadge}>{cartCount}</div>}
          </div>
        </div>
        <div className={styles.Main}>
          {materialData?.map((item: any, index: number) => {
            return (
              <>
                <div
                  className={styles.MaterialContainer}
                  key={index}
                  id={`resource-${item.interactionData.materialNo}`}
                  data-param={JSON.stringify({
                    page_name: fromPage === "productDetail" ? "实拍专区_图文" : "种草详情",
                    domain: fromPage === "productDetail" ? "实拍专区" : "种草",
                    resourceRank: index + 1,
                    type: fromPage === "productDetail" ? "实拍专区图文组件" : "种草图文组件",
                    name: item?.name,
                    materialId: materialNo,
                    click_material_id: index === 0 ? undefined : item?.interactionData.materialNo,
                    searchRequestId: getCookie("mstoken") || "" + Date.now(),
                  })}
                  data-eventid={`resource-${item.interactionData.materialNo}`}>
                  {/* 用户信息组件 */}
                  {/* <UserInfo materialBaseInfo={item.materialBaseInfo} /> */}
                  {/* 轮播图组件 */}
                  <CustomSwiper swiperSources={item.swiperSources} />
                  <div className={styles.TotalInfoContainer}>
                    {/* 接入 商卡组件 */}
                    {(item.productListVOList.length > 0 ||
                      item.activityListVOList.length > 0 ||
                      item.conferenceVOList.length > 0) && (
                      <div
                        className={styles.productContainer}
                        style={{
                          height: item.preCardList?.length === 1 ? pxtorem(96) : pxtorem(88),
                        }}>
                        {/* 前五个 */}
                        {item.preCardList?.length > 1 &&
                          item.preCardList.slice(0, 1).map((item: any, sourceIndex: number) => {
                            switch (item.cardType) {
                              case "product":
                                return (
                                  <div
                                    key={sourceIndex}
                                    className={styles.productBox}
                                    style={{ borderRight: "none", width: pxtorem(311) }}>
                                    <RecommendMidProduct
                                      product={item}
                                      titleDown="H5_MaterialList_ProductNameDown"
                                      goProduct={item => {
                                        goProduct(item, sourceIndex, index);
                                      }}
                                      addCart={item => {
                                        addCart(item, sourceIndex, index);
                                      }}
                                      goLive={item => {
                                        goLive(item, sourceIndex, index);
                                      }}
                                      stillBuy={item => {
                                        stillBuy(item, sourceIndex, index);
                                      }}
                                      remindMe={remindMe}
                                      text="赚"
                                    />
                                  </div>
                                );

                              case "activity":
                                return (
                                  <div
                                    key={sourceIndex}
                                    className={styles.productBox}
                                    style={{ borderRight: "none", width: pxtorem(311) }}>
                                    <RecommendMidActivity
                                      activity={item}
                                      text="赚"
                                      brandDown="H5_MaterialList_ScheduleNameDown"
                                      goActivityDetail={item => {
                                        goActivity(item, sourceIndex, index);
                                      }}
                                    />
                                  </div>
                                );
                              case "conference":
                                return (
                                  <div
                                    key={sourceIndex}
                                    className={styles.productBox}
                                    style={{ borderRight: "none", width: pxtorem(311) }}>
                                    <RecommendMidConference
                                      conference={item}
                                      text="赚"
                                      goConferenceDetail={item => {
                                        goConference(item, sourceIndex, index);
                                      }}
                                    />
                                  </div>
                                );
                              default:
                                break;
                            }
                          })}
                        {item.preCardList?.length === 1 &&
                          item.preCardList.slice(0, 1).map((item: any, sourceIndex: number) => {
                            switch (item.cardType) {
                              case "product":
                                return (
                                  <div key={sourceIndex} className={styles.productBox}>
                                    <RecommendProduct
                                      noLive="true"
                                      product={item}
                                      titleDown="H5_MaterialList_ProductNameDown"
                                      goProduct={item => {
                                        goProduct(item, sourceIndex, index);
                                      }}
                                      addCart={item => {
                                        addCart(item, sourceIndex, index);
                                      }}
                                      goLive={item => {
                                        goLive(item, sourceIndex, index);
                                      }}
                                      stillBuy={item => {
                                        stillBuy(item, sourceIndex, index);
                                      }}
                                      remindMe={remindMe}
                                      text="赚"
                                    />
                                  </div>
                                );

                              case "activity":
                                return (
                                  <div key={sourceIndex} className={styles.productBox}>
                                    <RecommendNormalActivity
                                      activity={item}
                                      text="赚"
                                      brandDown="H5_MaterialList_ScheduleNameDown"
                                      goActivityDetail={item => {
                                        goActivity(item, sourceIndex, index);
                                      }}
                                    />
                                  </div>
                                );
                              case "conference":
                                return (
                                  <div key={sourceIndex} className={styles.productBox}>
                                    <ConferenceActivity
                                      conference={item}
                                      text="赚"
                                      goConferenceDetail={item => {
                                        goConference(item, sourceIndex, index);
                                      }}
                                    />
                                  </div>
                                );
                              default:
                                break;
                            }
                          })}
                        {item.preCardList?.length > 1 && <div className={styles.more}></div>}
                        {item.preCardList?.length > 1 && (
                          <div className={styles.font}>
                            <div className={styles.text} onClick={() => handleMore(item)}>
                              查看更多
                            </div>
                            <img
                              className={styles.img}
                              onClick={() => handleMore(item)}
                              src="https://akim-oss.aikucun.com/9ccc8ead5c3d16fd1d7feb1dcc6673a419c774b4_1702280768875_7.png"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {/* 素材文字内容 */}
                    <InfoContent materialText={item.materialText} />
                    {/* 互动点赞分享组件 */}
                    <Interaction
                      interactionData={item.interactionData}
                      materialBaseInfo={item.materialBaseInfo}
                    />
                  </div>
                </div>
                {index < materialData.length - 1 && <div className={styles.divide}></div>}
              </>
            );
          })}
        </div>

        {loading && (
          <Image src="https://akim-oss.aikucun.com/38a7939c7013f30368887b0efcc72800ebdd7236_1702611093104_77.png" />
        )}
        <InfiniteScroll loadMore={loadMore} hasMore={hasNext} threshold={100} />
        {/* 商品口袋 */}
        {showMoreCard && (
          <Popup
            visible={showMoreCard}
            onMaskClick={() => {
              setShowMoreCard(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: pxtorem(12),
              borderTopRightRadius: pxtorem(12),
              height: pxtorem(467),
            }}
            style={{
              "--z-index": "1100",
            }}
            maskStyle={{
              zIndex: "1100",
            }}>
            <PocketCom
              cartTotal={cartCount}
              close={() => setShowMoreCard(false)}
              goConference={(item, index, curTab) => {
                goConference(item, index, curTab);
              }}
              goActivity={(item, index, curTab) => {
                goActivity(item, index, curTab);
              }}
              goLive={(item, index, curTab) => {
                goLive(item, index, curTab);
              }}
              stillBuy={(item, index, curTab) => {
                stillBuy(item, index, curTab);
              }}
              goDetail={(item, index, curTab) => {
                goProduct(item, index, curTab);
              }}
              goCart={goCart}
              addCart={(item, index, curTab) => {
                addCart(item, index, curTab);
              }}
              activityList={activityList}
              scheduleList={scheduleList}
              productList={productList}
              conferenceList={conferenceList}
            />
          </Popup>
        )}
        {cartModalVisible && showNewCartPanel ? (
          <AddPurchasePanel
            trackData={{
              pageName: fromPage === "productDetail" ? "实拍专区-图文" : "种草详情",
              domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            }}
            visible={cartModalVisible}
            product={modalProduct}
            close={() => {
              setCartModalVisible(false);
              queryGetCartTotal();
            }}
          />
        ) : (
          <AddToCartModal
            pageType={fromPage === "productDetail" ? 3 : 2}
            visible={cartModalVisible}
            product={modalProduct}
            close={() => {
              setCartModalVisible(false);
              queryGetCartTotal();
            }}
          />
        )}
      </div>
    </>
  );
};

export default inject("appStore")(observer(PromoteDetail));
