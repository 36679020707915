import { platform } from "@/utils/platform";
import { getBaseurl } from "@/utils/url-parse";
import { API, HostID, Response } from "@akc/biz-api";
import { Request } from "../request";
import {
  AppOrderDirectReqBody,
  AppOrderDirectResponse,
  CreateOrderReqBody,
  OrderDetail,
} from "./model";

// App直购下单-post请求体
// https://yapi.akcops.com/project/1626/interface/api/266550
export const appOrderDirect = async (props: AppOrderDirectReqBody) => {
  const res = await Request<any, Response<AppOrderDirectResponse>>({
    url: `${process.env.REACT_APP_ZUUL_INFRA}/akucun-order-center/api/order/create/direct`,
    method: "post",
    data: props,
  });
  return res;
};

// 饷店创建订单-post请求体
// https://yapi.akcops.com/project/867/interface/api/201381
export const createOrder = async (props: CreateOrderReqBody) => {
  const res = await Request<any, Response<AppOrderDirectResponse>>({
    url: `${getBaseurl()}/api/order/create/v2`,
    method: "post",
    data: props,
  });
  return res;
};

// 请求订单详情
export const queryOrderDetail = async (params: { orderNo?: string | null }) => {
  const { isApp, isSaas, isMini } = platform;
  let url = `${process.env.REACT_APP_MICRO_APP_URL}/api/order/v2/detail`;
  if (isApp) {
    url = `${process.env.REACT_APP_ZUUL_INFRA}/akucun-order-center/api/order/detail`;
  } else if (isMini) {
    url = `${process.env.REACT_APP_PAGE_URL}/api/order/v2/detail`;
  } else if (isSaas) {
    url = `${process.env.REACT_APP_SAAS_URL}/api/order/saas/v2/detail`;
  }
  console.log("queryOrderDetail>>>", url, ",orderNo=", params?.orderNo);
  const res = await Request({
    url: url,
    method: "get",
    params: params,
  });
  return res?.data;
};
