import { queryNewCsWhitelist } from "@/api/service";
import { getCookie } from "./platform";
export const getCSBase = async params => {
  let base = "/cschat/";
  try {
    const res = await queryNewCsWhitelist({ ...params, token: getCookie("mstoken") });
    if (res?.data) {
      base = "/akc-cs";
    }
  } catch (e) {
    console.error("客服新框架灰度异常，跳转客服咨询老项目", e);
  }
  return base;
};
