import AKJS from "@akc/akjs";
import _ from "lodash";
import { platform } from "../platform";

const keyForUser = async (key: string) => {
  const userInfo = (await AKJS.info.account()).data;
  return `${userInfo?.userID}_${key}`;
};

const get = async (key: string, forUser?: boolean) => {
  if (!platform.isApp) {
    return;
  }
  const k = (forUser ?? true) ? await keyForUser(key) : key;
  const value = (
    await AKJS.action.dispatch("natKVStorageGet", {
      key: k,
    })
  ).data;
  // eslint-disable-next-line quotes
  if (_.isString(value) && value.startsWith('"') && value.endsWith('"')) {
    return value.substring(1, value.length - 1);
  }
  return value;
};

const set = async (key: string, value: any, forUser?: boolean) => {
  if (!platform.isApp) {
    return;
  }
  const k = (forUser ?? true) ? await keyForUser(key) : key;
  return AKJS.action.dispatch("natKVStorageSet", {
    key: k,
    value: value,
  });
};

export const kvStorage = {
  get,
  set,
};
