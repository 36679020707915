import { fetchStudyTourListInfo } from "@/api/youxue";
import { useEffect, useRef, useState } from "react";
import { StudyTourSignUpDayListInfo } from "../../model";

const useActivityList = (props?: {
  title?: string;
  categoryCode?: string;
  selectStudyIds?: string[];
}) => {
  const [list, setList] = useState<StudyTourSignUpDayListInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const brandLogos = useRef<string[]>([]);
  const total = useRef<number>(0);

  useEffect(() => {
    if (!props?.title) {
      return;
    }
    let abort = false;
    setLoading(true);
    const queryActivityList = () => {
      fetchStudyTourListInfo({
        month: props?.title,
        categoryCode: props?.categoryCode,
      })
        .then(res => {
          setLoading(false);
          if (abort) {
            console.log("Tab 切换 abort");
            return;
          }
          if (res === undefined) {
            return;
          }
          const newList = res.studyTourSignUpDayList;
          console.log("Tab 切换 abort");
          brandLogos.current = res.brandLogos;
          total.current = res.canSignUpCount;
          if ((props?.selectStudyIds?.length ?? 0) > 0) {
            newList?.map((i?: StudyTourSignUpDayListInfo) => {
              i?.studyTourSignUpList?.map(item => {
                const isSelect =
                  (props?.selectStudyIds?.findIndex(id => id === item.studyTourId) ?? -1) > -1;
                item.isSelect = isSelect;
              });
            });
          }
          setList(newList);
        })
        .catch(err => {
          setLoading(false);
          if (abort) {
            console.log("请求失败，终止");
          } else {
            console.log("error", err);
          }

          console.log("error", err);
        });
    };
    queryActivityList();
    return () => {
      abort = true;
      console.log("destory");
    };
  }, [props?.title, props?.categoryCode]);

  return {
    list,
    loading,
    setList,
    brandLogos,
    total,
  };
};

export default useActivityList;
