import { pxtorem } from "@/utils/pxtorem";
import { Image } from "antd-mobile";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
interface Props {
  //视频地址
  videoUrl: string;
  //封面地址
  thumbnail?: string;
  //宽
  height: number;
  //高
  width: number;
  //是否自动播放
  autoPlay?: boolean;
  //是否显示控制栏
  showControls?: boolean;
  //是否自定义播放控制按钮
  customPlayBtn?: boolean;
  //是否静音
  muted?: boolean;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
}

const VideoPlayComp: React.FC<Props> = props => {
  const paddingLeft = props?.paddingLeft ?? 0;
  const paddingRight = props?.paddingRight ?? 0;
  const rootRef = useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(props.muted ?? true);
  const [autoPlay, setAutoplay] = useState(props?.autoPlay ?? false);
  const [showControls, setShowControls] = useState(props?.showControls ?? true);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (!(props.customPlayBtn ?? false)) {
      return;
    }
    const videoRef = rootRef.current;
    // 监听元数据加载完成事件
    const handleLoadedMetadata = () => {
      if (videoRef) {
        setDuration(videoRef.duration);
      }
    };

    // 监听时间更新事件
    const handleTimeUpdate = () => {
      if (videoRef) {
        setCurrentTime(videoRef.currentTime);
      }
    };

    // 添加事件监听器
    if (videoRef) {
      videoRef.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoRef.addEventListener("timeupdate", handleTimeUpdate);

      // 清理函数，组件卸载时移除事件监听器
      return () => {
        videoRef.removeEventListener("loadedmetadata", handleLoadedMetadata);
        videoRef.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, []);

  const init = () => {
    if (rootRef.current) {
      const videoRef = rootRef.current;
      videoRef.addEventListener("ended", () => {
        videoRef.currentTime = 0;
        setPlaying(false);
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  // 视频控制bar
  const onTogglePlay = e => {
    e.stopPropagation();
    const videoRef = rootRef.current;
    if (videoRef) {
      setPlaying(videoRef.paused);
      videoRef.paused ? videoRef.play() : videoRef.pause();
    }
  };

  const setVideoSize = () => {
    const containerW = 375 - (paddingLeft ?? 0) - (paddingRight ?? 0);
    const videoW = props.width;
    const videoH = props.height;
    const styles: CSSProperties = {
      width: pxtorem(containerW),
      height: pxtorem(videoH * (containerW / videoW)),
    };
    return styles;
  };

  const formatTime = (second: number) => {
    const hour = Math.floor(second / 3600);
    const mins = Math.floor((second / 60) % 60);
    const secs = Math.floor(second % 60);
    return (
      (hour === 0 ? "" : (hour < 10 ? "0" + hour : hour) + ":") +
      (mins < 10 ? "0" + mins : mins) +
      ":" +
      (secs < 10 ? "0" + secs : secs)
    );
  };

  // 视频控件
  const renderVideo = () => {
    return (
      <video
        ref={rootRef}
        className={styles.video}
        style={setVideoSize()}
        src={props.videoUrl}
        controls={showControls}
        autoPlay={autoPlay}
        muted={muted}
        poster={props.thumbnail}
        disablePictureInPicture
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsInline={true}
        x5-playsinline="true">
        <source src={props.videoUrl} />
      </video>
    );
  };

  return (
    <div className={styles.videoPlayC}>
      {renderVideo()}
      {(props?.customPlayBtn ?? false) && (
        <div className={styles.pbc}>
          <div className={styles.playBtnC}>
            <Image
              className={styles.playBtn}
              src={
                playing
                  ? "https://akim-oss.aikucun.com/6280a5403f77e16f1dd305387763ae80963e6e32_1731555441504_26.png"
                  : "https://akim-oss.aikucun.com/e9c00f2bcd6e688dd053b1646331143921872a4d_1731555468397_42.png"
              }
              onClick={e => {
                onTogglePlay(e);
              }}
            />
            <div className={styles.time}>{formatTime(duration - currentTime)}</div>
          </div>
          <div
            className={styles.muted}
            onClick={e => {
              e.stopPropagation();
              setMuted(!muted);
            }}>
            <Image
              className={styles.mutedImg}
              src={
                muted
                  ? "https://akim-oss.aikucun.com/eba64aa2e02b7763f038dccb3e5ff554d5d36ffb_1731555949946_11.png"
                  : "https://akim-oss.aikucun.com/eba64aa2e02b7763f038dccb3e5ff554d5d36ffb_1731556019215_82.png"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayComp;
