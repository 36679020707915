import { visitorInfo } from "@/api/member";
import { NetworkError } from "@akc/biz-api";
import { DotLoading } from "antd-mobile";
import React, { useEffect, useState } from "react";
import Apply from "./components/apply";
import Process from "./components/process";

const Logout: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [accountCancelling, setAccountCancelling] = useState(true);

  const init = async () => {
    try {
      await visitorInfo();
    } catch (error) {
      if ((error as NetworkError)?.code === 100008) {
        setAccountCancelling(true);
      }
    }
    setIsLoading(false);
  };

  const applySuccess = () => {
    console.log("申请成功");
    init();
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading) {
    return <DotLoading />;
  }
  if (accountCancelling) {
    return <Process />;
  } else {
    return (
      <Apply
        applySuccess={() => {
          applySuccess();
        }}
      />
    );
  }
};
export default Logout;
