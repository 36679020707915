import { SearchParam } from "@/api/type";
import { ReactComponent as FilterIcon } from "@/assets/filter.svg";
import FilterBoard from "@/pages/activity/components/FilterBoard";
import { FilterOptionProvider } from "@/pages/activity/hooks/useFilterOption";
import { identity } from "@/utils/identity";
import { Popup } from "antd-mobile";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

type Props = {
  filterList?: any[];
  brandId?: string;
  promotionId?: string;
  categoryId?: string;
  reqActList?: (params) => void;
  sortChange?: (params) => void;
  filterBoardChange?: (params) => void;
  goScrollTop: () => void;

  ref?: ForwardedRef<any>;
};

const FilterList = forwardRef<any, Props>((props, ref) => {
  const filterListMock = identity()
    ? [
      {
        name: "综合",
        value: 1,
      },
      {
        name: "上新占比",
        value: 2,
        keys: [1, 2],
      },
      {
        name: "销量",
        value: 3,
        keys: [1, 2],
      },
    ]
    : [
      {
        name: "综合",
        value: 1,
      },
      {
        name: "上新占比",
        value: 2,
        keys: [1, 2],
      },
      {
        name: "销量",
        value: 3,
        keys: [1, 2],
      },
      {
        name: "转发量",
        value: 4,
        keys: [1, 2],
      },
    ];
  const {
    filterList = filterListMock,
    reqActList,
    sortChange,
    filterBoardChange,
    goScrollTop,
    brandId,
    promotionId,
    categoryId,
  } = props;
  const [search] = useSearchParams();
  const [sortModel, setSortModel] = useState(1);
  const [sortType, setSortType] = useState(2);
  const listContainer = useRef<HTMLDivElement>(null);
  const [filterT, setFilterT] = useState<any>([]);
  const merchantShopCode = search.get("merchantShopCode") ?? "";
  const [maskActive, setMaskActive] = useState(false);
  const [filterBoardVisible, setFilterBoardVisible] = useState(false);
  const [filterInfo, setFilterInfo] = useState<Partial<SearchParam>>();
  const filterBoardRef = useRef<any>(null);

  const nameMap = {
    1: "综合",
    3: "销量",
    4: "转发",
    9: "折扣",
  };

  const checkChildActive = children => {
    if (!children) {
      return false;
    }

    let res = false;
    children.map(item => {
      if (item.value === sortModel) {
        res = true;
      }
    });

    return res;
  };

  const closeMask = () => {
    document.getElementById("page")?.classList.remove("over-hidden");
    setMaskActive(false);
  };

  const arrAction = (value, index) => {
    goScrollTop();
    closeMask();

    if (!value) {
      if (filterT[index]) {
        filterT[index] = false;
      } else {
        filterT[index] = true;
        setMaskActive(true);
        document.getElementById("page")?.classList.add("over-hidden");
      }
      setFilterT([...filterT]);

      if (filterList[index].children) {
        if (checkChildActive(filterList[index].children)) {
          return;
        }
        setSortModel(filterList[index].children[0].value);
        setSortType(filterList[index].children[0].key);
      }

      return;
    }
    setFilterT([]);
    console.log(88888, value);
    if (sortModel === value && value !== 8) {
      if (sortType === 1) {
        setSortType(2);
      } else {
        setSortType(1);
      }
    } else {
      setSortModel(value);
      value === 8 ? setSortType(2) : setSortType(1);
    }
  };

  const maskClose = () => {
    closeMask();
    setFilterT([]);
  };

  const childAction = props => {
    const { value, type } = props;
    closeMask();
    if (value) {
      setSortModel(value);
    }

    if (type) {
      setSortType(type);
    }
    setFilterT([]);
  };

  const hasFilterActive = useMemo(() => {
    return (
      typeof filterInfo?.soldOutStatus !== "undefined" ||
      (filterInfo?.serviceTag ?? []).length > 0 ||
      (filterInfo?.categoryIdList ?? []).length > 0 ||
      (filterInfo?.brandIds ?? []).length > 0 ||
      (filterInfo?.promotionTypes ?? []).length > 0 ||
      (filterInfo?.guideProperties ?? []).length > 0 ||
      (filterInfo?.saleProperties ?? []).length > 0 ||
      (filterInfo?.activityStatus ?? []).length > 0 ||
      filterInfo?.priceHigh ||
      filterInfo?.priceLow
    );
  }, [filterInfo]);

  useEffect(() => {
    if (!sortModel) {
      return;
    }

    if (reqActList) {
      const param = {
        pageId: "H5_Shop",
        searchParams: {
          sortModel: sortModel,
          sortType: sortType,
          shopCodes: [merchantShopCode],
        },
      };
      if (brandId) {
        param.searchParams["brandIds"] = [brandId];
      }
      reqActList(param);
    }

    if (sortChange) {
      sortChange({ sortModel, sortType });
    }
  }, [sortModel, sortType]);

  useUnactivate(() => {
    setFilterBoardVisible(false);
  });

  useImperativeHandle(ref, () => {
    return {
      resetFilter: () => {
        filterBoardRef.current?.reset();
        setFilterInfo({});
      },
    };
  });

  const FilterToast = ({ list }) => {
    return (
      <>
        <div className={styles["filter-toast"]}>
          {list.map((item, index) => {
            const { name, value, key } = item;

            return (
              <div
                className={`${styles["filter-btn"]} ${sortModel === value ? styles["active"] : ""}`}
                key={index}
                onClick={() => {
                  childAction({ value: value, type: key });
                }}>
                {name}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles["filter-list"]} ref={listContainer}>
        {filterList.map((item, index) => {
          const { name, keys = [], value, children } = item;

          return (
            <div className={styles["filter-box"]} key={index}>
              <div
                className={`${styles["filter-name"]} ${sortModel === value || filterT[index] || checkChildActive(children)
                    ? styles["active"]
                    : ""
                  }`}
                onClick={() => arrAction(value, index)}>
                {checkChildActive(children) ? nameMap[sortModel] : name}
              </div>
              {keys.length > 1 ? (
                <div className={styles["filter-switchs"]} onClick={() => arrAction(value, index)}>
                  {value !== 8 && (
                    <div>
                      <div
                        className={`${styles["arr-top"]} ${sortModel === value && sortType === keys[0] ? styles["active"] : ""
                          }`}></div>
                      <div
                        className={`${styles["arr-bottom"]} ${sortModel === value && sortType === keys[1] ? styles["active"] : ""
                          }`}></div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              {children && (
                <div className={styles["filter-switchs"]} onClick={() => arrAction(value, index)}>
                  <div>
                    <div
                      className={`${styles["arr-bottom"]}  ${checkChildActive(children) ? styles["active"] : ""
                        }`}></div>
                  </div>
                </div>
              )}
              {children && filterT[index] && <FilterToast list={children} />}
            </div>
          );
        })}
        <div
          className={`${styles["filter-box"]} ${hasFilterActive ? styles["active"] : ""}`}
          style={{ alignItems: "center" }}
          onClick={() => setFilterBoardVisible(true)}>
          <div className={styles["filter-name"]}>筛选</div>
          <FilterIcon className={styles["filter-icon"]} />
        </div>
      </div>
      {maskActive && <div className={styles["toast-mask"]} onClick={maskClose}></div>}
      <FilterOptionProvider
        filterBoardVisible={filterBoardVisible}
        scene="MERCHANT_SHOP_DETAIL"
        pageId="H5_Shop"
        shopCodes={merchantShopCode ? [merchantShopCode] : undefined}
        brandIds={brandId ? [brandId] : undefined}
        promotionIdList={promotionId ? [promotionId] : undefined}
        categoryIdList={categoryId ? [categoryId] : undefined}>
        <Popup
          visible={filterBoardVisible}
          showCloseButton
          onMaskClick={() => {
            setFilterBoardVisible(false);
          }}
          onClose={() => {
            setFilterBoardVisible(false);
          }}
          bodyStyle={{ height: "90vh" }}>
          <FilterBoard
            ref={filterBoardRef}
            brandIds={brandId ? [brandId] : undefined}
            promotionIdList={promotionId ? [promotionId] : undefined}
            categoryIdList={categoryId ? [categoryId] : undefined}
            onFilterChange={params => {
              setFilterBoardVisible(false);
              setFilterInfo(params);
              filterBoardChange?.(params);
            }}
          />
        </Popup>
      </FilterOptionProvider>
    </>
  );
});

export default FilterList;
