import { queryAchievementDataList } from "@/api/videoShare";
import exposure from "@/utils/expose/exposure";
import { Skeleton, Swiper, SwiperRef } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import InvitedShareModal from "../../components/invitedShareModal";
import { UseVideoShareContext } from "../../hooks/useVideoShare";
import Achievement from "../achievement";
import LiveContent from "../live-content";
import LiveIntro from "../live-intro";

import styles from "./index.module.less";

const LIVING_EMPTY_IMG =
  "https://akim-oss.aikucun.com/66ad5a02fc4da3862775534989748aa25390f85e_1709200256864_96.png";
const LIVINGROOM_EMPTY_IMG =
  "https://akim-oss.aikucun.com/607a0afea67430ca07004df4be1c4aeecf4c1593_1709201599217_13.png";
const EMPTY_IMG =
  "https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1709115260446_59.png";
const ENDING_TITLE_IMG =
  "https://akim-oss.aikucun.com/3d20c80b0089cbaa10cf8cb11901e2958c2739cb_1709102044041_47.png";

const LiveList: React.FC = () => {
  const { anchorDataList, isOpenModal, setAnchorName } = UseVideoShareContext();
  const [swiperActiveIndex, setSwiperActiveIndex] = useState<number>(0);
  const [activityDataList, setActivityDataList] = useState<any[]>([]);
  const [changeLivingRoomLoading, setChangeLivingRoomLoading] = useState<boolean>(false);
  const [distributorCnt, setDistributorCnt] = useState<number>(0);
  const [endingLiveInfo, setEndingLiveInfo] = useState<any>({ list: [] });
  const [hasLivingRoom, setHasLivingRoom] = useState<boolean>(false);
  const swiperRef = useRef<SwiperRef>(null);
  const reqAchievementDataList = async (index: number) => {
    if (anchorDataList.length <= 0) {
      return;
    }
    const res = (await queryAchievementDataList({
      accountId: anchorDataList[index].anchorId,
      type: "LAST",
    })) as any;
    setEndingLiveInfo(res?.data?.liveList ? res?.data?.liveList[0] : {});
    setDistributorCnt(Number(res?.data?.distributorCnt) || 0);
  };
  const handleSwiperChange = (index: number) => {
    setSwiperActiveIndex(index);
    setActivityDataList(anchorDataList[index]?.activityDataList || []);
    setAnchorName(anchorDataList[index]?.anchorName);
    setChangeLivingRoomLoading(true);
    const isHasLivingRoom = anchorDataList[index]?.activityDataList.find(
      element => element.type === 1,
    )
      ? true
      : false;
    !isHasLivingRoom && reqAchievementDataList(index);
    setHasLivingRoom(isHasLivingRoom);
    setTimeout(() => {
      setChangeLivingRoomLoading(false);
    }, 500);
  };

  useEffect(() => {
    setActivityDataList(anchorDataList[0]?.activityDataList || []);
    setAnchorName(anchorDataList[0]?.anchorName);
    const isHasLivingRoom = anchorDataList[0]?.activityDataList.find(element => element.type === 1)
      ? true
      : false;
    !isHasLivingRoom && reqAchievementDataList(0);
    setHasLivingRoom(isHasLivingRoom);
  }, [anchorDataList]);

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < anchorDataList.length; i++) {
        const dom = document.getElementById(`channelItem-${i}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [anchorDataList?.length]);
  return (
    <>
      {anchorDataList.length > 0 && (
        <>
          {/* 多个直播间 */}
          {anchorDataList.length > 1 && (
            <Swiper
              trackOffset={10}
              slideSize={
                swiperActiveIndex === 0 || swiperActiveIndex === anchorDataList.length - 1 ? 87 : 85
              }
              defaultIndex={0}
              onIndexChange={handleSwiperChange}
              indicator={() => null}
              style={{
                paddingLeft:
                  swiperActiveIndex === 0 || swiperActiveIndex === anchorDataList.length - 1
                    ? "10px"
                    : "0",
                boxSizing: "border-box",
              }}
              ref={swiperRef}>
              {anchorDataList.map((item, index) => {
                return (
                  <Swiper.Item
                    key={index}
                    onClick={() => {
                      swiperRef?.current?.swipeTo(index);
                    }}>
                    <div
                      key={index}
                      id={`channelItem-${index}`}
                      data-param={JSON.stringify({
                        page_name: "分享员详情页",
                        resourceType: "视频号直播预览",
                        name: item.anchorName,
                        resourceRank: index + 1,
                        module: "绑定视频号列表",
                        domain: undefined,
                      })}
                      data-eventid={`channelItem-${index}`}>
                      <LiveIntro
                        item={item}
                        active={index === swiperActiveIndex}
                        isMultiple={true}
                      />
                    </div>
                  </Swiper.Item>
                );
              })}
            </Swiper>
          )}
          {/* 只有一个直播间 */}
          {anchorDataList.length === 1 && (
            <div className={styles["singular-intro"]}>
              <LiveIntro item={anchorDataList[0]} />
            </div>
          )}
          {/* 直播已结束的信息 */}
          {!hasLivingRoom && endingLiveInfo.list && (
            <div style={{ margin: "0 12px" }}>
              <Achievement
                achievementDataList={endingLiveInfo.list}
                distributorCnt={distributorCnt}
                lastUpdateTime={endingLiveInfo.lastUpdateTime}>
                <div className={styles["ending-header"]}>
                  <img src={ENDING_TITLE_IMG} className={styles["title"]} />
                  <div className={styles["time"]}>直播时间 {endingLiveInfo.liveRoomTime}</div>
                </div>
                <div className={styles["ending-desc"]}>{endingLiveInfo.liveRoomName}</div>
                {endingLiveInfo.list.length <= 0 && (
                  <div className={styles["empty__ending"]}>
                    <img src={EMPTY_IMG} className={styles["empty__img"]} />
                    <div className={styles["empty__text"]}>本场数据正在统计中，请稍后再来查看</div>
                  </div>
                )}
              </Achievement>
            </div>
          )}

          {/* 直播中或预告中的直播间信息 */}
          {activityDataList.length > 0 &&
            !changeLivingRoomLoading &&
            activityDataList.map((item, index) => {
              return (
                <LiveContent
                  key={index}
                  item={item}
                  index={index}
                  name={anchorDataList[index]?.anchorName}
                />
              );
            })}
          {/* 切换时股价瓶 */}
          {changeLivingRoomLoading && !endingLiveInfo.list && (
            <div className={styles["change-living-room-skeleton"]}>
              <Skeleton animated className={styles["skeleton"]} />
            </div>
          )}
          {/* 无直播间信息空状态 */}
          {activityDataList.length <= 0 && !changeLivingRoomLoading && !endingLiveInfo.list && (
            <img className={styles["empty"]} src={LIVING_EMPTY_IMG} />
          )}
        </>
      )}
      {/* 无直播间空状态 */}
      {anchorDataList.length <= 0 && !changeLivingRoomLoading && (
        <img className={styles["empty"]} src={LIVINGROOM_EMPTY_IMG} />
      )}
      {isOpenModal && <InvitedShareModal />}
    </>
  );
};
export default LiveList;
