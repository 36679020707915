import { getShop } from "@/api/decorate";
import SideBar from "@/components/sidebar";
import { default as useNavigation } from "@/utils/useNavigate";
import { Image, InfiniteScroll } from "antd-mobile";
import { inject, observer } from "mobx-react";
import { default as React, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ShopList.module.less";
// import ShopCard from "../components/shop_card/index";
import { getCategoryList, getTabList } from "@/api/market";
import goBack from "@/assets/go_back.png";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import { shareList } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import exposure from "@/utils/expose/exposure";
import { hostEnv, MICRO_APP_BASEURL, platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { getBaseurl } from "@/utils/url-parse";
import { ShopCardV2 } from "@akc/mx-taro-h5-components";
import axios from "axios";
import { getUserRole } from "../utils/identity";

interface Props {
  appStore?: any;
  [key: string]: any;
}

const ShopList: React.FC<Props> = () => {
  const { track } = useCommonContext();
  const navigater = useNavigate();
  const { navigate2Product, navigate2LiveRoom } = useNavigation();
  const [search] = useSearchParams();
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [list, setList] = useState([]);
  const shopId = search.get("shopId"); // R店铺ID
  const shopCode = search.get("relationTargetId"); // 商家店铺ID
  const categoryId = search.get("categoryId");
  const categoryName = search.get("categoryName");
  const [, setShopInfo] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<any>([]);
  const [categroyIndex, setCategroyIndex] = useState<any>(0);
  const categoriesModalHeight = useRef<any>(null);
  const [show, setShow] = useState(false);
  const [categoriesModalTranslateY, setCategoriesModalTranslateY] = useState(0);
  const [selectedCateMargin, setSelectedCateMargin] = useState("0.32rem");

  const getCookie = cname => {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  };

  const queryUser = async () => {
    await axios({
      method: "get",
      url: search.get("distributorId")
        ? `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${search.get(
            "shopId",
          )}?distributorId=${search.get("distributorId")}`
        : `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${search.get("shopId")}`,
      headers: {
        "app-login-channel": "xdWxH5",
        "auth-token": getCookie("mstoken"),
        "AKC-H5": "H5",
      },
      params: {},
    }).then(res => {
      localStorage.setItem("userinfo", JSON.stringify(res?.data?.data));
    });
  };

  //获取tab下的列表
  const queryTabList = async (pageNum, index, tabList) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const categoryId = tabList?.[index]?.id || categories?.[index]?.id || "";
    const res: any = await getTabList({
      pageId: "H5_Shop",
      cacheRequestId: shopCode,
      searchParams: {
        isNeedSpuIds: true,
        pageNum: pageNum,
        pageSize: 10,
        categoryId: `${categoryId}`,
      },
      voConfig: {
        withProduct: true,
        withProductCnt: 3,
      },
      storeIdsClick: sessionStorage.getItem("storeIdsClick") || "",
    });
    const allList: any = [];
    for (const item of res?.data?.result || []) {
      allList.push({
        ...item,
        categoryId,
        categoryName,
        searchRequestId: res?.data?.extra?.searchRequestId,
      });
    }
    setList(pageNum === 1 ? allList : list.concat(allList));
    setPageNum(res?.data?.pageIndex + 1);
    setHasMore(res?.data?.hasNext);
    setLoading(false);
    if (pageNum === 1 && categroyIndex === 0) {
      let str: any = [];

      str.push(allList?.[0]?.shopLogo);
      str = str.join(",");
      shareList(shopCode, str);
    }
  };

  //查询分类
  const queryTabs = async () => {
    const res = await getCategoryList({
      cacheRequestId: shopCode,
    });
    if (res) {
      const tabList = res?.data || [];
      setCategories(tabList);
      console.log("分类", res.data, categories);
      setTimeout(() => {
        console.log("分类弹框高度", document.getElementById("categories-in-modal")?.clientHeight);
        const tmp = document.getElementById("categories-in-modal")?.clientHeight || 0;
        categoriesModalHeight.current = tmp;
        // setCategoriesModalTranslateY(-tmp);
      }, 0);
      queryTabList(1, 0, tabList);
    }
  };

  const showTabs = () => {
    if (!show) {
      setShow(true);
      setCategoriesModalTranslateY(0);
    } else {
      setCategoriesModalTranslateY(-categoriesModalHeight.current);
      setCategoriesModalTranslateY(0);
      setShow(false);
    }
  };

  //切换tab
  const changeCategory = index => {
    if (index === categroyIndex) {
      return;
    }
    setCategroyIndex(index);
    setList([]);
    setPageNum(1);
    setHasMore(false);
    if (show) {
      showTabs();
    }

    window.scrollTo(0, 0);

    const categoryDom = document.getElementById(`category-${categories[index].id}`) as HTMLElement;
    categoryDom.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
    setSelectedCateMargin(`${categoryDom.offsetLeft}px`);

    queryTabList(1, index, []);
  };

  useEffect(() => {
    if (
      search.get("shopId") &&
      search.get("shopId") !== "undefined" &&
      search.get("shopId") !== "null"
    ) {
      queryUser();
    }
    //页面埋点
    /* eslint-disable */
    if (track.track) {
      track.track("$pageview", {
        page_name: "店铺推荐列表页",
        domain: "B2R旗舰店",
        previous_page_name: search.get("previous_page_name"),
        desc: shopCode,
      });
    }
    /* eslint-enable */
    document.title = "精选品牌";
    // if (!shopCode) return;
    // 请求店铺信息接口
    getShop({
      merchantShopCode: shopCode,
      needLicenseImg: true,
      needScore: true,
    }).then(res => {
      setShopInfo(res?.data || {});
    });
    queryTabs();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        const dom = document.getElementById(`market-${list[i]["shopCode"]}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [list?.length]);

  //加载更多
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {list?.length > 0 && (
              <>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
                <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
              </>
            )}
          </div>
        )}
      </>
    );
  };
  const loadMore = async () => {
    if (hasMore) {
      queryTabList(pageNum, categroyIndex, []);
    }
  };
  const onShopAction = (e, index?: any) => {
    console.log("点击店铺回调", e);
    if (index >= 0) {
      if (track.track) {
        track.track("resource_click", {
          page_name: "店铺推荐列表页",
          previous_page_name: search.get("page_name"),
          resource_type: "店铺大卡",
          resource_id: categoryId,
          resource_name: categoryName,
          resource_rank: index + 1,
          domain: "B2R旗舰店",
          module: "店铺列表",
          resource_content: "B2R旗舰店",
          merchant_shop_code: e.shopCode,
          recommend_requestid: e.searchRequestId,
          btn_name: "进入品牌",
          tab_name: categories[categroyIndex]?.classifyName || "",
        });
      }
    } else {
      if (track.track) {
        track.track("resource_click", {
          page_name: "店铺推荐列表页",
          previous_page_name: search.get("page_name"),
          domain: "B2R旗舰店",
          resource_content: "B2R旗舰店",
          module: "关注好店",
          merchant_shop_code: e.shopCode,
          btn_name: e.shopName,
          tab_name: categories[categroyIndex]?.classifyName || "",
        });
      }
    }
    if (search.get("distributorId")) {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&distributorId=${search.get(
          "distributorId",
        )}&shopId=${search.get("shopId")}&page_name=${encodeURIComponent("店铺聚合")}`,
      );
    } else {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&shopId=${search.get(
          "shopId",
        )}&page_name=${encodeURIComponent("店铺聚合")}`,
      );
    }
  };
  const onProductAction = (e, index) => {
    console.log("点击商品回调", e, index);
    const item = list[index];
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺推荐列表页",
        previous_page_name: search.get("page_name"),
        resource_type: "店铺大卡",
        resource_id: categoryId,
        resource_name: categoryName,
        resource_rank: index + 1,
        domain: "B2R旗舰店",
        module: "店铺列表",
        resource_content: "B2R旗舰店",
        merchant_shop_code: e.shopCode,
        recommend_requestid: item["searchRequestId"],
        btn_name: "商品点击",
        activity_spu_code: e.activitySpuId,
        activity_id: e.activityId,
        sku_id: e.selectSkuId,
        tab_name: categories[categroyIndex]?.classifyName || "",
      });
    }
    navigate2Product({
      activityId: e.activityId,
      activitySpuId: e.activitySpuId,
      selectSkuId: e.selectSkuId,
    });
  };
  const onStudyTourMaterialAction = e => {
    console.log("点击游学素材图片/视频回调", e);
  };
  const onLiveAction = (e, index) => {
    console.log("点击直播回调", e, index);
    const item = list[index];
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺推荐列表页",
        previous_page_name: search.get("page_name"),
        resource_type: "店铺大卡",
        resource_id: categoryId,
        resource_name: categoryName,
        resource_rank: index + 1,
        domain: "B2R旗舰店",
        module: "店铺列表",
        resource_content: "B2R旗舰店",
        merchant_shop_code: e.shopCode,
        recommend_requestid: item["searchRequestId"],
        btn_name: "直播点击",
        live_no: e.liveNo,
        tab_name: categories[categroyIndex]?.classifyName || "",
      });
    }
    navigate2LiveRoom({
      liveId: e.liveNo,
      liveStatus: e.liveStatus,
    });
  };
  const goBackOrHome = () => {
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺推荐列表页",
        previous_page_name: search.get("page_name"),
        resource_type: "店铺大卡",
        resource_id: categoryId,
        resource_name: categoryName,
        domain: "B2R旗舰店",
        module: "店铺列表",
        resource_content: "B2R旗舰店",
        btn_name: "返回",
        tab_name: categories[categroyIndex]?.classifyName || "",
      });
    }
    if (history.length > 1) {
      history.back();
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  const handleClick = (e: any) => {
    console.log(e?.target?.id);
    if (e?.target?.id !== "modal_content" && e?.target?.id !== "tadDiv") {
      if (show) {
        showTabs();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [show]);

  return (
    <div id="pages" className={styles.shop_list}>
      <img className={styles.bg_img} />

      {platform.isH5 && (
        <Image className={styles.go_back} src={goBack} onClick={() => goBackOrHome()} />
      )}

      {platform.isH5 && <SideBar />}

      <div className={styles.shop_header}>
        <img
          className={styles.header_icon_left}
          src="https://akim-oss.aikucun.com/mshop/0f9c59dc8f356bb53f340af885b02bb8be70c3ed_1700709311339_34.png"
        />
        <span className={styles.header_title}>精选品牌</span>
        <img
          className={styles.header_icon_right}
          src="https://akim-oss.aikucun.com/mshop/9315f3842dbebfd918d6f567aff82d6c55038d34_1700710051322_13.png"
        />
      </div>

      <div className={styles.tabs}>
        <div className={styles.tadDiv} id="tadDiv">
          <div className={styles.tabLine}>
            <div className={styles.tabTile}>
              {categories.map((item, index) => {
                return (
                  <div
                    className={`${styles.tab} ${index === categroyIndex ? styles.curTab : ""}`}
                    style={{
                      width: `${item.classifyName.length * 0.32}rem`,
                    }}
                    id={`category-${item.id}`}
                    key={index}
                    onClick={() => changeCategory(index)}>
                    <div className={styles.text}>{item.classifyName}</div>
                  </div>
                );
              })}
            </div>

            <div
              className={styles.selectedLineContainer}
              style={{
                width: `${(categories[categroyIndex]?.classifyName.length || 0) * 0.32}rem`,
                marginLeft: selectedCateMargin,
              }}>
              <div className={styles.selectedLine} />
            </div>
          </div>
        </div>

        {!show && (
          <img
            onClick={showTabs}
            className={styles.btn}
            id="modal_content"
            src="https://akim-oss.aikucun.com/92fa144482756e63338d20885c5d6a81ac635026_1698813644407_97.png"></img>
        )}

        {show && (
          <img
            onClick={showTabs}
            className={styles.btn}
            id="modal_content"
            src="https://akim-oss.aikucun.com/0cd05b13ecf15964616c170824bfc0efec07b6fb_1694775377718_66.png"></img>
        )}

        {categories.length > 0 && (
          <div className={styles.tabModal} style={{ visibility: `${show ? "visible" : "hidden"}` }}>
            <div
              className={styles.content}
              id="categories-in-modal"
              style={{
                transform: `translateY(${categoriesModalTranslateY}px)`,
                transition: "transform 0.3s",
              }}>
              {categories.map((item, index) => {
                return (
                  <div
                    key={index}
                    id="modal_content"
                    className={
                      styles.category +
                      " " +
                      (index === categroyIndex ? styles.category_selected : "")
                    }
                    onClick={() => changeCategory(index)}>
                    <div id="modal_content" className={styles.text}>
                      {item.classifyName}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {list?.length > 0 && (
        <div className={styles.list}>
          {list.map((item: any, index) => {
            return (
              <div
                id={`market-${item.shopCode}`}
                data-param={JSON.stringify({
                  page_name: "店铺推荐列表页",
                  resource_type: "店铺大卡",
                  resource_name: categoryName,
                  resource_id: categoryId,
                  resource_rank: index + 1,
                  module: "店铺列表",
                  resource_content: "B2R旗舰店",
                  domain: "B2R旗舰店",
                  merchant_shop_code: item?.shopCode,
                  recommend_requestid: item?.searchRequestId,
                  tab_name: categories[categroyIndex]?.classifyName || "",
                })}
                data-eventid={`market-${item?.shopCode}`}
                key={index}
                className={styles.item}>
                <ShopCardV2
                  nameFrontTagLocationCode={`${hostEnv}_Shop_ShopNameFront`}
                  mainLocationCode={`${hostEnv}_Shop_ShopNameDown`}
                  shopInfo={item}
                  index={index}
                  sign="shop-list"
                  onShopAction={e => onShopAction(e, index)}
                  onProductAction={e => onProductAction(e, index)}
                  onStudyTourMaterialAction={onStudyTourMaterialAction}
                  onLiveAction={e => onLiveAction(e, index)}
                  labelLocationVOList={item?.labelLocationVOList}
                  profitTag="赚"
                  role={getUserRole()}
                  terminal="h5"></ShopCardV2>
              </div>
            );
          })}
        </div>
      )}
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </div>
  );
};

export default inject("appStore")(observer(ShopList));
