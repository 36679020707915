import { ActivityTaskParams } from "@/api/rewardCenter";
import { pxtorem } from "@/utils/pxtorem";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface IProps extends Omit<React.AllHTMLAttributes<HTMLDivElement>, "onChange"> {
  tabs: {
    name: React.ReactNode;
    value: number | string;
    key: string;
    iconUrl?: string;
  }[];
  type?: "mini" | "";
  value?: ActivityTaskParams["searchType"] | ActivityTaskParams["taskWinningStatus"];
  onChange?: (value: ActivityTaskParams["searchType"]) => void;
}

const RewardTabs: React.FC<IProps> = ({ tabs, value, type, onChange, ...props }) => {
  //切换tab
  const changeTab = (val: ActivityTaskParams["searchType"]) => {
    onChange?.(val);
  };

  useEffect(() => {
    if (value === undefined) {
      onChange?.(tabs[0].value as ActivityTaskParams["searchType"]);
    }
  }, [value]);

  const transform = (index: number) => {
    console.log(type, value, tabs);
    if (type === "mini") {
      return `translateX(calc(${pxtorem((66 - 32) / 2)} + ${pxtorem(66)} * ${index}))`;
    }
    return `translateX(calc( 100vw / ${tabs.length} / 2 - ${pxtorem(16)} + 100vw / ${tabs.length} * ${index}))`;
  };

  return (
    <div className={styles.tabs + " " + (type === "mini" ? styles.mini : "")} {...props}>
      {tabs.map((item, index) => {
        return (
          <div
            className={styles.tab + " " + (value === item.value ? styles.active : "")}
            style={{ width: type === "mini" ? pxtorem(66) : "auto" }}
            key={index}
            onClick={() => changeTab(item.value as ActivityTaskParams["searchType"])}>
            {
              item.iconUrl ? <img
                style={{ width: pxtorem(32) }}
                src="https://akim-oss.aikucun.com/f970c786f5bee690e81c3e66db31e101175fded7_1713764369498_21.png"
              /> : item.name
            }
          </div>
        );
      })}
      <div
        className={styles.line}
        style={{
          width: pxtorem(32),
          transform: transform(tabs.findIndex(item => value === item.value) || 0),
        }}></div>
    </div>
  );
};
export default RewardTabs;
