import { getCurrentFormatTime } from "@/utils/date-format";
import { YouXueSignUpDetail } from "@akc/biz-api";
import React from "react";
import styles from "./index.module.less";

interface Props {
  detail?: YouXueSignUpDetail;
}

// 游学信息
const YouXueInfoComp: React.FC<Props> = props => (
  <div className={styles.comp}>
    <img className={styles.card__image} src={props.detail?.studyTourLogo} />
    <div className={styles.card__content}>
      <div className={styles.content__title}>
        <div className={styles.title__left}>{props.detail?.studyTourTitle}</div>
        {props.detail?.travelAllowance && (
          <div className={styles.title__right}>
            <div className={styles.right__title}>{props.detail?.travelAllowance}</div>
          </div>
        )}
      </div>
      <div className={styles.content__desc}>
        {`游学时间：${getCurrentFormatTime(parseInt(props.detail?.studyTourBeginTime || "0", 10))}-${getCurrentFormatTime(parseInt(props.detail?.studyTourEndTime || "0", 10))}`}
      </div>
    </div>
  </div>
);

export default YouXueInfoComp;
