import { searchTopListV2 } from "@/api/decorate";
import { SharePosterParams } from "@/api/scode/poster";
import buyNowPng from "@/assets/buy_now.png";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import moneyPng from "@/assets/money.png";
import nullPng from "@/assets/null.png";
import noToplistKill from "@/assets/nullAcitivity.png";
import scorePng from "@/assets/score.png";
import softArrow from "@/assets/soft-arrow.png";
import top1Png from "@/assets/top_1.png";
import top2Png from "@/assets/top_2.png";
import top3Png from "@/assets/top_3.png";
import yuPng from "@/assets/yu.png";
import loadingPng from "@/base64";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import SideBar from "@/components/sidebar";
import SizePopup from "@/components/size-popup";
import { ProductModel } from "@/models/product";
import { TopListRequestParams } from "@/types";
import { sharePosterParams4H5Product, shareProductPosterAction } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { formatImgUrl } from "@/utils/crop";
import { identity } from "@/utils/identity";
import { pxtorem } from "@/utils/pxtorem";
import { showSKUPreview } from "@/utils/select-color";
import useNavigation from "@/utils/useNavigate";
import { Image, InfiniteScroll } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import exposure from "../utils/expose/exposure";
import styles from "./TopList.module.less";
// import { identity } from "@/utils/identity";
interface Props {
  appStore?: any;
  [key: string]: any;
}

const SecKill: React.FC<Props> = ({ appStore }) => {
  const [search] = useSearchParams();
  const [dataInfo, setDataInfo] = useState<any>({ result: [] });
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [proIndex, setProIndex] = useState(0);
  // eslint-disable-next-line
  let [pageNum, setPageNum] = useState(1);
  const { navigate2Product } = useNavigation();
  const pageCode = search.get("customPageCode");
  const [activeIndex, setActiveIndex] = useState<any>(Number(search.get("activeIndex")));
  const { track } = useCommonContext();
  const [merchantShopCode] = useState<string | null>(search.get("merchantShopCode"));
  interface INavList {
    text: string;
    show: boolean;
    value: "ALL" | "SALE" | "PROFIT" | "FORWARDINGAMOUNT";
    imgText?: string;
    imgActiveText?: string;
  }
  const navList: INavList[] = [
    {
      show: true,
      text: "Zui热卖",
      value: "ALL",
      imgText:
        "https://akim-oss.aikucun.com/d0f708bfc3ff64e2e5a7dcb257c85cdc2a4c3a59_1704275811203_72.png",
      imgActiveText:
        "https://akim-oss.aikucun.com/a7cce6b641806a351cf55d4dc2ba549f9ae7d01d_1704276126648_57.png",
    },
    {
      show: identity() ? false : true,
      text: "Zui高佣",
      value: "PROFIT",
      imgText:
        "https://akim-oss.aikucun.com/6d4723488855ef4e9e55c1e7b5e820e7bd1e9313_1704277093330_46.png",
      imgActiveText:
        "https://akim-oss.aikucun.com/77b4f0062a9da23e62828d35860452f4371ac22a_1704277142686_82.png",
    },
    { text: "销量榜", value: "SALE", show: true },
    { text: "转发榜", value: "FORWARDINGAMOUNT", show: identity() ? false : true },
  ];
  // const pageIdMap = {
  //   h5: "H5_TopProduct",
  //   mini: "Mini_Program_TopProduct",
  // };

  const getPlatformFunc = () => {
    // eslint-disable-next-line no-underscore-dangle
    const isMini = window.__wxjs_environment === "miniprogram";
    const isApp = navigator && navigator.userAgent.search("AKC") > -1;
    const isSaas = isMini && !!location.href.match(/plt=saas/i);
    const isWxH5 = !isMini && navigator && !!navigator.userAgent.match(/miniProgram/i);
    const isH5 = isWxH5 || (!isMini && !isApp);
    const isTuan = isMini && location.href.match(/plt=atuan/i);
    return {
      isMini,
      isApp,
      isWxH5,
      isH5,
      isSaas,
      isBrowser: !isMini && !isApp && !isWxH5,
      isTuan,
    };
  };

  const platform = getPlatformFunc();
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
            <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
            <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
          </div>
        )}
      </>
    );
  };

  //获取秒杀
  const queryTopListV2 = async (index = 0, isScroll?: boolean) => {
    if (!isScroll) {
      setLoading(true);
    }
    const merchantShopCode = search.get("merchantShopCode");
    // const pageId = platform.isH5 ? pageIdMap["h5"] : pageIdMap["mini"];
    const pageId = "H5_TopProduct";
    // const pageId = search.get("customPageCode");
    const params: TopListRequestParams = {
      merShopCode: merchantShopCode || "",
      pageId: pageId,
      pageNum: pageNum,
      pageSize: 10,
      topListType: navList[index].value,
    };
    const { data } = await searchTopListV2(params);
    if (data?.result ?? false) {
      if (pageNum > 1) {
        data.result = dataInfo.result.concat(data.result ?? []);
      }
      setDataInfo({ ...data });
      setHasMore(data.hasNext ?? false);
      setActiveIndex(index);
      setPageNum(data.pageIndex + 1);
    } else {
      setHasMore(data?.hasNext ?? false);
      setDataInfo(dataInfo);
      // if (isChange) {
      //   Toast.show({
      //     content:
      //       index == 0 ? "暂无秒杀中的数据哦～" : "暂无即将开始的数据哦～",
      //   });
      // }
    }
    setLoading(false);
  };

  const loadMore = async () => {
    await queryTopListV2(activeIndex, true);
  };

  const changeIndex = index => {
    if (track.track) {
      track.track("tab_click", {
        page_name: "店铺-榜单",
        previous_page_name: "店铺首页",
        tab_name: navList[index].text,
        merchant_shop_code: search.get("merchantShopCode"),
      });
    }
    if (activeIndex !== index) {
      document.getElementById("swiper-container")?.scroll({ top: 0 });
      setHasMore(false);
      pageNum = 1;
      setPageNum(pageNum);
      setActiveIndex(index);
      dataInfo.result = [];
      queryTopListV2(index);
    }
  };

  useEffect(() => {
    if (platform.isMini) {
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (search.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      appStore.token = search.get("token");
    }
    //页面埋点
    /* eslint-disable */
    if (track.track) {
      track.track("$pageview", {
        page_name: "店铺-榜单",
        previous_page_name: "店铺首页",
        merchant_shop_code: search.get("merchantShopCode"),
      });
      track.track("tab_expose", {
        page_name: "店铺-榜单",
        tab_name: "综合榜单",
        rank: 1,
        previous_page_name: "店铺首页",
        merchant_shop_code: search.get("merchantShopCode"),
      });
      track.track("tab_expose", {
        page_name: "店铺-榜单",
        tab_name: "销量榜单",
        rank: 2,
        previous_page_name: "店铺首页",
        merchant_shop_code: search.get("merchantShopCode"),
      });
      if (!identity()) {
        track.track("tab_expose", {
          page_name: "店铺-榜单",
          tab_name: "佣金榜单",
          rank: 3,
          previous_page_name: "店铺首页",
          merchant_shop_code: search.get("merchantShopCode"),
        });
        track.track("tab_expose", {
          page_name: "店铺-榜单",
          tab_name: "转发榜单",
          rank: 4,
          previous_page_name: "店铺首页",
          merchant_shop_code: search.get("merchantShopCode"),
        });
      }
    }
    /* eslint-enable */
    queryTopListV2(activeIndex);
    document.title = identity() ? "大家都在买" : "大家都在卖";
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < dataInfo.result.length; i++) {
        const dom = document.getElementById(`${pageCode}-${activeIndex}-${i}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, [dataInfo?.result]);

  //转发商品
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);

  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>(
    {} as SharePosterParams,
  );
  const [isOTC, setIsOTC] = useState(false);
  const forwardProduct = (e: React.MouseEvent<HTMLDivElement>, item: any) => {
    e.stopPropagation();
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺-榜单详情页",
        resource_type: "商品列表",
        previous_page_name: "店铺首页",
        domain: "B2R旗舰店",
        btn_name: "转发",
        activity_spu_code: item.activitySpuId,
        sku_id: item.selectSkuId,
        merchant_shop_code: search.get("merchantShopCode"),
      });
    }
    if (!platform.isApp && !platform.isMini) {
      setSharePosterData(
        sharePosterParams4H5Product({
          pageCode: pageCode as string,
          activitySpuId: item.activitySpuId,
        }),
      );
      setCustomEditData({
        activitySpuId: item.activitySpuId,
        activityId: item.activityId,
        selectSkuId: item.selectSkuId,
      });
      setIsOTC(!!item?.skuExtendInfoVO?.isOTC);
      setShowPoster(true);
    } else {
      shareProductPosterAction({
        product: item,
        pageCode: pageCode as string,
      });
    }
  };
  const onClosePoster = () => {
    setShowPoster(false);
  };

  //跳转商详
  const goProduct = (item, index?: any, salePropertyFirstListPicItem?: any) => {
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺-榜单详情页",
        resource_type: "商品列表",
        previous_page_name: "店铺首页",
        domain: "B2R旗舰店",
        btn_name: "商品点击",
        activity_spu_code: item.activitySpuId,
        sku_id: item.selectSkuId,
        resource_rank: index + 1,
        resource_name: item.title,
        tab_name: navList[activeIndex].text,
        merchant_shop_code: search.get("merchantShopCode"),
      });
    }
    navigate2Product(
      {
        activityId: item.activityId,
        activitySpuId: item.activitySpuId,
        selectSkuId: item.selectSkuId,
        firstSalePropertyValue: salePropertyFirstListPicItem?.value,
      },
      {
        page_name: "店铺-榜单",
        previous_page_name: "店铺首页",
        resource_type: "商品列表",
        resource_name: item.title,
        tab_name: navList[activeIndex].text,
        activity_spu_code: item.activitySpuCode,
        sku_id: item.selectSkuId,
        btn_name: "商品点击",
        resource_rank: index + 1,
        merchant_shop_code: search.get("merchantShopCode"),
      },
    );
  };

  // 选色
  const [sizePopupProduct, setSizePopupProduct] = useState<ProductModel>({} as ProductModel);
  const [showSizePopup, setShowSizePopup] = useState(false);
  const skuPreview = (e: React.MouseEvent<HTMLDivElement>, item: any, index?: any) => {
    e.stopPropagation();
    const productInfo: ProductModel = {
      salePropertyFirstList: item.salePropertyFirstList,
      spuInfoVO: {
        sellOut: item.spuSellOut,
      },
      spuId: item.spuId,
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
    };
    showSKUPreview(productInfo).then(result => {
      if (result) {
        return;
      }
      setSizePopupProduct(productInfo);
      setShowSizePopup(true);
      setProIndex(index);
    });
  };
  const onCloseSizePopup = () => {
    setShowSizePopup(false);
  };

  //标签
  const formatLabel = (list, type) => {
    const arr: any = [];
    if (list) {
      for (const label of list) {
        if (label.locationCode === type && label?.labelInfoVOList?.length > 0) {
          for (const labelList of label.labelInfoVOList) {
            arr.push({
              type: labelList.contentType,
              value: labelList.labelIconUrl ? labelList.labelIconUrl : labelList.labelText,
              height: 16,
              width: labelList.labelIconWidth / (labelList.labelIconHeight / 16),
            });
          }
        }
      }
    }
    return arr;
  };
  return (
    <div className={styles.top_list} id="seckill">
      <div className={styles.top_padding}></div>
      <div className={styles.tab_bg}></div>
      {platform.isH5 && <SideBar />}
      <div className={styles.toplist} id="seckill2">
        <div>
          <div className={styles.nav}>
            {navList &&
              navList.length > 0 &&
              navList.map((nav, index) => {
                if (!nav.show) {
                  return null;
                }
                return (
                  <div
                    key={index}
                    className={styles.item + " " + (activeIndex === index ? styles.active : "")}
                    onClick={() => changeIndex(index)}>
                    {nav.value === "ALL" || nav.value === "PROFIT" ? (
                      <img
                        className={activeIndex === index ? styles.activeTabImg : styles.fontImg}
                        src={activeIndex === index ? nav.imgActiveText : nav.imgText}
                      />
                    ) : (
                      <div className={styles.font}>{nav.text}</div>
                    )}
                    {activeIndex === index && <div className={styles.line}></div>}
                  </div>
                );
              })}
          </div>
          <>
            {loading ? (
              <div className={styles.loading}>
                <Image src={loadingPng} width={pxtorem(30)}></Image>
              </div>
            ) : (
              <>
                {dataInfo.result?.length > 0 ? (
                  <div
                    style={{
                      zIndex: 99,
                      position: "relative",
                    }}
                    id="swiper-container"
                    className={styles["swiper-container"]}>
                    {dataInfo.result?.map((item, index) => {
                      return (
                        <div
                          className={styles.allCotent}
                          key={index}
                          id={`${pageCode}-${activeIndex}-${index}`}
                          data-param={JSON.stringify({
                            page_name: "店铺-榜单",
                            previous_page_name: "店铺首页",
                            resource_type: "商品列表",
                            resource_name: item.title,
                            type: "榜单二级页",
                            content: "榜单二级页",
                            name: item.title,
                            id: item.activityId,
                            picture: true,
                            hasSpecially: false,
                            activityId: item.activityId,
                            resource_rank: index + 1,
                            tab_name: navList[activeIndex]?.text,
                            merchant_shop_code: search.get("merchantShopCode"),
                          })}
                          data-eventid={pageCode + "-" + activeIndex + "-" + index}>
                          <div
                            className={styles.contentDiv}
                            style={{
                              backgroundColor: "rgba(255, 236, 223, 1)",
                              borderRadius: pxtorem(10),
                            }}>
                            <div
                              style={{
                                borderRadius: pxtorem(10),
                              }}
                              className={styles.contentOut}
                              onClick={() => goProduct(item, index)}>
                              <div className={styles.top}>
                                {/* {formatLabel(item.labelList, "TOP").map((label, labelIndex) => ())} */}
                                <div className={styles.top_level}>
                                  {index < 3 && (
                                    <div>
                                      {index === 0 && <img src={top1Png} alt="" />}
                                      {index === 1 && <img src={top2Png} alt="" />}
                                      {index === 2 && <img src={top3Png} alt="" />}
                                    </div>
                                  )}
                                </div>
                                <div className={styles.one1}>
                                  <div className={styles.img}>
                                    {item?.skuExtendInfoVO?.rewardCreditAmount > 0 && (
                                      <div className={styles.score}>
                                        <div className={styles.score_text}>
                                          至多积
                                          {item?.skuExtendInfoVO?.rewardCreditAmount}
                                        </div>
                                        <Image className={styles.img_score} src={scorePng}></Image>
                                      </div>
                                    )}
                                    <Image
                                      width="100%"
                                      height="100%"
                                      src={
                                        item?.skuMainImageUrls?.[0]
                                          ? formatImgUrl(item?.skuMainImageUrls?.[0], 120, 120)
                                          : nullPng
                                      }
                                      style={{ borderRadius: pxtorem(8) }}></Image>
                                    <div className={styles.save_money_content}>
                                      {item?.skuCurrentPriceInfo.commission > 0 && (
                                        <div className={styles.save_money}>
                                          {platform.isApp
                                            ? "省赚"
                                            : platform.isTuan
                                              ? "推广费"
                                              : platform.isMini && !platform.isSaas
                                                ? "饷"
                                                : "赚"}
                                          <span
                                            style={{
                                              paddingLeft: pxtorem(2),
                                            }}>
                                            ¥{item?.skuCurrentPriceInfo.commission}
                                          </span>
                                        </div>
                                      )}
                                      {item?.skuCurrentPriceInfo.commissionAddition > 0 && (
                                        <div className={styles.add}>
                                          +{item?.skuCurrentPriceInfo.commissionAddition}
                                        </div>
                                      )}
                                    </div>
                                    {formatLabel(
                                      item?.labelLocationVOList,
                                      // "H5_SuperMarketing_ProductStyleNo3_ProductPicRight"
                                      // "APP_Shop_DecorateSales_ColumnProductPicRight"
                                      "H5_TopProduct_ProductList_PicRight",
                                      // "APP_Shop_DecorateSales_ColumnProductNameDown"
                                    ).map((label, labelIndex) => {
                                      if (label.type === "image") {
                                        return (
                                          <Image
                                            key={labelIndex}
                                            src={label.value}
                                            style={{
                                              height: pxtorem(40),
                                              width: pxtorem(40),
                                              position: "absolute",
                                              top: 0,
                                              right: 0,
                                            }}
                                            fit="contain"></Image>
                                        );
                                      }
                                      if (label.type === "text") {
                                        return (
                                          <div
                                            key={labelIndex}
                                            style={{
                                              height: pxtorem(16),
                                              position: "absolute",
                                              top: 0,
                                              right: 0,
                                            }}>
                                            {label.value}
                                          </div>
                                        );
                                      }
                                    })}
                                    {item?.spuSellOut && (
                                      <div className={styles.sell_out}>
                                        <div className={styles.sell_out_circle}>已售罄</div>
                                      </div>
                                    )}
                                  </div>
                                  <div className={styles.content}>
                                    <div className={styles.content_header}>
                                      <div className={styles.label}>
                                        {formatLabel(
                                          item?.labelLocationVOList,
                                          "H5_TopProduct_ProductList_PicRightTitlefront",
                                          // "APP_Shop_DecorateSales_ColumnProductNameFront"
                                        ).map((label, labelIndex) => {
                                          if (label.type === "image") {
                                            return (
                                              <Image
                                                key={labelIndex}
                                                src={label.value}
                                                className={styles.label_img}
                                                style={{
                                                  width: pxtorem(label.width),
                                                  position: "absolute",
                                                }}
                                                fit="contain"></Image>
                                            );
                                          }
                                          if (label.type === "text") {
                                            return (
                                              <div
                                                key={labelIndex}
                                                style={{
                                                  paddingRight: pxtorem(4),
                                                }}>
                                                {label.value}
                                              </div>
                                            );
                                          }
                                        })}
                                        <div
                                          className={styles.name}
                                          style={{
                                            textIndent:
                                              formatLabel(
                                                item?.labelLocationVOList,
                                                "APP_Shop_DecorateSales_ColumnProductNameFront",
                                              )?.length > 0
                                                ? pxtorem(
                                                    formatLabel(
                                                      item?.labelLocationVOList,
                                                      "APP_Shop_DecorateSales_ColumnProductNameFront",
                                                    )[0]?.width + 1 || 33,
                                                  )
                                                : 0,
                                          }}>
                                          {item?.title}
                                        </div>
                                        {new Date().valueOf() <
                                          item?.skuCurrentPriceInfo?.promotionStartTime && (
                                          <Image className={styles.label_yu} src={yuPng}></Image>
                                        )}
                                      </div>
                                      {formatLabel(
                                        item?.labelLocationVOList,
                                        "H5_TopProduct_ProductList_PicRightTitleDown",
                                      )?.length > 0 && (
                                        <div className={styles.discount_label}>
                                          {formatLabel(
                                            item?.labelLocationVOList,
                                            "H5_TopProduct_ProductList_PicRightTitleDown",
                                          ).map((label, labelIndex) => {
                                            if (label.type === "image") {
                                              return (
                                                <Image
                                                  key={labelIndex}
                                                  src={label.value}
                                                  style={{
                                                    height: pxtorem(16),
                                                    width: pxtorem(label.width),
                                                    paddingRight: pxtorem(4),
                                                    flexShrink: 0,
                                                  }}></Image>
                                              );
                                            }
                                            if (label.type === "text") {
                                              return (
                                                <div
                                                  key={labelIndex}
                                                  style={{
                                                    paddingRight: pxtorem(4),
                                                  }}>
                                                  {label.value}
                                                </div>
                                              );
                                            }
                                          })}
                                        </div>
                                      )}
                                      {item?.statisticsInfo?.topDescVOList?.[0]?.desc && (
                                        <div className={styles.stasticsInfo_label}>
                                          {item?.statisticsInfo?.topDescVOList?.[0]?.desc}
                                          <Image src={softArrow}></Image>
                                        </div>
                                      )}
                                    </div>
                                    <div className={styles.content_content}>
                                      <div
                                        className={styles.style_list}
                                        onClick={e => skuPreview(e, item, index)}>
                                        {[
                                          ...((item?.salePropertyFirstList &&
                                            item?.salePropertyFirstList) ||
                                            []),
                                        ]
                                          .splice(0, 4)
                                          .map((style, styleIndex) => {
                                            return (
                                              <div
                                                className={styles.style_list_item}
                                                key={styleIndex}>
                                                <Image
                                                  className={styles.item_img}
                                                  src={style.picList?.[0] || nullPng}
                                                  style={{
                                                    opacity: style.sellOut ? "0.1" : "1",
                                                  }}></Image>
                                                {style.sellOut && (
                                                  <div className={styles.item_sell_out}>售罄</div>
                                                )}
                                              </div>
                                            );
                                          })}
                                        <div className={styles.style_list_item}>
                                          <div className={styles.item_font}>
                                            <div>{item?.salePropertyFirstList?.length}款</div>
                                            <div>可选</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.price_btn}>
                                        <div className={styles.price}>
                                          {(item?.skuCurrentPriceInfo.promotionType === "SECKILL" ||
                                            item?.skuCurrentPriceInfo.promotionType ===
                                              "FIXED_PRICE") && (
                                            <div className={styles.price_text}>
                                              {item?.skuCurrentPriceInfo.promotionTypeName}
                                            </div>
                                          )}
                                          <div className={styles.price_real}>
                                            <div>¥</div>
                                            <div className={styles.price_big}>
                                              {Math.trunc(item?.skuCurrentPriceInfo.marketingPrice)}
                                            </div>
                                            <div className={styles.decimal}>
                                              .
                                              {Math.round(
                                                (item?.skuCurrentPriceInfo.marketingPrice % 1) * 10,
                                              )}
                                            </div>
                                          </div>
                                          {item?.skuCurrentPriceInfo.tagPrice > 0 && (
                                            <div className={styles.price_old}>
                                              ¥
                                              <span
                                                style={{
                                                  paddingLeft: pxtorem(2),
                                                }}>
                                                {item?.skuCurrentPriceInfo.tagPrice}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        {!identity() && (
                                          <div
                                            className={styles.btn}
                                            onClick={e => forwardProduct(e, item)}>
                                            <Image
                                              className={styles.btn_img}
                                              src={moneyPng}></Image>
                                            <div className={styles.btn_font}>转发赚钱</div>
                                          </div>
                                        )}
                                        {identity() && (
                                          <div
                                            className={styles.buy_btn}
                                            onClick={() => goProduct(item, index)}>
                                            <Image
                                              className={styles.btn_img}
                                              src={buyNowPng}></Image>
                                            <div className={styles.buy_text}>立即购买</div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={20}>
                      <InfiniteScrollContent hasMore={hasMore} />
                    </InfiniteScroll>
                  </div>
                ) : (
                  <div className={styles.nullStatus}>
                    <Image className={styles.noSecKill} src={noToplistKill}></Image>
                    <div className={styles.text}>
                      暂无
                      {activeIndex > 1
                        ? navList[activeIndex].text + "单"
                        : navList[activeIndex].text + "榜单"}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>

      {/* 海报 */}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        isOTC={isOTC}
        shareType="PRODUCT"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}></PosterSwipe>
      {/* 选色卡 */}
      <SizePopup
        product={sizePopupProduct}
        show={showSizePopup}
        close={onCloseSizePopup}
        toDetailByFirstProp={(product, salePropertyFirstListPicItem) =>
          goProduct(product, proIndex, salePropertyFirstListPicItem)
        }
      />
    </div>
  );
};

export default inject("appStore")(observer(SecKill));
