import { searchCouponV2 } from "@/api/decorate";
import { SharePosterParams } from "@/api/scode/poster";
import goBack from "@/assets/go_back.png";
import noSecKill from "@/assets/no_seckill.png";
import Coupon11Comp from "@/components/coupon/11Sec/index";
import PosterSwipe from "@/components/poster-swiper";
import SideBar from "@/components/sidebar";
import { sharePosterParams4H5Coupon } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { Image } from "antd-mobile";
import { inject, observer } from "mobx-react";
import { default as React, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./CouponList.module.less";
interface Props {
  appStore?: any;
  [key: string]: any;
}

const CouponList: React.FC<Props> = ({ appStore }) => {
  const navigater = useNavigate();
  const [search] = useSearchParams();
  const baseUrl = "";
  const shopId = search.get("shopId");
  const pageCode = search.get("customPageCode");
  const merchantShopCode = search.get("merchantShopCode");
  const [couponList, setCouponList] = useState<any>([]);
  const { track } = useCommonContext();

  const goBackOrHome = () => {
    if (history.length > 1) {
      history.back();
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${baseUrl}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  const queryCoupon = async () => {
    const res = await searchCouponV2({
      merShopCode: merchantShopCode,
      pageId: "H5_Shop",
    });
    setCouponList(res?.data || []);
  };

  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  const sharePosterData = useRef<SharePosterParams>();
  const onClosePoster = () => {
    sharePosterData.current = undefined;
    setShowPoster(false);
  };

  // 已领取的优惠券记录
  const [fetchedCouponIds, setFetchedCouponIds] = useState<string[]>([]);

  const share4H5Action = coupon => {
    sharePosterData.current = sharePosterParams4H5Coupon({
      pageCode: pageCode as string,
      voucherNo: coupon.voucherNo,
    });
    setShowPoster(true);
  };
  const fetchSuccessAction = coupon => {
    const key = `${coupon.awdId}_${coupon.promoActivityId}`;
    setFetchedCouponIds([...fetchedCouponIds, key]);
  };

  /**
   * 优惠券卡片-一行一
   */
  const couponBigCard = item => {
    return (
      <Coupon11Comp
        coupon={item}
        pageData={{
          pageCode: search.get("customPageCode"),
          name: search.get("page_name"),
          merchantShopCode: search.get("merchantShopCode"),
        }}
        share4H5Action={() => share4H5Action(item)}
        fetchSuccessAction={() => fetchSuccessAction(item)}
        trackInfo={{
          page_name: "店铺-优惠券列表",
          previous_page_name: "店铺首页",
          merchant_shop_code: search.get("merchantShopCode"),
          domain: "B2R旗舰店",
          resource_type: "COUPON",
          coupon_type: "COUPON",
          coupons_id: item.awdId,
          coupon_name: item.dcTemplateVoucherName,
          btn_name: "立即领取",
          is_manual: 1,
          coupon_amount: item.quotaAmount,
        }}
      />
    );
  };

  useEffect(() => {
    if (platform.isMini) {
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (search.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      appStore.token = search.get("token");
    }
    //页面埋点
    /* eslint-disable */
    if (track.track) {
      track.track("$pageview", {
        page_name: "店铺-优惠券列表",
        previous_page_name: "店铺首页",
        merchant_shop_code: search.get("merchantShopCode"),
      });
    }
    /* eslint-enable */
    document.title = "优惠券";
    queryCoupon();
  }, []);

  return (
    <>
      <div
        className={styles.coupon_list}
        style={{
          backgroundColor: "#F1F2F5",
        }}>
        {(platform.isH5 || platform.isWxH5) && (
          <Image className={styles.go_back} src={goBack} onClick={() => goBackOrHome()} />
        )}
        {platform.isH5 && <SideBar />}
        {couponList.length > 0 ? (
          <div className={styles.one1} style={{ margin: pxtorem(12) }}>
            {couponList?.map((item, index) => {
              return <div key={index}>{couponBigCard(item)}</div>;
            })}
          </div>
        ) : (
          <div className={styles.nullStatus}>
            <Image className={styles.empty_img} src={noSecKill} />
            <div className={styles.text}>暂无优惠券</div>
          </div>
        )}
      </div>
      {/* 海报 */}
      {sharePosterData.current && (
        <PosterSwipe
          show={showPoster}
          posterData={sharePosterData.current}
          shareType="COUPON"
          btnConfig={["copy", "edit"]}
          close={onClosePoster}
        />
      )}
    </>
  );
};

export default inject("appStore")(observer(CouponList));
