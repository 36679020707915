import useNavigation from "@/utils/useNavigate";
import { Popup } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";

import ARROW_RIGHT from "@/assets/arrow_right@3.png";
import ICON_CLOSE from "@/assets/icon_close_gray.png";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

const AVATAR_URL =
  "https://akim-oss.aikucun.com/25b92ed62e9f188307a0284671df91e9678571cc_1702637476577_65.png";

interface IProps {
  item: any;
  active?: boolean;
  isMultiple?: boolean;
}
const LiveIntro: React.FC<IProps> = ({ item, active = false, isMultiple = false }) => {
  const [search] = useSearchParams();
  const contentRef = useRef<HTMLDivElement>(null);
  const [showAllVisible, setShowAllVisible] = useState<boolean>(false);
  const [showAllBtnVisible, setShowAllBtnVisible] = useState<boolean>(false);
  const { navigate2H5 } = useNavigation();

  const setShowAllBtn = () => {
    console.log("content-height", item.anchorName, contentRef.current?.clientHeight);
    const contentHeight = contentRef?.current?.clientHeight || 0;
    if (contentHeight > 40) {
      setShowAllBtnVisible(true);
    } else {
      setShowAllBtnVisible(false);
    }
  };
  const goHistory = () => {
    navigate2H5({
      path: "/mx-shop-micro/video-share/historyData",
      query: `anchorId=${item.anchorId}&token=${search.get("token")}&currrentRole=${search.get(
        "currrentRole",
      )}`,
    });
  };
  useEffect(() => {
    setShowAllBtn();
  }, []);

  return (
    <>
      <div className={`${styles["live-intro"]}  ${isMultiple && styles["live-intro-multiple"]}`}>
        {active && isMultiple && (
          <img
            src="https://akim-oss.aikucun.com/799d7482d017dc08abe69cb690edb52c7486f56d_1709121675197_44.png"
            alt=""
            className={styles["active_bg_img"]}
          />
        )}
        <div className={styles["live-intro-top"]}>
          <img
            className={styles["intro__avatar"]}
            src={item.anchorAvatar || AVATAR_URL}
            alt="头像"
          />
          <div className={styles["intro__name"]}>{item.anchorName}</div>
          <div className={styles["intro__link2history"]}>
            <span className={styles["history__text"]} onClick={goHistory}>
              历史直播数据
            </span>

            <img className={styles["intro__arrow__icon"]} src={ARROW_RIGHT} alt="" />
          </div>
        </div>
        <div className={styles["live-intro-bottom"]}>
          <div ref={contentRef}>
            {showAllBtnVisible && (
              <div onClick={() => setShowAllVisible(true)} className={styles["intro__showAll"]}>
                查看全部
              </div>
            )}
            {item.anchorDesc}
          </div>
        </div>
      </div>
      <Popup
        visible={showAllVisible}
        onMaskClick={() => {
          setShowAllVisible(false);
        }}
        onClose={() => {
          setShowAllVisible(false);
        }}
        bodyStyle={{
          height: "250px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          zIndex: 10000,
        }}>
        <div className={styles["popup-wapper"]}>
          <div className={styles["popup-header"]}>
            <div className={styles["popup__title"]}>{item.anchorName}</div>
            <img
              className={styles["popup__close"]}
              src={ICON_CLOSE}
              onClick={() => setShowAllVisible(false)}
            />
          </div>
          <div className={styles["popup-content"]}>
            这里展示这个直播间的总体描述这里展示这个直播间的总体描述这里展示这个直播间的总体描述这里展示这个直播间的总体描述这里展示这个直播间的总体描述
          </div>
        </div>
      </Popup>
    </>
  );
};
export default LiveIntro;
