import { AddressListModel } from "@/pages/product-detail/model/AddressListModel";
import React from "react";
import styles from "./index.module.less";

type Props = {
  addressId?: string;
  addressList?: AddressListModel[];
  onAddressItemClick?: (addressId?: string, index?: number) => void;
};

/**
 * 地址列表组件
 */
const AddressListComp: React.FC<Props> = props => {
  const addressList = props.addressList;
  return addressList?.map((item, index) => {
    return (
      <div
        className={styles.addressItem}
        key={index}
        onClick={() => {
          props.onAddressItemClick?.(item?.id, index);
        }}>
        <div className={styles.left}>
          <div className={styles.concats}>
            <div className={styles.name}>{item.contact}</div>
            <div className={styles.tel}>{item.phone}</div>
            {item.isDefault === 1 && <div className={styles.default}>默认</div>}
            {(item.addressLabel?.length ?? 0) > 0 && (
              <div className={styles.company}>{item.addressLabel}</div>
            )}
          </div>
          <div className={styles.loacation}>
            {item.province} {item.city} {item.area} {item.street}
          </div>
        </div>
        <div className={styles.right}>
          <div
            className={`${props.addressId === item.id ? styles["checkbox-active"] : styles["checkbox-inactive"]}`}
          />
        </div>
      </div>
    );
  });
};

export default AddressListComp;
