import React from "react";
import FollowShopAssistant from "./components/FollowShopAssistant";
import GoodRecommendations from "./components/GoodRecommendations";
import ShareCoupon from "./components/ShareCoupon";
import { useAfterPay } from "./constrol";
import styles from "./index.module.less"; // 引入 CSS Modules

/**
 * 商家小票页面
 *
 * 因嵌入在微信的 iframe 中，无法使用 cookie，故此页面逻辑不鉴权
 *
 * 1. 通过交易号拿到订单信息及加密后的 userId、shopId、distributorId
 * 2. 调用后面的接口查询分享券、关注助手、推荐商品
 */
const AfterPay = (): React.ReactElement => {
  const control = useAfterPay();

  return (
    <div className={styles["after-pay"]}>
      <div className={styles.orderSuccess}>
        <div className={styles.successHeader}>
          <div className={styles.successText}>
            <img
              src="https://akim-oss.aikucun.com/53a5687cb26dc41f2ab4033e97e13adefd3740d6_1731896927663_48.png"
              alt="Success"
              className={styles.successIcon}
            />
            下单成功
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <button className={`${styles.btn} ${styles.btnHome}`} onClick={control.handleGoHome}>
            逛逛首页
          </button>
          <button className={`${styles.btn} ${styles.btnOrder}`} onClick={control.handleLookOrder}>
            查看订单
          </button>
        </div>
        <div className={styles.reminder}>
          温馨提示：小店不会以快递丢失/商品质量等为由，向您索取银行卡信息或手机验证码，切勿向他人透露。
        </div>

        {/* 分享券 */}
        {control.shareCouponVisible && (
          <ShareCoupon
            shareObj={control.shareObj}
            handleJumpOut2Coupon={control.handleJumpOut2Coupon}
          />
        )}
        {/* 关注助手 */}
        {control.subscribeVisible && <FollowShopAssistant link={control.subscribeRes?.showLink} />}
        {/* 商品推荐 */}
        {/* followUsVisible, shareCouponVisible 都为ture时 不显示商品推荐 */}
        {undefined !== control.shareCouponVisible &&
          undefined !== control.subscribeVisible &&
          !(control.shareCouponVisible && control.subscribeVisible) && (
            <GoodRecommendations
              orderDetail={control.orderDetail}
              visibleCount={control.recommandProductListVisibleCount}
              jumpOut2Product={control.jumpOut2Product}
            />
          )}
      </div>
    </div>
  );
};

export default AfterPay;
