import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import { hostEnv } from "@/utils/platform";
import { Image, InfiniteScroll } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./market.module.less";

import { pxtorem } from "@/utils/pxtorem";
import { storeRem } from "@/utils/rem";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useNavigate } from "@/utils/navigate/navigate";
import { focusList, getCategoryList, getDarkWord, getTabList } from "@/api/market";
import loadingPng from "@/base64";
import RedPacketNotice from "@/components/red-packet-notice";
import { shareMarket } from "@/utils/actions";
import exposure from "@/utils/expose/exposure";
import Track from "@/utils/track";
import { getBaseurl } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import {
  ShopCardV2,
  FavoriteShopAggregationComp as StoreFocusAgg,
} from "@akc/mx-taro-h5-components";
import axios from "axios";
import { throttle } from "lodash";
import { useActivate, useAliveController, useUnactivate } from "react-activation";
import { getUserRole } from "../utils/identity";
interface Props {
  appStore?: any;
  [key: string]: any;
}

// let categoriesModalHeight: any = null;
const Home: React.FC<Props> = () => {
  const track = Track();
  const [queryLoading, setQueryLoading] = useState(true);
  const [search] = useSearchParams();
  const { navigate2Product, navigate2LiveRoom } = useNavigation();
  const shopId = search.get("shopId");
  const navigater = useNavigate();
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [categories, setCategories] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [categroyIndex, setCategroyIndex] = useState<any>(0);
  const indexRef = useRef(1);
  const [, setLoading] = useState(true);
  const categoriesModalHeight = useRef<any>(null);
  const [str, setStr] = useState("");

  const [focusInfo, setFocusInfo] = useState<any>({
    shopInfos: [],
  });
  const { getCachingNodes } = useAliveController();

  const [selectedCateMargin, setSelectedCateMargin] = useState("0.32rem");
  const [darkList, setDarkList] = useState<any[]>([{ searchWords: "" }]);
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time + 1);
      if (time >= darkList?.length - 2 && time > 0) {
        setDarkList(darkList.concat(darkList));
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const queryDarkWord = async () => {
    const res = await getDarkWord({
      shopBizCode: search.get("shopId"),
      scene: "SHOP_SEARCH",
    });
    setDarkList(res?.data?.darkList || [{ searchWords: "" }]);
  };

  //获取tab下的列表
  const queryTabList = async (pageNum, index, tabList) => {
    if (queryLoading && indexRef.current > 1) {
      return;
    }
    indexRef.current++;
    setLoading(false);
    setQueryLoading(true);
    const categoryId = tabList?.[index]?.id || categories?.[index]?.id;
    const res = await getTabList({
      pageId: "H5_Shop",
      searchParams: {
        isNeedSpuIds: true,
        pageNum: pageNum,
        pageSize: 10,
        categoryId,
      },
      voConfig: {
        withProduct: true,
        withProductCnt: 3,
      },
    });
    let list = res?.data.result || [];
    if (pageNum > 1 && cardList.length > 0) {
      list = cardList.concat(list);
    }
    setCardList(list);
    setHasMore(res?.data?.hasNext ?? false);
    setPageNum(pageNum + 1);
    setQueryLoading(false);
    setTimeout(() => {
      setLoading(false);
      if (sticky && pageNum <= 1) {
        const pageDom: any = document.getElementById("pages");
        const tabDom: any = document.getElementById("tadDiv");
        const searchDiv: any = document.getElementById("searchDiv");
        const height =
          focusInfo?.shopInfos?.length > 0
            ? searchDiv.clientHeight + 2 * tabDom.clientHeight + 2
            : 0;
        pageDom.scrollTo(0, height);
      }
    }, 100);
    if (pageNum === 1 && index === 0) {
      let stri: any = [];
      stri.push(list[0]?.shopLogo);
      stri = stri.join(",");
      setStr(stri);
      shareMarket(stri);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < cardList.length; i++) {
        const dom = document.getElementById(`market-${cardList[i]["shopCode"]}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [cardList?.length]);

  const [categoriesModalTranslateY, setCategoriesModalTranslateY] = useState(0);

  //查询分类
  const queryTabs = async () => {
    const res = await getCategoryList({});
    if (res) {
      const tabList = res?.data || [];
      setCategories(tabList);
      console.log("分类", res.data);
      setTimeout(() => {
        console.log("分类弹框高度", document.getElementById("categories-in-modal")?.clientHeight);
        const tmp = document.getElementById("categories-in-modal")?.clientHeight || 0;
        categoriesModalHeight.current = tmp;
        // setCategoriesModalTranslateY(-tmp);
      }, 0);
      queryTabList(1, 0, tabList);
    }
  };

  const getCookie = cname => {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  };

  const queryUser = async () => {
    await axios({
      method: "get",
      url: search.get("distributorId")
        ? `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${search.get(
          "shopId",
        )}?distributorId=${search.get("distributorId")}`
        : `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${search.get("shopId")}`,
      headers: {
        "app-login-channel": "xdWxH5",
        "auth-token": getCookie("mstoken"),
        "AKC-H5": "H5",
      },
      params: {},
    }).then(res => {
      localStorage.setItem("userinfo", JSON.stringify(res.data.data));
      document.title = getUserRole() === "R" ? "店铺" : "品牌馆";
      console.error(document.cookie);
    });
  };

  const showTabs = () => {
    if (!show) {
      setShow(true);
      setCategoriesModalTranslateY(0);
    } else {
      setCategoriesModalTranslateY(-categoriesModalHeight.current);
      setCategoriesModalTranslateY(0);
      setShow(false);
    }
  };

  useEffect(() => {
    if (
      search.get("shopId") &&
      search.get("shopId") !== "undefined" &&
      search.get("shopId") !== "null"
    ) {
      queryUser();
    }
    storeRem();
    queryDarkWord();
    queryTabs();
  }, []);

  const handleClick = (e: any) => {
    console.log(e?.target?.id);
    if (e?.target?.id !== "modal_content" && e?.target?.id !== "tadDiv") {
      if (show) {
        showTabs();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [show]);

  //加载更多
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <div className={styles.infinite}>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {cardList?.length > 0 && (
              <>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
                <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  //点击「全部」按钮的回调
  const onAllAction = e => {
    track.track("resource_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      resource_content: "B2R旗舰店",
      module: "关注好店",
      btn_name: "全部",
    });
    //关注好店铺全部按钮
    console.log("关注好店铺全部按钮", e);
    if (search.get("distributorId")) {
      navigater(
        `./marketFocus?distributorId=${search.get("distributorId")}&shopId=${search.get("shopId")}`,
      );
    } else {
      navigater(`./marketFocus?shopId=${search.get("shopId")}`);
    }
  };

  //获取关注的店
  const queryFocus = async () => {
    const res: any = await focusList({
      pageId: "H5_Shop",
      searchParams: {
        pageNum: 1,
        pageSize: 10,
      },
    });
    const list = res?.data?.result || [];
    // const list = [];
    const obj = {
      shopInfos: list,
      tagLocationCode: "H5_Shop_ShopNameDown",
    };
    setFocusInfo(obj);
  };

  const onFocusShopAction = (e, index) => {
    track.track("resource_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      resource_content: "B2R旗舰店",
      module: "关注好店",
      resource_rank: index,
      merchant_shop_code: e.shopCode,
      btn_name: e.shopName,
    });
    if (search.get("distributorId")) {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&distributorId=${search.get(
          "distributorId",
        )}&shopId=${search.get("shopId")}&page_name=${encodeURIComponent(
          "店铺聚合",
        )}&productType=1`,
      );
    } else {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&shopId=${search.get(
          "shopId",
        )}&page_name=${encodeURIComponent("店铺聚合")}&productType=1`,
      );
    }
  };

  useActivate(() => {
    console.log("market-----useActivate", shopId, getCachingNodes());
    document.title = getUserRole() === "R" ? "店铺" : "品牌馆";
    queryFocus();
    track.track("$pageview", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
    });
    shareMarket(str);
  });

  useUnactivate(() => {
    console.log("market-----useUnactivate", shopId, getCachingNodes());
  });

  const onShopAction = (e, index?: any) => {
    console.log("点击店铺回调", e);
    if (index >= 0) {
      track.track("resource_click", {
        page_name: "店铺聚合",
        previous_page_name: search.get("page_name"),
        domain: "B2R旗舰店",
        module: "店铺列表",
        resource_content: e.shopName,
        resource_rank: index,
        merchant_shop_code: e.shopCode,
      });
    } else {
      track.track("resource_click", {
        page_name: "店铺聚合",
        previous_page_name: search.get("page_name"),
        domain: "B2R旗舰店",
        resource_content: "B2R旗舰店",
        module: "关注好店",
        merchant_shop_code: e.shopCode,
        btn_name: e.shopName,
      });
    }
    if (search.get("distributorId")) {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&distributorId=${search.get(
          "distributorId",
        )}&shopId=${search.get("shopId")}&page_name=${encodeURIComponent(
          "店铺聚合",
        )}&productType=1`,
      );
    } else {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&shopId=${search.get(
          "shopId",
        )}&page_name=${encodeURIComponent("店铺聚合")}&productType=1`,
      );
    }
  };
  const onProductAction = (e, index) => {
    console.log("点击商品回调", e);
    track.track("resource_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      activity_spu_code: e.activitySpuId,
      activity_id: e.activityId,
      sku_id: e.selectSkuId,
      btn_name: "商品点击",
      resource_rank: index,
      merchant_shop_code: e.shopCode,
      tab_name: categories[categroyIndex].classifyName,
    });
    navigate2Product({
      activityId: e.activityId,
      activitySpuId: e.activitySpuId,
      selectSkuId: e.selectSkuId,
    });
  };
  const onStudyTourMaterialAction = e => {
    console.log("点击游学素材图片/视频回调", e);
  };
  const onLiveAction = (e, index) => {
    console.log("点击直播回调", e);
    track.track("resource_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      btn_name: "直播点击",
      live_no: e.liveNo,
      resource_rank: index,
      merchant_shop_code: e.shopCode,
      tab_name: categories[categroyIndex].classifyName,
    });
    navigate2LiveRoom({
      liveId: e.liveNo,
      liveStatus: e.liveStatus,
    });
  };

  //获取页面信息
  const queryPage = () => {
    //关注好店
    queryFocus();
  };

  useLayoutEffect(() => {
    queryPage();
    track.track("$pageview", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
    });
  }, [shopId]);

  const goSearch = () => {
    track.track("btn_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      btn_name: "搜索框",
      module: "搜索框",
    });
    const word = darkList?.[time].searchWords;
    localStorage.removeItem("search_tag_market");
    if (search.get("distributorId")) {
      navigater(
        `./marketSearch?distributorId=${search.get("distributorId")}&page_name=${encodeURIComponent(
          "店铺聚合",
        )}&shopId=${search.get("shopId")}&key=${word}`,
      );
    } else {
      navigater(
        `./marketSearch?page_name=${encodeURIComponent("店铺聚合")}&shopId=${search.get(
          "shopId",
        )}&key=${word}`,
      );
    }
  };

  const loadMore = async () => {
    if (hasMore) {
      queryTabList(pageNum, categroyIndex, []);
    }
  };

  const onScroll = throttle(() => {
    const dom: any = document.getElementById("tadDiv");
    const searchDiv: any = document.getElementById("searchDiv");
    console.log("滚动高度--", dom?.getBoundingClientRect()?.top, searchDiv?.clientHeight);
    if ((dom?.getBoundingClientRect()?.top ?? 0) - searchDiv?.clientHeight <= 2 && !sticky) {
      setSticky(true);
      console.log("吸顶了--");
    }
    if ((dom?.getBoundingClientRect()?.top ?? 0) - searchDiv?.clientHeight > 2 && sticky) {
      setSticky(false);
      console.log("移走了--");
    }
  }, 100);

  //切换tab
  const changeCategory = index => {
    track.track("tab_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      resource_content: "B2R旗舰店",
      module: "关注好店",
      tab_name: categories[index]?.classifyName,
    });

    if (index === categroyIndex) {
      return;
    }

    setCategroyIndex(index);
    setCardList([]);
    setPageNum(1);
    setHasMore(false);
    if (show) {
      showTabs();
    }

    const pageDom = document.getElementById("pages") as HTMLElement;
    const tabDom = document.getElementById("tadDiv") as HTMLElement;
    const searchDiv = document.getElementById("searchDiv") as HTMLElement;
    const height =
      focusInfo?.shopInfos?.length > 0 ? searchDiv.clientHeight + 2 * tabDom.clientHeight + 2 : 0;
    if (sticky) {
      pageDom.scrollTo(0, height);
    }

    const categoryDom = document.getElementById(`category-${categories[index].id}`) as HTMLElement;
    categoryDom.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
    setSelectedCateMargin(`${categoryDom.offsetLeft}px`);

    queryTabList(1, index, []);
  };

  return (
    <>
      {/* 日日奖红包到期提醒组件 */}
      <RedPacketNotice />
      <div className={styles.market}>
        <img
          className={styles.backImg}
          src={
            getUserRole() === "R"
              ? "https://akim-oss.aikucun.com/204be2bcb7f597333369db51ee7a374bba060ff5_1698809050866_28.png"
              : "https://akim-oss.aikucun.com/204be2bcb7f597333369db51ee7a374bba060ff5_1698809050866_28.png"
          }></img>
        <div className={styles.page} id="pages" onScroll={onScroll}>
          <div className={styles.searchDiv} id="searchDiv">
            <img
              className={styles.back}
              src={
                getUserRole() === "R"
                  ? "https://akim-oss.aikucun.com/ceb157dc3e125916389079cbf880aa3c80abc613_1698810722215_82.png"
                  : "https://akim-oss.aikucun.com/1fa6d223a9cf0abd9a15df05857ef2ebba9ce012_1698807275461_45.png"
              }></img>
            <input onFocus={() => goSearch()} className={styles.searchBox}></input>
            <img
              className={styles.search_img}
              src="https://akim-oss.aikucun.com/f04090805c6eebdfc7f9cb69b81f3e44c3a3636a_1698809314459_85.png"
            />
            <div className={styles.darkList} onClick={() => goSearch()}>
              {darkList?.length > 0 &&
                darkList.map((item, index) => {
                  return (
                    <div
                      style={{ top: `${time * -0.4}rem` }}
                      className={styles.darkList_item}
                      key={index}>
                      {item.searchWords}
                    </div>
                  );
                })}
            </div>
          </div>
          {focusInfo?.shopInfos?.length > 0 && (
            <div className={styles.focus}>
              <StoreFocusAgg
                shopInfos={focusInfo.shopInfos}
                tagLocationCode="H5_Shop_ShopNameDown"
                onAllAction={onAllAction}
                title={getUserRole() === "R" ? "关注好店" : "关注品牌"}
                onShopAction={(e, key) => onFocusShopAction(e, key)}
                role={getUserRole()}
                terminal="h5"></StoreFocusAgg>
            </div>
          )}
          <div className={styles.tabs}>
            <div className={styles.tadDiv} id="tadDiv">
              <div className={styles.tabLine}>
                <div className={styles.tabTile}>
                  {categories.map((item, index) => {
                    return (
                      <div
                        className={`${styles.tab} ${index === categroyIndex ? styles.curTab : ""}`}
                        style={{
                          width: `${item.classifyName.length * 0.32}rem`,
                        }}
                        id={`category-${item.id}`}
                        key={index}
                        onClick={() => changeCategory(index)}>
                        <div className={styles.text}>{item.classifyName}</div>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={styles.selectedLineContainer}
                  style={{
                    width: `${(categories[categroyIndex]?.classifyName.length || 0) * 0.32}rem`,
                    marginLeft: selectedCateMargin,
                  }}>
                  <div className={styles.selectedLine} />
                </div>
              </div>
            </div>

            {!show && (
              <img
                onClick={showTabs}
                className={styles.btn}
                id="modal_content"
                src="https://akim-oss.aikucun.com/92fa144482756e63338d20885c5d6a81ac635026_1698813644407_97.png"></img>
            )}

            {show && (
              <img
                onClick={showTabs}
                className={styles.btn}
                id="modal_content"
                src="https://akim-oss.aikucun.com/0cd05b13ecf15964616c170824bfc0efec07b6fb_1694775377718_66.png"></img>
            )}

            {categories.length > 0 && (
              <div
                className={styles.tabModal}
                style={{ visibility: `${show ? "visible" : "hidden"}` }}>
                <div
                  className={styles.content}
                  id="categories-in-modal"
                  style={{
                    transform: `translateY(${categoriesModalTranslateY}px)`,
                    transition: "transform 0.3s",
                  }}>
                  {categories.map((item, index) => {
                    return (
                      <div
                        key={index}
                        id="modal_content"
                        className={
                          styles.category +
                          " " +
                          (index === categroyIndex ? styles.category_selected : "")
                        }
                        onClick={() => changeCategory(index)}>
                        <div id="modal_content" className={styles.text}>
                          {item.classifyName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {cardList?.length > 0 && (
            <div className={styles.cards + " " + (sticky ? styles.stickyClass : "")}>
              {cardList.map((item: any, index) => {
                return (
                  <div
                    id={`market-${item.shopCode}`}
                    data-param={JSON.stringify({
                      page_name: "店铺聚合",
                      domain: "B2R旗舰店",
                      module: "店铺列表",
                      resource_rank: index,
                      merchant_shop_code: item?.shopCode,
                      resource_content: item?.shopName,
                      tab_name: categories[categroyIndex].classifyName,
                    })}
                    data-eventid={`market-${item?.shopCode}`}
                    key={index}
                    className={styles.card_item}>
                    <ShopCardV2
                      nameFrontTagLocationCode={`${hostEnv}_Shop_ShopNameFront`}
                      mainLocationCode={`${hostEnv}_Shop_ShopNameDown`}
                      shopInfo={item}
                      index={index}
                      sign="market"
                      onShopAction={e => onShopAction(e, index)}
                      onProductAction={e => onProductAction(e, index)}
                      onStudyTourMaterialAction={onStudyTourMaterialAction}
                      onLiveAction={e => onLiveAction(e, index)}
                      labelLocationVOList={item?.labelLocationVOList}
                      profitTag="赚"
                      role={getUserRole()}
                      terminal="h5"></ShopCardV2>
                  </div>
                );
              })}
            </div>
          )}
          {cardList?.length <= 0 && (hasMore || queryLoading) && (
            <div
              id="sk_img"
              style={{
                padding: `${pxtorem(12)} ${pxtorem(12)} 0 ${pxtorem(12)}`,
                backgroundColor: "#f1f2f5",
                minHeight: "100vh",
                position: "absolute",
                zIndex: 2,
                width: "100%",
                boxSizing: "border-box",
              }}>
              <img
                style={{ width: "100%", height: pxtorem(240) }}
                src="https://akim-oss.aikucun.com/1668efdd569b38c535ab86a9e98551543cea9896_1699434897813_29.png"
              />
              {(focusInfo?.shopInfos?.length <= 0 || sticky) && (
                <img
                  style={{ width: "100%", height: pxtorem(240) }}
                  src="https://akim-oss.aikucun.com/1668efdd569b38c535ab86a9e98551543cea9896_1699434897813_29.png"
                />
              )}
            </div>
          )}
          {cardList?.length <= 0 && !queryLoading && !hasMore && (
            <div className={styles.empty}>
              <img
                className={styles.img}
                src="https://akim-oss.aikucun.com/810876fda193a98a58c8ede9cd6763c33263d3b1_1698825597037_23.png"></img>
              <div className={styles.text}>当前分类暂无品牌</div>
            </div>
          )}
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default inject("appStore")(observer(Home));
