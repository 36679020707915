import { navigate } from "@/utils/navigate";
import useNavigation from "@/utils/useNavigate";
import { MaterialMediaModel, MaterialModuleModel, ProductDetailModel } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";

const onMaterialAction = (
  material?: MaterialModuleModel,
  productDetailModel?: ProductDetailModel,
) => {
  const { navigate2Material } = useNavigation();
  navigate2Material(material?.type, material?.materialNo, productDetailModel?.activitySpuId);
};

const onMoreMaterialAction = (productDetailModel?: ProductDetailModel) => {
  const originParams = location.href.split("?")[1];
  const shopPath = `/mx-shop-micro/material?activitySpuId=${productDetailModel?.activitySpuId}&baseProductId=${productDetailModel?.spuInfoVO?.spuId}&useType=1&queryType=14${originParams}`;
  navigate({
    h5Options: {
      url: shopPath,
    },
  });
};

const onPreviewAction = (
  item: MaterialMediaModel,
  index: number,
  mediaList?: MaterialMediaModel[],
) => {
  console.log("图片预览");
  Toast.show("待和入智能文案需求后支持使用最新的预览组件");
};

const onMediaMoreAction = (
  mediaList?: MaterialMediaModel[],
  productDetailModel?: ProductDetailModel,
) => {
  console.log("更多资源", mediaList, productDetailModel);
  Toast.show("待和入智能文案需求后支持");
};

const onAiMaterialAction = (productDetailModel?: ProductDetailModel) => {
  console.log("ai点击", productDetailModel);
  Toast.show("待和入智能文案需求后支持");
};

const onAiMaterialCopyAction = (text?: string) => {
  if (!text || text.length === 0) {
    return;
  }
  copy(text);
  Toast.show("复制成功");
};
export const ProductDetailAction = {
  onMaterialAction,
  onMoreMaterialAction,
  onPreviewAction,
  onMediaMoreAction,
  onAiMaterialAction,
  onAiMaterialCopyAction,
};
