import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Input } from "antd-mobile";
import styles from "./index.module.less";

interface FormItemInfo {
  id: string;
  title: string; // 标题
  content?: string; // 内容
  hintContent?: string; // hint信息
  showCheckStatus: boolean; // 是否显示提示信息
  maxLength?: number;
  className?: string;
}

type Props = {
  formItem: FormItemInfo;
  onInputChange?: (result: string) => void;
  ref?: ForwardedRef<any>;
};

// 游学报名表单ItemComp
const YxFormItemComp = forwardRef<any, Props>(({ formItem, onInputChange }, ref) => {
  const [inputTips, setInputTips] = useState(
    formItem.showCheckStatus && formItem.content && formItem.content.length === 0,
  );
  const [inputContent, setInputContent] = useState<string | undefined>(formItem.content);

  const getInputContent = () => {
    return inputContent;
  };

  useImperativeHandle(ref, () => ({
    getInputContent,
  }));

  useEffect(() => {
    setInputTips(formItem.showCheckStatus && (!inputContent || inputContent.length === 0));
  }, [inputContent, formItem.showCheckStatus]);

  useEffect(() => {
    setInputContent(formItem.content);
  }, [formItem.content]);

  return (
    <>
      <div className={styles["itemV"]}>
        <div
          className={`${styles["itemV1"]}${formItem?.className?.length ? " " + formItem.className : ""}`}>
          <div className={styles["left"]}>
            <div className={styles["flag"]}>*</div>
            <span className={styles["title"]}>{formItem.title}</span>
          </div>
          <Input
            id={formItem.id}
            type={
              formItem.id === "inputPhone" || formItem.id === "inputAidouNum" ? "number" : "text"
            }
            pattern={
              formItem.id === "inputPhone" || formItem.id === "inputAidouNum" ? "[0-9]*" : undefined
            }
            maxLength={formItem.maxLength ?? 1000}
            placeholder={formItem.hintContent}
            className={styles["input"]}
            value={inputContent}
            onChange={e => {
              if (formItem.id === "inputPhone" || formItem.id === "inputAidouNum") {
                let value = e;
                e = value.slice(0, 20);
              }
              onInputChange?.(e);
              setInputContent(e);
            }}
          />
        </div>
        <div className={styles["divider"]} />
        {inputTips && <span className={styles["inputTips"]}>此项为必填项</span>}
      </div>
    </>
  );
});

export default YxFormItemComp;
