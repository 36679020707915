import { queryActivityBanner } from "@/api/activity";
import { ApiGetWxConfig } from "@/api/wx-config";
import { ReactComponent as ToTopIcon } from "@/assets/toTop.svg";
import BottomNotice from "@/components/bottom-notice";
import RedPacketNotice from "@/components/red-packet-notice";
import { NavigationBarRef } from "@/components/top-navigation";
import VerticalScrollview from "@/components/vertical-scrollview";
import { useCommonContext } from "@/utils/context/common";
import { default as useDomObserver } from "@/utils/hooks/useDomObserver";
import { default as useStickyStateObserver } from "@/utils/hooks/useStickyStateObserver";
import { pxtorem } from "@/utils/pxtorem";
import {
  activityAPI,
  ActivityBrandModel,
  ActivityModel,
  LabelPageId,
  ResourceBannerModel,
} from "@akc/biz-api";
import { BannerComp, TrackDataContext } from "@akc/mx-taro-h5-components";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import { useParams, useSearchParams } from "react-router-dom";
import ActivityCouponPopup from "./components/ActivityCouponPopup";
import ActivityDesc from "./components/ActivityDesc";
import ActivityEnding from "./components/ActivityEnding";
import ActivitySelector from "./components/ActivitySelector";
import CategoryAndSize from "./components/CategoryAndSize";
import FollowWX from "./components/FollowWX";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LiveFloatingBubble from "./components/LiveFloatingBubble";
import MaterialList from "./components/MaterialList";
import Navigation from "./components/Navigation";
import ProductList from "./components/ProductList";
import ProductStatusTab from "./components/ProductStatusTab";
import SelectionHeader from "./components/SelectionHeader";
import SortAndFilter from "./components/SortAndFilter";
import { ActivityProductProvider, useSelectedActivity } from "./hooks/useActivityProduct";
import styles from "./index.module.less";

interface ExtraActivityModel {
  /** 品牌素材信息 */
  materialResult?: {
    total: string | number;
    materialsResultVOList: ({ materialNo: string; } & Record<string, any>)[];
  };
}

interface ActivityContentProps {
  headerDomRef?: React.RefObject<HTMLDivElement>;
  brandData?: ActivityBrandModel & ExtraActivityModel;
  activity?: ActivityModel;
  isOver: boolean;
  onCategoryShowOrHide?: (show: boolean) => void;
}

const ActivityContent: React.FC<ActivityContentProps> = (props) => {
  const params = useParams();
  const activity = props.activity;
  const waterfallContainerDom = useRef<HTMLDivElement>(null);
  const [updateActivity, setUpdateActivity] = useState(false);
  const [search] = useSearchParams();
  const sortAndFilterDomRef = useRef<any>(null);
  const [bannerList, setBannerList] = useState<ResourceBannerModel[]>([]);
  const [activitySelectorOut, setActivitySelectorOut] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>(
    search.get("isShowAll") ? undefined : params.activityId,
  );
  const navigationRef = useRef<NavigationBarRef>(null);
  const selectedActivity = useSelectedActivity();
  const brandTopLabel = useMemo(() => {
    if (!activity || !Array.isArray(activity?.labelLocationVOList)) {
      return [];
    }
    const brandLabels = activity.labelLocationVOList.find(item => item.locationCode === "H5_ScheduleDetail_BrandDown");
    if (!brandLabels || !Array.isArray(brandLabels?.labelInfoVOList)) {
      return [];
    }
    // "物流停运标签"
    const codes1 = ["DATE_LABEL"];
    const result = brandLabels.labelInfoVOList.filter(item => !!item.labelCode && codes1.includes(item.labelCode));
    // TODO: 等后续推进该需求与小程序逻辑同步
    // 实测该地方只能展示1-2个标签，所以取前1个(需要考虑品牌名本身很长) 后续考虑推动为需求来变更 
    if (brandLabels.labelInfoVOList.length > 1) {
      return result.slice(0, 1);
    }
    return result;
  }, [activity]);

  const otcProductHideMenu = menuList => {
    console.log("otcProductHideMenu——", menuList);
    ApiGetWxConfig(window.location.href.split("#")[0]).then(res => {
      if (res) {
        const { code, data } = res;
        if (code === 200 && data) {
          const config = data.weixinJsApiSignature;
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: config.appId, // 必填，公众号的唯一标识
            timestamp: config.timestamp, // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature, // 必填，签名
            jsApiList: [
              // 必填，需要使用的JS接口列表
              "updateAppMessageShareData", // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
              "updateTimelineShareData", // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
              "showMenuItems", // 批量显示功能按钮接口
              "hideMenuItems",
              "chooseWXPay", // 发起一个微信支付请求
              "checkJsApi",
              "previewImage",
              "chooseImage",
              "getLocalImgData",
              "hideAllNonBaseMenuItem",
            ],
          });
        }
        window.wx.ready(() => {
          // 判断当前客户端版本是否支持指定JS接口
          window.wx.checkJsApi({
            jsApiList: [
              "updateAppMessageShareData",
              "onMenuShareAppMessage",
              "updateTimelineShareData",
              "onMenuShareTimeline",
              "showMenuItems",
              "hideMenuItems",
              "chooseWXPay",
              "previewImage",
              "chooseImage",
              "getNetworkType",
              "hideAllNonBaseMenuItem",
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success() {
              console.log("ready——", menuList);
              window.wx.hideMenuItems({
                menuList,
              });
            },
          });
        });
        window.wx.error((res2: any) => {
          console.error("微信签名失败", res2);
        });
      }
    });
  };

  useEffect(() => {
    if (!props.brandData?.brandId) {
      return;
    }
    queryActivityBanner().then(res => setBannerList(res));
  }, [props.brandData]);

  // useEffect(() => {
  //   if (activity && platform.isWxH5) {
  //     setTimeout(() => {
  //       otcProductHideMenu(
  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         // @ts-ignore
  //         activity?.isOTC ? ["menuItem:share:timeline"] : [],
  //       );
  //     }, 1000);
  //   }
  // }, [activity]);

  const isCategoryTabSticky = useRef(false);
  const isCategoryTabStickyBeforeUnactivate = useRef(false);
  useUnactivate(() => {
    isCategoryTabStickyBeforeUnactivate.current = isCategoryTabSticky.current;
  });
  useActivate(() => {
    isCategoryTabSticky.current = isCategoryTabStickyBeforeUnactivate.current;
  });

  const productWaterfallDom = useRef<HTMLDivElement>(null);
  const sortAndFilterWrapper = useRef<HTMLDivElement>(null);
  useStickyStateObserver(sortAndFilterWrapper.current, isSticky => {
    isCategoryTabSticky.current = isSticky;
  });

  const isActivitySelectorSticky = useRef(false);
  const selectorContainerDom = useRef<HTMLDivElement>(null);
  const [activitySelectorBorderRadis, setActivitySelectorBorderRadis] = useState<number>(10);
  const [activitySelectorBgColor, setActivitySelectorBgColor] = useState<string>("");

  useStickyStateObserver(selectorContainerDom.current, isSticky => {
    setActivitySelectorBorderRadis(isSticky ? 0 : 10);
    setActivitySelectorBgColor(isSticky ? "#fff" : "#fff");
    isActivitySelectorSticky.current = isSticky;
  });

  useDomObserver(selectorContainerDom.current, out => {
    console.log("监听位置--------", out);
    setActivitySelectorOut(out);
  });

  const overviewContainerDomRef = useRef<HTMLDivElement>(null);

  return (<VerticalScrollview
    className={`${styles.container} ${props.isOver ? styles.empty : ""}`}
    onMoveUpAtBottom={() => {
      console.log("move up~~~~");
    }}>
    <Navigation
      ref={navigationRef}
      showSearchIcon={!props.isOver}>
      <SelectionHeader
        showSelectionInHeader={false}
      // onClickSelection={() =>
      //   waterfallContainerDom.current?.scrollIntoView({ behavior: "smooth" })
      // }
      />
    </Navigation>

    {!props.isOver && (
      <>
        <div ref={props.headerDomRef}>
          <Header brandTopLabel={brandTopLabel} brandData={props.brandData} isInitialFollowing={props.activity?.focusStatus === 1} />
        </div>
        <div className={styles.followWXContainer}>
          <FollowWX />
        </div>

        <div className={`${styles.section} ${styles.activitySelectorAndMaterialWrapper}`}>
          {/* {brandData.activityList && brandData.activityList.length > 1 && (
                <div style={{ paddingTop: pxtorem(9) }} ref={selectorContainerDom}>
                  <ActivitySelector
                    brandData={brandData}
                    borderRadis={activitySelectorBorderRadis}
                    bgColor={activitySelectorBgColor}
                    initialSelectedActivityId={selectedId}
                    onSelectionChange={(id) => {
                      if (overviewContainerDomRef.current && isActivitySelectorSticky.current) {
                        overviewContainerDomRef.current?.scrollIntoView();
                      }
                      sortAndFilterDomRef.current.resetFilter?.();
                      setSelectedId(id);
                    }}
                  />
                </div>
              )} */}
          {/* {activitySelectorOut && (
                <div
                  style={{
                    position: "fixed",
                    top: pxtorem(44),
                    zIndex: 2,
                    padding: `${pxtorem(6)} 0`,
                    background: "#fff",
                    width: "100vw",
                    borderBottom: `solid rgba(34, 34, 34, 0.05) ${pxtorem(1)}`,
                  }}
                >
                  <ActivitySmallSelector
                    brandData={brandData}
                    borderRadis={activitySelectorBorderRadis}
                    bgColor={activitySelectorBgColor}
                    initialSelectedActivityId={selectedId}
                    onSelectionChange={(id) => {
                      overviewContainerDomRef.current?.scrollIntoView();
                      sortAndFilterDomRef.current.resetFilter?.();
                      setTimeout(() => {
                        setSelectedId(id);
                      }, 100);
                    }}
                    small={true}
                  />
                </div>
              )} */}
        </div>

        <div
          ref={waterfallContainerDom}
          className={`${styles.waterfallContainer} ${styles.section}`}>
          {props.brandData?.activityList && props.brandData.activityList.length > 1 && (
            <div ref={selectorContainerDom} className={styles.sticky}>
              <ActivitySelector
                brandData={props.brandData}
                borderRadis={activitySelectorBorderRadis}
                bgColor={activitySelectorBgColor}
                initialSelectedActivityId={selectedId}
                onSelectionChange={id => {
                  if (overviewContainerDomRef.current && isActivitySelectorSticky.current) {
                    overviewContainerDomRef.current?.scrollIntoView();
                  }
                  sortAndFilterDomRef.current.resetFilter?.();
                  // setSelectedId(id);
                }}
              />
            </div>
          )}

          <div ref={overviewContainerDomRef} className={styles.overviewContainer}>
            <ActivityDesc
              brandId={props.activity?.brandId}
              brandData={props.brandData}
              updateActivity={updateActivity}
            />
            <MaterialList />
          </div>

          {bannerList.length > 0 && (
            <BannerComp className={styles.bannerContainer} bannerList={bannerList} />
          )}

          <CategoryAndSize
            onCategoryShowOrHide={props.onCategoryShowOrHide}
            onSelectionChange={() => {
              sortAndFilterDomRef.current.resetFilter?.();
            }}
          />
          <div
            ref={sortAndFilterWrapper}
            className={styles.sticky}
            style={{
              zIndex: 3,
              top:
                props.brandData?.activityList && props.brandData.activityList.length > 1
                  ? pxtorem(99)
                  : pxtorem(44),
            }}>
            <SortAndFilter
              ref={sortAndFilterDomRef}
              brandData={props.brandData}
              onSortFilterChange={() => {
                // TODO
                productWaterfallDom.current?.scrollIntoView();
                // console.log("11", isCategoryTabSticky.current);
                // if (isCategoryTabSticky.current) {
                //   productWaterfallDom.current?.scrollIntoView();
                // }
              }}
            />
          </div>
          <ProductStatusTab
            brandData={props.brandData}
            onChange={() => {
              productWaterfallDom.current?.scrollIntoView();
            }}
          />
          <div
            ref={productWaterfallDom}
            style={{
              scrollMarginTop:
                props.brandData?.activityList && props.brandData.activityList.length > 1
                  ? pxtorem(140)
                  : pxtorem(88),
            }}
            className={styles.waterfallWrapper}>
            <ProductList onCartChange={navigationRef.current?.triggerAddCartSuccess} />
          </div>
        </div>
      </>
    )}

    {props.isOver && props.activity && <ActivityEnding activity={props.activity} />}

    <LiveFloatingBubble activityId={selectedActivity?.activityId} />
    {/* 漂浮在底部的通告栏 */}
    <BottomNotice isActivity />
    {!props.isOver && <ActivityCouponPopup updateActivity={() => setUpdateActivity(true)} />}
  </VerticalScrollview>
  );
};

const Activity: React.FC = () => {
  const params = useParams();
  const [brandData, setBrandData] = useState<ActivityBrandModel & ExtraActivityModel>({});
  const [showSelectionInHeader, setShowSelectionInHeader] = useState(false);
  const [activity, setActivity] = useState<ActivityModel>();
  const [search] = useSearchParams();
  const { track, canShare } = useCommonContext();

  const isOver = (activity?.status !== 1 && activity?.status !== 2) || !!activity?.isActivityExpire;

  const pageView = () => {
    const showTab = brandData.activityList?.length !== 1;
    const isShowAll = search.get("isShowAll");
    const activityId = isShowAll
      ? brandData.activityList?.map(i => i.activityId).join(",")
      : params.activityId;

    track.track?.("$pageview", {
      page_name: "品牌活动",
      tab_name: showTab
        ? isShowAll
          ? "全部"
          : brandData.activityList?.find(i => i.activityId === params.activityId)?.activityAggrTitle
        : undefined,
      tab_id: showTab ? activityId : undefined,
      tab_rank: showTab
        ? isShowAll
          ? 0
          : (brandData.activityList?.map(i => i.activityId).indexOf(params.activityId) ?? 0 + 1)
        : undefined,
      activity_id: activityId,
    });
  };

  const onCategoryShowOrHide = useCallback(show => {
    setShowSelectionInHeader(!show);
  }, []);

  useEffect(() => {
    if (!params.activityId) {
      return;
    }
    Promise.all([
      activityAPI.queryActivityBrandData({
        activityId: params.activityId,
        pageId: LabelPageId.h5ActivityDetail,
        queryType: search.get("queryType") ?? undefined,
      }),
      activityAPI.queryActivityData({
        activityId: params.activityId,
        pageId: LabelPageId.h5ActivityDetail,
      }),
    ]).then(datas => {
      const data = datas[1] as ActivityModel & ExtraActivityModel;
      if (!data?.brandId) {
        return;
      }
      setActivity(data);
      const brandData = datas[0];
      if (!brandData?.brandId) {
        return;
      }
      brandData.activityList?.forEach((item, index) => (item.rank = index));
      if (brandData.activityList && brandData.activityList.length > 1) {
        brandData.activityList.unshift({
          activityAggrTitle: "全部",
          activityAggrSubTitleList: [`共${brandData.totalOnlineProductCount}款`],
          brandStoryVO: brandData.activityList[0].brandStoryVO,
          merchantShopCode: brandData.activityList[0].merchantShopCode,
          brandId: brandData.brandId,
          backgroundBrandVideoUrl: brandData.activityList[0].backgroundBrandVideoUrl,
        });
      }
      setBrandData({
        ...brandData,
        materialResult: data.materialResult,
      });
      pageView();
    });
  }, []);

  const otcProductHideMenu = menuList => {
    console.log("otcProductHideMenu——", menuList);
    ApiGetWxConfig(window.location.href.split("#")[0]).then(res => {
      if (res) {
        const { code, data } = res;
        if (code === 200 && data) {
          const config = data.weixinJsApiSignature;
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: config.appId, // 必填，公众号的唯一标识
            timestamp: config.timestamp, // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature, // 必填，签名
            jsApiList: [
              // 必填，需要使用的JS接口列表
              "updateAppMessageShareData", // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
              "updateTimelineShareData", // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
              "showMenuItems", // 批量显示功能按钮接口
              "hideMenuItems",
              "chooseWXPay", // 发起一个微信支付请求
              "checkJsApi",
              "previewImage",
              "chooseImage",
              "getLocalImgData",
              "hideAllNonBaseMenuItem",
            ],
          });
        }
        window.wx.ready(() => {
          // 判断当前客户端版本是否支持指定JS接口
          window.wx.checkJsApi({
            jsApiList: [
              "updateAppMessageShareData",
              "onMenuShareAppMessage",
              "updateTimelineShareData",
              "onMenuShareTimeline",
              "showMenuItems",
              "hideMenuItems",
              "chooseWXPay",
              "previewImage",
              "chooseImage",
              "getNetworkType",
              "hideAllNonBaseMenuItem",
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success() {
              console.log("ready——", menuList);
              window.wx.hideMenuItems({
                menuList,
              });
            },
          });
        });
        window.wx.error((res2: any) => {
          console.error("微信签名失败", res2);
        });
      }
    });
  };

  useEffect(() => {
    document.title = brandData?.brandName ?? "活动详情";
  }, [brandData]);

  useActivate(() => {
    pageView();
    document.title = brandData?.brandName ?? "活动详情";
  });

  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);
  useEffect(() => {
    setTrackData({
      page_name: "品牌活动",
      previous_page_name: params.fromPage ?? "",
    });
  }, []);

  // 仅有1个档期时，忽略 isShowAll 参数
  const initialSelectedActivityId = search.get("isShowAll") && (brandData?.activityList?.length ?? 0) > 1 ? undefined : params.activityId;
  const headerDomRef = useRef<HTMLDivElement>(null);
  return (
    <ActivityProductProvider
      initialSelectedActivityId={initialSelectedActivityId}
      activityList={brandData.activityList}>

      <ActivityContent headerDomRef={headerDomRef} brandData={brandData} activity={activity} isOver={isOver} onCategoryShowOrHide={onCategoryShowOrHide} />

      <ToTopIcon
        className={`${styles.toTopIcon} ${showSelectionInHeader ? "" : styles.hide}`}
        onClick={() => {
          headerDomRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
      />

      {!isOver && canShare && <Footer brandData={brandData} activityList={brandData?.activityList || []} />}
      <RedPacketNotice />


    </ActivityProductProvider>
  );
};

export default Activity;
