import { batchReceiveCouponServ } from "@/api/activity";
import { fetchCouponList } from "@/api/product";
import { CouponModel, ProductDetailModel } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import { useEffect, useState } from "react";

/**
 * 获取商品详情优惠券列表
 */
export const useCouponList = (product?: ProductDetailModel) => {
  const [couponList, setCouponList] = useState<CouponModel[]>();

  //获取优惠券列表
  const updateCouponList = async () => {
    const res = await fetchCouponList({
      activityId: product?.activityId,
      activitySpuId: product?.activitySpuId,
      skuId: product?.selectSkuId,
      productPrice: product?.skuCurrentPriceInfo?.marketingPrice,
      advanceActivityId: product?.skuAdvancePriceInfo?.activityId || "",
      advanceActivitySpuId: product?.skuAdvancePriceInfo?.activitySpuId || "",
    });
    setCouponList(res);
  };

  //领取优惠券
  const receiveCoupon = (coupon: CouponModel) => {
    const { awdId, promoActivityId } = coupon;
    batchReceiveCouponServ({
      couponByIdReqList: [{ awdId, promoActivityId }],
      rcsTraceId: "",
      captchaToken: "",
      pageIndex: window.location.href,
      protocolVersion: "",
    })
      .then(res => {
        const { code, success, data, message } = res || {};
        if (code === "00000" && success && data) {
          Toast.show({
            content: "优惠券领取成功",
            maskStyle: { zIndex: 100001 },
          });
          updateCouponList();
        } else {
          Toast.show({
            content: message || "优惠券被领光啦",
            maskStyle: { zIndex: 100001 },
          });
        }
      })
      .catch(err => {
        Toast.show({
          content: "系统异常",
          maskStyle: { zIndex: 100001 },
        });
      });
  };

  useEffect(() => {
    if (!product) {
      return;
    }
    updateCouponList();
  }, [product]);

  return { couponList, receiveCoupon };
};
