import ActionSheet from "@/components/action-sheet";
import TopNavigation from "@/components/top-navigation";
import { platform } from "@/utils/platform";
import { Button, DotLoading, Image, Popup, SafeArea } from "antd-mobile";
import React, { useEffect } from "react";
import SettingItem from "./components/setting-item";
import useShareConfig from "./hooks/useShareConfig";
import styles from "./index.module.less";

/**
 * 转发设置页面
 */

const ShareSetting: React.FC = () => {
  const {
    groups,
    popUpData,
    mpPopupVisible,
    setMpPopupVisible,
    setPopUpData,
    onDetailAction,
    onSwitchAction,
  } = useShareConfig();

  const { isH5 } = platform;

  useEffect(() => {
    document.title = "转发设置";
  }, []);

  return (
    <>
      {isH5 && (
        <TopNavigation
          backgroundColor="#f1f2f5"
          hideSearchIcon={true}
          hideCartIcon={true}
          hideFavoriteIcon={true}
        />
      )}
      <div className={styles.wrap}>
        {groups ? (
          groups.length === 0 ? (
            <div className={styles.center}>暂无转发设置</div>
          ) : (
            groups.map((group, i) => (
              <div key={i} className={i > 0 ? styles.group : ""}>
                <div className={styles.groupTitle}>{group.title}</div>
                <div className={styles.list}>
                  {group.items.map((item, index) => (
                    <SettingItem
                      {...item}
                      key={index}
                      onDetailClick={onDetailAction}
                      onSwitchChanged={onSwitchAction}
                      trackData={{
                        page_name: "转发设置",
                        previous_page_name: "我的",
                        btn_name: item.title,
                        page_desc: "转发设置",
                      }}
                    />
                  ))}
                </div>
              </div>
            ))
          )
        ) : (
          <div className={styles.center}>
            <DotLoading />
          </div>
        )}
      </div>
      <ActionSheet
        onMaskClick={() => setPopUpData(undefined)}
        visible={popUpData !== undefined}
        title={popUpData?.title}
        actions={popUpData?.actions.map(i => ({
          ...i,
          trackData: {
            page_name: "转发设置",
            btn_name: i.title,
            page_desc: "转发设置",
          },
        }))}
        onCancelClick={() => setPopUpData(undefined)}
      />
      <Popup visible={mpPopupVisible} closeOnMaskClick={true} bodyClassName={styles.mpPopup}>
        <div className={styles.titleWrap}>
          <span className={styles.title}>商品详情样式</span>
          <div
            className={styles.closeWrap}
            onClick={e => {
              e.stopPropagation();
              setMpPopupVisible(false);
            }}>
            <Image
              className={styles.close}
              src="https://akim-oss.aikucun.com/4a1a0e5ed29c0ea4cf8742c01f98270f5bebf689_1725009399069_64.png"
            />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.title}>素材详情</div>
            <Image
              className={styles.img}
              src="https://akim-oss.aikucun.com/25ff016d3cccefbe03e0c18b25b79db356b31a82_1725009950737_2.png"
            />
          </div>
          <div className={styles.item}>
            <div className={styles.title}>普通商详</div>
            <Image
              className={styles.img}
              src="https://akim-oss.aikucun.com/83c2c8280c8cb6e96216fa94b8bc8c09b25d8528_1725009950483_38.png"
            />
          </div>
        </div>

        <div
          className={styles.btnContainer}
          onClick={e => {
            e.stopPropagation();
            setMpPopupVisible(false);
          }}>
          <div className={styles.btn}>我知道了</div>
          <SafeArea position="bottom" />
        </div>
      </Popup>
    </>
  );
};

export default ShareSetting;
