import React, { useEffect, useMemo, useRef, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import styles from "./index.module.less";

type Props = {
  dataList: any[]; // 数据列表
  childRender: React.FC<any>; // 子组件FC
  itemHeight: (index: number) => number;
  marginY: number; // y轴的间距
  padding?: number; // 虚拟列表预加载高度
};

// 纵向虚拟列表
const VirtialList: React.FC<Props> = ({
  dataList,
  childRender,
  itemHeight,
  marginY,
  padding = window.innerHeight,
}) => {
  const [listHeight, setListHeight] = useState<number>(0);
  const itemTopList = useRef<number[]>([]);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);
  const intersectionObserver = useRef<IntersectionObserver>();
  const dataListRef = useRef<any[]>([]);
  const indexList = useRef<number[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const initData = () => {
    dataList.forEach((_, index) => {
      itemTopList.current[index] =
        index === 0 ? 0 : itemTopList.current[index - 1] + (itemHeight(index - 1) + marginY);
    });
    setListHeight(
      itemTopList.current[dataList.length - 1] + (itemHeight(dataList.length - 1) + marginY),
    );

    const items = containerRef.current?.children ?? [];
    Array.from(items).forEach(item => {
      intersectionObserver.current?.observe(item);
    });
    setEndIndex(dataList.length - 1);
    dataListRef.current = dataList;
  };

  useUnactivate(() => {
    indexList.current = [startIndex, endIndex];
  });

  useActivate(() => {
    setTimeout(() => {
      setStartIndex(indexList.current[0] || 0);
      console.log("window.scrollY", window.scrollY);
      if (window.scrollY === 0) {
        setStartIndex(0);
        setEndIndex(0);
      } else if (indexList.current[1] && indexList.current[1] > 0) {
        setEndIndex(indexList.current[1]);
      } else {
        // 渲染过程中跳走，逻辑执行中断，返回时需要重新执行数据逻辑
        initData();
      }
    }, 300);
  });

  useEffect(() => {
    intersectionObserver.current = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        const delta = parseInt(entry.target["style"].top) - entry.boundingClientRect.top;

        setStartIndex(
          Math.max(
            0,
            itemTopList.current.findIndex(item => {
              return item + padding >= delta;
            }),
          ),
        );
        const itemTopListInverse = [...itemTopList.current].reverse();
        setEndIndex(
          Math.min(
            itemTopList.current.length - 1,
            itemTopList.current.length -
              1 -
              itemTopListInverse.findIndex(item => {
                return item <= delta + padding + window.innerHeight;
              }),
          ),
        );
      },
      { threshold: 0 },
    );

    return () => {
      intersectionObserver.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const items = containerRef.current?.children ?? [];
      Array.from(items).forEach(item => {
        intersectionObserver.current?.observe(item);
      });
    });
  }, [startIndex, endIndex]);

  useEffect(initData, [dataList]);

  const children = useMemo(() => {
    return dataList.map(item => {
      return childRender(item);
    });
  }, [dataList]);

  return (
    <div className={styles.container} style={{ height: listHeight + "px" }} ref={containerRef}>
      {dataList.map((_, index) => {
        return (
          index >= startIndex &&
          index <= endIndex && (
            <div
              key={index}
              data-index={index}
              className={styles.item}
              style={{ top: itemTopList.current[index] + "px" }}>
              {children[index]}
            </div>
          )
        );
      })}
    </div>
  );
};

export default VirtialList;
