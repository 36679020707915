import React, { useEffect, useRef } from "react";

import KeepAlive from "react-activation";
import { getUrlParam } from "../../utils/url-parse";
import Index from "./index";

const Container: React.FC = () => {
  const { activitySpuId = "", activityNo, merchantShopCode } = getUrlParam();
  const domRef = useRef<HTMLDivElement>(null);
  const cacheKey = `material-${activityNo || merchantShopCode || activitySpuId}`;

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      console.error("!!!!!!!!!!!!!", mutations);
    });

    if (domRef.current) {
      observer.observe(domRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  console.log("cacheKey-----------", cacheKey);
  return (
    <KeepAlive cacheKey={cacheKey} saveScrollPosition={true}>
      <div ref={domRef}>
        <Index />
      </div>
    </KeepAlive>
  );
};

export default Container;
