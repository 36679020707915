import React, { useState } from "react";
import styles from "./index.module.less";

const ShareCoupon = ({ shareObj, handleJumpOut2Coupon }) => {
  const handleBannerClick = () => {
    handleJumpOut2Coupon?.(shareObj.link);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "120px",
        borderRadius: "8px",
        overflow: "hidden", // 隐藏超出部分
        marginBottom: "12px",
      }}>
      <img className={styles.banner} onClick={handleBannerClick} src={shareObj.placardImgUrl} />
    </div>
  );
};

export default ShareCoupon;
