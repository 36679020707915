import { DotLoading, Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface SaveImagePopupProps {
  imageUrl: string;
  onMaskClick?: () => void;
}

const SaveImagePopup: React.FC<SaveImagePopupProps> = props => {
  return (
    <div className={styles.popup_wrapper}>
      <div
        className={styles.popup_content}
        onClick={e => {
          console.log("click");
          e.stopPropagation();
          props.onMaskClick?.();
        }}>
        <Image
          className={styles.image}
          src={props.imageUrl}
          placeholder={<DotLoading />}
          onClick={e => {
            e.stopPropagation();
          }}
        />
        <div className={styles.text}>长按可保存图片哦~</div>
      </div>
    </div>
  );
};

export default SaveImagePopup;
