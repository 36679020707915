import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import React, { useRef } from "react";
import { UseVideoShareContext } from "../../hooks/useVideoShare";
import styles from "./index.module.less";

const InvitedShareModal: React.FC = () => {
  const { qrImage, setIsOpenModal, handleDownload, queryAcceptInvite } = UseVideoShareContext();
  const imageRef = useRef<HTMLImageElement>(null);
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const handleModalClick = e => {
    e.stopPropagation();
  };

  return (
    <div className={styles.modalWrapper} onClick={closeModal}>
      <div className={styles.popup} onClick={e => handleModalClick(e)}>
        <div
          className={styles.popupContent}
          style={{
            width: pxtorem(289),
            height: pxtorem(390),
            borderRadius: pxtorem(15),
            marginBottom: pxtorem(30),
          }}>
          <img
            ref={imageRef}
            show-menu-by-longpress="true"
            id="downloadImg"
            src={qrImage}
            lazy-load="false"
            crossOrigin="anonymous"
            style={{
              width: pxtorem(289),
              height: pxtorem(390),
              borderRadius: pxtorem(15),
            }}></img>
        </div>

        <div
          className={styles.popupButton}
          style={{
            justifyContent: platform.isApp ? "space-between" : "center",
          }}>
          <div
            className={`${styles.btn} ${styles.left}`}
            style={{
              width: pxtorem(140),
              height: pxtorem(44),
              borderRadius: pxtorem(6),
              fontSize: pxtorem(16),
              lineHeight: pxtorem(22),
              letterSpacing: pxtorem(0),
              background: platform.isApp ? "#ffe7e7" : "#ff4747",
              color: platform.isApp ? "#ff4747" : "#fff",
            }}
            onClick={queryAcceptInvite}>
            已接受邀请
          </div>
          {platform.isApp && (
            <div
              className={`${styles.btn} ${styles.right}`}
              style={{
                width: pxtorem(140),
                height: pxtorem(44),
                borderRadius: pxtorem(6),
                fontSize: pxtorem(16),
                lineHeight: pxtorem(22),
                letterSpacing: pxtorem(0),
              }}
              onClick={() => handleDownload(qrImage)}>
              保存图片
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default InvitedShareModal;
