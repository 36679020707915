import { ProductDetailModel } from "@akc/biz-api";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
  productDetail?: ProductDetailModel;
  //转发
  onShareClick?: () => void;
  //回到顶部
  onScrollTop?: () => void;
};

/**
 * 浮动菜单按钮
 */
const FloatMenuComp: React.FC<Props> = prop => {
  const product = prop.productDetail;
  return (
    <div className={styles.floatM}>
      {product?.pricePanelType !== 13 && product?.pricePanelType2 !== 7 && (
        <div>
          {!prop?.productDetail?.skuCurrentPriceInfo?.commission ? (
            <div onClick={() => prop.onShareClick?.()}>
              <Image
                className={`${styles["floatIcon"]} ${product?.skuExtendInfoVO?.isHideByManager ? styles["hideDisabled"] : styles[""]}`}
                src={
                  "https://akim-oss.aikucun.com/d87a9bcdb9f1a24f193cbd65fdbd09c14ae936a9_1736994479042_88.png"
                }
              />
            </div>
          ) : (
            <div onClick={() => prop.onShareClick?.()}>
              <Image
                className={`${styles["floatIcon"]} ${product?.skuExtendInfoVO?.isHideByManager ? styles["hideDisabled"] : styles[""]}`}
                src={
                  "https://akim-oss.aikucun.com/d87a9bcdb9f1a24f193cbd65fdbd09c14ae936a9_1736994518745_0.png"
                }
              />
            </div>
          )}
        </div>
      )}
      <div
        onClick={() => {
          prop.onScrollTop?.();
        }}>
        <Image
          className={styles.floatIcon}
          src={
            "https://akim-oss.aikucun.com/d87a9bcdb9f1a24f193cbd65fdbd09c14ae936a9_1736994564734_96.png"
          }
        />
      </div>
    </div>
  );
};

export default FloatMenuComp;
