import { CenterPopup } from "antd-mobile";
import React from "react";
import styles from "./PopForDraw.module.less";

const PopForSign: React.FC<{ visible: boolean; setVisible: any }> = ({ visible, setVisible }) => {
  return (
    <div className={styles.container}>
      <CenterPopup
        visible={visible}
        bodyStyle={{
          backgroundColor: "transparent",
        }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}>
        <div className={styles.wrap}>
          <div className={styles.popBgImg}></div>
          <div className={styles.btn} onClick={() => setVisible(false)}></div>
          <div className={styles.closeBtn} onClick={() => setVisible(false)}></div>
        </div>
      </CenterPopup>
    </div>
  );
};

export default PopForSign;
