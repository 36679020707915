import { ImageURLUitls } from "@akc/foundation";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
  //商品图片
  spuDetailImageUrls?: string[];
  // 特殊类目提示
  categoryHeadAnnouncement?: string;
  //图片点击
  onImgClick?: (imgUrls?: string[], index?: number) => void;
};

/**
 * 商品介绍
 */
const ProductIntroduceComp: React.FC<Props> = ({
  onImgClick,
  spuDetailImageUrls,
  categoryHeadAnnouncement,
}) => {
  let imgList = [
    ...(spuDetailImageUrls ?? []),
    "https://xiangdian-oss.aikucun.com/app_x_ios/a6e99ba0343566875378a282354858e601bc17fb_1719304853522_11.png",
  ];

  return (
    <div className={styles.productIntroduce}>
      <div className={styles.title}>商品介绍</div>
      {(categoryHeadAnnouncement?.length || 0) > 0 && (
        <div className={styles.otcTip}>
          <Image
            className={styles.flag}
            src={
              "https://akim-oss.aikucun.com/e57a4cc247e0d9ea18e081230b0d852d5330c5ef_1729153484580_87.png"
            }
            fit="contain"
          />
          <div className={styles.txt}>{categoryHeadAnnouncement}</div>
        </div>
      )}
      {imgList?.map((item, index) => {
        return (
          <Image
            key={index}
            className={styles.img}
            src={ImageURLUitls.normalProcessImage(item, 375)}
            fit="contain"
            onClick={() => {
              onImgClick?.(imgList, index);
            }}
          />
        );
      })}
    </div>
  );
};

export default ProductIntroduceComp;
