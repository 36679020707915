import { pxtorem } from "@/utils/pxtorem";
import { CalendarPicker } from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "../index.module.less";

const ActiveArrow = ({ active }: { active: boolean }) => {
  const url = useMemo(() => {
    if (active) {
      return "https://akim-oss.aikucun.com/eab584cb0ec09faae0b763225908635712a55796_1713780960643_13.png";
    }
    return "https://akim-oss.aikucun.com/0c360168a0dff329de2d1dfcea991d1faa7f6513_1713780781871_43.png";
  }, [active]);
  return <img style={{ width: pxtorem(10), height: pxtorem(10) }} src={url} />;
};

const CustomDate = ({
  name,
  date,
  active,
  toggle,
}: {
  name: string;
  date: string;
  active: boolean;
  toggle: (open: boolean) => void;
}) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      onClick={() => toggle(true)}>
      {date || name}
      <ActiveArrow active={active}></ActiveArrow>
    </div>
  );
};
//日期格式转化
const formatDate = (start: any, end: any) => {
  if (!start || !end) {
    return "";
  }
  return `${new Date(start).getMonth() + 1}.${
    new Date(start).getDate() < 10 ? "0" + new Date(start).getDate() : new Date(start).getDate()
  }-${new Date(end).getMonth() + 1}.${new Date(end).getDate() < 10 ? "0" + new Date(end).getDate() : new Date(end).getDate()}`;
};

type ValueType = string | number | boolean | [string, string];

interface OptionItem {
  key?: ValueType;
  name: string;
  value?: ValueType;
}

interface IProps {
  type?: "date" | string;
  value?: ValueType;
  onChange?: (value?: ValueType) => void;
  options: OptionItem[];
  resetFlag?: number;
}

const RewardFilterItem: React.FC<IProps> = ({ options, value, onChange, type, resetFlag }) => {
  const [activeValue, setActiveValue] = useState<ValueType | undefined>();
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState("");

  const handleChange = useCallback(
    (value?: ValueType) => {
      console.log("filterItems", value);
      setActiveValue(value);
      if (type === "date" && Array.isArray(value)) {
        return;
      }
      onChange?.(value);
    },
    [type, onChange],
  );

  useEffect(() => {
    console.log("filterItems", value);
    if (type === "date") {
      setDate(Array.isArray(value) ? formatDate(value[0], value[1]) : "");
      setActiveValue(Array.isArray(value) ? "date" : value || 0);
    } else {
      setActiveValue(value);
    }
  }, [resetFlag, value]);

  return (
    <>
      <div className={styles.filterList}>
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={
                styles.filterItem + " " + (item.value === activeValue ? styles.active : "")
              }
              onClick={() => handleChange(item.value)}>
              {type === "date" && item.value === "date" ? (
                <CustomDate
                  name={item.name}
                  date={date}
                  active={item.value === activeValue}
                  toggle={setVisible}></CustomDate>
              ) : (
                item.name
              )}
            </div>
          );
        })}
      </div>
      {type === "date" ? (
        <CalendarPicker
          visible={visible}
          selectionMode="range"
          onClose={() => setVisible(false)}
          onMaskClick={() => setVisible(false)}
          onConfirm={(val: any) => {
            setDate(formatDate(val[0], val[1]));
            onChange?.(val);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default RewardFilterItem;
