import useShare from "@/pages/promote/share";
import { Image, Mask } from "antd-mobile";
import { debounce } from "lodash";
import React, { useState } from "react";

import IconLike from "@/assets/icon_like.png";
import IconLiked from "@/assets/icon_liked.png";
import IconShare from "@/assets/icon_share.png";
import styles from "./index.module.less";

import { setAwesome } from "@/api/promote/index";
import { useSearchParams } from "react-router-dom";
import { formatTimeAgo } from "../../utils/utils";

interface IinteractionDataItem {
  isLike: boolean;
  likedNum: string;
  materialNo: string;
}
interface IProps {
  interactionData: IinteractionDataItem;
  materialBaseInfo: any;
}
const Interaction: React.FC<IProps> = ({ interactionData, materialBaseInfo }) => {
  const [interactionDataStatus, setInteractionDataStatus] =
    React.useState<IinteractionDataItem>(interactionData);
  const [showShareMask, setShowShareMask] = useState<boolean>(false);
  const useShareProps = useShare();
  const [search] = useSearchParams();
  const fromPage = search.get("fromPage");
  const querySetAwesome = debounce(async (interactFlag: number) => {
    try {
      const { success, data }: any = await setAwesome({
        materialNo: interactionData.materialNo,
        interactScene: 1,
        interactFlag: interactFlag,
      });
      if (success) {
        setInteractionDataStatus({
          materialNo: interactionDataStatus.materialNo,
          isLike: !interactionDataStatus.isLike,
          likedNum: data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, 500);

  const handleClickLike = async () => {
    const { isLike } = interactionDataStatus;
    querySetAwesome(isLike ? 1 : 0);
  };
  const handleClickShare = () => {
    setShowShareMask(true);
    const shareType = fromPage === "productDetail" ? 5 : 3;
    useShareProps.shareSetting(shareType, interactionData.materialNo);
  };

  return (
    <div className={styles.TotalInfoInterAction}>
      <div className={styles.UserInfoPublishTime}>
        {formatTimeAgo(materialBaseInfo?.createTimeStamp, materialBaseInfo?.sysTime)}
      </div>
      <div style={{ display: "flex" }}>
        <div className={styles.InterActionBox}>
          <Image
            src={interactionDataStatus.isLike ? IconLiked : IconLike}
            onClick={handleClickLike}
            className={styles.InterActionIcon}
          />
          <span className={styles.InterActionText}>{interactionDataStatus.likedNum}</span>
        </div>
        <div className={styles.InterActionBox}>
          <Image src={IconShare} className={styles.InterActionIcon} onClick={handleClickShare} />
        </div>
      </div>
      <Mask
        visible={showShareMask}
        getContainer={document.getElementById("root")}
        className={styles["share-mask-container"]}
        onMaskClick={() => setShowShareMask(false)}>
        <img
          className={styles["share-guide"]}
          src="https://akim-oss.aikucun.com/342fd5035fac892693a1b04f6a73c3a957a0617d_1700105346385_60.png"
        />
      </Mask>
    </div>
  );
};

export default Interaction;
