import { getBaseurl } from "@/utils/url-parse";
import { API } from "@akc/biz-api";
import { Request } from "./request";

// 查询用户授权绑定状态
const queryBindCheck = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/auth/common/bindCheck`,
    method: "get",
    data: params,
  });
  return res?.data;
};

export interface VisitorInfo {
  /** 0:表示已经不是游客了，2:待绑定手机号，3:待确认绑定 */
  visitorStatus: 0 | 2 | 3;
  /** true：已经授权，false：未授权 */
  isAuthorized?: boolean;
  /** true：已经绑定手机号，false：未绑定手机号 */
  isBindPhone?: boolean;
  /** true：是游客，false：不是游客 */
  isVisitor?: boolean;
  /** 2：安吉这一类第三方内嵌饷店的场景 */
  thirdLoginScene?: 2;
}

/**
 * @link https://yapi.akcops.com/project/669/interface/api/141930
 * @description 获取当前登录用户的visitorStatus
 * @author 郎齐鹏
 */
export const visitorInfo = async () =>
  API.get<VisitorInfo>({
    hostID: "h5shop",
    path: "/api/member/auth/xdwxh5/visitorInfo",
  });

// 管理员-登陆退出
const adminLogout = function () {
  return Request({
    url: `${getBaseurl()}/api/member/logout`,
    method: "post",
  });
};

// 隐藏入口控制
// https://yapi.akcops.com/project/2507/interface/api/394359
const queryHideInfo = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/multi/member/hideEntry`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 设置隐藏
// https://yapi.akcops.com/project/2507/interface/api/394341
const setHideInfo = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/multi/member/setHideInfo`,
    method: "post",
    data: params,
  });
  return res?.data;
};

export { adminLogout, queryBindCheck, queryHideInfo, setHideInfo };
