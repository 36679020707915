import { SortModel, SortType } from "@/api/activity";
import { ProductReqItem, queryProductList } from "@/api/product";
import { getShareConfig } from "@/api/share";
import { SearchParam } from "@/api/type";
import { ProductModel } from "@akc/biz-api";
import constate from "constate";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type SelectiveOptions = {
  activityIdList?: string[];
  brandIds?: string[];
  categoryIdList?: string[];
  shopCodes?: string[];
};
type ProductModelExt = ProductModel & {
  // 当前是否item项选中
  isSelect?: boolean;
  // 是否转发
  isForwarded?: boolean;
  // 规格、描述内容是否支持折叠、展开
  supportExpand?: boolean;
  // 折叠、展开状态
  expandedStatus?: boolean;
  // 一级属性选择的index
  selectedIndex?: number;
};
type SortInfo = {
  name: string;
  sortModel: SortModel;
  sortTypes: SortType[];
};
const useSelectiveProduct = ({
  pageId,
  scene,
  options,
}: {
  pageId: string;
  scene: string;
  options: SelectiveOptions;
}) => {
  const sortList = useMemo<Array<SortInfo>>(() => {
    return [
      {
        name: "综合",
        sortModel: 1,
        sortTypes: [2],
      },
      {
        name: "价格",
        sortModel: 7,
        sortTypes: [1, 2],
      },
      {
        name: "上新",
        sortModel: 6,
        sortTypes: [2, 1],
      },
      {
        name: "销量",
        sortModel: 3,
        sortTypes: [2, 1],
      },
      {
        name: "赚",
        sortModel: 8,
        sortTypes: [2],
      },
    ];
  }, []);
  const [selectedSortModel, setSelectedSortModel] = useState(1);
  const [selectedSortType, setSelectedSortType] = useState(2);
  const [filterInfo, setFilterInfo] = useState<Partial<SearchParam>>();

  const currentPageNum = useRef<number>(1);
  const isLoading = useRef<boolean>(false);
  const currentRequestId = useRef(0);
  const [total, setTotal] = useState("");
  const [hasNext, setHasNext] = useState(true);
  const [productList, setProductList] = useState<Array<ProductModelExt>>([]);
  const [forwardNum, setForwardNum] = useState(9);
  const [checkedProductList, setCheckedProductList] = useState<Array<ProductModelExt>>([]);

  const getLetterWidth = (letter = "", fontSize = 12) => {
    const dom = document.createElement("span");
    dom.style.display = "inline-block";
    dom.style.fontSize = fontSize + "px";
    dom.textContent = letter;
    document.body.appendChild(dom);
    const width = dom?.getBoundingClientRect()?.width;
    dom.remove();
    return Number((width ?? 0).toFixed(2));
  };

  const getSupportExpandStatus = (product: ProductModelExt) => {
    const propertyTextWidth = getLetterWidth(product?.specPropertyList?.join(",") ?? "");
    const styleDescWidth = getLetterWidth(product?.styleDesc ?? "");
    let supportExpandStatus;
    if (propertyTextWidth >= 260 || styleDescWidth >= 260) {
      supportExpandStatus = true;
    } else {
      supportExpandStatus = false;
    }
    return supportExpandStatus;
  };

  const autoCheckedProduct = () => {
    let count = 0;
    let list: Array<ProductModelExt> = [];
    for (let i = 0; i < productList.length; i++) {
      if (
        !productList[i].isSelect &&
        !productList[i].isForwarded &&
        !productList[i].skuExtendInfoVO?.isShare
      ) {
        count += 1;
        list.push(productList[i]);
      }
      if (count >= forwardNum) {
        break;
      }
    }
    if (list?.length) {
      const activitySpuIdList = list.map(item => item.activitySpuId);
      const newProductList = productList.map(item => {
        return {
          ...item,
          isSelect: activitySpuIdList.includes(item.activitySpuId),
        };
      });
      setProductList(newProductList);
    }
    if (list?.length) {
      setTimeout(() => {
        const pageDom: any = document.getElementById("selective-page");
        const productDom: any = document.getElementById(`product-${list[0].activitySpuId}`);
        productDom &&
          pageDom.scrollTo({
            top: productDom.offsetTop,
            behavior: "smooth",
          });
      }, 300);
    }
  };

  const updateProductItem = async (item: ProductReqItem, index: number) => {
    const searchParams = {
      scene: scene,
      pageSize: 1,
      pageNum: 1,
      activityIdList: options?.activityIdList,
      shopCodes: options?.shopCodes,
      brandIds: options?.brandIds || filterInfo?.brandIds,
      categoryIdList: options?.categoryIdList || filterInfo?.categoryIdList,
    };
    const voConfig = {
      showType: 1,
      needIsShare: true,
      autoFillPageSize: true,
      filterSellOutSaleProperty: true,
    };
    const productReqList = [item];
    const res = await queryProductList(pageId, searchParams, voConfig, productReqList);
    if (res?.result?.length) {
      const newProductItem: ProductModelExt = res.result[0];
      newProductItem.isSelect =
        productList[index].isSelect || checkedProductList.length < forwardNum;
      newProductItem.selectedIndex =
        newProductItem?.salePropertyFirstList?.findIndex(
          i => i.value === item.firstSalePropertyValue,
        ) ?? 0;
      const newProductList = productList.map((item, i) => {
        if (i === index) {
          return {
            ...newProductItem,
            supportExpand: getSupportExpandStatus(newProductItem),
            expandedStatus: false,
          };
        }
        return item;
      });
      setProductList(newProductList);
    }
  };

  const getProductList = async (requestId: number) => {
    isLoading.current = true;
    const res = await queryProductList(
      pageId,
      {
        scene: scene,
        pageSize: 20,
        pageNum: currentPageNum.current,

        activityIdList: options?.activityIdList,
        shopCodes: options?.shopCodes,
        brandIds: options?.brandIds || filterInfo?.brandIds,
        categoryIdList: options?.categoryIdList || filterInfo?.categoryIdList,

        sortModel: selectedSortModel,
        sortType: selectedSortType,
        soldOutStatus: filterInfo?.soldOutStatus,
        serviceTag: filterInfo?.serviceTag,
        promotionTypes: filterInfo?.promotionTypes,
        guideProperties: filterInfo?.guideProperties,
        saleProperties: filterInfo?.saleProperties,
        priceHigh: filterInfo?.priceHigh,
        priceLow: filterInfo?.priceLow,
        activityStatus: filterInfo?.activityStatus,
      },
      {
        showType: 1,
        needIsShare: true,
        autoFillPageSize: true,
        filterSellOutSaleProperty: true,
      },
    );
    if (requestId !== currentRequestId.current) {
      return;
    }
    const num = Number(res.totalCount) > 9 ? 9 : Number(res.totalCount);
    setHasNext(res.hasNext);
    setTotal(res.total);
    setForwardNum(num);
    if (currentPageNum.current === 1 && res.result?.length) {
      let count = 0;
      let templateList: Array<ProductModelExt> = res.result;
      templateList.forEach((product, index) => {
        product.rank = index;
        (product.supportExpand = getSupportExpandStatus(product)), (product.expandedStatus = false);
        if (!product.skuExtendInfoVO?.isShare && count < num) {
          count += 1;
          product.isSelect = true;
        } else {
          product.isSelect = false;
        }
      });
      setProductList(templateList);
      if (count > 0) {
        setTimeout(() => {
          const activitySpuId = templateList.find(i => !!i.isSelect)?.activitySpuId;
          const pageDom: any = document.getElementById("selective-page");
          const productDom: any = document.getElementById(`product-${activitySpuId}`);
          productDom &&
            pageDom.scrollTo({
              top: productDom.offsetTop,
              behavior: "smooth",
            });
          if (res.hasNext) {
            currentRequestId.current = Math.floor(Math.random() * 10000000000);
            getProductList(currentRequestId.current);
          }
        }, 300);
      }
    } else {
      setProductList(productList => {
        return [
          ...productList,
          ...(res.result?.map((item, index) => ({
            ...item,
            rank: productList.length + index,
            supportExpand: getSupportExpandStatus(item),
            expandedStatus: false,
            isSelect: false,
          })) ?? []),
        ];
      });
    }
    currentPageNum.current = res.pageIndex + 1;
    isLoading.current = false;
  };

  const resetProductList = () => {
    setProductList([]);
    setCheckedProductList([]);
    setHasNext(true);
    currentPageNum.current = 1;
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    getProductList(currentRequestId.current);
  };

  const loadMore = async () => {
    if (typeof currentPageNum.current === "undefined" || isLoading.current) {
      return;
    }
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    await getProductList(currentRequestId.current);
  };

  const queryShareConfig = async () => {
    const data: any = await getShareConfig();
    setForwardNum(data?.forwardNum ?? 9);
  };

  useEffect(() => {
    queryShareConfig();
  }, []);

  const hasFilterActive = useMemo(() => {
    return (
      typeof filterInfo?.soldOutStatus !== "undefined" ||
      (filterInfo?.serviceTag ?? []).length > 0 ||
      (options?.categoryIdList ? [] : (filterInfo?.categoryIdList ?? [])).length > 0 ||
      (options?.brandIds ? [] : (filterInfo?.brandIds ?? [])).length > 0 ||
      (filterInfo?.promotionTypes ?? []).length > 0 ||
      (filterInfo?.guideProperties ?? []).length > 0 ||
      (filterInfo?.saleProperties ?? []).length > 0 ||
      (filterInfo?.activityStatus ?? []).length > 0 ||
      filterInfo?.priceHigh ||
      filterInfo?.priceLow
    );
  }, [filterInfo]);

  useEffect(() => {
    resetProductList();
  }, [selectedSortModel, selectedSortType]);

  useEffect(() => {
    if (filterInfo !== undefined) {
      resetProductList();
    }
  }, [filterInfo]);

  useEffect(() => {
    if (!productList || productList.length === 0) {
      setCheckedProductList([]);
      return;
    }
    if (productList.length) {
      const list = productList.filter(item => item.isSelect) || [];
      setCheckedProductList(list);
    }
  }, [productList]);

  return {
    sortList,
    selectedSortModel,
    setSelectedSortModel,
    selectedSortType,
    setSelectedSortType,
    forwardNum,
    autoCheckedProduct,
    checkedProductList,
    setCheckedProductList,
    total,
    hasNext,
    productList,
    setProductList,
    loadMore,
    updateProductItem,
    setFilterInfo,
    hasFilterActive,
  };
};

const [SelectiveProductProvider, UseSelectiveProductContext] = constate(useSelectiveProduct);
export { SelectiveProductProvider, UseSelectiveProductContext };
