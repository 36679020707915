import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { ActivityBrandModel } from "@akc/biz-api";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  useBrandIds,
  useIsEmptyStatus,
  useProductList,
  useSelectedActivity,
  useSetBrandIds,
} from "../hooks/useActivityProduct";
import ActivitySelector from "./ActivitySelector";
import BrandTabs from "./BrandTabs";
import styles from "./LandingProductList.module.less";
import ProductList from "./ProductList1";
import SortAndFilter from "./SortAndFilter";

interface Props {
  ref: any;
  onScrollTop: () => void;
  setDisableScroll: (bool) => void;
  brandList: any;
  activeBrandIndex: number;
  setActiveBrandIndex: (index) => void;
  brandData: any;
  selectedActivityId: string;
  setSelectedActivityId: (index) => void;
  setShowEmptyStatus: (bool) => void;
  handleSetIsSticky: (bool) => void;
  trackData?: any;
}

const LandingProductList: React.FC<Props> = forwardRef(
  (
    {
      onScrollTop,
      setDisableScroll,
      brandList,
      activeBrandIndex,
      setActiveBrandIndex,
      brandData,
      selectedActivityId,
      setSelectedActivityId,
      setShowEmptyStatus,
      handleSetIsSticky,
      trackData,
    },
    ref,
  ) => {
    const brandIds = useBrandIds();
    const setBrandIds = useSetBrandIds();
    const selectedActivity = useSelectedActivity();
    const productList = useProductList();
    const isEmptyStatus = useIsEmptyStatus();

    const watchStickyRef = useRef<any>(null);
    const [isSticky, setIsSticky] = useState(false);
    const sortAndFilterDomRef = useRef<any>(null);
    const [showPanel, setShowPanel] = useState(false);
    const selectorContainerDom = useRef<HTMLDivElement>(null);
    const sortAndFilterWrapper = useRef<HTMLDivElement>(null);
    const productWaterfallDom = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!watchStickyRef.current) {
        return;
      }
      const observer = new IntersectionObserver(entries => {
        const entry = entries[0];
        setIsSticky(!entry.intersectionRatio);
        handleSetIsSticky(!entry.intersectionRatio);
      });
      observer.observe(watchStickyRef.current);

      return () => {
        observer?.disconnect();
      };
    }, [watchStickyRef.current]);

    useEffect(() => {
      setShowEmptyStatus(brandList.length === 0 && isEmptyStatus);
    }, [isEmptyStatus]);

    useEffect(() => {
      if (brandList.length === 1) {
        setBrandIds([brandList?.[0]?.brandId]);
      }
    }, [brandList]);

    useImperativeHandle(ref, () => {
      return {
        productList,
        selectedActivity,
      };
    });

    return (
      <div className={styles.body__product}>
        <div ref={watchStickyRef} className={styles["scroll__watch-sticky-line"]}></div>
        <div className={styles.product__sticky}>
          {platform.isH5 && (
            <div
              style={{
                position: isSticky ? "relative" : "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                background: isSticky ? "#fff" : "transparent",
              }}></div>
          )}

          {brandList?.length > 1 && (
            <BrandTabs
              list={brandList}
              activeTabIndex={activeBrandIndex}
              brandData={brandData}
              isSticky={isSticky}
              onChangeActiveTabIndex={index => {
                setActiveBrandIndex(index);
                // sortAndFilterDomRef.current.resetFilter?.();
                onScrollTop();
              }}
              onChangeShowPanel={bool => {
                setDisableScroll(bool);
                setShowPanel(bool);
              }}
            />
          )}
          <div
            className={styles.sticky__body}
            style={{
              borderRadius: isSticky || showPanel ? "0" : "10px 10px 0 0",
            }}>
            {brandData?.activityList?.length > 0 && (
              <div ref={selectorContainerDom}>
                <ActivitySelector
                  brandData={brandData}
                  initialSelectedActivityId={selectedActivityId}
                  onSelectionChange={id => {
                    setSelectedActivityId(id);
                    sortAndFilterDomRef.current.resetFilter?.();
                    onScrollTop();
                  }}
                />
              </div>
            )}

            <div
              ref={sortAndFilterWrapper}
              className={styles.sticky}
              style={{
                zIndex: 2,
                top: pxtorem(44),
                borderRadius: isSticky ? "10px" : 0,
              }}>
              <SortAndFilter
                ref={sortAndFilterDomRef}
                tabData={brandList[activeBrandIndex]}
                brandData={brandData}
                onSortFilterChange={() => {
                  // TODO
                  // productWaterfallDom.current?.scrollIntoView();
                  // console.log("11", isCategoryTabSticky.current);
                  // if (isCategoryTabSticky.current) {
                  //   productWaterfallDom.current?.scrollIntoView();
                  // }
                  onScrollTop();
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={productWaterfallDom}
          style={{
            scrollMarginTop:
              brandData.activityList && brandData.activityList.length > 1
                ? pxtorem(140)
                : pxtorem(88),
          }}
          className={styles.product__list}>
          <ProductList trackData={trackData} />
        </div>
      </div>
    );
  },
);

export default LandingProductList;
