import { MaterialContextContentVideoModel } from "@akc/biz-api";
import React from "react";
import VideoPlayComp from "../../../video/video";
import styles from "./index.module.less";
type Props = {
  videoInfo?: MaterialContextContentVideoModel;
};
const ItemVideoComp: React.FC<Props> = ({ videoInfo }) => {
  return (
    <div className={styles.resItemVideo}>
      <VideoPlayComp
        width={300}
        height={200}
        videoUrl={videoInfo?.video ?? ""}
        thumbnail={videoInfo?.thumbnail}
        paddingLeft={12}
        paddingRight={12}
      />
    </div>
  );
};

export default ItemVideoComp;
