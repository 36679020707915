import { pxtorem } from "@/utils/pxtorem";
import { Image, Popup } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

type Props = {
  visible: boolean;
  onClose?: () => void;
  onGoSetting?: () => void;
};

/**
 * 素材商详引导弹窗
 */
const GuideDialog: React.FC<Props> = props => {
  return (
    <Popup
      visible={props.visible}
      onMaskClick={() => {
        props.onClose?.();
      }}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        zIndex: 10000,
      }}>
      <div className={styles.materialPGuideC}>
        <div className={styles.top}>
          <div className={styles.title}>商品详情样式</div>
          <Image
            className={styles.close}
            src="https://devdevnew.obs.cn-east-3.myhuaweicloud.com/taro/resources/close_black.png"
            onClick={() => {
              props.onClose?.();
            }}
          />
        </div>
        <div className={styles.body}>
          <div className={styles.desc}>
            当前商详为<span className={styles.selected}>「素材商详」</span>
            样式，可提高转化率，若要切回之前样式，可到
            <span className={styles.selected}>“我的”-“转发设置”</span>下切换
          </div>
          <div className={styles.imgC}>
            <Image
              className={styles.material}
              width={pxtorem(320)}
              height={pxtorem(320)}
              fit="contain"
              src="https://xiangdian-oss.aikucun.com/app_x_ios/ae5472fcebaa7120b163f536c110370bcfa912c1_1728642854641_19.png"
            />
            <Image
              className={styles.general}
              width={pxtorem(320)}
              height={pxtorem(320)}
              fit="contain"
              src="https://xiangdian-oss.aikucun.com/app_x_ios/00b08592db008045ec51117c35190956e7631d7c_1728642587661_33.png"
            />
          </div>
        </div>
        <div className={styles.bottomC}>
          <div
            className={styles.settingBtn}
            onClick={() => {
              props.onGoSetting?.();
            }}>
            去设置
          </div>
          <div
            className={styles.confirmBtn}
            onClick={() => {
              props.onClose?.();
            }}>
            我知道了
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default GuideDialog;
