import { ReactComponent as MinusIcon } from "@/assets/minus.svg";
import { ReactComponent as PlusIcon } from "@/assets/plus.svg";
import React from "react";
import styles from "./index.module.less";

interface StepperProps {
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  change?: (value: number) => void;
  overlimit?: (e: { type: string; value: number }) => void;
}

const Stepper: React.FC<StepperProps> = ({
  value = 0,
  min = 0,
  max = 9999,
  step = 1,
  disabled = false,
  overlimit,
  change,
}) => {
  const blur = e => {
    let value = e.target.value;
    value = value.replace(/^0+/, "");
    const num = Math.min(Math.max(min, Number(value)), max);
    if (Number(value) > max) {
      overlimit && overlimit({ type: "plus", value: Number(value) });
    } else if (Number(value) < min) {
      overlimit && overlimit({ type: "mins", value: Number(value) });
    }
    change && change(num);
  };
  const minus = () => {
    if (value > min) {
      change && change(Math.max(min, value - step));
    } else {
      overlimit && overlimit({ type: "mins", value: Number(value) });
      change && change(value);
    }
  };
  const plus = () => {
    if (value < max) {
      change && change(Math.min(max, value + step));
    } else {
      overlimit && overlimit({ type: "plus", value: Number(value) });
      change && change(Math.min(max, value + step));
    }
  };
  const changeInput = e => {
    change && change(e.target.value);
  };

  return (
    <>
      <div className={styles["mx-stepper"]}>
        <div
          className={`${styles["mx-stepper__action"]} ${styles["minus"]} ${
            disabled || value <= min ? styles["disabled"] : ""
          }`}
          onClick={minus}>
          <MinusIcon className={styles.icon} />
        </div>
        <div className={styles["mx-stepper__input"]}>
          <input
            type="number"
            className={`${styles["input"]} ${disabled ? styles["disabled"] : ""}`}
            value={value}
            onInput={changeInput}
            onBlur={blur}
          />
        </div>
        <div
          className={`${styles["mx-stepper__action"]} ${styles["plus"]} ${
            disabled || value >= max ? styles["disabled"] : ""
          }`}
          onClick={plus}>
          <PlusIcon className={styles.icon} />
        </div>
      </div>
    </>
  );
};

export default Stepper;
