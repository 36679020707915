import useSkip from "@/pages/userTrack/hooks/useSkip";
import React from "react";
import styles from "./index.module.less";

const empty: React.FC = () => {
  const { goHome } = useSkip();
  return (
    <>
      <div className={styles["empty-wrapper"]}>
        <img
          className={styles["image"]}
          src="https://akim-oss.aikucun.com/004922066efd0f47bcb6e8caace8db91817298ba_1711016117251_60.png"></img>
        您还没有浏览记录
        <div className={styles["back-to-index"]} onClick={goHome}>
          回首页
        </div>
      </div>
    </>
  );
};

export default empty;
