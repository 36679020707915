import { subscribe, unSubscribe } from "@/api/decorate";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Switch, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import PopForSign from "./PopForSign";
import SignCalendar from "./SignCalendar";
import SignCard from "./SignCard";
import styles from "./SignList.module.less";

const SignList: React.FC<{ activityInfo: any; handleSignIn: any }> = ({
  activityInfo,
  handleSignIn,
}) => {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [enableRemind, setEnableRemind] = useState(false);
  const [popForSignVisible, setPopForSignVisible] = useState(false);
  const popstateCallback = useRef<any>(null);

  const { navigate } = useNavigation();

  const subscribeMessage = () => {
    // if (platform.isSaas) {
    //   return;
    // }
    setEnableRemind(true);
    if (platform.isApp && !enableRemind) {
      // app推送消息
      AKJS.ready().then(() => {
        AKJS.action
          .dispatch("natPermissions", {
            type: "notification",
          })
          .then(res => {
            console.log(11111, res.data.granted);
            if (res.data.granted) {
              // 订阅消息
              subscribe({
                bizType: "DAILY_AWARD",
                bizNo: activityInfo.promoActivityId,
              })
                .then(() => {
                  Toast.show("订阅成功");
                })
                .catch(err => {
                  Toast.show("订阅失败");
                  setEnableRemind(false);
                });
            }
          });
      });
    } else if (platform.isMini && !enableRemind) {
      // 跳到小程序订阅页面
      let tempKey = "daily_reward";
      window.wx.miniProgram.navigateTo({
        url: `/order/pages/subscribe-message/subscribe-message?tempKey=${tempKey}&bizType=DAILY_AWARD&bizNo=${activityInfo.promoActivityId}&modalContent=日日奖签到订阅&tempName=签到提醒&bgImg=https://akim-oss.aikucun.com/902ba3cda1883801594b6e1b452790cc53948fda_1725591882855_32.png`,
      });
    }
  };

  const unSubscribeMessage = () => {
    setEnableRemind(false);
    // 取消订阅
    unSubscribe({
      bizType: "DAILY_AWARD",
      bizNo: activityInfo.promoActivityId,
    })
      .then(() => {
        Toast.show("已取消订阅");
      })
      .catch(err => {
        setEnableRemind(true);
      });
  };

  const toMyReward = () => {
    const currentTime = new Date().getTime()?.toString();
    const query = {
      promoActivityId: activityInfo?.promoActivityId,
      timestamp: currentTime,
    };
    navigate({
      h5Options: {
        url: "/mx-shop-micro/reward",
        query: query,
      },
      appOptions: {
        url: `${origin}/mx-shop-micro/reward?promoActivityId=${activityInfo?.promoActivityId}&timestamp=${currentTime}`,
      },
      miniOptions: {
        url: `${origin}/mx-shop-micro/reward`,
        query: query,
      },
    });
  };

  const onClickSignIn = async () => {
    if (activityInfo.lotteryInfo?.btnStatus !== 2) {
      return;
    }
    await handleSignIn();
    setPopForSignVisible(false);
    setTimeout(() => {
      if (platform.isMini) {
        subscribeMessage();
      } else if (platform.isApp) {
        subscribeMessage();
      }
    }, 1000);
  };

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".todaySignCard")?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }, 1000);
    if (activityInfo.lotteryInfo?.btnStatus === 2) {
      setPopForSignVisible(true);
    }
    setEnableRemind(activityInfo.enableRemind);
  }, [activityInfo]);

  useEffect(() => {
    if (platform.isMini) {
      popstateCallback.current = () => {
        if (window.location.hash) {
          const hash = location.hash.split("#")[1];
          if (hash === "subscribeTrue") {
            setTimeout(() => {
              setEnableRemind(true);
            }, 500);
          } else if (hash === "subscribeFalse") {
            setTimeout(() => {
              setEnableRemind(false);
            }, 500);
          }
        }
      };
      window.addEventListener("popstate", popstateCallback.current);
    }
    return () => {
      popstateCallback.current && window.removeEventListener("popstate", popstateCallback.current);
    };
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <span>连续签到可抽奖</span>
          <img
            className={styles.titleImg}
            src="https://akim-oss.aikucun.com/mshop/6411f0e0fd558cb53a417bd78edb58ca1dfde7c8_1728894904638_77.png"
          />
        </div>
        {!platform.isH5 && (
          <div className={styles.headerRight}>
            <span>提醒</span>
            <Switch
              checked={enableRemind}
              disabled={activityInfo.status !== "BEGIN"}
              style={{ "--width": "32px", "--height": "18px" }}
              onChange={isOpen => (isOpen ? subscribeMessage() : unSubscribeMessage())}
              className={styles.switch}
            />
          </div>
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.list}>
          {activityInfo.signInTaskInfo?.signInItemList.map((item, index) => (
            <SignCard key={index} info={item} />
          ))}
        </div>
      </div>
      <div className={styles.bottom}>
        <img
          onClick={() => setCalendarVisible(true)}
          className={styles.bottomLeft}
          src="https://akim-oss.aikucun.com/e97c9c00e8d5ae2dc9d41aac60dd45f3040a84f9_1723084181452_73.png"
        />
        <div
          className={
            styles.bottomCenter +
            " " +
            ([1, 2].includes(activityInfo.lotteryInfo?.btnStatus) ? styles.primary : "")
          }
          onClick={onClickSignIn}>
          {activityInfo.lotteryInfo?.btnStatusDesc || "未开始"}
        </div>
        <img
          onClick={toMyReward}
          className={styles.bottomRight}
          src="https://akim-oss.aikucun.com/356a192b7913b04c54574d18c28d46e6395428ab_1723084180715_38.png"
        />
      </div>
      <SignCalendar
        visible={calendarVisible}
        setVisible={setCalendarVisible}
        promoActivityId={activityInfo.promoActivityId}
        limitStartTime={activityInfo.startTime}
        limitEndTime={activityInfo.endTime}
        activityInfo={activityInfo}
      />
      <PopForSign
        visible={popForSignVisible}
        setVisible={setPopForSignVisible}
        handleSignIn={onClickSignIn}
      />
    </div>
  );
};

export default SignList;
