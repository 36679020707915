import { queryProductSnapshotDetail } from "@/api/product";
import { ProductSnapshot } from "@/models/product";
import useNavigation from "@/utils/useNavigate";
import { MultiImageViewerRef } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useTradeSnapshotDetail = () => {
  const [search] = useSearchParams();
  const itemCode = search.get("itemCode");
  const skuId = search.get("skuId");
  const spuVersion = search.get("spuVersion");
  const spuId = search.get("spuId");
  const activityId = search.get("activityId");
  const { navigate2Product } = useNavigation();
  //快照详情
  const [snapshotDetail, setSnapshotDetail] = useState<ProductSnapshot>();
  //图片预览
  const [previewPicList, setPreviewPicList] = useState<string[]>([]);
  //图片预览是否显示
  const [showPicPreview, setShowPicPreview] = useState<boolean>(false);
  //图片预览默认 index
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  //预览组件ref
  const previewRef = useRef<MultiImageViewerRef>(null);
  //商品参数弹窗是否显示
  const [showProductParams, setShowProductParams] = useState<boolean>(false);

  //请求快照详情
  const fetchSnapshotDetail = () => {
    queryProductSnapshotDetail({
      itemCode: itemCode,
      skuId: skuId,
      spuVersion: spuVersion ? Number(spuVersion) : undefined,
    })
      .then(res => {
        setSnapshotDetail(res);
      })
      .catch(e => {
        console.log("queryProductSnapshotDetail>>>error=", e);
      });
  };

  useEffect(() => {
    document.title = "交易快照";
    fetchSnapshotDetail();
  }, []);

  //跳转商品详情
  const goProductDetail = () => {
    if (!spuId) {
      return;
    }
    navigate2Product({
      activitySpuId: spuId,
      activityId: activityId ? activityId : undefined,
      selectSkuId: skuId ? skuId : undefined,
    });
  };

  return {
    snapshotDetail,
    previewPicList,
    showPicPreview,
    previewIndex,
    setPreviewPicList,
    setShowPicPreview,
    setPreviewIndex,
    previewRef,
    goProductDetail,
    showProductParams,
    setShowProductParams,
  };
};
