import { Tabs } from "antd-mobile";
import React from "react";
import { TopTabModel } from "../../model/TopTabModel";
import styles from "./index.module.less";

type Props = {
  activeKey?: string;
  tabItems?: TopTabModel[];
};

/**
 * 顶部tab栏
 */
const TopTabsComp: React.FC<Props> = prop => {
  const [activeKey, setActiveKey] = React.useState(prop.activeKey ?? "1");

  React.useEffect(() => {
    setActiveKey(prop.activeKey ?? "1");
  }, [prop.activeKey]);

  if ((prop.tabItems?.length ?? 0) <= 0) {
    return <></>;
  }
  return (
    <div className={styles.topTabs}>
      <Tabs
        activeKey={activeKey}
        style={{
          "--title-font-size": "16px",
          "--active-line-color": "#FF4747",
          "--active-title-color": "#222222",
          "--active-line-height": "2px",
          "--content-padding": "8px",
        }}
        onChange={key => {
          setActiveKey(key);
          const element = document.getElementById(`anchor-${key}`);
          if (element) {
            const rect = element.getBoundingClientRect();
            const offset = rect.top + window.pageYOffset - 44;
            window?.scrollTo({
              top: offset,
              behavior: "smooth",
            });
          }
        }}>
        {prop.tabItems?.map(item => <Tabs.Tab title={item.title} key={item.key} />)}
      </Tabs>
    </div>
  );
};

export default TopTabsComp;
