import {
  formatDate,
  sevenDayBeforeYYMMDD,
  thirtyDayBeforeYYMMDD,
  yesterdayYYMMDD,
} from "@/utils/date-format";
import { Toast } from "antd-mobile";
import { useCallback, useEffect, useState } from "react";
import { DateSelectDialogProps } from "..";

export const useDateDialog = (props: DateSelectDialogProps) => {
  const tabList = [
    { name: "时间选择", value: 1 },
    { name: "自定义", value: 2 },
  ];
  const dateFixList = [
    {
      name: "近一个月",
      fixType: "month",
      startDate: thirtyDayBeforeYYMMDD(new Date()),
      endDate: formatDate(new Date()),
    },
    {
      name: "近一周",
      fixType: "week",
      startDate: sevenDayBeforeYYMMDD(new Date()),
      endDate: formatDate(new Date()),
    },
    {
      name: "今天",
      fixType: "today",
      startDate: formatDate(new Date()),
      endDate: formatDate(new Date()),
    },
    {
      name: "昨天",
      fixType: "yesterday",
      startDate: yesterdayYYMMDD(new Date()),
      endDate: yesterdayYYMMDD(new Date()),
    },
  ];
  const [curTabIndex, setCurTabIndex] = useState<number>(props.defaultRange?.tabIndex ?? 0);
  const [curFixIndex, setCurFixIndex] = useState<number>(props.defaultRange?.fixIndex ?? -1);
  const [currentTimeIndex, setCurrentTimeIndex] = useState<number>(0);
  const [startDate, setStartDate] = useState<string | undefined>(
    props.defaultRange?.tabIndex === 1 && props.defaultRange?.startDate
      ? props.defaultRange?.startDate
      : undefined,
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    props.defaultRange?.tabIndex === 1 && props.defaultRange?.endDate
      ? props.defaultRange?.endDate
      : undefined,
  );
  const [minDate, setMinDate] = useState(new Date(2017, 8, 1));
  const [maxDate, setMaxDate] = useState(new Date());

  const labelRenderer = useCallback((type: string, data) => {
    switch (type) {
      case "year":
        return data + "年";
      case "month":
        return data + "月";
      case "day":
        return data + "日";
      case "hour":
        return data + "时";
      case "minute":
        return data + "分";
      case "second":
        return data + "秒";
      default:
        return data;
    }
  }, []);

  useEffect(() => {
    if (props.popVisible) {
      setCurTabIndex(props.defaultRange?.tabIndex ?? 0);
      setCurFixIndex(props.defaultRange?.fixIndex ?? -1);
      setStartDate(
        props.defaultRange?.tabIndex === 1 && props.defaultRange?.startDate
          ? props.defaultRange?.startDate
          : "",
      );
      setEndDate(
        props.defaultRange?.tabIndex === 1 && props.defaultRange?.endDate
          ? props.defaultRange?.endDate
          : "",
      );
    }
  }, [props.popVisible]);

  const timeChange = date => {
    if (currentTimeIndex === 0) {
      setStartDate(formatDate(date));
      setMinDate(date);
    } else if (currentTimeIndex === 1) {
      setEndDate(formatDate(date));
      setMaxDate(date);
    }
  };

  const getDatePickerDefaultValue = () => {
    if (currentTimeIndex === 0) {
      return startDate && startDate?.length > 0 ? new Date(startDate) : new Date();
    }
    return endDate && endDate?.length > 0 ? new Date(endDate) : new Date();
  };
  const onClose = () => {
    props?.close?.();
  };
  const comfirm = () => {
    if (curTabIndex === 1 && (!startDate || !endDate)) {
      Toast.show({ content: "请选择开始时间/结束时间", maskStyle: { zIndex: 100001 } });
      return;
    }
    if (curTabIndex === 0) {
      props?.getDateRange?.({
        startDate: curFixIndex >= 0 ? dateFixList[curFixIndex]?.startDate : undefined,
        endDate: curFixIndex >= 0 ? dateFixList[curFixIndex]?.endDate : undefined,
        tabIndex: curTabIndex,
        fixIndex: curFixIndex,
        fixName: curFixIndex >= 0 ? dateFixList[curFixIndex]?.name : undefined,
      });
    } else {
      props?.getDateRange?.({
        startDate: startDate,
        endDate: endDate,
        tabIndex: curTabIndex,
      });
    }

    onClose();
  };

  return {
    tabList,
    onClose,
    comfirm,
    curTabIndex,
    currentTimeIndex,
    startDate,
    endDate,
    timeChange,
    minDate,
    maxDate,
    setCurTabIndex,
    setCurrentTimeIndex,
    labelRenderer,
    dateFixList,
    curFixIndex,
    setCurFixIndex,
    getDatePickerDefaultValue,
  };
};
