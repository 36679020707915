import { useRef, useState } from "react";
import { StudyTourSelectInfo, StudyTourSignUpDayListInfo, YouXueSignUp } from "../../model";

export interface PopupProps {
  visible: boolean;
  studyTourInfo?: StudyTourSelectInfo[];
  tab?: string;
  onVisiblePress: (visible?: boolean) => void;
  onDeletePress: () => void;
}

const useSelectList = (props?: PopupProps) => {
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [deleteRefresh, setDeleteRefresh] = useState<boolean>(false);
  const selectStudyTourDetail = useRef<YouXueSignUp>();
  const onDetailDialogVisible = (show?: boolean) => setDetailVisible(show ?? false);

  const onSureAction = (signUpDetail?: YouXueSignUp) => {
    props?.studyTourInfo?.map(item => {
      item?.list?.forEach(i => {
        i.studyTourSignUpList?.forEach(t => {
          if (t?.studyTourId === signUpDetail?.studyTourId) {
            t.isSelect = false;
            setDeleteRefresh(!deleteRefresh);
            return;
          }
        });
      });
    });
    props?.onDeletePress?.();
  };

  let lists: StudyTourSignUpDayListInfo[] = [];
  props?.studyTourInfo?.map(item => {
    console.log(item.tab, props?.tab);
    if (item.tab === props?.tab) {
      lists = [...lists, ...(item?.list ?? [])];
    }
  });

  const showEmptyList = () => {
    let isEmpty = true;
    props?.studyTourInfo?.map(item => {
      if (item.tab === props?.tab) {
        item.list?.forEach(i => {
          i.studyTourSignUpList?.forEach(i => {
            if (i?.isSelect) {
              isEmpty = false;
            }
          });
        });
      }
    });
    return isEmpty;
  };

  const onClose = () => {
    props?.onVisiblePress(false);
  };

  const onActivityDetailAction = (signUpDetail?: YouXueSignUp) => {
    selectStudyTourDetail.current = signUpDetail;
    setDetailVisible(true);
  };

  return {
    lists,
    detailVisible,
    deleteRefresh,
    selectStudyTourDetail,
    onDetailDialogVisible,
    onSureAction,
    showEmptyList,
    onClose,
    onActivityDetailAction,
  };
};

export default useSelectList;
