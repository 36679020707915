import { getHighCommission } from "@/api/shopPlus";
import goBack from "@/assets/go_back.png";
import ProductWaterfall from "@/components/product-waterfall";
import { identity } from "@/utils/identity";
import { MICRO_APP_BASEURL, platform } from "@/utils/platform";
import { Image } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./HighCommission.module.less";

interface Props {
  appStore?: any;
  [key: string]: any;
}

const HighCommission: React.FC<Props> = ({ appStore }) => {
  const navigater = useNavigate();
  const [prodArr, setProdArr] = useState<any>([]);
  const [leftProd, setLeftProd] = useState<any>([]);
  const [arrKey, setArrKey] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [search] = useSearchParams();
  const [, setPage] = useState();

  const [pageNum, setPageNum] = useState(0);

  const [queryLoading, setQueryLoading] = useState(false);

  useEffect(() => {
    if (prodArr.length > 0 && leftProd.length < 1) {
      setLeftProd([prodArr[arrKey]]);
      setArrKey(1);
    }
  }, [prodArr]);

  const querHighCommission = async ({ from = 0 }) => {
    const shopId = search.get("merchantShopCode");

    if (queryLoading) {
      return;
    }
    setQueryLoading(true);

    const res: any = await getHighCommission({
      merShopCode: shopId,
      pageId: "H5_Shop",
      pageNum: from + 1,
      pageSize: 20,
    });

    if (res?.data?.result) {
      setPageNum(res?.data?.pageIndex ?? 0);
      setPage(res?.data?.pages ?? 0);
      setHasMore(res?.data?.hasNext ?? false);
      if (from > 0) {
        const valArr = res?.data?.result ? prodArr.concat(res?.data?.result) : res?.data?.result;
        setProdArr(valArr ?? []);
      } else {
        setProdArr(res?.data?.result ?? []);
      }
    }

    setQueryLoading(false);
  };

  useEffect(() => {
    document.body.style.overflow = "unset";
    document.title = identity() ? "品质甄选" : "高佣加码";
    querHighCommission({});
  }, []);

  //加载更多
  const loadMore = async () => {
    await querHighCommission({ from: pageNum });
  };
  const shopId = search.get("merchantShopCode");

  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  return (
    <>
      <div className={styles["high-commission-contain"]}>
        {(platform.isH5 || platform.isWxH5) && (
          <Image className={styles["go_back"]} src={goBack} onClick={() => goBackOrHome()}></Image>
        )}

        <div className={styles["high-commission"]}>
          <ProductWaterfall
            productList={prodArr}
            hasNext={hasMore}
            loadMore={loadMore}
            provideBackground={false}
          />
        </div>
      </div>
    </>
  );
};

export default inject("appStore")(observer(HighCommission));
