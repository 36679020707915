import { getBaseurl } from "@/utils/url-parse";
import { RewardProductModel, SKUBaseInfo, SpuSalePropertyModel } from "@akc/biz-api";
import { AxiosResponse } from "axios";
import moment from "moment";
import { Request, Response } from "./request";

export interface ActivityTaskParams {
  // 搜索关键词
  searchKeyword?: string;
  shopCode?: string;
  // 参与者类型 MERCHANT-商家 IDOL-爱豆 SHOP_OWNER-店长 CONSUMER-消费者
  participantType?: "MERCHANT" | "IDOL" | "SHOP_OWNER" | "CONSUMER";
  // 1=限时激励 2=权益
  searchType?: 0 | 1 | 2 | 3 | 4 | 5;
  // 业务类型 满返任务：FULL_RETURN PK活动： RANK 满赠任务： GIFT_RETURN
  promoActivityType?: "FULL_RETURN" | "RANK" | "GIFT_RETURN";
  // 活动状态：10 未参与 20 进行中 30 待开奖/开奖中 40 已结束/已开奖
  actSellRebateStatus?: 10 | 20 | 30 | 40;
  // 中奖状态
  taskWinningStatus?: "HAS_GMV" | "WINNING" | "NOT_WINNING_HAS_GMV" | "NOT_WINNING_NO_GMV";
  // 是否中奖
  isDraw?: boolean;
  // 1= 全部店铺 2=关注店铺
  shopType?: 1 | 2;
  // 活动时间范围标示
  date?: 0 | 7 | 30 | [string, string];
  // 活动开始时间
  beginDate?: number | string;
  // 活动结束时间
  endDate?: number | string;
  brandId?: string;
  pageIndex?: number;
  pageSize?: number;
  // 是否刷新全部数据（列表、数量统计）
  refreshAll?: boolean;
  taskAggregationCode?: string; // 新增参数
}

export interface ActivityTaskItem {
  // 活动code
  actCode: string;
  // 活动id
  actId: string;
  //
  ownerId: string;
  ownerType?: "PLATFORM" | "MERCHANT";
  // 活动名称
  name: string;
  // 活动描述
  actDesc: string;
  // 活动图片
  actImgUrl: string;
  // 品牌Logo,可能多个
  brandLogos?: string[];
  // 品牌名称
  brandName?: string;
  // 是否是已关注的店铺
  isFollow?: boolean;
  // 是否可报名
  canSignUp?: boolean;
  // 是否是当日新活动（当日进入新状态的活动）
  todayNewActivity?: boolean;
  // 开奖状态 1、未中奖 2、已中奖 3、计算中 （已开奖状态才有值）
  calculateReward?: 1 | 2 | 3;
  // 活动状态，N-未开始；B-进行中；E-已结束；C-已作废.
  status?: "N" | "B" | "E" | "C";
  // "活动状态 NOT_BEGIN:未开始 ADVANCE:预告中 BEGIN:已开始 END:已结束 CANCEL:已作废 DRAFT:草稿 AWAITING_DRAW:待开奖 "
  aggStatus?: "NOT_BEGIN" | "ADVANCE" | "BEGIN" | "END" | "CANCEL" | "DRAFT" | "AWAITING_DRAW";
  // 活动创建时间
  createTime?: number;
  // 活动开始时间
  startTime: number;
  // 活动结束时间
  endTime: number;
  // 当前系统时间
  currentSysTime: number;
  // 计算奖励时间
  calculateAwardTime: number;
  // 奖励时间
  rewardDistributionTime: number;
  // 预估奖励
  estimatedRewards?: string | number;
  // 专属人群
  exclusiveCrowd?: boolean;
  // 参与人数
  participantCount?: number;
  // 是否加码
  raiseAward?: boolean;
  // 展示形式
  showStyle?: string;
  // 业务类型
  bizType?: "FULL_RETURN" | "RANK" | "GIFT_RETURN";
  // 业务类型名称
  bizTypeName?: string;
  actSellBatePartakeInfo?: {
    // 活动参与状态	 10: "未报名" 20: "进行中" 30: "开奖中" 40: "已发放" 50: "未达标"
    partakeStatus?: 10 | 20 | 30 | 40 | 50;
    // 当前销售额
    currentGmv?: number;
    // 下阶段奖励目标（满返、满赠）
    nextRewardAmount?: number;
    // 最低入围门槛金额（PK）
    minSalesVolume?: number;
    // 当前排名（PK）
    currentRank?: number;
    // GMV("销售额"), SALE_QUANTITY("销售数量"), COMMISSION("推广费佣金") SHOP_NEW_USER_QUANTITY("新C数量");
    thresholdIndicatorType?: "GMV" | "SALE_QUANTITY" | "COMMISSION" | "SHOP_NEW_USER_QUANTITY";
    // 预估奖励
    estimatedRewards?: string | number;
    // 预估奖励（元/件）
    expectedAwardAmount?: number;
    // 奖品名称
    giftName?: string;
  };
  actSellRebateRuleInfos?: {
    ruleDetails?: {
      endThreshold?: number;
    }[];
  };
}
export interface CouponItem {
  awdId: string;
  baseAmount: number;
  couponId?: string;
  couponLimitDescList: string[];
  currentNum: number;
  dcTemplateVoucherInstructions: string;
  dcTemplateVoucherName: string;
  idolOrDistributorCanShareCoupon: boolean;
  initialNum: number;
  isOldCoupon: boolean;
  ownerId?: string;
  ownerType?: "USE_CODE" | "PLATFORM";
  quotaAmount: number;
  shareTimeDesc: string;
  timeDesc: string;
  voucherNo?: string;
  wxExclusive: boolean;
}

export interface RewardSearchData {
  benefitsList?: ActivityTaskItem[];
  limitedTimeList?: ActivityTaskItem[];
  couponAwdList?: CouponItem[];
}

function getRandomValue(arr) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return undefined; // 确保输入是非空数组
  }
  const randomIndex = Math.floor(Math.random() * arr.length); // 生成随机索引
  return arr[randomIndex]; // 返回随机选取的元素
}
const defalutData = (params: ActivityTaskParams): ActivityTaskItem => {
  let data: Partial<ActivityTaskItem> = {
    isFollow: params.shopType ? true : getRandomValue([true, false]),
    raiseAward: getRandomValue([true, false]),
    bizType: getRandomValue(["FULL_RETURN", "RANK", "GIFT_RETURN"]),
  };
  if ([10, 20, 30].includes(params.actSellRebateStatus as number)) {
    data.actSellBatePartakeInfo = {
      partakeStatus: params.actSellRebateStatus,
    };
    if (params.actSellRebateStatus === 10) {
      data.canSignUp = getRandomValue([true, false]);
      data.status = getRandomValue(["N", "B"]);
    } else {
      data.canSignUp = true;
      data.status = params.actSellRebateStatus === 20 ? "B" : "E";
    }
  } else {
    if (params.isDraw) {
      data.actSellBatePartakeInfo = {
        partakeStatus: 40,
      };
      data.calculateReward = 2;
    } else {
      data.actSellBatePartakeInfo = {
        partakeStatus: getRandomValue([40, 50]),
      };
      if (data.actSellBatePartakeInfo.partakeStatus === 40) {
        data.calculateReward = getRandomValue([1, 2, 3]);
      } else {
        data.calculateReward = 1;
      }
    }
    data.status = "E";
  }
  if (data.status === "N") {
    data.startTime = moment().add(1, "days").valueOf();
    data.endTime = moment().add(10, "days").valueOf();
  }
  if (data.status === "B") {
    data.startTime = moment().subtract(10, "days").valueOf();
    data.endTime = moment().add(10, "days").valueOf();
  }
  if (data.status === "E") {
    data.startTime = moment().subtract(10, "days").valueOf();
    data.endTime = moment().subtract(1, "days").valueOf();
  }
  if (data.actSellBatePartakeInfo.partakeStatus === 30) {
    data.calculateAwardTime = moment().add(1, "days").valueOf();
  }
  data.currentSysTime = moment().valueOf();
  return {
    // 活动code
    actCode: Math.floor(Math.random() * 999999999).toFixed(0),
    // 活动id
    actId: Math.floor(Math.random() * 999999999).toFixed(0),
    //
    ownerId: "",
    ownerType: getRandomValue(["PLATFORM", ""]),
    // 活动名称
    name: "活动名称活动名称活动名称活动名称活动名称活动名称活动名称",
    // 活动描述
    actDesc: "活动描述活动描述活动描述活动描述活动描述活动描述活动描述",
    // 活动图片
    actImgUrl: "",
    // 品牌Logo,可能多个
    brandLogos: getRandomValue([
      [""],
      ["", ""],
      ["", "", ""],
      ["", "", "", ""],
      ["", "", "", "", ""],
    ]),
    // 品牌名称
    brandName: "品牌名称",
    // 是否是已关注的店铺
    isFollow: data.isFollow ?? false,
    // 是否可报名
    canSignUp: data.canSignUp ?? false,
    // 开奖状态 1、未中奖 2、已中奖 3、计算中 （已开奖状态才有值）
    calculateReward: data.calculateReward ?? undefined,
    // 活动状态，N-未开始；B-进行中；E-已结束；C-已作废.
    status: data.status ?? "N",
    // 活动创建时间
    createTime: moment().subtract(15, "days").valueOf(),
    // 活动开始时间
    startTime: data.startTime ?? moment().add(1, "days").valueOf(),
    // 活动结束时间
    endTime: data.endTime ?? moment().add(10, "days").valueOf(),
    // 当前系统时间
    currentSysTime: data.currentSysTime ?? moment().valueOf(),
    // 计算奖励时间
    calculateAwardTime: data.calculateAwardTime ?? moment().add(15, "days").valueOf(),
    // 奖励时间
    rewardDistributionTime: data.calculateAwardTime ?? moment().add(15, "days").valueOf(),
    // 预估奖励
    estimatedRewards: data.estimatedRewards ?? "3000元",
    // 专属人群
    exclusiveCrowd: false,
    // 参与人数
    participantCount: data.participantCount ?? Number(Math.floor(Math.random() * 999).toFixed(0)),
    // 是否加码
    raiseAward: data.raiseAward ?? false,
    // 展示形式
    showStyle: "",
    // 业务类型 "FULL_RETURN" | "RANK" | "GIFT_RETURN"
    bizType: (params.promoActivityType || data.bizType) ?? "FULL_RETURN",
    // 业务类型名称
    bizTypeName: "",
    actSellBatePartakeInfo: {
      // 活动参与状态	 10: "未报名" 20: "进行中" 30: "开奖中" 40: "已发放" 50: "未达标"
      partakeStatus: data?.actSellBatePartakeInfo?.partakeStatus ?? 10,
      currentGmv: Number(Math.floor(Math.random() * 1399).toFixed(0)),
      currentRank: 100,
      minSalesVolume: Number(Math.floor(Math.random() * 9999).toFixed(0)),
    },
    actSellRebateRuleInfos: {
      ruleDetails: [
        {
          endThreshold: 100,
        },
      ],
    },
  };
};

const setList = (params: ActivityTaskParams, len: number) => {
  let list: ActivityTaskItem[] = [];
  for (let index = 0; index < len; index++) {
    list.push(defalutData(params));
  }
  return list;
};

// 查询活动列表
const queryPage = async (params?: ActivityTaskParams) => {
  delete params?.date;
  delete params?.refreshAll;
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/queryPage`,
    method: "post",
    data: params,
  });
  // const res = {data:{
  //   hasNext: false,
  //   result: setList((params || {}) as ActivityTaskParams, 10),
  // }};
  console.log("奖励中心-活动列表", res?.data);
  return res?.data;
};
// 查询活动统计数量
const queryActCount = async (params?: ActivityTaskParams) => {
  delete params?.date;
  delete params?.refreshAll;
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/queryActCount`,
    method: "post",
    data: params,
  });
  return res?.data;
};
// 查询红包统计数据
const queryRedPacketSummary = async (params?: ActivityTaskParams) => {
  delete params?.date;
  delete params?.refreshAll;
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/findPrizePool`,
    method: "post",
    data: params,
  });
  return res?.data;
};

const queryActPageBySearch = async (params?: { brandId: string }) => {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/searchPromoActivity`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 满返活动banner
const queryRebateBanner = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/queryRebateBanner`,
    method: "post",
    data: params || {},
  });
  return res?.data;
};

// 满返活动banner
const queryCouponPage = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/selectShopShareCouponPage`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 满返活动banner
const queryRedPacketList = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/activity/redPacket`,
    method: "post",
    data: params,
  });
  return res?.data;
};

const subscribeInfo = () => {
  return Request<any, any>({
    url: `${getBaseurl()}/api/member/multi/member/subscribeInfo`,
    method: "get",
  });
};
// 领取红包
const receiveRedPacket = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/redPacket/grabMerRedPacket`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 获取奖品商品详情
const rewardProductDetial = async (props: {
  /** 活动ID */
  activityId?: string;
  /** 活动SpuID（商品ID） */
  activitySpuId: string;
  /** 收货地址ID */
  addressId?: string;
  /** 选中的skuId，不填则返回后台根据规则选中默认skuId */
  selectSkuId?: string;
}) => {
  const res = await Request<RewardProductModel>({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/productAwardInfo`,
    method: "post",
    data: props,
  });
  return res?.data;
};

// https://yapi.akcops.com/project/2507/interface/api/411045
const msgReachSubscribe = async () => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/multi/member/msgReachSubscribe`,
    method: "get",
  });
  return res?.data;
};
// 满赠兑换通知横条提示
/**
 * @description 查询横幅通知
 * @link  https://yapi.akcops.com/project/2711/interface/api/412305
 * @author 泽北(桑国凯)
 */
const queryBannerMsg = async (params?: { bizId: string }) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/promo/queryBannerMsg`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// https://yapi.akcops.com/project/2679/interface/api/411108
const checkIsNeedPopUp = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/checkIsNeedPopUp`,
    method: "get",
    params,
  });
  return res?.data;
};

export {
  checkIsNeedPopUp,
  msgReachSubscribe,
  queryActCount,
  queryActPageBySearch,
  queryBannerMsg,
  queryCouponPage,
  queryPage,
  queryRebateBanner,
  queryRedPacketList,
  queryRedPacketSummary,
  receiveRedPacket,
  rewardProductDetial,
  subscribeInfo,
};
