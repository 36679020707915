import { batchReceiveCoupon } from "@/api/coupon";
import { checkUserBindPhone } from "@/api/my";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Mask, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import styles from "./index.module.less";

import { visitorInfo } from "@/api/member";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";

interface IProps {
  couponInfo: any;
  pageName?: string;
  update?: () => void;
}

const CouponForActivity: React.FC<IProps> = ({ couponInfo, pageName, update }) => {
  const { navigate2NewPersonPage } = useNavigation();

  const [visible, setVisible] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [isReceiveSuccess, setIsReceiveSuccess] = useState<boolean>(false);
  const [newCoupons, setNewCoupons] = useState<any>([]);
  const [spCoupons, setSpCoupons] = useState<any>([]);
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = useState(false);
  const track = Track();

  const toAuth = (hasScope = true) => {
    let url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    // 处理微信自动添加字符串问题，替换为空
    url = url.replace(/\/\?from=(message|timeline|singlemessage|groupmessage)(\S*)#/, "/#");
    url = url.replace(/\/\?(scene|subscene|clicktime)=[\d]+(\S*)#/, "/#");
    window.location.href = `/api/member/auth?${
      hasScope ? "scope=snsapi_userinfo&" : ""
    }backUrl=${window.encodeURIComponent(url)}`;
  };

  const goNewPerson = () => {
    track.track("btn_click", {
      page_name: pageName,
      btn_name: "逛新人专区",
    });
    if (!isReceiveSuccess) {
      Toast.show("请先领券！");
      return;
    }
    if (couponInfo?.configId) {
      navigate2NewPersonPage(couponInfo?.configId);
    }
  };

  const onClose = () => {
    setVisible(false);
    if (checked) {
      localStorage.setItem("noShowCouponDialogAgain", "true");
    }
    track.track("btn_click", {
      page_name: pageName,
      btn_name: "关闭优惠券弹窗",
    });
  };

  useActivate(() => {
    setVisible(false);
  });

  useUnactivate(() => {
    setVisible(false);
  });

  useEffect(() => {
    if (couponInfo?.newPeopleCouponActivities?.length) {
      setNewCoupons(couponInfo.newPeopleCouponActivities);
    }
    if (couponInfo?.spCouponActivities?.length) {
      setSpCoupons(couponInfo.spCouponActivities);
    }
    if (localStorage.getItem("noShowCouponDialogAgain") === "true") {
      setVisible(false);
    } else if (
      couponInfo?.newPeopleCouponActivities?.length ||
      couponInfo?.spCouponActivities?.length
    ) {
      setVisible(true);
    }
    track.track("resource_expose", {
      page_name: pageName,
      resource_type: "优惠券弹窗",
      resource_name: "优惠券弹窗",
      desc: "新客",
    });
  }, []);

  const NewCouponsComp = () => {
    return (
      <div className={styles.coupon_new_person}>
        <div className={styles.coupon_new_person_title}>
          <div className={styles.left}>
            <span>新人专享券</span>
            <span className={styles.line}></span>
            <span className={styles.time_wrap}>
              <span className={styles.time}>7</span>天后过期
            </span>
          </div>
          <div className={styles.right} onClick={() => goNewPerson()}>
            <span>逛新人专区</span>
            <i className={styles.icon_arrow_right}></i>
          </div>
        </div>
        <div className={styles.coupon_new_person_con}>
          {newCoupons.map((item, index) => (
            <div className={styles.coupon_new_person_item} key={index}>
              <div className={styles.title_label}>全场通用</div>
              <div className={styles.coupon_count}>
                <span className={styles.unit}>¥</span>
                {item.amount}
              </div>
              <div className={styles.coupon_limit}>满{item.thresholdAmount}可用</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const SpCouponsComp = () => {
    return (
      <>
        {!!newCoupons?.length && <div className={styles.shop_coupon_title}>店铺福利券</div>}
        <div className={styles.coupon_center}>
          {spCoupons.map((item, index) => (
            <div className={styles.coupon_item} key={index}>
              <div className={styles.left}>
                <span className={styles.unit}>¥</span>
                {item.amount}
              </div>
              <div className={styles.right}>
                <div className={`${styles.coupon_name} ${styles.line_ellipsis_1}`}>
                  {item.couponName}
                </div>
                <div className={styles.coupon_time}>{item.time}</div>
                <div className={`${styles.coupon_range} ${styles.line_ellipsis_1}`}>
                  {item.couponDesc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const onReceiveCoupon = async () => {
    const res1 = await visitorInfo();
    if (!res1?.isAuthorized) {
      toAuth();
      return;
    }
    const res2 = await checkUserBindPhone();
    if (!res2?.data?.isBindPhone) {
      setBindPhonePopupVisible(true);
      return;
    }
    if (isReceiveSuccess) {
      setVisible(false);
      track.track("btn_click", {
        page_name: pageName,
        btn_name: "立即使用",
        desc: "否",
      });
      return;
    }
    track.track("btn_click", {
      page_name: pageName,
      btn_name: "一键领取",
      desc: "否",
    });
    let couponByIdReqList = [];
    if (spCoupons && spCoupons.length) {
      couponByIdReqList =
        spCoupons.map(item => ({
          awdId: item.ticketId,
          promoActivityId: item.promoActivityId,
        })) || [];
    }
    if (newCoupons && newCoupons.length) {
      couponByIdReqList = couponByIdReqList.concat(
        newCoupons.map(item => ({
          awdId: item.ticketId,
          promoActivityId: item.promoActivityId,
        })),
      );
    }
    const res = await batchReceiveCoupon({
      couponByIdReqList,
      rcsTraceId: "",
      captchaToken: "",
    });
    const { code, success, data, message } = res || {};
    if (code === "00000" && success && data) {
      Toast.show("优惠券领取成功");
      setIsReceiveSuccess(true);
      update && update();
      return;
    }
    Toast.show(message || "优惠券被领光啦");
  };

  const onFinishBindPhone = () => {
    setBindPhonePopupVisible(false);
    onReceiveCoupon();
  };

  return (
    <>
      <Mask visible={visible} onMaskClick={() => onClose()} opacity={0.75}>
        <div className={styles.coupon_wrap}>
          <div className={styles.coupon}>
            <div className={styles.close} onClick={() => onClose()}></div>
            <div className={styles.coupon_sunshine}></div>
            <div className={styles.coupon_top}>
              <div className={styles.total_count}>
                <span>{couponInfo.totalCouponAmount}</span>
              </div>
            </div>
            <div className={styles.coupon_con}>
              {!!newCoupons.length && <NewCouponsComp />}
              {!!spCoupons.length && <SpCouponsComp />}
            </div>
            <div className={styles.coupon_btm}>
              <div
                className={`${styles.btn} ${isReceiveSuccess ? styles.to_use : null}`}
                onClick={() => onReceiveCoupon()}></div>
              <p className={styles.tips}>可在“我的-优惠券“中查看</p>
            </div>
            {!!couponInfo.lastWindowNum && (
              <div
                className={`${styles.switch} ${checked ? styles.checked : null}`}
                onClick={() => setChecked(!checked)}>
                不再提醒
              </div>
            )}
          </div>
        </div>
      </Mask>
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={onFinishBindPhone}
        extra={{ bindStatus: 3 }}
      />
    </>
  );
};

export default CouponForActivity;
