import {
  carrierContextParams,
  CarrierElementType,
  ContentType,
  envContextParams,
  SourceScene,
} from "@/api/scode/scode_params";
import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import { getCurPageClientHeight } from "@/utils/getStyle";
import { shareCardByH5 } from "@/utils/share/share";
import { ProductDetailModel } from "@akc/biz-api";
import { useThrottleFn } from "ahooks";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { TopTabModel } from "../model/TopTabModel";
import { CSUtils } from "../utils/CSUtils";
/**
 * 商品详情 & 素材商详,公共逻辑处理hook文件
 */
export const useBaseProductDetail = () => {
  const [search] = useSearchParams();

  //活动商品id
  const activitySpuId = React.useRef<string | null | undefined>(search.get("activitySpuId"));

  //档期id
  const activityId = React.useRef<string | null | undefined>(search.get("activityId"));

  //选中的skuId
  const [selectSkuId, setSelectSkuId] = React.useState<string | null | undefined>(
    search.get("selectSkuId"),
  );

  //一级属性
  const [firstSalePropertyValue, setFirstSalePropertyValue] = React.useState<
    string | null | undefined
  >(search.get("firstSalePropertyValue"));

  //二级属性
  const [secondSalePropertyValue, setSecondSalePropertyValue] = React.useState<
    string | null | undefined
  >(search.get("secondSalePropertyValue"));

  const pageCode = search.get("customPageCode");

  const liveNo = search.get("liveNo");

  //skcId(老的productId)
  const skcId = search.get("productId");

  const ud = search.get("ud");

  const needConvert = search.get("needConvert");

  //播号（商品失效信息不全时，兜底使用）
  const productNum = search.get("productNum");

  //底部显示文案
  const converterMsg = search.get("converterMsg");

  //商详模型
  const [productDetail, setProductDetail] = React.useState<ProductDetailModel>();

  //是否显示顶部tab
  const [showTopTabs, setShowTopTabs] = React.useState<boolean>(false);
  //是否显示浮动菜单
  const [showFloatMenu, setShowFloatMenu] = React.useState<boolean>(false);
  //顶部tab数据集
  const [tabItems, setTabItems] = React.useState<TopTabModel[]>([]);
  //顶部tab选中key
  const [activeKey, setActiveKey] = React.useState("1");

  //滚动监听
  const { run: handleScroll } = useThrottleFn(
    () => {
      const scrollTop =
        window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

      setShowFloatMenu(scrollTop > getCurPageClientHeight() / 2);
      setShowTopTabs(scrollTop > 44);

      let currentKey = tabItems?.[0]?.key;
      for (const item of tabItems) {
        const element = document.getElementById(`anchor-${item.key}`);
        if (!element) {
          continue;
        }
        const rect = element.getBoundingClientRect();
        if (rect.top <= 45) {
          currentKey = item.key;
        } else {
          break;
        }
      }
      setActiveKey(currentKey);
    },
    {
      leading: true,
      trailing: true,
      wait: 100,
    },
  );

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  //客服
  const onServiceAction = () => {
    const merchantShopCode = productDetail?.activitySimpleVO?.merchantShopCode;
    const activitySpuId = productDetail?.activitySpuId;
    const activityNo = productDetail?.activityId;
    const externalCode = productDetail?.activityId;
    const shopId = search.get("shopId") ?? "";
    const distributorId = search.get("distributorId") ?? "";
    const sourceQuery = search.get("sourceQuery");
    CSUtils.checkLocationType(
      2,
      { merchantShopCode, activitySpuId, activityNo, externalCode },
      "",
      shopId,
      distributorId,
      sourceQuery,
    );
  };

  //右上角三个点分享
  const getProductSysTemShareInfo = async (pdModel?: ProductDetailModel) => {
    let params = {
      bizInfo: {
        contentType: ContentType.spuProduct,
        contentId: pdModel?.activitySpuId,
        contentTypeConfig: 1,
        selectSkuId: pdModel?.selectSkuId || "",
        contentDetailId: pdModel?.selectSkuId || "",
      },
      envContext: envContextParams({
        sourceScene: SourceScene.productMaterial,
      }),
      contentContext: {
        contentType: ContentType.spuProduct,
        contentId: pdModel?.activitySpuId,
      },
      carrierContext: carrierContextParams([CarrierElementType.h5Link]),
      withForwarder: 1,
      withPreview: 0,
    };
    let res = await fetchShareCardInfo(params);

    const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = res?.data || {};
    if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
      return;
    }
    let confirmParam = {
      requestId: String(new Date().getTime()),
      scene: (res?.data && res?.data?.scode) || "",
      extraMap: {
        contentId: pdModel?.activitySpuId ?? "",
        contentType: "6",
        activity_spu_code: pdModel?.activitySpuId ?? "",
        sku_id: pdModel?.selectSkuId ?? "",
      },
      hideMenus: pdModel?.skuExtendInfoVO?.isOTC ? ["menuItem:share:timeline"] : [],
    };
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      confirmParam,
    );
  };

  return {
    activityId,
    selectSkuId,
    setSelectSkuId,
    firstSalePropertyValue,
    setFirstSalePropertyValue,
    secondSalePropertyValue,
    setSecondSalePropertyValue,
    activitySpuId,
    pageCode,
    liveNo,
    productDetail,
    setProductDetail,
    onServiceAction,
    skcId,
    ud,
    needConvert,
    productNum,
    converterMsg,
    setTabItems,
    tabItems,
    activeKey,
    showTopTabs,
    showFloatMenu,
    getProductSysTemShareInfo,
  };
};
