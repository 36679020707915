import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
  title?: string;
  content?: string;
  onClick?: () => void;
};

/**
 * 商品营销标签
 */
const ProductLabelComp: React.FC<Props> = ({ title, content, onClick }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.left}>{title}</div>
      <div className={styles.center}>{content}</div>
      {onClick && (
        <Image
          className={styles.arrow}
          src="https://akim-oss.aikucun.com/5cd2c1e7a8442a4ce08e04e2c08f76d3f90e134e_1734604018274_47.png"
        />
      )}
    </div>
  );
};

export default ProductLabelComp;
