import { AwardItem } from "@/models/reward";
import { useCommonContext } from "@/utils/context/common";
import { getCurrentformatTime } from "@/utils/date-format";
import { Toast } from "antd-mobile";
import React from "react";
import styles from "./PhysicalCard.module.less";

interface CardProps {
  reward?: AwardItem;
  isFromCustomer?: boolean;
  onShopDetailClick?: (reward?: AwardItem) => void;
  onCustomerClick?: (reward?: AwardItem) => void;
  onInviteClick?: (reward?: AwardItem) => void;
  onOrderClick?: (reward?: AwardItem) => void;
  onClaimClick?: (reward?: AwardItem) => void;
}

const PhysicalCardCamp: React.FC<CardProps> = ({
  reward,
  isFromCustomer,
  onClaimClick,
  onInviteClick,
  onCustomerClick,
  onOrderClick,
  onShopDetailClick,
}) => {
  const { canShare } = useCommonContext();

  const onShopDetailAction = () => {
    onShopDetailClick?.(reward);
  };
  const onClaimAction = () => {
    if (reward?.productAward?.expired) {
      Toast.show("奖励已过期");
      return;
    }
    onClaimClick?.(reward);
  };

  const onInviteAction = () => {
    onInviteClick?.(reward);
  };

  const onCustomerCenterAction = () => {
    onCustomerClick?.(reward);
  };

  const onOrderAction = () => {
    onOrderClick?.(reward);
  };

  const content = () => {
    return (
      <>
        <div className={styles.content_title} onClick={onShopDetailAction}>
          <div className={styles.title_left}>{reward?.productAward?.shopName}</div>
          <img
            className={styles.title_image}
            src={
              "https://akim-oss.aikucun.com/mshop/7bcad940df84d27092f655c1e58ac791769c4e33_1724552411687_71.png"
            }
          />
        </div>
        <div
          className={
            reward?.productAward?.expiredTime
              ? styles.content_price
              : `${styles.content_price} ${styles.content_space}`
          }>
          <div className={styles.price_title}>{reward?.productAward?.awardValue}</div>
          <div className={styles.desc_title}>{reward?.productAward?.awardTitle}</div>
        </div>
        <div className={styles.content_desc}>{reward?.productAward?.expiredTime}</div>
      </>
    );
  };

  const bottomRight = () => {
    if (isFromCustomer) {
      return (
        <>
          {canShare && (
            <div className={styles.right_normal} onClick={onInviteAction}>
              <div className={styles.btn_title}>告诉好友</div>
            </div>
          )}
          {reward?.exchange && <div className={styles.right_space} />}
          {reward?.exchange && (
            <div className={styles.right_normal} onClick={onOrderAction}>
              <div className={styles.btn_title}>查看订单</div>
            </div>
          )}
        </>
      );
    }

    return (
      <>
        {canShare && (
          <div className={styles.right_normal} onClick={onInviteAction}>
            <div className={styles.btn_title}>告诉好友</div>
          </div>
        )}
        <div className={styles.right_space} />
        {reward?.exchange ? (
          <div className={styles.right_normal} onClick={onOrderAction}>
            <div className={styles.btn_title}>查看订单</div>
          </div>
        ) : (
          <div
            className={
              reward?.productAward?.expired
                ? `${styles.right_red} ${styles.right_unable}`
                : styles.right_red
            }
            onClick={onClaimAction}>
            <div className={styles.btn_title}>领取奖励</div>
          </div>
        )}
      </>
    );
  };

  const cardImgeUrl = () => reward?.productAward?.awardImageUrl;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          <div
            className={
              styles.top_left
            }>{`中奖时间：${getCurrentformatTime(parseInt(reward?.awardTime || "0", 10))}`}</div>
          <div
            className={
              reward?.exchange ? `${styles.top_right} ${styles.top_right_r}` : styles.top_right
            }>
            {reward?.productAward?.expired ? "已过期" : reward?.exchange ? "已兑奖" : "待兑奖"}
          </div>
        </div>
        {!isFromCustomer && (
          <img
            className={styles.topLine}
            src={
              "https://akim-oss.aikucun.com/mshop/7056538220bfea98f51bfaee99f70b7d2423d7db_1723120200611_89.png"
            }
          />
        )}
        <div className={styles.card}>
          <div className={styles.card_left}>
            <img className={styles.left_image} src={cardImgeUrl()} />
            <div className={styles.left_content}>{content()}</div>
          </div>
        </div>

        <>
          <img
            className={styles.bottomLine}
            src={
              "https://akim-oss.aikucun.com/mshop/7056538220bfea98f51bfaee99f70b7d2423d7db_1723120200611_89.png"
            }
          />
          <div className={styles.bottom}>
            <div className={styles.bottom_left} onClick={onCustomerCenterAction}>
              {isFromCustomer && (
                <div className={styles.left_content}>
                  <img className={styles.c_image} src={reward?.userAvatarUrl} />
                  <div className={styles.c_content}>{reward?.userName}</div>
                  <img
                    className={styles.c_arrow}
                    src={
                      "https://akim-oss.aikucun.com/mshop/eec3ad2e003b47ca2dd387bc88c570ac39ada344_1723121268728_92.png"
                    }
                  />
                </div>
              )}
            </div>
            <div className={styles.bottom_right}>{bottomRight()}</div>
          </div>
        </>
      </div>
    </>
  );
};

export default PhysicalCardCamp;
