import { ImageURLUitls } from "@akc/foundation";

// 以下经过手动验证可以进行从http协议转为https协议；
const convertDomainList = {
  "akmer.aikucun.com": "http:",
  "mer.oss.aikucun.com": "http:",
  "akim.oss-cn-shanghai.aliyuncs.com": "http:",
  "thirdwx.qlogo.cn": "http:",
  "devdev.oss-cn-shanghai.aliyuncs.com": "http:",
  "devdev.cdn.akucun.com": "http:",
  "devdevnew.oss.aikucun.com": "http:",
  "image-process-service.oss-cn-shanghai.aliyuncs.com": "http:",
};

// http2https；
export const http2https = val => {
  if (typeof val !== "string" || !val) {
    return val;
  }
  if (val.match(/^https:\/\//)) {
    return val;
  }
  const matchRes = val.match(/^(http:)\/\/([-.\w]+)\/(.+)/);
  if (matchRes && convertDomainList[matchRes[2]] === matchRes[1]) {
    return `https://${matchRes[2]}/${matchRes[3]}`;
  }
  return val;
};

export const formatImgUrl = (url: any, w = 200, h?: number) =>
  ImageURLUitls.normalProcessImage(http2https(url), w, h);
