import { queryImage } from "@/api/decorate";
import { searchBarrageList } from "@/api/index";
import useNavigation from "@/utils/useNavigate";
import { Image, Swiper } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import exposure from "../../utils/expose/exposure";
import { pxtorem } from "../../utils/pxtorem";
import styles from "./index.module.less";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  navType?: any;
  navResourceRank?: any;
  trackInfo?: any;
  [key: string]: any;
}

const ImageCom: React.FC<Props> = (props: Props) => {
  const [barrageList, setBarrageList] = useState<string[]>([]);
  const { preasableAction } = useNavigation();

  const [imageUrl, setImageUrl] = useState<string>();

  const positionMap = {
    TOP_LEFT: "left",
    BOTTOM_LEFT: "left",
    TOP_RIGHT: "flex-end",
    BOTTOM_RIGHT: "flex-end",
  };

  const verticalItems = barrageList.map((item: any, index) => (
    <Swiper.Item
      key={index}
      style={{
        paddingTop: pxtorem(9),
        display: "flex",
        justifyContent: positionMap[props.propData?.bulletScreenSite || "TOP_LEFT"],
      }}>
      <div className={styles.barrage}>
        <span style={{ color: "rgba(255, 231, 171, 1)" }}>{item.nickName}</span>
        <span style={{ padding: `0 ${pxtorem(5)}` }}>{item.timeDesc}</span>
        <span>{item.content}</span>
      </div>
    </Swiper.Item>
  ));
  //获取弹幕
  const queryBarrage = async () => {
    const res = await searchBarrageList();
    setBarrageList(res?.data?.atmosphere ?? []);
  };

  useEffect(() => {
    if (props.propData.bulletScreenDisplay === 0) {
      queryBarrage();
    }
    const interval = setInterval(() => {
      let flag = false;
      const dom = document.getElementById(`${props.propData.componentCode}`);
      if (dom) {
        exposure.add({
          el: dom,
        });
      } else {
        flag = true;
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.propData?.holeConfigDetails?.length) {
      // 有挖孔，需要请求接口生成图片
      const func = async () => {
        const params = {
          componentCode: props.propData.componentCode,
          componentDetailCode: props.propData.holeConfigDetails[0].componentDetailCode,
          customPageCode: props.pageData.pageCode,
          customPageVersion: props.pageData.version,
          pageId: "H5_SuperMarketing",
          preview: false,
        };
        try {
          const res = await queryImage(params);
          if (res?.data?.holeImageUrl) {
            setImageUrl(res?.data?.holeImageUrl);
            return;
          }
        } catch (err) {
          // do nothing
        }
        setImageUrl(props.propData?.url);
      };
      func();
    } else {
      setImageUrl(props.propData?.url);
    }
  }, [props.propData]);

  const goDetail = (item?: any, index?: any) => {
    if (item === null || index === undefined) {
      return;
    }
    preasableAction(
      item,
      props.pageData.name,
      props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "图片",
      props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
      index,
      props?.trackInfo,
    );
  };

  return (
    <div
      className={styles.image}
      style={{
        borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
        borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
        borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
        borderBottomRightRadius: pxtorem(props.propData?.borderBottomRightRadius),
        margin: `${pxtorem(props.propData?.paddingTop)} ${pxtorem(
          props.propData?.paddingRight,
        )} ${pxtorem(props.propData?.paddingBottom)} ${pxtorem(props.propData?.paddingLeft)}`,
      }}
      id={`${props.propData.componentCode}`}
      data-param={JSON.stringify({
        type: props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "图片",
        content: "图片",
        picture: true,
        hasSpecially: false,
        resourceRank: props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
        relativeRank: 0,
        ...props.trackInfo,
      })}
      data-eventid={props.propData.componentCode}>
      <Image
        src={imageUrl}
        style={{
          borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
          borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
          borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
          borderBottomRightRadius: pxtorem(props.propData?.borderBottomRightRadius),
          width: pxtorem(props.propData.width),
          height: pxtorem(props.propData.height),
        }}
        onClick={() => goDetail()}></Image>
      {props.propData?.hotConfigDetails?.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              position: "absolute",
              left: pxtorem(item.x),
              top: pxtorem(item.y),
              width: pxtorem(item.width),
              height: pxtorem(item.height),
              zIndex: 9,
            }}
            onClick={() => goDetail(item, index)}></div>
        );
      })}
      {props.propData.bulletScreenDisplay === 0 && (
        <div
          className={
            ["TOP_LEFT", "TOP_RIGHT"].includes(props.propData?.bulletScreenSite || "TOP_LEFT")
              ? styles.top
              : styles.bottom
          }>
          <Swiper
            direction="vertical"
            autoplay={true}
            autoplayInterval={2000}
            allowTouchMove={false}
            loop={true}
            style={{
              "--height": pxtorem(43),
              borderRadius: pxtorem(8),
            }}
            className={
              ["TOP_LEFT", "TOP_RIGHT"].includes(props.propData?.bulletScreenSite || "TOP_LEFT")
                ? styles.comeInTop
                : styles.comeInBottom
            }
            indicator={() => <div></div>}>
            {verticalItems}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default inject("appStore")(observer(ImageCom));
