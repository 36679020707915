import AKJS from "@akc/akjs";
import { Request } from "../api/request";
import { getAppSystem } from "./app-system";
import { platform } from "./platform";
import { urlQueryObject } from "./url-parse";
import { versionsCompare } from "./version-compare";
// import { useNavigate } from 'react-router-dom';
import stores from "@/store/index";
import { getBaseurl } from "@/utils/url-parse";

const staticUrl = getBaseurl();

const wx = window.wx;
const baseUrl = location.protocol + "//" + location.hostname;

export const stringifyUrl = (
  url: string,
  params: {
    [key in string]: any;
  },
) => {
  if (url) {
    let str = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => `${key}=${params[key]}`)
      .join("&");
    const { shopId, distributorId, plt } = urlQueryObject();
    if (!params.shopId) {
      str += `&shopId=${shopId}`;
    }
    if (!params.distributorId && distributorId) {
      str += `&distributorId=${distributorId}`;
    }
    str += `&plt=${plt}`;
    const hyphen = url.includes("?") ? "&" : "?";
    return `${url}${hyphen}${str}`;
  }
  return "";
};

// 跳转到指定页面
export type NavigatorOptions = {
  url: string;
  query?: {
    [key in string]: any;
  };
};

export function navigate(options: {
  appOptions?: NavigatorOptions;
  miniOptions?: NavigatorOptions;
  h5Options?: NavigatorOptions;
}) {
  const { isApp, isMini } = platform;
  if (isApp) {
    const { url, query } = options.appOptions || {};
    if (url) {
      if (query) {
        query.page_name = document.title;
      }
      const schema =
        url.indexOf("http") >= 0
          ? url
          : url.indexOf("akapp://") >= 0
            ? url
            : url.indexOf("/") >= 0
              ? `akapp://mengxiang.com${url}`
              : `akapp://mengxiang.com/${url}`;
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.page.open", { url: schema, param: query }).catch(console.error);
      });
    }
  } else if (isMini) {
    const { url, query = {} } = options.miniOptions || {};
    if (url) {
      if (query) {
        query.page_name = document.title;
      }
      const newurl = stringifyUrl(url, query);
      if (newurl.startsWith("http")) {
        const hyphen = newurl.includes("?") ? "&" : "?";
        window.location.href = `${newurl + hyphen}token=${stores.appStore.token}`;
        return;
      }
      wx &&
        wx.miniProgram.navigateTo({
          url: newurl,
        });
    }
  } else {
    const { url, query = {} } = options.h5Options || {};
    if (url) {
      if (query) {
        query.page_name = document.title || "首页";
        query.previous_page_name = document.title || "首页";
      }
      const newurl = stringifyUrl(url, query);
      window.location.href = newurl;
    }
  }
}

// 跳转到档期详情
export const navigate2Activity = (query: { activityNo: string; marketName?: string }) => {
  navigate({
    appOptions: {
      url: "activity",
      query: {
        id: query.activityNo,
        marketName: query.marketName,
      },
    },
    miniOptions: {
      url: "/acts/pages/activity-detail/activity-detail",
      query: {
        id: query.activityNo,
      },
    },
    h5Options: {
      url: `${baseUrl}/#/brand`,
      query: {
        activityNo: query.activityNo,
        fromPage: query.marketName,
      },
    },
  });
};

// 跳转到直播间
export const navigate2LiveRoom = (props: { liveId: string; liveStatus: number }) => {
  navigate({
    appOptions: {
      url: "liveRoom",
      query: {
        id: props.liveId,
      },
    },
    miniOptions: {
      url: `/pages/transfer/transfer?pageName=live-playing&shareOnlyId=${props.liveId}`,
    },
    h5Options: {
      url: `${baseUrl}/#/living?channel=h5Mall&liveNo=${props.liveId}`,
    },
  });
};

// 跳转到普通会场详情
export function navigate2Venue(query: {
  miniQuery?: {
    id: string;
  };
  h5Query?: {
    conferenceId: string;
  };
}): void {
  navigate({
    appOptions: {
      url: `${process.env.REACT_APP_PAGE_URL_4_APP}/pages/commonActivity.html#/index?conferenceId=${query.h5Query?.conferenceId}`,
    },
    miniOptions: {
      url: "/acts/pages/conference/conference",
      query: query.miniQuery,
    },
    h5Options: {
      url: `${baseUrl}/#/commonActivity?conferenceId=${query.h5Query?.conferenceId}`,
    },
  });
}

// 跳转到超品会场详情
export function navigate2SuperVenue(query: {
  miniQuery?: {
    pageName?: string;
    shareOnlyId: string;
  };
  h5Query?: {
    hotSaleId: string;
  };
}) {
  navigate({
    appOptions: {
      url: `${process.env.REACT_APP_PAGE_URL_4_APP}/pages/superBrand.html#/index?hotSaleId=${query.h5Query?.hotSaleId}`,
    },
    miniOptions: {
      url: "/pages/transfer/transfer",
      query: query.miniQuery,
    },
    h5Options: {
      url: `${baseUrl}/#/superbrand?hotSaleId=${query.h5Query?.hotSaleId}`,
    },
  });
}

// 跳转到优惠券档期凑单页
export function navigate2OfferOrderList(query: any) {
  navigate({
    miniOptions: {
      url: "/acts/pages/offer-order/offer-order",
      query: query.miniQuery,
    },
    h5Options: {
      url: `${baseUrl}/#/offer-order`,
      query: query.h5Query,
    },
  });
}

// 跳转到优惠券商品凑单页
export function navigate2GoodsOfNpieceXfold(query: any) {
  navigate({
    miniOptions: {
      url: "/acts/pages/buy-more-discount/buy-more-discount",
      query: query.miniQuery,
    },
    h5Options: {
      url: `${baseUrl}/#/goodsOfNpieceXfold`,
      query: query.h5Query,
    },
  });
}

// 回退
export const navigateBack = () => {
  const platformList: string[] = [];
  for (const key in platform) {
    if (Object.prototype.hasOwnProperty.call(platform, key)) {
      const current = platform[key];
      if (current) {
        platformList.push(key);
      }
    }
  }

  if (platformList.includes("isMini")) {
    wx.miniProgram.navigateBack();
  } else {
    history.back();
  }
};
