import { Popup } from "antd-mobile";
import React from "react";
import styles from "./RegularPopup.module.less";

const RegularPopup = ({ visible, setVisible, activityRuleDesc }) => {
  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      bodyStyle={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}>
      <div className={styles.regularPopWrap}>
        <div className={styles.header}>
          活动规则
          <div className={styles.closeBtn} onClick={() => setVisible(false)}></div>
        </div>
        <div className={styles.content}>{activityRuleDesc}</div>
      </div>
    </Popup>
  );
};
export default RegularPopup;
