import React, { useEffect, useRef, useState } from "react";

import styles from "./index.module.less";

interface ICustomIndicatorProps {
  total: number;
  current: number;
}

// 自定义指示器
const SwiperCustomIndicator: React.FC<ICustomIndicatorProps> = ({
  total: length,
  current: currentIndex,
}) => {
  const [removeWidth, setRemoveWidth] = useState(0);
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(4);
  const [prevIndex, setPrevIndex] = useState(-1);
  const boxRef = useRef<HTMLDivElement>(null);
  const boxDom = boxRef?.current;
  const handleClickLeft = () => {
    const newRemoveWidth = removeWidth - 10;
    if (currentIndex === leftIndex && currentIndex > 0) {
      if (!boxDom) {
        return;
      }
      boxDom.style.transform = `translateX(-${newRemoveWidth}px)`;
      setRemoveWidth(newRemoveWidth);
      setRightIndex(rightIndex - 1);
      setLeftIndex(leftIndex - 1);
    }
  };
  const handleClickRight = () => {
    const newRemoveWidth = removeWidth + 10;
    if (currentIndex === rightIndex && currentIndex < length - 1) {
      if (!boxDom) {
        return;
      }
      boxDom.style.transform = `translateX(-${newRemoveWidth}px)`;
      setRemoveWidth(newRemoveWidth);
      setRightIndex(rightIndex + 1);
      setLeftIndex(leftIndex + 1);
    }
  };
  useEffect(() => {
    if (currentIndex > prevIndex) {
      handleClickRight();
    } else {
      handleClickLeft();
    }
    setPrevIndex(currentIndex);
  }, [currentIndex]);
  return (
    <div className={styles["container"]}>
      <div className={styles["box"]} style={{ maxWidth: "46px" }}>
        <div className={styles["box-item"]} ref={boxRef}>
          {Array.from({ length }, (v, index) => (
            <div
              className={`${styles["item"]} ${currentIndex === index ? styles["active"] : ""} ${
                index === leftIndex && leftIndex !== 0 ? styles["small"] : ""
              } ${index === rightIndex && rightIndex !== length - 1 ? styles["small"] : ""}`}
              style={{
                width: "6px",
                height: "6px",
                marginRight: `${index === length - 1 ? 0 : "4px"}`,
                opacity: `${index < leftIndex || index > rightIndex ? "0" : "1"}`,
              }}
              key={index}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SwiperCustomIndicator;
