import { searchSecKill, searchSecKillTab } from "@/api";
import { searchSecListV2 } from "@/api/decorate";
import { SharePosterParams } from "@/api/scode/poster";
import buyPng from "@/assets/buy.png";
import buyNowPng from "@/assets/buy_now.png";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import moneyPng from "@/assets/money.png";
import noSecKill from "@/assets/no_seckill.png";
import nullPng from "@/assets/null.png";
import backPng from "@/assets/scekill_back.png";
import scorePng from "@/assets/score.png";
import contentPng from "@/assets/seckill_bac.png";
import contentBackPng from "@/assets/seckill_div.png";
import yuPng from "@/assets/yu.png";
import loadingPng from "@/base64";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import SideBar from "@/components/sidebar";
import SizePopup from "@/components/size-popup";
import { ProductModel } from "@/models/product";
import { sharePosterParams4H5Product, shareProductPosterAction } from "@/utils/actions";
import { formatImgUrl } from "@/utils/crop";
import { identity } from "@/utils/identity";
import { project } from "@/utils/project";
import { pxtorem } from "@/utils/pxtorem";
import { showSKUPreview } from "@/utils/select-color";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Image, InfiniteScroll } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import exposure from "../utils/expose/exposure";
import styles from "./secKill.module.less";
interface Props {
  appStore?: any;
  [key: string]: any;
}

const SecKill: React.FC<Props> = ({ appStore }) => {
  const [search] = useSearchParams();
  const [dataInfo, setDataInfo] = useState<any>({ result: [] });
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [proIndex, setProIndex] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const { navigate2Product } = useNavigation();
  const pageCode = search.get("customPageCode");
  const version = search.get("customPageVersion");
  const decorate = search.get("decorate");
  const [activeIndex, setActiveIndex] = useState<any>(Number(search.get("activeIndex")));
  const track = Track();
  const [tabList, setTabList] = useState<any>([]);
  const [tabLoad, setTabLoad] = useState<boolean>(false);
  const [isOTC, setIsOTC] = useState(false);

  const getPlatformFunc = () => {
    // eslint-disable-next-line no-underscore-dangle
    const isMini = window.__wxjs_environment === "miniprogram";
    const isApp = navigator && navigator.userAgent.search("AKC") > -1;
    const isSaas = isMini && !!location.href.match(/plt=saas/i);
    const isWxH5 = !isMini && navigator && !!navigator.userAgent.match(/miniProgram/i);
    const isH5 = isWxH5 || (!isMini && !isApp);
    const isTuan = isMini && location.href.match(/plt=atuan/i);
    return {
      isMini,
      isApp,
      isWxH5,
      isH5,
      isSaas,
      isBrowser: !isMini && !isApp && !isWxH5,
      isTuan,
    };
  };

  const platform = getPlatformFunc();
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
            <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
            <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
          </div>
        )}
      </>
    );
  };

  //获取秒杀
  const querySecKill = async (index = 0, isScroll?: boolean, pageNo?: number) => {
    console.log("进入查询秒杀----");
    if (!isScroll) {
      setLoading(true);
    }
    let params: any = null;
    let res: any = null;
    if (decorate === "1") {
      params = {
        merShopCode: search.get("merchantShopCode"),
        secKillType: index === 1 ? "INSECKILL" : "FEATURE",
        pageNum: pageNo ? pageNo : pageNum,
        pageSize: 10,
        pageId: project() ? "H5_Shop" : "H5_SuperMarketing",
      };
      res = await searchSecListV2(params);
    } else {
      params = {
        componentCode: search.get("componentCode"),
        componentDetailCode:
          index === 1 ? search.get("oneDetailCode") : search.get("twoDetailCode"),
        customPageCode: pageCode,
        customPageVersion: version,
        pageNum: pageNo ? pageNo : pageNum,
        pageSize: 10,
        pageId: project() ? "H5_Shop" : "H5_SuperMarketing",
        preview: false,
      };
      res = await searchSecKill(params);
    }
    if ((res?.data?.result?.length ?? 0) > 0) {
      if (pageNum > 1) {
        res.data.result = dataInfo.result.concat(res.data.result);
      }
      setDataInfo({ ...res.data });
      setHasMore(res?.data?.hasNext ?? false);
      // setActiveIndex(index);
      setPageNum(res.data.pageIndex + 1);
    } else {
      setHasMore(res?.data?.hasNext ?? false);
      setDataInfo(dataInfo);
      // if (isChange) {
      //   Toast.show({
      //     content:
      //       index == 0 ? "暂无秒杀中的数据哦～" : "暂无即将开始的数据哦～",
      //   });
      // }
    }
    setLoading(false);
  };

  //获取秒杀tab
  const querySecKillTabs = async () => {
    let params = {
      componentCode: search.get("componentCode"),
      customPageCode: pageCode,
      customPageVersion: version,
      pageNum: 1,
      pageSize: 10,
      pageId: project() ? "H5_Shop" : "H5_SuperMarketing",
      preview: false,
    };
    let res = await searchSecKillTab(params);
    let arr: any = [];
    if (res?.data?.featureList?.length > 0) {
      arr.push(2);
    }
    if (res?.data?.inseckillList?.length > 0) {
      arr.push(1);
    }
    setTabList(arr);
    setTabLoad(true);
    if (arr.includes(1)) {
      querySecKill(1);
    } else {
      querySecKill(2);
      setActiveIndex(2);
    }
  };

  const loadMore = async () => {
    await querySecKill(activeIndex, true);
  };

  const changeIndex = index => {
    if (project() === 2 && track.track) {
      track.track("tab_click", {
        page_name: project() === 2 ? "店铺-秒杀详情页" : "秒杀二级页",
        merchant_shop_code: search.get("merchantShopCode"),
        desc: search.get("merchantShopCode"),
        tab_name: index === 1 ? "秒杀中" : "即将开始",
      });
    }
    if (activeIndex !== index) {
      document.getElementById("seckill2")?.scrollIntoView();
      setHasMore(false);
      setPageNum(1);
      setActiveIndex(index);
      dataInfo.result = [];
      querySecKill(index, undefined, 1);
    }
  };

  useLayoutEffect(() => {
    if (platform.isMini) {
      console.log("小程序-----");
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (search.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      if (search.get("plt") === "atuan") {
        appStore.channel = "tuanApplets";
      }
      appStore.token = search.get("token");
    }
    document.title = "秒杀";
    //页面埋点
    /* eslint-disable */
    track.track("$pageview", {
      page_name: project() === 2 ? "店铺-秒杀详情页" : "秒杀二级页",
      merchant_shop_code: search.get("merchantShopCode"),
      desc: search.get("merchantShopCode"),
      tab_name: "秒杀中",
      market_id: search.get("customPageCode"),
      market_name: document.title,
    });
    if (project() === 2) {
      track.track("tab_expose", {
        page_name: "店铺-秒杀详情页",
        tab_name: "秒杀中",
        rank: 1,
        merchant_shop_code: search.get("merchantShopCode"),
        desc: search.get("merchantShopCode"),
        previous_page_name: search.get("previous_page_name"),
      });
      track.track("tab_expose", {
        page_name: "店铺-秒杀详情页",
        tab_name: "即将开始",
        rank: 2,
        merchant_shop_code: search.get("merchantShopCode"),
        desc: search.get("merchantShopCode"),
        previous_page_name: search.get("previous_page_name"),
      });
    }
    /* eslint-enable */
    querySecKillTabs();
    return () => {
      localStorage.removeItem("seckill_fresh");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < dataInfo.result.length; i++) {
        const dom = document.getElementById(`${pageCode}-${activeIndex}-${i}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, [dataInfo?.result]);

  //转发商品
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);

  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>(
    {} as SharePosterParams,
  );
  const forwardProduct = (e: React.MouseEvent<HTMLDivElement>, item: any) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "秒杀",
        btn_name: "立即转发",
        btn_text: "立即转发",
        merchant_shop_code: search.get("merchantShopCode"),
      });
    }
    /* eslint-enable */
    e.stopPropagation();
    if (!platform.isApp && !platform.isMini) {
      setSharePosterData(
        sharePosterParams4H5Product({
          pageCode: pageCode as string,
          activitySpuId: item.activitySpuId,
          selectSkuId: item.selectSkuId,
        }),
      );
      setCustomEditData({
        activitySpuId: item.activitySpuId,
        activityId: item.activityId,
        selectSkuId: item.selectSkuId,
      });
      setIsOTC(!!item?.skuExtendInfoVO?.isOTC);
      setShowPoster(true);
    } else {
      shareProductPosterAction({
        product: item,
        pageCode: pageCode as string,
      });
    }
    /* eslint-disable */
    if (track.track) {
      track.track("poster_expose", {
        page_name: document.title,
        expose_content: "秒杀",
        type: "手动",
        market_id: pageCode,
        market_name: document.title,
      });
    }
    /* eslint-enable */
  };
  const onClosePoster = () => {
    setShowPoster(false);
  };

  //跳转商详
  const goProduct = (
    item,
    index?: any,
    salePropertyFirstListPicItem?: any,
    type?: any,
    e?: React.MouseEvent<HTMLDivElement>,
  ) => {
    let btnInfo: any = null;
    if (type === "btn") {
      btnInfo = {
        page_name: document.title,
        module: "秒杀",
        btn_name: "立即购买",
        btn_text: "立即购买",
      };
    }
    if (type === "size") {
      btnInfo = {
        page_name: document.title,
        module: "秒杀选色",
        btn_name: "查看详情",
        btn_text: "查看详情",
      };
    }
    e?.stopPropagation();
    if (track.track && project() === 2) {
      track.track("resource_click", {
        page_name: "店铺-秒杀详情页",
        resource_type: "商品列表",
        previous_page_name: "店铺首页",
        domain: "B2R旗舰店",
        btn_name: "商品点击",
        activity_spu_code: item.activitySpuId,
        sku_id: item.selectSkuId,
        resource_rank: index + 1,
        tab_name: activeIndex === 1 ? "秒杀中" : "即将开始",
        merchant_shop_code: search.get("merchantShopCode"),
      });
    }
    navigate2Product(
      {
        activityId: item.activityId,
        activitySpuId: item.activitySpuId,
        selectSkuId: item.selectSkuId,
        firstSalePropertyValue: salePropertyFirstListPicItem?.value,
      },
      {
        type: "秒杀",
        targetId: item.activitySpuId,
        resourceRank: 0,
        relativeRank: index,
        hasSpecially: false,
        btnInfo: btnInfo,
      },
    );
  };

  // 选色
  const [sizePopupProduct, setSizePopupProduct] = useState<ProductModel>({} as ProductModel);
  const [showSizePopup, setShowSizePopup] = useState(false);
  const skuPreview = (e: React.MouseEvent<HTMLDivElement>, item: any, index?: any) => {
    e.stopPropagation();
    const productInfo: ProductModel = {
      salePropertyFirstList: item.salePropertyFirstList,
      spuInfoVO: {
        sellOut: item.spuSellOut,
      },
      spuId: item.spuId,
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
    };
    showSKUPreview(productInfo).then(result => {
      if (result) {
        return;
      }
      setSizePopupProduct(productInfo);
      setShowSizePopup(true);
      setProIndex(index);
    });
  };
  const onCloseSizePopup = () => {
    setShowSizePopup(false);
  };

  //标签
  const formatLabel = (list, type) => {
    const arr: any = [];
    for (const label of list) {
      if (label.locationCode === type && label?.labelInfoVOList?.length > 0) {
        for (const labelList of label.labelInfoVOList) {
          arr.push({
            type: labelList.contentType,
            value: labelList.labelIconUrl ? labelList.labelIconUrl : labelList.labelText,
            height: 15,
            width: labelList.labelIconWidth / (labelList.labelIconHeight / 15),
          });
        }
      }
    }
    return arr;
  };
  return (
    <div
      className={styles.sec_kill}
      id="seckill"
      style={{
        backgroundColor: dataInfo.result?.length > 0 ? "#F1F2F5" : "F1F2F5",
      }}>
      {platform.isH5 && <SideBar />}
      <div className={styles.seckill} id="seckill2">
        {tabLoad && tabList?.length >= 2 && <Image src={backPng} className={styles.back}></Image>}
        <div
          style={{
            position: "relative",
            top: pxtorem(tabList.length > 1 ? 24 : 12),
          }}>
          <div className={styles.seckill_header}>
            {tabList?.length >= 2 && tabLoad && (
              <>
                <ul className={styles.nav}>
                  <li
                    className={` ${styles.item} ${styles.font} ${
                      activeIndex === 1 ? styles.active : ""
                    }`}
                    onClick={() => changeIndex(1)}>
                    {/* <Image className={styles.img} src={killPng}></Image> */}
                    限时秒杀
                  </li>
                  <li
                    className={` ${styles.item} ${styles.font} ${
                      activeIndex === 2 ? styles.active : ""
                    }`}
                    onClick={() => changeIndex(2)}>
                    秒杀预告
                  </li>
                </ul>
                <Image className={styles.div_content} src={contentPng}></Image>
              </>
            )}
          </div>
          {tabList?.length >= 2 && tabLoad && (
            <Image className={styles.div_back} src={contentBackPng}></Image>
          )}
          <>
            {loading ? (
              <div className={styles.loading}>
                <Image src={loadingPng} width={pxtorem(30)}></Image>
              </div>
            ) : (
              <>
                {dataInfo.result?.length > 0 ? (
                  <div
                    style={{
                      marginTop: pxtorem(tabList.length > 1 ? 20 : 0),
                      zIndex: 99,
                      position: "relative",
                      overflowX: "hidden",
                      overflowY: "auto",
                      height: pxtorem(tabList.length > 1 ? 580 : 630),
                      paddingBottom: pxtorem(24),
                    }}>
                    {dataInfo.result?.map((item, index) => {
                      return (
                        <div
                          className={styles.allCotent}
                          key={index}
                          id={`${pageCode}-${activeIndex}-${index}`}
                          data-param={JSON.stringify({
                            type: "秒杀二级页",
                            content: project() === 2 ? "店铺-秒杀详情页" : "秒杀二级页",
                            name: item.title,
                            id: item.activityId,
                            picture: true,
                            hasSpecially: false,
                            activityId: item.activityId,
                            resourceRank: 0,
                            relativeRank: index + 1,
                            tab_name: activeIndex === 1 ? "秒杀中" : "即将开始",
                          })}
                          data-eventid={pageCode + "-" + activeIndex + "-" + index}>
                          <div
                            className={styles.contentDiv}
                            style={{
                              borderRadius: `${activeIndex === 1 ? 0 : pxtorem(12)} ${
                                activeIndex === 1 ? pxtorem(12) : 0
                              } ${pxtorem(12)} ${pxtorem(12)}`,
                            }}>
                            <div
                              style={{
                                borderRadius:
                                  index === 0
                                    ? `${
                                        activeIndex === 1 && tabList.length > 1 ? 0 : pxtorem(12)
                                      } ${activeIndex === 1 ? pxtorem(12) : 0} ${pxtorem(
                                        12,
                                      )} ${pxtorem(12)}`
                                    : pxtorem(12),
                              }}
                              className={styles.contentOut}
                              onClick={e => goProduct(item, index, null, null, e)}>
                              <div className={styles.top}>
                                <div className={styles.one1}>
                                  <div className={styles.img}>
                                    {item?.skuExtendInfoVO?.rewardCreditAmount > 0 && (
                                      <div className={styles.score}>
                                        <div className={styles.score_text}>
                                          至多积
                                          {item?.skuExtendInfoVO?.rewardCreditAmount}
                                        </div>
                                        <Image className={styles.img_score} src={scorePng}></Image>
                                      </div>
                                    )}
                                    <Image
                                      width="100%"
                                      height="100%"
                                      src={
                                        item?.skuMainImageUrls?.[0]
                                          ? formatImgUrl(item?.skuMainImageUrls?.[0], 120, 120)
                                          : nullPng
                                      }
                                      style={{ borderRadius: pxtorem(8) }}></Image>
                                    <div className={styles.save_money_content}>
                                      {item?.skuCurrentPriceInfo.commission > 0 && (
                                        <div className={styles.save_money}>
                                          {platform.isApp
                                            ? "省赚"
                                            : platform.isTuan
                                              ? "推广费"
                                              : platform.isMini && !platform.isSaas
                                                ? "饷"
                                                : "赚"}
                                          <span
                                            style={{
                                              paddingLeft: pxtorem(2),
                                            }}>
                                            ¥{item?.skuCurrentPriceInfo.commission}
                                          </span>
                                        </div>
                                      )}
                                      {item?.skuCurrentPriceInfo.commissionAddition > 0 && (
                                        <div className={styles.add}>
                                          +{item?.skuCurrentPriceInfo.commissionAddition}
                                        </div>
                                      )}
                                    </div>
                                    {formatLabel(
                                      item?.labelLocationVOList,
                                      project() === 2 || decorate === "1"
                                        ? "H5_Shop_DecorateSales_ColumnProductPicRight"
                                        : "H5_SuperMarketing_ProductStyleNo3_ProductPicRight",
                                    ).map((label, labelIndex) => {
                                      if (label.type === "image") {
                                        return (
                                          <Image
                                            key={labelIndex}
                                            src={label.value}
                                            style={{
                                              height: pxtorem(40),
                                              width: pxtorem(40),
                                              position: "absolute",
                                              top: 0,
                                              right: 0,
                                            }}
                                            fit="contain"></Image>
                                        );
                                      }
                                      if (label.type === "text") {
                                        return (
                                          <div
                                            key={labelIndex}
                                            style={{
                                              height: pxtorem(16),
                                              position: "absolute",
                                              top: 0,
                                              right: 0,
                                            }}>
                                            {label.value}
                                          </div>
                                        );
                                      }
                                    })}
                                    {item?.spuSellOut && (
                                      <div className={styles.sell_out}>
                                        <div className={styles.sell_out_circle}>已售罄</div>
                                      </div>
                                    )}
                                  </div>
                                  <div className={styles.content}>
                                    <div className={styles.content_header}>
                                      <div className={styles.label}>
                                        {formatLabel(
                                          item?.labelLocationVOList,
                                          project() === 2 || decorate === "1"
                                            ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                            : "H5_SuperMarketing_ProductStyleNo3_TitleFront",
                                        ).map((label, labelIndex) => {
                                          if (label.type === "image") {
                                            return (
                                              <Image
                                                key={labelIndex}
                                                src={label.value}
                                                className={styles.label_img}
                                                style={{
                                                  width: pxtorem(label.width),
                                                  position: "absolute",
                                                }}
                                                fit="contain"></Image>
                                            );
                                          }
                                          if (label.type === "text") {
                                            return (
                                              <div
                                                key={labelIndex}
                                                style={{
                                                  paddingRight: pxtorem(4),
                                                }}>
                                                {label.value}
                                              </div>
                                            );
                                          }
                                        })}
                                        <div
                                          className={styles.name}
                                          style={{
                                            textIndent:
                                              formatLabel(
                                                item?.labelLocationVOList,
                                                project() === 2 || decorate === "1"
                                                  ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                                  : "H5_SuperMarketing_ProductStyleNo3_TitleFront",
                                              )?.length > 0
                                                ? pxtorem(
                                                    formatLabel(
                                                      item?.labelLocationVOList,
                                                      project() === 2 || decorate === "1"
                                                        ? "H5_Shop_DecorateSales_ColumnProductNameFront"
                                                        : "H5_SuperMarketing_ProductStyleNo3_TitleFront",
                                                    )[0]?.width + 1 || 33,
                                                  )
                                                : 0,
                                          }}>
                                          {item?.title}
                                        </div>
                                        {new Date().valueOf() <
                                          item?.skuCurrentPriceInfo?.promotionStartTime && (
                                          <Image className={styles.label_yu} src={yuPng}></Image>
                                        )}
                                      </div>
                                      <div className={styles.discount_label}>
                                        {formatLabel(
                                          item?.labelLocationVOList,
                                          project() === 2 || decorate === "1"
                                            ? "H5_Shop_DecorateSales_ColumnProductNameDown"
                                            : "H5_SuperMarketing_ProductStyleNo3_TitleDown",
                                        ).map((label, labelIndex) => {
                                          if (label.type === "image") {
                                            return (
                                              <Image
                                                key={labelIndex}
                                                src={label.value}
                                                style={{
                                                  height: pxtorem(16),
                                                  width: pxtorem(label.width),
                                                  paddingRight: pxtorem(4),
                                                }}></Image>
                                            );
                                          }
                                          if (label.type === "text") {
                                            return (
                                              <div
                                                key={labelIndex}
                                                style={{
                                                  paddingRight: pxtorem(4),
                                                }}>
                                                {label.value}
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>
                                    <div className={styles.content_content}>
                                      <div
                                        className={styles.style_list}
                                        onClick={e => skuPreview(e, item, index)}>
                                        {item?.salePropertyFirstList
                                          .slice(0, 4)
                                          .map((style, styleIndex) => {
                                            return (
                                              <div
                                                className={styles.style_list_item}
                                                key={styleIndex}>
                                                <Image
                                                  className={styles.item_img}
                                                  src={style.picList?.[0] || nullPng}
                                                  style={{
                                                    opacity: style.sellOut ? "0.1" : "1",
                                                  }}></Image>
                                                {style.sellOut && (
                                                  <div className={styles.item_sell_out}>售罄</div>
                                                )}
                                              </div>
                                            );
                                          })}
                                        <div className={styles.style_list_item}>
                                          <div className={styles.item_font}>
                                            <div>{item?.salePropertyFirstList?.length}款</div>
                                            <div>可选</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.price_btn}>
                                        <div className={styles.price}>
                                          {(item?.skuCurrentPriceInfo.promotionType === "SECKILL" ||
                                            item?.skuCurrentPriceInfo.promotionType ===
                                              "FIXED_PRICE") && (
                                            <div className={styles.price_text}>
                                              {item?.skuCurrentPriceInfo.promotionTypeName}
                                            </div>
                                          )}
                                          <div className={styles.price_real}>
                                            <div>¥</div>
                                            <div className={styles.price_big}>
                                              {Math.trunc(item?.skuCurrentPriceInfo.marketingPrice)}
                                            </div>
                                            <div className={styles.decimal}>
                                              .
                                              {Math.round(
                                                (item?.skuCurrentPriceInfo.marketingPrice % 1) *
                                                  100,
                                              )}
                                            </div>
                                          </div>
                                          {item?.skuCurrentPriceInfo.tagPrice > 0 && (
                                            <div className={styles.price_old}>
                                              ¥
                                              <span
                                                style={{
                                                  paddingLeft: pxtorem(2),
                                                }}>
                                                {item?.skuCurrentPriceInfo.tagPrice}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        {!identity() && (
                                          <div
                                            className={
                                              new Date().valueOf() <
                                              (item?.skuCurrentPriceInfo?.promotionStartTime || 0)
                                                ? styles.btn_pre
                                                : styles.btn
                                            }
                                            onClick={e => forwardProduct(e, item)}>
                                            <Image
                                              className={styles.btn_img}
                                              src={moneyPng}></Image>
                                            <div className={styles.btn_font}>转发赚钱</div>
                                          </div>
                                        )}
                                        {identity() &&
                                          new Date().valueOf() <
                                            (item?.skuCurrentPriceInfo?.promotionStartTime ||
                                              0) && (
                                            <div
                                              className={styles.btn_buy}
                                              onClick={e => goProduct(item, index, null, "btn", e)}>
                                              <Image
                                                className={styles.btn_img}
                                                src={buyPng}></Image>
                                            </div>
                                          )}
                                        {identity() &&
                                          new Date().valueOf() >=
                                            (item?.skuCurrentPriceInfo?.promotionStartTime ||
                                              0) && (
                                            <div
                                              className={styles.buy_btn}
                                              onClick={e => goProduct(item, index, null, "btn", e)}>
                                              <Image
                                                className={styles.btn_img}
                                                src={buyNowPng}></Image>
                                              <div className={styles.buy_text}>立即购买</div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={20}>
                      <InfiniteScrollContent hasMore={hasMore} />
                    </InfiniteScroll>
                  </div>
                ) : (
                  <div className={styles.nullStatus}>
                    <Image className={styles.noSecKill} src={noSecKill}></Image>
                    <div className={styles.text}>暂无秒杀活动</div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
      {/* 海报 */}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        isOTC={isOTC}
        shareType="PRODUCT"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}></PosterSwipe>
      {/* 选色卡 */}
      <SizePopup
        product={sizePopupProduct}
        show={showSizePopup}
        close={onCloseSizePopup}
        toDetailByFirstProp={(product, salePropertyFirstListPicItem) =>
          goProduct(product, proIndex, salePropertyFirstListPicItem, "size")
        }
      />
    </div>
  );
};

export default inject("appStore")(observer(SecKill));
