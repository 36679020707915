import {
  AccountInfo,
  ExpirationIsComing,
  RedPacketDetailListModel,
  RedPacketInfo,
  RewardListModel,
} from "@/models/reward";
import { getBaseurl } from "@/utils/url-parse";
import { API } from "@akc/biz-api";
import { Request } from "../request";

export interface Tab {
  /** 名称 */
  name?: string;
  /** 类型 */
  type?: string;
}

export type RewardParams = {
  // 优惠券：COUPON 积分：POINTS 红包：RED_PACKET
  awardType: string;
  // 页码
  pageIndex: number;
  // 页大小
  pageSize: number;
  // 用户昵称
  userName?: string;
  // 查询开始时间
  startTime?: string;
  // 查询结束时间
  endTime?: string;
};

/**
 * @description 查询我的任务中奖记录数据
 * @author 张冰
 * @link https://yapi.akcops.com/project/2679/interface/api/400551
 */

export async function fetchMyRewardList(params: RewardParams): Promise<RewardListModel> {
  const res = await Request({
    url: `${getBaseurl()}/api/spweb/award/query/queryUserWinningRecordList`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * @description 查询我的顾客Tab列表
 * @author 张冰
 * @link https://yapi.akcops.com/project/2679/interface/api/400560
 */

export async function fetchTabList(): Promise<Tab[]> {
  const res = await Request({
    url: `${getBaseurl()}/api/spweb/award/query/queryUserAwardTypeList`,
    method: "post",
  });
  return res?.data;
}

/**
 * @description 查询我的顾客中奖记录
 * @author 张冰
 * @link https://yapi.akcops.com/project/2679/interface/api/400560
 */

export async function fetchCustomerRewardList(params: RewardParams): Promise<RewardListModel> {
  const res = await Request({
    url: `${getBaseurl()}/api/spweb/award/query/queryMyCustomerWinningRecordList`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * @description 红包账户详情
 * @author 李少华
 * @link https://yapi.akcops.com/project/1605/interface/api/401163
 */

export async function fetchAccountInfo(): Promise<AccountInfo> {
  const res = await Request({
    url: `${getBaseurl()}/api/finance/aggregation/redpack/queryCUserInfo`,
    method: "post",
    data: {},
  });
  return res?.data;
}

/**
 * @description 红包下发总金额
 * @author 李少华
 * @link https://yapi.akcops.com/project/1605/interface/api/402981
 */

export async function fetchRedPacketTotalInfo(): Promise<RedPacketInfo> {
  const res = await Request({
    url: `${getBaseurl()}/api/finance/aggregation/redpack/getTotalSendAmt`,
    method: "post",
    data: {
      accountType: "REDPACK",
    },
  });
  return res?.data;
}

/**
 * @description 红包账户流水记录
 * @author 李少华
 * @link https://yapi.akcops.com/project/1605/interface/api/401433
 */

export async function fetchRedPacketDetailList(params?: {
  pageNo?: number;
  pageSize?: number;
  beginDate?: string;
  endDate?: string;
}): Promise<RedPacketDetailListModel> {
  const res = await Request({
    url: `${getBaseurl()}/api/finance/aggregation/redpack/queryCAccountFlows`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * @description 即将过期红包
 * @author 李少华
 * @link /api/finance/aggregation/redpack/queryExpiringAmt
 */

export async function fetchExpirationIsComing(): Promise<ExpirationIsComing> {
  const res = await Request({
    url: `${getBaseurl()}/api/finance/aggregation/redpack/queryExpiringAmt`,
    method: "post",
    data: {
      intervalDays: 2,
    },
  });
  return res?.data;
}
export type DailyPrizeExpireVO = {
  bigType: string;
  expireTime: string;
  totalAwardValue: number;
};

export type DailyPrizeGrandVO = {
  userName: string;
  totalAwardValue: number;
  bigType: string;
};

export type DailyPrizeMessageVO = {
  bizTypes?: string[];
  dailyPrizeExpireVO?: DailyPrizeExpireVO;
  dailyPrizeGrandVO?: DailyPrizeGrandVO;
};

/**
 * 日日奖-消息弹窗接口
 * @author 芋头
 * https://yapi.akcops.com/project/2679/interface/api/418026
 */
export const queryDailyAwardMessage = (params: { bizTypes?: string[] }) =>
  API.post<DailyPrizeMessageVO>({
    hostID: "h5shop",
    path: "/api/spweb/award/daily/message",
    body: params,
  });
