import Track from "@/utils/track";
import { Grid } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import exposure from "../../utils/expose/exposure";
import styles from "./index.module.less";
// import {
//   toUseCoupon,
// } from "@/utils/actions";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import "swiper/css";
const track = Track();
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  small?: boolean;
  last?: boolean;
  loadMore?: any;
  isNav?: any;
  navType?: any;
  navigationType?: any;
  navResourceRank?: any;
  navStyle?: any;
  [key: string]: any;
}

const PointsCoupon: React.FC<Props> = (props: Props) => {
  const {
    navigate2SaasCoupon,
    // navigate2Product,
    // navigate2Activity,
    // navigate2OfferOrderList,
    // navigate2GoodsOfNpieceXfold,
  } = useNavigation();
  const [dataInfo, setDataInfo] = useState({ ...props.propData });
  const loading = useRef(false);

  useEffect(() => {
    setDataInfo(JSON.parse(JSON.stringify(props.propData)));
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < (props.propData.dataInfo?.length || 0); i++) {
        const dom = document.getElementById(`coupon-${props.propData?.dataInfo[i]?.awdId}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [props.propData.dataInfo?.length]);

  const goDetail = async item => {
    if (loading.current) {
      return;
    }
    loading.current = true;
    if (track.track) {
      track.track("resource_click", {
        page_name: document.title,
        module: "优惠券",
        btn_name: "去券详情",
        btn_text: "去券详情",
      });
    }
    await navigate2SaasCoupon({ awdId: item.awdId, promoActivityId: item.promoActivityId });
    setTimeout(() => {
      loading.current = false;
    }, 1000);
  };

  // const goUse = (item) => {
  //   /* eslint-disable */
  //   if (track.track) {
  //     track.track("resource_click", {
  //       page_name: document.title,
  //       module: "优惠券",
  //       btn_name: "去使用",
  //       btn_text: "去使用",
  //     });
  //   }
  //   /* eslint-enable */
  //   // 去使用
  //   const { isOldCoupon, awdId, voucherNo } = props.coupon;
  //   const params = isOldCoupon
  //     ? {
  //       couponId: awdId,
  //     }
  //     : {
  //       voucherNo,
  //     };
  //   toUseCoupon(params).then((res) => {
  //     const { success, data, message } = res || {};
  //     if (success) {
  //       const {
  //         jumpType,
  //         couponId: _couponId,
  //         voucherNo: _voucherNo,
  //         activityId,
  //         awdInstanceId,
  //       } = data || {};
  //       switch (jumpType) {
  //         case "productDetail":
  //           navigate2Product({
  //             activitySpuId: data.activitySpuId,
  //             activityId: data.activityId,
  //             selectSkuId: data.selectSkuId,
  //           });
  //           break;
  //         case "productList":
  //           navigate2GoodsOfNpieceXfold({
  //             h5Query: {
  //               couponId: _couponId,
  //               voucherNo: _voucherNo,
  //               awdInstanceId,
  //             },
  //             miniQuery: isOldCoupon
  //               ? {
  //                 id: _couponId,
  //                 awdInstanceId,
  //               }
  //               : {
  //                 voucherNo: _voucherNo,
  //                 awdInstanceId,
  //               },
  //           });
  //           break;
  //         case "activityDetail":
  //           navigate2Activity({
  //             activityNo: activityId,
  //           });
  //           break;
  //         case "activityList":
  //           navigate2OfferOrderList({
  //             h5Query: isOldCoupon ? { couponId: awdId } : { voucherNo },
  //             miniQuery: isOldCoupon ? { id: awdId } : { voucherNo },
  //           });
  //           break;
  //         default:
  //           break;
  //       }
  //     } else {
  //       Toast.show(message || "服务异常，请稍后重试！");
  //     }
  //   });
  // };

  console.log("积分优惠券是否还有-----", dataInfo);

  return (
    <>
      <div
        className={styles.points}
        style={{
          marginTop: pxtorem(props.propData?.paddingTop),
          marginBottom: pxtorem(props.propData?.paddingBottom),
        }}>
        {/* {dataInfo.layoutType === "12" && ( */}
        <div className={styles.one2} id={`one2_coupon_${props.propData.componentCode}`}>
          <Grid columns={2} gap={9}>
            {dataInfo.dataInfo?.map((item, index) => {
              return (
                <Grid.Item key={index} onClick={() => goDetail(item)}>
                  <div
                    id={`coupon-${item.awdId}`}
                    data-param={JSON.stringify({
                      type: "兑换商品",
                      content: "积分优惠券",
                      name: item.dcTemplateVoucherName,
                      id: item.awdId,
                      hasSpecially: false,
                      couponsId: item.awdId,
                      resourceRank: props.resourceRank,
                      relativeRank: index + 1,
                    })}
                    data-eventid={`coupon-${item.awdId}`}
                    className={styles.card}>
                    <div className={styles.top}>
                      <img
                        className={styles.img}
                        src="https://akim-oss.aikucun.com/0bc970f4e2013327f114aebdc560b1b10f3ddc29_1701945782821_6.png"
                      />
                      <img
                        className={styles.backImg}
                        src="https://akim-oss.aikucun.com/9132e087f1bf1ec918d99ba611cc565ee51c54d8_1701946712762_40.png"
                      />
                      <div className={styles.textDiv}>
                        <div className={styles.priceInfo}>
                          <div className={styles.symbol}>¥</div>
                          <div className={styles.price}>{item.quotaAmount}</div>
                        </div>
                        <img
                          className={styles.line}
                          src="https://akim-oss.aikucun.com/2d96ab396ecf73f25cab9770d2e0b16cc5f290ce_1701946390912_81.png"
                        />
                        <div className={styles.text}>优惠券</div>
                      </div>
                    </div>
                    {Number(item.buttonStatus) === 6 && (
                      <div className={styles.out}>
                        <img
                          className={styles.img}
                          src="https://akim-oss.aikucun.com/7d1839d4a23e0825649a11335f83750287e40205_1702463190454_71.png"
                        />
                      </div>
                    )}
                    <div className={styles.bottom}>
                      <div className={styles.title}>
                        满{item.baseAmount}减{item.quotaAmount}
                      </div>
                      <div className={styles.sub_title}>{item?.couponLimitDescList?.join("")}</div>
                      <div className={styles.score_bootom}>
                        <div className={styles.score}>
                          {item.pointsValue > 0 && (
                            <>
                              <img
                                className={styles.img}
                                src="https://akim-oss.aikucun.com/01f736cc08031a90878affd69d8ca79dff021722_1701948300988_99.png"
                              />
                              <div className={styles.text}>{item.pointsValue}积分</div>
                            </>
                          )}
                        </div>
                        {Number(item.buttonStatus) !== 6 &&
                          Number(item.buttonStatus) !== 4 &&
                          Number(item.buttonStatus) !== 5 &&
                          item.promoActivityStatus !== "NOT_BEGIN" && (
                            <div
                              onClick={e => {
                                e.stopPropagation();
                                goDetail(item);
                              }}
                              className={styles.btn}>
                              去兑换
                            </div>
                          )}
                        {Number(item.buttonStatus) === 6 &&
                          item.promoActivityStatus !== "NOT_BEGIN" && (
                            <div className={styles.out_btn}>已抢光</div>
                          )}
                        {(Number(item.buttonStatus) === 4 || Number(item.buttonStatus) === 5) &&
                          item.promoActivityStatus !== "NOT_BEGIN" && (
                            <div className={styles.out_btn}>已兑换</div>
                          )}
                        {item.promoActivityStatus === "NOT_BEGIN" && (
                          <div className={styles.out_btn}>未开始</div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid.Item>
              );
            })}
          </Grid>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default inject("appStore")(observer(PointsCoupon));
