import { DailyPrizeExpireVO, queryDailyAwardMessage } from "@/api/daily-reward";
import { akjs } from "@/utils/akjs";
import { platform } from "@/utils/platform";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
interface RedPacketNoticeProps {
  onClose?: () => void;
  duration?: number;
  topOffset?: number;
  forceShow?: boolean;
  zIndex?: number;
}

const STORAGE_KEY = "RED_NOTICE_CACHE";

// 获取当前日期字符串 YYYY-MM-DD
const getTodayString = (): string => {
  const date = new Date();
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
};

// 记录今天已经显示过通知
const markDailyNoticeShown = (): void => {
  try {
    const cache = {
      lastShowDate: getTodayString(),
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(cache));
  } catch (error) {
    console.error("Failed to save daily notice cache:", error);
  }
};

// 检查今天是否已经显示过通知
const shouldShowDailyNotice = (): boolean => {
  try {
    const cacheStr = localStorage.getItem(STORAGE_KEY);
    if (!cacheStr) {
      return true;
    }

    const cache = JSON.parse(cacheStr);
    return cache.lastShowDate !== getTodayString();
  } catch (error) {
    return true;
  }
};

const RedPacketNotice: React.FC<RedPacketNoticeProps> = ({
  onClose,
  duration = 3000,
  topOffset = 0,
  forceShow = false,
  zIndex = 9999,
}) => {
  // 是否显示
  const [visible, setVisible] = useState(false);
  // 是否正在离开
  const [leaving, setLeaving] = useState(false);
  // 触摸开始位置
  const touchStartY = useRef(0);
  // 元素引用
  const elementRef = useRef<HTMLDivElement>(null);
  // 是否需要渲染
  const [shouldRender, setShouldRender] = useState(false);
  const [lotteryInfo, setLotteryInfo] = useState<DailyPrizeExpireVO | null>(null);



  // 获取抽奖信息
  const fetchLotteryInfo = useCallback(async () => {
    try {
      const res = await queryDailyAwardMessage({ bizTypes: ["LIMIT_RED_PACKET"] });
      const dailyPrizeExpireVO = res?.dailyPrizeExpireVO || null;
      setLotteryInfo(dailyPrizeExpireVO);
      setShouldRender(true);
      // 标记今天已显示
      if (!forceShow && dailyPrizeExpireVO) {
        markDailyNoticeShown();
      }
    } catch (error) {
      console.error("fetchLotteryInfo--->error:", error);
    }
  }, [forceShow]);

  useEffect(() => {
    // 如果是App环境，不显示组件，触发一个通知消息
    if (platform.isApp) {
      akjs.event.send({
        name: "notification_banner",
        param: {
          bizTypes: ["LIMIT_RED_PACKET"],
        },
      });
    }
    // 检查是否应该显示通知
    if (forceShow || shouldShowDailyNotice()) {
      fetchLotteryInfo();
    }
  }, [forceShow, fetchLotteryInfo]);

  // 关闭通知
  const handleClose = () => {
    setLeaving(true);
    setTimeout(() => {
      setVisible(false);
      onClose?.();
    }, 300);
  };

  useEffect(() => {
    if (shouldRender) {
      // 组件挂载后显示
      setVisible(true);

      // 自定义时间后自动消失
      const timer = setTimeout(() => {
        handleClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, shouldRender]);

  // 处理触摸开始事件
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartY.current = e.touches[0].clientY;
  };

  // 处理触摸移动事件
  const handleTouchMove = (e: React.TouchEvent) => {
    const currentY = e.touches[0].clientY;
    const deltaY = currentY - touchStartY.current;

    if (deltaY < 0) { // 只处理向上滑动
      if (elementRef.current) {
        elementRef.current.style.transform = `translateY(${deltaY}px)`;
      }
    }
  };

  // 处理触摸结束事件
  const handleTouchEnd = (e: React.TouchEvent) => {
    const currentY = e.changedTouches[0].clientY;
    const deltaY = currentY - touchStartY.current;

    if (deltaY < -30) { // 如果向上滑动超过30px则关闭
      handleClose();
    } else {
      // 回弹
      if (elementRef.current) {
        elementRef.current.style.transform = "";
      }
    }
  };
  // 如果没有抽奖信息或不需要渲染或不显示，则返回null
  if (!lotteryInfo || !shouldRender || !visible || platform.isApp) {
    return null;
  }
  return (
    <div
      ref={elementRef}
      className={`${styles.container} ${leaving ? styles.leave : ""}`}
      style={{
        top: `${topOffset}px`,
        zIndex: zIndex
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.content}>
        <div className={styles.description}>
          <img
            className={styles.icon}
            src="https://akim-oss.aikucun.com/716a0d30eea1a8471195bae08863b93e3b029a1d_1733725967558_16.png"
            alt="icon"
          />
          <div className={styles.textBox}>
            <span>您有</span>
            <span style={{ color: "#FF4747" }}>{lotteryInfo.totalAwardValue}元</span>
            <span>红包{lotteryInfo.expireTime}，快去使用吧</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedPacketNotice; 