import { formatImgUrl } from "@/utils/crop";
import { getCurrentFormatTime, getCurrentformatTime } from "@/utils/date-format";
import { pxtorem } from "@/utils/pxtorem";
import React from "react";
import { YouXueSignUp } from "../../../model";
import styles from "./index.module.less";

interface CardProps {
  signUpDetail?: YouXueSignUp;
  supportMulSelect?: boolean; // 支持多选
  supportDelete?: boolean; // 支持删除
  showCover?: boolean; // 不可选
  onDetailPress?: (signUpDetail?: YouXueSignUp) => void;
  onSelectPress?: (signUpDetail?: YouXueSignUp) => void;
  onDeletePress?: (signUpDetail?: YouXueSignUp) => void;
}

const StudyActivityCardComp: React.FC<CardProps> = ({
  signUpDetail,
  supportMulSelect,
  supportDelete,
  showCover,
  onSelectPress,
  onDetailPress,
  onDeletePress,
}) => {
  const benefitNames = signUpDetail?.studyTourBenefitPointResp
    ?.map(benefit => benefit.benefitName)
    .join("·");

  const selectImageSrc = () => {
    if (!signUpDetail?.signUpFlag || false) {
      return "https://akim-oss.aikucun.com/mshop/eec3ad2e003b47ca2dd387bc88c570ac39ada344_1734934466162_75.png";
    }

    if (signUpDetail?.memberSignUpFlag || false) {
      return "https://akim-oss.aikucun.com/mshop/31638c8d685da2f7a7b147912126d346d379cb27_1734934335527_52.png";
    }

    return signUpDetail?.isSelect || false
      ? "https://akim-oss.aikucun.com/mshop/31638c8d685da2f7a7b147912126d346d379cb27_1734934335527_52.png"
      : "https://akim-oss.aikucun.com/mshop/f763bb96a88b46618aa6010b4cc55550f80d5b4a_1734934415434_48.png";
  };

  const showTravelTag = () => {
    return (
      (signUpDetail?.travelAllowance?.length ?? 0) > 0 ||
      (signUpDetail?.travelProvinceInAllowance?.length ?? 0) > 0 ||
      (signUpDetail?.travelProvinceOutAllowance?.length ?? 0) > 0
    );
  };

  const showTag = () => {
    return showTravelTag() || (signUpDetail?.studyCategoryList?.length ?? 0) > 0;
  };

  const onDetailAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onDetailPress?.(signUpDetail);
  };

  const travelTagDiv = (tag?: string) => (
    <div className={styles.tag__travel}>
      <div className={styles.tag__title}>{tag}</div>
    </div>
  );

  return (
    <>
      <div
        className={showCover ? `${styles.card} ${styles.card__transparent}` : styles.card}
        onClick={() => onSelectPress?.(signUpDetail)}>
        {supportMulSelect && (
          <div className={styles.card__left}>
            <img className={styles.left__image} src={selectImageSrc()} />
          </div>
        )}
        <div className={styles.card__right}>
          <div className={styles.right__top}>
            <div className={styles.top__image}>
              <img
                className={styles.image__i}
                src={formatImgUrl(signUpDetail?.studyTourLogo, 60, 60)}
              />
              {signUpDetail?.memberSignUpFlag && (
                <div className={styles.image__status}>
                  <div className={styles.status__title}>已报名</div>
                </div>
              )}
            </div>

            <div
              className={styles.top__content}
              style={{ marginRight: supportDelete ? pxtorem(20) : 0 }}>
              <div className={styles.content__title}>
                <div className={styles.title__left}>{signUpDetail?.studyTourTitle}</div>
              </div>
              <div
                className={
                  styles.content__desc
                }>{`游学活动时间：${getCurrentFormatTime(parseInt(signUpDetail?.studyTourBeginTime || "0", 10))}-${getCurrentFormatTime(parseInt(signUpDetail?.studyTourEndTime || "0", 10))}`}</div>
              <div
                className={
                  styles.address__desc
                }>{`游学地点：${signUpDetail?.studyTourProvince}`}</div>
            </div>
          </div>
          <div className={styles.right__bottom} onClick={onDetailAction}>
            <div className={styles.bottom__title}>{benefitNames}</div>
            <img
              className={styles.bottom__image}
              src="https://akim-oss.aikucun.com/mshop/dc5999c9efd5dc8255f036a748507c62d9f45d21_1716972920290_34.png"
            />
          </div>

          {showTag() && (
            <div className={styles.bottom__tag}>
              {signUpDetail?.travelAllowance && travelTagDiv(signUpDetail?.travelAllowance)}
              {signUpDetail?.travelProvinceInAllowance &&
                travelTagDiv(signUpDetail?.travelProvinceInAllowance)}
              {signUpDetail?.travelProvinceOutAllowance &&
                travelTagDiv(signUpDetail?.travelProvinceOutAllowance)}
              {(signUpDetail?.studyCategoryList?.length ?? 0) > 0 &&
                signUpDetail?.studyCategoryList?.map((item, index) => {
                  return (
                    <div key={index} className={styles.tag__category}>
                      <div key={index} className={styles.tag__title}>
                        {item.categoryName}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {supportDelete && (
            <div onClick={() => onDeletePress?.(signUpDetail)}>
              <img
                className={styles.right__delete}
                src={
                  "https://akim-oss.aikucun.com/mshop/eec3ad2e003b47ca2dd387bc88c570ac39ada344_1717420794394_34.png"
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StudyActivityCardComp;
