import { SourceScene } from "@/api/scode/scode_params";
import loadingPng from "@/base64";
import Bottom from "@/components/bottom";
import PosterSwipe from "@/components/poster-swiper";
import { pxtorem } from "@/utils/pxtorem";
import { Image, InfiniteScroll, SearchBar } from "antd-mobile";
import React from "react";
import DateSelectDialog from "./components/date-select-dialog";
import NormalCardComp from "./components/Normal";
import PhysicalCardCamp from "./components/PhysicalCard";
import styles from "./customerReward.module.less";
import useCustomerReward from "./hook/useCustomerReward";

const CustomerReward: React.FC = () => {
  const {
    tabList,
    selectTab,
    list,
    hasMore,
    loading,
    dateRange,
    dateDialogVisible,
    sharePosterData,
    showPoster,
    customEditData,
    searchBarRef,
    onChangeTabIndex,
    loadMore,
    emptyTitleDes,
    onCustomerAction,
    onForwardAction,
    onSearchChange,
    onSearchAction,
    onSelectTimeAction,
    onCloseTimeAction,
    getDateRange,
    showSelectTime,
    onClosePosterAction,
    onOrderDetailAction,
    onShopDetailAction,
    onCouponToShopDetailAction,
  } = useCustomerReward();

  return (
    <div id="page" className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header_top}>
          <div className={styles.top_left} onClick={onSelectTimeAction}>
            <div className={styles.l_text}>{showSelectTime()}</div>
            <img
              className={styles.l_image}
              src="https://akim-oss.aikucun.com/mshop/fc2808947569dba154fcacce7fb06c1e749986e9_1723187719064_56.png"
            />
          </div>
          <div className={styles.top_right}>
            <SearchBar
              ref={searchBarRef}
              className={styles.r_search}
              placeholder="请输入内容"
              onChange={onSearchChange}
              onSearch={val => {
                onSearchAction();
              }}
            />
            <div className={styles.r_text} onClick={onSearchAction}>
              搜索
            </div>
          </div>
        </div>
        <div className={styles.header_line} />
        <ul className={styles.header_tabs}>
          {tabList?.map((item, index) => {
            return (
              <li
                className={
                  item?.type === selectTab?.type
                    ? `${styles["tabs_item"]} ${styles["tabs_item--active"]}`
                    : `${styles["tabs_item"]}`
                }
                key={item.type}
                onClick={e => {
                  onChangeTabIndex(e, index);
                }}>
                {item?.name}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.body}>
        {loading && list.length === 0 && (
          <div className={`${styles.loading} ${styles.loadingFixed}`}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )}
        <>
          {list?.length > 0 ? (
            <>
              <div className={styles.card}>
                {selectTab?.type === "RED_PACKET" && (
                  <div className={styles.red_tips}>* 红包全场商品可用（虚拟和海淘商品除外）</div>
                )}
                {list.map((item, index) =>
                  item?.awardType === "PRODUCT" ? (
                    <PhysicalCardCamp
                      reward={item}
                      isFromCustomer={true}
                      key={index}
                      onInviteClick={onForwardAction}
                      onCustomerClick={onCustomerAction}
                      onOrderClick={onOrderDetailAction}
                      onShopDetailClick={onShopDetailAction}
                    />
                  ) : (
                    <NormalCardComp
                      reward={item}
                      isFromCustomer={true}
                      key={index}
                      onInviteClick={onForwardAction}
                      onCustomerClick={onCustomerAction}
                      onCouponToShopDetailClick={onCouponToShopDetailAction}
                    />
                  ),
                )}
              </div>
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={20}>
                <Bottom
                  loading={loading}
                  hasMore={hasMore}
                  reachBottom={list && list?.length > 0 ? true : false}
                />
              </InfiniteScroll>
            </>
          ) : (
            !loading && (
              <div className={styles.empty}>
                <img
                  className={styles.empty_icon}
                  src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                />
                <div>{emptyTitleDes(selectTab)}</div>
              </div>
            )
          )}
        </>
      </div>
      <DateSelectDialog
        popVisible={dateDialogVisible}
        defaultRange={dateRange.current}
        close={onCloseTimeAction}
        getDateRange={getDateRange}
      />

      {sharePosterData && (
        <PosterSwipe
          show={showPoster}
          customEditData={customEditData}
          posterData={sharePosterData}
          shareType={SourceScene.dailyRewardGift}
          btnConfig={["copy", "edit"]}
          close={onClosePosterAction}
        />
      )}
    </div>
  );
};

export default CustomerReward;
