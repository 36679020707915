import React, { useEffect, useRef, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import styles from "./index.module.less";

interface IProps {
  naviFloat: boolean;
  productStatus: number | null;
  onStatusChange: (status: number) => void;
}

const ProductStatusTab = (props: IProps) => {
  const { productStatus, onStatusChange } = props;

  const [translateY, setTranslateY] = useState(0);
  const [statusFixed, setStatusFixed] = useState(false);
  const lastScrollTop = useRef(0);

  const handleScroll = () => {
    if (!statusFixed) {
      setTranslateY(0);
      return;
    }
    let scrollTop = document.documentElement.scrollTop;
    if (Math.abs(lastScrollTop.current - scrollTop) < 50) {
      return;
    }
    window.requestAnimationFrame(() => {
      if (lastScrollTop.current > scrollTop) {
        console.log("下滑-显示");
        setTranslateY(0);
      } else if (scrollTop > 50) {
        console.log("上滑-隐藏");
        setTimeout(() => {
          setTranslateY(-40);
        }, 300);
      }
      lastScrollTop.current = scrollTop;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [statusFixed]);

  useEffect(() => {
    setStatusFixed(props.naviFloat);
  }, [props.naviFloat]);

  useActivate(() => {
    window.addEventListener("scroll", handleScroll);
  });

  useUnactivate(() => {
    window.removeEventListener("scroll", handleScroll);
  });

  return (
    <div
      className={`${styles["product-status__tab"]} ${statusFixed ? styles["status-fixed"] : ""}`}
      style={{
        transform: `translateY(${translateY}px)`,
        transition: "all ease 0.3s",
      }}>
      <span
        className={`${styles["item"]} ${productStatus === 1 ? styles["active"] : ""}`}
        onClick={() => onStatusChange(1)}>
        热卖中
      </span>
      <span
        className={`${styles["item"]} ${productStatus === 0 ? styles["active"] : ""}`}
        onClick={() => onStatusChange(0)}>
        预告
      </span>
    </div>
  );
};

export default ProductStatusTab;
