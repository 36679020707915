import { AccountInfo, ExpirationIsComing } from "@/models/reward";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import React from "react";
import styles from "./RedPacket.module.less";
import RotateComp from "./Rotate";

interface CardProps {
  redPacket?: AccountInfo;
  totalAmount: number;
  expirationIsComing: ExpirationIsComing;
  onUsePress?: (isUse?: boolean, info?: AccountInfo) => void;
  onDetailPress?: (info?: AccountInfo) => void;
  onRefreshPress?: () => void;
}

const Popover = ({ children }) => {
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const hintStyle = React.useRef({});
  const [visible, setVisible] = React.useState(false);

  const handleClickToShowPopover = (e) => {
    e.stopPropagation();
    // const { offsetTop, offsetLeft, clientHeight } = nodeRef.current as HTMLDivElement;

    hintStyle.current = {
      right: `-${pxtorem(20)}`,
      top: `-${pxtorem(38 + 6)}`,
    };
    setVisible(!visible);

    if (!visible) {
      const timer = setTimeout(() => {
        if (timer) {
          clearTimeout(timer);
        }
        setVisible(false);
      }, 5000);
    }
  };

  return (
    <div ref={nodeRef} className={styles.popover_container} onClick={handleClickToShowPopover}>
      {children}
      {
        visible && (
          <div
            className={styles.hint_message}
            style={hintStyle.current}
            onClick={handleClickToShowPopover}
          >
            2天内即将过期的红包，使用时优先抵扣即将过期的余额~
          </div>
        )
      }
    </div>
  );
};


const RedPacketCardComp: React.FC<CardProps> = ({
  redPacket,
  totalAmount,
  expirationIsComing,
  onUsePress,
  onDetailPress,
  onRefreshPress,
}) => {
  const hasRedPacket = parseFloat(redPacket?.freeBalance ?? "0") > 0;

  const onUseAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onUsePress?.(hasRedPacket, redPacket);
  };

  const onDetailAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onDetailPress?.(redPacket);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.tag} />
          <div className={styles.card_left}>
            <div className={styles.left_content}>
              <div className={styles.first_container}>
                <div className={styles.content_title} onClick={onDetailAction}>
                  <div className={styles.title_left}>红包余额</div>
                  <div className={styles.title_right}>
                    <div className={styles.price_left}>¥</div>
                    <div className={styles.price_right}>{`${redPacket?.freeBalance ?? 0}`}</div>
                  </div>
                  <div className={styles.dec_title}>明细</div>
                  <img
                    className={styles.dec_image}
                    src={
                      "https://akim-oss.aikucun.com/mshop/11fdcf7b0fdc6fde0a168ede848ffb6681c0bef8_1723546528825_85.png"
                    }
                  />
                </div>
                <div className={styles.card_right} onClick={onUseAction}>
                  <div className={styles.btn_title}>{hasRedPacket ? "去使用" : "去逛逛"}</div>
                </div>
              </div>
              { /* 即将过期 */}
              {
                expirationIsComing?.expiringBalance ? (
                  <div className={styles.overdue}>
                    <span style={{ fontSize: "12px", color: "#fff" }}>
                      即将过期¥{expirationIsComing.expiringBalance}，使用时优先抵扣即将过期的红包
                    </span>
                    <Popover>
                      <div style={{ marginLeft: "2px", paddingTop: "4px" }}>
                        <img
                          style={{ width: "14px", height: "14px" }}
                          src='https://filetest-oss.aikucun.com/akucun-member-aggregation-file/ff855c6522401be1a1fb7d875e7a90af5a71c048_1735550280242_3.png'
                        />
                      </div>
                    </Popover>
                  </div>
                ) : null
              }
              {totalAmount > -1 ? (
                <div className={styles.desc_title}>{`累计获取红包共计¥${totalAmount}`}</div>
              ) : (
                <div className={styles.total_content}>
                  <div className={styles.c_title}>累计获取红包</div>
                  <RotateComp toggleRotationPress={onRefreshPress} />
                  <div className={styles.c_title}>点击刷新</div>
                </div>
              )}
            </div>
          </div>
          {/* <div className={styles.card_right} onClick={onUseAction}>
            <div className={styles.btn_title}>{hasRedPacket ? "去使用" : "去逛逛"}</div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default RedPacketCardComp;
