import { queryDataReportServ } from "@/api/appH5";
import { getCookie } from "@/utils/platform";
import constate from "constate";
import React, { useEffect, useState } from "react";

// 用户类型
enum UserType {
  R = "R",
  e = "e",
}

type PopupDataType = {
  isPopup: boolean; // 是否展示弹窗
  userType: UserType; // 用户类型 R e
  willCompleteCnt: number; // 即将达标的数量
  willEndCnt: number; // 即将达标的数量
  waitStartCnt: number; // 即将达标的数量
  waitJoinCnt: number; // 即将达标的数量
};

const useShopInfo = () => {
  const [dataReport, setDataReport] = useState<PopupDataType>({
    isPopup: false,
    userType: UserType.e,
    willCompleteCnt: 0,
    willEndCnt: 0,
    waitStartCnt: 0,
    waitJoinCnt: 0,
  });

  const queryDataReport = (params?: any) => {
    queryDataReportServ().then((res) => {
      if (!res) {
        return;
      }
      setDataReport({
        ...res,
        isPopup: params ? params.isPopup : res.isPopup,
      });
      if (params === undefined) {
        setTimeout(() => {
          setDataReport({
            ...res,
            isPopup: false,
          });
        }, 5000);
      }
    });
  };

  useEffect(() => {
    queryDataReport();
  }, []);

  return { dataReport, setDataReport, queryDataReport };
};

const [ShopInfoProvider, UseShopInfoContext] = constate(useShopInfo);
export { ShopInfoProvider, UseShopInfoContext };
