

import React from "react";
import styles from "./RightsUpgradePop.module.less";

type Props = {
  obj: any;
  onClickDetail: () => void;
  onClose: () => void;
};
const DistributorStimulateSwitchSecondPop: React.FC<Props> = ({ obj, onClickDetail, onClose }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>激励活动进度日报</div>
      <div>
        {obj?.willCompleteCnt > 0 && <section className={styles.section}>
          <span className={styles.title}>即将达标：</span>
          <span className={styles.content}>
            共<span className={styles.mark}>{obj?.willCompleteCnt}</span>场活动业绩即将冲破下一阶梯的门槛，继续加油争取更高的奖励!
          </span>
        </section>}
        {obj?.willEndCnt > 0 && <section className={styles.section}>
          <span className={styles.title}>即将结束：</span>
          <span className={styles.content}>
            共<span className={styles.mark}>{obj?.willEndCnt}</span>场活动时间不足24小时了，可进行最后阶段的冲刺
          </span>
        </section>}
        {obj?.waitStartCnt > 0 && <section className={styles.section}>
          <span className={styles.title}>待开始：</span>
          <span className={styles.content}>
            共<span className={styles.mark}>{obj?.waitStartCnt}</span>场预热中的活动，敬请期待
          </span>
        </section>}
        {obj?.waitJoinCnt > 0 && <section className={styles.section}>
          <span className={styles.title}>待参加：</span>
          <span className={styles.content}>
            共<span className={styles.mark}>{obj?.waitJoinCnt}</span>场进行中的活动你还没有推广业绩，和大家一起转发播货赢奖励吧
          </span>
        </section>}

      </div>
      <div className={styles.closeBtn} onClick={onClose}></div>
      <div className={styles.footer} onClick={() => { onClickDetail(); onClose(); }}>
        查看详情
      </div>
    </div>
  );
};

export default DistributorStimulateSwitchSecondPop;
