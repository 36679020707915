export const urlQueryObject = (): {
  [key in string]: string | undefined;
} => {
  const queryObj = {};
  const querySplit = window.location.href.split("?");
  const queryStr = querySplit[querySplit.length - 1];
  const queryArray = queryStr ? queryStr.split("&") : [];
  queryArray.forEach(i => {
    const query = i.split("=");
    const [key, value] = query;
    queryObj[key] = value;
  });
  return queryObj;
};

/**
 * 获取当前url中参数
 * @param name {有值则返回当前值得参数，没有则返回全部参数[Object]}
 */
export function getUrlParam(name: null | string = null, url: null | string = null): any {
  const result = {};
  let curUrl = url || window.location.href;

  curUrl = curUrl.split("?") as any;

  if (Array.isArray(curUrl)) {
    curUrl = curUrl.length > 1 ? curUrl[1] : null;
  }

  if (curUrl) {
    curUrl.split("&").forEach(element => {
      if (!element) {
        return;
      }
      const temp = element.split("=");
      const key = temp[0];
      const value =
        typeof temp[1] === "undefined"
          ? undefined
          : decodeURIComponent(temp[1].replace(/%/g, "%25"));
      result[key] = value;
    });
  }

  return name ? result[name] : result;
}

export function getBaseurl() {
  const getPlatform = () => {
    // eslint-disable-next-line no-underscore-dangle
    const isMini = window.__wxjs_environment === "miniprogram";
    const isApp = navigator && navigator.userAgent.search("AKC") > -1;
    const isiOS = isApp && navigator && navigator.userAgent.match(/(iPhone|iPad|iPod|iOS)/i);
    const isAndroid = isApp && navigator && navigator.userAgent.match(/(Android)/i);
    const isSaas = isMini && !!location.href.match(/plt=saas/i);
    const isWxH5 = !isMini && navigator && !!navigator.userAgent.match(/miniProgram/i);
    const isH5 = isWxH5 || (!isMini && !isApp);
    const isTuan = isMini && location.href.match(/plt=atuan/i);
    return {
      isMini,
      isApp,
      isiOS,
      isAndroid,
      isWxH5,
      isH5,
      isSaas,
      isBrowser: !isMini && !isApp && !isWxH5,
      isTuan,
    };
  };
  const staticUrl = location.protocol + "//" + location.hostname;

  if (process.env.REACT_APP_MODE === "stable") {
    return process.env.REACT_APP_BASE_URL;
  }

  if (process.env.REACT_APP_MODE === "local") {
    return process.env.REACT_APP_BASE_URL;
  }

  if (process.env.REACT_APP_ENV === "stable") {
    return process.env.REACT_APP_BASE_URL;
  }

  if (getPlatform()?.isH5 || getPlatform()?.isWxH5) {
    if (!staticUrl) {
      return "";
    }
    return staticUrl;
  }

  return process.env.REACT_APP_BASE_URL;
}

export function obj2UrlParams(obj) {
  if (
    !obj ||
    Object.prototype.toString.call(obj) !== "[object Object]" ||
    !Object.keys(obj).length
  ) {
    return "";
  }
  const arr: string[] = [];
  Object.keys(obj).forEach((v) => {
    arr.push(v + "=" + obj[v]);
  });
  return arr.join("&");
}

export function getUrlQueryObject(href = window.location.href) {
  const queryObj = {};
  const querySplit = href.split("?");
  const queryStr = querySplit[querySplit.length - 1];
  const queryArray = queryStr ? queryStr.split("&") : [];
  queryArray.forEach(i => {
    const query = i.split("=");
    const [key, value] = query;
    queryObj[key] = value;
  });
  return queryObj;
};
