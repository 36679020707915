import { action, computed, makeObservable, observable } from "mobx";

class AppStore {
  todos = [];
  removeList = [];
  token = null;
  channel = null;
  xid = null;
  userInfo = null;
  constructor() {
    makeObservable(this, {
      userInfo: observable,
      xid: observable,
      channel: observable,
      token: observable,
      todos: observable,
      removeList: observable,
      completedTodosCount: computed,
      report: computed,
      addTodo: action,
    });
  }

  get completedTodosCount() {
    return this.todos.filter(todo => todo.completed === true).length;
  }

  get report() {
    if (this.todos.length === 0) {
      return "<none>";
    }
    const nextTodo = this.todos.find(todo => todo.completed === false);
    return (
      `Next todo: "${nextTodo ? nextTodo.task : "<none>"}". ` +
      `Progress: ${this.completedTodosCount}/${this.todos.length}`
    );
  }

  addTodo(task) {
    this.todos.push({
      task: task,
      completed: false,
      assignee: null,
    });
  }
}

export default AppStore;
