import VideoPlayComp from "@/pages/product-detail/components/video/video";
import React from "react";
import styles from "./index.module.less";
type Props = {
  videoUrl?: string;
  coverUrl?: string;
};
const TopVideoComp: React.FC<Props> = ({ videoUrl, coverUrl }) => {
  return (
    <div className={styles.pdTopVideo}>
      <VideoPlayComp
        width={375}
        height={375}
        videoUrl={videoUrl ?? ""}
        thumbnail={coverUrl}
        showControls={false}
        customPlayBtn
      />
    </div>
  );
};

export default TopVideoComp;
