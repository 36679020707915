import {
  searchActivity,
  searchNavigationActivity,
  searchNavigationProduct,
  searchProduct,
} from "@/api";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import noSecKill from "@/assets/no_seckill.png";
import loadingPng from "@/base64";
import { initTime } from "@/utils/countdown";
import exposure from "@/utils/expose/exposure";
import { platform } from "@/utils/platform";
import Track from "@/utils/track";
import { getUrlParam } from "@/utils/url-parse";
import { Image, InfiniteScroll } from "antd-mobile";
import { isEmpty } from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import ActivityCom from "../../components/activity/Index";
import { pxtorem } from "../../utils/pxtorem";
import ImageCom from "../image/Index";
import ProductCom from "../product/Index";
import TextCom from "../text/Index";
import "./index.less";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  last?: any;
  sticky?: any;
  [key: string]: any;
}

let flag: any = null;

const NavigationCom: React.FC<Props> = (props: Props) => {
  const [activeKey, setActiveKey] = useState(
    props.propData?.navigationConfigDetails[0]
      ? props.propData?.navigationConfigDetails[0].archorComponentCode
        ? props.propData?.navigationConfigDetails[0].archorComponentCode
        : "0"
      : "0",
  );

  // eslint-disable-next-line prefer-const
  let [dataInfo, setDataInfo] = useState(JSON.parse(JSON.stringify(props.propData)));
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAllEmpty, setIsAllEmpty] = useState(true);
  const track = Track();

  //加载更多(仅限最后一个组件为档期或商品)
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean; }) => {
    if (!props.last) {
      return <></>;
    }
    return (
      <>
        {hasMore ? (
          <div className="loadingMore">
            <Image src={loadingPng} width={pxtorem(20)} height={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
            <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
            <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
          </div>
        )}
      </>
    );
  };

  const changeTime = data => {
    for (const item of data?.result || []) {
      const { day, hour, minute, seconds } = initTime({
        type: "ms",
        startTime: item?.status === 1 ? item?.beginTimeStr : item?.endTimeStr,
        serverTime: new Date().valueOf(),
      });
      item.day = day;
      item.hour = hour;
      item.minute = minute;
      item.seconds = seconds;
    }
  };

  useEffect(() => {
    setDataInfo(props.propData);
    dataInfo = props.propData;
    if (dataInfo && props.last) {
      if (dataInfo?.styleType === "0") {
        setHasMore(dataInfo?.navigationConfigDetails[activeKey]?.dataInfo?.hasNext);
      } else {
        const last = dataInfo?.components?.[dataInfo?.components?.length - 1];
        console.log("电梯导航---", last);
        if (last?.dataInfo) {
          setHasMore(last?.dataInfo?.hasNext ? last?.dataInfo?.hasNext : false);
          console.log("电梯导航---设置", last?.dataInfo?.hasNext);
        }
      }
    }
    if (props.propData?.components) {
      const interval = setInterval(() => {
        let flag = false;
        for (let i = 0; i < (dataInfo?.navigationConfigDetails?.length || 0); i++) {
          if (
            ((props.propData.styleType === "DEFAULT" || props.propData.styleType === "0") &&
              (isEmpty(props.propData?.navigationConfigDetails[i]) ||
                isEmpty(props.propData?.navigationConfigDetails[i]?.dataInfo?.result))) ||
            ((props.propData.styleType === "ELEVATOR" || props.propData.styleType === "1") &&
              (isEmpty(props.propData?.components[i]) ||
                isEmpty(props.propData?.components[i]?.dataInfo?.result)))
          ) {
            // console.log("i", i);
            continue;
          } else {
            const dom = document.getElementById(`${props.propData.componentCode}-nav${i}`);
            if (dom) {
              exposure.add({
                el: dom,
              });
              console.log("导航tab---------", `${props.propData.componentCode}-nav${i}`);
            } else {
              flag = true;
            }
          }
          if (!flag) {
            clearInterval(interval);
          }
        }
      }, 1000);
    }
  }, [JSON.stringify(props.propData)]);

  const judgeAllEmpty = () => {
    for (let index = 0; index < dataInfo?.navigationConfigDetails.length; index++) {
      const item = dataInfo?.navigationConfigDetails[index];
      if (
        !(dataInfo.styleType === "ELEVATOR" || dataInfo.styleType === "1") &&
        !isEmpty(item?.dataInfo?.result)
      ) {
        setIsAllEmpty(false);
        setActiveKey(String(index));
        console.log("setActiveKey", activeKey);
        break;
      }
    }
  };

  useEffect(() => {
    judgeAllEmpty();
  }, [dataInfo.componentCode]);

  useEffect(() => {
    if (dataInfo && props.last) {
      if (dataInfo?.styleType === "0") {
        setHasMore(dataInfo?.navigationConfigDetails[activeKey]?.dataInfo?.hasNext);
      } else {
        const last = dataInfo?.components?.[dataInfo?.components?.length - 1];
        if (last?.dataInfo) {
          setHasMore(last?.dataInfo?.hasNext ? last?.dataInfo?.hasNext : false);
          console.log("电梯导航---6666", last);
        }
      }
    }
    console.log("dataInfo", props.propData);
  }, []);

  const changeActiveKey = (key: string, tabName?: any) => {
    if (key === activeKey) {
      return;
    }
    setActiveKey(key);
    if (
      dataInfo.styleType !== "ELEVATOR" &&
      dataInfo.styleType !== "1" &&
      dataInfo?.navigationType !== "HORIZONTAL"
    ) {
      const id = `${dataInfo?.navigationConfigDetails[activeKey].componentDetailCode}-content`;
      const navId = `${dataInfo?.navigationConfigDetails[activeKey].componentDetailCode}-content`;
      const dom: any = document.getElementById(navId);
      const domContent: any = document.getElementById(id);
      const pageDom: any = document.getElementById("page");
      console.log("纵向滚动-----", dom?.getBoundingClientRect()?.top);
      if (platform.isH5 || platform.isWxH5) {
        if (pxtorem(dom?.getBoundingClientRect()?.top ?? 0) <= pxtorem(44)) {
          console.log("元素距离页面最上距离", domContent.offsetTop);
          pageDom.scrollTop = domContent.offsetTop - 50;
        }
      } else {
        document.getElementById(props?.propData?.componentCode)?.scrollIntoView();
      }
    }
    if (dataInfo.styleType === "0" && dataInfo.navigationType === "HORIZONTAL") {
      const id = `${dataInfo?.navigationConfigDetails[activeKey].componentDetailCode}-content`;
      const navId = `${dataInfo?.navigationConfigDetails[activeKey].componentDetailCode}-content`;
      const dom: any = document.getElementById(navId);
      const domContent: any = document.getElementById(id);
      const pageDom: any = document.getElementById("page");
      console.log("横向滚动-----", dom?.getBoundingClientRect()?.top);
      if (
        (platform.isH5 || platform.isWxH5) &&
        pxtorem(dom?.getBoundingClientRect()?.top ?? 0) <= pxtorem(97)
      ) {
        // domContent?.scrollIntoView();
        console.log("元素距离页面最上距离", domContent.offsetTop);
        pageDom.scrollTop = domContent.offsetTop - 96;
        // return;
      }
      if ((dom?.getBoundingClientRect()?.top ?? 0) <= 0) {
        // domContent?.scrollIntoView();
        console.log("元素距离页面最距离", domContent.offsetTop);
        pageDom.scrollTop = domContent.offsetTop - 50;
        // return;
      }
    }
    setLoading(false);
    setHasMore(true);
    /* eslint-disable */
    track.track("tab_click", {
      page_name: document.title,
      tab_name: tabName,
      rank: Number(key),
      market_id: getUrlParam()?.["customPageCode"],
      market_name: document.title,
    });
    /* eslint-enable */
  };

  const handleSetActive = to => {
    if (!flag) {
      flag = setTimeout(() => {
        setActiveKey(to);
        flag = null;
      }, 300);
    } else {
      clearTimeout(flag);
      flag = setTimeout(() => {
        setActiveKey(to);
        flag = null;
      }, 300);
    }
  };

  //获取档期
  const queryActivity = async (item: any, pageData: any, from = 0, isNav, isScroll = false) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!isNav) {
      const params = {
        componentCode: item.componentCode,
        componentDetailCodes: item.activityRuleConfigDetails.map(item => item.componentDetailCode),
        customPageCode: pageData.pageCode,
        customPageVersion: pageData.version,
        from: from,
        pageSize: from > 0 ? item.morePageSize : item.pageSize,
        lastActivityId:
          item?.dataInfo?.result?.length > 0
            ? item?.dataInfo?.extra?.lastActivityId
              ? item?.dataInfo?.extra?.lastActivityId
              : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activityId
            : null,
        pageId: "H5_SuperMarketing",
      };
      const res = await searchActivity(params);
      if (res) {
        if (from > 0 && res?.data) {
          res.data.result = (item?.dataInfo?.result || []).concat(
            res?.data?.result?.length > 0 ? res.data.result : [],
          );
          item.dataInfo = res?.data;
        } else {
          item.dataInfo = res?.data;
        }
        changeTime(item.dataInfo);
        const last = dataInfo?.components?.[dataInfo?.components?.length - 1];
        if (isScroll && last?.componentCode === item.componentCode) {
          setHasMore(res?.data?.hasNext ?? false);
        }
      }
    } else {
      const params = {
        componentCode: dataInfo.componentCode,
        componentDetailCode: item.componentDetailCode,
        customPageCode: pageData.pageCode,
        customPageVersion: pageData.version,
        from: from,
        pageSize: from > 0 ? item.morePageSize : item.pageSize,
        lastActivityId:
          item?.dataInfo?.result?.length > 0
            ? item?.dataInfo?.extra?.lastActivityId
              ? item?.dataInfo?.extra?.lastActivityId
              : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activityId
            : null,
        pageId: "H5_SuperMarketing",
      };
      const res = await searchNavigationActivity(params);
      if (res) {
        if (from > 0 && res?.data) {
          res.data.result = (item?.dataInfo?.result || []).concat(
            res?.data?.result?.length > 0 ? res.data.result : [],
          );
          item.dataInfo = res?.data;
        } else {
          item.dataInfo = res?.data;
        }
        changeTime(item.dataInfo);
        if (isScroll) {
          setHasMore(res?.data?.hasNext ?? false);
        }
      }
    }
    setDataInfo({ ...dataInfo });
    setLoading(false);
  };

  //获取商品
  const queryProduct = async (item: any, pageData: any, from = 0, isNav, isScroll = false) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!isNav) {
      const params = {
        componentCode: item.componentCode,
        componentDetailCode: item.productRuleConfigDetails[0].componentDetailCode,
        customPageCode: pageData.pageCode,
        customPageVersion: pageData.version,
        from: from,
        pageSize: from > 0 ? item.morePageSize : item.pageSize,
        lastActivitySpuCode:
          item?.dataInfo?.result?.length > 0
            ? item?.dataInfo?.extra?.lastActivitySpuCode
              ? item?.dataInfo?.extra?.lastActivitySpuCode
              : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activitySpuId
            : null,
        pageId: "H5_SuperMarketing",
        preview: false,
      };
      const res = await searchProduct(params);
      if (from > 0 && res?.data) {
        res.data.result = (item?.dataInfo?.result || []).concat(res.data.result);
        item.dataInfo = res?.data;
      } else {
        item.dataInfo = res?.data;
      }
      const last = dataInfo?.components?.[dataInfo?.components?.length - 1];
      if (isScroll && last?.componentCode === item.componentCode) {
        setHasMore(res?.data?.hasNext ?? false);
      }
    } else {
      const params = {
        componentCode: dataInfo.componentCode,
        componentDetailCode: item.componentDetailCode,
        customPageCode: pageData.pageCode,
        customPageVersion: pageData.version,
        from: from,
        pageSize: from > 0 ? item.morePageSize : item.pageSize,
        lastActivitySpuCode:
          item?.dataInfo?.result?.length > 0
            ? item?.dataInfo?.extra?.lastActivitySpuCode
              ? item?.dataInfo?.extra?.lastActivitySpuCode
              : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activitySpuId
            : null,
        pageId: "H5_SuperMarketing",
        preview: false,
      };
      const res = await searchNavigationProduct(params);
      if (from > 0 && res?.data) {
        res.data.result = (item?.dataInfo?.result || []).concat(res.data.result);
        item.dataInfo = res?.data;
      } else {
        item.dataInfo = res?.data;
      }
      if (isScroll) {
        setHasMore(res?.data?.hasNext ?? false);
      }
    }
    setDataInfo(JSON.parse(JSON.stringify(dataInfo)));
    setLoading(false);
  };

  //档期加载更多
  const loadActivity = async (item, elevator?: boolean, isScroll = false) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: elevator ? "电梯导航档期" : "普通导航档期",
        btn_name: "查看更多",
        btn_text: "查看更多",
      });
    }
    /* eslint-enable */
    if (elevator) {
      const last = item;
      await queryActivity(
        last,
        props.pageData,
        last?.dataInfo?.result?.length ?? 0,
        false,
        isScroll,
      );
    } else {
      const last = dataInfo?.navigationConfigDetails[item];
      await queryActivity(
        last,
        props.pageData,
        last?.dataInfo?.result?.length ?? 0,
        true,
        isScroll,
      );
    }
  };

  //商品加载更多
  const loadProduct = async (item, elevator?: boolean, isScroll = false) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: elevator ? "电梯导航商品" : "普通导航商品",
        btn_name: "查看更多",
        btn_text: "查看更多",
      });
    }
    /* eslint-enable */
    if (elevator) {
      const last = item;
      await queryProduct(
        last,
        props.pageData,
        last?.dataInfo?.result?.length ?? 0,
        false,
        isScroll,
      );
    } else {
      const last = dataInfo?.navigationConfigDetails[item];
      await queryProduct(last, props.pageData, last?.dataInfo?.result?.length ?? 0, true, isScroll);
    }
  };

  const loadMore = async () => {
    if (dataInfo.styleType === "0") {
      if (dataInfo?.navigationConfigDetails[activeKey].type === "ACTIVITY") {
        loadActivity(activeKey, false, true);
      }
      if (dataInfo?.navigationConfigDetails[activeKey].type === "PRODUCT") {
        loadProduct(activeKey, false, true);
      }
    } else {
      if (dataInfo?.components[dataInfo?.components.length - 1].type === "ACTIVITY") {
        loadActivity(dataInfo?.components[dataInfo?.components.length - 1], true, true);
      } else if (dataInfo?.components[dataInfo?.components.length - 1].type === "PRODUCT") {
        loadProduct(dataInfo?.components[dataInfo?.components.length - 1], true, true);
      } else {
        setHasMore(false);
      }
    }
  };

  const rem: any = document.documentElement.style.fontSize.split("px")[0];
  const top = rem * 0.88;

  return (
    <>
      {dataInfo?.navigationType === "HORIZONTAL" &&
        (dataInfo.styleType === "DEFAULT" || dataInfo.styleType === "0") && (
          <div
            style={{
              marginTop: !isAllEmpty ? pxtorem(props.propData.paddingTop) : "",
              marginBottom: !isAllEmpty ? pxtorem(props.propData.paddingBottom) : "",
            }}
            id={props.propData.componentCode}>
            <div
              className="navigation_horizontal"
              id={`${dataInfo?.navigationConfigDetails[activeKey].componentDetailCode}-nav`}
              style={{
                top: props.stickTop
                  ? pxtorem(props.stickTop)
                  : platform.isH5 || platform.isWxH5
                    ? pxtorem(44)
                    : pxtorem(0),
                background: props.propData.backgroundImgUrl
                  ? `url(${props.propData.backgroundImgUrl}) no-repeat`
                  : "unset",
                backgroundSize: "cover",
                backgroundColor: props.propData.backgroundColor,
              }}>
              {dataInfo?.navigationConfigDetails?.map((nav, index) => {
                return (
                  <div key={index}>
                    {isEmpty(nav?.dataInfo?.result) ? (
                      <></>
                    ) : (
                      <div
                        style={{
                          paddingRight:
                            index < dataInfo?.navigationConfigDetails?.length ? pxtorem(18) : 0,
                          color:
                            String(index) === activeKey
                              ? props.propData.activeFontColor
                              : props.propData.fontColor,
                          backgroundColor:
                            String(index) === activeKey ? props.propData.activeBackgroundColor : "",
                        }}
                        className={String(index) === activeKey ? "active_tab" : "normal_tab"}
                        id={`${props.propData.componentCode}-nav${index}`}
                        data-param={JSON.stringify({
                          type: dataInfo.styleType === "0" ? "普通导航" : "电梯导航",
                          name: document.title,
                          tab_name: nav.tabName,
                          rank: index,
                          market_id: getUrlParam()?.["customPageCode"],
                          market_name: document.title,
                        })}
                        data-eventid={props.propData.componentCode + "-nav" + index}
                        onClick={() => changeActiveKey(String(index), nav.tabName)}>
                        {nav.tabImage ? (
                          <Image
                            className={String(index) === activeKey ? "active_img" : "normal_img"}
                            src={nav.tabImage}
                            fit="cover"></Image>
                        ) : (
                          <div className="nav_text">{nav.tabName}</div>
                        )}
                        {String(index) === activeKey && (
                          <div className="nav_content">
                            <div
                              className="nav_dot"
                              style={{
                                backgroundColor: props.propData.activeColor,
                              }}></div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              key={dataInfo?.navigationConfigDetails[activeKey].componentDetailCode}
              id={`${dataInfo?.navigationConfigDetails[activeKey].componentDetailCode}-content`}>
              {dataInfo?.navigationConfigDetails[activeKey].type === "ACTIVITY" && (
                <div>
                  {isEmpty(dataInfo?.navigationConfigDetails[activeKey]?.dataInfo) ? (
                    <>
                      {dataInfo?.navigationConfigDetails[activeKey]?.dataInfo && (
                        <div className="nullStatus">
                          <Image className="noSecKill" src={noSecKill}></Image>
                          <div className="text">暂无活动</div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        padding: `${pxtorem(6)}`,
                      }}>
                      <ActivityCom
                        propData={dataInfo?.navigationConfigDetails[activeKey]}
                        pageData={props.pageData}
                        last={props.last}
                        loadMore={() => loadActivity(activeKey, false)}
                        isNav={true}
                        navType="normal"
                        navResourceRank={props.resourceRank}></ActivityCom>
                    </div>
                  )}
                </div>
              )}
              {dataInfo?.navigationConfigDetails[activeKey].type === "PRODUCT" && (
                <div>
                  {isEmpty(dataInfo?.navigationConfigDetails[activeKey]?.dataInfo) ? (
                    <>
                      {dataInfo?.navigationConfigDetails[activeKey]?.dataInfo && (
                        <div className="nullStatus">
                          <Image className="noSecKill" src={noSecKill}></Image>
                          <div className="text">暂无商品</div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        paddingLeft: `${pxtorem(12)}`,
                        paddingRight: `${pxtorem(12)}`,
                        paddingTop: `${pxtorem(6)}`,
                      }}>
                      <ProductCom
                        propData={dataInfo?.navigationConfigDetails[activeKey]}
                        pageData={props.pageData}
                        isNav={true}
                        last={props.last}
                        navType="normal"
                        navStyle="HORIZONTAL"
                        navResourceRank={props.resourceRank}
                        loadMore={() => loadProduct(activeKey, false)}></ProductCom>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      {dataInfo?.navigationType === "VERTICAL" &&
        (dataInfo.styleType === "DEFAULT" || dataInfo.styleType === "0") && (
          <div
            style={{
              marginTop: pxtorem(props.propData.paddingTop),
              marginBottom: pxtorem(props.propData.paddingBottom),
            }}
            id={props.propData.componentCode}>
            <div className="navigation_vertical">
              <div
                className="sider"
                style={{
                  color: dataInfo.fontColor,
                  borderTopRightRadius: pxtorem(6),
                  borderBottomRightRadius: pxtorem(6),
                }}>
                <div
                  style={{
                    position: "sticky",
                    top: platform?.isH5 || platform?.isWxH5 ? pxtorem(60) : pxtorem(0),
                    background: props.propData.backgroundImgUrl
                      ? `url(${props.propData.backgroundImgUrl}) no-repeat`
                      : "unset",
                    backgroundSize: "cover",
                    backgroundColor: props.propData.backgroundImgUrl
                      ? "unset"
                      : dataInfo?.backgroundColor,
                    borderTopRightRadius: pxtorem(6),
                    borderBottomRightRadius: pxtorem(6),
                  }}>
                  <div className="nav_content">
                    {dataInfo?.navigationConfigDetails?.map((item, index) => {
                      return (
                        <div key={index}>
                          {isEmpty(item) || isEmpty(item?.dataInfo?.result) ? (
                            <></>
                          ) : (
                            <Link
                              key={index}
                              activeClass="active"
                              containerId=""
                              spy={true}
                              smooth={true}
                              offset={0}
                              duration={300}>
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  paddingTop: pxtorem(15),
                                  paddingBottom: pxtorem(15),
                                  paddingLeft: pxtorem(6),
                                  paddingRight: pxtorem(6),
                                  display: "flex",
                                  alignItems: "center",
                                  boxSizing: "border-box",
                                  borderTopLeftRadius: index === 0 ? 0 : pxtorem(6),
                                  borderTopRightRadius: index === 0 ? pxtorem(6) : 0,
                                  borderBottomLeftRadius:
                                    index === dataInfo?.navigationConfigDetails.length - 1
                                      ? 0
                                      : pxtorem(6),
                                  borderBottomRightRadius:
                                    index === dataInfo?.navigationConfigDetails.length - 1
                                      ? pxtorem(6)
                                      : 0,
                                  backgroundColor: props.propData.backgroundImgUrl
                                    ? "unset"
                                    : String(index) === activeKey
                                      ? dataInfo?.activeBackgroundColor
                                      : dataInfo?.backgroundColor,
                                }}
                                id={`${props.propData.componentCode}-nav${index}`}
                                data-param={JSON.stringify({
                                  type: dataInfo.styleType === "0" ? "普通导航" : "电梯导航",
                                  name: document.title,
                                  tab_name: item.tabName,
                                  rank: index,
                                  market_id: getUrlParam()?.["customPageCode"],
                                  market_name: document.title,
                                })}
                                data-eventid={props.propData.componentCode + "-nav" + index}
                                onClick={() => changeActiveKey(String(index), item.tabName)}>
                                <div
                                  className="dot"
                                  style={{
                                    backgroundColor: dataInfo.activeColor,
                                    visibility: String(index) === activeKey ? "visible" : "hidden",
                                  }}></div>
                                {item.tabImage ? (
                                  <Image
                                    style={{
                                      width: pxtorem(30),
                                      paddingLeft: pxtorem(9),
                                    }}
                                    src={item.tabImage}></Image>
                                ) : (
                                  <div
                                    className="title"
                                    style={{
                                      color:
                                        String(index) === activeKey
                                          ? dataInfo.activeFontColor
                                          : dataInfo.fontColor,
                                      fontWeight: String(index) === activeKey ? 500 : 400,
                                    }}>
                                    {item.tabName?.length === 4 ? (
                                      <>
                                        <div>{item?.tabName?.substring(0, 2)}</div>
                                        <div>{item?.tabName?.substring(2, 4)}</div>
                                      </>
                                    ) : item.tabName?.length === 5 ? (
                                      <>
                                        <div>{item?.tabName?.substring(0, 2)}</div>
                                        <div>{item?.tabName?.substring(2, 5)}</div>
                                      </>
                                    ) : item.tabName?.length === 6 ? (
                                      <>
                                        <div>{item?.tabName?.substring(0, 3)}</div>
                                        <div>{item?.tabName?.substring(3, 6)}</div>
                                      </>
                                    ) : (
                                      item.tabName
                                    )}
                                  </div>
                                )}
                              </div>
                            </Link>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="container" id={"containerElement_" + dataInfo.componentCode}>
                <div className="content">
                  {dataInfo?.navigationConfigDetails.map((item, index) => {
                    return (
                      <div
                        key={item.componentDetailCode}
                        id={`${item.componentDetailCode}-content`}
                        style={{ padding: `0 ${pxtorem(9)}` }}>
                        {item.type === "ACTIVITY" && activeKey === String(index) && (
                          <>
                            {isEmpty(item) || isEmpty(item?.dataInfo?.result) ? (
                              <div className="nullStatus">
                                <Image className="noSecKill" src={noSecKill}></Image>
                                <div className="text">暂无活动</div>
                              </div>
                            ) : (
                              <ActivityCom
                                propData={item}
                                pageData={props.pageData}
                                isNav={true}
                                last={props.last}
                                navType="normal"
                                direction="VERTICAL"
                                navResourceRank={props.resourceRank}
                                loadMore={() => loadActivity(index, false)}></ActivityCom>
                            )}
                          </>
                        )}
                        {item.type === "PRODUCT" && activeKey === String(index) && (
                          <>
                            {isEmpty(item) || isEmpty(item?.dataInfo?.result) ? (
                              <div className="nullStatus">
                                <Image className="noSecKill" src={noSecKill}></Image>
                                <div className="text">暂无商品</div>
                              </div>
                            ) : (
                              <ProductCom
                                propData={item}
                                pageData={props.pageData}
                                isNav={true}
                                navType="normal"
                                navigationType={dataInfo.navigationType}
                                navResourceRank={props.resourceRank}
                                last={props.last}
                                loadMore={() => loadProduct(index, false)}></ProductCom>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      {dataInfo?.navigationType === "VERTICAL" &&
        (dataInfo.styleType === "ELEVATOR" || dataInfo.styleType === "1") && (
          <div
            className="navigation_vertical"
            style={{
              margin: `${pxtorem(dataInfo?.paddingTop)} 0 ${pxtorem(dataInfo?.paddingBottom)} 0`,
            }}
            id={props.propData.componentCode}>
            <div
              className="sider"
              style={{
                color: dataInfo.fontColor,
                borderTopRightRadius: pxtorem(6),
                borderBottomRightRadius: pxtorem(6),
              }}>
              <div
                style={{
                  position: "sticky",
                  top: platform?.isH5 || platform?.isWxH5 ? pxtorem(60) : 0,
                  background: props.propData.backgroundImgUrl
                    ? `url(${props.propData.backgroundImgUrl}) no-repeat`
                    : "unset",
                  backgroundSize: "cover",
                  backgroundColor: props.propData.backgroundImgUrl
                    ? "unset"
                    : props.propData?.backgroundColor,
                  borderTopRightRadius: pxtorem(6),
                  borderBottomRightRadius: pxtorem(6),
                }}>
                <div className="nav_content">
                  {dataInfo?.navigationConfigDetails?.map((item, index) => {
                    return (
                      <div key={index}>
                        {
                          <Link
                            key={index}
                            activeClass="active"
                            to={item.archorComponentCode}
                            containerId="page"
                            spy={true}
                            smooth={true}
                            offset={platform.isH5 || platform.isWxH5 ? -top : 0}
                            duration={300}
                            onSetActive={handleSetActive}>
                            <div
                              key={item.archorComponentCode}
                              style={{
                                position: "relative",
                                paddingTop: pxtorem(12),
                                paddingBottom: pxtorem(12),
                                paddingLeft: pxtorem(4),
                                paddingRight: pxtorem(4),
                                display: "flex",
                                alignItems: "center",
                                boxSizing: "border-box",
                                borderTopLeftRadius: index === 0 ? 0 : pxtorem(6),
                                borderTopRightRadius: index === 0 ? pxtorem(6) : 0,
                                borderBottomLeftRadius:
                                  index === dataInfo?.navigationConfigDetails.length - 1
                                    ? 0
                                    : pxtorem(6),
                                borderBottomRightRadius:
                                  index === dataInfo?.navigationConfigDetails.length - 1
                                    ? pxtorem(6)
                                    : 0,
                                backgroundColor: props.propData.backgroundImgUrl
                                  ? "unset"
                                  : item.archorComponentCode === activeKey
                                    ? dataInfo?.activeBackgroundColor
                                    : dataInfo?.backgroundColor,
                              }}
                              id={`${props.propData.componentCode}-nav${index}`}
                              data-param={JSON.stringify({
                                type: dataInfo.styleType === "0" ? "普通导航" : "电梯导航",
                                name: document.title,
                                tab_name: item.tabName,
                                rank: index,
                                market_id: getUrlParam()?.["customPageCode"],
                                market_name: document.title,
                              })}
                              data-eventid={props.propData.componentCode + "-nav" + index}
                              onClick={() =>
                                changeActiveKey(item.archorComponentCode, item.tabName)
                              }>
                              <div
                                className="dot"
                                style={{
                                  backgroundColor: dataInfo.activeColor,
                                  visibility:
                                    item.archorComponentCode === activeKey ? "visible" : "hidden",
                                }}></div>
                              {item.tabImage ? (
                                <Image
                                  style={{
                                    width: pxtorem(30),
                                    paddingLeft: pxtorem(9),
                                  }}
                                  src={item.tabImage}></Image>
                              ) : (
                                <div
                                  className="title"
                                  style={{
                                    color:
                                      item.archorComponentCode === activeKey
                                        ? dataInfo.activeFontColor
                                        : dataInfo.fontColor,
                                    fontWeight: item.archorComponentCode === activeKey ? 500 : 400,
                                  }}>
                                  {item.tabName?.length === 4 ? (
                                    <>
                                      <div>{item?.tabName?.substring(0, 2)}</div>
                                      <div>{item?.tabName?.substring(2, 4)}</div>
                                    </>
                                  ) : item.tabName?.length === 5 ? (
                                    <>
                                      <div>{item?.tabName?.substring(0, 2)}</div>
                                      <div>{item?.tabName?.substring(2, 5)}</div>
                                    </>
                                  ) : item.tabName?.length === 6 ? (
                                    <>
                                      <div>{item?.tabName?.substring(0, 3)}</div>
                                      <div>{item?.tabName?.substring(3, 6)}</div>
                                    </>
                                  ) : (
                                    item.tabName
                                  )}
                                </div>
                              )}
                            </div>
                          </Link>
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="container" id="containerEleElement">
              <div className="content" id={`containerEleElement_${dataInfo.componentCode}`}>
                {dataInfo?.components.map((item, index) => {
                  return (
                    <div
                      key={item.componentCode}
                      id={item.componentCode}
                      style={{ padding: `0 ${pxtorem(9)}` }}>
                      {item.type === "ACTIVITY" && (
                        <ActivityCom
                          propData={item}
                          isNav={true}
                          pageData={props.pageData}
                          last={
                            dataInfo?.components.length === index + 1 && props.last ? true : false
                          }
                          navType="elevator"
                          direction="VERTICAL"
                          navResourceRank={props.resourceRank}
                          loadMore={() => loadActivity(item, true)}></ActivityCom>
                      )}
                      {item.type === "PRODUCT" && (
                        <ProductCom
                          propData={item}
                          pageData={props.pageData}
                          isNav={true}
                          last={
                            dataInfo?.components.length === index + 1 && props.last ? true : false
                          }
                          navType="elevator"
                          navigationType={dataInfo.navigationType}
                          navResourceRank={props.resourceRank}
                          loadMore={() => loadProduct(item, true)}></ProductCom>
                      )}
                      {item.type === "IMAGE" && (
                        <ImageCom
                          propData={item}
                          pageData={props.pageData}
                          navType="elevator"
                          navResourceRank={props.resourceRank}></ImageCom>
                      )}
                      {item.type === "TEXT" && (
                        <TextCom
                          propData={item}
                          pageData={props.pageData}
                          navType="elevator"
                          navResourceRank={props.resourceRank}></TextCom>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      {props.last && props.propData?.navigationConfigDetails[0].dataInfo && (
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore && props.last} threshold={0}>
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
      )}
      {props.last && (dataInfo.styleType === "ELEVATOR" || dataInfo.styleType === "1") && (
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore && props.last} threshold={0}>
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
      )}
    </>
  );
};

export default inject("appStore")(observer(NavigationCom));
