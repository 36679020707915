import { getBaseurl } from "@/utils/url-parse";
import { API, CouponModel } from "@akc/biz-api";
import { Request } from "../request";
export function queryCategoryList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/category/list`,
    method: "get",
    params,
  });
}
export function queryPreferenceList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/category/preferenceList`,
    method: "post",
    data: params,
  });
}
export function preferenceSave(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/category/preferenceSave`,
    method: "post",
    data: params,
  });
}
export function queryDiscoverList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/discover/materialPageList`,
    method: "post",
    data: params,
  });
}
export function queryRecommendList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/recommend/materialPageList`,
    method: "post",
    data: params,
  });
}

export function setAwesome(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/material/interact/awesome`,
    method: "post",
    data: params,
  });
}

export function queryMaterialDetail(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/materialInfo`,
    method: "post",
    data: params,
  });
}

export function queryRecommendByMaterialNo(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/recommendByMaterialNo`,
    method: "post",
    data: params,
  });
}
//  热词
export function queryHotWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryHotWord`,
    method: "post",
    data: params,
  });
}

//  推荐词
export function querySuggestWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/querySuggest`,
    method: "get",
    params,
  });
}

//  暗词
export function queryDarkWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryDarkWord`,
    method: "post",
    data: params,
  });
}

//搜索页暗词
export function getDarkWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryDarkWord`,
    method: "post",
    data: params,
  });
}

//搜索页热词
export function getHotWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryHotWord`,
    method: "post",
    data: params,
  });
}

//搜索页联想词
export function getSuggestWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/querySuggest`,
    method: "get",
    params,
  });
}

//营销弹框
export function getWhetherPopup(data?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/gray/window/popup/whetherPopup`,
    method: "post",
    data,
  });
}

export interface PromotionRulesModel {
  /** 营销活动结束时间(时间戳) */
  endTime?: string;
  /** 促销活动类型: N_PIECES_X_CNY:N件X元 N_PIECES_X_DISCOUNT:N件X折 */
  marketingToolsType?: string;
  /** 促销活动id */
  promoActivityId?: string;
  /** 分佣描述（Apollo配置） */
  promoRuleCommissionDesc?: string[];
  /** 活动规则描述 */
  promoRuleDesc?: string[];
  /** 营销活动开始时间(时间戳) */
  startTime?: string;
}

/**
 * 查询活动规则
 * @param promoActivityIds 活动id
 * @returns
 */
export const queryPromotionRules = async (promoActivityIds: string[]) => {
  return API.post<PromotionRulesModel[]>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/promo/queryPromotionRules",
    body: {
      promoActivityIds,
    },
  });
};

// 查询优惠券
export const queryCoupon = async (params?: {
  /** 券ID（老券） */
  couponId?: string;
  /** 店长ID */
  distributorId?: string;
  /** 拥有者角色:PromoVoucherOwnerTypeEnum:USE_CODE爱豆【分享券场景】，CONSUMER用户【优惠券场景】 */
  ownerType?: string;
  /** 场景ID */
  sceneId?: string;
  /** 券资产号（新券） */
  voucherNo?: string;
}) =>
  API.post<CouponModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/v1/coupon/queryCouponByNo",
    body: params,
  });
