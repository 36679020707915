import { getOrderStatusCount } from "@/api/my/shop";
import { getCookie, getPlatform } from "@/utils/platform";
import Track from "@/utils/track";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface IOrderCount {
  waitPayOrderCount?: number;
  waitDeliverOrderCount?: number;
  waitReceiveOrderCount?: number;
  completedOrderCount?: number;
  logisticExceptionOrderCount?: number;
}

const ShopOrderCard: React.FC = () => {
  const orderStatusLst = [
    {
      label: "待付款",
      key: "waitPayOrderCount",
      icon: "https://akim-oss.aikucun.com/mshop/0dc514caf9a8428c8748aff654d8af31724fc4ff_1715062228706_90.png",
      type: 0,
    },
    {
      label: "待发货",
      key: "waitDeliverOrderCount",
      icon: "https://akim-oss.aikucun.com/mshop/7ded5c855c84f11d18f57eb952ac3c45baefb247_1715062163235_10.png",
      type: 1,
    },
    {
      label: "待收货",
      key: "waitReceiveOrderCount",
      icon: "https://akim-oss.aikucun.com/mshop/8f4d1822000722aec5c6e5bf30b32ae3e4b26b29_1715062134390_48.png",
      type: 2,
    },
    {
      label: "已完成",
      key: "completedOrderCount",
      icon: "https://akim-oss.aikucun.com/mshop/c70b5b7957596726b064cf9f6f9cca4bf36ae766_1715062269793_46.png",
      type: 20,
    },
    {
      label: "退换/售后",
      key: "",
      icon: "https://akim-oss.aikucun.com/mshop/c3794ca936b0ecc6726b20ab2d89c75a1e3771f5_1715062301838_16.png",
      type: "afterSale",
    },
    {
      label: "异常订单",
      key: "logisticExceptionOrderCount",
      icon: "https://akim-oss.aikucun.com/mshop/2aa2aa6bcf270d30b6811c4437fb8f762a053514_1710764144472_24.png",
      type: "exception",
    },
  ];

  const [orderStatusCount, setOrderStatusCount] = useState<IOrderCount>({});
  const { navigate2H5 } = useNavigation();
  const [searchParams] = useSearchParams();

  const track = Track();

  const queryOrderStatusCount = async () => {
    const data = await getOrderStatusCount();
    data && setOrderStatusCount(data);
  };

  const goOrderList = (type: number | string | undefined, btnName) => {
    track.track("btn_click", {
      page_name: "我的",
      module: "我的推广订单",
      resource_type: "我的推广订单",
      btn_name: btnName,
    });
    if (type === "afterSale") {
      const { isWxH5 } = getPlatform();
      const msUserCode = getCookie("msUserCode");
      const shopId = searchParams.get("shopId");
      const distributorId = searchParams.get("distributorId");
      window.location.href = `/aftersale/serviceRecord?source=3&auditChannel=${
        isWxH5 ? "2" : "6"
      }&type=11&queryScene=1&isDistributeOrder=0&userId=${msUserCode}&shopId=${shopId}${
        distributorId ? `&distributorId=${distributorId}` : ""
      }`;
      return;
    }
    const options: IOptions = {
      path: "/#/distributor/order/list",
      is_micro_app: true,
      query: `distributor=true&type=${type}`,
    };
    if (type === "exception") {
      options.path = "/#/order/exception-list";
    }
    navigate2H5(options);
  };

  useEffect(() => {
    queryOrderStatusCount();
  }, []);

  return (
    <div className={styles.shopOrderCard}>
      <div className={styles.orderTitle}>
        <span className={styles.title}>我的推广订单</span>
        <div className={styles.more} onClick={() => goOrderList(undefined, "查看全部")}>
          查看全部
          <i className={styles.arrow} />
        </div>
      </div>
      <div className={styles.orderStatusList}>
        {orderStatusLst.map((item, index) => {
          return (
            <div
              className={styles.orderStatusItem}
              key={index}
              onClick={() => goOrderList(item.type, item.label)}>
              <div className={styles.imgItem}>
                {item.key && orderStatusCount[item.key] > 0 && (
                  <span className={styles.count}>
                    {orderStatusCount[item.key] > 99 ? 99 : orderStatusCount[item.key]}
                  </span>
                )}
                <img src={item.icon} alt="" />
              </div>
              <div className={styles.statusText}>{item.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShopOrderCard;
