import { getWeChatAccountInfoUrlByUserId } from "@/api/my/index";
import { getAppMarketPermission } from "@/api/my/shop";
import BizPopup from "@/components/biz-popup/index";
import FollowWeChat from "@/pages/personal/components/follow-wechat";
import React, { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { UseUserInfoContext } from "../../hooks/useUserInfo";
import IncentiveProfileCard from "../incentive-profile-card";
import CardItem from "../shop-card-item";
import ShopCenterData from "../shop-center-data";
import ShopCustomerData from "../shop-customer-data";
import ShopCustomerTrack from "../shop-customer-track";
import ShopDispatchTask from "../shop-dispatch-task";
import ShopHeader from "../shop-header";
import ShopOrderCard from "../shop-order-card";
import ShopProtectAccount from "../shop-protect-account";
import ShopSchoolCard from "../shop-school-card";
import ShopServiceCard from "../shop-service-card";
import styles from "./index.module.less";
import { ShopInfoProvider } from "./useShopInfo";

type BizPopRef = {
  showDistributorStimulateSwitchSecond: (any) => void;
};

const ShopOwner: React.FC = () => {
  const { userInfo } = UseUserInfoContext();
  const [isFollow, setIsFollow] = useState<boolean>(true);
  const [showAppMarket, setShowAppMarket] = useState<boolean>(false);
  const bizPopRef = useRef<BizPopRef>(null);

  const walletItem = {
    title: "我的余额 (元)",
    key: "walletItem",
    icon: "https://akim-oss.aikucun.com/mshop/518a96c6f86e1c3c522b2a97c176bd4a4d27428d_1715062405846_50.png",
    path: "/#/wallet",
  };

  const orderItem = {
    title: "我的订单",
    key: "orderItem",
    icon: "https://akim-oss.aikucun.com/mshop/81b3798bebc63c6900afbcc6f76859c4fbb66f4b_1711634282140_17.png",
    path: "/#/order/list",
  };

  const monthlyBillItem = {
    title: "月度结算账单",
    key: "monthlyBillItem",
    icon: "https://akim-oss.aikucun.com/mshop/d179a3480d6741a8a6b29ac49ced7baf69700b50_1712478545282_47.png",
    path: "/#/monthly-bill",
  };

  const appMarketItem = {
    title: "第三方应用市场",
    key: "appMarketItem",
    icon: "https://akim-oss.aikucun.com/mshop/14a4530a4bbc4f00e842f70212617e3e0c2f9d41_1716370113498_38.png",
    path: "/#/appMarket",
  };

  const waitCheckAfterOrdersItem = {
    title: "待审核售后单",
    key: "waitCheckAfterOrdersItem",
    icon: "https://akim-oss.aikucun.com/mshop/abb6ad73f916b2318161f04bb80de30db4bb3213_1710829217159_12.png",
    path: "/#/aftersale/serviceRecord",
  };

  const unbindDistributorItem = {
    title: "解绑店长身份",
    key: "unbindDistributorItem",
    icon: "https://akim-oss.aikucun.com/mshop/9ce0b46c2ba1a76913ef6414adc113a31d5143b1_1710828317129_11.png",
    path: "/#/distributor/unbinding",
  };

  const onProgressQueryClick = (obj) => {
    console.log("obj", obj);
    if (bizPopRef.current) {
      bizPopRef.current.showDistributorStimulateSwitchSecond(obj);
    }
  };

  useEffect(() => {
    getWeChatAccountInfoUrlByUserId().then(res => setIsFollow(!!res?.data?.isFollow));
    getAppMarketPermission().then(res => setShowAppMarket(!!res?.data));
  }, []);

  useActivate(() => {
    getWeChatAccountInfoUrlByUserId().then(res => setIsFollow(!!res?.data?.isFollow));
    getAppMarketPermission().then(res => setShowAppMarket(!!res?.data));
  });

  return (
    <ShopInfoProvider>
      <div className={styles.shopContainer}>
        <ShopHeader userInfo={userInfo} />
        <CardItem item={walletItem} userInfo={userInfo} />
        <ShopProtectAccount />
        {!isFollow && (
          <div className={styles.followWeChat}>
            <FollowWeChat />
          </div>
        )}
        <ShopCenterData />
        {/* 激励活动-卡片 */}
        <IncentiveProfileCard onProgressQueryClick={onProgressQueryClick} />
        <ShopOrderCard />
        <CardItem item={orderItem} />
        <ShopCustomerData />
        <ShopDispatchTask />
        <ShopServiceCard />
        <CardItem item={monthlyBillItem} />
        {showAppMarket && <CardItem item={appMarketItem} />}
        <ShopCustomerTrack />
        <ShopSchoolCard />
      </div>
      <BizPopup businessCode="myPage" ref={bizPopRef} />
    </ShopInfoProvider>
  );
};

export default ShopOwner;
