import { getBaseurl } from "@/utils/url-parse";
import { API } from "@akc/biz-api";
import { Request } from "./request";
//当前登录用户分享员状态
export function queryChannelSharerStatus(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/sharer/queryChannelSharerStatus`,
    method: "get",
    // headers: {
    //   'app-login-channel': 'xdApplets',
    // },
    data: params,
  });
}

//筛选数据
export function filterData(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/queryFilterBoardData`,
    method: "post",
    data: params,
  });
}
//查询商品
export function queryProductList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/list`,
    method: "post",
    data: params,
  });
}
