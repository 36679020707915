import BottomNotice from "@/components/bottom-notice";
import RedPacketNotice from "@/components/red-packet-notice";
import Track from "@/utils/track";
import React, { useEffect, } from "react";
import { useActivate } from "react-activation";
import Personal from "../personal/index";
import Admin from "./components/admin-index";
import ShopOwner from "./components/shop-index";
import { UserInfoProvider, UseUserInfoContext } from "./hooks/useUserInfo";
import styles from "./index.module.less";

const Container = () => {
  const { userInfo } = UseUserInfoContext();

  useEffect(() => {
    document.title = "我的";
  }, [userInfo]);

  return (
    <div className={styles.myPage} id="my-page">
      {userInfo?.currentRole === 4 && <Admin />}
      {userInfo?.currentRole === 3 && !userInfo?.distributorDisable && <ShopOwner />}
      {(userInfo?.currentRole === 1 ||
        (userInfo?.currentRole === 3 && !!userInfo?.distributorDisable)) && (
          <Personal />
        )}
      <RedPacketNotice />

      {/* 漂浮在底部的通告栏 */}
      <BottomNotice />
    </div>
  );
};

const My: React.FC = () => {
  const track = Track();
  useEffect(() => {
    document.title = "我的";
    track.track("$page_view", {
      page_name: "我的",
      domain: "我的",
    });
  }, []);
  useActivate(() => {
    document.title = "我的";
  });
  return (
    <UserInfoProvider>
      <Container />
    </UserInfoProvider>
  );
};

export default My;
