import { getCouponCount } from "@/api/coupon/index";
import Clickable from "@/components/clickable";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import styles from "./index.module.less";

type ItemType = {
  title: string;
  key: string;
  icon: string;
  path?: string;
  show: boolean;
  [key: string]: any;
};

const serviceList: Array<ItemType> = [
  {
    title: "优惠券",
    key: "couponItem",
    icon: "https://akim-oss.aikucun.com/mshop/30f810e77bd109a7d8d2bea0177e17af727e9d58_1711629012510_50.png",
    path: "/#/coupon",
    show: true,
  },
  {
    title: "浏览足迹",
    key: "browseHistoryItem",
    icon: "https://akim-oss.aikucun.com/mshop/11a5539d1471f5813380de8cf081ba12a671a486_1711706660797_75.png",
    path: "/mx-shop-micro/track",
    show: true,
  },
  {
    title: "日日奖",
    key: "dailyReward",
    icon: "https://akim-oss.aikucun.com/mshop/30f810e77bd109a7d8d2bea0177e17af727e9d58_1724307169553_18.png",
    path: "/mx-shop-micro/daily-reward",
    show: true,
  },
];

const ServiceCard: React.FC = () => {
  const [couponCount, setCouponCount] = useState<number>(0);
  const { navigate2MyItem } = useNavigation();

  const onItemClick = (item: ItemType) => {
    if (item.path) {
      navigate2MyItem(item.path);
      return;
    }
  };

  useEffect(() => {
    getCouponCount().then(count => setCouponCount(Number(count)));
  }, []);

  useActivate(() => {
    getCouponCount().then(count => setCouponCount(Number(count)));
  });

  return (
    <div className={styles["service-card"]}>
      <div className={styles["service-card__title"]}>常用服务</div>
      <div className={styles["service-card__list"]}>
        {serviceList
          .filter(item => item.show)
          .map(item => (
            <Clickable
              key={item.key}
              className={styles["item"]}
              onClick={() => onItemClick(item)}
              trackData={{
                page_name: "我的",
                btn_name: item.title,
                resource_type: "常用服务",
              }}>
              <img src={item.icon} alt="" />
              <div className={styles["name"]}>
                {item.title}
                {item.key === "couponItem" &&
                  couponCount > 0 &&
                  `(${couponCount > 99 ? 99 : couponCount})`}
              </div>
            </Clickable>
          ))}
      </div>
    </div>
  );
};

export default ServiceCard;
