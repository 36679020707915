import { queryFrontSetting } from "@/api/yearEndBonus";
import exposure from "@/utils/expose/exposure";
import { platform } from "@/utils/platform";
import Track from "@/utils/track";
import AKJS from "@akc/akjs";
import React, { useEffect, useState } from "react";
import styles from "./activity-banner.module.less";

interface Props {
  pageName?: string;
}
// 业绩提升小妙招
const ImprovePerformance: React.FC<Props> = ({ pageName = "" }) => {
  const { isApp } = platform;
  const track = Track();
  const enumList = ["转发红包", "抽奖赢豪礼", "省赚翻倍", "新品速递"];

  const [list, setList] = useState<any>([]);

  const toDetail = (item, index) => {
    if (!item?.linkUrl) {
      return;
    }
    track.track("resource_click", {
      page_name: pageName,
      domain: "年终奖",
      module: "业绩提升小妙招",
      resource_name: enumList[index],
      resource_rank: index,
      btn_name: enumList[index],
    });
    if (isApp) {
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.page.open", {
          url: item.linkUrl,
        });
      });
    }
  };

  const getFrontSetting = () => {
    queryFrontSetting().then(res => {
      setList(res?.annualBonus2024LinkConfigList || []);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        const dom = document.getElementById(`banner-${i}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 2000);
  }, [list]);

  useEffect(() => {
    getFrontSetting();
  }, []);

  return (
    <div className={styles.component}>
      {list?.length > 0 && (
        <>
          <h3 className={styles.component__tit}>业绩提升小妙招</h3>
          <div className={styles.component__con}>
            {list.map((item: any, index: number) => (
              <img
                id={`banner-${index}`}
                data-param={JSON.stringify({
                  page_name: pageName,
                  domain: "年终奖",
                  module: "业绩提升小妙招",
                  resource_name: enumList[index],
                })}
                className={styles.con__item}
                key={index}
                src={item.linkLogoUrl}
                onClick={() => toDetail(item, index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ImprovePerformance;
