import { ProductSnapshot } from "@/models/product";
import { Image, Popup } from "antd-mobile";
import React, { ReactNode } from "react";
import { default as ProductParamtersComp } from "../product-paramters";
import styles from "./index.module.less";
export enum SnapshotDialogType {
  //商品参数
  Product_Paramters = "Product_Paramters",
}
type Props = {
  //是否显示
  dialogVisible: boolean;
  //弹窗类型
  type?: SnapshotDialogType;
  //快照详情
  snapshotDetail?: ProductSnapshot;
  //关闭
  onClose?: (type?: SnapshotDialogType) => void;
};

/**
 * 底部弹窗
 */
const SnapshotBottomDialog: React.FC<Props> = props => {
  let note: ReactNode = <></>;
  let title = "";
  let showBottomBtn = false;
  switch (props.type) {
    case SnapshotDialogType.Product_Paramters:
      showBottomBtn = true;
      title = "产品参数";
      note = <ProductParamtersComp snapshotDetail={props.snapshotDetail} />;
      break;
  }
  return (
    <Popup
      visible={props.dialogVisible}
      onMaskClick={() => {
        props.onClose?.(props.type);
      }}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        zIndex: 3000,
      }}>
      <div className={`${styles["container"]}`}>
        <div className={styles.top}>
          <div className={styles.title}>{title}</div>
          <Image
            className={styles.close}
            src="https://devdevnew.obs.cn-east-3.myhuaweicloud.com/taro/resources/close_black.png"
            onClick={() => {
              props.onClose?.(props.type);
            }}
          />
        </div>
        <div className={`${styles["body"]} ${showBottomBtn ? styles["bodyB"] : styles[""]}`}>
          {note}
        </div>
        {showBottomBtn && (
          <div className={styles.bottomC}>
            <div
              className={styles.confirmBtn}
              onClick={() => {
                props.onClose?.(props.type);
              }}>
              完成
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default SnapshotBottomDialog;
