import { isInGray } from "@/api";
import { queryDailyPrizeInfo } from "@/api/dailyReward";
import { useEffect, useRef, useState } from "react";

const useLottery = ({ refreshTimes }) => {
  const [activityInfo, setActivityInfo] = useState<any>({});
  const isGrayInit = useRef(false);
  // 抽奖是否灰度
  const [isGray, setIsGray] = useState(false);
  const getGray = async () => {
    if (!isGrayInit.current) {
      const res = await isInGray("B-POINT_LOTTERY_PANEL");
      const flag = !!res;
      isGrayInit.current = true;
      return flag;
    }

    return isGray;
  };
  const queryActivityInfo = async () => {
    const isGrayFlag = await getGray();
    const info = await queryDailyPrizeInfo({});
    setIsGray(isGrayFlag && !!info?.canUsePointsForLottery);
    setActivityInfo(info);
  };
  useEffect(() => {
    console.log("刷新数据", refreshTimes);
    queryActivityInfo();
  }, [refreshTimes]);
  return {
    activityInfo,
    isGray,
  };
};

export default useLottery;
