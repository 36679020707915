import { SourceScene } from "@/api/scode/scode_params";
import loadingPng from "@/base64";
import Bottom from "@/components/bottom";
import PosterSwipe from "@/components/poster-swiper";
import TopNavigation from "@/components/top-navigation";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { Image, InfiniteScroll } from "antd-mobile";
import React from "react";
import DrawResultPop from "./components/DrawResultPop";
import NormalCardComp from "./components/Normal";
import PhysicalCardCamp from "./components/PhysicalCard";
import RedPacketCardComp from "./components/RedPacket";
import useMyReward from "./hook/useMyReward";
import styles from "./reward.module.less";

const MyReward: React.FC = () => {
  const {
    canShare,
    tabList,
    selectTab,
    list,
    hasMore,
    loading,
    rightTitle,
    redPacket,
    currentRoleIsC,
    currentRoleIsAdmin,
    showPoster,
    sharePosterData,
    customEditData,
    drawResultVisible,
    drawResultInfo,
    curShareType,
    totalAmount,
    expirationIsComing,
    onCloseDrowResultPopAction,
    onProductRefreshAction,
    onChangeTabIndex,
    loadMore,
    emptyTitleDes,
    onUseAction,
    onRightAction,
    onForwardAction,
    onRedDetailAction,
    onClosePosterAction,
    onRedPacketUseAction,
    onRefreshTotalAmountAction,
    onClaimRewardAction,
    onOrderDetailAction,
    onShopDetailAction,
    onCouponToShopDetailAction,
    onRewardForwardAction,
  } = useMyReward();

  return (
    <div id="page" className={styles.container}>
      <div className={styles.header}>
        {(platform.isH5 || platform.isWxH5) && (
          <div>
            <TopNavigation
              hideSearchIcon={true}
              hideCartIcon={true}
              hideFavoriteIcon={true}
              showRightTitle={currentRoleIsC || currentRoleIsAdmin ? undefined : rightTitle}
              backgroundColor="#FFF"
              rightClick={onRightAction}
            />
          </div>
        )}
        {platform.isMini && !currentRoleIsC && (
          <div className={styles.heade_nav} onClick={onRightAction}>
            <div className={styles.nav_title}>{rightTitle}</div>
          </div>
        )}
        <ul
          className={
            platform.isApp ? styles.header_tabs : `${styles.header_tabs} ${styles.header_bg}`
          }>
          {tabList?.map((item, index) => {
            return (
              <li
                className={
                  item?.type === selectTab?.type
                    ? `${styles["tabs_item"]} ${styles["tabs_item--active"]}`
                    : `${styles["tabs_item"]}`
                }
                key={item.type}
                onClick={e => {
                  onChangeTabIndex(e, index);
                }}>
                {item?.name}
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className={
          platform.isH5 || platform.isWxH5 || (platform.isMini && !currentRoleIsC)
            ? `${styles.body} ${styles.body_t}`
            : styles.body
        }>
        {loading && list.length === 0 && (
          <div className={`${styles.loading} ${styles.loadingFixed}`}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )}
        <>
          {list?.length > 0 ? (
            <>
              <div className={styles.card}>
                {redPacket && selectTab?.type === "RED_PACKET" && (
                  <RedPacketCardComp
                    totalAmount={totalAmount}
                    redPacket={redPacket}
                    expirationIsComing={expirationIsComing}
                    onDetailPress={onRedDetailAction}
                    onUsePress={onRedPacketUseAction}
                    onRefreshPress={onRefreshTotalAmountAction}
                  />
                )}
                {selectTab?.type === "RED_PACKET" && (
                  <div className={styles.red_tips}>
                    {platform.isApp
                      ? "* 红包限饷店下单抵扣使用"
                      : "* 红包全场商品可用（虚拟和海淘商品除外）"}
                  </div>
                )}
                {selectTab?.type === "COUPON" && (
                  <div className={styles.coupon_tips}>
                    * 限指定商品下单使用（虚拟、组合、秒杀商品和部分特殊商品不可用）
                  </div>
                )}
                {list.map((item, index) =>
                  item?.awardType === "PRODUCT" ? (
                    <PhysicalCardCamp
                      reward={item}
                      key={index}
                      onClaimClick={onClaimRewardAction}
                      onOrderClick={onOrderDetailAction}
                      onShopDetailClick={onShopDetailAction}
                      onInviteClick={onRewardForwardAction}
                    />
                  ) : (
                    <NormalCardComp
                      reward={item}
                      key={index}
                      onUsePress={item => onUseAction(item, index)}
                      onCouponToShopDetailClick={onCouponToShopDetailAction}
                      onInviteClick={onRewardForwardAction}
                    />
                  ),
                )}
              </div>
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={20}>
                <Bottom
                  loading={loading}
                  hasMore={hasMore}
                  reachBottom={list && list?.length > 0 ? true : false}
                />
              </InfiniteScroll>
            </>
          ) : (
            !loading && (
              <div className={styles.empty}>
                <img
                  className={styles.empty_icon}
                  src="https://akim-oss.aikucun.com/mshop/c1038a4f63dcfb02d1b8416fe7beca0faeb70abc_1724034878247_29.png"
                />
                <div>{emptyTitleDes(selectTab)}</div>
              </div>
            )
          )}
        </>
      </div>

      {canShare && (
        <div className={styles.bottom}>
          <div className={styles.bottom_btn} onClick={() => onForwardAction()}>
            <div className={styles.btn_title}>邀请好友一起参与</div>
          </div>
        </div>
      )}

      {sharePosterData && (
        <PosterSwipe
          show={showPoster}
          customEditData={customEditData}
          posterData={sharePosterData}
          shareType={curShareType}
          btnConfig={["copy", "edit"]}
          close={onClosePosterAction}
        />
      )}
      <DrawResultPop
        visible={drawResultVisible}
        onClose={onCloseDrowResultPopAction}
        onOrderSuccess={onProductRefreshAction}
        result={drawResultInfo.current}
        handleShare={onForwardAction}
      />
    </div>
  );
};

export default MyReward;
