import { queryCardPop } from "@/api/market";
import useNavigation from "@/utils/useNavigate";
import { Mask, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface Props {
  // 场景码
  serverCode: string
}

const MarketingPopup: React.FC<Props> = ({ serverCode }) => {
  const [visible, setVisible] = useState(true);
  const [data, setData] = useState<any>({});

  const { navigate } = useNavigation();

  const onClose = () => {
    setVisible(false);
  };

  const query = async () => {
    const res = await queryCardPop({
      serverCode,
    });
    if (res?.data) {
      setData(res.data);
    }
    console.log(123, res);
  };

  const navigateTo = e => {
    e.stopPropagation();
    onClose();
    const { skipType } = data;
    switch (skipType) {
      case "DAILY_REWARD":
        navigate({
          h5Options: {
            url: "/mx-shop-micro/daily-reward",
          },
        });
        break;
      default:
        onClose();
    }
  };

  useEffect(() => {
    query();
  }, []);

  if (!data || data.skipType !== "DAILY_REWARD") {
    return <></>;
  }

  return (
    <Mask visible={visible} onMaskClick={() => onClose()} opacity={0.75}>
      <div className={styles.wrap} onClick={onClose}>
        <img src={data.popupUrl} className={styles.img} onClick={navigateTo} />
        <div className={styles.closeBtn}></div>
      </div>
    </Mask>
  );
};

export default MarketingPopup;
