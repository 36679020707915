import Clickable from "@/components/clickable";
import RoleSwitchPopup from "@/pages/personal/components/role-switch-popup";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseUserInfoContext } from "../../hooks/useUserInfo";
import styles from "./index.module.less";

const ShopHeader = props => {
  const { userInfo } = props;
  const track = Track();
  const { navigate2MyItem } = useNavigation();
  const { handleSwitchRole } = UseUserInfoContext();
  const navigater = useNavigate();
  const [roleSwitchPoupVisible, setRoleSwitchPoupVisible] = useState(false);
  const defaultAvatarUrl =
    "https://akim-oss.aikucun.com/mshop/068e94004fd177d0aa182bf7a7831fe672dcfdb8_1710238003899_26.png";

  const handleRoleSwitchConfirm = switchedRole => {
    setRoleSwitchPoupVisible(false);
    if (switchedRole === 3) {
      return;
    }
    handleSwitchRole(switchedRole);
  };

  const onCopy = e => {
    e.stopPropagation();
    copy(userInfo.distributorCode || userInfo.distributorId);
    Toast.show("复制成功");
    track.track("btn_click", {
      page_name: "我的",
      btn_name: "复制",
    });
  };

  const onEdit = e => {
    e.stopPropagation();
    navigate2MyItem("/#/memberInfo");
    track.track("btn_click", {
      page_name: "我的",
      btn_name: "编辑",
    });
  };

  const onSetting = e => {
    e.stopPropagation();
    navigater(`../mx-shop-micro/sub-setting${window.location.search}`);
  };

  useEffect(() => {
    console.log("useEffect");
  }, []);

  return (
    <div className={styles.shopHeader}>
      <div className={styles.userInfo}>
        <div className={styles.avatarWrap}>
          <img className={styles.avatar} src={userInfo?.headImgUrl || defaultAvatarUrl} alt="" />
          {userInfo.roleSwitchAvailable && (
            <Clickable
              className={styles.switch}
              onClick={() => setRoleSwitchPoupVisible(true)}
              trackData={{
                page_name: "我的",
                btn_name: "切换身份",
              }}>
              <i className={styles.iconSwitch} />
              <span>切换身份</span>
            </Clickable>
          )}
        </div>
        <div className={styles.flexColumn}>
          <div className={styles.nickName}>
            <p>{userInfo.nickname}</p>
            {userInfo.level === 1 && <i className={styles.iconLevelOne} />}
            {userInfo.level === 2 && <i className={styles.iconLevelTwo} />}
            <i className={styles.iconDistributor} />
            <i className={styles.iconEdit} onClick={onEdit} />
          </div>
          {(userInfo.distributorCode || userInfo.distributorId) && (
            <div className={styles.userId}>
              用户编号: {userInfo.distributorCode || userInfo.distributorId}
              <span className={styles.copyBtn} onClick={onCopy}>
                复制
              </span>
            </div>
          )}
        </div>
      </div>
      <Clickable
        className={styles.setting}
        onClick={onSetting}
        trackData={{
          page_name: "我的",
          btn_name: "设置",
        }}>
        <i className={styles.iconSetting} />
        设置
      </Clickable>
      {/* 切换身份弹出层 */}
      <RoleSwitchPopup
        userinfo={userInfo}
        visible={roleSwitchPoupVisible}
        setVisible={setRoleSwitchPoupVisible}
        currentRole={userInfo.currentRole}
        onConfirm={handleRoleSwitchConfirm}
      />
    </div>
  );
};

export default ShopHeader;
