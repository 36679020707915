import { isInGray } from "@/api";
import { queryJumpTypeServ } from "@/api/activity";
import { buyMore } from "@/api/add-to-cart";
import { queryAtmosphereData } from "@/api/newPerson";
import {
  fetchProductMaterialDetail,
  queryMaterialList,
  queryProductList,
  queryTopMaterialPage,
} from "@/api/product";
import { SharePosterParams } from "@/api/scode/poster";
import {
  carrierContextParams,
  CarrierElementType,
  ContentType,
  envContextParams,
  SourceScene,
} from "@/api/scode/scode_params";
import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import { getMaterialProductSwitch } from "@/api/share";
import { CustomEditData } from "@/components/poster-swiper";
import { ProductModel as PM2 } from "@/models/product";
import { sharePosterParams4H5Product } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { getCurPageClientHeight } from "@/utils/getStyle";
import { navigate } from "@/utils/navigate";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import { showSKUPreview } from "@/utils/select-color";
import { shareCardByH5 } from "@/utils/share/share";
import { getUrlParam, urlQueryObject } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import {
  CouponModel,
  PriceInfoModel,
  ProductDetailAtmosphereModel,
  ProductDetailModel,
  ProductMaterialModel,
  ProductModel,
} from "@akc/biz-api";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { useThrottleFn } from "ahooks";
import { MultiImageViewerRef, Toast } from "antd-mobile";
import React, { useContext, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MarketingServicesType } from "../../components/promotion-dialog";
import { useAddressList } from "../../hook/useAddressList";
import { useCouponList } from "../../hook/useCouponList";
import useRecommendProductList from "../../hook/useRecommendProductList";
import { MaterialListModel } from "../../model/MaterialListModel";
import { TopMaterialResponse } from "../../model/TopMaterialResponse";
import { TopTabModel } from "../../model/TopTabModel";
import { CSUtils } from "../../utils/CSUtils";
import { PDUtils } from "../../utils/PDUtils";
import { useTrack } from "./useTrack";

const pageName = "素材商品详情页";
const materialProductGuideKey = "materialProductGuide";
export const useProductDetailMaterial = () => {
  const {
    navigate2Activity,
    navigate2GoodsOfNpieceXfold,
    navigate2BusinessLicense,
    navigate2NxProductList,
  } = useNavigation();
  const commonContext = useCommonContext();
  const currentRoleIsC = commonContext.currentRole === "C";
  const [search] = useSearchParams();
  const pageCode = search.get("customPageCode");
  //素材id
  const materialNo = search.get("materialNo");
  //商品id
  const activitySpuId = search.get("activitySpuId");
  //活动id
  const [activityId, setActivityId] = React.useState<string | undefined | null>(
    search.get("activityId"),
  );
  //skuId
  const [selectSkuId, setSelectSkuId] = React.useState<string | undefined | null>(
    search.get("selectSkuId"),
  );
  const promoActivityId = search.get("promoActivityId");
  const awdInstanceId = search.get("awdInstanceId");

  //一级属性value
  const [firstSalePropertyValue, setFirstSalePropertyValue] = React.useState<
    string | undefined | null
  >(search.get("firstSalePropertyValue"));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  const headerDomRef = useRef<HTMLDivElement>(null);

  const productDomRef = useRef<HTMLDivElement>(null);

  const previewRef = useRef<MultiImageViewerRef>(null);

  // 商品详情
  const [productDetail, setProductDetail] = React.useState<ProductMaterialModel>();
  // 推荐素材
  const [topMaterialResponse, setTopMaterialResponse] = useState<TopMaterialResponse>();
  // 实拍专区
  const [materialListModel, setmaterialListModel] = useState<MaterialListModel>();
  // 氛围及购买记录
  const [atmosphereModel, setAtmosphereModel] = React.useState<ProductDetailAtmosphereModel>();
  // 商品
  const [productModel, setProductModel] = useState<ProductModel | undefined>();
  // 营销弹窗显示状态
  const [promotionDialogVisible, setPromotionDialogVisible] = useState<boolean>(false);
  // 营销弹窗类型
  const [promotionDialogType, setPromotionDialogType] = useState<MarketingServicesType>();
  // 当前价格
  const [currentPriceInfo, setCurrentPriceInfo] = useState<PriceInfoModel>();
  // 购物车数量
  const [cartTotal, setCartTotal] = useState<number>();
  //点击商品显示弹窗
  const [showSizePopup, setShowSizePopup] = React.useState(false);
  //商品sku弹窗所需模型
  const [sizePopupProduct, setSizePopupProduct] = React.useState<PM2>();
  //转发
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>();
  const [customEditData, setCustomEditData] = useState<CustomEditData>();
  const [showPoster, setShowPoster] = useState(false);
  const [isOTC, setIsOTC] = useState(false);
  const [cartModalVisible, setCartModalVisible] = useState<boolean>(false);
  const [modalProduct, setModalProduct] = useState<any>();
  //用药指导弹窗
  const [showMedicateDialog, setShowMedicateDialog] = useState<boolean>(false);
  //是否显示浮动菜单
  const [showFloatMenu, setShowFloatMenu] = useState<boolean>(false);
  //是否显示顶部tab
  const [showTopTabs, setShowTopTabs] = useState<boolean>(false);
  //顶部tab选中key
  const [activeKey, setActiveKey] = useState("1");
  //顶部tab数据集
  const [tabItems, setTabItems] = useState<TopTabModel[]>([]);
  //图片预览
  const [previewPicList, setPreviewPicList] = useState<string[]>([]);
  //图片预览是否显示
  const [showPicPreview, setShowPicPreview] = useState<boolean>(false);
  //图片预览默认 index
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  //加购类型
  const [cartPannelButtonType, setCartPannelButtonType] = useState<number>();
  //loading
  const [loading, setLoading] = useState<boolean>(false);
  //是否显示新版加购面板
  const [showNewCartPanel, setShowNewCartPanel] = useState<boolean>(false);
  //是否显示引导弹窗
  const [showGuideDialog, setShowGuideDialog] = useState<boolean>(false);

  const getProductDetailModel = () => {
    return productDetail?.productDetailVO;
  };

  //商详模型
  const productDetailModel = getProductDetailModel();

  const { trackPageView, trakcMaterialCopy, trackBusinessLicense } = useTrack(pageName);

  //地址
  const {
    addressList,
    selectAddressId,
    updateSelectAddress,
    fetchAddressList,
    isLoadAddress,
    currentAddressId,
  } = useAddressList();

  //优惠券
  const { couponList, receiveCoupon } = useCouponList(productDetail?.productDetailVO);

  //推荐商品列表
  const { hasNext, recommendProductList, onLoadNextPage } = useRecommendProductList({
    product: productDetailModel,
    scene: "product_detail_v2",
  });

  //获取加购面板灰度
  const getCartPanelGray = async () => {
    const res = await isInGray("B-H5_REACT_ADD_PANEL");
    const newCartPanel = !!res;
    setShowNewCartPanel(newCartPanel);
  };

  //滚动监听
  const { run: handleScroll } = useThrottleFn(
    () => {
      const scrollTop =
        window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

      console.log("scrollTop=", scrollTop);
      setShowFloatMenu(scrollTop > getCurPageClientHeight() / 2);
      setShowTopTabs(scrollTop > 44);

      let currentKey = tabItems?.[0]?.key;
      for (const item of tabItems) {
        const element = document.getElementById(`anchor-${item.key}`);
        if (!element) {
          continue;
        }
        const rect = element.getBoundingClientRect();
        if (rect.top <= 45) {
          currentKey = item.key;
        } else {
          break;
        }
      }
      setActiveKey(currentKey);
    },
    {
      leading: true,
      trailing: true,
      wait: 100,
    },
  );

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  //获取购物车数量
  const changeCartNum = () => {
    commonContext.setCartNum().then(num => {
      console.log("购物车数量", num);
      setCartTotal(num);
    });
  };

  /**
   * 组装顶部tab数据集
   */
  const handlerTopTabs = topMaterial => {
    let tabs: TopTabModel[] = [];
    tabs.push({ key: "1", title: "详情" });
    if (
      (productDetailModel?.productSizeTableSimpleInfo?.attrValueRes?.length ?? 0) > 0 ||
      (productDetailModel?.spuInfoVO?.sizeUrl?.length ?? 0) > 0
    ) {
      tabs.push({ key: "2", title: "尺码" });
    }
    if (
      (topMaterial?.materialPictureVOList?.length ?? 0) > 0 ||
      (topMaterial?.result?.length ?? 0) > 0
    ) {
      tabs.push({ key: "3", title: currentRoleIsC ? "实拍" : "素材" });
    }
    tabs.push({ key: "4", title: "商品" });
    setTabItems(tabs);
  };

  //右上角三个点分享
  const getProductSysTemShareInfo = async (pdModel?: ProductDetailModel) => {
    let params = {
      bizInfo: {
        contentType: ContentType.spuProduct,
        contentId: pdModel?.activitySpuId,
        contentTypeConfig: 1,
        selectSkuId: pdModel?.selectSkuId || "",
        contentDetailId: pdModel?.selectSkuId || "",
      },
      envContext: envContextParams({
        sourceScene: SourceScene.productMaterial,
      }),
      contentContext: {
        contentType: ContentType.spuProduct,
        contentId: pdModel?.activitySpuId,
      },
      carrierContext: carrierContextParams([CarrierElementType.h5Link]),
      withForwarder: 1,
      withPreview: 0,
    };
    let res = await fetchShareCardInfo(params);

    const { urlShareImg, urlShareTitle, urlShareDesc, urlShareUrl, scode } = res?.data || {};
    if (!urlShareImg || !urlShareTitle || !urlShareDesc || !urlShareUrl) {
      return;
    }
    let confirmParam = {
      requestId: String(new Date().getTime()),
      scene: (res?.data && res?.data?.scode) || "",
      extraMap: {
        contentId: pdModel?.activitySpuId ?? "",
        contentType: "6",
        activity_spu_code: pdModel?.activitySpuId ?? "",
        sku_id: pdModel?.selectSkuId ?? "",
      },
      hideMenus: pdModel?.skuExtendInfoVO?.isOTC ? ["menuItem:share:timeline"] : [],
    };
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        link: urlShareUrl,
        imgUrl: urlShareImg,
        scode,
      },
      confirmParam,
    );
  };

  //页面初始化请求
  const initPageRequest = (params?: { selectSkuId?: string; firstSalePropertyValue?: string }) => {
    if (params?.firstSalePropertyValue) {
      setLoading(true);
    }

    // 请求商品详情数据
    fetchProductMaterialDetail({
      activityId: activityId ?? "",
      activitySpuId: activitySpuId ?? "",
      pageId: "H5_ProductDetail",
      selectSkuId: params?.selectSkuId ?? selectSkuId,
      materialNo: materialNo,
      firstSalePropertyValue: params?.firstSalePropertyValue ?? firstSalePropertyValue,
      addressId: currentAddressId.current,
      promoActivityIds: promoActivityId ? [promoActivityId] : undefined,
      sourceScene: promoActivityId ? "JOIN_ORDER_PAGE" : undefined,
      awdInstanceId: awdInstanceId,
    })
      .then(res => {
        setLoading(false);
        setSelectSkuId(res?.productDetailVO?.selectSkuId);
        setFirstSalePropertyValue(res?.productDetailVO?.skuBaseInfoVO?.firstSalePropertyValue);
        setActivityId(res?.productDetailVO?.activityId);
        setProductDetail(res);
        setCurrentPriceInfo(
          res?.productDetailVO?.skuAdvancePriceInfo ?? res?.productDetailVO?.skuCurrentPriceInfo,
        );
        getProductSysTemShareInfo(res?.productDetailVO);
        setTrackData(data => ({
          ...data,
          page_name: pageName,
          previous_page_name: search.get("page_name") ?? search.get("previous_page_name"),
          activity_id: activityId ?? res?.productDetailVO?.activityId,
          activity_spu_code: activitySpuId ?? res?.productDetailVO?.activitySpuId,
          module: pageName,
          sku_id: res?.productDetailVO?.selectSkuId ?? selectSkuId,
          merchant_shop_code: res?.productDetailVO?.activitySimpleVO?.merchantShopCode,
          product_model: res?.productDetailVO?.activityType === 4 ? "普通商品" : "档期商品",
          material_id: res?.materialNo,
          brand_id: res?.productDetailVO?.brandInfo?.brandId,
          price: res?.productDetailVO?.skuCurrentPriceInfo?.marketingPrice,
          product_name: res?.productDetailVO?.skuBaseInfoVO?.title,
        }));

        trackPageView({
          previousPageName: search.get("page_name") ?? search.get("previous_page_name"),
          materialId: res?.materialNo,
          activitySpuId: activitySpuId ?? res?.productDetailVO?.activitySpuId,
          activityId: activityId ?? res?.productDetailVO?.activityId,
        });
      })
      .catch(e => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    document.title = "商品详情";
    const fetchData = async () => {
      await fetchAddressList();
      initPageRequest();
    };
    getCartPanelGray();
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!productDetail) {
        return;
      }

      const activitySpuId = productDetailModel?.activitySpuId;
      let materialPromise;

      if (currentRoleIsC) {
        //实拍专区
        materialPromise = queryMaterialList({
          pageId: "H5_MaterialList",
          objectIds: activitySpuId ? [activitySpuId] : [],
          baseProductIds: productDetail?.productDetailVO?.spuInfoVO?.spuId
            ? [productDetail?.productDetailVO?.spuInfoVO?.spuId]
            : [],
        });
      } else {
        //精选素材
        materialPromise = queryTopMaterialPage({
          pageId: "H5_MaterialList",
          voConfig: { queryCollectionFlag: true },
          objectIds: activitySpuId ? [activitySpuId] : [],
          baseProductIds: productDetail?.productDetailVO?.spuInfoVO?.spuId
            ? [productDetail?.productDetailVO?.spuInfoVO?.spuId]
            : [],
        });
      }

      //商品列表
      const productPromise = queryProductList(
        "H5_ProductDetail",
        {
          scene: "MATERIAL_PRODUCT_DETAIL",
        },
        undefined,
        [
          {
            activitySpuId: productDetail?.productDetailVO?.activitySpuId,
            selectSkuId: productDetail?.productDetailVO?.selectSkuId,
            activityId: productDetail?.productDetailVO?.activityId,
          },
        ],
      );

      const materialProductSwitchPromise = getMaterialProductSwitch();

      // 请求购物车数量
      changeCartNum();

      // 请求气氛数据
      queryAtmosphereData({
        activitySpuId: productDetailModel?.activitySpuId,
      }).then(res => {
        setAtmosphereModel(res);
      });

      const [topMaterial, productRes, materialProductSwitch] = await Promise.all([
        materialPromise,
        productPromise,
        materialProductSwitchPromise,
      ]);
      setLoading(false);
      currentRoleIsC ? setmaterialListModel(topMaterial) : setTopMaterialResponse(topMaterial);
      setProductModel(productRes?.result?.[0]);
      handlerTopTabs(topMaterial);
      if (
        localStorage.getItem(materialProductGuideKey) !== "1" &&
        materialProductSwitch?.showFlag
      ) {
        setShowGuideDialog(true);
      }
    };
    fetchData();
  }, [productDetail]);

  //显示营销标签弹窗
  const showPromotionDialog = (type: MarketingServicesType) => {
    setPromotionDialogType(type);
    setPromotionDialogVisible(true);
  };

  //关闭营销标签弹窗
  const diamissPromotionDialog = () => {
    setPromotionDialogVisible(false);
  };

  //跳转商品尺码详情页面
  const goToProductFitSize = () => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/productFitSize?fitSizeCode=${productDetailModel?.productSizeTableSimpleInfo?.fitSizeCode}`,
      },
    });
  };

  //一行一商品卡片转发
  const forwardProductCard11 = (item: any) => {
    setSharePosterData(
      sharePosterParams4H5Product({
        pageCode: pageCode as string,
        sourceScene: SourceScene.productMaterial,
        activitySpuId: item.activitySpuId,
        selectSkuId: item.selectSkuId,
      }),
    );
    setCustomEditData({
      activitySpuId: item.activitySpuId,
      activityId: item.activityId,
      selectSkuId: item.selectSkuId,
    });
    setIsOTC(item?.skuExtendInfoVO?.isOTC ?? false);
    setShowPoster(true);
  };

  //显示商品sku预览弹窗
  const showSkuPreview = product => {
    const productInfo: PM2 = {
      salePropertyFirstList: product.salePropertyFirstList,
      spuInfoVO: {
        sellOut: product.spuSellOut,
      },
      spuId: product.spuId,
      activityId: product.activityId,
      activitySpuId: product.activitySpuId,
      selectSkuId: product.selectSkuId,
    };
    showSKUPreview(productInfo).then(result => {
      if (result) {
        return;
      }
      setSizePopupProduct?.(productInfo);
      setShowSizePopup(true);
    });
  };

  // 还想买
  const stillBuy = () => {
    buyMore({
      activityId: productDetailModel?.activityId,
      activitySpuId: productDetailModel?.activitySpuId,
    }).then(res => {
      if (res) {
        Toast.show("你的购买意愿已通知商家");
      }
    });
  };

  //转发赚钱(详情转发)
  const onForwardAction = () => {
    if (!productDetailModel?.activitySpuId) {
      return;
    }
    setSharePosterData(
      sharePosterParams4H5Product({
        pageCode: pageCode as string,
        sourceScene: SourceScene.productMaterial,
        activitySpuId: productDetailModel?.activitySpuId,
        selectSkuId: productDetailModel?.selectSkuId,
      }),
    );
    setCustomEditData({
      activitySpuId: productDetailModel?.activitySpuId,
      activityId: productDetailModel?.activityId,
      selectSkuId: productDetailModel?.selectSkuId,
    });
    setIsOTC(productDetailModel?.skuExtendInfoVO?.isOTC ?? false);
    setShowPoster(true);
  };

  //立即购买
  const onBuyAction = () => {
    if (
      productDetailModel?.skuCurrentPriceInfo?.promotionTimeStatus === 1 &&
      !productDetailModel?.skuAdvancePriceInfo
    ) {
      Toast.show("该场活动还未开始，暂不支持购买");
      return;
    }
    if (!productDetailModel?.skuExtendInfoVO?.allowDeliver) {
      Toast.show("此商品当前收货地址无法配送，请更换收货地址再进行购买");
      return;
    }
    if (!productDetailModel?.activityId || !productDetailModel?.activitySpuId) {
      console.log("活动id和商品id不能为空");
      return;
    }
    setCartModalVisible(true);
    setCartPannelButtonType(3);
    setModalProduct(productDetailModel);
  };

  //加入购物车
  const onAddCartAction = () => {
    //该商品不允许发货
    if (!productDetail?.productDetailVO?.skuExtendInfoVO?.allowDeliver) {
      Toast.show("此商品当前收货地址无法配送，请更换收货地址再进行购买");
      return;
    }
    // 不允许加购
    if (!productDetailModel?.skuExtendInfoVO?.buyShoppingCartModel) {
      return;
    }
    if (productDetailModel?.skuExtendInfoVO?.isVirtual) {
      Toast.show("亲~该场活动还未开始，暂不支持购买");
      return;
    }
    if (!productDetailModel?.activityId || !productDetailModel?.activitySpuId) {
      return;
    }

    setCartModalVisible(true);
    setCartPannelButtonType(2);
    setModalProduct(productDetailModel);
  };

  //客服
  const onServiceAction = () => {
    const merchantShopCode = productDetailModel?.activitySimpleVO?.merchantShopCode;
    const activitySpuId = productDetailModel?.activitySpuId;
    const activityNo = productDetailModel?.activityId;
    const externalCode = productDetailModel?.activityId;
    const shopId = search.get("shopId") ?? "";
    const distributorId = search.get("distributorId") ?? "";
    const sourceQuery = search.get("sourceQuery");
    CSUtils.checkLocationType(
      2,
      { merchantShopCode, activitySpuId, activityNo, externalCode },
      "",
      shopId,
      distributorId,
      sourceQuery,
    );
  };

  //店铺
  const onB2RShopAction = () => {
    let merchantShopCode = productDetailModel?.activitySimpleVO?.merchantShopCode;
    const originParams = location.href.split("?")[1];
    let mxShopPath = `/mx-shop-micro/decorate?merchantShopCode=${merchantShopCode}&productType=1&${originParams}`;
    const { skuCurrentPriceInfo, skuAdvancePriceInfo } = productDetailModel || {};
    if (skuCurrentPriceInfo?.promotionType) {
      mxShopPath += `&currentPromotionType=${skuCurrentPriceInfo.promotionType}`;
    }
    if (skuAdvancePriceInfo?.promotionType) {
      mxShopPath += `&advancePromotionType=${skuAdvancePriceInfo.promotionType}`;
    }
    navigate({
      h5Options: {
        url: mxShopPath,
      },
    });
  };

  //去使用优惠券
  const onUseCoupon = (coupon?: CouponModel) => {
    const { isOldCoupon, couponId, voucherNo } =
      coupon ?? productDetailModel?.skuExtendInfoVO?.newcomerCouponVO ?? {};
    const params: { couponId?: string; voucherNo?: string } = {};
    if (isOldCoupon) {
      params.couponId = couponId;
    } else {
      params.voucherNo = voucherNo;
    }

    queryJumpTypeServ(params).then(res => {
      const { success, data, message } = res || {};
      const { couponId: _couponId, voucherNo: _voucherNo, activityId } = data || {};
      const originParams = location.href.split("?")[1];
      const mxShopPath = `/mx-shop-micro/joint-bill?couponId=${_couponId}&voucherNo=${_voucherNo}&${originParams}`;
      if (success) {
        switch (data.jumpType) {
          case "productDetail":
            onBuyAction();
            break;
          case "productList":
            navigate2GoodsOfNpieceXfold({
              h5Query: {
                couponId: data.couponId,
                voucherNo: data.voucherNo,
                awdInstanceId: data.awdInstanceId,
              },
            });
            break;
          case "activityDetail":
            navigate2Activity({
              activityNo: activityId ?? "",
            });
            break;
          case "activityList":
            navigate({
              h5Options: {
                url: mxShopPath,
              },
            });
            break;
          default:
            break;
        }
      } else {
        Toast.show(message || "服务异常，请稍后重试！");
      }
    });
  };

  const goPromoActivity = item => {
    if (item.skipUrl && item.drillDown) {
      const { shopId, distributorId } = urlQueryObject();
      let skipUrl = item.skipUrl;
      if (skipUrl.indexOf("/#/goodsOfNpieceXfold?") > 0) {
        const promoActivityId = getUrlParam("promoActivityId", skipUrl);
        navigate2NxProductList(promoActivityId);
      } else {
        const mShopId = getUrlParam("shopId", skipUrl);
        const mDistributorId = getUrlParam("distributorId", skipUrl);
        skipUrl = PDUtils.updateUrlParameter(skipUrl, "shopId", mShopId ?? shopId);
        skipUrl = PDUtils.updateUrlParameter(
          skipUrl,
          "distributorId",
          mDistributorId ?? distributorId,
        );
        window.location.href = skipUrl;
      }
    }
    diamissPromotionDialog();
  };

  //素材复制按钮
  const onMaterialCopyClick = () => {
    trakcMaterialCopy({
      previousPageName: search.get("page_name") ?? search.get("previous_page_name"),
      materialId: productDetail?.materialNo,
      activitySpuId: activitySpuId ?? productDetailModel?.activitySpuId,
    });
  };

  //营业执照
  const onBusinessLicenseClick = () => {
    const actId = (
      productDetailModel?.skuAdvancePriceInfo ?? productDetailModel?.skuCurrentPriceInfo
    )?.activityId;
    if (actId) {
      navigate2BusinessLicense(actId);
    }
    trackBusinessLicense({
      previousPageName: search.get("page_name") ?? search.get("previous_page_name"),
      materialId: productDetail?.materialNo,
      activitySpuId: activitySpuId ?? productDetailModel?.activitySpuId,
    });
  };

  //保存引导状态
  const saveGuideState = () => {
    localStorage.setItem(materialProductGuideKey, "1");
  };

  //地址切换
  const onAddressSwitch = (addressId?: string, index?: number) => {
    currentAddressId.current = addressId;
    initPageRequest();
    updateSelectAddress(addressId, index);
  };

  return {
    atmosphereModel,
    promotionDialogVisible,
    promotionDialogType,
    diamissPromotionDialog,
    showPromotionDialog,
    productModel,
    productDetail,
    productDetailModel,
    topMaterialResponse,
    currentPriceInfo,
    materialListModel,
    currentRoleIsC,
    cartTotal,
    goToProductFitSize,
    showSizePopup,
    setShowSizePopup,
    sizePopupProduct,
    setSizePopupProduct,
    forwardProductCard11,
    sharePosterData,
    customEditData,
    showPoster,
    isOTC,
    setShowPoster,
    cartModalVisible,
    modalProduct,
    changeCartNum,
    setCartModalVisible,
    stillBuy,
    firstSalePropertyValue,
    onForwardAction,
    onBuyAction,
    onAddCartAction,
    showSkuPreview,
    onServiceAction,
    showMedicateDialog,
    setShowMedicateDialog,
    headerDomRef,
    addressList,
    selectAddressId,
    initPageRequest,
    setFirstSalePropertyValue,
    onUseCoupon,
    showFloatMenu,
    productDomRef,
    showTopTabs,
    activeKey,
    tabItems,
    onB2RShopAction,
    goPromoActivity,
    couponList,
    receiveCoupon,
    selectSkuId,
    previewPicList,
    setPreviewPicList,
    showPicPreview,
    setShowPicPreview,
    previewIndex,
    setPreviewIndex,
    previewRef,
    cartPannelButtonType,
    setCartPannelButtonType,
    loading,
    hasNext,
    recommendProductList,
    onLoadNextPage,
    onMaterialCopyClick,
    onBusinessLicenseClick,
    showNewCartPanel,
    showGuideDialog,
    setShowGuideDialog,
    saveGuideState,
    onAddressSwitch,
  };
};
