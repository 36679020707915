import { CommentItem, getCommentList } from "@/api/product";
import TopNavigation from "@/components/top-navigation";
import { Card, Image, InfiniteScroll, Rate, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface Props {
  productId?: string;
}

const splitShapes = (shapes: string) => {
  return shapes.split(",");
};

const CommentList: React.FC<Props> = () => {
  const [search] = useSearchParams();
  const productId = search.get("productId") || "";
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [commentList, setCommentList] = useState<CommentItem[]>([]);

  const loadMore = async () => {
    if (loading) {return;}
    setLoading(true);
    try {
      const { result = [], pages = 0 } = await getCommentList({
        productId: productId,
        pageIndex: pageIndex + 1,
        pageSize: 10
      }) || {};
      setCommentList(prev => [...prev, ...result]);
      setPageIndex(prev => prev + 1);

      // 判断是否还有更多数据
      setHasMore(pageIndex + 1 < pages);
    } catch (err) {
      Toast.show({
        content: "暂无数据, 请稍后再试哦！",
        position: "bottom"
      });
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className={styles.commentListContainer}>
      <TopNavigation
        hideSearchIcon={true}
        hideCartIcon={false}
        hideFavoriteIcon={true}
        backgroundColor="#fff"
      />
      {commentList.length > 0 && !error ? (
        <>
          {commentList.map((item, index) => (
            <React.Fragment key={item.id}>
              <Card className={styles.commentCard}>
                <div className={styles.commentHeader}>
                  <div className={styles.commentHeaderLeft}>
                    <div className={styles.commentHeaderAvatar}>
                      <Image src={item.userHeadImgUrl} alt="" fit='cover' />
                    </div>
                    <div className={styles.commentHeaderDesc}>
                      <div className={styles.commentName}>
                        {item.userName}
                      </div>
                      <div className={styles.commentValue}>
                        <Rate
                          readOnly
                          value={item.score}
                          style={{ "--star-size": "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.commentHeaderRight}>
                    {item.commentTime}
                  </div>
                </div>
                <div className={styles.productShape}>
                  {splitShapes(item.productSpecs || "").map((ele, index) => (
                    <div key={index} className={styles.shapeItem}>
                      {ele}
                    </div>
                  ))}
                </div>
                <div className={styles.commentTxt}>
                  {item.content}
                </div>
              </Card>
            </React.Fragment>
          ))}
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            threshold={250}
          >
            {hasMore ? (
              <div className={styles.loadingText}>加载中...</div>
            ) : (
              <div className={styles.noMoreText}>没有更多了</div>
            )}
          </InfiniteScroll>
        </>
      ) : (
        <div className={styles.nomoreContainer}>
          <div>
            <img src='https://akim-oss.aikucun.com/27eb0c6ff79db3c21d671e006e26e3ce031d3751_1736577935723_41.png' alt="" />
          </div>
          <div>暂无评价</div>
        </div>
      )}
    </div>
  );
};

export default CommentList;