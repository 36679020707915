import { getCookie, platform } from "@/utils/platform";
import Track from "@/utils/track";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "../index.module.less";
import { ReactComponent as SearchIcon } from "./SearchIcon.svg";

const RewardSearchHeader: React.FC = () => {
  const track = Track();
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const goSearch = () => {
    track.track("resource_click", {
      page_name: "奖励中心",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      resource_type: "搜索框",
      resource_name: "品牌搜索",
      btn_name: "点击搜索",
    });
    let envid: any = location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    let href = `/mx-shop-micro/reward-search?token=${search.get("token")}&shopId=${search.get("shopId")}&channel=h5Mall&page_name=奖励中心&env_identity=${envid || getCookie("X-Hades-Env-Identity")}`;
    if (search.get("distributorId")) {
      href += `&distributorId=${search.get("distributorId")}`;
    }
    navigater(href);
  };
  return (
    <div className={`${styles.header} ${(platform.isH5 || platform.isWxH5) && styles.header_h5}`}>
      {
        (platform.isH5 || platform.isWxH5) ? <img className={styles.header__search_icon} onClick={goSearch} src="https://akim-oss.aikucun.com/mshop/cd4a53a42d33a8f7e93178bcbefab1d22e7fd4ac_1736835051071_49.png" /> : (
          <div className={styles.search} onClick={goSearch}>
            <SearchIcon className={styles.icon} />
            <div className={styles.text}>请输入活动名称或品牌名称</div>
          </div>
        )
      }
    </div>
  );
};
export default RewardSearchHeader;
