import { getCouponCount } from "@/api/coupon/index";
import { getShareShop, getShowChannelSharer } from "@/api/my/shop";
import Clickable from "@/components/clickable";
import PosterSwipe from "@/components/poster-swiper";
import { sharePosterParams4H5Shop } from "@/utils/actions";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

type ItemType = {
  title: string;
  key: string;
  icon: string;
  path?: string;
  show: boolean;
  [key: string]: any;
};

const serviceList: Array<ItemType> = [
  {
    title: "日日奖",
    key: "dailyReward",
    icon: "https://akim-oss.aikucun.com/mshop/30f810e77bd109a7d8d2bea0177e17af727e9d58_1724307169553_18.png",
    path: "/mx-shop-micro/daily-reward",
    show: true,
  },
  {
    title: "转发拆红包",
    key: "redPacketItem",
    icon: "https://akim-oss.aikucun.com/mshop/d8f1b8a8d71ad2dda589502101c542c27dcd2e34_1711628627445_5.png",
    path: "/#/open-envelopes",
    show: true,
  },
  {
    title: "阅己图书",
    key: "bookItem",
    icon: "https://akim-oss.aikucun.com/mshop/07d27cec0a895eb0015679afedb4664469a373e2_1711628957003_12.png",
    path: "/#/book/home",
    show: true,
  },
  {
    title: "积分中心",
    key: "pointCenterItem",
    icon: "https://akim-oss.aikucun.com/mshop/67d72b8c6698c9e2beb105def907e08baf3c7b7e_1711628982960_66.png",
    path: "/#/beGoldCoinCenter",
    show: true,
  },
  {
    title: "分享员",
    key: "videoShareItem",
    icon: "https://akim-oss.aikucun.com/mshop/fda9607da326ca7be855d8f3c4e3db0753981012_1711629044297_62.png",
    show: false,
  },
  {
    title: "优惠券",
    key: "couponItem",
    icon: "https://akim-oss.aikucun.com/mshop/30f810e77bd109a7d8d2bea0177e17af727e9d58_1711629012510_50.png",
    path: "/#/coupon",
    show: true,
  },
  {
    title: "一键转链",
    key: "transferLinkItem",
    icon: "https://akim-oss.aikucun.com/mshop/36d15463d0a0c5ee7ccc2bbe8fe457ad7c0ae434_1711634003267_31.png",
    path: "/#/tools/transfer-link",
    show: true,
  },
  {
    title: "分享店铺",
    key: "shopPosterItem",
    icon: "https://akim-oss.aikucun.com/mshop/a0884fa441740768d87102f55f1660dc29653989_1711629099570_57.png",
    show: true,
  },
  {
    title: "品牌关注",
    key: "brandFollowItem",
    icon: "https://akim-oss.aikucun.com/mshop/3f37b18fddf9114f0f892b8edd8a1fcf5699a3d5_1711629123563_97.png",
    path: "/#/my-focus",
    show: true,
  },
  {
    title: "浏览足迹",
    key: "browseHistoryItem",
    icon: "https://akim-oss.aikucun.com/mshop/11a5539d1471f5813380de8cf081ba12a671a486_1711706660797_75.png",
    path: "/mx-shop-micro/track",
    show: true,
  },
];

const ShopServiceCard: React.FC = () => {
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId") ?? "";
  const [shopServiceList, setShopServiceList] = useState<ItemType[]>(serviceList);
  const [couponCount, setCouponCount] = useState<number>(0);
  const [accountId, setAccountId] = useState<string>("");
  const [showPoster, setShowPoster] = useState(false);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  const { navigate2MyItem } = useNavigation();
  const navigater = useNavigate();

  const queryShowChannelSharer = async () => {
    const data = await getShowChannelSharer();
    const { entryFlag = false, accountId = "" } = data || {};
    setAccountId(accountId);
    if (entryFlag) {
      const list = serviceList.map(item => {
        if (item.key === "videoShareItem") {
          return {
            ...item,
            show: true,
          };
        }
        return item;
      });
      setShopServiceList(list);
    }
  };

  const onShowShopPoster = async () => {
    const data: any = await getShareShop({ shopId, shopBizCode: shopId });
    if (data?.mainPic) {
      setSharePosterData(
        await sharePosterParams4H5Shop({
          shopId: shopId,
          mainPic: data.mainPic,
        }),
      );
      setShowPoster(true);
    }
  };

  const onClosePoster = () => {
    setSharePosterData({} as any);
    setShowPoster(false);
    document.body.style.overflow = "unset";
  };

  const onItemClick = (item: ItemType) => {
    if (item.path) {
      navigate2MyItem(item.path);
      return;
    }
    if (item.key === "videoShareItem") {
      const query = location.href.split("?")[1];
      navigater(`/mx-shop-micro/video-share?${query}&accountId=${accountId}`);
      return;
    }
    if (item.key === "shopPosterItem") {
      onShowShopPoster();
      return;
    }
  };

  useEffect(() => {
    queryShowChannelSharer();
    getCouponCount().then(count => setCouponCount(Number(count)));
  }, []);

  useEffect(() => {
    const page = document.getElementById("my-page");
    if (showPoster && page) {
      page.style.overflowY = "hidden";
    }
    if (!showPoster && page) {
      page.style.overflowY = "scroll";
    }
  }, [showPoster]);

  useActivate(() => {
    queryShowChannelSharer();
    getCouponCount().then(count => setCouponCount(Number(count)));
  });

  return (
    <>
      <div className={styles.shopServiceCard}>
        <div className={styles.serviceTitle}>常用服务</div>
        <div className={styles.serviceList}>
          {shopServiceList
            .filter(item => item.show)
            .map(item => (
              <Clickable
                key={item.key}
                className={styles.serviceItem}
                onClick={() => onItemClick(item)}
                trackData={{
                  page_name: "我的",
                  btn_name: item.title,
                  resource_type: "常用服务",
                }}>
                <img src={item.icon} alt="" />
                <div className={styles.serviceName}>
                  {item.title}
                  {item.key === "couponItem" &&
                    couponCount > 0 &&
                    `(${couponCount > 99 ? 99 : couponCount})`}
                </div>
              </Clickable>
            ))}
        </div>
      </div>
      {/* 店铺海报 */}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        shareType="H5_SHOP"
        close={onClosePoster}
      />
    </>
  );
};

export default ShopServiceCard;
