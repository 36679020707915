import useList from "@/pages/userTrack/hooks/useTrackList";
import { trackResourceClick } from "@/pages/userTrack/utils/track";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { ProductCard1NComp } from "@akc/mx-taro-h5-components";
import moment from "moment";
import React, { useEffect } from "react";
import Empty from "../empty";
import styles from "./index.module.less";
//
interface Props {
  curTab?: { key: string; value: string };
}
const ListWrapper: React.FC<Props> = () => {
  const { isH5, isWxH5 } = platform;
  const { locationCode, listData, listLoading } = useList();
  const { navigate2Product } = useNavigation();
  const onProdutClick = e => {
    navigate2Product({
      activityId: e.activityId,
      activitySpuId: e.activitySpuId,
      selectSkuId: e.selectSkuId,
    });
  };
  return (
    <>
      {listData?.length > 0 && (
        <div
          className={styles["list-wrapper-container"]}
          style={{
            height: `calc(100vh - ${isH5 || isWxH5 ? "66px" : "0px"})`,
            overflowY: "scroll",
          }}>
          {listData?.map((item: any, index) => {
            return (
              <div key={index} className={styles["list-wrapper"]}>
                <div className={styles["list-wrapper-date"]}>
                  {moment(Number(item?.viewTimeStamp)).format("MM月DD日")}
                </div>
                <div className={styles["list-wrapper-content"]}>
                  {item?.traceList?.map((product, idx) => {
                    return (
                      <div
                        className={styles["list-wrapper-content-item"]}
                        key={product?.activitySpuId}>
                        <ProductCard1NComp
                          product={product}
                          titleLines={1}
                          onProdutClick={product => {
                            onProdutClick(product);
                            // trackResourceClick(product, idx);
                          }}
                          showBrandName={true}
                          showBottomTag={true}
                          bottomTagLocationCode={locationCode?.current}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className={styles["bottom-tips"]}>
            <p className={styles.desc}>仅展示七天内足迹</p>
          </div>
        </div>
      )}
      {!listData?.length && !listLoading && <Empty />}
    </>
  );
};

export default ListWrapper;
