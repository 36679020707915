import { akjs } from "@/utils/akjs";
import { platform } from "@/utils/platform";
import { getBaseurl } from "@/utils/url-parse";
import { API, ForwardChannelType, ForwardConfigModel } from "@akc/biz-api";
import { Request } from "./request";

const staticUrl = getBaseurl();

// 获取转发配置
export const getShareConfig = async () => {
  return API.post<ForwardConfigModel>({
    path: "/api/share/unity/prod/union/getShareConfigureV2",
  });
};

// 获取素材商品详情开关情况
export const getMaterialProductSwitch = async () => {
  return API.post<{ showFlag?: boolean; switchStatus?: number }>({
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/productMaterialDetailSwitch/query",
    body: {
      scene: "CONFIGURATION",
    },
  });
};

// 更新素材商详开关状态
export const updateMaterialProductSwitch = async (switchStatus: boolean) => {
  return API.post({
    path: "/api/mshop/mshop-aggr-prod/outer/materialUse/v1/productMaterialDetailSwitch/update",
    body: {
      switchStatus: switchStatus ? 1 : 0,
    },
  });
};

// 获取转发模式
export const fetchForwardMode = async (): Promise<ForwardChannelType> => {
  const config = await getShareConfig();
  return config?.shareChannels?.find(item => item.defaultChannel)?.shareChannelCode || "h5";
};

export interface ShareConfigParams {
  /** 是否转发缺货尺码(0否1是) */
  stockFlag?: 0 | 1;
  /** 转发数 */
  forwardNum?: number;
  /** 海报样式-用户昵称(0否1是) */
  expandStyleNickName?: 0 | 1;
  /** 海报样式-店铺名称(0否1是) */
  expandStyleShopName?: 0 | 1;
  /** 默认使用渠道(h5，applets小程序，pApplets个人小程序) */
  defaultChannel?: ForwardChannelType;
  /** 使用模板 */
  defaultTemplateNo?: string;
  /** 默认的活动海报模板 */
  defaultActivityPosterTemplateNo?: string;
  /** 默认的活动卡片模板 */
  defaultActivityCardTemplateNo?: string;
  /** 默认商品卡片模版 */
  defaultProductCardTemplateNo?: string;
  /** 商品卡片不为空时，当前字段标识是否显示头像昵称0否1是 */
  customTemplateFlag?: 0 | 1;
}

// 变更转发配置
export const setShareConfig = async (params?: ShareConfigParams) => {
  return API.post<ForwardConfigModel>({
    path: "/api/share/unity/prod/union/shareConfigureV2",
    body: params,
  }).then(async model => {
    if (model && platform.isApp) {
      // 先存储再响应出去
      await akjs.kvStorage.set("forward_setting_config", JSON.stringify(model));
    }
    return model;
  });
};

// 判断是否在灰度中
export const isBizHit = async (code: string) => {
  const response = await Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/common/bwl/isHitByBatch`,
    method: "post",
    data: {
      bwlCodes: [code],
    },
  });
  const result = response?.data;
  if (result) {
    const items = result.filter(item => item.bwlCode === code);
    if (items.length > 0) {
      return items[0].isHit === 1;
    }
  }
  return false;
};

// 选择性转发商品-保存接口
export const saveForwardRecord = (params: any) => {
  Request({
    url: `${staticUrl}/aggregation-center-facade/api/app/forwardRecord/save/v3.0`,
    method: "post",
    data: params,
  });
};
