import StarComp from "@/components/basics/star";
import { NumberUtils } from "@akc/foundation";
import React, { useState } from "react";
import styles from "./ShopItem.module.less";

interface props {
  item: any;
  index: number;
  onConfirmAction: (item: any) => void;
}

const ShopItem: React.FC<props> = ({ item, index, onConfirmAction }) => {
  const starDom = () => {
    if (item?.score > 0) {
      return (
        <>
          <StarComp score={item.score || 0} />
          <span className={styles.star__score}>
            {`${NumberUtils.toInt(item.score)}.`}
            {NumberUtils.decimals(item.score, 1)}分
          </span>
        </>
      );
    } else {
      return <span className={styles.star__score}>暂无评分</span>;
    }
  };

  return (
    <div className={styles.shop}>
      <div className={styles.shop__left}>
        <img className={styles.shop__logo} src={item.bizLogo} />
        <div className={styles.shop__info}>
          <p className={styles.shop__name}>{item.bizName}</p>
          <div className={styles.shop__star}>{starDom()}</div>
        </div>
      </div>
      <div
        className={`${styles.shop__btn} ${item.isHide && styles.shop__btn_cancel}`}
        onClick={() => onConfirmAction(item)}>
        {item.isHide ? "取消隐藏" : "隐藏店铺"}
      </div>
    </div>
  );
};

export default ShopItem;
