import { Popup } from "antd-mobile";
import React, { useEffect } from "react";
import styles from "./index.module.less";

interface IProps {
  visible: boolean;
  visibleChange: (visible: boolean) => void;
}

const RedPackRule: React.FC<IProps> = ({ visible, visibleChange }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    visibleChange(false);
  };

  useEffect(() => {
    setOpen(visible);
  }, [visible]);
  return (
    <>
      <Popup
        visible={open}
        showCloseButton
        onMaskClick={handleClose}
        onClose={handleClose}
        bodyStyle={{ height: "70vh" }}>
        {
          <div className={styles.popupContent}>
            <div className={styles.popupHeader}>活动规则</div>
            <div className={styles.popupText}>
              <div className={styles.popupTextTitle}>规则一</div>
              <span>转发领红包活动仅限店主在店铺内参与；</span>
            </div>
            <div className={styles.popupText}>
              <div className={styles.popupTextTitle}>规则二</div>
              <span>转发带有“转发红包”标签的活动，才可以获得领红包的机会；</span>
            </div>
            <div className={styles.popupText}>
              <div className={styles.popupTextTitle}>规则三</div>
              <span>每场品牌活动只有1次领取红包的机会；</span>
            </div>
            <div className={styles.popupText}>
              <div className={styles.popupTextTitle}>规则四</div>
              <span>每次转发成功后，即有机会获得红包，红包奖励会自动转入余额账户并支持提现；</span>
            </div>
            <div className={styles.popupText}>
              <div className={styles.popupTextTitle}>规则五</div>
              <span>每次转发领红包的活动中，单个红包金额随机产出，金额不确定；</span>
            </div>
            <div className={styles.popupText}>
              <div className={styles.popupTextTitle}>规则六</div>
              <span>若店铺的转发一直没有成交，会影响继续获得转发领取红包的资格。</span>
            </div>
          </div>
        }
      </Popup>
    </>
  );
};
export default RedPackRule;
