import { ReactComponent as FilterIcon } from "@/assets/filter.svg";
import SortIcon from "@/components/sort-icon";
import { Popup } from "antd-mobile";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";
// import { FilterOptionProvider } from "@/pages/activity/hooks/useFilterOption";
import { FilterOptionProvider } from "../../hooks/useFilterOption";
// import FilterBoard from "@/pages/activity/components/FilterBoard";
import FilterBoard from "../../components/FilterBoard";
import { UseSelectiveProductContext } from "../../hooks/useSelectiveProduct";

type Props = {
  ref?: ForwardedRef<any>;
};
const SortAndFilter = forwardRef<any, Props>((props, ref) => {
  const [search] = useSearchParams();
  const queryData = {
    activityIdList: search.get("activityIdList")?.split(",") || undefined,
    brandIds: search.get("brandIds")?.split(",") || undefined,
    categoryIdList: search.get("categoryIdList")?.split(",") || undefined,
    shopCodes: search.get("shopCodes")?.split(",") || undefined,
  };

  const {
    sortList,
    selectedSortModel,
    setSelectedSortModel,
    selectedSortType,
    setSelectedSortType,
    setFilterInfo,
    hasFilterActive,
  } = UseSelectiveProductContext();

  const [filterBoardVisible, setFilterBoardVisible] = useState(false);
  const filterBoardRef = useRef<any>(null);

  useUnactivate(() => {
    setFilterBoardVisible(false);
  });

  useImperativeHandle(ref, () => {
    return {
      resetFilter: filterBoardRef.current?.reset,
    };
  });

  return (
    <>
      <div className={styles["sort-list"]}>
        {sortList.map((item, index) => (
          <div
            key={index}
            className={`${styles["sort-item"]} ${selectedSortModel === item.sortModel ? styles["active"] : ""}`}
            onClick={() => {
              if (selectedSortModel === item.sortModel) {
                if (item.sortTypes.length === 2) {
                  setSelectedSortType(selectedSortType === 1 ? 2 : 1);
                }
              } else {
                setSelectedSortModel(item.sortModel);
                setSelectedSortType(item.sortTypes[0]);
              }
            }}>
            <span>{item.name}</span>
            {item.sortTypes.length === 2 && (
              <SortIcon sortType={selectedSortModel === item.sortModel ? selectedSortType : 0} />
            )}
          </div>
        ))}
        <div
          className={`${styles["sort-item"]} ${hasFilterActive ? styles["active"] : ""}`}
          onClick={() => setFilterBoardVisible(true)}>
          <span>筛选</span>
          <FilterIcon className={styles.filterIcon} />
        </div>
      </div>
      <FilterOptionProvider
        filterBoardVisible={filterBoardVisible}
        scene="SELECTIVE_FORWARDING_PAGE"
        pageId="H5_TopProduct"
        activityIdList={queryData.activityIdList}
        brandIds={queryData.brandIds}
        categoryIdList={queryData.categoryIdList}
        shopCodes={queryData.shopCodes}>
        <Popup
          visible={filterBoardVisible}
          showCloseButton
          onMaskClick={() => {
            setFilterBoardVisible(false);
          }}
          onClose={() => {
            setFilterBoardVisible(false);
          }}
          bodyStyle={{ height: "90vh" }}>
          <FilterBoard
            ref={filterBoardRef}
            brandIds={queryData.brandIds}
            categoryIdList={queryData.categoryIdList}
            onFilterChange={params => {
              setFilterBoardVisible(false);
              setFilterInfo(params);
              console.log("onFilterChange", params);
            }}
          />
        </Popup>
      </FilterOptionProvider>
    </>
  );
});

export default SortAndFilter;
