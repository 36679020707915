import ProductWaterfall from "@/components/product-waterfall";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import React, { useContext, useState } from "react";
import useProductList from "../hooks/useProductList";
import BrandTabs from "./BrandTabs";
import FixedGuide from "./FixedGuide";

type Props = {
  isVisitor: boolean;
  toBind: () => void;
};

const ProductListWaterfall: React.FC<Props> = ({ isVisitor, toBind }) => {
  const { productList, hasNext, loadMore, selectedBrand, setSelectedBrand } = useProductList();
  const [isShowToTopBtn, setIsShowToTopBtn] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { trackData, setTrackData } = useContext(TrackDataContext);

  return (
    <>
      <BrandTabs
        selectedBrand={selectedBrand}
        setSelectedBrand={val => {
          val &&
            setTrackData({
              ...trackData,
              tab_name: val.brandName,
              tab_rank: val.tab_rank + 1,
            });
          setSelectedBrand(val);
        }}
        setIsShowToTopBtn={setIsShowToTopBtn}
      />
      <ProductWaterfall
        productList={productList}
        hasNext={hasNext}
        loadMore={loadMore}
        topRightLocationCode="H5_ScheduleDetail_SmallList_ProductPicRight"
        titleFrontLocationCode="H5_ScheduleDetail_SmallList_TitleFront"
        titleBottomLocationCode="H5_ScheduleDetail_SmallList_TitleDown"
      />
      <FixedGuide isShowToTopBtn={isShowToTopBtn} isVisitor={isVisitor} toBind={toBind} />
    </>
  );
};

export default ProductListWaterfall;
