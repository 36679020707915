const updateUrlParameter = (url, paramName, paramValue) => {
  // 创建一个URL对象
  let newUrl = new URL(url);

  // 使用URLSearchParams来处理查询字符串
  let params = new URLSearchParams(newUrl.search);

  // 设置新的参数值
  params.set(paramName, paramValue);

  // 更新URL对象的查询字符串
  newUrl.search = params.toString();

  // 返回新的URL字符串
  return newUrl.toString();
};

export const PDUtils = {
  updateUrlParameter,
};
