import {
  getDarkWord,
  getHotWord,
  getSuggestWord,
  queryDiscoverList,
  setAwesome,
} from "@/api/promote";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import exposure from "@/utils/expose/exposure";
import { MICRO_APP_BASEURL, getCookie } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { storeRem } from "@/utils/rem";
import Track from "@/utils/track";
import { unmountApp } from "@micro-zoe/micro-app";
import { Dialog, Image, InfiniteScroll } from "antd-mobile";
import { debounce, isEmpty } from "lodash";
import Macy from "macy";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import MaterialCard from "../promote/components/discovery/components/material_card";
import { useTrack } from "../promote/track";
import styles from "./search.module.less";
interface Props {
  appStore?: any;
  [key: string]: any;
}
const imageInfoMap = new Map();
const Home: React.FC<Props> = () => {
  const [macyObject, setMacyObject] = useState<any>({});
  const track = Track();
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const distributorId = search.get("distributorId");
  const previous_page_name = search.get("page_name");
  const navigater = useNavigate();
  const [historyList, setHistoryList] = useState<any>([]);
  const [showTag, setShowTag] = useState<any>({
    text: "",
    show: false,
  });
  const [hasMore, setHasMore] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [darkList, setDarkList] = useState<any[]>([{ searchWords: "" }]);
  const [hotList, setHotList] = useState<any[]>([]);
  const [inputVal, setInputVal] = useState("");
  const [showSuggest, setShowSuggest] = useState(false);
  const [suggestList, setSuggestList] = useState<any[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [over, setOver] = useState(false);
  const [maxHeight, setMaxHeight] = useState<any>(75);
  const { trackSearchClick, trackSearchResult, trackSearchCardClick } = useTrack();
  const [searchRequestId, setSearchRequestId] = useState<string>("");
  const [materialDatalist, setMaterialDatalist] = useState<any>([]);
  const [isHide, setIsHide] = useState(false);
  const suggestRef = useRef(false);
  // const [isloading, setIsloading] = useState(false);
  // const [time, setTime] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTime(time + 1);
  //     if (time >= darkList?.length - 2 && time > 0) {
  //       setDarkList(darkList.concat(darkList));
  //     }
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [time]);
  //加载更多
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {materialDatalist?.length > 0 && (
              <>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
                <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const queryDarkWord = async () => {
    const res = await getDarkWord({
      shopBizCode: search.get("shopId"),
      scene: "G_P_C",
    });
    setDarkList(res?.data?.darkList || [{ searchWords: "" }]);
  };

  const queryHotWord = async () => {
    const res = await getHotWord({
      shopBizCode: search.get("shopId"),
      scene: "G_P_C",
    });
    let list = res?.data?.hotWords || [];
    list = list.filter(item => {
      return item.hotLink === null || item.hotLink === undefined;
    });
    setHotList(list);
  };

  useEffect(() => {
    if (materialDatalist?.length > 0) {
      const interval = setInterval(() => {
        const dom = document.getElementById("waterfall");
        if (dom) {
          clearInterval(interval);
          const macyInstance = Macy({
            container: "#waterfall", // 图像列表容器
            trueOrder: false,
            waitForImages: true,
            useOwnImageLoader: false,
            debug: true,
            margin: { x: 0, y: 15 }, // 设计列与列的间距
            columns: 2, // 设置列数
          });
          macyObject["waterfall"] = macyInstance;
          setMacyObject(macyObject);
          macyObject["waterfall"].runOnImageLoad(function () {
            macyObject["waterfall"].recalculate(true, true);
          });
          let sessionStorageValue: string = sessionStorage.getItem("discovery_card_12") as string;
          sessionStorageValue = JSON.parse(sessionStorageValue);
          const objName = "waterfall";
          if (sessionStorageValue) {
            sessionStorageValue[objName] = document.getElementById(objName)?.style.height;
            sessionStorage.setItem("discovery_card_12", JSON.stringify(sessionStorageValue));
          } else {
            const obj: any = {};
            obj[objName] = document.getElementById(objName)?.style.height;
            sessionStorage.setItem("discovery_card_12", JSON.stringify(obj));
          }
          // }, 50);
        }
      }, 50);
    }
  }, [materialDatalist?.length]);

  useEffect(() => {
    document.title = "逛一逛";
    storeRem();
    unmountApp("h5shop", {
      destroy: true,
    });
    setSearchRequestId(getCookie("mstoken") || "" + Date.now());
    queryDarkWord();
    queryHotWord();
  }, []);

  useActivate(() => {
    console.log("in useActivate search");
    document.title = "逛一逛";
    if (
      localStorage.getItem("search_tag") === null ||
      localStorage.getItem("search_tag") === "undefined"
    ) {
      setShowTag({
        show: false,
        text: "",
      });
      setInputVal("");
      setPageNum(1);
      setMaterialDatalist([]);
      setShowSuggest(false);
      setMaxHeight(75);
      setShowMore(true);
      // setOver(true);
      localStorage.removeItem("search_tag");
    } else {
      localStorage.removeItem("search_tag");
    }
  });

  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  useEffect(() => {
    let list: any = localStorage.getItem("promote-s-searchHistory");
    if (!isEmpty(list)) {
      list = JSON.parse(list);
      setHistoryList(list.splice(0, 20));
    } else {
      setHistoryList([]);
    }
    track.track("$pageview", {
      page_name: "搜索结果页",
      previous_page_name: previous_page_name,
      domain: "种草",
    });
  }, []);
  const queryImageInfo = async (list: Array<any>) => {
    if (!list || list.length === 0) {
      return [];
    }
    const queryInfo = url => {
      return new Promise(resolve => {
        if (imageInfoMap.get(url)) {
          resolve(imageInfoMap.get(url));
        } else if (url.indexOf("?") === -1) {
          fetch(url + "?x-image-process=image/info")
            .then(res => {
              if (res.status === 200) {
                return res.json();
              }
            })
            .then(res => {
              imageInfoMap.set(url, res);
              resolve(res);
            })
            .catch(() => {
              resolve({ width: "", height: "" });
            });
        } else {
          resolve({ width: 100, height: 100 });
        }
      });
    };
    const promises = list.map(item => queryInfo(item?.coverVOList[0]?.coverUrl));
    const dimensionsList = await Promise.all(promises);
    return list.map((item, index) => {
      return {
        ...item,
        coverInfo: dimensionsList[index],
      };
    });
  };
  //获取tab下的列表
  const queryTabList = async (keyword, pageNum, searchType?: string) => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    setLoading(true);
    const res: any = await queryDiscoverList({
      scene: "G_P_C",
      pageNo: pageNum,
      pageSize: pageNum > 1 ? 5 : 20,
      keyWords: keyword,
      // frontCodes: [{ ...item }],
      searchRequestId,
    });
    let list: any = [];
    const handledData = await queryImageInfo(res?.data?.result);
    for (const item of handledData || []) {
      list.push(item);
    }
    if (pageNum > 1 && materialDatalist.length > 0) {
      list = materialDatalist.concat(list);
    }
    setMaterialDatalist(list);
    setShowSuggest(false);
    pageNum === 1 ? setPageNum(4) : setPageNum(pageNum + 1);
    setHasMore(res?.data?.hasNext ?? false);
    setQueryLoading(false);
    setLoading(false);
    searchType &&
      trackSearchResult({
        keyword: keyword,
        search_type: searchType,
        totalCount: res?.data?.totalCount ?? 0,
        searchRequestId,
        previous_page_name,
      });
  };

  const searchText = (e?: any) => {
    if (e.key === "Enter") {
      e.target.blur();
    } else {
      return;
    }
    if (showTag.show) {
      return;
    }
    let text = e.target.value;
    if (isEmpty(text) && darkList?.length > 0) {
      text = darkList[0].searchWords;
    }
    if (e.key === "Enter" && !isEmpty(text)) {
      track.track("btn_click", {
        page_name: "种草页-搜索",
        previous_page_name: previous_page_name,
        domain: "种草",
        btn_name: "搜索",
        btn_text: text,
      });
      //查询
      let keywords: any = localStorage.getItem("promote-s-searchHistory");
      if (keywords) {
        keywords = JSON.parse(keywords);
        const index = keywords.indexOf(text);
        if (index < 0) {
          keywords.unshift(text);
        } else {
          keywords.splice(index, 1);
          keywords.unshift(text);
        }
      } else {
        keywords = [];
        keywords.unshift(text);
      }
      if (suggestRef.current) {
        setTimeout(() => {
          keywords = keywords.splice(0, 20);
          setHistoryList(keywords);
          localStorage.setItem("promote-s-searchHistory", JSON.stringify(keywords));
          setShowTag({ text: text, show: true });
          queryTabList(text, 1, "输入词");
          setMaxHeight(75);
        }, 1000);
      } else {
        keywords = keywords.splice(0, 20);
        setHistoryList(keywords);
        localStorage.setItem("promote-s-searchHistory", JSON.stringify(keywords));
        setShowTag({ text: text, show: true });
        queryTabList(text, 1, "输入词");
        setMaxHeight(75);
      }
    } else {
      if (suggestRef.current) {
        setTimeout(() => {
          setMaterialDatalist([]);
          setHasMore(false);
          setShowSuggest(false);
          setPageNum(1);
          setMaxHeight(75);
        }, 1000);
      } else {
        setMaterialDatalist([]);
        setHasMore(false);
        setShowSuggest(false);
        setPageNum(1);
        setMaxHeight(75);
      }
    }
  };

  const searchBtn = () => {
    setMaterialDatalist([]);
    const dom: any = document.getElementById("inputDom");
    let text = dom.value;
    if (isEmpty(text) && darkList?.length > 0) {
      text = darkList[0].searchWords;
    }
    if (showTag.show) {
      queryTabList(showTag.text, 1);
      return;
    }
    if (!isEmpty(text)) {
      let keywords: any = localStorage.getItem("promote-s-searchHistory");
      if (keywords) {
        keywords = JSON.parse(keywords);
        const index = keywords.indexOf(text);
        if (index < 0) {
          keywords.unshift(text);
        } else {
          keywords.splice(index, 1);
          keywords.unshift(text);
        }
      } else {
        keywords = [];
        keywords.unshift(text);
      }
      keywords = keywords.splice(0, 20);
      setHistoryList(keywords);
      localStorage.setItem("promote-s-searchHistory", JSON.stringify(keywords));
      trackSearchClick({ btn_text: text, btn_name: "搜索", previous_page_name });
      setShowTag({ text: text, show: true });
      queryTabList(text, 1, "输入词");
    } else {
      setMaterialDatalist([]);
      setHasMore(false);
      setPageNum(1);
    }
  };

  const clearKey = () => {
    Dialog.confirm({
      content: "确认删除搜索记录?",
      className: "dialog_search",
      onConfirm: async () => {
        localStorage.removeItem("promote-s-searchHistory");
        setHistoryList([]);
        trackSearchClick({ btn_text: "", btn_name: "删除", previous_page_name });
      },
    });
  };

  const clear = e => {
    e.stopPropagation();
    // trackSearchClick({ btn_text: showTag.text, btn_name: "删除" });
    setShowTag({
      text: "",
      show: false,
    });
    setShowMore(false);
    setSuggestList([]);
    setShowSuggest(false);
    setMaterialDatalist([]);
    setHasMore(false);
    setPageNum(1);
    setInputVal("");
    setMaxHeight(75);
  };

  const clickTab = (text, btn_name?: any) => {
    trackSearchClick({ btn_text: text, btn_name: btn_name, previous_page_name });
    setShowTag({
      text: text,
      show: true,
    });
    setSuggestList([]);
    setShowSuggest(false);
    let keywords: any = localStorage.getItem("promote-s-searchHistory");
    if (keywords) {
      keywords = JSON.parse(keywords);
      const index = keywords.indexOf(text);
      if (index < 0) {
        keywords.unshift(text);
      } else {
        keywords.splice(index, 1);
        keywords.unshift(text);
      }
    } else {
      keywords = [];
      keywords.unshift(text);
    }
    keywords = keywords.splice(0, 20);
    setHistoryList(keywords);
    localStorage.setItem("promote-s-searchHistory", JSON.stringify(keywords));
    queryTabList(text, 1, btn_name);
  };

  const loadMore = async () => {
    if (hasMore && !queryLoading) {
      await queryTabList(showTag.text, pageNum);
    }
  };

  const changeShow = () => {
    const obj = { ...showTag };
    trackSearchClick({ btn_text: "", btn_name: "搜索框", previous_page_name });
    if (showTag.show) {
      console.log("进入查询-------");
      obj.show = false;
      setShowTag(obj);
      const dom: any = document.getElementById("inputDom");
      setInputVal(obj.text);
      setMaterialDatalist([]);
      setShowSuggest(true);
      setTimeout(() => {
        console.log("进入查询2222------");
        dom?.focus();
      }, 1000);
    }
  };

  const submit = e => {
    e.preventDefault();
  };

  const querySuggestWord = debounce(async text => {
    suggestRef.current = true;
    console.log("text----------", text, isEmpty(text));
    if (isEmpty(text)) {
      return;
    }
    const res = await getSuggestWord({
      shopBizCode: search.get("shopId"),
      keyword: text,
      scene: "G_P_C",
    });
    const list: any = [];
    if (res?.data?.suggest?.length > 0) {
      for (let item of res.data.suggest) {
        const reg = new RegExp(`(${text})`, "g");
        item = {
          text: `<div>${item.replace(reg, `<span style="color:#ff0000">${text}</span>`)}</div>`,
          old: item,
        };
        list.push(item);
      }
    }
    setSuggestList(list || []);
    setShowSuggest(true);
    suggestRef.current = false;
  }, 500);

  const changeText = e => {
    const text = e?.target?.value;
    console.log(text, showTag.show);
    setInputVal(text);
    if (isEmpty(text)) {
      setShowSuggest(false);
      setShowTag({ show: false, text: "" });
    }
  };

  useEffect(() => {
    querySuggestWord(inputVal);
    // 清除上一个 effect，确保每次只有一个防抖函数在运行
    return () => {
      querySuggestWord.cancel();
    };
  }, [inputVal]);

  useEffect(() => {
    if (showTag.show || showSuggest) {
      return;
    }
    const rem: any = document.documentElement.style.fontSize.split("px")[0];
    const height: any = document.getElementById("tags")?.offsetHeight;
    if (height / rem > 1.7) {
      setShowMore(true);
      setOver(true);
    } else {
      setShowMore(false);
      setOver(false);
    }
  }, [historyList?.length, showTag.show, showSuggest]);

  const setLike = async (item, index) => {
    const params = {
      scene: "G_P_C",
      materialNo: item.materialNo,
      interactScene: 1,
      interactFlag: item.awesome ? 1 : 0,
    };
    const res = (await setAwesome(params)) as any;
    if (res && res.success) {
      const newMaterialDatalist = materialDatalist.map(i => {
        if (i.materialNo === item.materialNo) {
          return {
            ...i,
            awesomeNum: res.data,
            awesome: !item.awesome,
          };
        } else {
          return i;
        }
      });
      // const indexAll = (pageNum - 2) * 10 + index;
      trackSearchCardClick({
        index,
        btn_name: item.awesome ? "取消点赞" : "点赞",
        previous_page_name,
      });
      setMaterialDatalist(newMaterialDatalist);
    }
  };

  const goDetail = (item, index) => {
    localStorage.setItem("search_tag", "true");
    // const indexAll = (pageNum - 2) * 10 + index;
    // trackSearchCardClick({ index: indexAll, btn_name: "查看详情" });
    trackSearchCardClick({ index, btn_name: "查看详情", previous_page_name });
    if (item.containVideo && item.type === 1) {
      distributorId
        ? navigater(
            `/mx-shop-micro/recommendList?distributorId=${distributorId}&materialNo=${item.materialNo}`,
          )
        : navigater(`/mx-shop-micro/recommendList?materialNo=${item.materialNo}`);
    } else {
      distributorId
        ? navigater(
            `/mx-shop-micro/PromoteDetail?distributorId=${distributorId}&materialNo=${item.materialNo}&shopId=${shopId}`,
          )
        : navigater(`/mx-shop-micro/PromoteDetail?materialNo=${item.materialNo}&shopId=${shopId}`);
    }
  };

  const delKey = e => {
    e.stopPropagation();
    // trackSearchClick({ btn_text: showTag.text, btn_name: "删除" }); //还未搜索
    setTimeout(() => {
      setShowSuggest(false);
      setShowTag({
        show: false,
        text: "",
      });
      setMaterialDatalist([]);
      setMaxHeight(75);
      setInputVal("");
    }, 300);
  };

  //搜索结果卡片曝光埋点
  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < materialDatalist.length; i++) {
        const dom = document.getElementById(`search-${materialDatalist[i].materialNo}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [materialDatalist?.length]);

  return (
    <div className={styles.search}>
      <div className={styles.searchBox}>
        <img
          onClick={() => goBackOrHome()}
          className={styles.back}
          src="https://akim-oss.aikucun.com/11d024154013c0ca47dfaeca33ecdeb6a5b9fcb6_1694082344311_50.png"></img>
        <div className={styles.inputBox} onClick={() => changeShow()}>
          <img
            className={styles.img}
            src="https://akim-oss.aikucun.com/f04090805c6eebdfc7f9cb69b81f3e44c3a3636a_1694086326376_51.png"></img>
          <form onSubmit={submit} action="javascript:return true;">
            <input
              className={styles.input + " " + (showTag.show ? styles.hid : styles.visiable)}
              onKeyDown={e => searchText(e)}
              onChange={e => changeText(e)}
              id="inputDom"
              type="search"
              placeholder={darkList?.[0]?.searchWords}
              value={inputVal}
              autoFocus
            />
            {!isEmpty(inputVal) && (
              <img
                onClick={e => delKey(e)}
                className={styles.del}
                src="https://akim-oss.aikucun.com/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1701227025054_25.png"
              />
            )}
            {/* <div className={styles.darkList}>
              {
                darkList?.length > 0 && darkList.map((item, index) => {
                  return <div style={{ top: pxtorem(time * -20) }} className={styles.darkList_item} key={index}>{item.searchWords}</div>;
                })
              }
            </div> */}
          </form>
          <div className={styles.tag + " " + (showTag.show ? styles.visiable : styles.hid)}>
            <div
              style={{
                fontSize: pxtorem(14),
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: pxtorem(200),
              }}>
              {showTag.text}
            </div>
            <img
              onClick={e => clear(e)}
              className={styles.close}
              src="https://akim-oss.aikucun.com/feacb8e58e1e4974fd9a12dc2e5e730ffd3ec89f_1694091300902_1.png"></img>
          </div>
        </div>
        <div className={styles.text} onClick={() => searchBtn()}>
          搜索
        </div>
      </div>
      {suggestList.length > 0 && showSuggest && !showTag.show && (
        <div className={styles.suggest}>
          {suggestList.map((item, index) => {
            return (
              <div
                className={styles.item + " " + "sug_item"}
                key={index}
                onClick={() => clickTab(item.old, "推荐")}
                dangerouslySetInnerHTML={{ __html: item.text }}></div>
            );
          })}
        </div>
      )}
      {historyList.length > 0 && !showTag.show && !showSuggest && (
        <div className={styles.history}>
          <div className={styles.top}>
            <div className={styles.text}>搜索历史</div>
            <img
              onClick={() => clearKey()}
              className={styles.img}
              style={{
                width: pxtorem(18),
                height: pxtorem(18),
              }}
              src="https://akim-oss.aikucun.com/48570e5616cec2c76868543427cb99868d0eb377_1701079697838_78.png"></img>
          </div>
          <div
            className={styles.tagsDiv}
            style={{ maxHeight: maxHeight ? pxtorem(maxHeight) : "unset" }}>
            <div className={styles.tags} id="tags">
              {historyList.map((item, index) => {
                return (
                  <div
                    className={styles.item}
                    key={index}
                    onClick={() => clickTab(item, "搜索历史")}>
                    {item}
                  </div>
                );
              })}
              {!showMore && over && (
                <div
                  className={styles.up}
                  onClick={() => {
                    setMaxHeight(75);
                    setShowMore(true);
                  }}>
                  <img src="https://akim-oss.aikucun.com/2b9d013177da6700fbaee9f12bb86452c577caf6_1700657079063_17.png" />
                </div>
              )}
            </div>
            {showMore && over && (
              <div
                className={styles.down}
                onClick={() => {
                  setMaxHeight(null);
                  setShowMore(false);
                }}>
                <img src="https://akim-oss.aikucun.com/2b9d013177da6700fbaee9f12bb86452c577caf6_1700657079063_17.png" />
              </div>
            )}
          </div>
        </div>
      )}
      {hotList.length > 0 && !showSuggest && !showTag.show && (
        <div className={styles.hot}>
          <div className={styles.top}>
            <div className={styles.text}>搜索发现</div>
            <div
              className={`${styles["discovery-show"]} ${styles[isHide ? "hide" : "unhide"]}`}
              onClick={() => {
                setIsHide(!isHide),
                  trackSearchClick({ btn_text: "", btn_name: "隐藏", previous_page_name });
              }}
            />
          </div>
          {isHide ? (
            <div
              style={{
                color: "#A5A5A5",
                fontSize: pxtorem(10),
                textAlign: "center",
                paddingTop: pxtorem(16),
              }}>
              当前搜索发现已隐藏
            </div>
          ) : (
            <div className={styles.tags} style={{ maxHeight: "unset" }}>
              {hotList.map((item, index) => {
                return (
                  <div
                    className={styles.item}
                    key={index}
                    onClick={() => clickTab(item.hotName, "搜索发现")}>
                    {item.hotName}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {materialDatalist?.length > 0 && showTag.show && !showSuggest && (
        <div className={styles["water-fall-box"]}>
          <div id="waterfall" className={styles["waterfall"]}>
            {materialDatalist &&
              materialDatalist?.length > 0 &&
              materialDatalist.map((item, index) => {
                return (
                  <div
                    className={styles["card-item"]}
                    id={`search-${item.materialNo}`}
                    data-param={JSON.stringify({
                      page_name: "搜索结果",
                      domain: "种草",
                      module: "图文列表",
                      resourceRank: index,
                      materialId: item?.materialNo,
                      previous_page_name: previous_page_name,
                    })}
                    data-eventid={`search-${item.materialNo}`}
                    key={index}>
                    <MaterialCard
                      key={index}
                      {...item}
                      setLike={item => {
                        setLike(item, index);
                      }}
                      goDetail={item => {
                        goDetail(item, index);
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={250}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
      )}
      {materialDatalist.length <= 0 && !loading && showTag.show && (
        <div className={styles.empty}>
          <img
            className={styles.img}
            src="https://akim-oss.aikucun.com/39b4ba7e3b243ff2a354ee1d644cbac0a0a2c1ed_1700467176746_20.png"></img>
          <div className={styles.text}>没有找到相关内容，换个词试试吧</div>
        </div>
      )}
    </div>
  );
};

export default inject("appStore")(observer(Home));
