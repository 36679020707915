import { queryJumpTypeServ } from "@/api/activity";
import { CouponAward } from "@/models/reward";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";

function useCouponAction() {
  const {
    navigate2Product,
    navigate2Activity,
    navigate2OfferOrderList,
    navigate2GoodsOfNpieceXfold,
  } = useNavigation();
  // 优惠券凑单页
  const couponDetailAction = async (couponAward?: CouponAward) => {
    const params = couponAward?.newVersionCoupon
      ? { voucherNo: couponAward?.voucherNo }
      : { couponId: couponAward?.couponId };
    const { success, data } = await queryJumpTypeServ(params);
    if (success && data) {
      const {
        jumpType,
        isOldCoupon,
        couponId: _couponId,
        voucherNo: _voucherNo,
        activityId,
        awdInstanceId,
      } = data ?? {};
      switch (jumpType) {
        case "productDetail":
          navigate2Product({
            activitySpuId: data.activitySpuId,
            activityId: data.activityId,
            selectSkuId: data.selectSkuId,
          });
          break;
        case "productList":
          if (platform.isApp) {
            AKJS.action.dispatch("event.page.open", {
              url: "/wechatMiniProgram",
              param: {
                path: `/acts/pages/offer-order/offer-order?id=${_couponId}&voucherNo=${_voucherNo}&awdInstanceId=${awdInstanceId}`,
              },
            });
            return;
          }
          navigate2GoodsOfNpieceXfold({
            miniQuery: isOldCoupon
              ? {
                  id: _couponId,
                  awdInstanceId,
                }
              : {
                  voucherNo: _voucherNo,
                  awdInstanceId,
                },
            h5Query: {
              couponId: _couponId,
              voucherNo: _voucherNo,
              awdInstanceId,
            },
          });
          break;
        case "activityDetail":
          navigate2Activity({
            activityNo: activityId,
          });
          break;
        case "activityList":
          if (platform.isApp) {
            AKJS.action.dispatch("event.page.open", {
              url: "/wechatMiniProgram",
              param: {
                path: `/acts/pages/offer-order/offer-order?id=${_couponId}&voucherNo=${_voucherNo}`,
              },
            });
            return;
          }
          navigate2OfferOrderList({
            miniQuery: isOldCoupon ? { id: _couponId } : { voucherNo: _voucherNo },
            h5Query: {
              couponId: _couponId,
              voucherNo: _voucherNo,
            },
          });
          break;
        default:
          break;
      }
    }
  };
  return {
    couponDetailAction,
  };
}

export default useCouponAction;
