import { Alert } from "@/components/alert";
import { AwardItem } from "@/models/reward";
import { getCookie, platform } from "@/utils/platform";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { useSearchParams } from "react-router-dom";

function usePageAction() {
  const { navigate, navigate2Store, navigate2H5, navigate2OrderDetail, navigate2Home } =
    useNavigation();
  const [search] = useSearchParams();
  const role = search.get("role");

  // 订单
  const openOrderDetail = (reward?: AwardItem) => {
    navigate2OrderDetail({ orderNo: reward?.productAward?.orderNo });
  };

  // 顾客奖励
  const openCustomerReward = (promoActivityId?: string) => {
    const query = {
      promoActivityId,
    };
    navigate({
      appOptions: {
        url: `${origin}/mx-shop-micro/customer-reward?promoActivityId=${promoActivityId}`,
      },
      miniOptions: {
        url: `${origin}/mx-shop-micro/customer-reward`,
        query: query,
      },
      h5Options: {
        url: "/mx-shop-micro/customer-reward",
        query: query,
      },
    });
  };

  // 会场
  const openVenue = info => {
    navigate({
      h5Options: {
        url: `/mx-shop-micro?customPageCode=${info.customPageCode}`,
      },
      appOptions: {
        url: `${origin}/mx-shop-micro?customPageCode=${info.customPageCode}`,
      },
      miniOptions: {
        url: `${origin}/mx-shop-micro`,
        query: {
          customPageCode: info.customPageCode,
        },
      },
    });
  };

  // 红包详情
  const openRedDetail = () =>
    navigate({
      appOptions: {
        url: `${origin}/mx-shop-micro/redPacket-detail`,
      },
      miniOptions: {
        url: `${origin}/mx-shop-micro/redPacket-detail`,
      },
      h5Options: {
        url: "/mx-shop-micro/redPacket-detail",
      },
    });

  // 红包凑单
  const openRedPacketUsePage = (isUse?: boolean) => {
    if (!isUse) {
      navigate2Home();
      return;
    }
    if (platform.isApp) {
      Alert.alert({
        content: "即将打开饷店小程序，请确保当前 App 账号与微信登录账号一致",
        confirmText: "立即打开",
        cancelText: "取消",
        onConfirm: () => {
          AKJS.action.dispatch("event.page.open", {
            url: "/wechatMiniProgram",
            param: {
              path: "/pages/transfer/transfer?pageName=offer-order",
            },
          });
        },
      });
      return;
    }
    navigate({
      miniOptions: {
        url: "/acts/pages/offer-order/offer-order",
      },
      h5Options: {
        url: "/mx-shop-micro/joint-bill",
      },
    });
  };

  // 积分中心
  const openPointCenter = () => {
    if (platform.isMini) {
      const link = `${location.origin}/v2/membercenter`;
      let url = `/pages/web-view/web-view?url=${encodeURIComponent(link)}`;
      window.wx.miniProgram.navigateTo({
        url,
      });
    } else {
      const options: IOptions = {
        path: "/#/membercenter",
        is_micro_app: true,
      };
      navigate2H5(options);
    }
  };

  // 店铺
  const openShopDetail = (merchantShopCode?: string) => {
    navigate2Store({
      merchantShopCode: merchantShopCode,
      shopCode: merchantShopCode,
      page_name: encodeURIComponent("店铺聚合"),
      productType: 1,
    });
  };

  // 跳转
  const navH5 = (path: string, queries?: { [key in string]: any }) => {
    const distributorId = search.get("distributorId");
    const shopId = search.get("shopId");
    let appendStr = "";
    if (queries) {
      appendStr = Object.keys(queries)
        .map(key => queries[key] && key + "=" + queries[key])
        .filter(i => i)
        .join("&");
    }
    if (platform.isApp) {
      const url = `${
        process.env.REACT_APP_PAGE_URL_4_APP
      }/v2/${path}?distributorId=${distributorId}&shopId=${shopId}&env_identity=${getCookie(
        "X-Hades-Env-Identity",
      )}&${appendStr}`;
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.page.open", { url }).catch(error => {
          console.error("跳转失败:", error);
        });
      });
    } else if (platform.isMini) {
      const url = location.href.split("mx-shop-micro");
      const newUrl = `${url[0]}v2/${path}?distributorId=${distributorId}&shopId=${shopId}&${appendStr}`;
      window.wx.miniProgram.navigateTo({
        url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
      });
    } else {
      const url = location.href.split("mx-shop-micro");
      const newUrl = `${url[0]}#/${path}?distributorId=${distributorId}&shopId=${shopId}&${appendStr}`;
      window.location.href = newUrl;
    }
  };

  const openCustomerCneter = (reward?: AwardItem) => {
    // 顾客中心
    const cRole = role || (platform?.isApp ? "b" : "e");
    navH5("analysis/customer/archieve", {
      role: cRole,
      customerId: reward?.userId,
      searchType: "visit",
    });
  };

  // 热卖榜单
  const openTopList = () => {
    if (platform.isMini) {
      const url = location.href.split("mx-shop-micro");
      const newUrl = `${url[0]}mx-shop-micro/decorate/toplist`;
      window.wx.miniProgram.navigateTo({
        url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
      });
    } else {
      navigate2H5({
        path: "/#/top-list?from=cjf",
      });
    }
  };

  return {
    openOrderDetail,
    openCustomerReward,
    openRedDetail,
    openRedPacketUsePage,
    openPointCenter,
    openShopDetail,
    openCustomerCneter,
    openVenue,
    openTopList,
  };
}

export default usePageAction;
