import { getGZHInfo } from "@/api/wechat";
import { useCommonContext } from "@/utils/context/common";
import { Modal, Popup } from "antd-mobile";
import { default as React } from "react";
import { btn_click } from "../../track";
import styles from "./index.module.less";

const FollowWeChat: React.FC = () => {
  const context = useCommonContext();
  const [showPopup, setShowPopup] = React.useState(false);

  const onPopupClose = () => {
    setShowPopup(false);
    getGZHInfo().then(res => {
      context.setGzhInfo(res);
      if (!res?.isFollow) {
        Modal.show({
          showCloseButton: true,
          closeOnMaskClick: true,
          content: (
            <div className={styles.modal}>
              <div className={styles.header}>您未关注公众号</div>
              <div className={styles.content}>将会错过品牌上新和福利折扣通知</div>
              <div
                className={styles.button}
                onClick={() => {
                  Modal.clear();
                  setShowPopup(true);
                }}>
                立即关注
              </div>
            </div>
          ),
        });
      }
    });
  };

  const onFollow = () => {
    setShowPopup(true);
    btn_click("我的-头部功能区", "关注公众号");
    // window.location.href = link;
  };

  return (
    <>
      <div className={styles["follow-wechat"]} onClick={onFollow}>
        <img
          className={styles["follow-wechat__icon"]}
          src="https://akim-oss.aikucun.com/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59_1698929404619_16.png"
        />
        <div className={styles["follow-wechat__text"]}>关注公众号，接收发货消息通知</div>
        <div className={styles["follow-wechat__btn"]}>关注</div>
      </div>
      <Popup visible={showPopup} showCloseButton onMaskClick={onPopupClose} onClose={onPopupClose}>
        <div className={styles.popup}>
          <div className={styles.header}>关注公众号领积分</div>
          <div className={styles.imgWrapper}>
            {context.gzhInfo?.weChatInfoVO?.qrCodeUrl && (
              <img className={styles.bg} src={context.gzhInfo?.weChatInfoVO?.qrCodeUrl} />
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};
export default FollowWeChat;
