import { currentUserInfoServ, getUserInfo } from "@/api/my/index";
import { switchRole } from "@/api/my/shop";
import constate from "constate";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useUserInfo = () => {
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const [userInfo, setUserInfo] = React.useState<any>({});

  const updateUserInfo = () => {
    Promise.all([getUserInfo({ shopId }), currentUserInfoServ()]).then(response => {
      const [user1, user2] = response;
      const data = {
        ...user1?.data,
        ...user2?.data,
      };
      localStorage.setItem("userinfo", JSON.stringify(data));
      localStorage.setItem("userinfo-switch", JSON.stringify(data));
      setUserInfo(data);
    });
  };

  const handleSwitchRole = async role => {
    const [res] = await Promise.all([switchRole(role)]);
    if (res?.data) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  useEffect(() => {
    updateUserInfo();
  }, []);

  return { userInfo, setUserInfo, updateUserInfo, handleSwitchRole };
};

const [UserInfoProvider, UseUserInfoContext] = constate(useUserInfo);
export { UserInfoProvider, UseUserInfoContext };
