import { getWhetherPopup } from "@/api/promote";
import useNavigation from "@/utils/useNavigate";
import { pop } from "@sentry/browser/types/transports/offline";
import { Dialog, Modal } from "antd-mobile";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useUnactivate } from "react-activation";
import DistributorStimulateSwitchSecondPop from "./DistributorStimulateSwitchSecondPop";
import RightsUpgradePop from "./RightsUpgradePop";

type Props = {
  businessCode: string;
  ref?: any;
};

type PopInfo = {
  popupFlag: boolean;
  popupCode: string;
  obj?: any;
}

const BizPopup: React.FC<Props> = forwardRef(({ businessCode }, ref) => {
  const { navigate } = useNavigation();
  const showRightsUpgradePop = () => {
    const handle = Modal.show({
      content: <RightsUpgradePop onClose={() => handle.close()} />,
      bodyStyle: { width: "80vw", borderRadius: "10px", paddingTop: "10px" },
      closeOnMaskClick: true,
    });
  };
  const showDistributorStimulateSwitchSecondPop = (obj) => {
    const onClickDetail = () => {
      navigate({
        h5Options: {
          url: "/mx-shop-micro/reward-center",
          query: {
            searchType: 1
          }
        },
      });
    };
    const handle = Modal.show({
      content: <DistributorStimulateSwitchSecondPop obj={obj} onClickDetail={onClickDetail} onClose={() => handle.close()} />,
      bodyStyle: { width: "80vw", borderRadius: "10px", paddingTop: "10px" },
      closeOnMaskClick: true,
    });
  };
  const init = async () => {
    const res = await getWhetherPopup({ businessCode });
    if (!res?.data?.length) {
      return;
    }
    res.data.forEach((item: PopInfo) => {
      console.log(item.popupCode);
      const { popupFlag, popupCode, obj } = item;
      if (!popupFlag) {
        return;
      }
      switch (popupCode) {
        case "RightsUpgrade":
          // 会员权益升级
          showRightsUpgradePop();
          break;
        case "DistributorStimulateSwitchSecond":
          // 激励活动进度日报弹框
          showDistributorStimulateSwitchSecondPop(obj);
          console.log(88888, obj);
          break;
      }
    });
    // 弹窗内容类型 1:图片 2:文案
    // if (popContentType === 2) {
    //   Dialog.alert({
    //     title: windowPopContent.title,
    //     content: windowPopContent.content,
    //     confirmText: "我知道了",
    //     className: "dialog__private",
    //   });
    // }
  };
  const showDistributorStimulateSwitchSecond = (obj) => {
    // 激励活动进度日报弹框
    showDistributorStimulateSwitchSecondPop(obj);
  };
  useImperativeHandle(ref, () => ({
    showDistributorStimulateSwitchSecond,
  }));

  useEffect(() => {
    init();
  }, []);
  useUnactivate(() => {
    Modal.clear();
  });
  return <></>;
});

export default BizPopup;
