import star_half from "@/assets/star_half.png";
import star_has from "@/assets/star_has.png";
import star_null from "@/assets/star_null.png";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface Props {
  score: number;
}

const maxScore = 5;

const StarCom: React.FC<Props> = props => {
  const arr = String(props.score).split(".");
  let halfTmp: any = 0;
  let fullTmp: any = 0;
  let emptyTmp: any = 0;
  fullTmp = arr[0];
  if (arr[1]) {
    halfTmp = Number(arr[1]) > 0 ? 1 : 0;
  }
  emptyTmp = maxScore - fullTmp - halfTmp;
  const full = Number(fullTmp);
  const half = Number(halfTmp);
  const empty = Number(emptyTmp);

  return (
    <div className={styles.star_com}>
      {(full ?? 0) > 0 &&
        Array.from("1".repeat(full)).map((item, index) => {
          return <Image key={index} src={star_has} className={styles.star_item} />;
        })}
      {half > 0 &&
        Array.from("1".repeat(half)).map((item, index) => {
          return <Image key={index} src={star_half} className={styles.star_item} />;
        })}
      {empty > 0 &&
        Array.from("1".repeat(empty)).map((item, index) => {
          return <Image key={index} src={star_null} className={styles.star_item} />;
        })}
    </div>
  );
};

export default StarCom;
