import React from "react";
import styles from "./index.module.less";

const AddCartSuccess: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src="https://akim-oss.aikucun.com/2d6ea936c8e4f406ea954097dec418d2775cf7a0_1736330955116_6.png" className={styles.image} />
      <span className={styles.text}>加购成功</span>
    </div>
  );
};

export default AddCartSuccess;
