import { useEffect, useRef, useState } from "react";
import { YouXueSignUpDetail } from "@akc/biz-api";
import { useAuditStatus } from "./useAuditStatus";
import { useSearchParams } from "react-router-dom";
import { fetchCancelSignUp, getYouxueSignUpDetail, youxueSignUpConfirm } from "@/api/youxue";
import { YouxueDialog } from "../components/dialog";
import { onClosePage } from "@/utils/page_utils";
import { Toast } from "antd-mobile";

export const useConfirm = () => {
  // 路由参数
  const [searchParams] = useSearchParams();
  const studyTourId = searchParams.get("studyTourId");
  const scene = searchParams.get("scene") ?? "DETAIL";

  const [detail, setDetail] = useState<YouXueSignUpDetail>();

  const auditStatus = useAuditStatus(detail);

  const refuseReason = useRef<string>();

  useEffect(() => {
    if (scene === "CHANGE") {
      document.title = "游学行程变更提醒";
    } else {
      document.title = "游学活动详情";
    }
  }, [scene]);

  const fetchDetailInfo = async () => {
    if (!studyTourId) {
      return;
    }
    const res = await getYouxueSignUpDetail({
      studyTourId,
      scene: scene,
    });
    setDetail(res?.data);
  };

  useEffect(() => {
    fetchDetailInfo();
  }, [studyTourId, scene]);

  // 取消报名
  const onCancelSignUp = () => {
    if (!studyTourId) {
      return;
    }
    YouxueDialog.cancelSignUp({
      submit: () => {
        fetchCancelSignUp({ studyTourId })
          .then(() => onClosePage())
          .catch(console.error);
      },
    });
  };

  // 暂不参加
  const onSubmitNotJoin = () => {
    if (!studyTourId) {
      return;
    }
    YouxueDialog.onSubmitNotJoin({
      onChange: reason => {
        refuseReason.current = reason;
      },
      submit: () => {
        if ((refuseReason.current?.length || 0) === 0) {
          Toast.show("请输入不能参加的详细原因");
          return;
        }
        YouxueDialog.onSubmitNotJoin2({
          submit: () => {
            youxueSignUpConfirm({
              confirmStatus: 2,
              studyTourId,
              refuseReason: refuseReason.current,
            })
              .then(() => {
                fetchDetailInfo();
              })
              .catch(console.error);
          },
        });
      },
    });
  };

  // 确认参加
  const onSubmitJoin = () => {
    if (!studyTourId) {
      return;
    }
    youxueSignUpConfirm({
      confirmStatus: 1,
      studyTourId,
    })
      .then(() => {
        YouxueDialog.submitJoin();
        fetchDetailInfo();
      })
      .catch(console.error);
  };

  return {
    detail,
    auditStatus,
    scene,
    onCancelSignUp,
    onSubmitJoin,
    onSubmitNotJoin,
  };
};
