import React from "react";
import styles from "./index.module.less";

interface Props {
  title?: string;
  logo?: string;
}

// 品牌信息
const BrandInfoComp: React.FC<Props> = props => (
  <div className={styles.comp}>
    <img className={styles.logo} src={props.logo} />
    <div className={styles.title}>{props.title ?? ""}</div>
  </div>
);

export default BrandInfoComp;
