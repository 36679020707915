import { queryCardPop } from "@/api/market";
import { MarketModel } from "@/pages/product-detail/model/MarketModel";
import useNavigation from "@/utils/useNavigate";
import { Mask, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface Props {
  // 营销信息
  marketInfo?: MarketModel;
  // 展示
  isShowMarketingPop?: boolean;
  // 关闭
  onClose: () => void;
}

const MarketingPopup: React.FC<Props> = ({ marketInfo, isShowMarketingPop, onClose }) => {
  const { navigate, navigate2Product, navigate2H5 } = useNavigation();

  const onClosePress = () => {
    onClose?.();
  };

  const navigateTo = e => {
    e.stopPropagation();
    onClose?.();
    if (marketInfo?.skipType === "PRODUCT") {
      if (marketInfo?.activityId && marketInfo?.activitySpuCode) {
        navigate2Product({
          activityId: marketInfo?.activityId,
          activitySpuId: marketInfo?.activitySpuCode,
        });
      }
    } else if (marketInfo?.skipType === "HOT_SALE") {
      navigate({
        h5Options: {
          url: `/mx-shop-micro?customPageCode=${marketInfo?.hotSaleId}`,
        },
      });
    } else if (marketInfo?.skipType === "DAILY_REWARD") {
      navigate({
        h5Options: {
          url: "/mx-shop-micro/daily-reward",
        },
      });
    }
  };
  if (!marketInfo) {
    return <></>;
  }

  if (
    !(
      (marketInfo?.skipType === "PRODUCT" &&
        marketInfo?.activityId &&
        marketInfo?.activitySpuCode) ||
      marketInfo?.skipType === "HOT_SALE" ||
      marketInfo?.skipType === "DAILY_REWARD"
    )
  ) {
    return <></>;
  }

  return (
    <Mask visible={isShowMarketingPop} onMaskClick={() => onClosePress()} opacity={0.75}>
      <div className={styles.wrap} onClick={onClosePress}>
        <img src={marketInfo?.popupUrl} className={styles.img} onClick={navigateTo} />
        <div className={styles.closeBtn}></div>
      </div>
    </Mask>
  );
};

export default MarketingPopup;
