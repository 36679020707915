import AddPurchasePanel from "@/components/add-purchase-panel";
import AddToCartModal from "@/components/add-to-cart-modal";
import PosterSwipe from "@/components/poster-swiper";
import TopNavigation from "@/components/top-navigation";
import VerticalScrollview from "@/components/vertical-scrollview";
import LiveFloatingBubble from "@/pages/activity/components/LiveFloatingBubble";
import { navigate } from "@/utils/navigate";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import { getUrlQueryObject } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import { LabelInfoModel, ProductDetailModel } from "@akc/biz-api";
import {
  BannerComp,
  DecorateMaterialCompV2,
  PDActivityCardComp,
  PDB2RShopCardComp,
  ProductDetailAttrComp,
  ProductDetailBottomBarComp,
  ProductDetailPriceComp,
  ProductDetailPromotionComp,
  ProductDetailShootingNValuationComp,
  ProductDetailSoldRecordComp,
  ProductDetailTopComp,
  ProductDetailVideoNImageComp,
  ProductSKUPannelComp,
} from "@akc/mx-taro-h5-components";
import { Image, ImageViewer, Swiper, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React, { useMemo } from "react";
import BottomTipComp from "../components/bottom-tip";
import FloatMenuComp from "../components/float-menu";
import LREntranceComp from "../components/lr-entrance";
import MedicateGuidanceDialog from "../components/medicate-guidance-dialog";
import ProductIntroduceComp from "../components/product-introduce";
import ProductList from "../components/product-list";
import PromotionBottomDialog, { MarketingServicesType } from "../components/promotion-dialog";
import SkeletonComp from "../components/skeleton";
import TitleComp from "../components/title-comp";
import TopTabsComp from "../components/top-tabs";
import { ProductDetailAction } from "../utils/action";
import MarketingPopup from "./components/market-pop";
import MediaPreview from "./components/media";
import NewCustomerZonePopupV2 from "./components/new-customer-zone";
import NewCustomerZonePopupV1 from "./components/new-customer-zone/v1";
import PromotionFeeDialog from "./components/promotion-fee-dialog";
import RecommendProductDialog from "./components/recommend-product-dialog";
import TopVideoComp from "./components/top-video";
import { useProductDetail } from "./hook/useProductDetail";
import styles from "./index.module.less";
import { ProductVideoImageItem } from "./model/Media";

const ProductDetail: React.FC = () => {
  const control = useProductDetail();
  const {
    navigate2Home,
    navigate2H5,
    navigate2NxProductList,
    navigate2Material,
    navigate2Cart,
    navigate2Product,
    navigate2Activity
  } = useNavigation();

  //价格组件
  const productDetailPriceComp = useMemo(
    () => (
      <ProductDetailPriceComp
        className={styles.part}
        product={control.productDetail}
        showRemindBtn={false}
        topPromotionLocationCode="H5_ProductDetail_SalesPriceTag"
        promotionLocationCode="H5_ProductDetail_PriceDown"
        bottomProductionLocationCode="H5_ProductDetail_TitleDown"
        onCopyTitleAction={str => {
          if (!str) {
            return;
          }
          copy(str);
          Toast.show("内容已复制");
        }}
        onCopySubTitleAction={str => {
          if (!str) {
            return;
          }
          copy(str);
          Toast.show("内容已复制");
        }}
        onForwardAction={() => {
          control.onForwardAction();
        }}
        onSKUAction={skuInfo => {
          control.setFirstSalePropertyValue(skuInfo?.value);
          control.setSelectSkuId(undefined);
        }}
        onMedicationGuidanceAction={() => {
          //用药指导
          control.setShowMedicateDialog(true);
        }}
        onSKUExpandAction={() => {
          //sku
          control.handleSelectSku();
        }}
        onProfitAction={() => {
          //点击佣金
          control.setIsAdvanceProfit(false);
          control.setShowPromotionFeeDialog(true);
        }}
        onAdvanceProfitAction={() => {
          //点击预告佣金
          control.setIsAdvanceProfit(true);
          control.setShowPromotionFeeDialog(true);
        }}
        onCouponAction={(labelInfoVOList?: LabelInfoModel[], product?: ProductDetailModel) => {
          //点击领券按钮
          control.showPromotionDialog(MarketingServicesType.Discount);
        }}
      />
    ),
    [control.productDetail],
  );

  return (
    <>
      {control.showSkeleton ? <SkeletonComp /> : (

        <VerticalScrollview className={styles.container}>
          <div id="anchor-1" ref={control.headerDomRef} />
          {/* 导航 */}
          <TopNavigation
            hideHomeText={true}
            hideSearchIcon={true}
            hideCartIcon={true}
            hideFavoriteIcon={!control.showFavoriteIcon}
            center={
              control.showTopTabs ? (
                <TopTabsComp activeKey={control.activeKey} tabItems={control.tabItems} />
              ) : (
                <Image
                  className={styles.topStable}
                  src="https://akim-oss.aikucun.com/8e2e5c6656109e73430813f34b8b01d794db022f_1724899992042_44.png"
                />
              )
            }
          />
          <div className={styles.body}>
            {/* 顶部区域 */}
            <ProductDetailTopComp
              swiperRef={control.swiperRef}
              product={control.productDetail}
              productPicRightLocationCode={"H5_ProductDetail_ProductPicRight"}
              noWrapText={control.noWrapText}
              atmosphereNode={
                <>
                  {(control.atmosphereModel?.atmosphereList?.length ?? 0) > 0 && (
                    <div className={styles.swip}>
                      <Swiper direction='vertical' loop={true} indicator={false} autoplay autoplayInterval={3000} style={{ height: 40 }}>
                        {
                          control.atmosphereModel?.atmosphereList?.map((item, index) => {
                            return <Swiper.Item key={index} >
                              <div className={styles.swipItem}>
                                <Image
                                  className={styles.swipItemIcon}
                                  src={item.userAvatar}
                                  fit="contain"
                                  lazy={true}
                                />
                                <div className={styles.swipItemName}>
                                  {item.userName}
                                  {item.orderTime}
                                  {item.dataTypeString?.trimEnd()}
                                </div>
                              </div>
                            </Swiper.Item>;
                          })
                        }
                      </Swiper>
                    </div>
                  )}
                </>
              }
              videoNode={(coverUrl, videoUrl) => (
                <TopVideoComp videoUrl={videoUrl} coverUrl={coverUrl} />
              )}
              onImageAction={index => {
                control.onSkuMainImagePreview({ index: index });
              }}
              onSizeImageAction={() => {
                control.onSkuMainImagePreview({ isSizeImage: true });
              }}
              onSizeSpecAction={() => {
                control.goToProductFitSize();
              }}
              onSwipeTo={index => {
                control.swiperRef.current?.swipeTo(index);
              }}
            />
            <div className={styles.center}>
              {/* 价格区域 */}
              {productDetailPriceComp}
              {/* 营销信息区域<复杂标签> */}
              <ProductDetailPromotionComp
                className={styles.part}
                product={control.productDetail}
                incentiveLocationCode="H5_ProductDetail_Marketing_Motivate"
                promoteSalesLocationCode="H5_ProductDetail_Marketing_PromoteSales"
                serviceLocationCode="H5_ProductDetail_Marketing_Service"
                //地址
                onAddressAction={() => {
                  control.showPromotionDialog(MarketingServicesType.Address);
                }}
                //发货
                onDeliveryAction={() => {
                  control.showPromotionDialog(MarketingServicesType.delivery);
                }}
                //激励
                onIncentiveAction={() => {
                  let motivateTag = control.productDetail?.labelLocationVOList?.filter(item => item.locationCode === "H5_ProductDetail_Marketing_Motivate");
                  let labelInfoVOList = (motivateTag?.length && motivateTag?.[0]?.labelInfoVOList) || [];
                  if (labelInfoVOList.length === 1 && labelInfoVOList?.[0]?.refIdList?.length === 1) {
                    const label = labelInfoVOList[0];
                    if (label?.skipUrl && label?.drillDown) {
                      let SKIPURL = new URL(label?.skipUrl);
                      let urlQuery = getUrlQueryObject(label?.skipUrl);
                      navigate({
                        h5Options: {
                          url: `${SKIPURL.pathname}`,
                          query: urlQuery
                        },
                      });
                    }
                    return;
                  }
                  control.showPromotionDialog(MarketingServicesType.Motivate);
                }}
                //服务
                onServiceAction={() => {
                  control.showPromotionDialog(MarketingServicesType.Service);
                }}
                //活动
                onPromoteSalesAction={() => {
                  const nxCodes = [
                    "PROMO_NX_YUAN",
                    "PROMO_NX_ZHE",
                    "PROMO_YI_KOU_JIA",
                    "PROMO_NX_YUAN_YIKOUJIA",
                    "PROMO_NX_ZHE_YIKOUJIA",
                  ];
                  let labelList = control.productDetail?.labelLocationVOList?.map(item => {
                    if (
                      item.locationCode &&
                      "H5_ProductDetail_Marketing_PromoteSales".includes(item.locationCode)
                    ) {
                      return item;
                    }
                  });
                  labelList = labelList?.filter(item => item) ?? [];
                  let promotionTtags = labelList[0]?.labelInfoVOList ?? [];
                  const promoteSalesNx = promotionTtags.filter(
                    i => i.labelCode && nxCodes.includes(i.labelCode),
                  );
                  if (promoteSalesNx?.length) {
                    const promoActivityId = promoteSalesNx.map(i => i.refId).filter(i => i) as string[];
                    navigate2NxProductList(promoActivityId);
                  } else {
                    control.showPromotionDialog(MarketingServicesType.Promote);
                  }
                }}
                //新客券
                onNewcomerCouponAction={() => {
                  const { receiveStatus, buttonStatus } =
                    control.productDetail?.skuExtendInfoVO?.newcomerCouponVO ?? {};
                  if (buttonStatus === 2) {
                    // buttonStatus按钮状态(1：领券 2：条件不符 3：暂未开始 4：已领取 5.去使用 6.已抢光 7.去凑单）
                    return;
                  }
                  if (receiveStatus === 0) {
                    control.showPromotionDialog(MarketingServicesType.Discount_NewComer);
                  } else if (receiveStatus === 1) {
                    if (buttonStatus === 7) {
                      control.onUseCoupon();
                    } else if (buttonStatus === 5) {
                      // 去使用，等同于立即购买
                      control.onBuyAction();
                    }
                  }
                }}
                //商品参数
                onInformationAction={() => {
                  control.showPromotionDialog(MarketingServicesType.Product_Paramters);
                }}
              />

              {/* 规格选择 */}
              <ProductSKUPannelComp
                className={styles.sku}
                product={control.productDetail}
                packUpSellOut={false}
                onPropertyAction={e => {
                  //点击sku后的回调
                  control.onPropertyAction(e.skuInfo);
                }}
                onSizeSpecAction={() => {
                  //查看全部尺码表回调
                  control.goToProductFitSize();
                }}
              />

              {/* 档期卡片 */}
              {control.activityItem && (
                <PDActivityCardComp
                  className={styles.part}
                  activity={control.activityItem}
                  resourceType={
                    control.productDetail?.cardEntranceVO?.cardType === "ACTIVITY"
                      ? "你可能还喜欢"
                      : "你可能还需要"
                  }
                  resourceName={
                    control.productDetail?.cardEntranceVO?.cardType === "ACTIVITY"
                      ? "你可能还喜欢"
                      : "你可能还需要"
                  }
                  mainLocationCode="H5_ScheduleList_BrandDown"
                  entryBtnAction={activity => {
                    navigate2H5({
                      path: `/mx-shop-micro/activity/${activity.activityId}?liveNo=${control.liveNo}&fromPage=商品详情`,
                    });
                  }}
                  onProductAction={(product, index) => {
                    navigate2Product({
                      activityId: product.activityId,
                      activitySpuId: product.activitySpuId,
                      selectSkuId: product.selectSkuId,
                    });
                  }}
                />
              )}

              {/* 店铺卡片 */}
              {control.shopItem && (
                <PDB2RShopCardComp
                  className={styles.part}
                  shopInfo={control.shopItem}
                  resourceType={
                    control.productDetail?.cardEntranceVO?.cardType === "ACTIVITY"
                      ? "你可能还喜欢"
                      : "你可能还需要"
                  }
                  resourceName={
                    control.productDetail?.cardEntranceVO?.cardType === "ACTIVITY"
                      ? "你可能还喜欢"
                      : "你可能还需要"
                  }
                  nameFrontTagLocationCode="H5_Shop_ShopNameFront"
                  onShopAction={(shopInfo, index) => {
                    control.onB2RShopAction(2);
                  }}
                  onProductAction={(product, index) => {
                    navigate2Product({
                      activityId: product.activityId,
                      activitySpuId: product.activitySpuId,
                      selectSkuId: product.selectSkuId,
                    });
                  }}
                />
              )}

              {/* 素材 */}
              {control.materialItem && (
                <div id="anchor-2">
                  {!control.currentRoleIsC && control.materialItem && (
                    <DecorateMaterialCompV2
                      className={styles.part}
                      models={control.materialItem?.result}
                      count={control.materialItem?.totalCount ?? 0}
                      title="精选素材"
                      titleFontSize={14}
                      titleFrontLocationCode="H5_MaterialList_MaterialTitlefront"
                      moreLimitNumber={4}
                      onMoreAction={() => {
                        ProductDetailAction.onMoreMaterialAction(control.productDetail);
                      }}
                      onMaterialAction={material => {
                        navigate2Material(
                          material?.type,
                          material?.materialNo,
                          control.productDetail?.activitySpuId,
                        );
                      }}
                    />
                  )}
                </div>
              )}

              {/* 商品视频/图片 */}
              {control.showVideoImage() && (
                <div className={styles.media}>
                  <ProductDetailVideoNImageComp
                    product={control?.productDetail}
                    onClickAction={(index: number, mediaList: ProductVideoImageItem[]) => {
                      control.setShowMediaPreview(true);
                      control.setMediaSelectIndex(index);
                      control.setVideoImage(mediaList);
                    }}
                  />
                </div>
              )}

              {/* banner */}
              {control.bannerList?.length && (<BannerComp className={styles.banner} bannerList={control.bannerList} />)}

              {/* 宝贝评价 */}
              <LREntranceComp
                leftTitle="宝贝评价"
                rightTitle="查看全部"
                onMoreClick={() => {
                  navigate({
                    h5Options: {
                      url: `/mx-shop-micro/comment-list?productId=${control.productDetail?.skuBaseInfoVO?.merStyleNo}`,
                    },
                  });
                }}
              />

              {/* 实拍专区 */}
              {(control.materialListModel?.materialPictureVOList?.length ?? 0) > 0 && (
                <div id="anchor-2">
                  <ProductDetailShootingNValuationComp
                    className={styles.part}
                    materials={control.materialListModel?.materialPictureVOList}
                    materialTotalCount={control.materialListModel?.total}
                    showEvaluation={false}
                    onMoreMaterialAction={() => {
                      const originParams = control.getReplaceUrlParam();
                      const path = `/mx-shop-micro/promote?objectIds=${control.productDetail?.activitySpuId}&baseProductIds=${control.productDetail?.spuInfoVO?.spuId}&tab=1&fromPage=productDetail&queryType=14&${originParams}`;
                      navigate({
                        h5Options: {
                          url: path,
                        },
                      });
                    }}
                    onMaterialAction={item => {
                      const originParams = control.getReplaceUrlParam();
                      const path = `/mx-shop-micro/${item.containVideo && item.type === 1 ? "recommendList" : "PromoteDetail"}?fromPage=productDetail&materialNo=${item.materialNo}&${originParams}`;
                      navigate({
                        h5Options: {
                          url: path,
                        },
                      });
                    }}
                  />
                </div>
              )}

              {/* 最近购买 */}
              <div id="anchor-sold-record">
                <ProductDetailSoldRecordComp
                  className={styles.part}
                  product={control.productDetail}
                  recentBuyList={control.atmosphereModel?.recentBuyList}
                  saleCountStr={control.atmosphereModel?.saleCountStr}
                  onPackUpAction={() => {
                    const element = document.getElementById("anchor-sold-record");
                    if (element) {
                      const rect = element.getBoundingClientRect();
                      const offset = rect.top + window.pageYOffset - 44;
                      window?.scrollTo({
                        top: offset,
                        behavior: "instant",
                      });
                    }
                  }}
                />
              </div>
              {/* 商品介绍 */}
              <div id="anchor-3">
                <TitleComp className={styles.detailIntroduce} title="详情介绍" />
                {/* 尺码表 */}
                <ProductDetailAttrComp
                  product={control.productDetail}
                  onAllAttrClick={() => {
                    control.goToProductFitSize();
                  }}
                />
                <ProductIntroduceComp
                  spuDetailImageUrls={control.productDetail?.spuDetailImageUrls}
                  categoryHeadAnnouncement={
                    control.productDetail?.spuInfoVO?.categoryHeadAnnouncement
                  }
                  onImgClick={(imgs, index) => {
                    control.setShowPicPreview(true);
                    control.setPreviewPicList(imgs ?? []);
                    control.setPreviewIndex(index ?? 0);
                    control.previewRef?.current?.swipeTo(index ?? 0);
                  }}
                />
              </div>
            </div>
            {/* 推荐商品列表 */}
            {control.productDetail && control.recommendProductList?.length > 0 && (
              <div id="anchor-4">
                <ProductList
                  productList={control.recommendProductList}
                  loadMore={control.onLoadNextPage}
                  hasNext={control.hasNext}
                />
              </div>
            )}
          </div >

          {/* 多媒体预览组件 */}
          {
            control.showMediaPreview && control.videoImage && (
              <MediaPreview
                swiperSources={control.videoImage}
                selectIndex={control.mediaSelectIndex}
                onClosePress={() => control.setShowMediaPreview(false)}
              />
            )
          }

          {/* 底部功能按钮区域 */}
          <div className={styles.bottomBtns}>
            {/* 底部提示文案 */}
            <BottomTipComp
              productDetail={control.productDetail}
              converterMsg={control.converterMsg}
              relatedRecommendProducts={control.soldOutRecommendProductList}
              onShowRecommendProducts={() => {
                //显示推荐商品列表弹窗
                control.setShowRecommendProductDialog(true);
              }}
            />
            <ProductDetailBottomBarComp
              product={control.productDetail}
              cartCount={control.cartTotal}
              isReminded={false}
              onB2RShopAction={() => {
                //点击「店铺」
                control.onB2RShopAction();
              }}
              onActivityAction={() => {
                //点击「会场」
                if (control.productDetail?.activityId) {
                  navigate2Activity({
                    activityNo: control.productDetail?.activityId,
                  });
                }
              }}
              onHomeAction={() => {
                //点击「首页」
                navigate2Home();
              }}
              onServiceAction={() => {
                //点击「客服」
                control.onServiceAction();
              }}
              onCartAction={() => {
                //点击「购物车」
                navigate2Cart();
              }}
              onForwardAction={() => {
                //点击「转发赚钱」
                control.onForwardAction();
              }}
              onBuyAction={() => {
                //点击「立即购买」
                control.onBuyAction();
              }}
              onAddCartAction={() => {
                //点击「加入购物车」
                control.onAddCartAction();
              }}
              onWant2BuyAction={() => {
                // 点击「还想买」
                control.stillBuy();
              }}
              onExchangeAction={() => {
                // 点击「立即兑换」
                control.onExchangeAction();
              }}
            />
          </div>
          {/* 悬浮菜单按钮 */}
          {
            control.showFloatMenu && control.productDetail && (
              <FloatMenuComp
                productDetail={control.productDetail}
                onScrollTop={() => {
                  control.headerDomRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
                onShareClick={() => {
                  if (control.productDetail?.skuExtendInfoVO?.isHideByManager) {
                    const useInfo = JSON.parse(localStorage.getItem("userinfo") ?? "") ?? {};
                    Toast.show(
                      useInfo.shopOwner
                        ? "该品牌已被你隐藏，若要转发前往“我的-隐藏设置”取消隐藏"
                        : "该品牌暂时无法销售",
                    );
                    return;
                  }
                  control.onForwardAction();
                }}
              />
            )
          }
          {/* 营销标签底部弹窗 */}
          <PromotionBottomDialog
            dialogVisible={control.promotionDialogVisible}
            onClose={type => {
              control.diamissPromotionDialog();
              if (type === MarketingServicesType.Discount_NewComer) {
                control.fetchProductDetail();
              }
            }}
            type={control.promotionDialogType}
            product={control.productDetail}
            addressId={control.selectAddressId}
            addressList={control.addressList}
            couponList={control.couponList}
            onAddressItemClick={control.onAddressSwitch}
            onPromoteRightLook={label => {
              control.diamissPromotionDialog();
              setTimeout(() => {
                control.goPromoActivity?.(label);
              }, 500);
            }}
            onReceive={coupon => {
              //领券
              control.receiveCoupon(coupon);
            }}
            onUse={coupon => {
              control.diamissPromotionDialog();
              // 去使用，等同于立即购买
              control.onBuyAction();
            }}
            onTargetAmount={coupon => {
              control.diamissPromotionDialog();
              control.onUseCoupon(coupon);
            }}
          />
          {/* OTC用药指导弹窗 */}
          <MedicateGuidanceDialog
            dialogVisible={control.showMedicateDialog}
            medicationGuidanceList={control.productDetail?.skuBaseInfoVO?.medicationGuidanceList}
            onClose={() => {
              control.setShowMedicateDialog(false);
            }}
          />
          {/* 转发弹窗 */}
          {
            control.sharePosterData && (
              <PosterSwipe
                show={control.showPoster}
                posterData={control.sharePosterData}
                customEditData={control.customEditData}
                isOTC={control.isOTC}
                shareType="PRODUCT"
                btnConfig={["copy", "edit"]}
                close={() => {
                  control.setShowPoster(false);
                  document.body.style.overflow = "auto";
                }}
              />
            )
          }
          {/* 加购弹窗 */}
          {
            control.productDetail && control.cartPannelButtonType && control.showNewCartPanel ? (
              <AddPurchasePanel
                visible={control.cartModalVisible}
                product={control.modalProduct}
                needLoading={false}
                close={(e) => {
                  if (e?.skuId !== control.productDetail?.selectSkuId) {
                    control.setFirstSalePropertyValue(undefined);
                    control.setSelectSkuId(e?.skuId);
                  }
                  control.setCartModalVisible(false);
                }}
                confirm={control.changeCartNum}
                trackData={{ pageName: "素材商品详情页" }}
                buttonType={control.cartPannelButtonType}
                buttonName="确定"
              />
            ) : (
              <AddToCartModal
                visible={control.cartModalVisible}
                product={control.modalProduct}
                buttonStyle={control.cartPannelButtonType}
                buttonText="确定"
                pageType={5}
                close={(e) => {
                  if (e?.skuId !== control.productDetail?.selectSkuId) {
                    control.setFirstSalePropertyValue(undefined);
                    control.setSelectSkuId(e?.skuId);
                  }
                  control.setCartModalVisible(false);
                }}
                confirm={control.changeCartNum}
              />
            )
          }
          {/* 图片预览 */}
          <ImageViewer.Multi
            ref={control.previewRef}
            getContainer={document.body}
            images={control.previewPicList}
            visible={control.showPicPreview}
            defaultIndex={control.previewIndex}
            onClose={() => {
              control.setShowPicPreview(false);
            }}
          />
          {/* 推广费弹窗 */}
          <PromotionFeeDialog
            dialogVisible={control.showPromotionFeeDialog}
            isAdvanceProfit={control.isAdvanceProfit}
            productDetail={control.productDetail}
            onClose={() => {
              control.setShowPromotionFeeDialog(false);
            }}
          />

          {/* 推荐商品弹窗 */}
          <RecommendProductDialog
            dialogVisible={control.showRecommendProductDialog}
            productList={control.soldOutRecommendProductList}
            onClose={() => {
              control.setShowRecommendProductDialog(false);
            }}
          />

          {/* 直播小窗 */}
          <LiveFloatingBubble activityId={control.productDetail?.skuCurrentPriceInfo?.activityId ?? control.productDetail?.activityId} />

          {/* 新人专区 新 */}
          {
            control.productDetail &&
            control.showPopCoupon &&
            control.couponInfo.thirdCustomerFlag === 1 && (
              <NewCustomerZonePopupV2
                couponInfo={control.couponInfo}
                pageName={"商品详情页面"}
                shopCode={""}
                activityId={control.productDetail?.activityId ?? ""}
                activitySpuId={control.productDetail?.activitySpuId ?? ""}
                update={() => {
                  control.fetchProductDetail();
                }}
                onClose={() => {
                  control.setShowPopCoupon(false);
                }}
              />
            )
          }

          {/* 新人专区 老 */}
          {
            control.productDetail &&
            control.showPopCoupon &&
            control.couponInfo.thirdCustomerFlag === 0 && (
              <NewCustomerZonePopupV1
                couponInfo={control.couponInfo}
                pageName={"商品详情页面"}
                update={() => {
                  control.fetchProductDetail();
                }}
                onClose={() => {
                  control.setShowPopCoupon(false);
                }}
              />
            )
          }

          {/* 营销pop */}
          {
            control.isShowMarketingPop && (
              <MarketingPopup
                isShowMarketingPop={control.isShowMarketingPop}
                marketInfo={control.marketingPopInfo}
                onClose={() => {
                  control.setIsShowMarketingPop(false);
                }}
              />
            )
          }
        </VerticalScrollview >
      )}
    </>
  );
};

export default ProductDetail;
