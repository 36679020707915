import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "./request";
/**
 * (爱豆满赠、爱豆满返、PK)活动详情查询-限制角色仅为店主
 * https://yapi.akcops.com/project/2679/interface/api/384198
 * @author 高扬
 */
export async function queryDetailServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/queryDetail?actId=${params.actId}`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * (爱豆满赠、爱豆满返、PK)活动详情查询-落地页用
 * https://yapi.akcops.com/project/2679/interface/api/384198
 * @author 高扬
 */
export async function queryBaseDetailServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/queryBaseDetail?actId=${params.actId}`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * 根据addreddId查询地址详情(收货地址页)
 * https://yapi.akcops.com/project/2679/interface/api/391749
 * @author 泽北
 */
export function queryAddressDetailServ(params: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/address/queryBaseAddressInfo`,
    method: "get",
    params,
  });
}

/**
 * 新增或修改地址
 * https://yapi.akcops.com/project/2679/interface/api/390786
 * @author 泽北
 */
export function saveOrUpdateAddressServ(params: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/address/saveOrUpdateAddress`,
    method: "post",
    data: params,
  });
}

/**
 * 查询收货地址填写页信息
 * https://yapi.akcops.com/project/2679/interface/api/390084
 * @author 泽北
 */
export function queryAddrPageInfoServ(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/address/queryAddrPageInfo`,
    method: "get",
    params,
  });
}

/**
 * 分页查询地址列表
 * https://yapi.akcops.com/project/2679/interface/api/383802
 * @author 泽北
 */
export async function queryGiftAddrPaginationServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/address/queryGiftAddrPagination`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * 逻辑删除地址
 * https://yapi.akcops.com/project/2679/interface/api/383838
 * @author 泽北
 */
export function logicalDeleteServ(params: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/address/logicalDelete`,
    method: "get",
    params,
  });
}

export async function getActivityListServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/activity/list`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * 激励活动卡片(列表)
 * https://yapi.akcops.com/project/2679/interface/api/404889
 * 白国庆
 */
export async function querySearchPromoActivityCardListServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/searchPromoActivityCardList`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * @description 爱豆激励-店主店长站内消息数据
 * @link https://yapi.akcops.com/project/2711/interface/api/414330
 * @author 泽北(桑国凯)
 */
export async function queryDataReportServ() {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/dataReport`,
    method: "post",
  });
  return res?.data;
}

/**
 * 店长业绩分页列表接口
 * https://yapi.akcops.com/project/2679/interface/api/416689
 * @author 刘袁
 */
export async function queryDistributorDataListServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/distributor/performanceDetails?actId=${params.actId}&pageIndex=${params.pageIndex}&pageSize=${params.pageSize}`,
    method: "get",
    data: params,
  });
  return res?.data;
}

/**
 * 查询激励中心的头部的tab列表
 * https://yapi.akcops.com/project/2679/interface/api/417221
 * @author 刘袁
 */
export async function queryIncentiveCenterTabListServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/queryIncentiveCenterTabList`,
    method: "get",
    params,
  });
  return res?.data;
}

/**
 * @description 激励活动卡片（R开放体系）
 * @link https://yapi.akcops.com/project/2679/interface/api/384225
 * @author 高扬
 */
export async function queryPromoActivityCardInfo(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/searchPromoActivityCardInfo`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * @description 活动列表查询(R激励列表)
 * @link https://yapi.akcops.com/project/2679/interface/api/410163
 * @author 金言
 */
export async function queryActListServ(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/queryActList`,
    method: "post",
    data: params,
  });
  return res?.data;
}
