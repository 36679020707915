import React, { useState } from "react";
import styles from "./BrandItem.module.less";

interface props {
  item: any;
  index: number;
  onConfirmAction: (item: any, isTogger?: boolean) => void;
}

const BrandItem: React.FC<props> = ({ item, index, onConfirmAction }) => {
  return (
    <div className={styles.brand}>
      <div className={styles.brand__left}>
        <img className={styles.brand__logo} src={item.bizLogo} />
        <div className={styles.brand__info}>
          <p className={styles.brand__name}>{item.bizName}</p>
          <p className={styles.brand__hide_type} onClick={() => onConfirmAction(item, true)}>
            {item.hideType === "brand" ? "全部隐藏" : "仅隐藏常销商品"}
          </p>
        </div>
      </div>
      <div
        className={`${styles.brand__btn} ${item.isHide && styles.brand__btn_cancel}`}
        onClick={() => onConfirmAction(item)}>
        {item.isHide ? "取消隐藏" : "隐藏品牌"}
      </div>
    </div>
  );
};

export default BrandItem;
